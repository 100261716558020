import React from 'react';
import icons from '@bbkAdminUtils/icons';
import { css } from '@emotion/react';

const SuspenseLoader = ({
  style = { padding: '20px' },
  message = 'Loading...',
}) => (
  <div
    style={style}
    css={css`
      padding: 20px;
      color: rgba(0, 0, 0, 0.3);
      display: flex;
      width: calc(100% - 60px);
      height: calc(100% - 60px);
      justify-content: center;
      align-items: center;
      cursor: progress;
      svg {
        width: 64px;
        height: 64px;
        circle {
          stroke: rgba(0, 0, 0, 0.3);
        }
      }
    `}
  >
    {icons.loaderSW6} {message}
  </div>
);

export default SuspenseLoader;
