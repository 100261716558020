const tailwindColors = {
  darkestPurple: '#46326e',
  darkerPurple: '#230067',
  purple: '#582ea9',
  medPurple: '#6600b5',
  darkerPurple2: '#5b23ac',
  brightPurple: '#9013fe',
  primary: '#3d8cb8',
  success: '#27ae60',
  danger: {
    DEFAULT: '#eb5656',
    500: '#eb5656',
  },
  black: '#000000',
  white: '#ffffff',
  transparent: 'transparent',
  current: 'currentColor',

  cblogo: '#ff3300',
  toggleGreen: '#50e3c2',
  cbrprimary: {
    50: '#e5f3fa',
    100: '#b5def0',
    200: '#8ccce6',
    300: '#68badf',
    400: '#4da8d5',
    500: '#3d8cb8',
    600: '#2d6a8a',
    700: '#1d485f',
    800: '#012a38',
    900: '#040f13',
  },
  cbrneutral: {
    50: '#f4f5f7',
    100: '#eaecee',
    200: '#d6dadf',
    300: '#bac1c9',
    400: '#96a0ac',
    500: '#6e7a87',
    600: '#4d5966',
    700: '#374049',
    800: '#21262c',
    900: '#0b0d0f',
  },
  cbrinfo: {
    50: '#eff5f5',
    100: '#d0e0e1',
    200: '#b1cbce',
    300: '#92b6ba',
    400: '#73a1a6',
    500: '#59888c',
    600: '#456a6d',
    700: '#314b4e',
    800: '#1e2d2f',
    900: '#0a0f10',
  },
  cbrdanger: {
    50: '#fce9e9',
    100: '#f5bdbc',
    200: '#ee9190',
    300: '#e86564',
    400: '#e13837',
    500: '#c81f1e',
    600: '#9b1817',
    700: '#6f1111',
    800: '#430a0a',
    900: '#160303',
  },
  cbrwarn: {
    50: '#feefe7',
    100: '#fbceb6',
    200: '#f9ad86',
    300: '#f68d55',
    400: '#f46c25',
    500: '#da530b',
    600: '#aa4009',
    700: '#792e06',
    800: '#491c04',
    900: '#180901',
  },
  cbrsuccess: {
    50: '#ecf9ee',
    100: '#c5eccd',
    200: '#9fe0ac',
    300: '#78d38a',
    400: '#52c769',
    500: '#38ad4f',
    600: '#297f3a',
    700: '#1f602c',
    800: '#133a1a',
    900: '#061309',
  },
  cbryellow: {
    50: '#FFF9E5',
    100: '#FEF6CD',
    200: '#F9E88F',
    300: '#EFDB6F',
    400: '#E9CC33',
    500: '#BCA322',
    600: '#716111',
    700: '#5C4B0E',
    800: '#362C0A',
    900: '#181405',
  },
};

module.exports = tailwindColors;
