import React from 'react';
import classNames from 'classnames';

import TippyTooltip from '@bbkAdminComponents/tooltips/tippy-tooltip';
import icons from '@bbkAdminUtils/icons';
import Icon from '@bbkAdminComponents/icons/icon-generator';

import './form-field.scss';

type Props = {
  title?: string;
  description?: string;
  input: React.ReactNode;
  disabled?: boolean;
  warning?: string;
};

const FormField: React.FC<Props> = (props) => {
  const devNull: React.ReactEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    return false;
  };

  const { disabled, warning, title, description, input } = props;
  return (
    <div
      className={classNames('bbk-form-field', {
        disabled,
        warning,
      })}
    >
      <div className="bbk-form-field-mask" onClick={devNull} />
      <div className="bbk-form-field-title">
        {title}
        {description && (
          <span className="checkbox-question-icon">
            <TippyTooltip content={description}>
              {icons.questionMark}
            </TippyTooltip>
          </span>
        )}
      </div>
      <div className="bbk-form-field-input">
        {input}
        <div className="bbk-form-field-warning-icon">
          <Icon name="warningLarge" class="inline redFill warning" />
        </div>
      </div>
      <div className="bbk-form-field-warning">{warning}</div>
    </div>
  );
};

FormField.defaultProps = {
  disabled: false,
};

export default FormField;
