import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { CountryData } from 'react-phone-input-2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import { useSelector } from 'react-redux';
import type { FormikConfig } from 'formik';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import cs from 'classnames';
import _ from 'lodash';
import {
  ImportantMessage,
  InputError,
  InputLabel,
} from '@bbkAdminComponents/form-components';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { signupStyles } from '@bbkAdminComponents/trial-signup/TrialSignupPage';
import type { SelectOption } from '@bbkAdminComponents/select/select';
import { FormikGenericSelect } from '@bbkAdminComponents/select/select';
import { TrialSignupSteps } from '@bbkAdminComponents/trial-signup/TrialSignupSteps';
import { TrialSignupLogo } from '@bbkAdminComponents/trial-signup/TrialSignupLogo';
import { segmentCustomEvent } from '@bbkAdminUtils/analytics';
import { PreparingOverlay } from '@bbkAdminComponents/preparing-overlay/preparing-overlay';
import BrightbackSession from '@bbkAdminUtils/brightback-session';
import {
  MARKETO_UTM_FIELDS_QUERY_PARAMS_TO_COOKIE,
  MarketoUtmFieldsType,
  preauthCompanySlice,
} from '@bbkAdminRedux/rtkq/preauth-company.slice';
import { SignupPaths } from '@bbkAdminRouter/signup-paths';
import type { CompanyData } from '@bbkAdminComponents/trial-signup/TrialSignupContext';
import img from '@bbkAdminComponents/trial-signup/assets/signup-page2-cb-retention.png';
import { selectUser } from '@bbkAdminRedux/app/selectors';
import { useSearchParams } from '@bbkAdminUtils/useSearchParams';
import { encodeObjectForQuery } from '@bbkAdminRedux/rtkq/rtkq-utils';

const monthlyCancelsOptions: SelectOption[] = [
  {
    label: 'Under 50',
    value: 'under_50',
  },
  {
    label: '50 - 249',
    value: 'between_50_and_249',
  },
  {
    label: '250 - 999',
    value: 'between_250_and_999',
  },
  {
    label: '1000 - 5000',
    value: 'between_1000_and_5000',
  },
  {
    label: '5000+',
    value: 'more_than_5000',
  },
];

const billingSystemOptions: SelectOption[] = [
  {
    label: 'Apple App Store',
    value: 'apple_appstore',
  },
  {
    label: 'Aria Systems',
    value: 'aria_system',
  },
  {
    label: 'Bold Commerce',
    value: 'bold_commerce',
  },
  {
    label: 'Braintree Subscriptions',
    value: 'braintree',
  },
  {
    label: 'Chargebee',
    value: 'chargebee',
  },
  {
    label: 'Chargify',
    value: 'chargify',
  },
  {
    label: 'Custom / Homegrown',
    value: 'custom_homegrown',
  },
  {
    label: 'Google Play',
    value: 'google_play',
  },
  {
    label: 'InfusionSoft',
    value: 'infusionsoft',
  },
  {
    label: 'Ordergroove',
    value: 'ordergroove',
  },
  {
    label: 'Piano',
    value: 'piano',
  },
  {
    label: 'Recharge',
    value: 'recharge',
  },
  {
    label: 'Recurly',
    value: 'recurly',
  },
  {
    label: 'Stripe Billing',
    value: 'stripe',
  },
  {
    label: 'Zuora',
    value: 'zuora',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const businessModelOptions: SelectOption[] = [
  {
    label: 'SaaS',
    value: 'saas',
  },
  {
    label: 'eCommerce',
    value: 'eCommerce',
  },
  {
    label: 'Digital',
    value: 'digital',
  },
];

const industryVerticalOptions: SelectOption[] = [
  {
    label: 'Apparel & Beauty',
    value: 'apparel_beauty',
  },
  {
    label: 'Consumer Services',
    value: 'consumer_services',
  },
  {
    label: 'Education',
    value: 'education',
  },
  {
    label: 'Finance',
    value: 'finance',
  },
  {
    label: 'Fintech & Insurance',
    value: 'fintech_insurance',
  },
  {
    label: 'Food Delivery',
    value: 'food_delivery',
  },
  {
    label: 'Health & Wellness',
    value: 'health_wellness',
  },
  {
    label: 'Hospitality',
    value: 'hospitality',
  },
  {
    label: 'Manufacturing',
    value: 'manufacturing',
  },
  {
    label: 'News & Publishing',
    value: 'news_publishing',
  },
  {
    label: 'Pets',
    value: 'pets',
  },
  {
    label: 'Retail',
    value: 'retail',
  },
  {
    label: 'SaaS',
    value: 'saas',
  },
  {
    label: 'Streaming Media & Services',
    value: 'streaming_services',
  },
  {
    label: 'Telecommunications',
    value: 'telecommunications',
  },
  {
    label: 'Transportation',
    value: 'transportation',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

function getCookie(cname: string) {
  const list = decodeURIComponent(document.cookie)
    .split(';')
    .map((x) => x.trim());
  const name = cname + '=';
  const value = list
    .find((x) => x.startsWith(name))
    ?.split(name)
    .pop();
  return value ?? '';
}

const monthlyCancelMap = _.keyBy(monthlyCancelsOptions, 'value');
const billingSystemMap = _.keyBy(billingSystemOptions, 'value');
const businessModelMap = _.keyBy(businessModelOptions, 'value');
const industryVerticalMap = _.keyBy(industryVerticalOptions, 'value');

export const TrialSignupPage3: React.FC = () => {
  const history = useHistory();
  const searchParams = useSearchParams<{ companyKey: string } & CompanyData>();
  const user = useSelector(selectUser);
  const companyName = searchParams.companyName;
  const companyDomain = searchParams.domain;
  const [companyKey, setCompanyKey] = useState<string>('');
  const [showOverLay, setShowOverLay] = useState<boolean>(false);
  const [enrichCompany] =
    preauthCompanySlice.endpoints.enrichCompany.useMutation();

  const isCbRequest = Boolean(searchParams.cb_email);
  const isSignupRequest = Boolean(searchParams.companyKey);
  useEffect(() => {
    if (isSignupRequest) {
      setCompanyKey(searchParams.companyKey!); // email/password signup
    } else {
      history.push({
        pathname: SignupPaths.trialSignup,
        search: encodeObjectForQuery(searchParams),
      });
    }
  }, []);

  useEffect(() => {
    if (
      isSignupRequest &&
      user &&
      companyName &&
      companyDomain &&
      window.MktoForms2
    ) {
      window.MktoForms2.loadForm(
        window.BBK_MARKETO_BASE_URL!,
        window.BBK_MARKETO_MUNCHKIN_ID!,
        window.BBK_MARKETO_FORM_ID!,
        (form) => {
          if (form) {
            form.onSuccess(() => {
              form.getFormElem().hide();
              // Return false to prevent the submission handler from taking the lead to the follow up url
              return false;
            });
            const utmFields: { [key: string]: string } = {};
            Object.keys(MARKETO_UTM_FIELDS_QUERY_PARAMS_TO_COOKIE).forEach(
              (key) => {
                const field =
                  MARKETO_UTM_FIELDS_QUERY_PARAMS_TO_COOKIE[
                    key as keyof MarketoUtmFieldsType
                  ];
                utmFields[field.fieldName] = getCookie(field.cookieName);
              }
            );

            form.addHiddenFields({
              // These are the values which will be submitted to Marketo
              Company: companyName,
              Website: companyDomain,
              Email: user.email,
              FirstName: user.details.first_name,
              LastName: user.details.last_name,
              retentionSignupSource: isCbRequest ? 'App Signup' : 'Web Signup',
              ...utmFields,
            });
            form.submit();
          }
        }
      );
    }
  }, [user, companyName, companyDomain, isSignupRequest]);
  const [phoneData, setPhoneData] = useState<CountryData & { value: string }>({
    format: '+. (...) ...-....',
    dialCode: '1',
    countryCode: 'us',
    name: 'United States',
    value: '',
  });

  const schema = Yup.object({
    monthlyCancels: Yup.string().required('Field is required'),
    billingSystem: Yup.string().required('Field is required'),
    businessModel: Yup.string().required('Field is required'),
    industryVertical: Yup.string().required('Field is required'),
    showPhone: Yup.boolean(),
    phone: Yup.string().when('showPhone', {
      is: true,
      then: (sch) =>
        sch
          .required('Field is required')
          .test('phone', 'Must be a valid phone', (val) => {
            const format = phoneData.format; // '+. (...) ...-....' for the US, '+.. .... ......' for UK
            const formatDots = format.replace(/[^.]/g, ''); // '...........' for the US
            return formatDots.length === val.length;
          }),
    }),
  });

  type ValuesSchema = Yup.InferType<typeof schema>;

  const initialValues: ValuesSchema = {
    monthlyCancels: '',
    billingSystem: isCbRequest ? 'chargebee' : '',
    businessModel: '',
    industryVertical: '',
    showPhone: !isCbRequest,
    phone: '',
  };

  const onSubmit: FormikConfig<ValuesSchema>['onSubmit'] = async ({
    monthlyCancels,
    billingSystem,
    businessModel,
    industryVertical,
    phone,
  }) => {
    try {
      BrightbackSession.setActiveCompany({ internal_name: companyKey }, false);
      setShowOverLay(true);
      await enrichCompany({
        company: companyKey,
        monthly_cancels: monthlyCancels,
        billing_system: billingSystem,
        business_model: businessModel,
        industry_vertical: industryVertical,
        phone,
      });
      segmentCustomEvent('User completed company registration.', {
        companyKey,
        monthlyCancels: monthlyCancelMap[monthlyCancels],
        billingSystem: billingSystemMap[billingSystem],
        businessModel: businessModelMap[businessModel],
        industryVertical: industryVerticalMap[industryVertical],
        phone,
      });
    } catch (e) {
      (window.Rollbar ?? console).warn(
        `Trial Sign Up Error: failed to enrich ${companyKey}`
      );
    } finally {
      window.location.pathname = `/company/${companyKey}`;
    }
  };

  return (
    <div className="trial-signup-page">
      <div className="left-col">
        <div>
          <div css={{ textAlign: 'center' }}>
            <TrialSignupLogo />
            <div className="page-title">
              Last thing, help us to set up your account
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={schema}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form>
                {!isCbRequest && (
                  <>
                    <InputLabel css={signupStyles.selectLabelStyles}>
                      What is your business phone?{' '}
                      <ImportantMessage>*</ImportantMessage>
                    </InputLabel>
                    <PhoneInput
                      value={phoneData.value}
                      onChange={(value, countryData: CountryData) => {
                        setPhoneData({ ...countryData, value });
                        setFieldValue('phone', value);
                      }}
                      preferredCountries={['us', 'gb', 'au', 'ca']}
                      preserveOrder={['preferredCountries']}
                      country="us"
                      placeholder={phoneData.format
                        // Replace everything from the beginning to the first space with an empty string
                        // replaces . with 1, 2, 3 etc. '(...) ...-....' -> (123) 456-7891'
                        .replace(
                          /\./g,
                          (() => {
                            let c = 0;
                            return () => {
                              c = (c % 9) + 1;
                              return `${c}`;
                            };
                          })()
                        )}
                      specialLabel=""
                      buttonClass="!tw-border-[#979797]"
                      inputClass={cs(
                        'bbk-simple-text-input w-100 dark-border !tw-py-[7.5px] !tw-h-auto',
                        {
                          error: touched.phone && errors.phone,
                        }
                      )}
                    />
                    <InputError>{touched.phone && errors.phone}</InputError>
                  </>
                )}

                <InputLabel css={signupStyles.selectLabelStyles}>
                  How many customers cancel each month?{' '}
                  <ImportantMessage>*</ImportantMessage>
                </InputLabel>
                <FormikGenericSelect
                  className={cs('w-100 dark-border', {
                    error: touched.monthlyCancels && errors.monthlyCancels,
                  })}
                  name="monthlyCancels"
                  options={monthlyCancelsOptions}
                />
                <InputError>
                  {touched.monthlyCancels && errors.monthlyCancels}
                </InputError>

                {!isCbRequest && (
                  <div>
                    <InputLabel css={signupStyles.selectLabelStyles}>
                      What subscription billing system are you using?{' '}
                      <ImportantMessage>*</ImportantMessage>
                    </InputLabel>
                    <FormikGenericSelect
                      className={cs('w-100 dark-border', {
                        error: touched.billingSystem && errors.billingSystem,
                      })}
                      name="billingSystem"
                      options={billingSystemOptions}
                    />
                    <InputError>
                      {touched.billingSystem && errors.billingSystem}
                    </InputError>
                  </div>
                )}

                <InputLabel css={signupStyles.selectLabelStyles}>
                  What is your business model?{' '}
                  <ImportantMessage>*</ImportantMessage>
                </InputLabel>
                <FormikGenericSelect
                  className={cs('w-100 dark-border', {
                    error: touched.businessModel && errors.businessModel,
                  })}
                  name="businessModel"
                  options={businessModelOptions}
                />
                <InputError>
                  {touched.businessModel && errors.businessModel}
                </InputError>

                <InputLabel css={signupStyles.selectLabelStyles}>
                  What is your industry vertical?{' '}
                  <ImportantMessage>*</ImportantMessage>
                </InputLabel>
                <FormikGenericSelect
                  className={cs('w-100 dark-border', {
                    error: touched.industryVertical && errors.industryVertical,
                  })}
                  name="industryVertical"
                  options={industryVerticalOptions}
                />
                <InputError>
                  {touched.industryVertical && errors.industryVertical}
                </InputError>

                <ButtonV2
                  type="submit"
                  primary
                  fullWidth
                  css={{ margin: '0.5rem 0 1rem 0' }}
                >
                  Start using Chargebee Retention
                </ButtonV2>
              </Form>
            )}
          </Formik>
        </div>
        <TrialSignupSteps step={3} maxSteps={3} />
      </div>

      <div className="right-col tw-text-black">
        <div>
          <div>
            <div className="tw-text-4xl tw-font-extrabold">
              Saving up to 35% of customers from canceling just got way
              easier...
            </div>
            <div className="tw-mt-6 tw-mb-10 tw-font-semibold">
              Launch and test high performing yet delightfully simple retention
              flows in minutes with Chargebee Retention.
            </div>
          </div>
          <img srcSet={`${img} 2x`} alt="Testimonials" className="tw-w-full" />
        </div>
      </div>
      {showOverLay && <PreparingOverlay />}
    </div>
  );
};
