import React from 'react';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import type { ActiveCompany } from '@bbkAdminRedux/app/reducers';

export const CompanyLogo: ReactFCC<{
  company: ActiveCompany;
  className?: string;
}> = ({ company, className }) => {
  const img = `https://www.google.com/s2/favicons?domain=${company.domain}`;

  return <img src={img} alt={company.name} className={className} />;
};
