import { isNil } from 'lodash';
import { LSNotFoundError } from '@bbkJavascript/LSNotFoundError';

// (De)Serialize Localstorage Usage
class LocalStorage {
  namespace: string;

  store: Storage;

  sep: string;

  constructor(namespace: string) {
    this.namespace = namespace;
    this.store = window.localStorage;
    this.sep = '\x00';
  }

  static clear(): void {
    window.localStorage.clear();
  }

  notFound(k: string): LSNotFoundError {
    const err = new LSNotFoundError(`Not Found [${k}]`);
    err.notFound = true;
    err.key = k;
    return err;
  }

  get<T = unknown>(key: string): [LSNotFoundError | Error] | [null, T] {
    const k = this.getFullKey(key);
    const v = this.store[k] as string | null | undefined;

    if (isNil(v)) return [this.notFound(k)];

    try {
      return [null, JSON.parse(v) as T];
    } catch (err) {
      return [err as Error];
    }
  }

  getFullKey(key: string): string {
    return [this.namespace, key].join(this.sep);
  }

  put<T = unknown>(key: string, value: T): [Error] | [null, string] {
    if (typeof value === 'undefined') {
      return [
        new LSNotFoundError(`Invalid parameters to put, ('${key}', undefined)`),
      ];
    }

    try {
      const k = this.getFullKey(key);
      const v = JSON.stringify(value);
      this.store[k] = v;
      return [null, v];
    } catch (err) {
      return [err as Error];
    }
  }

  has(key: string): [Error] | [null, boolean] {
    const k = this.getFullKey(key);
    let v: string | undefined;
    if (this && this.store && k) {
      v = this.store[k] as string;
    }
    if (isNil(v)) {
      return [this.notFound(k)];
    }
    return [null, true];
  }

  del(key: string): [Error] | [null] {
    if (key) {
      const k = this.getFullKey(key);
      if (!this.store[k]) return [this.notFound(k)];

      delete this.store[k];
      return [null];
    }

    Object.keys(window.localStorage).forEach((k) => {
      const ns = k.split(this.sep)[0];
      if (ns === this.namespace) {
        delete this.store[k];
      }
    });

    return [null];
  }

  search(pattern: RegExp): [null, { key?: string; value?: string }[]] {
    if (!pattern) {
      throw new Error('A pattern is required');
    }

    const matchKeys = (key: string): string | undefined => {
      const [, _key] = key.split(this.sep);

      if (!_key) return undefined;
      if (!pattern.test(_key)) return undefined;

      return key;
    };

    const makePairs = (key: string): { key?: string; value?: string } => ({
      key: key.split(this.sep)[1],
      value: this.store[key] as string,
    });

    return [null, Object.keys(this.store).filter(matchKeys).map(makePairs)];
  }
}

export default LocalStorage;

/*

Built off of https://github.com/voltraco/localstorage

```js
const foo = new LocalStorage('foo') // create a `foo` namespace
foo.put('quxx', { foo: 100 })
```

```js
const [err, value] = foo.get('quxx')
value.foo === 100 // true
```

```js
foo.get('quxx') // [null, { foo: 100 }]
foo.get('foo') // [ErrorNotFOund]

foo.has('quxx') // [null, true]
foo.has('foo') // [ErrorNotFound]

foo.delete('quxx') // [null, true]
foo.delete('foo') // [ErrorNotFound]

foo.delete() // delete everything in the `foo` namespace
```

*/
