/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Popover } from '@material-ui/core';
import Icons from '@bbkAdminUtils/icons';
import ActionButton from '@bbkAdminComponents/buttons/action-button';
import Checkbox from '@bbkAdminComponents/switches/checkbox/checkbox';
import RadioButton from '@bbkAdminComponents/switches/radio-button/radio-button';
import './form-select.scss';

export type SelectItem<T = string | number> = {
  id?: T;
  name: string;
  selected?: boolean;
  disabled?: boolean;
};

type Props<T extends SelectItem = SelectItem> = {
  className?: string;
  popoverClassName?: string;
  allItemsSelected?: boolean;
  chevronIcon?: keyof typeof Icons;
  items: T[];
  toggleItem: (item: T | null | { name: 'all' }) => void;
  placeholder: string;
  label?: React.ReactNode;
  noSelectionLabel?: React.ReactNode;
  multiple?: boolean;
  disabled?: boolean;
  clear?: boolean;
  popoverProps?: Omit<React.ComponentProps<typeof Popover>, 'open'>;
};

const FormSelect: React.FC<Props> = (props) => {
  const {
    chevronIcon = 'doubleChevron',
    items,
    allItemsSelected = false,
    toggleItem,
    label = 'Select',
    noSelectionLabel = '',
    className,
    popoverClassName,
    multiple,
    disabled,
    clear,
    placeholder,
    popoverProps,
  } = props;

  const popoverRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const activatePicker = () => {
    setOpen(true);
  };

  const deactivatePicker = () => {
    setOpen(false);
  };

  const onItemToggle: typeof toggleItem = (selectedItem) => {
    toggleItem(selectedItem);
    if (!multiple) {
      deactivatePicker();
    }
  };

  const renderPlaceholder = (): React.ReactNode => {
    return (
      <div className="bbk-fs-item-no-items bbk-fs-item-group">
        {placeholder}
      </div>
    );
  };

  const renderDropdown = (): React.ReactNode => {
    return (
      <>
        {multiple && (
          <div className="bbk-fs-item bbk-fs-item-all">
            <RadioButton
              handleClick={() => onItemToggle({ name: 'all' })}
              checked={allItemsSelected}
              label="All"
              classes="small"
              labelClass={classNames({
                'font-bold': allItemsSelected,
              })}
            />
          </div>
        )}
        <div className="bbk-fs-item-group">
          {items.map((item) => (
            <div
              key={item.name}
              className={classNames('bbk-fs-item', {
                'bbk-fs-item-selected': item.selected,
                'bbk-fs-item-disabled': item.disabled,
              })}
            >
              {multiple ? (
                <Checkbox
                  label={item.name}
                  checked={item.selected}
                  handleChange={() => onItemToggle(item)}
                  classes="black small"
                  labelClass={classNames({
                    'font-bold': item.selected,
                  })}
                />
              ) : (
                <span
                  className={classNames('block bbk-fs-item-label', {
                    'font-bold': item.selected,
                  })}
                  onClick={item.disabled ? undefined : () => onItemToggle(item)}
                >
                  {item.name}
                </span>
              )}
            </div>
          ))}
        </div>
      </>
    );
  };

  let selectedLabel = noSelectionLabel;
  if (allItemsSelected) {
    selectedLabel = 'All';
  } else {
    const selectedItems = items.filter((item) => item.selected);
    const selectedItem1 = selectedItems[0];
    if (selectedItems.length === 1 && selectedItem1) {
      selectedLabel = selectedItem1.name;
    } else if (selectedItems.length >= 2) {
      selectedLabel = `${selectedItems.length} selected`;
    }
  }

  return (
    <div
      className={classNames('bbk-form-select', className, {
        'bbk-form-select-multiple': multiple,
      })}
    >
      <button
        className={classNames('bbk-form-select-button', {
          'bbk-fsb-active': open,
        })}
        type="button"
        ref={popoverRef}
        onClick={activatePicker}
        disabled={disabled}
      >
        {label}
        <div className="bbk-fsb-text">
          <span className="bbk-fs-selected-item-text">{selectedLabel}</span>
        </div>
        <div className="bbk-fsb-icon">{Icons[chevronIcon]}</div>
      </button>
      <Popover
        classes={{
          paper: classNames('bbk-fs-popover', popoverClassName, {
            'bbk-fs-popover-multiple': multiple,
          }),
        }}
        anchorEl={popoverRef.current}
        onClose={deactivatePicker}
        open={open}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        {...popoverProps}
      >
        <div className="bbk-po-menu no-pad">
          <div className="bbk-fs-popover-inner-container">
            {items.length < 1 ? renderPlaceholder() : renderDropdown()}

            {multiple && (
              <div className="bbk-fs-item-done">
                <ActionButton
                  title="Done"
                  onClick={deactivatePicker}
                  classes="brightPurple"
                />
              </div>
            )}
            {clear && (
              <div className="bbk-fs-item-done">
                <ActionButton
                  title="Clear"
                  onClick={() => onItemToggle(null)}
                  classes="brightPurple fullWidth"
                />
              </div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default FormSelect;
