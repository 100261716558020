// @ts-nocheck
import type { ComponentProps } from 'react';
import React from 'react';
import type {
  HeroImageV1,
  LossActivity,
  LossQuote,
  OtherActionGenerics,
  RestModalInfo,
  RestModalPerson,
  RestQuote,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import TextArea from '@bbkAdminComponents/inputs/textarea/textarea';
import FileInputPreview from '@bbkAdminComponents/inputs/file/file-input-preview';
import IconPicker from '@bbkAdminComponents/icon-picker/icon-picker';
import Checkbox, {
  CheckboxLabel,
} from '@bbkAdminComponents/switches/checkbox/checkbox-v2';
import Icons from '@bbkAdminUtils/icons';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { FeatureFlagsE } from '@bbkAdminUtils/feature-flags';
import type { RTEOutputType } from '@bbkAdminComponents/rich-text-editor';
import { RichTextEditor } from '@bbkAdminComponents/rich-text-editor';
import GenericSelect from '@bbkAdminComponents/select/select';
import type { PatchPath, Paths } from '@bbkAdminUtils/utility-types';
import ColorSelector from '@bbkAdminComponents/color-picker/color-selector';
import { useAsyncThemeData } from '@bbkAdminComponents/async-theme-provider';


type GenericProps = { [restEditorProp: string]: any };
type RenderProps<T extends GenericProps = GenericProps> = {
  value: any;
  patchPath: PatchPath;
  onChange: (patchPath: PatchPath, value: any) => void;
  label?: string;
  featureFlags?: string[];
  outputType?: RTEOutputType;
} & T;

export type Editor<T extends GenericProps = GenericProps> = {
  label?: string;
  nodeType?: string;
  node?: (renderProps: RenderProps<T>) => React.ReactNode;
  helpLabel?: React.ReactNode;
} & T;

export type GroupLayoutItem<T, K = Paths<T>> = {
  key: K;
  isArray?: boolean;
  hidden?: boolean | ((renderProps: Pick<RenderProps, 'value'>) => boolean);
  editor?: Editor;
  isActive?: (featureFlags: FeatureFlagsE[]) => boolean;
  label?: string;
  layout: GroupLayoutItem[];
  isCollapse?: boolean;
};

export type GroupLayout<T = any> = GroupLayoutItem<T>[];

export const textEditorConfig: Editor<ComponentProps<typeof RichTextEditor>> = {
  node({
    value,
    patchPath,
    onChange,
    suggestions,
    urlSuggestions,
    enabledButtons,
    outputType,
    featureFlags = [],
    toolbarAtTop = false,
  }) {
    const activateLinkButton = featureFlags.includes(
      FeatureFlagsE.ACTIVATE_TEXT_EDITOR_LINK_BUTTON
    );

    return (
      <RichTextEditor
        value={value}
        onChange={(newValue) => {
          // console.log('rtev2 value', newValue);
          onChange(patchPath, newValue);
        }}
        suggestions={suggestions}
        urlSuggestions={urlSuggestions}
        enabledButtons={{
          link: activateLinkButton,
          ...enabledButtons,
        }}
        outputType={outputType}
        toolbarAtTop={toolbarAtTop}
      />
    );
  },
};

export const urlTextEditorConfig: typeof textEditorConfig = {
  ...textEditorConfig,
  enabledButtons: {
    bold: false,
    italic: false,
    underline: false,
    link: false,
  },
  stripDivWrapperOnChange: true,
  outputType: 'plaintext',
  fieldType: 'url',
};

export const textAreaConfig: Editor = {
  node({ value, patchPath, onChange }) {
    return (
      <TextArea
        value={value}
        onChange={(e) => onChange(patchPath, e.target.value)}
        enableLengthWarnings={false}
      />
    );
  },
};

export const textInputConfig: Editor = {
  node({ value, patchPath, onChange }) {
    return (
      <input
        type="text"
        className="bbk-simple-text-input"
        value={value}
        onChange={(e) => onChange(patchPath, e.target.value)}
      />
    );
  },
};

export const selectConfig: Editor = {
  node({ value, patchPath, onChange, opts, defaultValue }) {
    const val = value ?? defaultValue;
    return (
      <GenericSelect
        options={opts}
        value={opts.find((o) => o.value === val)}
        onChange={(e) => onChange(patchPath, e.value)}
      />
    );
  },
};

export const booleanConfig: Editor = {
  nodeType: 'checkbox',
  node({ value, patchPath, onChange, label = 'Select' }) {
    return (
      <CheckboxLabel>
        <Checkbox
          checked={value}
          onChange={(e) => {
            onChange(patchPath, e.target.checked);
          }}
        />
        {label}
      </CheckboxLabel>
    );
  },
};

export const offerCardPackBadgeColorPicker: Editor = {
  nodeType: 'colorSelector',
  node({value, patchPath, onChange}) {
     const {data: theme} = useAsyncThemeData();
    const options = _.get(theme, 'offerCardBadgeColors',[]);
    return (
      <ColorSelector options={options} onChange={(val) => onChange(patchPath, val)} value={value}/>
    );
  }
}
export const offerCardPackBadgeTextColorPicker: Editor = {
  nodeType: 'colorSelector',
  node({value, patchPath, onChange}) {
     const {data: theme} = useAsyncThemeData();
    const options = _.get(theme, 'offerCardBadgeTextColors', []);
    return (
      <ColorSelector options={options} onChange={(val) => onChange(patchPath, val)} value={value}/>
    );
  }
}

export const defaultTrueBooleanConfig: Editor = {
  nodeType: 'checkbox',
  node({ value = true, patchPath, onChange, label = 'Select' }) {
    return (
      <CheckboxLabel>
        <Checkbox
          checked={value}
          onChange={(e) => {
            onChange(patchPath, e.target.checked);
          }}
        />
        {label}
      </CheckboxLabel>
    );
  },
};

export const imageEditorConfig: Editor = {
  label: 'Image',
  node({ value, patchPath, onChange }) {
    return (
      <FileInputPreview
        url={value}
        onChange={({ fileUrl }) => onChange(patchPath, fileUrl)}
      />
    );
  },
};

export const iconEditorConfig: Editor = {
  node({ value, patchPath, onChange }) {
    return (
      <IconPicker
        selectedIconId={value}
        iconSelect={(chosenIcon: { icon: string }) => {
          onChange(patchPath, chosenIcon.icon);
        }}
        enableClear
      />
    );
  },
};

export const personGroupLayout: GroupLayout<RestModalPerson> = [
  {
    key: 'avatar',
    editor: {
      ...imageEditorConfig,
      helpLabel: 'Recommended size: 250x250px; < 100kB',
    },
  },
  {
    key: 'name',
    editor: {
      label: 'Name',
    },
  },
  {
    key: 'title',
    editor: {
      label: 'Title',
    },
  },
  {
    key: 'preposition',
    editor: {
      label: 'Preposition',
    },
  },
  {
    key: 'organization',
    editor: {
      label: 'Company',
    },
  },
];

export const detailGroupLayout: GroupLayout<RestModalInfo> = [
  {
    key: 'title',
    editor: {
      label: 'Title',
      ...textEditorConfig,
      enabledButtons: {
        bold: false,
        italic: false,
        underline: false,
        link: false,
      },
    },
  },
  {
    key: 'details',
    editor: {
      label: 'Description',
      ...textEditorConfig,
    },
  },
];

export const offerCardPackPriceEditorLayout1 = [
  {
    key: 'current_price',
    editor: {
      label: 'Current Price',
      ...textEditorConfig,
    }
  },{
    key: 'new_price',
    editor: {
      label: 'New Price',
      ...textEditorConfig,
    },
  },
  {
    key: 'hide_new_price',
    editor: {
      label: 'Hide New Price',
      ...booleanConfig,
      hideGroupLabel: true,
    },
  },
  {
    key: 'frequency',
    editor: {
      ...textEditorConfig,
    },
  },
  {
    key: 'hide_price_section',
    editor: {
      label: 'Hide Price',
      ...booleanConfig,
      hideGroupLabel: true,
    }
  }
];

export const offerCardPackPriceEditorLayout2 = [
  {
    key: 'current_price',
    editor: {
      label: 'Current Price',
      ...textEditorConfig,
    }
  },
  {
    key: 'frequency',
    editor: {
      ...textEditorConfig,
    }
  },
  {
    key: 'hide_price_section',
    editor: {
      label: 'Hide Price',
      ...booleanConfig,
      hideGroupLabel: true,
    }
  }
]

export const offerCardPackDisclaimer1Editor = [
  {
    key: 'disclaimer1',
    editor: {
      label: 'Disclaimer',
      ...textEditorConfig,
    },
  },
  {
    key: 'hide_disclaimer1',
    editor: {
      label: 'Hide Disclaimer',
      ...booleanConfig,
      hideGroupLabel: true,
    }
  }
]

export const offerCardPackDisclaimer2Editor = [
  {
    key: 'disclaimer2',
    editor: {
      label: 'Disclaimer',
      ...textEditorConfig,
    },
  },
  {
    key: 'hide_disclaimer2',
    editor: {
      label: 'Hide Disclaimer',
      ...booleanConfig,
      hideGroupLabel: true,
    }
  }
]

export const offerCardPackBadgeEditorLayout = [
  {
    key: 'title',
    editor: {
      label: 'title',
      ...textEditorConfig,
    }
  },{
    key: 'badge_color',
    editor: {
      label: 'Badge',
      ...offerCardPackBadgeColorPicker,
    }
  },{
    key: 'badge_text_color',
    editor: {
      label: 'Text',
      ...offerCardPackBadgeTextColorPicker,
    }
  },{
    key: 'hide_badge',
    editor: {
      label: 'Hide Badge',
      ...booleanConfig,
      hideGroupLabel: true,
    },
  },
];

export const offerCardPackDescEditorLayout = [
  {
    key: 'description',
    editor: {
      label: 'Description',
      ...textEditorConfig,
    }
  },{
    key: 'hide_description',
    editor: {
      label: 'Hide Description',
      ...booleanConfig,
      hideGroupLabel: true,
    },
  },
];

export const offerCardPackActivityEditorLayout = [
  {
    key: 'bullet_image',
    editor: {
      label: 'Bullet Image (upload image)',
      ...imageEditorConfig
    }
  },{
    key: 'bullet_icon',
    editor: {
      label: 'Bullet Icon (pick from the list)',
      ...iconEditorConfig
    }
  },{
    key: 'item_list',
    isArray: true,
    editor: {
        ...textEditorConfig,
        toolbarAtTop: true
    },
  },{
    key: 'hide_activity',
    editor: {
      label: 'Hide Section',
      ...booleanConfig,
      hideGroupLabel: true,
    }
  }
];

export const quoteGroupLayout: GroupLayout<RestQuote> = [
  { key: 'avatar', editor: imageEditorConfig },
  { key: 'name' },
  { key: 'title' },
  { key: 'text', editor: textEditorConfig },
];

export const lossQuoteGroupLayout: GroupLayout<LossQuote> = [
  { key: 'avatar', editor: imageEditorConfig },
  {
    key: 'author',
    editor: {
      ...textEditorConfig,
      stripDivWrapperOnChange: true,
    },
  },
  {
    key: 'title',
    editor: {
      ...textEditorConfig,
      stripDivWrapperOnChange: true,
    },
  },
  {
    key: 'position',
    editor: {
      ...textEditorConfig,
      stripDivWrapperOnChange: true,
    },
  },
  { key: 'quote', editor: textEditorConfig },
];

export const lossActivityGroupLayout: GroupLayout<LossActivity> = [
  {
    key: 'image',
    editor: {
      ...imageEditorConfig,
      label: 'Image',
      helpLabel: 'Recommended width and height: 22px',
    },
  },
  { key: 'icon', editor: iconEditorConfig },
  {
    key: 'text',
    editor: {
      ...textEditorConfig,
      stripDivWrapperOnChange: true,
    },
  },
  {
    key: 'volume',
    editor: {
      ...textEditorConfig,
      stripDivWrapperOnChange: true,
    },
  },
];

export const lossActivityTweetProfile: GroupLayout<LossActivity> = [
  {
    key: 'pic',
    editor: imageEditorConfig,
  },
  {
    key: 'name',
  },
  {
    key: 'handle',
  },
];

export const heroImageGroupLayout: GroupLayout<HeroImageV1> = [
  {
    key: 'url',
    editor: {
      ...imageEditorConfig,
      helpLabel: 'Recommended size: 700x700px; < 250kB',
    },
  },
];

export const heroImageGroupForLCOModalLayout: GroupLayout<HeroImageV1> = [
  {
    key: 'url',
    editor: {
      ...imageEditorConfig,
      helpLabel: 'Recommended size: 500x500px; < 250kB',
    },
  },
];
export const heroImageGroupForOfferCardPackLayout: GroupLayout<HeroImageV1> = [
  {
    key: 'url',
    editor: {
      ...imageEditorConfig,
      helpLabel: 'Recommended size: 500x500px; < 250kB',
    },
  },
  {
    key: 'hide_image',
    editor: {
      ...booleanConfig,
      label: 'Hide image',
      hideGroupLabel: true,
    },
  },
];

export const heroImageGroupForLCOPopupLayout: GroupLayout<HeroImageV1> = [
  {
    key: 'url',
    editor: {
      ...imageEditorConfig,
      helpLabel: 'Recommended size: 300x200px; < 250kB',
    },
  },
];

export const heroImageGroupForLCOBannerLayout: GroupLayout<HeroImageV1> = [
  {
    key: 'url',
    editor: {
      ...imageEditorConfig,
      helpLabel: 'Recommended size: 150x70px; < 250kB',
    },
  },
];

export const otherActionsGenerics: GroupLayout<OtherActionGenerics> = [
  {
    key: 'includeOr',
    editor: {
      label: 'Include additional action label',
      ...booleanConfig,
    },
  },
  {
    key: 'includeOrText',
    editor: {
      label: 'Additional action label text',
    },
  },
];

export const createOtherActionItem: GroupLayout<OtherActionGenerics> = [
  {
    key: 'other_actions',
    editor: {
      node({ onClick }) {
        return (
          <ButtonV2
            onClick={onClick}
            css={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '0.5rem',
              marginBottom: '1rem',
              svg: {
                width: 16,
                height: 16,
                marginRight: 3,
              },
            }}
          >
            {Icons.plus} Add new additional action
          </ButtonV2>
        );
      },
    },
  },
];

export const structuredContentOptionsLayout = [
  {
    key: 'options',
    isArray: true,
  },
  {
    key: 'allowOther',
    editor: {
      label: 'Allow free-form response entry',
      ...booleanConfig,
    },
  },
  { key: 'placeholder', label: 'Placeholder text in other text input' },
];

export const lossConfigurationGroup = {
  label: 'Configuration',
  isCollapse: true,
  isActive: () => true,
  layout: [
    {
      key: 'disableOnMobile',
      editor: {
        ...booleanConfig,
        label: 'Disabled',
        groupLabel: 'Disabled on mobile',
      },
    },
  ],
};
