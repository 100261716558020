import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectCompany, selectUser } from '@bbkAdminRedux/app/selectors';
import BrightbackSession from '@bbkAdminUtils/brightback-session';
import { LoggedOutRouter } from '@bbkAdminRouter/logged-out.router';
import '@bbkAdminStylesheets/authRoot.scss';
import { AppRoot } from '@bbkAdminComponents/app-layout/app-root';
import {
  currentUserSlice,
  useIdentifyUser,
} from '@bbkAdminRedux/rtkq/current-user.slice';
import { useAppInit } from '@bbkAdminRedux/app/hooks';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import { usePollAndRefreshToken } from '@bbkAdminRedux/rtkq/preauth-company.slice';
import { SignupPaths } from '@bbkAdminRouter/signup-paths';
import { recordError } from '@bbkAdminUtils/recordError';
import { NoApps } from '@bbkAdminRouter/no-apps.page';

export const AuthRoot: ReactFCC = ({ children }) => {
  const pathname = useLocation().pathname;
  usePollAndRefreshToken();
  useIdentifyUser();
  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);

  const [fetchUser$] = currentUserSlice.endpoints.getCurrentUser.useLazyQuery();
  const appInit = useAppInit();

  useEffect(() => {
    fetchUser$(undefined, true)
      .then((u) => appInit(u.data))
      .catch(recordError);
  }, [appInit, fetchUser$]);

  const token = useMemo(() => BrightbackSession.getAccessToken(), []);

  useEffect(() => {
    if (
      pathname === SignupPaths.trialSignup ||
      pathname === SignupPaths.trialSignup2
    ) {
      BrightbackSession.clear();
      BrightbackSession.setActiveCompany(undefined, false);
      if (token) {
        window.location.reload();
      }
    }
  }, [pathname]);

  if (!token) {
    return <LoggedOutRouter>{children}</LoggedOutRouter>;
  }

  if (company && company.applications.length === 0) {
    return <NoApps company={company} />;
  }

  if (!user) {
    return null;
  }
  return (
    <div className="bbk-authRoot">
      <AppRoot>{children}</AppRoot>
    </div>
  );
};
