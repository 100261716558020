import React from 'react';
import classNames from 'classnames';
import Icon from '@bbkAdminComponents/icons/icon-generator';
import './styles/buttons.scss';

type Props = {
  title: React.ReactNode;
  openModal?: React.MouseEventHandler<HTMLButtonElement>;
};
const ArrowButton: React.FC<Props> = ({ openModal, title }) => (
  <button
    className={classNames(
      'bbk-button',
      'primary',
      'arrowButton',
      'brightPurple'
    )}
    onClick={openModal}
  >
    <span className="text">{title}</span>
    <Icon name="arrow" />
  </button>
);

export default ArrowButton;
