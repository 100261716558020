import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import {
  selectApp,
  selectCompany,
  selectUser,
} from '@bbkAdminRedux/app/selectors';
import { useSelectBillingIntegration } from '@bbkAdminRedux/rtkq/integrations.slice';
import { useRoles } from '@bbkAdminUtils/roles';
import { billingConfigSlice } from '@bbkAdminRedux/rtkq/billing-config.slice';
import BrightbackSession from '@bbkAdminUtils/brightback-session';
import {
  ApplicationType,
  CompanyType,
} from '@bbkInternalComponents/companies/company/company';
import { ActiveApp, ActiveCompany } from '@bbkAdminRedux/app/reducers';

export const isPendoEnabled = (company: ActiveCompany, app: ActiveApp) =>
  company.active &&
  app.active &&
  company.company_type !== 'internal' &&
  app.application_type === 'production';

export const usePendo = () => {
  const user = useSelector(selectUser);
  const app = useSelector(selectApp);
  const company = useSelector(selectCompany);
  const billingIntegration = useSelectBillingIntegration({
    appKey: app.encoded_id,
  });
  const { hasBillingRole } = useRoles();

  const { data: billingConfig } =
    billingConfigSlice.endpoints.getBillingConfig.useQuery(
      billingIntegration ? { app_key: app.encoded_id } : skipToken
    );

  const enablePendo = isPendoEnabled(company, app);

  useEffect(() => {
    if (!enablePendo) {
      console.log(
        `Pendo is not installed on ${company.internal_name} ${app.encoded_id}.`
      );
      return;
    }

    const pendoVisitorPayload = {
      id: `retention_${user.id}`,
      email: user.email,
      full_name: user.details.display_name,
      role: hasBillingRole ? 'billing' : 'user',
      is_chargebee_user:
        user.email.indexOf('chargebee.com') !== -1 ||
        user.email.indexOf('brightback.com') !== -1,
      domain: `${BrightbackSession.getActiveCompany()}_${BrightbackSession.getActiveApp()}`,
    };

    const pendoAccountPayload = {
      id: app.encoded_id,
      account_name: `${company.name} (${app.display_name})`,
      domain: `${company.internal_name}_${app.encoded_id}`,
      merchant_id: company.external_billing_id ?? null,
      merchant_type: company.company_type,
      site_type: app.application_type,

      plan_invoice_name: company.plan ?? null,
      account_claimed_at: company.created_at,

      is_trial: company.trial ?? false,
      ...(company.trial && { trial_end: company.trial_end_date }),
      ...(billingIntegration && {
        billing_integration: billingIntegration.data_type,
        ...(billingConfig && {
          enrich: billingConfig.enrich,
          manage_cancels: billingConfig.manage_cancels,
          manage_offers: billingConfig.manage_offers,
          attribution: billingConfig.attribution,
          repurchase_based_attribution:
            billingConfig.repurchase_based_attribution,
          cancel_all_subscriptions: billingConfig.cancel_all_subscriptions,
          apply_to_all_pages: billingConfig.apply_to_all_pages,
          enable_bypass: billingConfig.enable_bypass ?? false,
          ...(billingConfig.cancel_config?.type === 'stripe' && {
            cancel_end_of_term: true,
          }),
          ...(billingConfig.cancel_config?.type === 'recurly' && {
            cancel_end_of_term:
              billingConfig.cancel_config.cancel_type === 'cancel_subscription',
          }),
          ...(billingConfig.cancel_config?.type === 'chargebee' && {
            cancel_end_of_term: billingConfig.cancel_config.end_of_term,
          }),
          ...(billingConfig.cancel_config?.type === 'recharge' && {
            cancel_end_of_term: billingConfig.cancel_config.end_of_term,
          }),
        }),
      }),
    };

    window.pendo?.initialize({
      visitor: pendoVisitorPayload,
      account: pendoAccountPayload,
    });

    if (window.pendo?.validateInstall) {
      console.log(window.pendo.validateInstall());
    }
  }, [
    enablePendo,
    company,
    app,
    user,
    billingIntegration,
    billingConfig,
    hasBillingRole,
  ]);
};

export type TrackPayload = {
  event: string;
  properties: Record<string, string>;
};

const pendoTrack = (trackPayload: TrackPayload) => {
  console.log('Pendo Track Event', trackPayload.event);
  window.pendo?.track(trackPayload.event, trackPayload.properties);
};

export const usePendoTrack = () => {
  const app = useSelector(selectApp);
  const company = useSelector(selectCompany);
  const enablePendo = isPendoEnabled(company, app);
  if (!enablePendo) return;
  return pendoTrack;
};
