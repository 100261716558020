/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import classnames from 'classnames';
import _ from 'lodash';
import Fade from '@bbkUtils/fade';
import RemainingCounter from '@bbkAdminComponents/inputs/remaining-counter/remaining-counter';
import './textarea.scss';
import '../simple-text/simple-text-input.scss';

type Props = Omit<TextareaAutosize.Props, 'onChange' | 'value'> & {
  value: string | undefined;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  enableLengthWarnings?: boolean;
  classes?: string;
  handleKeyPress?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  minRows?: number;
};

const TextArea: React.FC<Props> = (props) => {
  const {
    value,
    onChange,
    classes,
    id,
    name,
    disabled,
    handleKeyPress,
    onBlur,
    onFocus,
    enableLengthWarnings = true,
    placeholder = 'Start typing...',
    minRows = 2,
    maxRows = 4,
    maxLength,
    defaultValue,
  } = props;

  const determineRemainingLength = () => {
    const returnStatus = {
      remaining: maxLength,
    };
    if (_.isNil(maxLength) || _.isNil(value) || value === '') {
      return returnStatus;
    }

    const remainingCharacters = maxLength - value.length;
    returnStatus.remaining = remainingCharacters;
    return returnStatus;
  };

  const remaining = determineRemainingLength();
  return (
    <div className="bbk-text-area-container">
      <TextareaAutosize
        value={value}
        defaultValue={defaultValue}
        className={classnames('bbk-text-area bbk-simple-text-input', classes)}
        onChange={onChange}
        name={name}
        id={id}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyUp={handleKeyPress}
        placeholder={placeholder}
        rows={minRows}
        maxRows={maxRows}
      />
      <Fade in={enableLengthWarnings} duration={150}>
        <RemainingCounter {...remaining} />
      </Fade>
    </div>
  );
};

export default TextArea;
