import { useMemo } from 'react';
import moment from 'moment';
import { usePlans } from '@bbkAdminUtils/plans';

const currentTime = moment.utc().startOf('day');
const weekAgo = moment(currentTime).subtract(1, 'week').startOf('week');
const monthAgo = moment(currentTime).subtract(1, 'month').startOf('month');
const quarterAgo = moment(currentTime)
  .subtract(1, 'quarter')
  .startOf('quarter');
const yearAgo = moment(currentTime).subtract(1, 'year').startOf('year');

// End periods should be the "day", not the end of the day
// which is what moment provides back by default. Since we use
// day as the smallest chunk, time comparison drifts if not selecting the start of the day.
const week: [Date, Date] = [
  moment(currentTime).startOf('week').toDate(),
  moment(currentTime).endOf('week').toDate(),
];
const month: [Date, Date] = [
  moment(currentTime).startOf('month').toDate(),
  moment(currentTime).endOf('month').toDate(),
];
const quarter: [Date, Date] = [
  moment(currentTime).startOf('quarter').toDate(),
  moment(currentTime).endOf('quarter').toDate(),
];
const year: [Date, Date] = [
  moment(currentTime).startOf('year').toDate(),
  moment(currentTime).endOf('year').toDate(),
];

const lastWeek: [Date, Date] = [
  weekAgo.toDate(),
  weekAgo.endOf('week').toDate(),
];
const lastMonth: [Date, Date] = [
  monthAgo.toDate(),
  monthAgo.endOf('month').toDate(),
];
const lastQuarter: [Date, Date] = [
  quarterAgo.toDate(),
  quarterAgo.endOf('quarter').toDate(),
];
const lastYear: [Date, Date] = [
  yearAgo.toDate(),
  yearAgo.endOf('year').toDate(),
];

const last7Days: [Date, Date] = [
  moment(currentTime).subtract(7, 'days').toDate(),
  moment(currentTime).endOf('day').toDate(),
];
const last30Days: [Date, Date] = [
  moment(currentTime).subtract(30, 'days').toDate(),
  moment(currentTime).endOf('day').toDate(),
];
const last90Days: [Date, Date] = [
  moment(currentTime).subtract(90, 'days').toDate(),
  moment(currentTime).endOf('day').toDate(),
];

export const DEMO_RANGE: [Date, Date] = [
  moment(currentTime).subtract(210, 'days').toDate(),
  moment(currentTime).endOf('day').toDate(),
];

export enum TimeRangesEnum {
  'DAY-6' = 'DAY-6',
  'DAY-29' = 'DAY-29',
  'DAY-89' = 'DAY-89',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
  'WEEK-1' = 'WEEK-1',
  'MONTH-1' = 'MONTH-1',
  'QUARTER-1' = 'QUARTER-1',
  'YEAR-1' = 'YEAR-1',
}

export const TimeRanges: Record<TimeRangesEnum, [Date, Date]> & {
  CUSTOM: [];
} = {
  'DAY-6': last7Days,
  'DAY-29': last30Days,
  'DAY-89': last90Days,
  WEEK: week,
  MONTH: month,
  QUARTER: quarter,
  YEAR: year,
  'WEEK-1': lastWeek,
  'MONTH-1': lastMonth,
  'QUARTER-1': lastQuarter,
  'YEAR-1': lastYear,
  CUSTOM: [],
};

export type RangeKeys = keyof typeof TimeRanges;

export type Period = {
  name: string;
  value: RangeKeys;
  default?: boolean;
  disabled?: boolean;
  selected?: boolean;
};

export const DefaultTimePeriod = {
  name: 'Last 30 days',
  value: TimeRangesEnum['DAY-29'],
  default: true,
};
export const TimePeriods: Period[] = [
  { name: 'Last 7 days', value: TimeRangesEnum['DAY-6'] },
  DefaultTimePeriod,
  { name: 'Last 90 days', value: TimeRangesEnum['DAY-89'] },
  { name: 'This Week', value: TimeRangesEnum.WEEK },
  { name: 'Last Week', value: TimeRangesEnum['WEEK-1'] },
  { name: 'This Month', value: TimeRangesEnum.MONTH },
  { name: 'Last Month', value: TimeRangesEnum['MONTH-1'] },
  { name: 'This Quarter', value: TimeRangesEnum.QUARTER },
  { name: 'Last Quarter', value: TimeRangesEnum['QUARTER-1'] },
  { name: 'This Year', value: TimeRangesEnum.YEAR },
  { name: 'Last Year', value: TimeRangesEnum['YEAR-1'] },
  { name: 'Custom', value: 'CUSTOM' },
];

export const StarterFreeDisabledPeriods: RangeKeys[] = [
  TimeRangesEnum['DAY-89'],
  TimeRangesEnum['MONTH-1'],
  TimeRangesEnum.QUARTER,
  TimeRangesEnum['QUARTER-1'],
  TimeRangesEnum.YEAR,
  TimeRangesEnum['YEAR-1'],
  'CUSTOM',
];
export const useSelectTimePeriods = () => {
  const { isStarterFreePlan } = usePlans();

  return useMemo(() => {
    const showAllTimeRanges = !isStarterFreePlan;
    return showAllTimeRanges
      ? TimePeriods
      : TimePeriods.map((p) => {
          if (StarterFreeDisabledPeriods.includes(p.value)) {
            return { ...p, disabled: true };
          }
          return p;
        });
  }, [isStarterFreePlan]);
};

/**
 * Checks if time period key is CUSTOM
 */
export function isCustomTimePeriod(timeAgoKey: RangeKeys): boolean {
  return timeAgoKey === 'CUSTOM';
}

type SearchObj = {
  timeAgoKey: RangeKeys;
  startDate?: moment.MomentInput;
  endDate?: moment.MomentInput;
};

/**
 * Searching for matching time period
 */
export function resolveTimePeriod(searchObj: SearchObj): Period {
  const timePeriods = TimePeriods;
  const { timeAgoKey, startDate, endDate } = searchObj;

  if (isCustomTimePeriod(timeAgoKey)) {
    const momentStart = moment.utc(startDate);
    const momentEnd = moment.utc(endDate);
    const format = 'MMM D, YYYY';
    return {
      name: `${momentStart.format(format)} - ${momentEnd.format(format)}`,
      value: 'CUSTOM',
    };
  }

  const timePeriod = timePeriods.find((r) => r.value === timeAgoKey);
  if (timePeriod) {
    timePeriod.name = timePeriod.name.toLowerCase();
    return timePeriod;
  }

  return timePeriods.find((r) => r.default) || timePeriods[0]!;
}

export type TimePickerStructure = {
  timeAgoKey: RangeKeys;
  startDate: Date;
  endDate: Date;
};
