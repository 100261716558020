/* eslint-disable react/require-default-props */
import React from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';

const durationDefault = 300;

type Props = {
  in: boolean;
  duration?: number;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
};
const Fade: ReactFCC<Props> = ({
  in: inProp,
  children,
  duration = durationDefault,
  mountOnEnter,
  unmountOnExit,
}) => {
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles: Partial<
    Record<TransitionStatus, React.CSSProperties>
  > = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <Transition
      in={inProp}
      timeout={duration}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
    >
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

Fade.defaultProps = {
  mountOnEnter: false,
  unmountOnExit: false,
};

export default Fade;
