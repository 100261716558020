import React from 'react';
import RoundButton from '@bbkAdminComponents/buttons/round-button';
import { TourStoryProps } from './tour-story-index';

const ResponsiveImage = () => (
  <img src="/assets/images/tour/build-a-page.png" />
);

const ProductTourStoryBuildAPage = ({ onClick }: TourStoryProps) => (
  <div className="bbk-product-tour-story-template bbk-product-tour-story-build-a-page">
    <article>
      <h1>Build a page using the Experience Manager</h1>
      <figure>
        <ResponsiveImage />
      </figure>
      <p>
        The <strong>Experience Manager</strong> is where you brand your
        experience, manage multiple pages, and target your cancel experience.
        The Offer, Loss Aversion and Survey Reason libraries empower you to
        update content across single or multiple pages, while Branding is
        defined centrally and inherited across all your pages. To get started,
        you will need to:
      </p>
      <div className="columns">
        <div>
          <ul className="small">
            <li>Update your branding</li>
            <li>Add cancel reasons to the Survey Reason Library</li>
            <li>
              Personalize your page by embedding dynamic fields (e.g. “Guy, I’m
              sorry to see you go…”)
            </li>
          </ul>
        </div>
        <div>
          <ul className="small">
            <li>
              Manage loss aversion content (“LA Cards”) to persuade people to
              stay with your product or service
            </li>
            <li>Create offers to add to your pages</li>
            <li>Customize survey questions to align with your brand voice</li>
          </ul>
        </div>
      </div>

      <footer>
        <p>
          <RoundButton onClick={onClick}>
            Next: Learn about Test Design
          </RoundButton>
        </p>
      </footer>
    </article>
  </div>
);

export default {
  name: 'Page Setup',
  key: 'bbk-pt-build-a-page',
  story: <ProductTourStoryBuildAPage />,
};
