import { useCallback, useEffect, useMemo } from 'react';
import { type DefaultRootState, useSelector } from 'react-redux';
import BrightbackSession from '@bbkAdminUtils/brightback-session';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import { selectCompanyInternalName } from '@bbkAdminRedux/app/selectors';
import {
  identifyWithAutopilot,
  identifyWithBeacon,
  identifyWithFullStory,
  identifyWithSegment,
  identifyWithSegmentGroup,
} from '@bbkAdminUtils/analytics';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';

export type DateString = string;
export type AppKey = string;
export type CompanyId = number;
export type CompanyKey = string;

export type UserRolesResp = {
  collection: {
    description: string;
    name: string;
  }[];
};
export const currentUserSlice = bbkApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserCompanyRoles: build.query<
      UserRolesResp['collection'],
      { companyKey: string }
    >({
      query: (args) => `/api/v1/${args.companyKey}/user_company_roles`,
      providesTags: ['UserCompanyRoles'],
      transformResponse: (res: UserRolesResp) => res.collection,
    }),
    identifyUser: build.mutation<unknown, unknown>({
      query: (body) => ({
        url: '/api/identify',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const useSelectCurrentUser = () => {
  const { data } = currentUserSlice.endpoints.getCurrentUser.useQuery();

  return useMemo(() => data, [data]);
};

const useSelectUserCompanies = () => {
  const user = useSelectCurrentUser();

  return useMemo(() => user?.companies ?? [], [user]);
};

const useSelectCurrentCompanyKey = () => {
  const companyKey = BrightbackSession.getActiveCompany();

  return useMemo(() => companyKey, [companyKey]);
};

export const useSelectCurrentCompany = () => {
  const companies = useSelectUserCompanies();
  const currentCompanyKey = useSelectCurrentCompanyKey();

  return useMemo(() => {
    const firstCompany = companies[0];
    if (!currentCompanyKey) return firstCompany;

    return (
      companies.find((c) => c.internal_name === currentCompanyKey) ??
      firstCompany
    );
  }, [companies, currentCompanyKey]);
};

export const useSelectCompanyApps = () => {
  const company = useSelectCurrentCompany();

  return useMemo(() => company?.applications ?? [], [company]);
};

const useSelectCurrentAppKey = () => {
  const appEncodedId = BrightbackSession.getActiveApp();

  return useMemo(() => appEncodedId, [appEncodedId]);
};

export const useSelectCurrentApp = () => {
  const apps = useSelectCompanyApps();
  const currentAppKey = useSelectCurrentAppKey();

  return useMemo(() => {
    const firstApp = apps[0];
    if (!currentAppKey) return firstApp;
    return apps.find((a) => a.encoded_id === currentAppKey) ?? firstApp;
  }, [apps, currentAppKey]);
};
export const useIdentifyUser = () => {
  const companyKey = useSelector(selectCompanyInternalName);

  const { isFetching: isUserFetching, isUninitialized: isUserUninitialized } =
    currentUserSlice.endpoints.getCurrentUser.useQuery();
  const user = useSelectCurrentUser();
  const company = useSelectCurrentCompany();
  const application = useSelectCurrentApp();
  const {
    data: roles,
    isFetching: isRolesFetching,
    isUninitialized: isRolesUninitialized,
  } = currentUserSlice.endpoints.getUserCompanyRoles.useQuery(
    { companyKey },
    { skip: companyKey === '_' }
  );
  const [identifyWithBrightback$] =
    currentUserSlice.endpoints.identifyUser.useMutation();

  const traits = useMemo(() => {
    if (isUserFetching || isRolesFetching) return undefined;

    if (
      !isUserUninitialized &&
      !isRolesUninitialized &&
      user &&
      company &&
      application &&
      roles
    ) {
      return {
        user,
        company,
        application,
        roles,
      };
    }
  }, [
    isUserFetching,
    isRolesFetching,
    isUserUninitialized,
    isRolesUninitialized,
    user,
    company,
    application,
    roles,
  ]);

  useEffect(() => {
    if (traits) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { user, company, application, roles } = traits;
      const userId = user.id;
      const userTraits = {
        displayName: `${user.details.first_name} ${user.details.last_name}`,
        email: user.email,
        createdAt: user.created_at,
        roles, // passed in as part of the user object
      };
      const appTraits = {
        application: {
          id: application?.internal_name,
          name: application?.display_name,
        },
      };
      const companyTraits = {
        company: {
          name: company?.internal_name,
          id: company?.id,
          createdAt: company?.created_at,
        },
        company_type: company?.company_type,
        plan: company?.plan,
      };
      const identifyTraits = {
        ...userTraits,
        ...appTraits,
        ...companyTraits,
      };
      identifyWithFullStory(userId, { ...userTraits, ...companyTraits });
      identifyWithSegment(userId, identifyTraits);
      identifyWithBrightback$({
        user: identifyTraits,
        company: {
          internal_name: company.internal_name,
          name: company.name,
          company_type: company.company_type,
          domain: company.domain,
          companyId: company.id,
          active: company.active,
          plan: company.plan,
        },
      }).unwrap();
      identifyWithSegmentGroup(user, company);
      identifyWithBeacon(user, company);
      identifyWithAutopilot(user.email);
    }
  }, [identifyWithBrightback$, traits]);
};

export const selectCurrentUser = (state: DefaultRootState) =>
  // FIXME: remove non-nullable assertion. It actually can be undefined
  currentUserSlice.endpoints.getCurrentUser.select()(state).data!;

export const selectCurrentCompany = (state: DefaultRootState) => {
  const user = selectCurrentUser(state);

  const companies = user?.companies ?? [];
  const companyKey = BrightbackSession.getActiveCompany();

  // FIXME: remove non-nullable assertion. It actually can be undefined
  const firstCompany = companies[0]!;
  if (!companyKey) return firstCompany;

  return companies.find((c) => c.internal_name === companyKey) ?? firstCompany;
};

export const selectCurrentApp = (state: DefaultRootState) => {
  const company = selectCurrentCompany(state);
  const apps = company?.applications ?? [];
  const appEncodedId = BrightbackSession.getActiveApp();

  // FIXME: remove non-nullable assertion. It actually can be undefined
  const firstApp = apps[0]!;
  if (!appEncodedId) return firstApp;
  return apps.find((a) => a.encoded_id === appEncodedId) ?? firstApp;
};
export const useSetBBAuthenticityToken = () => {
  const [getCurrentUser$, { data: currentUser }] =
    currentUserSlice.endpoints.getCurrentUser.useLazyQuery();
  return useCallback(async () => {
    let retries = 10;
    const authToken = currentUser?.authenticity_token;
    while (!authToken || authToken.length <= 1) {
      if (retries-- <= 0) {
        BlackToastTrigger({
          content: 'Error logging in - unable to set authenticity token',
        });
        break;
      }
      try {
        const user = await getCurrentUser$().unwrap();
        if (!user) throw Error('Failed to authenticate.');
        return user;
      } catch (e) {
        BrightbackSession.setActiveCompany(undefined);
        BrightbackSession.setActiveApp(undefined);
      }
    }
  }, [currentUser, getCurrentUser$]);
};
