import type React from 'react';
import type { SelectOption } from '@bbkAdminComponents/select/select';
import { PublishState } from '@bbkAdminUtils/api-client/rest-offers-api';
import { DestinationType } from '@bbkAdminRedux/rtkq/experience.slice';
import type { DateString } from '@bbkAdminRedux/rtkq/current-user.slice';
import type { RestExperience } from '@bbkAdminUtils/api-client/rest-experiences-api';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';

export type ConfirmationFunnel = {
  id: string;
  display_name: string;
  application_id: number;
  active: boolean;
  publish_state: PublishState;
  created_at: DateString;
  updated_at: DateString;
};

export type ConfirmationCard = {
  session: string;
  name: string;
  id: string;
  version: string;
  template: string;
  type: string;
  requires: {
    company: {
      logo: string;
      name: string;
    };
    header: {
      title: string;
      details: string;
    };
    forwards: {
      text: string;
      type: string;
      action: string;
      url: string;
      sync: object;
      validation: string;
    };
    confirmation?: {
      title: string;
    };
    hero?: {
      url: string;
      caption?: string;
    };

    forwards2?: {
      text: string;
      type: string;
      action: string;
      url: string;
      sync: object;
      validation: string;
    };
  };
  style_overlay: Partial<{
    font: Partial<{ fontFamily: React.CSSProperties['fontFamily'] }>;
    fontFamily: React.CSSProperties['fontFamily'];
    fontSize: React.CSSProperties['fontSize'];
    textColor: React.CSSProperties['color'];
    primary1Color: React.CSSProperties['color'];
    companyLeadColor: React.CSSProperties['color'];
    background: string;
    logo: string;
    global: string; // css global object
  }>;
  fullInfo: RestExperience;
  user: {
    app_id: string;
    email: string;
    account: object;
  };
};

export const destinationProtocols: SelectOption<DestinationType>[] = [
  { value: DestinationType.url, label: 'URL' },
  { value: DestinationType.confirmation_page, label: 'Redirect Page' },
  { value: DestinationType.global, label: 'Global URL Configuration' },
];

export const extractConfirmationFunnelKey = (url: string | undefined) => {
  if (!url) return;
  if (url.indexOf('?confirmation_funnel=') !== -1) {
    return url.split('=')[1] ?? url;
  }
  return;
};

export const confirmationFunnelSlice = bbkApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getConfirmationFunnels: build.query<
      ConfirmationFunnel[],
      { appKey: string }
    >({
      query: ({ appKey }) =>
        `/api/v1/company/application/${appKey}/confirmation_funnels`,
      transformResponse: (response: {
        confirmationFunnels: ConfirmationFunnel[];
      }) => response.confirmationFunnels,
      providesTags: ['ConfirmationFunnels'],
    }),

    getConfirmationCard: build.query<
      ConfirmationCard,
      { appKey: string; ccpKey: string }
    >({
      query: ({ appKey, ccpKey }) =>
        `/api/v1/company/application/${appKey}/confirmation_funnel/${ccpKey}`,
    }),

    createConfirmationCard: build.mutation<
      string,
      {
        appKey: string;
        confirmationCard: ConfirmationCard;
        publishState: PublishState;
      }
    >({
      query: ({ appKey, confirmationCard, publishState }) => ({
        url: `/api/v1/company/application/${appKey}/confirmation_funnel?publish=${
          publishState === PublishState.live
        }`,
        method: 'POST',
        body: confirmationCard,
      }),
      invalidatesTags: ['ConfirmationFunnels'],
    }),

    updateConfirmationCard: build.mutation<
      void,
      {
        appKey: string;
        ccpKey: string;
        confirmationCard: ConfirmationCard;
        publishState: PublishState;
      }
    >({
      query: ({ appKey, ccpKey, confirmationCard, publishState }) => ({
        url: `/api/v1/company/application/${appKey}/confirmation_funnel/${ccpKey}?publish=${
          publishState === PublishState.live
        }`,
        method: 'PUT',
        body: confirmationCard,
      }),
      invalidatesTags: ['ConfirmationFunnels'],
    }),

    getDefaultConfirmationCard: build.query<
      ConfirmationCard,
      { appKey: string }
    >({
      query: ({ appKey }) =>
        `/api/v1/application/${appKey}/default_confirmation_card`,
    }),
  }),
});
