import React from 'react';
import RoundButton from '@bbkAdminComponents/buttons/round-button';
import { TourStoryProps } from './tour-story-index';

const ResponsiveImage = () => (
  <img src="/assets/images/tour/deflection-performance.png" />
);

const ProductTourStoryDeflectionPerformance = ({ onClick }: TourStoryProps) => (
  <div className="bbk-product-tour-story-template bbk-product-tour-story-deflection-performance">
    <article>
      <h1>How to Analyze Deflection Performance</h1>
      <figure>
        <ResponsiveImage />
      </figure>
      <p>
        Our Insights & Trends Reports help you answer key questions on how your
        Deflection Funnel is performing such as:
      </p>
      <div className="columns padding center">
        <div>
          <p className="small">
            <em>How has my Deflect Rate changed over time?</em>
          </p>
          <p className="small">
            <em>How are my Offer Accept rates changing over time?</em>
          </p>
          <p className="small">
            <em>Which Offer performs best at saving customers?</em>
          </p>
        </div>
        <div>
          <p className="small">
            <em>Which page in my a/b test is winning?</em>
          </p>
          <p className="small">
            <em>Have we achieved lift over the control?</em>
          </p>
          <p className="small">
            <em>
              Which Audiences have the most cancels / highest opportunity for
              deflection?
            </em>
          </p>
        </div>
      </div>
      <p>
        The Save Performance and Deflection trends reports will help you answer
        time based questions, and are typically where we start when analyzing
        performance. The Experience Performance and Offer Performance reports
        will help you dig deeper into what is driving the most deflection.
      </p>
      <footer>
        <p>
          <RoundButton onClick={onClick}>
            Next: Customer and Churn Insights
          </RoundButton>
        </p>
      </footer>
    </article>
  </div>
);

export default {
  name: 'How to Analyze Deflection Performance',
  key: 'bbk-pt-deflection-performance',
  story: <ProductTourStoryDeflectionPerformance />,
};
