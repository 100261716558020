import Toggle from '@bbkAdminComponents/switches/toggle/toggle';
import { StripeBillingConfig } from '@bbkAdminRedux/rtkq/billing-config.slice';
import React from 'react';

export const StripeForm = (props: {
  values: StripeBillingConfig;
  setCancelConfig: (values: StripeBillingConfig) => void;
  loading: boolean;
}) => {
  const {
    values: { invoice_now, prorate },
    values,
    setCancelConfig,
    loading,
  } = props;

  const handleChange = (update: { [key: string]: any }) => {
    setCancelConfig({
      ...values,
      ...update,
    });
  };

  return (
    <>
      <Toggle
        xs
        theme={['theme-new-purple', 'theme-large-label']}
        checked={invoice_now}
        id="invoiceNow"
        label="Invoice now"
        handleChange={() =>
          handleChange({
            invoice_now: !invoice_now,
          })
        }
        disabled={loading}
        helpText="Final invoice for any un-invoiced metered usage and new/pending proration invoice items."
      />
      <Toggle
        xs
        theme={['theme-new-purple', 'theme-large-label']}
        checked={prorate}
        id="prorate"
        label="Prorate"
        handleChange={() =>
          handleChange({
            prorate: !prorate,
          })
        }
        disabled={loading}
        helpText="Will generate a proration invoice item that credits remaining unused time until the subscription period end."
      />
    </>
  );
};
