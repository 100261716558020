import React from 'react';
import { Link } from 'react-router-dom';
import { useUrl } from '@bbkAdminUtils/nav';
import Icon from '@bbkAdminComponents/icons/icon-generator';
import './styles/buttons.scss';

type Props = {
  title: string;
  url: string;
};
const DisconnectButton: React.FC<Props> = (props) => {
  const { url: propURL, title } = props;
  const createUrl = useUrl();

  return (
    <Link
      className="bbk-button"
      style={{ padding: '0px' }}
      to={{
        pathname: createUrl(propURL),
      }}
      target="_parent"
    >
      <button className="primary" style={{ color: '#FF4057' }}>
        <Icon class="redFill" name="close" />
        {title}
      </button>
    </Link>
  );
};

export default DisconnectButton;
