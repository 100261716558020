import { useCallback, useEffect } from 'react';
import { trim } from 'lodash';
import isFQDN from 'validator/lib/isFQDN';
import { preauthBbkApiSlice } from '@bbkAdminRedux/rtkq/preauth.bbkApi.slice';
import BrightbackSession from '@bbkAdminUtils/brightback-session';
import type { PLANS } from '@bbkAdminRedux/rtkq/checkout.plans';
import type { BbkClientConfig } from '@bbkRoot/typings';
import {
  brightbackAuthSlice,
  isSignedIn,
} from '@bbkAdminRedux/rtkq/brightback-auth.slice';
import { recordError } from '@bbkAdminUtils/recordError';

export enum IdentityProviderEnum {
  CHARGEBEE_IDP = 'chargebee-idp',
}

export type ChargebeeData = {
  first_name: string;
  last_name?: string;
  email: string;
  merchant_id: string;
  merchant_name: string;
  merchant_domain?: string;
  domain: string;
  site_id: string;
  site_name: string;
  subscription_plan: PLANS;
  application_type: 'production' | 'sandbox';
  api_key: string;
  company_exists_for_merchant_id: boolean;
  user_exists: boolean;
  idp?: IdentityProviderEnum.CHARGEBEE_IDP;
};

export const MARKETO_UTM_FIELDS_QUERY_PARAMS_TO_COOKIE = {
  utm_medium: { fieldName: 'lastUTMMedium', cookieName: 'cbr_medium' },
  utm_source: { fieldName: 'lastUTMSource', cookieName: 'cbr_source' },
  utm_keyword: { fieldName: 'lastUTMKeyword', cookieName: 'cbr_keyword' },
  utm_campaign: { fieldName: 'lastUTMCampaign', cookieName: 'cbr_campaign' },
  utm_term: { fieldName: 'lastUTMTerm', cookieName: 'cbr_term' },
  utm_content: { fieldName: 'lastUTMContent', cookieName: 'cbr_content' },
  gclid: { fieldName: 'uTMGCLID', cookieName: 'cbr_gclid' },
  utm_adgroupid: { fieldName: 'lastUTMAdgroup', cookieName: 'cbr_adgroupid' },
  utm_campaignid: {
    fieldName: 'lastUTMCampaignID',
    cookieName: 'cbr_campaignid',
  },
};

export type MarketoUtmFieldsType = {
  [field in keyof typeof MARKETO_UTM_FIELDS_QUERY_PARAMS_TO_COOKIE]?: string;
};

export const preauthCompanySlice = preauthBbkApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getClientConfig: build.query<BbkClientConfig, void>({
      query: () => ({
        url: `/client_config`,
        params: {
          nonce: Date.now(),
        },
      }),
    }),
    cbidpCheckUsername: build.query<{ valid: boolean }, { email: string }>({
      query: ({ email }) => ({
        url: '/api/v2/cbidp/check-username',
        params: {
          email,
        },
        method: 'POST',
      }),
    }),
    enrichCompany: build.mutation<
      unknown,
      {
        company: string;
        monthly_cancels?: string;
        billing_system?: string;
        business_model?: string;
        industry_vertical?: string;
        phone?: string;
        additional_params?: string;
      }
    >({
      query: ({ ...body }) => ({
        url: '/api/v1/company/enrich',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
    }),
    fetchCachedDataFromChargebee: build.query<ChargebeeData, { token: string }>(
      {
        query: ({ token }) => ({
          url: `/api/v1/company/fetch_cached_data_from_chargebee`,
          params: {
            cb_email: token,
          },
        }),
      }
    ),
    cacheCompanyCreation: build.mutation<
      unknown,
      {
        email: string;
        company_name: string;
        domain: string;
        additional_params?: string;
      }
    >({
      query: ({ ...body }) => ({
        url: '/api/v1/company/cache_company_creation',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
    }),
    createCompanyFromCache: build.mutation<
      { companyKey: string; appKey: string },
      void
    >({
      query: () => ({
        url: '/api/v1/company_from_cache',
        method: 'POST',
      }),
    }),
    assignInvitation: build.mutation<
      unknown,
      { username: string; inviteCode: string }
    >({
      query: ({ username, inviteCode }) => ({
        url: `/assign_invitation?code=${inviteCode}&external_user_id=${username}`,
      }),
    }),
  }),
});
export const DOMAIN_REGEX =
  /^[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,30})$/;

export const domainValidator = (domain: string): string => {
  let domainError = '';
  if (!domain || trim(domain).length === 0) {
    domainError = 'Please enter your company domain.';
  }

  if (!DOMAIN_REGEX.test(domain)) {
    domainError = 'Please enter a valid domain.';
  }
  return domainError;
};

export const extractDomain = (url: string): string =>
  url.replace(/^(?:https?:\/\/)?([^/]+).*/, '$1');

export const useBaseUriDomain = () => {
  const { data: clientConfig } =
    preauthCompanySlice.endpoints.getClientConfig.useQuery();

  return extractDomain(clientConfig?.base_uri ?? 'app.retention.chargebee.com');
};

// When entering the vanity domain only following cases are entertained
// cancel.freshly.com
// subscription.pret.com
// cancel.freshly.co.uk
// cancel.subdomain.freshly.co.uk

// Do not prefix www. or http://www. in vanity domain as it will add
// complexity in fetching root domain

export const customDomainValidator = (domain: string): string => {
  let domainError = '';
  if (!domain || trim(domain).length === 0) {
    domainError = 'Please enter your company domain.';
  }

  if (!isFQDN(domain)) {
    domainError = 'Please enter a valid custom domain.';
  }
  return domainError;
};

const getTimeRemaining = () =>
  (BrightbackSession.getAccessTokenExpiresIn() ?? 0) -
  BrightbackSession.getAccessTokenAgeInSeconds();

export const usePollAndRefreshToken = () => {
  const [doRefreshTokens$] =
    brightbackAuthSlice.endpoints.doRefreshTokens.useMutation();
  const [signOut$] = brightbackAuthSlice.endpoints.signOut.useMutation();

  const startRefreshTimer = useCallback((timeout?: number) => {
    if (timeout) {
      console.log(
        `usePollAndRefreshToken, set up timer to refresh token in ${timeout} seconds`
      );
    }
    setTimeout(
      async () => {
        if (isSignedIn()) {
          await doRefreshTokens$()
            .unwrap()
            .catch(async (err) => {
              recordError(err);
              await signOut$().unwrap();
            });
        }
        const timeRemaining = getTimeRemaining();
        startRefreshTimer(timeRemaining);
      },
      // run refresh 1 minute before the token expires or retry every 10 seconds
      (timeout && timeout > 600 ? timeout - 60 : 10) * 1000
    );
  }, []);

  useEffect(() => {
    startRefreshTimer();
  }, [startRefreshTimer]);
};
