import React from 'react';
import RoundButton from '@bbkAdminComponents/buttons/round-button';
import { TourStoryProps } from './tour-story-index';

const ResponsiveImage = () => (
  <img src="/assets/images/tour/deflection-funnel.png" />
);

const ProductTourStoryDeflectionFunnel = ({ onClick }: TourStoryProps) => (
  <div className="bbk-product-tour-story-template bbk-product-tour-story-deflection-funnel">
    <article>
      <h1>The Deflection Funnel</h1>
      <figure>
        <ResponsiveImage />
      </figure>
      <p>
        Customers who visit your Chargebee Retention cancel page are placed into
        two buckets:
      </p>
      <div>
        <ol>
          <li>
            Those who cancel their subscription: <strong>Cancels</strong>
          </li>
          <li>
            Those who don’t cancel: <strong>Deflects</strong>
          </li>
        </ol>
      </div>
      <p>
        Deflected customers are added to the <strong>Watch List</strong> on your
        dashboard for an adjustable period of time (30 days by default), after
        which point they either convert into a <strong>Save</strong> (triggering
        a Save event), or if they cancel at any point, they are{' '}
        <strong>Lost</strong>.
      </p>
      <p>
        To deflect customers, you must set up a Chargebee Retention cancel
        experience and test, and start referring traffic from your site.
      </p>
      <footer>
        <p>
          <RoundButton onClick={onClick}>
            Next: Setting up a Chargebee Retention test
          </RoundButton>
        </p>
      </footer>
    </article>
  </div>
);

export default {
  name: 'The Deflection funnel',
  key: 'bbk-pt-deflection-funnel',
  story: <ProductTourStoryDeflectionFunnel />,
};
