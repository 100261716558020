// @ts-nocheck
/* eslint-disable react/static-property-placement,max-classes-per-file */
import jsep from 'jsep';
import escodegen from 'escodegen';
import moment, { MomentInput } from 'moment';
import numeral from 'numeral';

type ExpressionContext = Record<string, any>;

// We need this wrapper to accommodate BrightbackExpression (graal.js) quirkiness with Java Date type => JS Date type
const momentWrapper = (date?: MomentInput) => {
  if (date instanceof moment) {
    return moment(date);
  }
  return moment(date?.toString());
};

export class BrightbackExpression {
  originalText: string;

  textMode: boolean;

  context: ExpressionContext | undefined = undefined;

  constructor(expressionString: string, textMode = false) {
    this.originalText = BrightbackExpression.cleanString(expressionString);
    this.textMode = textMode;
  }

  assertStandardFieldReference(pass: boolean): asserts pass {
    if (!pass) {
      console.error(
        `This is not a standard field reference expression : ${this.originalText}`
      );
      throw new Error(
        `This is not a standard field reference expression : ${this.originalText}`
      );
    }
  }

  // create standard field ref parts
  static createStandardFieldReferencePart = (
    value: string,
    fallback: string,
    prefix = '$'
  ): string =>
    `${prefix}{default(value('${value}'), "${fallback.replace(
      /\"/gm,
      '\\"'
    )}")}`;

  static createValueFieldReferencePart = (
    value: string,
    prefix = '$'
  ): string => `${prefix}{value('${value}')}`;

  getStandardFieldReferenceParts = () => {
    if (this.originalText === '') {
      return {};
    }

    let ast;

    try {
      ast = jsep(this.originalText);
    } catch (e) {
      console.warn('BBK Expression Invalid: ', e);
      return {
        fallback: this.originalText,
        identifier: 'Invalid expression',
      };
    }

    let fallback = null;
    let valueExpression = null;
    // only accept the pattern default(value('identifier'), 'fallback');
    try {
      this.assertStandardFieldReference(ast.type === 'CallExpression');
      this.assertStandardFieldReference(ast.arguments.length === 2);
      this.assertStandardFieldReference(ast.callee.type === 'Identifier');
      this.assertStandardFieldReference(ast.callee.name === 'default');
      valueExpression = ast.arguments[0];
      this.assertStandardFieldReference(ast.arguments[1].type === 'Literal');
      fallback = ast.arguments[1].value;
    } catch (e) {
      // no default
      valueExpression = ast;
    }
    this.assertStandardFieldReference(
      valueExpression.type === 'CallExpression'
    );
    this.assertStandardFieldReference(valueExpression.arguments.length === 1);
    this.assertStandardFieldReference(
      valueExpression.callee.type === 'Identifier'
    );
    this.assertStandardFieldReference(valueExpression.callee.name === 'value');

    this.assertStandardFieldReference(
      valueExpression.arguments[0].type === 'Literal'
    );
    const identifier = valueExpression.arguments[0].value;

    return { identifier, fallback };
  };

  resolvers = {
    CallExpression: (node: jsep.CallExpression) => {
      let func = null;
      if (node.callee.type === 'Identifier') {
        func = this.functions[node.callee.name];
      } else if (node.callee.type === 'MemberExpression') {
        const lib = this.functions[node.callee.object.name];
        if (!lib) {
          throw new Error(
            `no function library '${node.callee.object.name}' found`
          );
        }
        func = lib[node.callee.property.name];
      }
      if (!func) {
        throw new Error(`no function named '${node.callee.name}()' found`);
      }
      const args = node.arguments.map((arg) => this.resolve(arg));

      return func.apply(this, args);
    },
    Identifier: (node: jsep.Identifier) => {
      if (node.name.match(/[\W ]/g)) {
        throw new Error(`Unsupported character(s) in identifier: ${node.name}`);
      }
      if (node.name === 'undefined') return undefined;
      if (node.name === 'null') return null;
      return this.context[node.name];
    },
    UnaryExpression: (node: jsep.UnaryExpression) => {
      if (node.operator === '!') {
        return !this.resolve(node.argument);
      }
      if (node.operator === '-') {
        return -this.resolve(node.argument);
      }
      throw new Error(
        `UnaryExpression operator not supported : ${node.operator}`
      );
    },
    BinaryExpression: (node: jsep.BinaryExpression) => {
      if (node.operator === '>') {
        return this.resolve(node.left) > this.resolve(node.right);
      }
      if (node.operator === '<') {
        return this.resolve(node.left) < this.resolve(node.right);
      }
      if (node.operator === '>=') {
        return this.resolve(node.left) >= this.resolve(node.right);
      }
      if (node.operator === '<=') {
        return this.resolve(node.left) <= this.resolve(node.right);
      }
      if (node.operator === '==') {
        return this.resolve(node.left) === this.resolve(node.right);
      }
      if (node.operator === '!=') {
        return this.resolve(node.left) !== this.resolve(node.right);
      }
      if (node.operator === '+') {
        return this.resolve(node.left) + this.resolve(node.right);
      }
      if (node.operator === '-') {
        return this.resolve(node.left) - this.resolve(node.right);
      }
      if (node.operator === '*') {
        return this.resolve(node.left) * this.resolve(node.right);
      }
      if (node.operator === '/') {
        return this.resolve(node.left) / this.resolve(node.right);
      }
      throw new Error(
        `BinaryExpression operator not supported : ${node.operator}`
      );
    },
    LogicalExpression: (node: jsep.LogicalExpression) => {
      if (node.operator === '&&') {
        return this.resolve(node.left) && this.resolve(node.right);
      }
      if (node.operator === '||') {
        return this.resolve(node.left) || this.resolve(node.right);
      }
      throw new Error(
        `LogicalExpression operator not supported : ${node.operator}`
      );
    },
    Literal: (node: jsep.Literal) => {
      return node.value;
    },
    Compound: (node: jsep.Compound) => {
      const args = node.body.map((item) => item.name);
      throw new Error(`Expression not supported : ${args.join(' ')}`);
    },
  };

  functions = {
    default: (value: any, defaultValue: any) => {
      return BrightbackExpression.isNil(value) ? defaultValue : value;
    },
    value: (key: string) => {
      const v = this.context[key];
      if (
        !BrightbackExpression.isNil(v) &&
        this.textMode &&
        isNaN(v) &&
        !isNaN(Date.parse(v)) &&
        v.toString().match(/\d{4}-\d{2}-\d{2}T.*/)
      ) {
        return v.toString().slice(0, 10);
      }
      return v;
    },
    isNil: (value: any): value is null | undefined => {
      return BrightbackExpression.isNil(value);
    },
    isNilOrEmpty: (value: any): value is null | undefined | '' => {
      return BrightbackExpression.isNil(value) || value === '';
    },
    if: (condition: boolean, thenExp: any, elseExp: any) => {
      return condition ? thenExp : elseExp;
    },
    Generic: {
      isNilOrEmpty: (value: any): value is null | undefined | '' => {
        return BrightbackExpression.isNil(value) || value === '';
      },
      isNotNilOrEmpty: (
        value: any
      ): value is Exclude<any, null | undefined | ''> => {
        return !BrightbackExpression.isNil(value) && value !== '';
      },
    },
    Boolean: {
      and: (...args: unknown[]): boolean => {
        // [].every(a = a) => true
        return args.every((arg) => arg);
      },
      or: (...args: unknown[]): boolean => {
        // !!! WARNING https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some
        // [].some(a = a) => false
        return args.length === 0 ? true : args.some((arg) => arg);
      },
    },
    Date: {
      format: (date: MomentInput, format?: string) => {
        return momentWrapper(date).format(format);
      },

      isAfter: (date: MomentInput, numDays: number) => {
        return momentWrapper(date) > momentWrapper().add(numDays, 'days');
      },

      isBefore: (date: MomentInput, numDays: number) => {
        return momentWrapper(date) < momentWrapper().subtract(numDays, 'days');
      },

      willHappenWithinDays: (date: MomentInput, numDays: number) => {
        return momentWrapper(date).isBetween(
          momentWrapper(),
          momentWrapper().add(numDays, 'days')
        );
      },

      didHappenWithinDays: (date: MomentInput, numDays: number) => {
        return momentWrapper(date).isBetween(
          momentWrapper().subtract(numDays, 'days'),
          momentWrapper()
        );
      },

      difference: (date1: MomentInput, date2: MomentInput) => {
        return momentWrapper(date2) - momentWrapper(date1);
      },

      now: () => {
        return momentWrapper();
      },

      day: (date: MomentInput) => {
        return momentWrapper(date).day();
      },

      utc: (date: MomentInput) => {
        return momentWrapper(date).utc();
      },
    },
    String: {
      concat: (...values: string[]): string => {
        const args = values.map((arg) => BrightbackExpression.cleanString(arg));
        return args.join('');
      },
      toLowerCase: (value?: string): string => {
        return BrightbackExpression.isNil(value) ? '' : value.toLowerCase();
      },
      toUpperCase: (value?: string): string => {
        return BrightbackExpression.isNil(value) ? '' : value.toUpperCase();
      },
      includes: (string1: string, string2: string): boolean => {
        return (
          BrightbackExpression.cleanString(string1).indexOf(
            BrightbackExpression.cleanString(string2)
          ) > -1
        );
      },
      notIncludes: (string1: string, string2: string): boolean => {
        return (
          BrightbackExpression.cleanString(string1).indexOf(
            BrightbackExpression.cleanString(string2)
          ) === -1
        );
      },
      startsWith: (string1: string, string2: string): boolean => {
        if (BrightbackExpression.isNil(string1)) {
          return false;
        }

        return BrightbackExpression.cleanString(
          string1.toLowerCase()
        ).startsWith(BrightbackExpression.cleanString(string2.toLowerCase()));
      },
      in: (string: string | string[], ...arrayElements: string[]) => {
        if (Array.isArray(string)) {
          return arrayElements?.filter((o) => string?.includes(o)).length > 0;
        }
        return arrayElements.indexOf(string) > -1;
      },
      notIn: (string: string | string[], ...arrayElements: string[]) => {
        if (Array.isArray(string)) {
          return arrayElements?.filter((o) => string?.includes(o)).length === 0;
        }
        return arrayElements.indexOf(string) === -1;
      },
    },
    Number: {
      format: (value: number, format?: string): string => {
        return numeral(`${value}`).format(format);
      },
      removeDollarSigns: (value: string): number => {
        return parseFloat(value.replace(/\$/g, ''));
      },
    },
  };

  static isNil = (val: unknown): val is undefined | null => {
    return val === undefined || val === null;
  };

  static cleanString = (value?: string) => {
    return BrightbackExpression.isNil(value) ? '' : `${value}`;
  };

  process = (context: ExpressionContext) => {
    this.context = context;
    const ast = jsep(this.originalText);
    return BrightbackExpression.cleanString(this.resolve(ast));
  };

  resolve = (node: jsep.Expression) => {
    const resolver = this.resolvers[node.type];
    if (!resolver) {
      throw new Error(`Resolver not found for type: ${node.type}`);
    }
    return resolver(node);
  };

  static stringToAst(string: string): jsep.Expression {
    return jsep(string);
  }

  static astToString(ast: jsep.Expression): string {
    return escodegen.generate(ast);
  }
}

export class BrightbackText {
  static pattern = /\${([\s\S]+?)}/g;

  // Do not pick up all expression types
  static mentionPattern = /\${((?:default|value)[[\s\S]+?)}/g;

  static wrapped_pattern =
    /<span class="bbk-expression">\${([\s\S]+?)}<\/span>/g;

  static wrappedMentionPattern =
    /<bbk-expression class="bbk-expression">\${([\s\S]+?)}<\/bbk-expression>/g;

  originalText: string;

  constructor(originalText: string) {
    this.originalText = BrightbackExpression.cleanString(originalText);
  }

  getStandardFieldReferenceParts = () => {
    const text = this.originalText;
    const body = text.replace(BrightbackText.pattern, (match, exp) => exp);
    return new BrightbackExpression(body).getStandardFieldReferenceParts();
  };

  unwrapExpressionsInSpans = (): string => {
    return this.originalText.replace(
      BrightbackText.wrapped_pattern,
      (match, exp) => {
        return `\${${exp}}`;
      }
    );
  };

  unwrapExpressionsInMentions = (): string => {
    return this.originalText.replace(
      BrightbackText.wrappedMentionPattern,
      (match, exp) => {
        return `\${${exp}}`;
      }
    );
  };

  wrapExpressionsInSpans = () => {
    const text = this.unwrapExpressionsInSpans();
    return text.replace(BrightbackText.pattern, (match, exp) => {
      return `<span class="bbk-expression">\${${exp}}</span>`;
    });
  };

  wrapExpressionsInMentions = () => {
    const text = this.unwrapExpressionsInMentions();
    let result = text.replace(BrightbackText.mentionPattern, (match, exp) => {
      return `<bbk-expression class="bbk-expression">\${${exp}}</bbk-expression>`;
    });

    if (result.endsWith('</bbk-expression>')) {
      result += ' ';
    }

    return result;
  };

  process = (
    context: ExpressionContext,
    options?: { errorFallback?: string }
  ): string => {
    const errorFallback = options?.errorFallback;
    const text = this.originalText;
    return text.replace(BrightbackText.pattern, (match, exp) => {
      try {
        return new BrightbackExpression(exp, true).process(context);
      } catch (e) {
        if (BrightbackExpression.isNil(errorFallback)) {
          throw e;
        } else {
          return errorFallback;
        }
      }
    });
  };
}

export function BrightbackExpressionProcess(
  type: 'text' | 'expression',
  str: string,
  ctx: ExpressionContext
) {
  if (type === 'text') {
    const obj = new BrightbackText(str);
    return obj.process(ctx);
  }
  const obj = new BrightbackExpression(str);
  return obj.process(ctx);
}
