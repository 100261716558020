import React from 'react';
import { useSelector } from 'react-redux';
import cs from 'classnames';
import { NavContainer, NavItem } from '@bbkAdminComponents/app-layout/nav';
import { GenericActionMenu } from '@bbkAdminComponents/action-menu/generic-action-menu';
import { CBRIcons } from '@bbkAdminUtils/icons';
import { useUrl } from '@bbkAdminUtils/nav';
import { AuthorizedPaths } from '@bbkAdminRouter/router-paths';
import { selectAppId } from '@bbkAdminRedux/app/selectors';

const CBProductsSwitcherButton: React.FC<{
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled: boolean | undefined;
}> = ({ ...restProps }) => (
  <NavContainer>
    <NavItem
      classes="nav-link"
      text="Chargebee Products"
      icon="productsSwitcherDots"
      {...restProps}
    />
  </NavContainer>
);

const CBProductSwitcherItem: React.FC<{
  className?: string;
  url: string;
  title: string;
  desc: React.ReactNode;
  active?: boolean;
}> = ({ className, url, title, desc, active }) => {
  return (
    <div
      className={cs(
        '!tw-block',
        '!tw-text-black tw-text-left',
        '-tw-mt-4 -tw-mx-[15px]',
        className
      )}
    >
      <a
        href={url}
        rel="noreferrer noopener"
        className={cs(
          'tw-flex',
          'tw-mt-2 tw-mx-2 tw-px-2 tw-py-1',
          'tw-rounded',
          'hover:tw-text-black hover:tw-bg-cbrneutral-200'
        )}
      >
        <div className="tw-flex-1">
          <div className="tw-font-bold tw-text-sm tw-mb-1">{title}</div>

          <div className="!tw-font-normal tw-text-xs tw-text-cbrneutral-700">
            {desc}
          </div>
        </div>
        {active && (
          <div>
            <span
              className={cs(
                'tw-rounded-3xl tw-w-4 tw-h-4 tw-bg-cbrsuccess-400 tw-text-white',
                'tw-flex tw-items-center tw-justify-center'
              )}
            >
              {CBRIcons.cbrCheckmark()}
            </span>
          </div>
        )}
      </a>
    </div>
  );
};

export const CBProductsSwitcher = () => {
  const createUrl = useUrl();
  const appKey = useSelector(selectAppId);
  const cbBillingUrl = `/api/v1/apps/${appKey}/goto_billing`;

  const actions = [
    {
      copy: (
        <CBProductSwitcherItem
          url={cbBillingUrl}
          title="Billing"
          className="tw-mb-1 tw-pb-1 tw-border-b tw-border-b-cbrneutral-200"
          desc={
            <>
              Manage subscription billing, invoicing, and payment processing at
              scale.
            </>
          }
        />
      ),
    },
    {
      copy: (
        <CBProductSwitcherItem
          active
          url={createUrl(AuthorizedPaths.dashboard)}
          title="Retention"
          desc={
            <>
              Gain insights on cancellations and retain more customers with
              personalized offers.
            </>
          }
        />
      ),
    },
  ];

  return (
    <GenericActionMenu
      actions={actions}
      actionButton={CBProductsSwitcherButton}
      popoverProps={{
        classes: { paper: 'bbk-cnb-popover tw-w-[300px]' },
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'left',
        },
      }}
    />
  );
};
