import React, { useCallback, useRef, useState } from 'react';
import Icon from '@bbkAdminComponents/icons/icon-generator';
import Icons, { CBRIcons } from '@bbkAdminUtils/icons';
import { LogInError } from '@bbkAdminComponents/log-in-flow/error';
import { LogInTrouble } from '@bbkAdminComponents/log-in-flow/trouble';
import { LogInSuccess } from '@bbkAdminComponents/log-in-flow/success';
import type * as LogInTypes from '@bbkAdminComponents/log-in-flow/log-in-types';
import { LogInPaths } from '@bbkAdminComponents/log-in-flow/log-in-paths';
import Label from '@bbkAdminComponents/inputs/label/label';
import { SigninButton } from '@bbkAdminComponents/log-in-flow/signin-button';
import { PasswordInput } from '@bbkAdminComponents/inputs/password/password-input';
import { CBRetentionLogo } from '@bbkAdminComponents/cbretention-logo';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { preauthCompanySlice } from '@bbkAdminRedux/rtkq/preauth-company.slice';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';
import { recordError } from '@bbkAdminUtils/recordError';
import { FeatureFlagsE, useFeatureFlags } from '@bbkAdminUtils/feature-flags';

type SignInTypes = {
  error?: LogInTypes.Error;
  success?: LogInTypes.Success;
  formValues: LogInTypes.FormValues;
  googleSignIn: LogInTypes.GoogleSignIn;
  chargebeeSignIn: LogInTypes.ChargebeeSignIn;
  signIn: LogInTypes.SignIn;
  sending: LogInTypes.Sending;
  isCBPath?: boolean;
};
export const SignIn: React.FC<SignInTypes> = ({
  formValues,
  googleSignIn,
  signIn,
  chargebeeSignIn,
  error,
  sending,
  success,
  isCBPath,
}) => {
  const { email, password, setEmail, setPassword } = formValues;

  const pwdRef = useRef<HTMLInputElement>(null);

  const [showPasswordField, setShowPasswordField] = useState<boolean>(!!error);
  const [showCBIDPButton, setShowCBIDPButton] = useState<boolean>(false);

  const showCBIDPFlow = useFeatureFlags(FeatureFlagsE.SHOW_CBIDP_FLOW);

  const isSignInEnabled = !showCBIDPFlow || showPasswordField;
  const isCBIDPButtonEnabled = showCBIDPFlow && showCBIDPButton;

  const [cbidpCheckUsername$] =
    preauthCompanySlice.endpoints.cbidpCheckUsername.useLazyQuery();
  const checkUsername = useCallback(
    async (em: string) => {
      let isEmailAnCBIDPUser = false;
      try {
        const response = await cbidpCheckUsername$(
          { email: em },
          true
        ).unwrap();
        isEmailAnCBIDPUser = response.valid;
      } catch (e) {
        BlackToastTrigger({
          content: 'Could not check email. Please try again',
        });
        recordError(e);
      } finally {
        setShowCBIDPButton(isEmailAnCBIDPUser);
        setShowPasswordField(!isEmailAnCBIDPUser);
        if (!isEmailAnCBIDPUser) {
          pwdRef.current?.focus();
        } else {
          chargebeeSignIn(email);
        }
      }
    },
    [cbidpCheckUsername$, chargebeeSignIn]
  );

  return (
    <div>
      <div className="content">
        <CBRetentionLogo className="tw-h-10 tw-mb-6" />
        <div className="tw-font-semibold tw-mb-4">Sign-in to continue</div>
        {success && <LogInSuccess success={success} />}
        {error && <LogInError error={error} />}
        <form
          target="#"
          onSubmit={isSignInEnabled ? signIn : (e) => e.preventDefault()}
        >
          <div className="form-container">
            <Label id="email" className="tw-text-sm tw-font-semibold">
              Email
            </Label>
            <input
              type="email"
              name="email"
              className="bbk-simple-text-input medium"
              autoComplete="on"
              placeholder="Enter your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => {
                setShowCBIDPButton(false);
                setShowPasswordField(false);
              }}
              disabled={sending || isCBPath}
            />
            {isSignInEnabled && (
              <>
                <Label
                  id="password"
                  helpText="12 character minimum"
                  className="tw-text-sm tw-font-semibold"
                >
                  Password
                </Label>
                <PasswordInput
                  ref={pwdRef}
                  name="password"
                  autoComplete="on"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={sending}
                />
              </>
            )}
            {isSignInEnabled ? (
              <ButtonV2
                onClick={signIn}
                disabled={sending}
                primary
                fullWidth
                className="submitButtonFooter"
              >
                Sign in <Icon name="arrow" class="inline" />
              </ButtonV2>
            ) : (
              <ButtonV2
                disabled={sending}
                primary
                fullWidth
                className="submitButtonFooter"
                onClick={() => checkUsername(email)}
              >
                Continue
              </ButtonV2>
            )}
            <div className="signin-divider !tw-mb-2">
              <div className="or">or</div>
            </div>
            <SigninButton
              copy="Sign in with Google"
              onClick={googleSignIn}
              icon={Icons.google}
            />
            {isCBIDPButtonEnabled && (
              <div className="tw-mt-2">
                <SigninButton
                  copy="Sign in with Chargebee"
                  icon={
                    <span className="tw-text-cblogo">
                      {CBRIcons.cbrLogo(22, 22)}
                    </span>
                  }
                  onClick={chargebeeSignIn}
                />
              </div>
            )}
            <button type="submit" style={{ display: 'none' }}>
              Sign in
            </button>
          </div>
        </form>
      </div>
      <LogInTrouble
        link={{ path: LogInPaths.forgotPassword, copy: 'Forgot password?' }}
      />
    </div>
  );
};
