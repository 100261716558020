/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { skipToken } from '@reduxjs/toolkit/query';
import cs from 'classnames';
import { useUrl } from '@bbkAdminUtils/nav';
import icons from '@bbkAdminUtils/icons';
import Badge from '@bbkAdminComponents/badges/badge';
import { selectAppId } from '@bbkAdminRedux/app/selectors';
import { badgesSlice } from '@bbkAdminRedux/rtkq/badges.slice';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';

type Props = {
  classes?: string;
  href: string;
  icon?: keyof typeof icons;
  text: React.ReactNode;
  disabled?: boolean;
  linkAttributes?: Partial<React.ComponentProps<typeof Link>>;
  iconNode?: React.ReactElement;
};

const NavLinkWrapper: ReactFCC<{
  icon?: keyof typeof icons;
  iconNode?: React.ReactElement;
  text: React.ReactNode;
}> = ({ icon, iconNode, text }) => {
  const appKey = useSelector(selectAppId);
  const { data: badges = [] } = badgesSlice.endpoints.getBadges.useQuery(
    !appKey || appKey === '_' ? skipToken : appKey,
    { pollingInterval: 60000 }
  );

  const renderSettingsMenuBadge = (): React.ReactNode =>
    badges.find((b) => b.name === 'application_log' && b.state === 'flag') ? (
      <Badge badgeClasses="bbk-badge-flat bbk-badge-stat notify" />
    ) : null;
  return (
    <>
      {!isNil(icon) && <span className="nav-icon">{icons[icon]}</span>}
      {iconNode && <span className="nav-icon">{iconNode}</span>}
      {text}
      {text === 'Settings' && (
        <>
          &nbsp;
          {renderSettingsMenuBadge()}
        </>
      )}
    </>
  );
};

export const NavLink: React.FC<Props> = (props) => {
  const createUrl = useUrl();

  const {
    classes,
    href,
    icon,
    text,
    linkAttributes,
    iconNode,
    disabled,
    ...other
  } = props;
  const navLinkNode = (
    <NavLinkWrapper icon={icon} iconNode={iconNode} text={text} />
  );
  return disabled ? (
    <span
      className={cs(classes, {
        '!tw-cursor-not-allowed disabled': disabled,
      })}
    >
      {navLinkNode}
    </span>
  ) : (
    <Link
      to={{ pathname: createUrl(href) }}
      {...linkAttributes}
      className={classes}
      {...other}
    >
      {navLinkNode}
    </Link>
  );
};
