import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { set } from 'lodash';
import { isDefined } from '@bbkAdminRedux/rtkq/rtkq-utils';

export const useSearchParams = <
  T extends Record<string, unknown>,
>(): Partial<T> => {
  const location = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(location.search);

    const obj = {} as T;
    params.forEach((val, key) => {
      const objEl = obj[key];
      if (isDefined(objEl)) {
        const objArr = Array.isArray(objEl) ? objEl : [objEl];
        objArr.push(val);
        set(obj, key, objArr);
      } else {
        set(obj, key, val);
      }
    });

    return obj;
  }, [location.search]);
};
