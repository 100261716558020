// @ts-nocheck
import React, { useMemo, useState } from 'react';
import type { SlateDocument, SlatePlugin } from '@udecode/slate-plugins';
import {
  BoldPlugin,
  DEFAULTS_BOLD,
  DEFAULTS_ITALIC,
  DEFAULTS_LINK,
  DEFAULTS_PARAGRAPH,
  DEFAULTS_UNDERLINE,
  deserializeHTMLToDocument,
  EditablePlugins,
  HeadingToolbar,
  ItalicPlugin,
  LinkPlugin,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  ParagraphPlugin,
  pipe,
  serializeHTMLFromNodes,
  ToolbarLink,
  UnderlinePlugin,
  withInlineVoid,
  withLink,
} from '@udecode/slate-plugins';
import { createEditor, Node, Text } from 'slate';
import { withHistory } from 'slate-history';
import type { ReactEditor } from 'slate-react';
import { Slate, withReact } from 'slate-react';
import icons from '@bbkAdminUtils/icons';
import { BrightbackText } from '@bbkAdminUtils/brightback-expression';
import {
  BbkExpressionPlugin,
  DEFAULTS_BBK_EXPRESSION,
  ELEMENT_BBK_EXPRESSION,
  ToolbarBbkExpression,
} from '@bbkAdminComponents/rich-text-editor/bbk-expression';
import {
  BbkExpressionPluginCleanExport,
  DEFAULTS_BBK_EXPRESSION_CLEAN_EXPORT,
} from '@bbkAdminComponents/rich-text-editor/bbk-expression-export';
import { MarkButton } from './components';
import './styles/toolbar.scss';
import { useBbkExpression } from './bbk-expression/useBbkExpression';
import { BbkMentionSelect } from './bbk-expression/components/MentionSelect';
import {
  ALERT_FIELDS,
  FieldMapping,
  getAlertFields,
} from '@bbkAdminRedux/rtkq/integrations.slice';

export const options = {
  // default elements
  ...DEFAULTS_PARAGRAPH,
  ...DEFAULTS_LINK,
  // marks
  ...DEFAULTS_BOLD,
  ...DEFAULTS_ITALIC,
  ...DEFAULTS_UNDERLINE,
  // custom elements
  ...DEFAULTS_BBK_EXPRESSION,
  ...DEFAULTS_BBK_EXPRESSION_CLEAN_EXPORT,
};

export type Suggestion = {
  val: string;
  key: string;
  type: string;
  field_name?: string;
  integration_id?: number;
  is_lov?: boolean;
  fallback?: string;
  fallBackValue: string;
  name: string;
  standard?: boolean;
  alert?: boolean;
  reserved?: boolean;
};

export type TextEditorButtons = {
  bold?: boolean;
  underline?: boolean;
  italic?: boolean;
  fields?: boolean;
  urlFields?: boolean;
  alertFields?: boolean;
  link?: boolean;
};

export type RTEOutputType = 'html' | 'plaintext' | 'structuredPlain';

type Props = {
  value?: string;
  onChange: (value: string) => unknown;
  suggestions: Suggestion[] | FieldMapping[];
  urlSuggestions?: Suggestion[] | FieldMapping[];
  alertSuggestions?: Suggestion[] | FieldMapping[];
  enabledButtons?: TextEditorButtons;
  outputType?: RTEOutputType;
  toolbarAtTop?: boolean;
};

const replacePercent = (text: string): string =>
  text.replace(/%(?!\d|[ABCDEF]+)/gi, '%25');
const replaceSingleQuotes = (text: string): string =>
  text.replace(/&#x27;/gi, "'");
const changeDivsToPs = (text: string): string =>
  text.replace(/<(\/)?div/g, '<$1p');
const changesPsToDivs = (text: string): string =>
  text.replace(/<(\/)?p/g, '<$1div');
const addClassToEmptyDivs = (text: string): string =>
  text.replace(/<div><\/div>/g, '<div class="slate-div"></div>');
const cleanDivWrapper = (text: string): string =>
  text.replace(/^<div>|<\/div>$/gm, '');

const convertBbkExpToTextNode = (node: Node): Node => {
  if (Text.isText(node)) {
    return { ...node, text: replacePercent(node.text) };
  }

  const newNode = { ...node };
  if (newNode.type === ELEMENT_BBK_EXPRESSION) {
    // console.log(newNode);
    newNode.type = 'bbk-expression-clean-render';
  }

  return {
    ...newNode,
    children: newNode.children.map((n) => convertBbkExpToTextNode(n)),
  };
};

const serializeToBaseText = (nodes?: SlateDocument): string => {
  const buildText = (nodes ?? []).map((n) => Node.string(n)).join('\n');
  return buildText.trimEnd();
};

const serializeToStructuredText = (nodes?: SlateDocument): string => {
  // breaks about child nodes as well
  const buildText = (nodes ?? [])
    .map((n) => n.children.map((c) => Node.string(c)).join('\n'))
    .join('\n');
  return buildText.trimEnd();
};

export const RichTextEditor: React.FC<Props> = (props) => {
  const {
    value: propsValue,
    onChange,
    suggestions,
    urlSuggestions = [],
    alertSuggestions = getAlertFields(),
    enabledButtons: enabledButtonsProps,
    outputType = 'html',
    toolbarAtTop = false,
  } = props;

  const enabledButtons = {
    bold: outputType === 'html',
    italic: outputType === 'html',
    underline: outputType === 'html',
    fields: true,
    urlFields: false,
    alertFields: false,
    link: false,
    ...enabledButtonsProps,
  };
  const enabledFields =
    enabledButtons.fields ||
    enabledButtons.urlFields ||
    enabledButtons.alertFields;

  const plugins: SlatePlugin[] = [ParagraphPlugin(options)];
  if (enabledButtons.bold) plugins.push(BoldPlugin(options));
  if (enabledButtons.italic) plugins.push(ItalicPlugin(options));
  if (enabledButtons.underline) plugins.push(UnderlinePlugin(options));
  if (enabledButtons.link) plugins.push(LinkPlugin(options));
  if (enabledFields) {
    plugins.push(BbkExpressionPlugin(options));
    plugins.push(BbkExpressionPluginCleanExport(options));
  }

  const withPlugins = [withReact, withHistory, withInlineVoid({ plugins })];

  if (enabledButtons.link) withPlugins.push(withLink(options));

  const wrappedHtml = new BrightbackText(
    propsValue
  ).wrapExpressionsInMentions();
  const preConvertHTML = changeDivsToPs(wrappedHtml);
  const { body } = new DOMParser().parseFromString(preConvertHTML, 'text/html');
  const parsedSlateDocument = deserializeHTMLToDocument({
    plugins,
    element: body,
  });

  const [value, setValue] = useState<SlateDocument>(parsedSlateDocument);
  const editor: ReactEditor = useMemo(
    () => pipe(createEditor(), ...withPlugins),
    []
  );

  const bbkExpression = useBbkExpression(suggestions, {
    maxSuggestions: 10,
    trigger: '@',
  });

  const urlBbkExpression = useBbkExpression(urlSuggestions, {
    maxSuggestions: 10,
    trigger: '@',
  });

  const alertBbkExpression = useBbkExpression(alertSuggestions, {
    maxSuggestions: 10,
    trigger: '@',
  });

  const showToolbar = Object.values(enabledButtons).filter((v) => v).length > 0;

  const ele = (<>
  {showToolbar && (
        <HeadingToolbar>
          {enabledButtons.bold && (
            <MarkButton format={MARK_BOLD} icon={icons.bold} />
          )}
          {enabledButtons.italic && (
            <MarkButton format={MARK_ITALIC} icon={icons.italics} />
          )}
          {enabledButtons.underline && (
            <MarkButton format={MARK_UNDERLINE} icon={icons.underline} />
          )}
          {enabledButtons.link && (
            <ToolbarLink icon={icons.link} {...options} />
          )}
          {enabledButtons.urlFields && (
            <ToolbarBbkExpression
              {...options}
              icon={icons.globe}
              onToolbarMentionClick={urlBbkExpression.onToolbarMentionClick}
              activateMentionViaToolbar={
                urlBbkExpression.activateMentionViaToolbar
              }
            />
          )}
          {enabledButtons.fields && (
            <ToolbarBbkExpression
              {...options}
              icon={icons.lightning}
              onToolbarMentionClick={bbkExpression.onToolbarMentionClick}
              activateMentionViaToolbar={
                bbkExpression.activateMentionViaToolbar
              }
            />
          )}
          {enabledButtons.alertFields && (
            <ToolbarBbkExpression
              {...options}
              icon={icons.bell}
              onToolbarMentionClick={alertBbkExpression.onToolbarMentionClick}
              activateMentionViaToolbar={
                alertBbkExpression.activateMentionViaToolbar
              }
            />
          )}
        </HeadingToolbar>
      )}

      <EditablePlugins
        plugins={plugins}
        placeholder="Begin typing…"
        onKeyDown={[
          bbkExpression.onKeyDownBbkExpression,
          ...(enabledButtons.urlFields
            ? [urlBbkExpression.onKeyDownBbkExpression]
            : []),
          ...(enabledButtons.alertFields
            ? [alertBbkExpression.onKeyDownBbkExpression]
            : []),
        ]}
        onKeyDownDeps={[
          bbkExpression.index,
          bbkExpression.search,
          bbkExpression.target,
          ...(enabledButtons.urlFields
            ? [
                urlBbkExpression.index,
                urlBbkExpression.search,
                urlBbkExpression.target,
              ]
            : []),
          ...(enabledButtons.alertFields
            ? [
                alertBbkExpression.index,
                alertBbkExpression.search,
                alertBbkExpression.target,
              ]
            : []),
        ]}
        spellCheck
        autoFocus
        css={{
          border: '1px solid rgba(0,0,0,0.1)',
          borderTop: showToolbar && 'none',
          padding: '3px 10px',
          overflow: 'hidden',
          minHeight: '40px',
          fontSize: 14,
          color: 'rgba(0,0,0,0.8)',
          background: 'white',
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          borderRadius: !showToolbar && '4px',
          '> * + *': {
            marginTop: '.5rem',
          },
        }}
      />

      <BbkMentionSelect
        at={bbkExpression.target}
        valueIndex={bbkExpression.index}
        fields={bbkExpression.values}
        allFields={suggestions}
        onAddMention={bbkExpression.onAddBbkExpression}
        fallback={bbkExpression.fallback}
        fallbackRef={bbkExpression.fallbackRef}
        onChangeFallback={bbkExpression.onChangeFallback}
        activateMentionViaToolbar={bbkExpression.activateMentionViaToolbar}
        onMouseClickToolbarMention={bbkExpression.onMouseClickToolbarMention}
        closeMentionSelect={bbkExpression.closeMentionSelect}
      />

      {enabledButtons.urlFields && (
        <BbkMentionSelect
          at={urlBbkExpression.target}
          valueIndex={urlBbkExpression.index}
          fields={urlBbkExpression.values}
          allFields={urlSuggestions}
          onAddMention={urlBbkExpression.onAddBbkExpression}
          fallback={urlBbkExpression.fallback}
          fallbackRef={urlBbkExpression.fallbackRef}
          onChangeFallback={urlBbkExpression.onChangeFallback}
          activateMentionViaToolbar={urlBbkExpression.activateMentionViaToolbar}
          onMouseClickToolbarMention={
            urlBbkExpression.onMouseClickToolbarMention
          }
          closeMentionSelect={urlBbkExpression.closeMentionSelect}
        />
      )}
      {enabledButtons.alertFields && (
        <BbkMentionSelect
          at={alertBbkExpression.target}
          valueIndex={alertBbkExpression.index}
          fields={alertBbkExpression.values}
          allFields={alertSuggestions}
          onAddMention={alertBbkExpression.onAddBbkExpression}
          fallback={alertBbkExpression.fallback}
          fallbackRef={alertBbkExpression.fallbackRef}
          onChangeFallback={alertBbkExpression.onChangeFallback}
          activateMentionViaToolbar={
            alertBbkExpression.activateMentionViaToolbar
          }
          onMouseClickToolbarMention={
            alertBbkExpression.onMouseClickToolbarMention
          }
          closeMentionSelect={alertBbkExpression.closeMentionSelect}
        />
      )}
  </>);

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={(newValue) => {
        setValue(newValue as SlateDocument);
        bbkExpression.onChangeBbkExpression(editor);
        if (enabledButtons.urlFields) {
          urlBbkExpression.onChangeBbkExpression(editor);
        }
        if (enabledButtons.alertFields) {
          alertBbkExpression.onChangeBbkExpression(editor);
        }

        let serializedValue;
        if (outputType === 'plaintext') {
          serializedValue = serializeToBaseText(newValue);
        }
        if (outputType === 'structuredPlain') {
          serializedValue = serializeToStructuredText(newValue);
        }
        if (outputType === 'html') {
          // Converts expressions to new type for rendering
          const preserializeTransform = newValue.map((v) =>
            convertBbkExpToTextNode(v)
          );
          // Serialize to html
          serializedValue = serializeHTMLFromNodes({
            plugins,
            nodes: preserializeTransform,
          });
          // need to do this before its saved to the server to avoid problems with replacement
          serializedValue = replaceSingleQuotes(serializedValue);
          serializedValue = cleanDivWrapper(serializedValue);
          serializedValue = changesPsToDivs(serializedValue);
          serializedValue = addClassToEmptyDivs(serializedValue);
        }
        onChange(serializedValue);
      }}
    >
      {toolbarAtTop ? <div className='tw-w-full'>{ele}</div>: ele}
    </Slate>
  );
};
