import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import Icons from '@bbkAdminUtils/icons';
import tailwindColors from '@bbkRoot/tailwind.colors';

type SectionHeaderProps = {
  sub?: boolean;
  control?: boolean;
  disabled?: boolean;
};
export const SectionHeader = styled.span`
  font-weight: bold;
  font-size: ${(props: SectionHeaderProps) => (props.sub ? '13px' : '15px')};
  color: ${() => tailwindColors.cbrprimary['600']};
`;

export const HorizontalLine = styled.div`
  margin-top: 1.3rem;
  margin-bottom: 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 50%;
`;

export const BreakLine = styled.div`
  margin-top: 1.3rem;
`;

export const SectionGroup = styled.div`
  padding-bottom: 1.3rem;
  border-bottom: ${({ last }: { last?: boolean }) =>
    last ? '' : '1px solid rgba(0, 0, 0, 0.1)'};
  margin-bottom: ${({ last }: { last?: boolean }) => (last ? '' : '2rem')};
`;

export const SectionGroupNoPadding = styled.div`
  border-bottom: ${({ last }: { last?: boolean }) =>
    last ? '' : '1px solid rgba(0, 0, 0, 0.1)'};
  margin-bottom: ${({ last }: { last?: boolean }) => (last ? '' : '2rem')};
`;

export const HoverIcon = styled.span`
  margin-left: 0.5rem;
`;

export const SectionHeaderGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;
export const SectionHeaderGroupTitleArea = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;
export const SectionHeaderGroupActionItem = styled.div`
  font-size: 14px;
  text-align: right;
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
`;

export const SectionHeaderBar = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px 4px 0px 0px;
  font-size: 14px;
`;

export const SectionBody = styled.div`
  font-size: 14px;
  padding-left: 2rem;
`;
export const SectionBodyRow = styled.div`
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  > * {
    flex-shrink: 0;
  }
`;
export const SectionBodySubRow = styled.div`
  padding: 2px 0.5rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
  font-size: 13px;
  height: 28px;
  > * {
    flex-shrink: 0;
  }
`;

export const SectionBodyRowHeader = styled.div`
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  display: flex;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
`;

export const ActiveColumnItem = styled.div`
  flex-basis: 80px;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const PagesColumnItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  > * {
    flex-shrink: 0;
  }
`;

export const PagesColumnEntryItem = styled.div`
  width: 60%;
  max-width: 400px;
`;

export const PagesColumnEntryRemovalButton = styled.button`
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PagesColumnSliderItem = styled.div`
  width: 160px;
  margin-left: 1rem;
  display: flex;
  align-items: center;
`;

export const VisitsColumnItem = styled.div`
  flex-basis: 75px;
  text-align: right;
`;

export const DeflectionRateColumnItem = styled.div`
  flex-basis: 125px;
  text-align: right;
`;

export const SelectSpacer = styled.span`
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  min-width: ${({ wide }: { wide?: boolean }) => (wide ? 130 : 85)}px;
`;

type ButtonV2Props = React.ComponentProps<typeof ButtonV2>;

export const PublishButton: React.FC<ButtonV2Props> = (props) => (
  <ButtonV2
    success
    css={{
      marginRight: '1rem',
      display: 'flex',
      alignItems: 'center',
    }}
    {...props}
  >
    <div
      css={{
        marginTop: '-3px',
        marginBottom: '-2px',
      }}
    >
      {Icons.publish}
    </div>
    <span css={{ marginLeft: '0.5rem' }}>{props.children}</span>
  </ButtonV2>
);

export const RemovalButton: React.FC<ButtonV2Props> = (props) => (
  <div
    css={css`
      width: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  >
    <ButtonV2
      circle
      small
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        svg: {
          height: 8,
          width: 8,
        },
      }}
      {...props}
    >
      {Icons.close14}
    </ButtonV2>
  </div>
);

export const SimpleRemovalButton: React.FC<ButtonV2Props> = (props) => (
  <ButtonV2
    css={[
      props.css,
      css`
        svg {
          height: 8px;
          width: 8px;
        }
      `,
    ]}
    circle
    small
    {...props}
  >
    {Icons.close14}
  </ButtonV2>
);

export const SimpleAddButton: React.FC<ButtonV2Props> = ({
  children,
  ...restProps
}) => (
  <ButtonV2 transparent deepColor {...restProps}>
    {Icons.plus}
    {children && <span css={{ marginLeft: '0.5rem' }}>{children}</span>}
  </ButtonV2>
);

export const AddPageButton: React.FC<ButtonV2Props> = (props) => (
  <ButtonV2
    transparent
    deepColor
    css={{
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 0,
    }}
    {...props}
  >
    {Icons.plus}
    <span css={{ marginLeft: '0.5rem' }}>Add page</span>
  </ButtonV2>
);

export const AddButton: React.FC<ButtonV2Props> = ({
  children,
  ...restProps
}) => (
  <ButtonV2
    transparent
    deepColor
    css={{
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 0,
    }}
    {...restProps}
  >
    {Icons.plus}
    <span css={{ marginLeft: '0.5rem' }}>{children}</span>
  </ButtonV2>
);

export const GenericSectionBodyHeader = () => (
  <SectionBodyRowHeader>
    <PagesColumnItem>Pages</PagesColumnItem>
    {/*<VisitsColumnItem>Visits</VisitsColumnItem>*/}
    {/*<DeflectionRateColumnItem>Deflection rate</DeflectionRateColumnItem>*/}
  </SectionBodyRowHeader>
);

export const StatisticArea = () => (
  <>
    {/*<VisitsColumnItem>--</VisitsColumnItem>*/}
    {/*<DeflectionRateColumnItem>--</DeflectionRateColumnItem>*/}
  </>
);
