import React from 'react';
import cs from 'classnames';

export const TrialSignupSteps: React.FC<{
  step: number;
  maxSteps: number;
}> = ({ step, maxSteps }) => (
  <div className="trial-signup-steps">
    <div className="steps-title">
      Step {step} of {maxSteps}
    </div>
    <div className="steps-list">
      {Array.from(Array(maxSteps).keys()).map((i) => (
        <div
          key={i}
          className={cs('step-item', { 'step-active': step >= i + 1 })}
        />
      ))}
    </div>
  </div>
);
