import React from 'react';
import { Collapse } from 'react-collapse';
import classNames from 'classnames';
import './collapse-form-container.scss';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';

type Props = {
  open: boolean;
  onToggle: (openState: boolean) => void;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  classes?: string;
  buttonClasses?: string;
};

export const CollapseFormContainerControlled: ReactFCC<Props> = (props) => {
  const {
    title = 'Show / Hide',
    subtitle,
    classes = 'CFC-default-theme',
    open = true,
    onToggle,
    buttonClasses,
    children,
  } = props;

  return (
    <div
      className={classNames('collapse-form-container', classes, {
        'CFC-collapsed': !open,
        'CFC-expanded': open,
      })}
    >
      <button
        type="button"
        onClick={() => onToggle(!open)}
        className={classNames('CFC-button', buttonClasses)}
      >
        {title}
        {subtitle && <span className="CFC-button-subtitle">{subtitle}</span>}
      </button>
      <Collapse isOpened={open}>{children}</Collapse>
    </div>
  );
};
