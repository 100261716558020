// @ts-nocheck
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import Label from '@bbkAdminComponents/inputs/label/label';
import TextInput from '@bbkAdminComponents/inputs/text/text-input';
import { PortalInOverlay } from './portal-in-overlay';
import tailwindColors from '@bbkRoot/tailwind.colors';

export const BbkMentionSelect: React.FC = (props) => {
  const {
    className,
    styles,
    at,
    fields: searchFields,
    allFields,
    valueIndex,
    onAddMention,
    fallback,
    fallbackRef,
    onChangeFallback,
    activateMentionViaToolbar,
    onMouseClickToolbarMention,
    closeMentionSelect,
    ...other
  } = props;

  const fields = activateMentionViaToolbar ? allFields : searchFields;

  const ref: any = useRef();
  const editor = useSlate();
  useEffect(() => {
    // console.log(ReactEditor);
    if (at && fields.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, at);
      const rect = domRange.getBoundingClientRect();
      if (el) {
        el.style.top = `${rect.top + window.pageYOffset + 24}px`;
        el.style.left = `${rect.left + window.pageXOffset}px`;
      }
    }
    if (activateMentionViaToolbar) {
      const el = ref.current;
      const rect = activateMentionViaToolbar.getBoundingClientRect();
      if (el) {
        el.style.top = `${rect.top + window.pageYOffset + 24}px`;
        el.style.left = `${rect.left + window.pageXOffset}px`;
      }
    }
  }, [fields.length, editor, at, activateMentionViaToolbar]);

  const escapeMention = (e) => {
    if (e.key === 'Escape') {
      closeMentionSelect();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escapeMention, false);
    return () => {
      document.removeEventListener('keydown', escapeMention);
    };
  }, []);

  if (!activateMentionViaToolbar) {
    if (!at || !fields.length) {
      return null;
    }
  }

  return (
    <>
      <div
        onClick={closeMentionSelect}
        css={{
          zIndex: 1999,
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
        }}
      />
      <PortalInOverlay>
        <div
          ref={ref}
          css={{
            top: '-9999px',
            left: '-9999px',
            position: 'absolute',
            zIndex: 2000,
            background: 'white',
            border: '1px solid #eee',
            minWidth: 220,
            maxWidth: 440,
            borderRadius: 5,
            overflow: 'hidden',
            boxShadow: '0px 4px 30px 0px rgba(220, 220, 220, 1)',
          }}
          {...other}
        >
          <div
            className="bbk-field-map-container"
            css={{
              paddingTop: '5px',
              paddingBottom: '5px',
              maxHeight: '195px',
              overflowY: 'auto',
            }}
          >
            {fields.map((field, i) => (
              <div
                key={`${i}${field.name}`}
                onMouseDown={(e) => {
                  e.preventDefault();
                  onMouseClickToolbarMention(i);
                  fallbackRef?.current.focus();
                }}
                css={{
                  background:
                    i === valueIndex
                      ? tailwindColors.cbrprimary['50']
                      : 'transparent',
                  height: '30px',
                  padding: '0 13px',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'normal',
                  borderRadius: '2px',
                  color: tailwindColors.darkestPurple,
                  '&:hover': {
                    background:
                      i === valueIndex
                        ? tailwindColors.cbrprimary['50']
                        : '#f0f0f0',
                  },
                }}
              >
                {field.name}
              </div>
            ))}
            {fields.length === 0 && (
              <div
                css={{
                  height: '30px',
                  padding: '0 13px',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'normal',
                  borderRadius: '2px',
                  color: tailwindColors.darkestPurple,
                }}
              >
                No fields found.
              </div>
            )}
          </div>

          {fallbackRef && (
            <div
              className="bbk-field-map-fallback"
              css={{
                padding: '5px 8px 10px',
                borderTop: '1px solid rgba(0,0,0,0.1)',
              }}
            >
              <Label id="fallbackTextToolbarLabel" label="Fallback Text" />
              <TextInput
                value={fallback}
                classes="dark short"
                placeholder="fallback value"
                onKeyUp={(e) => {
                  // insert on enter
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    ReactEditor.focus(editor);
                    onAddMention(editor, fields[valueIndex]);
                  }
                  if (e.key === 'Escape') {
                    ReactEditor.focus(editor);
                    closeMentionSelect();
                  }
                }}
                onChange={(e) => onChangeFallback(e.target.value)}
                ref={fallbackRef}
              />
            </div>
          )}

          <div
            className="bbk-field-map-actions"
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px 12px 10px 12px',
              cursor: 'auto',
              background: '#fafafa',
              borderTop: '1px solid rgba(0,0,0,0.1)',
            }}
          >
            <button
              className="bbk-button brightPurple"
              onClick={() => {
                onAddMention(editor, fields[valueIndex]);
              }}
            >
              Done
            </button>
            <button className="bbk-button red" onClick={closeMentionSelect}>
              Cancel
              {/* or Remove (when clicking an existing one is implemented) */}
            </button>
          </div>
        </div>
      </PortalInOverlay>
    </>
  );
};
