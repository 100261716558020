/* eslint-disable max-len,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import cs from 'classnames';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import {
  appNotPersistedSlice,
  selectNotPersistedMisc,
} from '@bbkAdminRedux/rtkq/app-not-persisted.slice';
import { useAppDispatch } from '@bbkAdminRedux/redux_utils';
import { PLANS } from '@bbkAdminRedux/rtkq/checkout.plans';
import {
  checkoutSlice,
  usePlanPurchaseIntent,
} from '@bbkAdminRedux/rtkq/checkout.slice';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';
import type { RTKAPIErrorResponse } from '@bbkAdminRedux/rtkq/rtkq-error-logger';
import { parseRTKAPIError } from '@bbkAdminRedux/rtkq/rtkq-error-logger';
import { selectCompanyInternalName } from '@bbkAdminRedux/app/selectors';
import { CBRIcons } from '@bbkAdminUtils/icons';
import { useInfoModal } from '@bbkAdminComponents/info-dialog';
import { recordError } from '@bbkAdminUtils/recordError';
import { Constants } from '@bbkUtils/constants/ui-constants';

const PlanBlock: ReactFCC<{
  title: string;
  description: string;
  cta: React.ReactNode;
  points: string[];
  decoration?: React.ReactNode;
  isLoading?: boolean;
}> = ({ title, description, cta, points, decoration, isLoading }) => (
  <div
    className={cs(
      'tw-w-1/2',
      'tw-border tw-border-cbrneutral-200 tw-rounded-lg tw-p-4',
      'tw-flex tw-flex-col',
      {
        'tw-justify-center tw-items-center tw-text-cbrneutral-300': isLoading,
        'tw-justify-between': !isLoading,
      }
    )}
  >
    {isLoading ? (
      CBRIcons.cbrLoader(100, 100)
    ) : (
      <>
        <div className="tw-flex tw-flex-col">
          {decoration}
          <div className="tw-text-3xl tw-font-bold tw-mb-4">{title}</div>
          <div className="tw-text-[14px] tw-font-bold tw-mb-4">
            {description}
          </div>
          <ul className="tw-text-cbrneutral-500 tw-text-xs tw-ml-0 tw-pl-4 tw-mb-6">
            {points.map((x, idx) => (
              <li className="tw-mb-2.5" key={idx}>
                {x}
              </li>
            ))}
          </ul>
        </div>

        <div className="tw-flex tw-flex-col tw-h-20">{cta}</div>
      </>
    )}
  </div>
);

const StarterUpgradeDialog: ReactFCC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const companyKey = useSelector(selectCompanyInternalName);

  const { data: subsHistory, isLoading: isSubsHistoryLoading } =
    checkoutSlice.endpoints.getSubscriptionsHistory.useQuery(
      { companyKey },
      { skip: !open || companyKey === '_' }
    );
  const wasOnEssentialsPreviously = subsHistory?.find(
    (x) => x.subscription_plan === PLANS.ESSENTIALS
  );

  const [planUpgradeIntent, { isLoading: isPlanUpgradeIntentLoading }] =
    checkoutSlice.endpoints.planUpgradeIntent.useMutation();

  const [planUpgrade, { isLoading: isPlanUpgradeLoading }] =
    checkoutSlice.endpoints.planUpgrade.useMutation();

  const { triggerModal } = useInfoModal();

  const handlePlanPurchaseIntent = usePlanPurchaseIntent();

  const handleStarterPremiumInterestedClick = () => {
    planUpgradeIntent({ companyKey, subscriptionPlan: PLANS.STARTER_PREMIUM })
      .unwrap()
      .then(() => {
        triggerModal({
          title: <>We’ll be in touch</>,
          description: (
            <>
              Starter Premium will be available soon. A member of our team will
              reach out when it’s available to discuss pricing and help you
              upgrade.
            </>
          ),
        });
        onClose();
      })
      .catch((e: RTKAPIErrorResponse) => {
        const err = parseRTKAPIError(e);
        BlackToastTrigger({
          content: 'We have failed to save your interest. Try again later.',
        });
        recordError(`planUpgradeIntent error: ${err}`);
      });
  };

  const handleDeflectionUpgradeClick = () => {
    planUpgrade({ companyKey, subscriptionPlan: PLANS.ESSENTIALS })
      .unwrap()
      .then(() => {
        triggerModal({
          title: <>You’re all set!</>,
          description: (
            <>
              Congrats on starting your 30 day Deflection trial. Get started by
              creating your loss aversion cards and offers.
            </>
          ),
        });
        onClose();
      })
      .catch((e: RTKAPIErrorResponse) => {
        const err = parseRTKAPIError(e);
        BlackToastTrigger({
          content: 'We could not upgrade. Try again later.',
        });
        recordError(`planUpgrade error: ${err}`);
      });
  };

  const decorationClasses = cs(
    'tw-w-1/2 tw-text-center tw-self-center',
    'tw-text-xs tw-uppercase',
    'tw-px-2 tw-py-0.5 -tw-mt-7 tw-mb-1.5',
    'tw-bg-white tw-border tw-rounded-2xl'
  );
  return (
    <Dialog
      fullWidth
      open={open}
      PaperProps={{
        style: { overflow: 'hidden', maxWidth: '680px' },
      }}
      onClose={onClose}
    >
      <div className="tw-px-8 tw-py-5">
        <div className={cs('tw-flex tw-justify-between', 'tw-text-lg tw-mb-6')}>
          <div>
            <span className="tw-font-medium">Upgrade your current plan:</span>{' '}
            <span className="tw-font-bold">Starter Free</span>
          </div>
          <div className="tw-cursor-pointer" onClick={onClose}>
            {CBRIcons.cbrClose(20, 20)}
          </div>
        </div>
        <div className="tw-flex tw-gap-5">
          <PlanBlock
            decoration={
              <div
                className={cs(
                  decorationClasses,
                  'tw-text-cbrprimary-800 tw-border-cbrprimary-800'
                )}
              >
                Coming soon
              </div>
            }
            title="Starter Premium"
            description="Starting at $25 per month"
            cta={
              <ButtonV2
                secondary
                onClick={handleStarterPremiumInterestedClick}
                disabled={isPlanUpgradeIntentLoading || isPlanUpgradeLoading}
              >
                {isPlanUpgradeIntentLoading && CBRIcons.cbrLoader()} I’m
                interested
              </ButtonV2>
            }
            points={[
              'Unlimited report history',
              'Unlock retention benchmarks',
              'Export reports',
            ]}
          />

          <PlanBlock
            isLoading={isSubsHistoryLoading}
            decoration={
              wasOnEssentialsPreviously ? (
                <div
                  className={cs(
                    decorationClasses,
                    'tw-text-cbrdanger-400 tw-border-cbrdanger-400'
                  )}
                >
                  Trial ended
                </div>
              ) : undefined
            }
            title="Deflection"
            description={
              wasOnEssentialsPreviously
                ? 'Starting at $250/month (first 500 sessions)'
                : '30 days trial'
            }
            cta={
              <>
                {wasOnEssentialsPreviously ? (
                  <a
                    href="mailto:global-am-team@chargebee.com?subject=Upgrade plan"
                    className="tw-block"
                  >
                    <ButtonV2
                      primary
                      className="tw-mb-2 tw-w-full"
                      onClick={() => {
                        handlePlanPurchaseIntent();
                      }}
                    >
                      Contact sales
                    </ButtonV2>
                  </a>
                ) : (
                  <ButtonV2
                    primary
                    className="tw-mb-2"
                    onClick={handleDeflectionUpgradeClick}
                    disabled={
                      isPlanUpgradeLoading || isPlanUpgradeIntentLoading
                    }
                  >
                    {isPlanUpgradeLoading && CBRIcons.cbrLoader()} Start 30 day
                    trial
                  </ButtonV2>
                )}
                <Link
                  to={{
                    pathname: Constants.PurchaseLink,
                  }}
                  target="_blank"
                  className="tw-text-sm tw-text-cbrprimary-600 tw-font-bold tw-my-1 tw-text-center"
                  rel="noreferrer"
                >
                  Pricing options
                </Link>
              </>
            }
            points={[
              'Everything included in Starter Premium',
              'Add loss aversion cards to your cancel page',
              'Create branded offers to retain more customers',
              'Custom audiences and targeting',
            ]}
          />
        </div>
      </div>
    </Dialog>
  );
};

export const useUpgradeDialog = () => {
  const dispatch = useAppDispatch();
  const { showUpgradeDialog } = useSelector(selectNotPersistedMisc);

  const isOpen = Boolean(showUpgradeDialog);

  const handleOpen = () => {
    dispatch(
      appNotPersistedSlice.actions.saveMisc({ showUpgradeDialog: true })
    );
  };

  const handleClose = () => {
    dispatch(
      appNotPersistedSlice.actions.saveMisc({ showUpgradeDialog: false })
    );
  };

  return {
    component: <StarterUpgradeDialog open={isOpen} onClose={handleClose} />,
    handleOpen,
  };
};
