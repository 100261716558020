// @ts-nocheck
import _ from 'lodash';
import { CardLayout } from '@bbkAdminComponents/experiences/offers/editor-layouts/card-layouts';
import { ElementType } from '@bbkAdminUtils/utility-types';
import { RestLossAversionCardOrModalCard } from '@bbkAdminUtils/api-client/rest-cards-types';

export interface OneOfFn<
  T extends RestLossAversionCardOrModalCard = RestLossAversionCardOrModalCard,
> {
  (groups: CardLayout<T>): (obj: T) => ElementType<CardLayout<T>>;
}

export const oneOf: OneOfFn = (groups) => {
  return (obj) => {
    return (
      groups.find((group) => _.get(obj, [group.key].join('.')) !== undefined) ||
      groups[0]
    );
  };
};
