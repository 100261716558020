import type { IntegrationFieldTypeEnum } from '@bbkAdminRedux/data-management/reducers';

const ns = 'DATA_MANAGEMENT__';
export const RETRIEVE_INTEGRATION_FIELDS_REQUEST = `${ns}RETRIEVE_INTEGRATION_FIELDS_REQUEST`;
export const SAVE_FIELD_MAPPINGS = `${ns}SAVE_FIELD_MAPPINGS`;
export const SET_INTEGRATION_FIELDS = `${ns}SET_INTEGRATION_FIELDS`;
export const SET_FIELD_MAPPING = `${ns}SET_FIELD_MAPPING`;
export const SET_FIELD_TYPE = `${ns}SET_FIELD_TYPE`;
export const CLEAR_FIELD_MAPPINGS = `${ns}CLEAR_FIELD_MAPPINGS`;
export const LOADED_FIELD_MAPPINGS = `${ns}LOADED_FIELD_MAPPINGS`;
export const SAVED_FIELD_MAPPINGS = `${ns}SAVED_FIELD_MAPPINGS`;
export const FAILED_SAVING_FIELD_MAPPINGS = `${ns}FAILED_SAVING_FIELD_MAPPINGS`;
export const ADD_CUSTOM_FIELD = `${ns}ADD_CUSTOM_FIELD`;
export const REMOVE_CUSTOM_FIELD = `${ns}REMOVE_CUSTOM_FIELD`;

export type SetFieldMappingArgs = {
  id?: string;
  del?: boolean;
  name: string;
  displayName: string;
  includeInCustomersReport: boolean;
  type: IntegrationFieldTypeEnum;
  standard: boolean;
  integrationId: number;
  extObjectName: string;
  extFieldName: string;
};

export function setFieldMapping({
  id,
  name,
  displayName,
  includeInCustomersReport,
  del,
  type,
  standard,
  integrationId,
  extObjectName,
  extFieldName,
}: SetFieldMappingArgs) {
  return {
    type: SET_FIELD_MAPPING,
    id,
    name,
    displayName,
    includeInCustomersReport,
    del,
    fieldType: type,
    standard,
    integrationId,
    extObjectName,
    extFieldName,
  };
}
