/* eslint-disable default-param-last */
import { produce } from 'immer';
import { chain } from 'lodash';
import type { Dictionary } from '@bbkAdminUtils/utility-types';
import type { DateString } from '@bbkAdminRedux/rtkq/current-user.slice';
import { reducer } from '@bbkAdminRedux/redux_utils';
import * as Action from '@bbkAdminRedux/data-management/actions';
import type { LegacySagaFieldMapping } from '@bbkAdminRedux/rtkq/integrations.slice';

export enum IntegrationFieldTypeEnum {
  text = 'text',
  boolean = 'boolean',
  date = 'date',
  integer = 'integer',
  decimal = 'decimal',
  email = 'email',
  url = 'url',
  external_id = 'external_id',
}

export type IntegrationField = {
  external_id?: string;
  object?: string;
  name: string;
  label: string;
  type: IntegrationFieldTypeEnum;
  standard?: boolean;
  maps_to_type: IntegrationFieldTypeEnum[];
};

export type IntegrationFieldWithId = IntegrationField & {
  integration_id: number;
};

type IntegrationFieldObjectWithExternalIdMapped = {
  name: string; // external_id object name. Eg 'Account.Org_ID', where 'Org_ID' is external_id and 'Account' is object name
  external_id: string;
};

export type IntegrationFieldObject = {
  requires_external_id: boolean;
  fields: IntegrationField[];
} & (undefined | IntegrationFieldObjectWithExternalIdMapped);

export type IntegrationFieldsResponse = {
  id: number; // integration id
  company_id: number;
  integration_type: IntegrationTypeEnum;
  data_type: string;
  display_name: string;
  objects: IntegrationFieldObject[];
};

export enum IntegrationTypeEnum {
  Messaging = 'MESSAGING',
  Billing = 'BILLING',
  System = 'SYSTEM',
  Crm = 'CRM',
}

export enum IntegrationDataType {
  SLACK = 'SLACK',
  INTERCOM = 'INTERCOM',
  TOTANGO = 'TOTANGO',
  SALESFORCE = 'SALESFORCE',
  SEGMENT = 'SEGMENT',
  BRIGHTBACKJS = 'BRIGHTBACKJS',
  STRIPE = 'STRIPE',
  STRIPE_API_KEY = 'STRIPE_API_KEY',
  RECURLY = 'RECURLY',
  RECHARGE = 'RECHARGE',
  CHARGEBEE = 'CHARGEBEE',
}

export type IntegrationInput = {
  name: string;
  display: string;
  options: string[];
  required: boolean;
  value?: string;
};

export enum IntegrationAuthType {
  oauth = 'oauth',
  other = 'other',
}

export type Integration = {
  id: number;
  integration_type: IntegrationTypeEnum;
  data_type: IntegrationDataType;
  display_name: string;
  description: string;
  url: string;
  created_at: DateString;
  updated_at: DateString;
  system: boolean;
  active: boolean;
  connected: boolean;
  auth_type: IntegrationAuthType;
  required_input?: IntegrationInput[];
  integration_status?: {
    id: number;
    application_id: number;
    integration_id: number;
    integrating_user_id: number;
    created_at: DateString;
    updated_at: DateString;
    active: boolean;
    integration_objects: {
      id: number;
      integration_status_id: number;
      object_name: string;
      external_id: string;
      created_at: DateString;
      updated_at: DateString;
      active: boolean;
    }[];
    last_good_use?: DateString;
    last_bad_use?: DateString;
    fix_message?: string;
  };
};

export type ProcessedObject = {
  fieldsByType: Dictionary<Pick<IntegrationField, 'name' | 'label'>[]>;
  info: {
    fields: Pick<IntegrationField, 'name' | 'label'>[];
    external_id: string;
    requires_external_id: boolean;
  };
};

export type ProcessedIntegration = {
  info: Integration;
  objects: Record<string, ProcessedObject>;
  warnings: {
    type: string;
    object?: {
      name: string;
    };
  }[];
};

export const FieldTypes: { name: IntegrationFieldTypeEnum; title: string }[] = [
  {
    name: IntegrationFieldTypeEnum.boolean,
    title: 'Boolean',
  },
  {
    name: IntegrationFieldTypeEnum.date,
    title: 'Date',
  },
  {
    name: IntegrationFieldTypeEnum.decimal,
    title: 'Decimal',
  },
  {
    name: IntegrationFieldTypeEnum.email,
    title: 'Email',
  },
  {
    name: IntegrationFieldTypeEnum.integer,
    title: 'Integer',
  },
  {
    name: IntegrationFieldTypeEnum.text,
    title: 'Text',
  },
  {
    name: IntegrationFieldTypeEnum.url,
    title: 'Url',
  },
];

export type State = {
  integrations: Dictionary<ProcessedIntegration>;
  integrationsLoaded: boolean;
  fieldMappingsLoaded: boolean;
  fieldMappings: Dictionary<LegacySagaFieldMapping>;
  fieldMappingError?: string;
  savedFieldMappingsAt?: number;
};

const defaultId = `~~~${Date.now()}`;
const initialState: State = {
  integrations: {},
  fieldMappings: {
    [defaultId]: {
      id: defaultId,
      name: '',
      displayName: '',
      includeInCustomersReport: true,
      standard: false,
      field: {},
    },
  },
  integrationsLoaded: false,
  fieldMappingsLoaded: false,
};

export type SetFieldMappingAction = {
  id?: string;
  del?: boolean;
  name: string;
  displayName: string;
  includeInCustomersReport: boolean;
  fieldType: IntegrationFieldTypeEnum;
  standard: boolean;
  integrationId: number;
  extObjectName: string;
  extFieldName: string;
};

type SetFieldTypeAction = {
  field: string; // field id
  fieldType: IntegrationFieldTypeEnum;
};

type SetIntegrationFieldsAction = {
  integration: Integration;
  fields?: IntegrationFieldsResponse;
  incomplete?: boolean;
};

type RemoveCustomFieldAction = {
  fieldId: string;
};

type FailedSavingFieldMappingsAction = {
  error: { message: string };
};

export default reducer(initialState, {
  [Action.LOADED_FIELD_MAPPINGS]: (state = initialState) =>
    produce(state, (draft) => {
      draft.fieldMappingsLoaded = true;
    }),

  [Action.SAVE_FIELD_MAPPINGS]: (state = initialState) =>
    produce(state, (draft) => {
      Object.values(draft.fieldMappings).forEach((draftFm) => {
        if (!draftFm) return;
        let warning;
        if (draftFm.name) {
          if (!draftFm.type) {
            warning = { type: 'Type is required' };
          } else if (
            !draftFm.field ||
            !draftFm.field.name ||
            !draftFm.integration_id
          ) {
            warning = {
              field: 'Source Field is required',
            };
          }

          draftFm.warning = {
            ...draftFm.warning,
            ...warning,
          };
        }
      });

      draft.fieldMappingError = undefined;
    }),

  [Action.SAVED_FIELD_MAPPINGS]: (state = initialState) =>
    produce(state, (draft) => {
      draft.savedFieldMappingsAt = new Date().getTime();
    }),

  [Action.CLEAR_FIELD_MAPPINGS]: (state = initialState) =>
    produce(state, (draft) => {
      draft.fieldMappings = initialState.fieldMappings;
      draft.fieldMappingsLoaded = false;
    }),

  [Action.FAILED_SAVING_FIELD_MAPPINGS]: (
    state = initialState,
    action: FailedSavingFieldMappingsAction
  ) =>
    produce(state, (draft) => {
      draft.fieldMappingError = action.error.message;
    }),

  [Action.SET_FIELD_TYPE]: (
    state = initialState,
    { field, fieldType }: SetFieldTypeAction
  ) =>
    produce(state, (draft) => {
      const fieldMapping = draft.fieldMappings[field];
      if (!fieldMapping || fieldType === fieldMapping.type) return;
      fieldMapping.type = fieldType;
      fieldMapping.field = {};
    }),

  [Action.SET_FIELD_MAPPING]: (
    state = initialState,
    action: SetFieldMappingAction
  ) => {
    const {
      id,
      del,
      name,
      displayName,
      includeInCustomersReport,
      fieldType,
      standard,
      integrationId,
      extObjectName,
      extFieldName,
    } = action;
    if (id && del) {
      return produce(state, (draft) => {
        delete draft.fieldMappings[id];
      });
    }

    return produce(state, (draft) => {
      const fmId = id || name;
      const nameConflict = Object.values(draft.fieldMappings).reduce(
        (ret, fm) => {
          if (!fm) return ret;
          const fmName = fm.name;
          if (fmName === undefined || name === undefined) {
            return false;
          }
          return (
            ret ||
            ((fm.id || fmName) !== fmId &&
              fmName.toLowerCase() === name.toLowerCase())
          );
        },
        false
      );

      draft.fieldMappings[fmId] = {
        id: fmId,
        name,
        displayName,
        includeInCustomersReport,
        standard,
        integration_id: integrationId,
        type: fieldType,
        field: {
          object: extObjectName === '_' ? undefined : extObjectName,
          name: extFieldName,
        },
        warning:
          name && nameConflict
            ? { name: 'Name in use. Choose a unique name.' }
            : undefined,
      };
    });
  },

  [Action.RETRIEVE_INTEGRATION_FIELDS_REQUEST]: (state = initialState) =>
    produce(state, (draft) => {
      draft.integrationsLoaded = false;
    }),

  [Action.SET_INTEGRATION_FIELDS]: (
    state = initialState,
    { integration, fields, incomplete }: SetIntegrationFieldsAction
  ) =>
    produce(state, (draft) => {
      const objects: ProcessedIntegration['objects'] = {};
      fields?.objects.forEach((objInfo) => {
        const objFields = objInfo.fields;
        const extIdFieldsByType = objFields
          .filter((f) => f.external_id)
          .map((f) => ({
            type: IntegrationFieldTypeEnum.external_id,
            name: f.name,
            label: f.label,
          }));
        const fieldsByType = objFields.flatMap((f) =>
          f.maps_to_type.map((t) => ({
            type: t,
            name: f.name,
            label: f.label,
          }))
        );

        const objName = objFields.find((f) => f.object)?.object || '_';
        objects[objName] = {
          fieldsByType: chain([...extIdFieldsByType, ...fieldsByType])
            .sortBy((x) => x.label.toLowerCase())
            .groupBy((x) => x.type)
            .value(),
          info: objInfo,
        };

        if (objInfo.requires_external_id && objInfo.external_id) {
          const fmId = `Instance ID_${integration.id}_${objName}`;
          draft.fieldMappings[fmId] = {
            id: fmId,
            name: fmId,
            displayName: fmId,
            type: IntegrationFieldTypeEnum.external_id,
            standard: true,
            integration_id: integration.id,
            includeInCustomersReport: true,
            field: {
              object: objName,
              name: objInfo.external_id,
            },
          };
        }
      });

      draft.integrations[integration.id] = {
        info: integration,
        objects,
        warnings: [],
      };
      if (!draft.integrationsLoaded) {
        draft.integrationsLoaded = incomplete !== true;
      }
    }),

  [Action.ADD_CUSTOM_FIELD]: (state = initialState) =>
    produce(state, (draft) => {
      const fmId = `~~~${Date.now()}`;
      draft.fieldMappings[fmId] = {
        id: fmId,
        name: '',
        displayName: '',
        standard: false,
        includeInCustomersReport: true,
        field: {},
      };
    }),

  [Action.REMOVE_CUSTOM_FIELD]: (
    state = initialState,
    action: RemoveCustomFieldAction
  ) =>
    produce(state, (draft) => {
      delete draft.fieldMappings[action.fieldId];
    }),
});

export enum AppFieldType {
  text = 'text',
  boolean = 'boolean',
  date = 'date',
  integer = 'integer',
  decimal = 'decimal',
  email = 'email',
  url = 'url',
}
