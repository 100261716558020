import React from 'react';

type Props = {
  options: string[];
  value: number;
  onChange: (value: number) => void;
};

const ColorSelector: React.FC<Props> = ({ options, value, onChange }) => {
  const selectedIndex = options.findIndex((option, index) => index === value);
  return (
    <div className="tw-flex">
      {options.map((option, index) => {
        const isSelected = index === selectedIndex;
        return (
          <button
            onClick={() => onChange(index)}
            className="tw-w-10 tw-h-10 tw-mx-2 tw-rounded-full"
            style={{
              backgroundColor: option,
              border: isSelected ? '3px solid #295E7D' : '',
              padding: isSelected ? '3px' : '0px',
            }}
          ></button>
        );
      })}
    </div>
  );
};

export default ColorSelector;
