import React from 'react';
import cs from 'classnames';

type Props = {
  remaining?: number;
};
const RemainingCounter: React.FC<Props> = ({ remaining }) => {
  // not a number = hidden
  // > 10 = hidden
  // 5-10 = warning
  // < 5 = danger
  const getState = (): 'hidden' | 'warning' | 'danger' => {
    if (typeof remaining === 'number') {
      if (remaining < 5) {
        return 'danger';
      }
      if (remaining <= 10) {
        return 'warning';
      }
    }
    return 'hidden';
  };
  return (
    <span className={cs('bbk-editor-text-remaining', getState())}>
      {remaining}
    </span>
  );
};

export default RemainingCounter;
