import React from 'react';
import RoundButton from '@bbkAdminComponents/buttons/round-button';
import { TourStoryProps } from './tour-story-index';

const ProductTourStoryWelcomeToBrightback: React.FC<TourStoryProps> = ({
  onClick,
}) => (
  <div className="bbk-product-tour-story-template bbk-product-tour-story-welcome-to-brightback">
    <article>
      <h1>Welcome to Chargebee Retention! 🎉</h1>
      <p>
        Chargebee Retention is the simplest way for you to test, target and
        personalize your cancel flows to learn from and retain more customers.
      </p>
      <p>There are three main components of Chargebee Retention:</p>
      <ul>
        <li>
          The <strong>Chargebee Retention App</strong>, where you come to view
          performance dashboards and churn intelligence, configure online
          experiences, create custom audiences, and manage integrations and
          billing
        </li>
        <li>
          The customer-facing <strong>User Experiences</strong> that Chargebee
          Retention hosts on your behalf
        </li>
        <li>
          The underlying, real-time{' '}
          <strong>Chargebee Retention Event Feed</strong> that drives workflow
          automation and data syncing.
        </li>
      </ul>
      <p>
        <strong>Ready to get started?</strong>
      </p>
      <footer>
        <p>
          <RoundButton onClick={onClick}>
            Learn about the Deflection Funnel
          </RoundButton>
        </p>
      </footer>
    </article>
  </div>
);

export default {
  name: 'Welcome to Chargebee Retention',
  key: 'bbk-pt-welcome-to-brightback',
  story: <ProductTourStoryWelcomeToBrightback />,
};
