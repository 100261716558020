import type { TypedUseQueryStateResult } from '@reduxjs/toolkit/query/react';
import type { BBK_FETCH_BASE_QUERY } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';

export enum ONBOARDING_HISTORY_STEP {
  // Step 1
  CONNECT_BILLING = 'CONNECT_BILLING',

  // Step 2
  BRANDING = 'BRANDING',
  CREATE_OFFERS = 'CREATE_OFFERS',
  CREATE_PAGES = 'CREATE_PAGES',

  // Step 3
  TECHNICAL_INSTALLATION = 'TECHNICAL_INSTALLATION',
  INTEGRATE_BILLING_SYSTEM = 'INTEGRATE_BILLING_SYSTEM',
  ALERTS = 'ALERTS',

  FIELD_MAPPINGS = 'FIELD_MAPPINGS',
  REPORTING_REVENUE_FIELD = 'REPORTING_REVENUE_FIELD',

  DOMAIN = 'DOMAIN',
  URL_AND_PAGES = 'URL_AND_PAGES',

  // Step 4
  GO_LIVE = 'GO_LIVE',
}

export enum ONBOARDING_HISTORY_STATUS {
  INCOMPLETE = 'INCOMPLETE',
  COMPLETED = 'COMPLETED',
}

type GetOnboardingHistoryResponse = {
  step: ONBOARDING_HISTORY_STEP;
  status: ONBOARDING_HISTORY_STATUS;
}[];
type GetOnboardingHistoryArgs = {
  appKey: string;
};

type UpdatedOnboardingHistoryArgs = {
  appKey: string;
  step: ONBOARDING_HISTORY_STEP;
  status: ONBOARDING_HISTORY_STATUS;
};

type GoLiveArgs = {
  appKey: string;
};

export const onboardingHistorySlice = bbkApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOnboardingHistory: builder.query<
      GetOnboardingHistoryResponse,
      GetOnboardingHistoryArgs
    >({
      query: ({ appKey }) => `/api/v1/apps/${appKey}/onboarding`,
      providesTags: ['OnboardingHistory'],
    }),
    getOnboardingHistorySteps: builder.query<
      GetOnboardingHistoryResponse,
      GetOnboardingHistoryArgs
    >({
      query: ({ appKey }) => `/api/v1/apps/${appKey}/step_histories`,
      providesTags: ['OnboardingStepsHistory'],
    }),
    updateOnboardingHistory: builder.mutation<
      void,
      UpdatedOnboardingHistoryArgs
    >({
      query: ({ appKey, ...body }) => ({
        url: `/api/v1/apps/${appKey}/onboarding`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OnboardingHistory'],
    }),
    goLive: builder.mutation<void, GoLiveArgs>({
      query: ({ appKey }) => ({
        url: `/api/v1/apps/${appKey}/go_live`,
        method: 'POST',
      }),
      invalidatesTags: ['OnboardingHistory', 'CurrentApp'],
    }),
  }),
});

type OnboardingHistorySelectorArgs = TypedUseQueryStateResult<
  GetOnboardingHistoryResponse,
  GetOnboardingHistoryArgs,
  typeof BBK_FETCH_BASE_QUERY
>;

export const selectOnboardingHistoryMap = (
  res: OnboardingHistorySelectorArgs
): Omit<OnboardingHistorySelectorArgs, 'data'> & {
  data: Partial<Record<ONBOARDING_HISTORY_STEP, boolean>>;
} => {
  const map: Partial<Record<ONBOARDING_HISTORY_STEP, boolean>> =
    Object.fromEntries(
      (res.data ?? []).map((x) => [
        x.step,
        x.status === ONBOARDING_HISTORY_STATUS.COMPLETED,
      ])
    );
  return {
    ...res,
    data: map,
  };
};
