import _ from 'lodash';
import type { EmotionTheme } from '@bbkFunnel/pages/theme';
import { ThemeDefaults, ThemeKeys, ThemePaths } from '@bbkFunnel/pages/theme';
import type { RestExperience } from '@bbkAdminUtils/api-client/rest-experiences-api';
import type { Paths } from '@bbkAdminUtils/utility-types';
import type { RestBranding } from '@bbkAdminRedux/rtkq/experience.slice';
import { recordError } from '@bbkAdminUtils/recordError';

const getFontDefaultHref = (props: Pick<RestExperience, 'layout'>): string => {
  const basePath = 'layout.style_overlay';
  return _.get(
    props,
    `${basePath}.${ThemePaths.FONT_EMBED_URL}`,
    ThemeDefaults.FONT_EMBED_URL
  );
};

export const findElement: typeof document.querySelector = (
  selector: string
) => {
  try {
    return document.querySelector(selector);
  } catch (e) {
    recordError(e);
    return undefined;
  }
};

export const embedFont = (props: Pick<RestExperience, 'layout'>): void => {
  // check font href
  const href = getFontDefaultHref(props);
  if (href && href !== '') {
    if (!findElement(`link[href="${href}"]`)) {
      const link = document.createElement('link');
      link.href = href;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      document.body.appendChild(link);
    }
  }
};

export const prefetchImages = (props: Pick<RestExperience, 'layout'>): void => {
  const images: string[] = props.layout.cards
    .filter((card) => card.deploy === 'modal')
    .map(
      (card) =>
        _.get(card, 'requires.hero.url') ||
        _.get(card, 'requires.person.avatar')
    )
    .filter(Boolean);

  // delay to be after other resources
  setTimeout(() => {
    const links = images
      .filter((image) => !findElement(`link[href="${image}"]`))
      .map((image) => {
        const link = document.createElement('link');
        link.href = image;
        link.rel = 'prefetch';
        return link;
      });
    document.body.append(...links);
  }, 1000);
};

const basePath: Paths<Pick<RestBranding, 'style_overlay'>> = 'style_overlay';
export const processTheme = ({
  layout,
}: {
  layout: Pick<RestBranding, 'style_overlay'>;
}): EmotionTheme => ({
  [ThemeKeys.BACKGROUND]: _.get(
    layout,
    `${basePath}.${ThemePaths.BACKGROUND}`,
    ThemeDefaults.BACKGROUND
  ),
  [ThemeKeys.CARD_BACKGROUND]: _.get(
    layout,
    `${basePath}.${ThemePaths.CARD_BACKGROUND}`,
    ThemeDefaults.CARD_BACKGROUND
  ),
  [ThemeKeys.MODAL_BACKGROUND]: _.get(
    layout,
    `${basePath}.${ThemePaths.MODAL_BACKGROUND}`,
    ThemeDefaults.MODAL_BACKGROUND
  ),
  [ThemeKeys.FONT_FAMILY]: _.get(
    layout,
    `${basePath}.${ThemePaths.FONT_FAMILY}`,
    ThemeDefaults.FONT_FAMILY
  ),
  [ThemeKeys.FONT_EMBED_URL]: _.get(
    layout,
    `${basePath}.${ThemePaths.FONT_EMBED_URL}`,
    ThemeDefaults.FONT_EMBED_URL
  ),
  [ThemeKeys.FONT_SIZE]: _.get(
    layout,
    `${basePath}.${ThemePaths.FONT_SIZE}`,
    ThemeDefaults.FONT_SIZE
  ),
  [ThemeKeys.FONT_WEIGHT]: _.get(
    layout,
    `${basePath}.${ThemePaths.FONT_WEIGHT}`,
    ThemeDefaults.FONT_WEIGHT
  ),
  [ThemeKeys.PRIMARY_COLOR]:
    _.get(layout, `${basePath}.${ThemePaths.PRIMARY_COLOR}`) ||
    _.get(layout, `${basePath}.primary1Color`, ThemeDefaults.PRIMARY_COLOR),
  [ThemeKeys.TEXT_COLOR]: _.get(
    layout,
    `${basePath}.${ThemePaths.TEXT_COLOR}`,
    ThemeDefaults.TEXT_COLOR
  ),
  [ThemeKeys.BORDER_RADIUS]: _.get(
    layout,
    `${basePath}.${ThemePaths.BORDER_RADIUS}`,
    ThemeDefaults.BORDER_RADIUS
  ),
  [ThemeKeys.BUTTON_BORDER_RADIUS]: _.get(
    layout,
    `${basePath}.${ThemePaths.BUTTON_BORDER_RADIUS}`,
    ThemeDefaults.BUTTON_BORDER_RADIUS
  ),
  [ThemeKeys.BUTTON_TEXT_COLOR]: _.get(
    layout,
    `${basePath}.${ThemePaths.BUTTON_TEXT_COLOR}`,
    ThemeDefaults.BUTTON_TEXT_COLOR
  ),
  [ThemeKeys.MODAL_TEXT_COLOR]: _.get(
    layout,
    `${basePath}.${ThemePaths.MODAL_TEXT_COLOR}`,
    ThemeDefaults.MODAL_TEXT_COLOR
  ),
  [ThemeKeys.BUTTON_BACKGROUND_COLOR]: _.get(
    layout,
    `${basePath}.${ThemePaths.BUTTON_BACKGROUND_COLOR}`,
    ThemeDefaults.BUTTON_BACKGROUND_COLOR
  ),
  [ThemeKeys.BUTTON_TEXT_TRANSFORM]: _.get(
    layout,
    `${basePath}.${ThemePaths.BUTTON_TEXT_TRANSFORM}`,
    ThemeDefaults.BUTTON_TEXT_TRANSFORM
  ),
  [ThemeKeys.BUTTON_FONT_WEIGHT]: _.get(
    layout,
    `${basePath}.${ThemePaths.BUTTON_FONT_WEIGHT}`,
    ThemeDefaults.BUTTON_FONT_WEIGHT
  ),
  [ThemeKeys.BUTTON_FONT_SIZE]: _.get(
    layout,
    `${basePath}.${ThemePaths.BUTTON_FONT_SIZE}`,
    ThemeDefaults.BUTTON_FONT_SIZE
  ),
  [ThemeKeys.BUTTON_FONT_FAMILY]: _.get(
    layout,
    `${basePath}.${ThemePaths.BUTTON_FONT_FAMILY}`,
    ThemeDefaults.BUTTON_FONT_FAMILY
  ),
  [ThemeKeys.BOX_SHADOW]: _.get(
    layout,
    `${basePath}.${ThemePaths.BOX_SHADOW}`,
    ThemeDefaults.BOX_SHADOW
  ),
  [ThemeKeys.BORDER]: _.get(
    layout,
    `${basePath}.${ThemePaths.BORDER}`,
    ThemeDefaults.BORDER
  ),
  [ThemeKeys.ERROR_VAL]: _.get(
    layout,
    `${basePath}.${ThemePaths.ERROR_VAL}`,
    ThemeDefaults.ERROR_VAL
  ),
  [ThemeKeys.GLOBAL_CSS]: _.get(
    layout,
    `${basePath}.${ThemePaths.GLOBAL_CSS}`,
    ThemeDefaults.GLOBAL_CSS
  ),
  [ThemeKeys.LOGO_CENTER]: _.get(
    layout,
    `${basePath}.${ThemePaths.LOGO_CENTER}`,
    ThemeDefaults.LOGO_CENTER
  ),
  [ThemeKeys.LOGO_MAX_WIDTH]: _.get(
    layout,
    `${basePath}.${ThemePaths.LOGO_MAX_WIDTH}`,
    ThemeDefaults.LOGO_MAX_WIDTH
  ),
  [ThemeKeys.HEADER_BORDER]: _.get(
    layout,
    `${basePath}.${ThemePaths.HEADER_BORDER}`,
    ThemeDefaults.HEADER_BORDER
  ),
  [ThemeKeys.HEADER_FULL_WIDTH]: _.get(
    layout,
    `${basePath}.${ThemePaths.HEADER_FULL_WIDTH}`,
    ThemeDefaults.HEADER_FULL_WIDTH
  ),
  [ThemeKeys.TEXT_AREA_BORDER_RADIUS]: _.get(
    layout,
    `${basePath}.${ThemePaths.TEXT_AREA_BORDER_RADIUS}`,
    ThemeDefaults.TEXT_AREA_BORDER_RADIUS
  ),
  [ThemeKeys.TITLE_FONT_FAMILY]: _.get(
    layout,
    `${basePath}.${ThemePaths.TITLE_FONT_FAMILY}`,
    ThemeDefaults.TITLE_FONT_FAMILY
  ),
  [ThemeKeys.TITLE_FONT_SIZE]: _.get(
    layout,
    `${basePath}.${ThemePaths.TITLE_FONT_SIZE}`,
    ThemeDefaults.TITLE_FONT_SIZE
  ),
  [ThemeKeys.TITLE_FONT_WEIGHT]: _.get(
    layout,
    `${basePath}.${ThemePaths.TITLE_FONT_WEIGHT}`,
    ThemeDefaults.TITLE_FONT_WEIGHT
  ),
  [ThemeKeys.TITLE_FONT_COLOR]: _.get(
    layout,
    `${basePath}.${ThemePaths.TITLE_FONT_COLOR}`,
    ThemeDefaults.TITLE_FONT_COLOR
  ),
  [ThemeKeys.MODAL_TITLE_FONT_COLOR]: _.get(
    layout,
    `${basePath}.${ThemePaths.MODAL_TITLE_FONT_COLOR}`,
    ThemeDefaults.MODAL_TITLE_FONT_COLOR
  ),
  [ThemeKeys.TITLE_TEXT_TRANSFORM]: _.get(
    layout,
    `${basePath}.${ThemePaths.TITLE_TEXT_TRANSFORM}`,
    ThemeDefaults.TITLE_TEXT_TRANSFORM
  ),
  [ThemeKeys.SUBMIT_BUTTON_ORDER]: _.get(
    layout,
    `${basePath}.${ThemePaths.SUBMIT_BUTTON_ORDER}`,
    ThemeDefaults.SUBMIT_BUTTON_ORDER
  ),
  [ThemeKeys.SUBMIT_BUTTON_MODE]: _.get(
    layout,
    `${basePath}.${ThemePaths.SUBMIT_BUTTON_MODE}`,
    ThemeDefaults.SUBMIT_BUTTON_MODE
  ),
  [ThemeKeys.FORM_REQUIRED_LABEL]: _.get(
    layout,
    `${basePath}.${ThemePaths.FORM_REQUIRED_LABEL}`,
    ThemeDefaults.FORM_REQUIRED_LABEL
  ),
  [ThemeKeys.FORM_REQUIRED_FIELD_ERROR]: _.get(
    layout,
    `${basePath}.${ThemePaths.FORM_REQUIRED_FIELD_ERROR}`,
    ThemeDefaults.FORM_REQUIRED_FIELD_ERROR
  ),
  [ThemeKeys.SURVEY_ERROR_BOX_TITLE]: _.get(
    layout,
    `${basePath}.${ThemePaths.SURVEY_ERROR_BOX_TITLE}`,
    ThemeDefaults.SURVEY_ERROR_BOX_TITLE
  ),
  [ThemeKeys.SURVEY_ERROR_BOX_CONTENT]: _.get(
    layout,
    `${basePath}.${ThemePaths.SURVEY_ERROR_BOX_CONTENT}`,
    ThemeDefaults.SURVEY_ERROR_BOX_CONTENT
  ),
  [ThemeKeys.DICTIONARY_NONE]: _.get(
    layout,
    `${basePath}.${ThemePaths.DICTIONARY_NONE}`,
    ThemeDefaults.DICTIONARY_NONE
  ),
  [ThemeKeys.DICTIONARY_OTHER]: _.get(
    layout,
    `${basePath}.${ThemePaths.DICTIONARY_OTHER}`,
    ThemeDefaults.DICTIONARY_OTHER
  ),
  [ThemeKeys.OFFER_CARD_BADGE_COLORS]: _.get(
    layout,
    `${basePath}.${ThemePaths.OFFER_CARD_BADGE_COLORS}`,
    ThemeDefaults.OFFER_CARD_BADGE_COLORS
  ),
  [ThemeKeys.OFFER_CARD_BADGE_TEXT_COLORS]: _.get(
    layout,
    `${basePath}.${ThemePaths.OFFER_CARD_BADGE_TEXT_COLORS}`,
    ThemeDefaults.OFFER_CARD_BADGE_TEXT_COLORS
  ),
});
