import React from 'react';
import type { ActiveCompany } from '@bbkAdminRedux/app/reducers';
import { CBRetentionLogo } from '@bbkAdminComponents/cbretention-logo';

export const NoApps: React.FC<{ company: ActiveCompany }> = ({ company }) => (
  <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
    <div>
      <div className="tw-w-[160px]">
        <CBRetentionLogo />
      </div>
      <br />
      You haven&quot;t been invited to any application. <br />
      Ask admin of your company{' '}
      <span className="tw-font-bold">{company?.name}</span> to add you to
      applications.
    </div>
  </div>
);
