import type { RestCard } from '@bbkAdminUtils/api-client/rest-experiences-api';
import type {
  ModalContentSetItem,
  RestModalForwards,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import type { PatchPath } from '@bbkAdminUtils/utility-types';
import _ from 'lodash';

export const collectForwards = (card: RestCard) => {
  const result: { path: PatchPath; value: RestModalForwards }[] = [];
  if (!card) return [];

  const content_set = _.get(card, ['requires', 'content_set']) as
    | ModalContentSetItem[]
    | undefined;
  if (content_set) {
    content_set?.forEach((content, index) => {
      const path = ['requires', 'content_set', index, 'forwards'];
      const fwd = _.get(card, path);
      result.push({
        path: path,
        value: fwd,
      });
    });
    return result;
  }

  const cardRequires = card.requires;
  const forwards: RestModalForwards | undefined =
    'forwards' in cardRequires ? cardRequires.forwards : undefined;
  const genericActions: RestModalForwards[] | undefined =
    'generic_actions' in cardRequires
      ? cardRequires.generic_actions
      : undefined;
  const otherActions: RestModalForwards[] | undefined =
    'other_actions' in cardRequires ? cardRequires.other_actions : undefined;

  if (forwards) {
    result.push({
      path: ['requires', 'forwards'],
      value: forwards,
    });
  }

  if (genericActions) {
    result.push(
      ...genericActions.map((ga, idx) => ({
        path: ['requires', 'generic_actions', idx],
        value: ga,
      }))
    );
  }

  if (otherActions) {
    result.push(
      ...otherActions.map((oa, idx) => ({
        path: ['requires', 'other_actions', idx],
        value: oa,
      }))
    );
  }
  return result;
};
