import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import { RevenueSource, RevenueSourceType } from '@bbkAdminRedux/app/reducers';
import { DEFAULT_BRAND_ID } from '@bbkAdminRedux/rtkq/brands.slice';

type CompanySettings = {
  testing_email_blacklist: string[];
};

type UpdateAppSettingsArgs = {
  uniqueSavePeriod?: number;
  saveInterval?: number;
  cancelInterval?: number;
  userSessionInterval?: number;
  revenueSource?: RevenueSource;
  revenueSourceType?: RevenueSourceType;
} & {
  appKey: string;
  companyInternalName: string;
};

export type GlobalUrls = {
  global_save_url: string;
  global_cancel_url: string;
  global_fallback_url: string;
};

export type AppSettingsResponse = {
  has_billing_subscriptions: boolean;
  has_revenue: boolean;
};

export const companyAppSettingsSlice = bbkApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCompanySettings: build.query<
      CompanySettings,
      { companyInternalName: string }
    >({
      query: (args) => `/api/v1/companies/${args.companyInternalName}/settings`,
      providesTags: ['CompanySettings'],
    }),
    getAppSettings: build.query<AppSettingsResponse, { appKey: string }>({
      query: ({ appKey }) => `/api/v1/apps/${appKey}/settings`,
      providesTags: ['AppSettings'],
    }),
    updateCompanySettings: build.mutation<
      CompanySettings,
      { companyInternalName: string } & CompanySettings
    >({
      query: ({ companyInternalName, ...body }) => ({
        method: 'POST',
        url: `/api/v1/companies/${companyInternalName}/settings`,
        body,
      }),
      invalidatesTags: ['CompanySettings'],
    }),
    updateAppSettings: build.mutation<
      { success: boolean },
      UpdateAppSettingsArgs
    >({
      query: ({ companyInternalName, appKey, ...body }) => ({
        url: `/api/v1/company/${companyInternalName}/apps/${appKey}/settings`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CurrentApp', 'AppSettings'],
    }),
    getGlobalUrls: build.query<
      GlobalUrls,
      { appKey: string; brandId?: string }
    >({
      query: ({ appKey, brandId = DEFAULT_BRAND_ID }) =>
        `/api/v1/apps/${appKey}/brands/${brandId}/global_urls`,
      providesTags: (result, error, arg, meta) =>
        arg.brandId
          ? [
              {
                type: 'GlobalUrls',
                id: arg.brandId,
              },
            ]
          : ['GlobalUrls'],
    }),
    putGlobalUrls: build.mutation<
      GlobalUrls,
      { appKey: string; globalUrls: GlobalUrls; brandId?: string }
    >({
      query: ({ appKey, globalUrls, brandId = DEFAULT_BRAND_ID }) => ({
        url: `/api/v1/apps/${appKey}/brands/${brandId}/global_urls`,
        method: 'PUT',
        body: globalUrls,
      }),
      invalidatesTags: (result, error, arg, meta) =>
        arg.brandId
          ? [
              {
                type: 'AdditionalBrands',
                id: arg.brandId,
              },
              {
                type: 'GlobalUrls',
                id: arg.brandId,
              },
            ]
          : ['GlobalUrls'],
    }),
  }),
});
