// @ts-nocheck
import React from 'react';
import {
  RestModalForwards,
  RestModalForwardsAcceptOffer,
  RestModalForwardsIntercomChat,
  RestModalForwardsLink,
  RestModalForwardsModalEmail,
  RestModalForwardsSendEmail,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import {
  booleanConfig,
  GroupLayout,
  iconEditorConfig,
  textAreaConfig,
  textEditorConfig,
  urlTextEditorConfig,
} from '@bbkAdminComponents/experiences/offers/editor-layouts/group-layouts';

export const LinkActionGroupLayout: GroupLayout<RestModalForwardsLink> = [
  { key: 'text' },
  {
    key: 'url',
    editor: {
      label: 'Link destination',
      ...textEditorConfig,
      enabledButtons: {
        bold: false,
        italic: false,
        underline: false,
        link: false,
        urlFields: true,
      },
      stripDivWrapperOnChange: true,
      outputType: 'plaintext',
      fieldType: 'url',
    },
  },
  {
    key: 'icon',
    editor: iconEditorConfig,
  },
];

export const CancelLinkActionGroupLayout: GroupLayout<RestModalForwardsLink> = [
  { key: 'text' },
  {
    key: 'url',
    editor: {
      ...textEditorConfig,
      enabledButtons: {
        bold: false,
        italic: false,
        underline: false,
        link: false,
        urlFields: true,
      },
      outputType: 'plaintext',
      fieldType: 'url',
    },
  },
  {
    key: 'icon',
    editor: iconEditorConfig,
  },
];

export const NevermindLinkActionGroupLayout: GroupLayout<RestModalForwardsLink> =
  [
    { key: 'text' },
    {
      key: 'url',
      editor: {
        ...textEditorConfig,
        enabledButtons: {
          bold: false,
          italic: false,
          underline: false,
          link: false,
          urlFields: true,
        },
        outputType: 'plaintext',
        fieldType: 'url',
      },
    },
    {
      key: 'icon',
      editor: iconEditorConfig,
    },
  ];

export const EmailSuccessSubtextLayout: GroupLayout<RestModalForwards> = [
  { key: 'subText' },
  { key: 'cancelText' },
  {
    key: 'hideCancelAction',
    editor: { label: 'Hide cancel action', ...booleanConfig },
  },
  {
    key: 'cancelIcon',
    editor: iconEditorConfig,
  },
];

export const IntercomChatActionGroupLayout: GroupLayout<RestModalForwardsIntercomChat> =
  [
    { key: 'text' },
    { key: 'initial_message', editor: textAreaConfig },
    {
      key: 'icon',
      editor: iconEditorConfig,
    },
  ];

export const AcceptOfferActionGroupLayout: GroupLayout<RestModalForwardsAcceptOffer> =
  [
    { key: 'text' },
    {
      key: 'icon',
      editor: iconEditorConfig,
    },
  ];

export const ModalEmailActionGroupLayout: GroupLayout<RestModalForwardsModalEmail> =
  [
    { key: 'text' },
    {
      key: 'icon',
      editor: iconEditorConfig,
    },
  ];

export const DismissModalAction: GroupLayout<RestModalForwardsModalEmail> = [
  { key: 'text' },
  {
    key: 'icon',
    editor: iconEditorConfig,
  },
];

export const SendEmailActionGroupLayout: GroupLayout<RestModalForwardsSendEmail> =
  [
    {
      key: 'sync.message',
      editor: {
        ...textAreaConfig,
        label: 'Email message to send',
      },
    },
    { key: 'text' },
    {
      key: 'url',
      editor: {
        label: 'Email to send Feedback to',
        subtype: 'email',
        ...urlTextEditorConfig,
      },
    },
    {
      key: 'icon',
      editor: iconEditorConfig,
    },
  ];
