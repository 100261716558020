import React from 'react';
import './form-section.scss';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';

type Props = {
  title: React.ReactNode;
};

const FormSection: ReactFCC<Props> = (props) => {
  const { title, children } = props;
  return (
    <div className="bbk-section row">
      <div className="bbk-section-title col-md-3">{title}</div>
      <div className="bbk-section-content col-md-9">{children}</div>
    </div>
  );
};

export default FormSection;
