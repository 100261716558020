import { popupCenter } from '@bbkAdminUtils/popup';
import { pollFor } from '@bbkAdminUtils/poll-for';
import type { BbkClientConfig } from '@bbkRoot/typings';
import { encodeObject } from '@bbkAdminRedux/rtkq/rtkq-utils';
import type { SignInTokens } from '@bbkAdminRedux/rtkq/brightback-auth.slice';
import { SignInType } from '@bbkAdminRedux/rtkq/brightback-auth.slice';
import { getNowInSeconds } from '@bbkAdminUtils/utils';

export type GoogleTokens = {
  access_token: string;
  refresh_token: string;
  id_token: string;
  expires_in: number;
};

export type GoogleRefreshedTokens = Omit<GoogleTokens, 'refresh_token'>;

export type GoogleSignInArgs = {};

export class AuthGoogle {
  private config: BbkClientConfig;

  constructor(config: BbkClientConfig) {
    this.config = config;
  }

  private BASE_URL = window.location.origin;

  private LOGOUT_REDIRECT_URL = `${this.BASE_URL}/company?logout`;

  private LOGIN_REDIRECT_URL = `${this.BASE_URL}/company`;

  private get logoutURL() {
    const query = encodeObject({
      client_id: this.config.cognito_web_client_id,
      logout_uri: this.LOGOUT_REDIRECT_URL,
    });
    return `https://${this.config.cognito_domain}/logout?${query}`;
  }

  private get tokenURL() {
    return `https://${this.config.cognito_domain}/oauth2/token`;
  }

  private get revokeURL() {
    return `https://${this.config.cognito_domain}/oauth2/revoke`;
  }

  private get authURL() {
    const query = encodeObject({
      redirect_uri: this.LOGIN_REDIRECT_URL,
      response_type: 'code',
      client_id: this.config.cognito_web_client_id,
      identity_provider: 'Google',
    });
    return `https://${this.config.cognito_domain}/oauth2/authorize?${query}`;
  }

  private transformGoogleTokens(tokens: GoogleTokens): SignInTokens {
    return {
      type: SignInType.GOOGLE,
      access_token: tokens.id_token,
      refresh_token: tokens.refresh_token,
      exp: getNowInSeconds() + tokens.expires_in,
    };
  }

  private exchangeCodeForTokens = (code: string): Promise<SignInTokens> =>
    fetch(this.tokenURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeObject({
        grant_type: 'authorization_code',
        client_id: this.config.cognito_web_client_id,
        redirect_uri: this.LOGIN_REDIRECT_URL,
        code,
      }),
    }).then(async (response) => {
      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      return this.transformGoogleTokens(result as GoogleTokens);
    });

  public doRefresh = (refreshToken: string): Promise<SignInTokens> =>
    fetch(this.tokenURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeObject({
        grant_type: 'refresh_token',
        client_id: this.config.cognito_web_client_id,
        refresh_token: refreshToken,
      }),
    }).then(async (response) => {
      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      const tokens = result as GoogleRefreshedTokens;
      return this.transformGoogleTokens({
        ...tokens,
        refresh_token: refreshToken,
      });
    });

  public signOut = async (refreshToken?: string) => {
    await fetch(this.logoutURL, {
      method: 'GET',
      mode: 'no-cors',
    });

    if (refreshToken) {
      await fetch(this.revokeURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        mode: 'no-cors',
        body: encodeObject({
          token: refreshToken,
          client_id: this.config.cognito_web_client_id,
        }),
      });
    }
  };

  private pollForRedirectUrl = pollFor((win) => {
    const href = win.location.href;
    return href.includes(this.LOGOUT_REDIRECT_URL);
  });

  private pollForCode = pollFor((win) => {
    const url = new URL(win.location.href);
    return url.searchParams.get('code');
  });

  public signIn = async (args?: GoogleSignInArgs): Promise<SignInTokens> => {
    const pop = popupCenter(this.logoutURL, 'Google Log in', 640, 640);
    if (!pop) throw new Error('Could not log in via Google');

    await this.pollForRedirectUrl(pop);
    pop.location = this.authURL;

    const code = await this.pollForCode(pop);
    if (!code) throw new Error('Could not log in via Google');

    const tokens = await this.exchangeCodeForTokens(code);
    pop.close();
    return tokens;
  };
}
