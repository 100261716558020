import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import type { ActiveCompany } from '@bbkAdminRedux/app/reducers';
import { selectCompany } from '@bbkAdminRedux/app/selectors';
import { PLANS } from '@bbkAdminRedux/rtkq/checkout.plans';
import { useSelectCurrentCompany } from '@bbkAdminRedux/rtkq/current-user.slice';

export function useHasSubscriptionId() {
  const company = useSelector(selectCompany);

  return useMemo(() => !!company?.external_billing_id, [company]);
}

export function useHasSubscription() {
  const company = useSelector(selectCompany);
  const hasSubId = useHasSubscriptionId();

  return useMemo(() => {
    return hasSubId && !company.trial;
  }, [hasSubId, company]);
}

function useCommonPlans(company: ActiveCompany | undefined) {
  const plan = company?.plan;
  const checkPlan = (plans: PLANS[]): boolean => !plan || plans.includes(plan);

  const isTrial = () => company?.trial ?? false;

  const isStarterFreePlan = () => checkPlan([PLANS.STARTER_FREE]);
  const isStarterPremiumPlan = () => checkPlan([PLANS.STARTER_PREMIUM]);
  const isEssentialsPlan = () => checkPlan([PLANS.ESSENTIALS]);
  const isPerformancePlan = () => checkPlan([PLANS.PERFORMANCE]);
  const isEnterprisePlan = () => checkPlan([PLANS.ENTERPRISE]);

  function checkAccess({
    check,
    fallback,
  }: {
    check: boolean;
    fallback: boolean;
  }) {
    return plan ? check : fallback;
  }

  // Trial || Essentials
  // Only 3 pages allowed
  const pageCreationLimit = checkAccess({
    check: isEssentialsPlan(),
    fallback: false,
  });

  // Performance || Enterprise
  const canEditCustomCSS = checkAccess({
    check: isPerformancePlan() || isEnterprisePlan(),
    fallback: false,
  });

  return {
    plan,
    isTrial: isTrial(),
    isStarterFreePlan: isStarterFreePlan(),
    isStarterPremiumPlan: isStarterPremiumPlan(),
    isEssentialsPlan: isEssentialsPlan(),
    isPerformancePlan: isPerformancePlan(),
    isEnterprisePlan: isEnterprisePlan(),
    // Features
    pageCreationLimit,
    canEditCustomCSS,
  };
}

export function useSubscriptionPlans() {
  const company = useSelectCurrentCompany();
  const features = useCommonPlans(company);
  const trialExpireDate = company?.trial_end_date;

  return useMemo(
    () => ({
      ...features,
      trialExpireDate,
    }),
    [features, trialExpireDate]
  );
}

export function usePlans() {
  const company = useSelectCurrentCompany();
  const features = useCommonPlans(company);
  const trialExpireDate = company?.trial_end_date;

  return useMemo(
    () => ({
      ...features,
      trialExpireDate,
    }),
    [features, trialExpireDate]
  );
}

// Non-react component checks
export const canAccessSalesforceIntegration = (company: ActiveCompany) =>
  company.plan
    ? [PLANS.ENTERPRISE, PLANS.PERFORMANCE].includes(company.plan)
    : false;
