import { createApi } from '@reduxjs/toolkit/query/react';
import { BBK_FETCH_BASE_QUERY } from '@bbkAdminRedux/rtkq/bbkApi.slice';

export const internalBbkApiSlice = createApi({
  reducerPath: 'internalBbkApi-rktq',
  baseQuery: BBK_FETCH_BASE_QUERY,
  tagTypes: [
    'InternalFunnelRoutingNode',
    'InternalApplications',
    'InternalCompanies',
    'InternalCompanyUsers',
    'InternalUser',
    'InternalExperience',
    'InternalBrand',
    'InternalScheduledTasks',
    'InternalTriggerableTasks',
  ],
  endpoints: () => ({}),
});
