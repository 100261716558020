import type { Maybe } from '@bbkAdminUtils/utility-types';

export class JWTParser {
  public static parse<T>(jwt: string): Maybe<T> {
    try {
      const arr = jwt.split('.');
      return JSON.parse(atob(arr[1]!)) as T;
    } catch (err) {
      return undefined;
    }
  }
}
