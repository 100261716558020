import { useSelector } from 'react-redux';
import type { RestOffer } from '@bbkAdminUtils/api-client/rest-offers-api';
import { selectAppId, selectCompany } from '@bbkAdminRedux/app/selectors';
import { selectGetStartedState } from '@bbkAdminRedux/rtkq/app-persist/app-persist.slice';
import { offersAndLossAversionsSlice } from '@bbkAdminRedux/rtkq/offers-and-lossAversions.slice';
import {
  integrationSlice,
  selectBillingIntegration,
} from '@bbkAdminRedux/rtkq/integrations.slice';
import { isOffer } from '@bbkAdminUtils/api-client/rest-cards-types';
import { isDefined } from '@bbkAdminRedux/rtkq/rtkq-utils';
import { createNewOffer } from '@bbkAdminComponents/experiences/offers/new-offer-flow-2022/new-offer-utils';

export const useMapCategoriesToOffers = (): RestOffer[] => {
  const appKey = useSelector(selectAppId);
  const company = useSelector(selectCompany);

  const getStartedPersistedState = useSelector(selectGetStartedState(appKey));
  const selectedCategories =
    getStartedPersistedState.selectedOfferCategories ?? [];

  const { data: serverOffers = [], isLoading: isOffersLoading } =
    offersAndLossAversionsSlice.endpoints.getOffers.useQuery({
      appKey,
    });
  const { data: billingIntegration, isLoading: isIntegrationsLoading } =
    integrationSlice.endpoints.getIntegrations.useQuery(
      { appKey },
      { selectFromResult: selectBillingIntegration }
    );
  const isLoading = isOffersLoading || isIntegrationsLoading;

  if (isLoading) return [];

  const existingOffers = selectedCategories
    .map((cat) => serverOffers.find((o) => o.category === cat && isOffer(o)))
    .filter(isDefined);
  const newOffers = selectedCategories
    .filter(
      (cat) => !serverOffers.find((o) => o.category === cat && isOffer(o))
    )
    .map((cat) =>
      createNewOffer(cat, company.domain, Boolean(billingIntegration))
    );

  return [...existingOffers, ...newOffers];
};
