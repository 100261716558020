import React, { useEffect } from 'react';
import _ from 'lodash';
import '@bbkFunnelComponents/tabs/product-tour-tabs.scss';
import Icons from '@bbkAdminUtils/icons';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useAppDispatch } from '@bbkAdminRedux/redux_utils';
import {
  ProductTourList,
  ProductTours,
} from '@bbkAdminComponents/product-tour/tour-stories/tour-story-index';
import './product-tour.scss';
import {
  appNotPersistedSlice,
  selectNotPersistedMisc,
} from '@bbkAdminRedux/rtkq/app-not-persisted.slice';
import { useSelector } from 'react-redux';

const keyedProductTours = _.keyBy(ProductTours, (x) => x.key);

export const ProductTour: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    activeProductTourStory: selectedStory = 0,
    viewedProductTourStories = [],
  } = useSelector(selectNotPersistedMisc);
  const tourStories = ProductTourList;

  const viewedProductTourStory = (storyViewed: number) =>
    dispatch(
      appNotPersistedSlice.actions.saveMisc({
        viewedProductTourStories: Array.from(
          new Set([...viewedProductTourStories, storyViewed])
        ),
      })
    );

  const selectProductTourStory = (storySelected: number) =>
    dispatch(
      appNotPersistedSlice.actions.saveMisc({
        activeProductTourStory: storySelected,
      })
    );

  const hideProductTour = () =>
    dispatch(appNotPersistedSlice.actions.saveMisc({ showProductTour: false }));

  const selectTab = (idx: number) => {
    const tourStory = tourStories[idx];
    if (tourStory) {
      viewedProductTourStory(idx);
      selectProductTourStory(idx);
    }
  };

  useEffect(() => {
    // first time open should mark that first story as read
    if (selectedStory === 0) selectTab(0);
  }, []);

  return (
    <div className="bbk-product-tour">
      <Tabs
        className="bbk-product-tour-tabs"
        onSelect={selectTab}
        selectedIndex={selectedStory}
      >
        <div className="bbk-product-tour-tab-list">
          <div className="react-tabs__tab-list-frame">
            <div className="bbk-product-tour-tab-group">
              <TabList>
                {tourStories.map((ts, idx) => (
                  <Tab
                    key={`${ts.name}__${ts.key}`}
                    className={{
                      'react-tabs__tab': true,
                      viewed: viewedProductTourStories.includes(idx),
                      [ts.key]: true,
                    }}
                  >
                    <span />
                    {ts.name}
                  </Tab>
                ))}
              </TabList>
            </div>
          </div>
          <div className="bbk-product-tour-tab-footer">
            {Icons.bbkGrayscale}
          </div>
        </div>
        <div className="bbk-product-tour-tab-panels">
          {tourStories.map((ts, idx) => {
            const productTour = keyedProductTours[ts.key];
            if (productTour) {
              return (
                <TabPanel key={`${ts.name}__${ts.key}`}>
                  {React.cloneElement(productTour.story, {
                    onClick: () =>
                      idx + 1 === tourStories.length
                        ? hideProductTour()
                        : selectTab(idx + 1),
                  })}
                </TabPanel>
              );
            }
          })}
        </div>
      </Tabs>
    </div>
  );
};
