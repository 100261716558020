import React from 'react';
import cs from 'classnames';
import './round-button.scss';

type ButtonProps = {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  buttonClasses?: string;
};

const RoundButton: React.FC<ButtonProps> = ({
  children,
  onClick,
  buttonClasses,
}) => (
  <button className={cs('bbk-round-button', buttonClasses)} onClick={onClick}>
    {children}
  </button>
);

export default RoundButton;
