import React from 'react';
import CodeInput from 'react-verification-code-input';
import { css } from '@emotion/react';
import { LogInError } from '@bbkAdminComponents/log-in-flow/error';
import { LogInSuccess } from '@bbkAdminComponents/log-in-flow/success';
import { LogInTrouble } from '@bbkAdminComponents/log-in-flow/trouble';
import type * as LogInTypes from '@bbkAdminComponents/log-in-flow/log-in-types';
import { LogInPaths } from '@bbkAdminComponents/log-in-flow/log-in-paths';
import Label from '@bbkAdminComponents/inputs/label/label';
import { PasswordInput } from '@bbkAdminComponents/inputs/password/password-input';
import icons from '@bbkAdminUtils/icons';
import TippyTooltip from '@bbkAdminComponents/tooltips/tippy-tooltip';
import { ButtonV2 } from '@bbkAdminComponents/buttons';

type ResetTypes = {
  error?: LogInTypes.Error;
  success?: LogInTypes.Success;
  formValues: Partial<Omit<LogInTypes.FormValues, 'setPassword'>> &
    Pick<LogInTypes.FormValues, 'setPassword'>;
  updatePassword: LogInTypes.UpdatePassword;
  sending: LogInTypes.Sending;
  disabled: LogInTypes.Disabled;
};

export const ResetPassword: React.FC<ResetTypes> = ({
  error,
  success,
  formValues,
  updatePassword,
  sending,
  disabled,
}) => {
  const { password, setPassword, setPasswordVerifyCode, passwordVerifyCode } =
    formValues;
  return (
    <div>
      <form target="#" onSubmit={updatePassword}>
        <div className="content">
          <div className="tw-font-semibold tw-mb-4">Almost there...</div>
          {success && <LogInSuccess success={success} />}
          {error && <LogInError error={error} />}
          <div className="verify">
            <label>
              We have attempted to send a 6-digit verification code to you.
              Please paste or type it below.
            </label>
          </div>
          <div
            css={css`
              display: inline-flex;
            `}
          >
            <CodeInput
              type="number"
              fields={6}
              autoFocus={true}
              onComplete={setPasswordVerifyCode}
              css={css`
                input {
                  background-color: #f3e7f9;
                  border: solid 2px #9014fe;
                  border-right: none;
                  font-size: 24px;
                  font-family: inherit;
                  text-align: center;
                }

                input::selection {
                  background: #f3e7f9;
                }

                input:last-child {
                  border-right: solid 2px #9014fe;
                }

                input:focus {
                  border: solid 2px #a45fe1;
                  background-color: #f5f0f8;
                  caret-color: #9014fe;
                }
              `}
            />
          </div>
          <div className="verify">
            Didn't get a code?
            <TippyTooltip
              content={
                <p className="small">
                  Please ensure you are entering your email address exactly how
                  you did when signing up. Please check case and other special
                  characters.
                </p>
              }
              tippyOptions={{
                delay: [300, 0],
              }}
            >
              <span className="ms-1">{icons.questionMark}</span>
            </TippyTooltip>
          </div>

          <div className="verify form-container">
            {passwordVerifyCode && (
              <div className="helpContainer">
                <Label
                  id="password"
                  helpText="12 character minimum"
                  className="tw-text-sm tw-font-semibold"
                >
                  New password
                </Label>
                <PasswordInput
                  name="password"
                  autoComplete="on"
                  placeholder="Password"
                  required
                  pattern=".{12,}"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={sending}
                  autoFocus={true}
                />
              </div>
            )}
          </div>
          {!disabled && (
            <ButtonV2 primary fullWidth type="submit" disabled={sending}>
              Reset password
            </ButtonV2>
          )}
        </div>
      </form>
      <LogInTrouble link={{ path: LogInPaths.signIn, copy: 'Sign in' }} />
    </div>
  );
};
