// @ts-nocheck
import { getRenderElement, setDefaults } from '@udecode/slate-plugins';
import { DEFAULTS_BBK_EXPRESSION_CLEAN_EXPORT } from './defaults';

export const renderElementBbkExpressionCleanExport = (options?) => {
  const { bbkExpressionCleanExport } = setDefaults(
    options,
    DEFAULTS_BBK_EXPRESSION_CLEAN_EXPORT
  );

  return getRenderElement(bbkExpressionCleanExport);
};
