import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { omit, uniqBy } from 'lodash';
import type { BBKRequestContextArgs } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import {
  bbkApiSlice,
  BBKRequestContext,
} from '@bbkAdminRedux/rtkq/bbkApi.slice';
import type {
  CustomerReportOpts,
  CustomerReportOrder,
  ExportFormat,
  ReportsRequestBody,
  ReportsResponse,
} from '@bbkAdminUtils/api-client/reports-api';
import type { DateString } from '@bbkAdminRedux/rtkq/current-user.slice';
import type { TrendsResponseDataPoint } from '@bbkAdminRedux/rtkq/reports/trends.selectors';
import { ReportCustomer } from '@bbkAdminComponents/customers/utils';
import { selectFromResultTyped } from '@bbkAdminRedux/redux_utils';
import { useSelectBrandFunnels } from '@bbkAdminRedux/rtkq/funnels.slice';
import { isDefined } from '@bbkAdminRedux/rtkq/rtkq-utils';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';
import { useCreatePollingRequest } from '@bbkAdminRedux/rtkq/polling.slice';

export enum ReportType {
  cancellation_reasons = 'cancellation_reasons',
  customers = 'customers',
  customers_paginated = 'customers_paginated',
  saved_revenue = 'saved_revenue',
  save_performance = 'save_performance',
  watch_list_sentiment = 'watch_list_sentiment',
  watch_list_mrr = 'watch_list_mrr',
  trends = 'trends',
  trends_drill_down = 'trends_drill_down',
  billing_subscription_summary = 'billing_subscription_summary',
  cancel_insights = 'cancel_insights',
  cancel_insights_ai = 'cancel_insights_ai',
  offer_performance = 'offer_performance',
  funnel_audiences_history = 'funnel_audiences_history',
  offer_performance_funnel_audiences = 'offer_performance_funnel_audiences',
  offer_performance_funnel_audiences_cta = 'offer_performance_funnel_audiences_cta',
  benchmark_metrics = 'benchmark_metrics',
  repurchase_revenue_cohort = 'repurchase_revenue_cohort',
  daily_pulse = 'daily_pulse',
}

export type GetReportV1Args = {
  appKey: string;
  start: Date;
  end: Date;
  report_types: [ReportType];
  filters?: ReportFilters;
  limit?: number;
  search_term?: string;
  header?: string[];
  order?: CustomerReportOrder[];
} & BBKRequestContextArgs;

type ReportRequestGroupBy = string;

export type TimeGroupBy = 'week' | 'month' | 'quarter' | 'year';

type ReportRequest = {
  report_type: ReportType;
  parameters?: {
    group_by?: ReportRequestGroupBy;
    group_by_period?: string;
    group_by_period_payments?: TimeGroupBy;
  };
};

export type ReportFilter = {
  field: string;
  operator: 'equals' | 'contains' | 'not_equal';
  value: unknown;
};

export type ReportFilters = ReportFilter[][];

// supports only one report request
// when GetReportsArgs supports multiple
export type GetReportV2Args = {
  appKey: string;
  start: Date;
  end: Date;
  report_type_requests: [ReportRequest];
  filters?: ReportFilters;
} & BBKRequestContextArgs;

type ExportReportArgs = Omit<ReportsRequestBody, 'start' | 'end'> & {
  app_key: string;
  company_key: string;
  start_date: Date;
  end_date: Date;
  header?: string[];
} & ExportFormat;

export const reportsSlice = bbkApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getReportV1: build.query<unknown, GetReportV1Args>({
      query: ({ appKey, context, ...body }) => ({
        url: `/api/v1/apps/${appKey}/reports`,
        method: 'POST',
        body,
        params: {
          r: body.report_types.join(','),
          context,
        },
      }),
      transformResponse: (res: ReportsResponse, meta, args) => {
        const type = args.report_types[0];
        const reportResult = res.report_results.find(
          (rr) => rr.report_type === type
        );
        if (reportResult?.status !== 'success') {
          throw new Error(`Could not fetch report ${type}`);
        }
        return reportResult.data;
      },
    }),
    getReportV2: build.query<unknown, GetReportV2Args>({
      query: ({ appKey, context, ...body }) => ({
        url: `/api/v1/apps/${appKey}/reports`,
        method: 'POST',
        body,
        params: {
          r:
            body.report_type_requests.map((x) => x.report_type).join(',') ||
            undefined,
          context,
        },
      }),
      transformResponse: (res: ReportsResponse, meta, args) => {
        const type = args.report_type_requests[0].report_type;
        const reportResult = res.report_results.find(
          (rr) => rr.report_type === type
        );
        if (reportResult?.status !== 'success') {
          throw new Error(`Could not fetch report ${type}`);
        }
        return reportResult.data;
      },
    }),
    exportReport: build.query<{ statusURL: string }, ExportReportArgs>({
      query: (args) => ({
        url: `/api/v1/${args.company_key}/${args.app_key}/export`,
        method: 'POST',
        body: args,
      }),
    }),
  }),
});

export const useExportCustomersData = () => {
  const [exportReport$] = reportsSlice.endpoints.exportReport.useLazyQuery();
  const exportCustomerData = useCreatePollingRequest(exportReport$);

  return useCallback(
    async (
      customerReportOpts: CustomerReportOpts,
      {
        appId,
        companyId,
        returnDownloadUrl,
        includeEmailInstruction,
      }: {
        appId: string;
        companyId: string;
        returnDownloadUrl?: boolean;
        includeEmailInstruction?: boolean;
      }
    ) => {
      const emailInstructionNotice = includeEmailInstruction
        ? ' In addition to the local download, a copy will be emailed to your account email to download.'
        : '';

      BlackToastTrigger({
        content: `Export being generated. Please wait.${emailInstructionNotice}`,
        options: {
          autoClose: includeEmailInstruction ? 8000 : 3000,
        },
      });

      try {
        const downloadUrl = await exportCustomerData({
          reportType: ReportType.customers_paginated,
          app_key: appId,
          company_key: companyId,
          format: 'CSV',
          ...omit(customerReportOpts, ['end', 'start', 'appKey']),
          start_date: moment(customerReportOpts.start).utc().toDate(),
          end_date: moment(customerReportOpts.end).utc().toDate(),
        });
        // success, trigger download
        BlackToastTrigger({
          content: 'Export successfully generated.',
        });
        if (returnDownloadUrl) {
          return downloadUrl;
        }
        window.location.href = downloadUrl;
        return undefined;
      } catch (err: unknown) {
        let content = 'An error occurred during export, please try again.';
        if (err instanceof Error) {
          if (err.message === 'failed') {
            content = 'Export failed, please try again.';
          }
          if (err.message === 'expired') {
            content = 'Export link expired, please try again.';
          }
          if (err.message === 'timeout') {
            content = 'It took too long to generate file, please try again.';
          }
          if (err.message === 'unknown status') {
            content = 'It took too long to generate file, please try again.';
          }
        }
        window.Rollbar?.error(err as Error);
        BlackToastTrigger({
          content,
          options: {
            autoClose: 6000,
          },
        });
        throw Error(content);
      }
    },
    [exportCustomerData]
  );
};

export type TrendsDatapoint = TrendsResponseDataPoint;

export type TrendsReport = {
  trends: TrendsDatapoint[];
  has_billing_subscriptions: boolean;
};

export const ZEROED_TRENDS_DATA_POINT = {
  cohort_saved: 0,
  watchlists: 0,
  deflects: 0,
  canceled: 0,
  direct_canceled: 0,
  offer_accept_deflects: 0,
  la_offer_accepts: 0,
  modal_offer_accepts: 0,
  modal_offer_views: 0,
  la_offer_views: 0,
};

export type OfferPerfPoint = {
  offerName: string;
  offerCategory: string;
  totalViews: number;
  convRateViewed: number;
  saveRate: number;
  acceptRate: number;

  acceptedOfferCount: number;
  saveCount: number;
  // app.has_revenue, then this numbers bellow are available
  acceptedOfferSavedRevenue: number;
  saveCountSavedRevenue: number;
};
export type OfferPerformanceReport = {
  offers: OfferPerfPoint[];
  has_billing_subscriptions: boolean;
};

export type OfferPerfV2Point = {
  funnelAudContent: string; // "2059,fallback,4e86aYogVA"
  totalViews: number;
  convRateViewed: number;
  saveRate: number;
  acceptRate: number;

  acceptedOfferCount: number;
  saveCount: number;
  nonCanceledCount: number;
  // app.has_revenue, then this numbers bellow are available
  acceptedOfferSavedRevenue: number;
  saveCountSavedRevenue: number;

  // Repurchase
  deflect_payment_sessions: number;
  deflect_payment_revenue: number;
  deflect_payments_count: number;
  cancel_payment_sessions: number;
  cancel_payment_revenue: number;
  cancel_payments_count: number;

  first_view: DateString;
  last_view: DateString;
  offerName: string;
};
export type OfferPerfCtaPoint = Omit<OfferPerfV2Point, 'funnelAudContent'> & {
  funnelAudContentCta: string; // "1644,fallback,xLgqdkklDK,l_7834"
};
export type OfferPerformanceV2Report = {
  offers: OfferPerfV2Point[];
  has_billing_subscriptions: boolean;
};
export type OfferPerformanceCtaReport = {
  offers: [OfferPerfV2Point[], OfferPerfCtaPoint[]];
  has_billing_subscriptions: boolean;
};

export type CancellationReasonsDatapoint = {
  reason: string;
  lost_customers: number | null;
  lost_mrr: number | null;
  interval_lost_customers: number | null;
  interval_lost_mrr: number | null;
  percent_of_total: number | null;
  percent_of_total_mrr: number | null;
};

export type CancellationReasonsReport = {
  data: CancellationReasonsDatapoint[];
  has_billing_subscriptions: boolean;
  interval_lost_customers: number;
  interval_lost_mrr: number;
};

export type SavedRevenueDatapoint = {
  start: DateString;
  saved_customers: number;
  saved_mrr: number;
};

export type SavedRevenueReport = {
  interval_saved_customers: number;
  interval_saved_mrr: number;
  has_billing_subscriptions: boolean;
  data: SavedRevenueDatapoint[];
};

export type SavedPerformanceDatapoint = {
  date: DateString;
  rate: number; // Eg. 0.12
};

export type SavedPerformanceReport = {
  has_billing_subscriptions: boolean;
  weighted_averages: SavedPerformanceDatapoint[];
};

export type WatchlistDatapoint = {
  age: number;
  id: string;
  company: string;
  date: string;
  mrr?: number;
  reason: string;
  sentiment?: number;
};

export type WatchlistReport = {
  customers_at_risk: number;
  mrr_at_risk?: number;
  watchlist: WatchlistDatapoint[];
};

export type CustomersPaginatedReport = {
  customers: ReportCustomer[];
  has_billing_subscriptions: boolean;
  filterable_fields: string[];
  sortable_fields: string[];
};

export type RetainedRevenueCohortReportResponse = {
  periods: RetainedRevenueCohort[];
  has_billing_subscriptions: boolean;
};

type RetainedRevenueCohort = {
  date: DateString;
  deflects: number;
  deflect_payment_sessions: number;
  deflect_still_paying: number;
  repurchase_periods: RetainedRevenuePeriod[];
};

export type RetainedRevenuePeriod = {
  date: DateString;
  revenue: number;
  first_time_revenue: number;
  cancels: number;
  still_paying: number;
  canceled: number | null;
};

type AudienceKey = string;
type ControlFunnelAudience = `${number},control`;
type Level0FallbackFunnelAudience = `${number},fallback`;
type Level1FunnelAudience = `${number},${AudienceKey}`;
type Level1FallbackFunnelAudience = `${number},${AudienceKey},fallback`;
type Level2FunnelAudience = `${number},${AudienceKey},${AudienceKey}`;
type Level2FallbackFunnelAudience =
  `${number},${AudienceKey},${AudienceKey},fallback`;
type UnknownFunnelAudience = `${number},`;
export type FunnelAudience =
  | ControlFunnelAudience
  | Level0FallbackFunnelAudience
  | UnknownFunnelAudience
  | Level1FunnelAudience
  | Level2FunnelAudience
  | Level1FallbackFunnelAudience
  | Level2FallbackFunnelAudience;

export const isControlFunnelAudience = (
  fa: FunnelAudience
): fa is ControlFunnelAudience => fa.endsWith(',control');

export const isLevel0FallbackFunnelAudience = (
  fa: FunnelAudience
): fa is Level0FallbackFunnelAudience => fa.split(',')[1] === 'fallback';

export const isLevel1FunnelAudience = (
  fa: FunnelAudience
): fa is Level1FunnelAudience =>
  !isLevel0FallbackFunnelAudience(fa) &&
  !isControlFunnelAudience(fa) &&
  fa.split(',').length === 2;

export const isLevel1FallbackFunnelAudience = (
  fa: FunnelAudience
): fa is Level1FallbackFunnelAudience => fa.split(',')[2] === 'fallback';

export const isLevel2FunnelAudience = (
  fa: FunnelAudience
): fa is Level2FunnelAudience =>
  !isLevel1FallbackFunnelAudience(fa) && fa.split(',').length === 3;

export const isLevel2FallbackFunnelAudience = (
  fa: FunnelAudience
): fa is Level2FallbackFunnelAudience => fa.split(',')[3] === 'fallback';

export const isUnknownFunnelAudience = (
  fa: FunnelAudience
): fa is UnknownFunnelAudience => fa.endsWith(',');

type FunnelAudiencesHistoryRange = {
  funnel_audiences: FunnelAudience;
  start: DateString;
  end: DateString;
};

export type FunnelAudiencesHistoryReport = {
  ranges: FunnelAudiencesHistoryRange[];
  has_billing_subscriptions: boolean;
};

const getReportV1FunnelAudiencesHistory = (
  args: Pick<GetReportV1Args, 'appKey' | 'context'>
) => {
  const { appKey, context } = args;

  return reportsSlice.endpoints.getReportV1.useQuery(
    {
      appKey,
      start: moment('2018-01-01T00:00:00.000Z').toDate(),
      end: moment('2100-12-31T23:59:59.999Z').toDate(),
      report_types: [ReportType.funnel_audiences_history],
      context,
    },
    {
      skip: !appKey,
      selectFromResult: selectFromResultTyped<FunnelAudiencesHistoryReport>,
    }
  );
};

export const useSelectFunnelAudiencesHistory = (
  args: Pick<GetReportV1Args, 'appKey' | 'context'>
) => {
  const { appKey, context } = args;

  const res = getReportV1FunnelAudiencesHistory({ appKey, context });

  return useMemo(() => {
    const data = res.data;
    const ranges = uniqBy([...(data?.ranges ?? [])], (x) => x.funnel_audiences);
    return {
      ...res,
      data: { ...data, ranges },
    };
  }, [res]);
};

export const useSelectFunnelAudiencesHistoryByBrandId = (args: {
  brandId: string | undefined;
  context: BBKRequestContext;
  appKey: string;
}) => {
  const { appKey, context, brandId } = args;

  const res = getReportV1FunnelAudiencesHistory({ appKey, context });
  const brandFunnels = useSelectBrandFunnels({ appKey, context, brandId });

  const flatHistoryClean = useMemo(() => {
    if (!res.data || !brandFunnels) return [];
    return res.data.ranges.filter((r) => {
      if (!isDefined(r)) return false;
      const [fId] = r.funnel_audiences.split(',');
      return brandFunnels.find((f) => f.id === Number(fId));
    });
  }, [res.data, brandFunnels, brandId]);

  return useMemo(() => {
    const ranges = uniqBy([...(flatHistoryClean ?? [])], (x) => x);
    return {
      ...res,
      data: { ...res.data, ranges },
    };
  }, [res, flatHistoryClean]);
};
