import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query/react';
import Icon from '@bbkAdminComponents/icons/icon-generator';
import BottomActionBox from '@bbkAdminComponents/cards/bottom-action-box';
import Modal from '@bbkAdminComponents/DashboardModal/dash-modal';
import FormLayout from '@bbkAdminComponents/form/form-layout';
import FormField from '@bbkAdminComponents/form/form-field';
import { IntegrationFieldSelect } from '@bbkAdminComponents/integrations/integration-field-select';
import './integration-modal.scss';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';
import type { IntegrationDataType } from '@bbkAdminRedux/data-management/reducers';
import { IntegrationFieldTypeEnum } from '@bbkAdminRedux/data-management/reducers';
import { integrationSlice } from '@bbkAdminRedux/rtkq/integrations.slice';
import { selectAppId } from '@bbkAdminRedux/app/selectors';

type Props = {
  id: number;
  closeFunc: () => unknown;
  invisibleBackdrop?: boolean;
  objectName: string;
};
const IntegrationSelectKeyModal: React.FC<Props> = (props) => {
  const { closeFunc, id, invisibleBackdrop, objectName } = props;

  const appKey = useSelector(selectAppId);
  const { data: integration } =
    integrationSlice.endpoints.getIntegrations.useQuery(
      { appKey },
      {
        selectFromResult: (res) => ({
          ...res,
          data: res.data?.find((x) => x.id === id),
        }),
      }
    );

  const { data: fields } =
    integrationSlice.endpoints.getIntegrationFields.useQuery(
      integration
        ? { appKey, integrationDataType: integration.data_type }
        : skipToken
    );
  const objFields = useMemo(
    () => fields?.objects.find((o) => o.name === objectName),
    [objectName, fields]
  );

  const [fm, setFm] = useState<{
    integration_id: number;
    field: { object: string; name?: string };
  }>();
  useEffect(() => {
    if (objFields) {
      setFm({
        integration_id: id,
        field: {
          object: objFields.name,
          name: objFields.external_id,
        },
      });
    }
  }, [id, objFields]);

  const [updateExternalId, { isSuccess, error }] =
    integrationSlice.endpoints.setExternalId.useMutation();

  useEffect(() => {
    if (isSuccess) {
      BlackToastTrigger({ content: 'Saved key update.' });
      closeFunc();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      BlackToastTrigger({ content: 'Could not save key.' });
    }
  }, [error]);

  if (!integration) return null;

  const save = async () => {
    if (!fm) return;
    return updateExternalId({
      appKey,
      integrationDataType: integration.data_type,
      externalIdObjectName: objectName,
      external_id: fm.field.name!,
    }).unwrap();
  };

  const renderModalFooter = () => (
    <BottomActionBox
      buttons={[
        {
          type: 'check',
          title: 'Save changes',
          clicked: save,
        },
      ]}
    />
  );

  const renderContent = () => (
    <div>
      <p>Identify the key for the {integration.display_name} object.</p>
      <FormLayout>
        <FormField
          title="Object Key"
          input={
            <IntegrationFieldSelect
              value={fm}
              onChange={(e) => {
                const { integration_id, object, name } = e.value;
                setFm({
                  integration_id,
                  field: { object: object!, name },
                });
              }}
              filterOptions={(x) =>
                x.integration_id === id &&
                x.object === objectName &&
                (x.type === IntegrationFieldTypeEnum.external_id ||
                  x.maps_to_type.includes(IntegrationFieldTypeEnum.external_id))
              }
            />
          }
        />
      </FormLayout>
    </div>
  );

  return (
    <Modal
      show
      modalTitle={integration.display_name}
      modalIcon={
        <Icon
          name={
            integration.data_type.toLowerCase() as Lowercase<IntegrationDataType>
          }
        />
      }
      modalContent={renderContent()}
      modalClosed={closeFunc}
      classList={['dash-modal', 'integration-select-key-modal']}
      modalFooter={renderModalFooter()}
      invisibleBackdrop={invisibleBackdrop}
    />
  );
};
export default IntegrationSelectKeyModal;
