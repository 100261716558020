import React from 'react';
import purify from 'dompurify';
import htmlReactParser from 'html-react-parser';
import _ from 'lodash';
import { BrightbackText } from '@bbkAdminUtils/brightback-expression';

type Props = {
  value: unknown;
  context?: Record<string, unknown>;
};

const CustomerHtml: React.FC<Props> = (props) => {
  const { value, context } = props;
  const isDebug = window.bbkClientConfig && !window.bbkClientConfig.is_prod;

  if (React.isValidElement(value)) {
    // Passed in non-customer html: react components instead. just render them.
    return value;
  }
  const resolvedValue = new BrightbackText(value as string).process(
    context || {},
    {
      errorFallback: isDebug ? '[ERROR]' : '',
    }
  );
  const cleanValue = purify.sanitize(resolvedValue, {
    ALLOWED_TAGS: ['em', 'div', 'strong', 'u', 'span', 'a'],
    ALLOWED_ATTR: ['class', 'href'],
  });

  let useValue = cleanValue;
  if (!_.isString(cleanValue)) {
    useValue = '';
    console.error(
      'Trying to pass a non-string value to the htmlReactParser. Please check your values.',
      value,
      context,
      resolvedValue,
      cleanValue
    );
  }

  return htmlReactParser(useValue);
};

export default CustomerHtml;
