import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Collapse } from 'react-collapse';
import { Link, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import cs from 'classnames';
import { skipToken } from '@reduxjs/toolkit/query';
import moment from 'moment';
import type { LogArgument } from 'rollbar';
import { useAppDispatch } from '@bbkAdminRedux/redux_utils';
import { useCreatePagesListUrl, useUrl } from '@bbkAdminUtils/nav';
import internalMenu from '@bbkAdminComponents/internal/internal-pages';
import BrightbackSession from '@bbkAdminUtils/brightback-session';
import ProfileImage from '@bbkAdminComponents/profile/profile-image';
import { CompanyLogo } from '@bbkAdminComponents/profile/company-logo';
import LinkListPopover from '@bbkAdminComponents/popovers/link-list-popover';
import { ProductTourModal } from '@bbkAdminComponents/product-tour/product-tour-modal';
import type LinkGroupMenu from '@bbkAdminComponents/linkGroup/link-group';
import {
  selectApp,
  selectAppId,
  selectCompany,
  selectCompanyInternalName,
  selectUser,
} from '@bbkAdminRedux/app/selectors';
import { useHasSubscription, usePlans } from '@bbkAdminUtils/plans';
import { useStrings } from '@bbkAdminUtils/strings';
import { AuthorizedPaths } from '@bbkAdminRouter/router-paths';
import {
  useDaysToExpiration,
  useSelectCompanySubscription,
} from '@bbkAdminRedux/rtkq/checkout.slice';
import { FeatureFlagsE, useFeatureFlags } from '@bbkAdminUtils/feature-flags';
import {
  BottomAlertTab,
  NavCollapseContainer,
  NavContainer,
  NavHeader,
  NavIcons,
  NavItem,
  NavLink,
  NavSubHeader,
} from '@bbkAdminComponents/app-layout/nav';
import { CBRetentionLogo } from '@bbkAdminComponents/cbretention-logo';
import { useSelectBillingIntegration } from '@bbkAdminRedux/rtkq/integrations.slice';
import { billingConfigSlice } from '@bbkAdminRedux/rtkq/billing-config.slice';
import { GetStartedNav } from '@bbkAdminComponents/get-started/get-started-nav';
import SuspenseLoader from '@bbkAdminComponents/loaders/suspense-loader';
import { selectCompanyMisc } from '@bbkAdminRedux/rtkq/app-persist/app-persist.slice';
import { useSelectCurrentApp } from '@bbkAdminRedux/rtkq/current-user.slice';
import TippyTooltip from '@bbkAdminComponents/tooltips/tippy-tooltip';
import { useGetStartedStatus } from '@bbkAdminComponents/get-started/get-started-page';
import { useQueryFeatureFlags } from '@bbkAdminRedux/rtkq/feature-flags.slice';
import { appNotPersistedSlice } from '@bbkAdminRedux/rtkq/app-not-persisted.slice';
import {
  ONBOARDING_HISTORY_STEP,
  onboardingHistorySlice,
  selectOnboardingHistoryMap,
} from '@bbkAdminRedux/rtkq/onboarding-history.slice';
import {
  determineLeftNavAppearance,
  determineSelectedNavItem,
} from '@bbkAdminRedux/app/reducers';
import { recordError } from '@bbkAdminUtils/recordError';
import { useRoles } from '@bbkAdminUtils/roles';
import { IntegrationDataType } from '@bbkAdminRedux/data-management/reducers';
import { CBProductsSwitcher } from '@bbkAdminComponents/CBProductsSwitcher';
import { brightbackAuthSlice } from '@bbkAdminRedux/rtkq/brightback-auth.slice';

const WelcomePopup = React.lazy(
  () => import('@bbkAdminComponents/get-started/welcome-popup')
);

const withToolTip = (
  content: React.ReactNode,
  menuName: React.ReactElement
): React.ReactElement => (
  <TippyTooltip
    content={content}
    tippyOptions={{
      placement: 'right-start',
      appendTo: () => document.body,
      zIndex: 10,
    }}
  >
    {menuName}
  </TippyTooltip>
);

const LeftColumn: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { data: featureFlags } = useQueryFeatureFlags();
  const dispatch = useAppDispatch();
  const app = useSelector(selectApp);
  const currentApp = useSelectCurrentApp();
  const company = useSelector(selectCompany);
  const user = useSelector(selectUser);
  const selectedNavItem = determineSelectedNavItem(location.pathname);
  const showNav = determineLeftNavAppearance(location.pathname);
  const createUrl = useUrl();
  const [signOut$] = brightbackAuthSlice.endpoints.signOut.useMutation();

  const userAnchorRef = useRef<HTMLDivElement>(null);
  const appAnchorRef = useRef<HTMLDivElement>(null);

  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [appMenuOpen, setAppMenuOpen] = useState(false);
  const [trendsExpanded, setTrendsExpanded] = useState(false);
  const [customersExpanded, setCustomersExpanded] = useState(false);
  const [internalExpanded, setInternalExpanded] = useState(false);
  const [experiencesExpanded, setExperiencesExpanded] = useState(false);
  const [insightsExpanded, setInsightsExpanded] = useState(false);
  const [showInternal, setShowInternal] = useState(false);

  const { isSuccess: isFFsSuccess } = useQueryFeatureFlags();

  const appKey = useSelector(selectAppId);
  const companyInternalName = useSelector(selectCompanyInternalName);
  const hasSubscription = useHasSubscription();
  const subscription = useSelectCompanySubscription(
    hasSubscription
      ? {
          company: companyInternalName,
        }
      : skipToken
  );
  const billingIntegration = useSelectBillingIntegration({ appKey });
  const hasChargebeeIntegration =
    billingIntegration?.data_type === IntegrationDataType.CHARGEBEE;

  const { data: billingConfig } =
    billingConfigSlice.endpoints.getBillingConfig.useQuery(
      billingIntegration ? { app_key: appKey } : skipToken
    );
  const isRepurchaseAttributionEnabled =
    billingConfig?.repurchase_based_attribution;

  const { data: steps = {} } =
    onboardingHistorySlice.endpoints.getOnboardingHistorySteps.useQuery(
      { appKey },
      {
        skip: appKey === '_' || app?.get_started_status !== 'ELIGIBLE',
        selectFromResult: selectOnboardingHistoryMap,
      }
    );
  const isGetStartedEligible = currentApp?.get_started_status === 'ELIGIBLE';
  const { isTestAndLaunchCompleted, isCreateExperienceCompleted } =
    useGetStartedStatus();
  const showGetStarted = isGetStartedEligible;
  const isCreateOrLaunchCompleted =
    isCreateExperienceCompleted || isTestAndLaunchCompleted;
  const isExpLibraryDisabled = showGetStarted && !isCreateOrLaunchCompleted;
  const isCreateOrLaunchCompletedInPast =
    steps[ONBOARDING_HISTORY_STEP.BRANDING] ||
    steps[ONBOARDING_HISTORY_STEP.GO_LIVE];
  const isExpBrandingLibDisabled = isExpLibraryDisabled
    ? showGetStarted && !isCreateOrLaunchCompletedInPast
    : isExpLibraryDisabled;
  const pagesListUrl = useCreatePagesListUrl(
    !isCreateOrLaunchCompleted && isCreateOrLaunchCompletedInPast
  );

  const showWizardPopupFF = useFeatureFlags(FeatureFlagsE.SHOW_WELCOME_POPUP);
  const additionalBrandsEnabled = useFeatureFlags(
    FeatureFlagsE.ADDITIONAL_BRANDS_ENABLED
  );

  const playEnabled = useFeatureFlags(FeatureFlagsE.ENABLE_LIFECYCLE_OFFERS);
  const isCancelInsightsAIEnabled = useFeatureFlags(
    FeatureFlagsE.ENABLE_LLM_FEEDBACK_CLASSIFIER
  );
  const { showWelcomePopup } = useSelector(
    selectCompanyMisc(companyInternalName)
  );

  // Plan filters
  const { isTrial, isStarterFreePlan, isStarterPremiumPlan } = usePlans();
  // show welcome popup once per user for trial/starter
  const isStarter = isStarterFreePlan || isStarterPremiumPlan;
  const showWizardPopupEligible = isTrial || showWizardPopupFF || isStarter;
  const showWizardPopup =
    appKey !== '_' && showWizardPopupEligible && showWelcomePopup;
  const strings = useStrings();

  const { hasBillingRole } = useRoles();
  const showAlert = useMemo(() => {
    if (!subscription?.price.included_session_limit) return false;
    return (
      hasSubscription &&
      hasBillingRole &&
      subscription?.usage > subscription?.price.included_session_limit
    );
  }, [isTrial, hasSubscription, subscription, hasBillingRole]);

  const regexTest = useCallback(
    (regex: string | RegExp): boolean => {
      // test is general, regex is the string to test for
      // i.e. trendsReport, trends => true
      const routeRegex = new RegExp(regex);
      return routeRegex.test(selectedNavItem);
    },
    [selectedNavItem]
  );

  useEffect(() => {
    setTrendsExpanded(regexTest('trends'));
    setCustomersExpanded(regexTest('customers'));
    setInternalExpanded(regexTest('internal'));
    setExperiencesExpanded(regexTest('experiences'));
    setInsightsExpanded(regexTest('insights'));
  }, [regexTest]);

  useEffect(() => {
    let localShow = false;
    if (internalMenu && internalMenu.length && featureFlags) {
      localShow = internalMenu.reduce(
        (previousValue, item) =>
          previousValue ||
          item.featureFlags.filter((ff) => featureFlags.includes(ff)).length >
            0,
        false
      );
    }
    setShowInternal(localShow);
  }, [featureFlags]);

  if (!user && !company) {
    return null;
  }
  const appCount = user.companies.reduce(
    (ret, c) => ret + c.applications.length,
    0
  );
  const enableAppSwitcher = user.companies.length > 1 || appCount > 1;

  const switchApp = (companyKey: string, localAppKey: string): void => {
    const pathname = window.location.pathname;
    let splitPath = pathname.split('/').slice(5);
    if (splitPath[0] === 'experiences' || splitPath[0] === 'settings') {
      splitPath = splitPath.slice(0, 1);
    }
    const currentPath = splitPath.join('/');
    const location = `/company/${companyKey}/app/${localAppKey}/${currentPath}`;
    BrightbackSession.setActiveApp({ encoded_id: localAppKey });
    BrightbackSession.setActiveCompany({ internal_name: companyKey });
    window.location.assign(location);
  };

  const userMenuGroupItems: React.ComponentProps<
    typeof LinkGroupMenu
  >['links'] = [
    // {
    //   icon: 'profile',
    //   text: 'Profile',
    //   link: 'url(/profile)',
    //   disabled: true,
    //   classes: cs(
    //     { active: regexTest('profile') },
    //     'disabled'
    //   ),
    // },
    {
      icon: 'lock',
      text: 'Sign out',
      linkAction: (): void => {
        signOut$()
          .unwrap()
          .catch((err: LogArgument) => {
            recordError(err);
          });
      },
      classes: 'logout',
      iconClasses: 'w14',
    },
  ];

  if (useFeatureFlags(FeatureFlagsE.SHOW_WHATS_NEW)) {
    userMenuGroupItems.splice(0, 0, {
      icon: 'whatsNew',
      text: "What's new?",
      linkAction: (): void => {},
      classes: 'logout',
      iconClasses: 'w14',
    });
  }
  const appMenuGroupItems: React.ComponentProps<typeof LinkGroupMenu>['links'] =
    [];

  const sortedCompanies = _.orderBy(
    user.companies,
    [(v) => v.name.toLowerCase()],
    ['asc']
  );

  sortedCompanies.forEach((c) => {
    appMenuGroupItems.push({
      text: c.name,
      itemClasses: 'customerData',
      company: c,
    });
    if (!c.applications || c.applications.length === 0) {
      appMenuGroupItems.push({
        text: '(No Access)',
        classes: 'indent disabled',
        itemClasses: 'customerData',
        company: c,
        isApplication: true,
      });
    } else {
      c.applications.forEach((a) => {
        appMenuGroupItems.push({
          text: a.display_name,
          linkAction: () => switchApp(c.internal_name, a.encoded_id),
          classes: 'indent',
          itemClasses: 'customerData',
          company: c,
          app: a,
          isApplication: true,
        });
      });
    }
  });

  const expiration = useDaysToExpiration();
  const expired = expiration
    ? expiration.endDate.diff(moment.utc(), 'days') < 0
    : false;

  const lockIcon = (
    <span className="tw-inline-block tw-ml-2">{NavIcons.lock}</span>
  );
  return (
    <div
      className={cs('left-column', {
        'd-none': !showNav || !isFFsSuccess,
        'tw-pb-11': showAlert,
        'tw-pb-4': !showAlert,
      })}
    >
      <div>
        <Link
          className="tw-block tw-mb-6"
          to={{ pathname: createUrl(AuthorizedPaths.dashboard) }}
          title="Dashboard"
        >
          <CBRetentionLogo />
        </Link>
        <ul className="nav-links">
          {showGetStarted && (
            <NavHeader active={regexTest(AuthorizedPaths.getStarted)}>
              <NavLink
                classes={cs(
                  { active: regexTest(AuthorizedPaths.getStarted) },
                  'nav-link'
                )}
                href={AuthorizedPaths.getStarted}
                text={<GetStartedNav />}
              />
            </NavHeader>
          )}
          <NavHeader active={regexTest(AuthorizedPaths.dashboard)}>
            <NavLink
              classes={cs({ active: regexTest('dashboard') }, 'nav-link')}
              href={AuthorizedPaths.dashboard}
              icon="gauge"
              text="Dashboard"
            />
          </NavHeader>
          <div className="tw-relative">
            {isExpBrandingLibDisabled && (
              <TippyTooltip
                tippyOptions={{
                  placement: 'right',
                  offset: [0, -150],
                  arrow: false,
                  appendTo: () => document.body,
                }}
                content="Create your first cancel experience on the get started page to view this section."
              >
                <div
                  className={cs(
                    'tw-absolute tw-z-10',
                    'tw-top-0 tw-right-0 tw-bottom-0 tw-left-0'
                  )}
                />
              </TippyTooltip>
            )}
            {playEnabled && (
              <NavHeader active={regexTest(AuthorizedPaths.plays)}>
                <NavLink
                  classes={cs({ active: regexTest('plays') }, 'nav-link')}
                  href={AuthorizedPaths.plays}
                  icon="play"
                  text="Play"
                />
              </NavHeader>
            )}
            <NavHeader active={regexTest('experiences')}>
              <NavItem
                disabled={isExpBrandingLibDisabled}
                title="Experiences"
                classes={cs({ active: regexTest('experiences') }, 'nav-link')}
                iconNode={NavIcons.experiences}
                onClick={() => {
                  const newVal = !experiencesExpanded;
                  setExperiencesExpanded(newVal);
                  if (newVal) {
                    history.push(
                      additionalBrandsEnabled
                        ? createUrl(AuthorizedPaths.additionalBrandsPage)
                        : createUrl(AuthorizedPaths.experiencesBrandingPage)
                    );
                  }
                }}
                text={<span>Experiences</span>}
              />
            </NavHeader>
            <Collapse
              isOpened={
                isExpBrandingLibDisabled ||
                experiencesExpanded ||
                regexTest('/get-started')
              }
            >
              <NavCollapseContainer>
                <NavSubHeader>Manage</NavSubHeader>
                <NavContainer>
                  {additionalBrandsEnabled ? (
                    <NavLink
                      disabled={isExpLibraryDisabled}
                      classes={cs(
                        {
                          active: regexTest(
                            AuthorizedPaths.additionalBrandsPage
                          ),
                        },
                        'nav-link nav-sub-link'
                      )}
                      href={AuthorizedPaths.additionalBrandsPage}
                      text={strings.BRANDS_DISPLAY_NAME}
                      iconNode={NavIcons.pages}
                    />
                  ) : (
                    <NavLink
                      disabled={isExpBrandingLibDisabled}
                      classes={cs(
                        {
                          active: regexTest(
                            AuthorizedPaths.experiencesBrandingPage
                          ),
                        },
                        'nav-link nav-sub-link'
                      )}
                      href={AuthorizedPaths.experiencesBrandingPage}
                      text={strings.BRANDING_DISPLAY_NAME}
                      iconNode={NavIcons.pages}
                    />
                  )}
                </NavContainer>
                <NavContainer>
                  <NavLink
                    disabled={isExpBrandingLibDisabled}
                    classes={cs(
                      {
                        active:
                          regexTest(
                            new RegExp(`^${AuthorizedPaths.experiencesPages}`)
                          ) || regexTest('experiences/funnels'),
                      },
                      'nav-link nav-sub-link'
                    )}
                    href={pagesListUrl}
                    text="Pages"
                    iconNode={NavIcons.pages}
                  />
                </NavContainer>
                <NavContainer>
                  <NavLink
                    disabled={isStarter || isExpLibraryDisabled}
                    classes={cs(
                      {
                        active: regexTest(AuthorizedPaths.experiencesTargeting),
                      },
                      'nav-link nav-sub-link'
                    )}
                    href={AuthorizedPaths.experiencesTargeting}
                    text={
                      isStarter ? (
                        withToolTip(
                          strings.UPGRADE_PLAN({
                            description: strings.UPGRADE_TO_DEFLECTION_DESC,
                          }),
                          <div>Targeting{lockIcon}</div>
                        )
                      ) : (
                        <>
                          Targeting
                          {expired && lockIcon}
                        </>
                      )
                    }
                    iconNode={NavIcons.bullseye}
                  />
                </NavContainer>

                <NavSubHeader className="tw-mt-4">Libraries</NavSubHeader>
                <NavContainer>
                  <NavLink
                    classes={cs(
                      {
                        active: regexTest(AuthorizedPaths.offers),
                      },
                      'nav-link nav-sub-link'
                    )}
                    iconNode={NavIcons.offers}
                    href={AuthorizedPaths.offers}
                    disabled={isStarter || isExpLibraryDisabled}
                    text={
                      isStarter ? (
                        withToolTip(
                          strings.UPGRADE_PLAN({
                            description: strings.UPGRADE_TO_DEFLECTION_DESC,
                          }),
                          <div>Offers{lockIcon}</div>
                        )
                      ) : (
                        <>Offers</>
                      )
                    }
                  />
                </NavContainer>
                <NavContainer>
                  <NavLink
                    classes={cs(
                      {
                        active: regexTest(AuthorizedPaths.lossAversionList),
                      },
                      'nav-link nav-sub-link'
                    )}
                    iconNode={NavIcons.lossAversions}
                    href={AuthorizedPaths.lossAversionList}
                    disabled={isStarter || isExpLibraryDisabled}
                    text={
                      isStarter ? (
                        withToolTip(
                          strings.UPGRADE_PLAN({
                            description: strings.UPGRADE_TO_DEFLECTION_DESC,
                          }),
                          <div className="tw-flex">
                            Loss aversion cards{lockIcon}
                          </div>
                        )
                      ) : (
                        <>Loss aversion cards</>
                      )
                    }
                  />
                </NavContainer>
                <NavContainer>
                  <NavLink
                    disabled={isExpBrandingLibDisabled}
                    classes={cs(
                      {
                        active: regexTest(
                          /experiences(\/funnels\/\d+)?\/page\/reasons$/
                        ),
                      },
                      'nav-link nav-sub-link'
                    )}
                    iconNode={NavIcons.reasons}
                    href={AuthorizedPaths.experiencesPageReasons}
                    text="Survey reasons"
                  />
                </NavContainer>
                <NavContainer>
                  <NavLink
                    disabled={isExpBrandingLibDisabled}
                    classes={cs(
                      {
                        active: regexTest(AuthorizedPaths.confirmationPages),
                      },
                      'nav-link nav-sub-link'
                    )}
                    iconNode={NavIcons.confirmationPages}
                    href={AuthorizedPaths.confirmationPages}
                    text="Redirect pages"
                  />
                </NavContainer>
              </NavCollapseContainer>
            </Collapse>
            <NavHeader active={regexTest('audiences')}>
              <NavItem
                disabled={isExpLibraryDisabled || isStarter}
                title="Audiences"
                classes={cs({ active: regexTest('audiences') }, 'nav-link')}
                iconNode={NavIcons.audiences}
                onClick={() => {
                  if (!isStarter) {
                    history.push(createUrl(AuthorizedPaths.audiencesList));
                  }
                }}
                text={
                  isStarter ? (
                    withToolTip(
                      strings.UPGRADE_PLAN({
                        description: strings.UPGRADE_TO_DEFLECTION_DESC,
                      }),
                      <span>Audiences{lockIcon}</span>
                    )
                  ) : (
                    <>
                      Audiences
                      {expired && lockIcon}
                    </>
                  )
                }
              />
            </NavHeader>
          </div>
          <NavHeader active={regexTest('insights')}>
            <NavItem
              title="Insights"
              classes={cs(
                {
                  active: regexTest('insights'),
                },
                'nav-link',
                'nav-insights'
              )}
              icon="insights"
              onClick={() => {
                const newVal = !insightsExpanded;
                setInsightsExpanded(newVal);
                if (newVal)
                  history.push(
                    isStarter
                      ? createUrl(AuthorizedPaths.insightsCancel)
                      : createUrl(
                          AuthorizedPaths.insightsExperiencePerformanceReport
                        )
                  );
              }}
              text={
                <>
                  Insights
                  {expired && lockIcon}
                </>
              }
            />
          </NavHeader>
          <Collapse isOpened={insightsExpanded}>
            <NavCollapseContainer>
              {isCancelInsightsAIEnabled && (
                <NavContainer>
                  <NavLink
                    classes={cs(
                      { active: regexTest(AuthorizedPaths.insightsCancelAI) },
                      'nav-link nav-sub-link'
                    )}
                    href={AuthorizedPaths.insightsCancelAI}
                    text="Cancels (AI)"
                  />
                </NavContainer>
              )}
              <NavContainer>
                <NavLink
                  classes={cs(
                    { active: regexTest(AuthorizedPaths.insightsCancel) },
                    'nav-link nav-sub-link'
                  )}
                  href={AuthorizedPaths.insightsCancel}
                  text="Cancels"
                />
              </NavContainer>
              <NavContainer>
                <NavLink
                  disabled={isStarter}
                  classes={cs(
                    {
                      active: regexTest(
                        AuthorizedPaths.insightsOfferPerformance
                      ),
                    },
                    'nav-link nav-sub-link'
                  )}
                  href={AuthorizedPaths.insightsOfferPerformance}
                  text={
                    isStarter ? (
                      withToolTip(
                        strings.UPGRADE_PLAN({
                          description: strings.UPGRADE_TO_DEFLECTION_DESC,
                        }),
                        <span>Offer Performance{lockIcon}</span>
                      )
                    ) : (
                      <span>Offer Performance</span>
                    )
                  }
                />
              </NavContainer>
              <NavContainer>
                <NavLink
                  disabled={isStarter}
                  classes={cs(
                    {
                      active: regexTest(
                        AuthorizedPaths.insightsExperiencePerformanceReport
                      ),
                    },
                    'nav-link nav-sub-link'
                  )}
                  href={AuthorizedPaths.insightsExperiencePerformanceReport}
                  text={
                    isStarter ? (
                      withToolTip(
                        strings.UPGRADE_PLAN({
                          description: strings.UPGRADE_TO_DEFLECTION_DESC,
                        }),
                        <span>Experience Performance{lockIcon}</span>
                      )
                    ) : (
                      <span>Experience Performance</span>
                    )
                  }
                />
              </NavContainer>
              {isRepurchaseAttributionEnabled && (
                <NavContainer>
                  <NavLink
                    disabled={isStarter}
                    classes={cs(
                      {
                        active: regexTest(
                          AuthorizedPaths.insightsRetainedRevenueCohort
                        ),
                      },
                      'nav-link nav-sub-link'
                    )}
                    href={AuthorizedPaths.insightsRetainedRevenueCohort}
                    text={
                      isStarter ? (
                        withToolTip(
                          strings.UPGRADE_PLAN({
                            description: strings.UPGRADE_TO_DEFLECTION_DESC,
                          }),
                          <span>Retained Revenue Cohort{lockIcon}</span>
                        )
                      ) : (
                        <span>Retained Revenue Cohort</span>
                      )
                    }
                  />
                </NavContainer>
              )}
              <NavContainer>
                <NavLink
                  disabled={isStarter}
                  classes={cs(
                    {
                      active: regexTest(AuthorizedPaths.insightsLiftDashboard),
                    },
                    'nav-link nav-sub-link'
                  )}
                  href={AuthorizedPaths.insightsLiftDashboard}
                  text={
                    isStarter ? (
                      withToolTip(
                        strings.UPGRADE_PLAN({
                          description: strings.UPGRADE_TO_DEFLECTION_DESC,
                        }),
                        <span>Lift Report{lockIcon}</span>
                      )
                    ) : (
                      <span>Lift Report</span>
                    )
                  }
                />
              </NavContainer>
            </NavCollapseContainer>
          </Collapse>
          <NavHeader active={regexTest('trends')}>
            <NavItem
              title="Trends"
              classes={cs(
                { active: regexTest('trends') },
                'nav-link',
                'nav-trends'
              )}
              icon="trends"
              onClick={() => {
                const newVal = !trendsExpanded;
                setTrendsExpanded(newVal);
                if (newVal) {
                  history.push(createUrl(AuthorizedPaths.trendsCancels));
                }
              }}
              text={
                <>
                  Trends
                  {expired && lockIcon}
                </>
              }
            />
          </NavHeader>
          <Collapse isOpened={trendsExpanded}>
            <NavCollapseContainer>
              <NavContainer>
                <NavLink
                  classes={cs(
                    {
                      active: regexTest(AuthorizedPaths.trendsSavePerformance),
                    },
                    'nav-link nav-sub-link'
                  )}
                  href={AuthorizedPaths.trendsSavePerformance}
                  text="Save performance"
                />
              </NavContainer>
              <NavContainer>
                <NavLink
                  disabled={isStarter}
                  classes={cs(
                    {
                      active: regexTest(AuthorizedPaths.trendsDeflection),
                    },
                    'nav-link nav-sub-link'
                  )}
                  href={AuthorizedPaths.trendsDeflection}
                  text={
                    isStarter ? (
                      withToolTip(
                        strings.UPGRADE_PLAN({
                          description: strings.UPGRADE_TO_DEFLECTION_DESC,
                        }),
                        <span>Deflection{lockIcon}</span>
                      )
                    ) : (
                      <span>Deflection</span>
                    )
                  }
                />
              </NavContainer>
              <NavContainer>
                <NavLink
                  classes={cs(
                    {
                      active: regexTest(AuthorizedPaths.trendsCancels),
                    },
                    'nav-link nav-sub-link'
                  )}
                  href={AuthorizedPaths.trendsCancels}
                  text="Cancels"
                />
              </NavContainer>
              <NavContainer>
                <NavLink
                  disabled={isStarterFreePlan}
                  classes={cs(
                    {
                      active: regexTest(AuthorizedPaths.trendsSaves),
                    },
                    'nav-link nav-sub-link'
                  )}
                  href={AuthorizedPaths.trendsSaves}
                  text={
                    isStarterFreePlan ? (
                      withToolTip(
                        strings.UPGRADE_PLAN({
                          description:
                            strings.UPGRADE_TO_STARTER_PREMIUM_SAVES_DESC,
                        }),
                        <span>Saves{lockIcon}</span>
                      )
                    ) : (
                      <span>Saves</span>
                    )
                  }
                />
              </NavContainer>
            </NavCollapseContainer>
          </Collapse>
          <NavHeader active={regexTest('customers')}>
            <NavItem
              title="Customers"
              classes={cs(
                {
                  active: regexTest('customers'),
                },
                'nav-link',
                'nav-customers'
              )}
              icon="customers"
              text={
                <>
                  Customers
                  {expired && lockIcon}
                </>
              }
              onClick={() => {
                const newVal = !customersExpanded;
                setCustomersExpanded(newVal);
                if (newVal) {
                  history.push(
                    createUrl(AuthorizedPaths.customers, {
                      customerView: 'all',
                    })
                  );
                }
              }}
            />
          </NavHeader>
          <Collapse isOpened={customersExpanded}>
            <NavCollapseContainer>
              <NavContainer>
                <NavLink
                  classes={cs(
                    {
                      active: regexTest(`customers/all`),
                    },
                    'nav-link nav-sub-link'
                  )}
                  href="/customers/all"
                  text="All"
                />
              </NavContainer>
              <NavContainer>
                <NavLink
                  classes={cs(
                    {
                      active: regexTest(`customers/cancel`),
                    },
                    'nav-link nav-sub-link'
                  )}
                  href="/customers/cancels"
                  text="Cancels"
                />
              </NavContainer>
              <NavContainer>
                <NavLink
                  classes={cs(
                    {
                      active: regexTest(`customers/save`),
                    },
                    'nav-link nav-sub-link'
                  )}
                  href="/customers/saves"
                  disabled={isStarterFreePlan}
                  text={
                    isStarterFreePlan ? (
                      withToolTip(
                        strings.UPGRADE_PLAN({
                          description:
                            strings.UPGRADE_TO_STARTER_PREMIUM_SAVES_DESC,
                        }),
                        <div>Saves{lockIcon}</div>
                      )
                    ) : (
                      <>Saves</>
                    )
                  }
                />
              </NavContainer>
              <NavContainer>
                <NavLink
                  classes={cs(
                    {
                      active: regexTest(`customers/watchlist`),
                    },
                    'nav-link nav-sub-link'
                  )}
                  href="/customers/watchlist"
                  text="Watch list"
                />
              </NavContainer>
              <NavContainer>
                <NavLink
                  classes={cs(
                    {
                      active: regexTest(`customers/lostDeflects`),
                    },
                    'nav-link nav-sub-link'
                  )}
                  href="/customers/lostDeflects"
                  text={'Lost Deflects'}
                  linkAttributes={{
                    title: 'Cancels that had a deflect.',
                  }}
                />
              </NavContainer>
              {isRepurchaseAttributionEnabled && (
                <>
                  <NavContainer>
                    <NavLink
                      disabled={isStarter}
                      classes={cs(
                        {
                          active: regexTest(`customers/repurchases`),
                        },
                        'nav-link nav-sub-link'
                      )}
                      href="/customers/repurchases"
                      text={
                        isStarter ? (
                          withToolTip(
                            strings.UPGRADE_PLAN({
                              description: strings.UPGRADE_TO_DEFLECTION_DESC,
                            }),
                            <span>Repurchases{lockIcon}</span>
                          )
                        ) : (
                          <span>Repurchases</span>
                        )
                      }
                    />
                  </NavContainer>

                  <NavContainer>
                    <NavLink
                      disabled={isStarter}
                      classes={cs(
                        {
                          active: regexTest(`customers/winbacks`),
                        },
                        'nav-link nav-sub-link'
                      )}
                      href="/customers/winbacks"
                      text={
                        isStarter ? (
                          withToolTip(
                            strings.UPGRADE_PLAN({
                              description: strings.UPGRADE_TO_DEFLECTION_DESC,
                            }),
                            <span>Winbacks{lockIcon}</span>
                          )
                        ) : (
                          <span>Winbacks</span>
                        )
                      }
                    />
                  </NavContainer>
                </>
              )}
            </NavCollapseContainer>
          </Collapse>
        </ul>
      </div>
      <ul className="utilities">
        {showWizardPopup && (
          <Suspense fallback={<SuspenseLoader message=" " />}>
            <WelcomePopup />
          </Suspense>
        )}

        {showInternal && (
          <div className="nav-links">
            <NavContainer>
              <NavLink
                classes={cs({ active: regexTest('internal') }, 'nav-link')}
                icon="cbRetention2023Grayscale"
                text="Retention"
                href="/internal/companies"
              />
            </NavContainer>
            <Collapse
              isOpened={
                internalExpanded ||
                window.location.pathname.includes('/internal/')
              }
            >
              {internalMenu.map((item) => {
                if (
                  item.hiddenFromNav ||
                  item.featureFlags.filter((ff) => featureFlags.includes(ff))
                    .length === 0
                ) {
                  return null;
                }

                return (
                  <NavContainer key={item.path}>
                    <NavLink
                      classes={cs(
                        {
                          active: regexTest(item.path),
                        },
                        'nav-link nav-sub-link'
                      )}
                      href={item.path}
                      text={item.title /* + (item.component ? ' [REACT]':'') */}
                      linkAttributes={{
                        target: item.target,
                        rel: item.rel,
                      }}
                    />
                  </NavContainer>
                );
              })}
            </Collapse>
          </div>
        )}
        {hasChargebeeIntegration && <CBProductsSwitcher />}
        {!isStarter && (
          <NavContainer>
            <NavItem
              title="Tour"
              classes="nav-link"
              text="Tour"
              icon="flag"
              onClick={() =>
                dispatch(
                  appNotPersistedSlice.actions.saveMisc({
                    showProductTour: true,
                  })
                )
              }
            />
            <ProductTourModal />
          </NavContainer>
        )}
        <NavContainer>
          <NavLink
            classes={cs({ active: regexTest('help') }, 'nav-link')}
            href="https://www.chargebee.com/docs/retention"
            text="Help docs"
            icon="lifesaver"
            linkAttributes={{
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
          />
        </NavContainer>
        <NavContainer>
          <NavItem
            onClick={() => undefined}
            classes="nav-link"
            text={
              <a href="mailto:retention-support@chargebee.com">
                Contact support
              </a>
            }
            icon="contactSupport"
          />
        </NavContainer>
        <NavContainer>
          <div ref={userAnchorRef}>
            <NavItem
              classes={cs('nav-link')}
              onClick={() => setUserMenuOpen(true)}
              iconNode={<ProfileImage user={user} />}
              text={user.details.display_name}
            />
          </div>
        </NavContainer>
        <LinkListPopover
          open={userMenuOpen}
          linkGroupItems={userMenuGroupItems}
          handleClose={() => setUserMenuOpen(false)}
          anchorEl={userAnchorRef.current}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 10,
          }}
        />

        <NavContainer>
          <NavLink
            classes={cs({ active: regexTest('settings') }, 'nav-link')}
            icon="shield"
            href={AuthorizedPaths.settings}
            text="Settings"
          />
        </NavContainer>

        {enableAppSwitcher && company && (
          <>
            <NavContainer>
              <div ref={appAnchorRef}>
                <NavItem
                  classes="nav-link app-menu"
                  onClick={() => setAppMenuOpen(true)}
                  iconNode={
                    <CompanyLogo
                      className="tw-w-5 tw-ml-0.5 tw-rounded-full"
                      company={company}
                    />
                  }
                  content={
                    <>
                      {appCount > 1 && (
                        <div className="company-name">{company.name}</div>
                      )}
                      <div className="env" title={company.name}>
                        {app?.display_name ?? 'Default'}
                      </div>
                    </>
                  }
                />
              </div>
            </NavContainer>
            <LinkListPopover
              open={appMenuOpen}
              linkGroupItems={appMenuGroupItems}
              handleClose={() => setAppMenuOpen(false)}
              anchorEl={appAnchorRef.current}
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 10,
              }}
              enableSearch
            />
          </>
        )}
      </ul>
      {showAlert && <BottomAlertTab />}
    </div>
  );
};

export default LeftColumn;
