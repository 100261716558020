import React from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { useUrl } from '@bbkAdminUtils/nav';
import Icon from '@bbkAdminComponents/icons/icon-generator';
import './styles/buttons.scss';

type Props = {
  title: React.ReactNode;
  href?: string;
  toOptions?: LinkProps['to'];
  arrow?: boolean;
} & Omit<LinkProps, 'to' | 'title'>;

const LinkButton: React.FC<Props> = ({
  title,
  href,
  toOptions,
  arrow = true,
  ...restLinkProps
}) => {
  const createUrl = useUrl();
  const to = toOptions ?? createUrl(href);
  return (
    <Link
      to={to}
      className={classNames(
        'bbk-button',
        'primary',
        'arrowButton',
        'brightPurple'
      )}
      {...restLinkProps}
    >
      <span className="text">{title}</span>
      {arrow && <Icon name="arrow" />}
    </Link>
  );
};

export default LinkButton;
