import React from 'react';
import Icon from '@bbkAdminComponents/icons/icon-generator';
import './styles/buttons.scss';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';

type Props = {
  title: React.ReactNode;
  clicked?: React.MouseEventHandler;
};
const SendButton: ReactFCC<Props> = ({ title, clicked }) => (
  <button className="bbk-button primary brightPurple" onClick={clicked}>
    <Icon name="send" />
    {title}
  </button>
);

export default SendButton;
