/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from '@emotion/styled';
import { checkmark } from '@bbkAdminComponents/switches/checkbox/checkbox';
import tailwindColors from '@bbkRoot/tailwind.colors';

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

type StyledCheckboxProps = {
  checked?: boolean;
  darkPurple?: boolean;
  circle?: boolean;
  icon?: React.ReactNode;
  size?: 'normal' | 'small' | 'tiny';
};
const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) =>
    size === 'small' ? '18px' : size === 'tiny' ? '15px' : '22px'};
  height: ${({ size }) =>
    size === 'small' ? '18px' : size === 'tiny' ? '15px' : '22px'};
  background: ${({ checked, darkPurple }) =>
    checked
      ? darkPurple
        ? '#5D1BAE'
        : tailwindColors.cbrprimary['600']
      : '#fff'};
  border: ${({ checked }) =>
    checked ? 'none' : '1px solid rgba(0, 0, 0, 0.25)'};
  border-radius: ${(props) => (props.circle ? '50%' : '2px')};
  color: #fff;

  &:hover {
    cursor: pointer;
  }

  ${HiddenCheckbox}:disabled + & {
    opacity: 0.3;
    cursor: not-allowed;
  }
  svg {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
`;

export type CheckboxBase = StyledCheckboxProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>;
export const Checkbox: React.FC<CheckboxBase> = ({
  darkPurple,
  circle,
  className,
  checked,
  icon,
  size,
  ...restProps
}) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox type="checkbox" checked={checked} {...restProps} />
    <StyledCheckbox
      checked={checked}
      circle={circle}
      darkPurple={darkPurple}
      size={size}
    >
      {icon ?? checkmark}
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
`;
