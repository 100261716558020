import React from 'react';
import type { Argument } from 'classnames';
import cs from 'classnames';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import logo from '@bbkAdminImages/cbretention-logo.png';
import whiteLogo from '@bbkAdminImages/cbretention-white-logo.png';

export const CBRetentionLogo: ReactFCC<{
  white?: boolean;
  className?: Argument;
}> = ({ white, className }) => (
  <img
    srcSet={`${white ? whiteLogo : logo} 2x`}
    className={cs(className ?? 'tw-w-full')}
    alt="Chargebee Retention"
  />
);
