// @ts-nocheck
import { produce } from 'immer';
import { set } from 'lodash';
import type { RestOffer } from '@bbkAdminUtils/api-client/rest-offers-api';
import {
  OfferCategory,
  OfferObjective,
  OfferPresentation,
} from '@bbkAdminUtils/api-client/rest-offers-api';
import { NEW_OFFER_GOALS } from '@bbkAdminRedux/rtkq/offers-templates.slice';
import type {
  Goal,
  Layout,
} from '@bbkAdminComponents/experiences/offers/new-offer-flow';
import {
  actions,
  globalFinalizeOffer,
  globalProduceLayoutWithGoal,
  globalProduceUpdatedAction,
  layouts,
} from '@bbkAdminComponents/experiences/offers/new-offer-flow';
import type {
  CtaDetails,
  RestModalCard,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import {
  CtaProcessingOption,
  RestModalAction,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import {
  replaceBrightbackWithDomainInOffer,
  updateCtaAction,
} from '@bbkAdminComponents/experiences/offers/edit-offer-utils';
import { collectForwards } from '@bbkAdminComponents/experiences/offers/collect-forwards';

export const createNewOffer = (
  cat: OfferCategory,
  domain: string,
  useBillingProcessing?: boolean
): RestOffer => {
  const goals = NEW_OFFER_GOALS;

  const getLayout = (goal: Goal): Layout => {
    const layoutName = 'Image Card';
    const layoutLocal = layouts.find((l) => l.name === layoutName);
    if (!layoutLocal) {
      throw new Error(
        `useCreateOffer, could not find layout with name ${layoutName}`
      );
    }
    return {
      ...layoutLocal,
      offer: {
        ...globalProduceLayoutWithGoal(layoutLocal, goal),
      },
    };
  };

  const updateLayout = (layout: Layout, newPrimaryCard: RestModalCard) => {
    const primaryCardIndex = layout?.offer.cards.findIndex(
      (c) => c.name === layout.offer.primary_card_name
    );
    return produce(layout, (draft) =>
      set(draft, `offer.cards.${primaryCardIndex}`, newPrimaryCard)
    );
  };

  const generateOffer = () => {
    const isMultiOffer = cat === OfferCategory.multi_cta;
    const isMultiAction = cat === OfferCategory.multi_action;
    const numberButtons = 2;

    const goal =
      goals.find((g) => g.category === cat) ??
      goals.find((g) => g.category === OfferCategory.discount);
    const layout = getLayout(goal);
    const primaryCard = layout.offer.cards.find(
      (c) => c.name === layout.offer.primary_card_name
    ) as RestModalCard;
    if (!primaryCard) {
      throw new Error(
        `useCreateOffer, could not find primary card: ${JSON.stringify(
          layout.offer
        )}`
      );
    }

    const actionName = 'Link';
    const action = actions.find((a) => a.name === actionName);
    if (!action) {
      throw new Error(
        `useCreateOffer, could not find action with name ${actionName}`
      );
    }
    const newPrimaryCard: RestModalCard = produce(primaryCard, (draft) =>
      set(
        draft,
        'requires.forwards',
        globalProduceUpdatedAction(primaryCard.requires.forwards, action, goal)
      )
    );
    const newLayout = updateLayout(layout, newPrimaryCard);

    const layoutLocal = globalFinalizeOffer(newLayout);
    const updatedOffer = produce(layoutLocal.offer, (draft) => {
      const draftPrimaryCard: RestModalCard | undefined = draft.cards.find(
        (c) => c.name === draft.primary_card_name
      );
      const primaryCardIdx = draft.cards.indexOf(draftPrimaryCard);
      if (primaryCardIdx !== -1 && isMultiOffer) {
        //add additional CTA to multi-CTA offers
        for (let i = 0; i < numberButtons - 1; i += 1) {
          updateCtaAction(
            draft,
            ['cards', primaryCardIdx, 'requires', 'other_actions', i],
            RestModalAction.link,
            domain
          );
        }
      }
      if (draftPrimaryCard) {
        const draftForwards = collectForwards(draftPrimaryCard);
        draftForwards.forEach((fw, idx) => {
          set(
            draftPrimaryCard,
            [...fw.path, 'cta_details_list'],
            [
              {
                processing_option: useBillingProcessing
                  ? CtaProcessingOption.billing
                  : CtaProcessingOption.url,
                category: isMultiOffer ? undefined : cat,
              } as CtaDetails,
            ]
          );
        });
      }
      draft.category = isMultiOffer ? OfferCategory.multi_cta : cat;
      draft.isNew = true;
      draft.objective = OfferObjective.churn_deflection;
      draft.presentation = OfferPresentation.modal;
    });

    return replaceBrightbackWithDomainInOffer(updatedOffer, domain);
  };

  return generateOffer();
};
