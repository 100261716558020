import React, { lazy } from 'react';
import { FeatureFlagsE } from '@bbkAdminUtils/feature-flags';
import { Link } from 'react-router-dom';

export type InternalReactPageDefinition = {
  title: string;
  path: string;
  component?: React.LazyExoticComponent<React.ComponentType<any>>;
  featureFlags: FeatureFlagsE[];
  hiddenFromNav?: boolean;
  superAdminOnly?: boolean;
  target?: Partial<React.ComponentProps<typeof Link>>['target'];
  rel?: Partial<React.ComponentProps<typeof Link>>['rel'];
};
/**
 * After adding a component-based page to this file, run
 * `bbk generate-internal-page-stubs`
 * to stub out the new components
 *
 * Ensure that the component path format matches the others (same dir name as file name, no extension)
 */

const InternalReactPages: InternalReactPageDefinition[] = [
  {
    title: 'Activity',
    path: '/internal/activity',
    component: lazy(() => import('@bbkInternalComponents/activity/activity')),
    featureFlags: [FeatureFlagsE.CAN_ACCESS_ACTIVITY_LOG],
  },
  {
    title: 'Api Explorer',
    path: '/internal/apiexplorer',
    superAdminOnly: true,
    component: lazy(() => import('@bbkInternalComponents/api/api')),
    featureFlags: [FeatureFlagsE.CAN_ACCESS_API_EXPLORER],
  },
  {
    title: 'Application',
    path: '/internal/applications/',
    featureFlags: [FeatureFlagsE.CAN_VIEW_COMPANY_DATA],
    hiddenFromNav: true,
  },
  {
    title: 'Application',
    path: '/internal/company/:companyKey/applications/:appKey',
    featureFlags: [FeatureFlagsE.CAN_VIEW_COMPANY_DATA],
    hiddenFromNav: true,
    component: lazy(
      () =>
        import('@bbkInternalComponents/companies/application/updateApplication')
    ),
  },
  {
    title: 'Create Application',
    path: '/internal/company/:companyKey/application',
    featureFlags: [FeatureFlagsE.CAN_VIEW_COMPANY_DATA],
    hiddenFromNav: true,
    component: lazy(
      () =>
        import('@bbkInternalComponents/companies/application/createApplication')
    ),
  },
  {
    title: 'Funnel',
    path: '/internal/company/:companyKey/applications/:appKey/funnels/:funnelId',
    featureFlags: [FeatureFlagsE.CAN_ACCESS_LOW_LEVEL_APPLICATION_DATA],
    hiddenFromNav: true,
    component: lazy(
      () => import('@bbkInternalComponents/companies/funnel/funnelDetail')
    ),
  },
  {
    title: 'Retention JS',
    path: '/internal/bbkJsExample',
    component: lazy(
      () => import('@bbkInternalComponents/bbkJsExample/bbkJsExample')
    ),
    featureFlags: [FeatureFlagsE.CAN_ACCESS_BRIGHTBACK_JS],
  },
  {
    title: 'LifeCycle Offer JS',
    path: '/internal/lifecycle-offer',
    component: lazy(
      () =>
        import('@bbkInternalComponents/bbkJsExample/lifecycle-offer-example')
    ),
    featureFlags: [FeatureFlagsE.CAN_ACCESS_LIFECYCLE_JS],
  },
  {
    title: 'CDN Manager',
    path: '/internal/cdnManager',
    component: lazy(
      () => import('@bbkInternalComponents/cdnManager/cdnManager')
    ),
    superAdminOnly: true,
    featureFlags: [FeatureFlagsE.SUPER_ADMIN],
  },
  {
    title: 'Companies',
    path: '/internal/companies',
    component: lazy(() => import('@bbkInternalComponents/companies/companies')),
    featureFlags: [FeatureFlagsE.CAN_VIEW_COMPANY_DATA],
  },
  {
    title: 'Company',
    path: '/internal/companies/company/:companyKey',
    component: lazy(
      () => import('@bbkInternalComponents/companies/company/company')
    ),
    hiddenFromNav: true,
    featureFlags: [FeatureFlagsE.CAN_VIEW_COMPANY_DATA],
  },
  {
    title: 'Experience Details',
    path: '/internal/experiences/:experienceType/:companyKey/:uuid/version/:version?',
    component: lazy(
      () =>
        import('@bbkInternalComponents/companies/experience/experienceDetails')
    ),
    hiddenFromNav: true,
    featureFlags: [FeatureFlagsE.CAN_ACCESS_LOW_LEVEL_APPLICATION_DATA],
  },
  {
    title: 'Experience Version',
    path: '/internal/experiences/:uuid/:companyKey/versions',
    component: lazy(
      () =>
        import('@bbkInternalComponents/companies/experience/experienceVersions')
    ),
    hiddenFromNav: true,
    featureFlags: [FeatureFlagsE.CAN_ACCESS_LOW_LEVEL_APPLICATION_DATA],
  },
  {
    title: 'Experience List',
    path: '/internal/experiences/:companyKey',
    component: lazy(
      () =>
        import('@bbkInternalComponents/companies/experience/experiencesList')
    ),
    hiddenFromNav: true,
    featureFlags: [FeatureFlagsE.CAN_ACCESS_LOW_LEVEL_APPLICATION_DATA],
  },
  {
    title: 'Create Experience',
    path: '/internal/experiences/:companyKey/create',
    component: lazy(
      () =>
        import('@bbkInternalComponents/companies/experience/createExperience')
    ),
    hiddenFromNav: true,
    featureFlags: [FeatureFlagsE.CAN_ACCESS_LOW_LEVEL_APPLICATION_DATA],
  },
  {
    title: 'Create New Company',
    path: '/internal/accounts/provision',
    component: lazy(
      () => import('@bbkInternalComponents/companies/createCompany')
    ),
    hiddenFromNav: true,
    featureFlags: [FeatureFlagsE.CAN_CREATE_NEW_COMPANY],
  },
  {
    title: 'Usage Report',
    path: '/internal/sessionUsage',
    component: lazy(() => import('@bbkInternalComponents/sessionUsage/report')),
    featureFlags: [FeatureFlagsE.CAN_ACCESS_COMPANY_SESSION_LOOKUP_REPORT],
  },
  {
    title: 'Data Generation',
    path: '/internal/dataGeneration',
    component: lazy(
      () => import('@bbkInternalComponents/dataGeneration/dataGeneration')
    ),
    featureFlags: [FeatureFlagsE.SUPER_ADMIN],
  },
  {
    title: 'Decoder Ring',
    path: '/internal/decoder',
    superAdminOnly: true,
    component: lazy(() => import('@bbkInternalComponents/decoder/decoder')),
    featureFlags: [FeatureFlagsE.CAN_ACCESS_BRIGHTBACK_JS],
  },
  {
    title: 'Digest Email',
    path: '/internal/digestEmail/',
    featureFlags: [FeatureFlagsE.CAN_VIEW_COMPANY_DATA],
    component: lazy(
      () => import('@bbkInternalComponents/digestEmail/digestEmail')
    ),
  },
  {
    title: 'Email Suppression List',
    path: '/internal/emailSuppressionList',
    component: lazy(
      () =>
        import(
          '@bbkInternalComponents/emailSuppressionList/emailSuppressionList'
        )
    ),
    superAdminOnly: true,
    featureFlags: [FeatureFlagsE.SUPER_ADMIN],
  },
  {
    title: 'Events',
    path: '/internal/events',
    component: lazy(() => import('@bbkInternalComponents/events/events')),
    superAdminOnly: true,
    featureFlags: [FeatureFlagsE.SUPER_ADMIN],
  },
  {
    title: 'Feature Flags',
    path: '/internal/featureFlags',
    component: lazy(
      () => import('@bbkInternalComponents/featureFlags/featureFlags')
    ),
    superAdminOnly: true,
    featureFlags: [FeatureFlagsE.CAN_ADMIN_FEATURE_FLAGS],
  },
  {
    title: 'Funnel Demo',
    path: '/internal/demo',
    component: lazy(() => import('@bbkInternalComponents/demo/demo')),
    target: 'funnel_demo',
    rel: 'noopener noreferrer',
    featureFlags: [FeatureFlagsE.CAN_ACCESS_DEMO_PAGE],
  },
  {
    title: 'Invite User',
    path: '/internal/invite',
    component: lazy(
      () => import('@bbkInternalComponents/inviteUser/inviteUser')
    ),
    featureFlags: [FeatureFlagsE.CAN_ACCESS_USER_DATA],
  },
  {
    title: 'Task Manager',
    path: '/internal/taskManager',
    component: lazy(
      () => import('@bbkInternalComponents/taskManager/taskManager')
    ),
    superAdminOnly: true,
    featureFlags: [FeatureFlagsE.CAN_ACCESS_TASK_MANAGER],
  },
  {
    title: 'Test Console',
    path: '/internal/testConsole',
    component: lazy(
      () => import('@bbkInternalComponents/testConsole/testConsole')
    ),
    superAdminOnly: true,
    featureFlags: [FeatureFlagsE.SUPER_ADMIN],
  },
  {
    title: 'Query Playground',
    path: '/internal/playground',
    component: lazy(
      () => import('@bbkInternalComponents/playground/playground')
    ),
    superAdminOnly: true,
    featureFlags: [FeatureFlagsE.CAN_ACCESS_QUERY_PLAYGROUND],
  },
  {
    title: 'User Management',
    path: '/internal/user',
    component: lazy(() => import('@bbkInternalComponents/user/user')),
    featureFlags: [FeatureFlagsE.CAN_ACCESS_USER_DATA],
  },
  {
    title: 'Users',
    path: '/internal/users',
    component: lazy(() => import('@bbkInternalComponents/users/users')),
    featureFlags: [FeatureFlagsE.CAN_ACCESS_USER_DATA],
  },
  {
    title: 'Sessions',
    path: '/internal/company/:companyKey/applications/:appKey/experiences/:funnelId/sessions',
    component: lazy(() => import('@bbkInternalComponents/sessions/sessions')),
    featureFlags: [FeatureFlagsE.CAN_VIEW_COMPANY_DATA],
    hiddenFromNav: true,
  },
  {
    title: 'Session',
    path: '/internal/company/:companyKey/applications/:appKey/experiences/:funnelId/sessions/:sessionId',
    component: lazy(() => import('@bbkInternalComponents/session/session')),
    featureFlags: [FeatureFlagsE.CAN_VIEW_COMPANY_DATA],
    hiddenFromNav: true,
  },
  {
    title: 'Event',
    path: '/internal/company/:companyKey/applications/:appKey/experiences/:funnelId/sessions/:sessionId/events',
    component: lazy(() => import('@bbkInternalComponents/event/event')),
    featureFlags: [FeatureFlagsE.CAN_VIEW_COMPANY_DATA],
    hiddenFromNav: true,
  },
  {
    title: 'Internal ID Inspector',
    path:
      '/internal/company/:companyKey/applications/:appKey/experiences' +
      '/:funnelId/sessions/:sessionId/internalIdInspector/:appId/:internalId',
    component: lazy(
      () =>
        import('@bbkInternalComponents/internalIdInspector/internalIdInspector')
    ),
    featureFlags: [FeatureFlagsE.SUPER_ADMIN],
    hiddenFromNav: true,
  },
  {
    title: 'Internal ID Inspector',
    path: '/internal/internalIdInspector',
    component: lazy(
      () =>
        import('@bbkInternalComponents/internalIdInspector/internalIdInspector')
    ),
    featureFlags: [FeatureFlagsE.SUPER_ADMIN],
  },
  {
    title: 'Application Content',
    path: '/internal/company/:companyKey/applications/:appKey/:pageType',
    component: lazy(
      () =>
        import('@bbkInternalComponents/applicationContent/applicationContent')
    ),
    hiddenFromNav: true,
    featureFlags: [FeatureFlagsE.CAN_ACCESS_LOW_LEVEL_APPLICATION_DATA],
  },
].sort((a, b) => {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  if (a.title.toLowerCase() < b.title.toLowerCase()) {
    return -1;
  }
  if (a.title.toLowerCase() > b.title.toLowerCase()) {
    return 1;
  }
  return 0;
});

export default InternalReactPages;
