import type { RefObject } from 'react';
import React, { createContext, useCallback, useMemo, useState } from 'react';
import type { Dictionary } from '@bbkAdminUtils/utility-types';
import { omit } from 'lodash';

type RefOpt = RefObject<HTMLElement>;
export type RefOpts = Dictionary<RefOpt>;
export const RefsContext = createContext<
  | {
      refs: RefOpts;
      addRef: (name: string, opt: RefOpt) => void;
      removeRef: (name: string) => void;
    }
  | undefined
>(undefined);

export const RefsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [refs, setRefs] = useState<RefOpts>({});

  const addRef = useCallback((name: string, opts: RefOpt): void => {
    setRefs((prevState) => ({ ...prevState, [name]: opts }));
  }, []);

  const removeRef = useCallback((name: string): void => {
    setRefs((prevState) => omit(prevState, name));
  }, []);

  const value = useMemo(
    () => ({ refs, addRef, removeRef }),
    [refs, addRef, removeRef]
  );

  return <RefsContext.Provider value={value}>{children}</RefsContext.Provider>;
};
export const REFS_CONTEXT_FUNNEL_PREFIX = 'funnel-';
