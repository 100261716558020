/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import cs from 'classnames';
import { Popover } from '@material-ui/core';
import Fuse from 'fuse.js';
import {
  ExitSurveyIcons,
  ExitSurveyIconsCol,
} from '@bbkAdminUtils/icons/funnel-icons';
import ActionButton from '@bbkAdminComponents/buttons/action-button';
import Icons from '@bbkAdminUtils/icons';
import SearchTextInput from '@bbkAdminComponents/inputs/search-text/search-text-input';
import './icon-picker.scss';
import type { ElementType, MapElement } from '@bbkAdminUtils/utility-types';

const fuseOptions = {
  shouldSort: true,
  threshold: 0.4,
  keys: ['name', 'tags'],
};

type IconId = keyof typeof ExitSurveyIcons;
type IconEl = MapElement<typeof ExitSurveyIcons>;
type Props = {
  selectedIconId?: IconId;
  iconSelect: (icon: { icon: IconEl['icon'] | 'none' }) => void;
  brightbackTheme?: boolean;
  enableClear?: boolean;
  filter?: IconId[];
};

const defaultProps = {
  selectedIconId: 'none',
  brightbackTheme: false,
  filter: [],
};

type State = {
  chosenIcon?: IconEl;
  buttonFinderOpen: boolean;
  iconSearch: string;
  filteredIcons: IconEl[];
};

class IconPicker extends Component<Props & typeof defaultProps, State> {
  static defaultProps = defaultProps;

  pickerRef = React.createRef<HTMLButtonElement>();

  searchInput = React.createRef<HTMLInputElement>();

  filter = (el: ElementType<typeof ExitSurveyIconsCol>) => {
    const icons = this.props.filter;
    return icons.indexOf(el.name) > -1;
  };

  filteredIcons =
    this.props.filter.length > 0
      ? ExitSurveyIconsCol.filter(this.filter)
      : ExitSurveyIconsCol;

  fuseSearch = new Fuse([...this.filteredIcons], fuseOptions);

  state: State = {
    chosenIcon: undefined,
    buttonFinderOpen: false,
    iconSearch: '',
    filteredIcons: [...this.filteredIcons],
  };

  componentDidMount() {
    this.setSelectedIconId();
  }

  setSelectedIconId = () => {
    const { selectedIconId } = this.props;
    if (selectedIconId === 'none') return;
    if (selectedIconId) {
      // can it be located
      const selectedIcon = ExitSurveyIcons[selectedIconId];
      if (selectedIcon) {
        // it can be located
        this.setState({
          chosenIcon: selectedIcon,
        });
      } else {
        console.warn(
          `[ICON PICKER] A selected icon ID (${selectedIconId}) was provided but does not match any icons available for use. Please check this and change it.`
        );
      }
    }
  };

  clearSelectedIconId = () => {
    const { iconSelect } = this.props;
    iconSelect({ icon: 'none' });
    this.setState({
      chosenIcon: undefined,
    });
  };

  focusSearchInput = () => {
    setTimeout(() => {
      this.searchInput.current?.focus();
    }, 50);
  };

  openIconPopover = () => {
    this.setState(
      {
        buttonFinderOpen: true,
      },
      this.focusSearchInput
    );
  };

  closeIconPopover = () => {
    this.setState({
      buttonFinderOpen: false,
    });
  };

  handleIconSelect = (e: React.MouseEvent<HTMLButtonElement>, icon: IconEl) => {
    const { iconSelect } = this.props;
    iconSelect(icon);
    this.setState(
      {
        chosenIcon: { ...icon },
      },
      () => this.closeIconPopover()
    );
  };

  handleIconSearch = (e: { target: { value: string } }) => {
    const searchVal = e.target.value;
    this.setState({
      iconSearch: searchVal,
      filteredIcons:
        searchVal === ''
          ? [...this.filteredIcons]
          : this.fuseSearch.search(searchVal).map((i) => i.item),
    });
  };

  render() {
    const { chosenIcon, buttonFinderOpen, iconSearch, filteredIcons } =
      this.state;
    const { brightbackTheme, enableClear = false } = this.props;
    return (
      <div
        className={cs('bbk-icon-picker', {
          'bbk-icon-picker-bbk-theme': brightbackTheme,
        })}
      >
        <button
          className="bbk-icon-picker-button"
          type="button"
          onClick={this.openIconPopover}
          ref={this.pickerRef}
        >
          {chosenIcon?.svg}
        </button>
        <Popover
          classes={{ paper: 'bbk-ip-popover' }}
          onClose={this.closeIconPopover}
          anchorEl={this.pickerRef.current}
          open={buttonFinderOpen}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <div className="bbk-po-menu no-pad">
            <div className="bbk-ip-po-ic-input-area">
              <SearchTextInput
                name="searchIconPicker"
                id="searchIconPicker"
                value={iconSearch}
                classes="short"
                placeholder="Icon search..."
                onChange={this.handleIconSearch}
                maxLength={50}
                ref={this.searchInput}
              />
            </div>
            <div className="bbk-icon-picker-popover-icons">
              {filteredIcons.map((icon) => (
                <button
                  className={cs('bbk-ip-pi-exi-icon', {
                    'icon-selected': chosenIcon?.icon === icon.icon,
                  })}
                  title={icon.name}
                  type="button"
                  key={icon.icon}
                  onClick={(e) => this.handleIconSelect(e, icon)}
                >
                  {icon.svg}
                </button>
              ))}
            </div>
            <div
              className="bbk-ip-po-ic-button d-flex"
              style={{ justifyContent: 'space-between' }}
            >
              <ActionButton
                title="Done"
                icon={Icons.check}
                onClick={this.closeIconPopover}
                classes="brightPurple"
                iconClass="brightPurple"
              />
              {enableClear && (
                <ActionButton
                  title="Clear"
                  onClick={this.clearSelectedIconId}
                  classes="gray"
                />
              )}
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

export default IconPicker;
