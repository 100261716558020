/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Popover } from '@material-ui/core';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import './generic-dialog.scss';

export type ModalOnCloseReason = 'backdropClick' | 'escapeKeyDown';
type Props = {
  disabled?: boolean;
  actionButton?: typeof DefaultActionButton;
  onOpen?: () => void;
  onClose?: (reason?: ModalOnCloseReason) => void;
  popoverProps?: Omit<React.ComponentProps<typeof Popover>, 'open'> & {
    open?: boolean;
  };
  children: (props: { handleClose: () => void }) => React.ReactNode;
};

export const DefaultActionButton: React.FC<{
  onClick: React.MouseEventHandler;
  disabled: boolean | undefined;
}> = (props) => (
  <ButtonV2 transparent deepColor centerAll css={{ padding: 5 }} {...props}>
    Open
  </ButtonV2>
);

export const GenericPopover: React.FC<Props> = ({
  children,
  disabled,
  actionButton: ActionButton = DefaultActionButton,
  onOpen,
  onClose,
  popoverProps,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onOpen?.();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (reason?: ModalOnCloseReason) => {
    onClose?.(reason);
    setAnchorEl(null);
  };

  return (
    <>
      <ActionButton onClick={handleOpen} disabled={disabled} />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={(e, reason) => handleClose(reason)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...popoverProps}
      >
        {children({ handleClose })}
      </Popover>
    </>
  );
};
