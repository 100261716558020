// @ts-nocheck
import React from 'react';
import { RenderElementProps, useFocused, useSelected } from 'slate-react';
import icons from '@bbkAdminUtils/icons';
import tailwindColors from '@bbkRoot/tailwind.colors';
import { getFieldPrefixForBbkExpression } from '@bbkAdminComponents/rich-text-editor/bbk-expression/transforms/getFieldPrefixForBbkExpression';

/**
 * BbkExpressionElement with no default styles.
 * [Use the `styles` API to add your own styles.](https://github.com/OfficeDev/office-ui-fabric-react/wiki/Component-Styling)
 */
export const BbkExpressionElement: React.FC<RenderElementProps> = (props) => {
  const { attributes, element, children } = props;
  const selected = useSelected();
  const focused = useFocused();

  const {
    field: { name, fallBackValue, standard, alert, topMethod },
  } = element;

  const baseStyles = {
    backgroundColor: tailwindColors.cbrprimary['50'],
    border: '1px solid ' + tailwindColors.cbrprimary['500'],
    borderRadius: '3px',
    color: tailwindColors.cbrprimary['600'],
    display: 'inline-block',
    fontSize: '13px',
    margin: '0 1px',
    padding: '2px 8px',
    verticalAlign: 'baseline',
    '&:hover, &:focus': {
      border: '1px solid ' + tailwindColors.cbrprimary['600'],
      backgroundColor: tailwindColors.cbrprimary['100'],
      outline: 0,
    },
  };
  const selectedStyles = {
    border: '1px solid ' + tailwindColors.cbrprimary['600'],
    backgroundColor: tailwindColors.cbrprimary['100'],
    outline: 0,
  };
  return (
    <span
      {...attributes}
      contentEditable={false}
      css={[baseStyles, selected && selectedStyles]}
      title={getFieldPrefixForBbkExpression({ standard, alert })}
    >
      {topMethod === 'value' ? (
        <span style={{ fontWeight: 'bold' }}>{name}</span>
      ) : (
        <>
          <span style={{ fontWeight: 'bold' }}>{name} | </span>
          {fallBackValue && fallBackValue !== '' ? (
            <span css={{ fontStyle: 'italic' }}>{fallBackValue}</span>
          ) : (
            icons.warning
          )}
        </>
      )}
      <span css={{ display: 'none' }}>{children}</span>
    </span>
  );
};
