import React from 'react';
import classNames, { Argument } from 'classnames';
import { IndicatorColorsMap } from './indicator-colors';

import './styles/dot.scss';
import { css } from '@emotion/react';

export type DotIndicatorColor = keyof typeof IndicatorColorsMap;
type Props = {
  dotColor: DotIndicatorColor;
  classes?: Argument;
  [extraProps: string]: any;
};

const DotIndicator: React.FC<Props> = (props) => {
  const { dotColor = 'gray', classes, children, ...extraProps } = props;
  return (
    <div
      className={classNames('dot-indicator', dotColor, classes)}
      {...extraProps}
    >
      {children}
    </div>
  );
};

export default DotIndicator;
