// @ts-nocheck
import React, { createContext, useState } from 'react';
import { produce } from 'immer';
import { get, has } from 'lodash';
import type {
  RestModalCard,
  RestModalForwards,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import type { RestOffer } from '@bbkAdminUtils/api-client/rest-offers-api';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';
import { IntegrationDataType } from '@bbkAdminRedux/data-management/reducers';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import {
  getDefaultActionContent,
  getDefaultActionSteps,
} from '../content/action-content';
import type { Action, Layout } from '.';

export type Goal = {
  name: string;
  category: string;
  info: {
    title: string;
    details: string;
  };
  forwards: {
    text?: string;
  };
};
export const globalProduceUpdatedAction = (
  currentAction: RestModalForwards,
  newAction: Action,
  goal: Goal
) => {
  const newActionObj = getDefaultActionContent(newAction.forwards.action);
  const updatedAction = {
    ...currentAction,
    ...newActionObj,
    ...goal.forwards,
  };
  return updatedAction;
};

export const globalProduceLayoutWithGoal = (
  layoutToEdit: Layout,
  goal: Partial<Goal>
): RestOffer => {
  // console.log(goal, layoutToEdit);
  const result = produce(layoutToEdit, (draft) => {
    const { offer } = draft;
    offer.category = goal.category;
    offer.type = goal?.type;
    offer.display_name = goal?.name || offer.display_name;
    const primaryCard = offer.cards.find(
      (c) => c.name === offer.primary_card_name
    );

    primaryCard.requires.info = {
      ...primaryCard.requires.info,
      ...goal.info,
    };

    if (has(primaryCard, 'requires.forwards')) {
      primaryCard.requires.forwards = {
        ...primaryCard.requires.forwards,
        ...goal?.forwards,
      };
    } else if (has(primaryCard, 'requires.generic_actions')) {
      if (layoutToEdit.group === 'finalOffer') {
        primaryCard.requires.generic_actions[0] = {
          ...primaryCard.requires.generic_actions[0],
          ...goal?.forwards,
        };
      } else {
        primaryCard.requires.generic_actions =
          primaryCard.requires.generic_actions.map((ga) => ({
            ...ga,
            ...goal?.forwards,
          }));
      }
    }

    offer.cards = [primaryCard];
  });

  // console.log(result);
  return result.offer;
};

export const globalFinalizeOffer = (newLayout: Layout): Layout => {
  const result = produce(newLayout, (draft) => {
    const { offer } = draft;

    const primaryCard = offer.cards.find(
      (c) => c.name === offer.primary_card_name
    );

    let actionItems: RestModalForwards[] = [];
    const cards: RestModalCard[] = [];
    // forwards
    if (has(primaryCard, 'requires.forwards')) {
      actionItems.push(get(primaryCard, 'requires.forwards'));
    }
    // generic actions
    else if (has(primaryCard, 'requires.generic_actions')) {
      actionItems.push(...get(primaryCard, 'requires.generic_actions', []));
    }

    actionItems = actionItems.map((ai) => {
      // Generate values
      const additionalActionCards = getDefaultActionSteps(ai, ai.action);
      const actionContent = getDefaultActionContent(ai.action);
      if (additionalActionCards.length) {
        actionContent.modal = additionalActionCards[0].name;
      }
      actionContent.text = ai.text;

      cards.push(...additionalActionCards);
      return {
        ...ai,
        ...actionContent,
      };
    });

    // forwards
    if (has(primaryCard, 'requires.forwards')) {
      primaryCard.requires.forwards = get(actionItems, '0');
    }
    // generic actions
    else if (has(primaryCard, 'requires.generic_actions')) {
      primaryCard.requires.generic_actions = actionItems;
    }

    offer.cards = [primaryCard, ...cards];
  });

  return result;
};

type BillingDetails = {
  intervalCount?: number;
  billingIntervalPeriod?: string;
  couponId?: string;
  planId?: string;
};

export const NewOfferFlowContext = createContext<{
  // Goal (step 1)
  goal?: Goal;
  setGoal: React.Dispatch<React.SetStateAction<Goal | undefined>>;

  // Layout
  layout?: Layout;
  setLayout: React.Dispatch<React.SetStateAction<Layout | undefined>>;

  connectBilling: boolean;
  setConnectBilling: React.Dispatch<React.SetStateAction<boolean>>;

  billingDetails: BillingDetails;
  setBillingDetails: React.Dispatch<React.SetStateAction<BillingDetails>>;

  produceBillingDataInOffer: (layout: Layout) => Layout | boolean;
  produceLayoutWithGoal: (layoutToEdit: Layout) => RestOffer;
  produceUpdatedAction: (
    currentAction: RestModalForwards,
    newAction: Action
  ) => RestModalForwards;

  // generates additional modals for offers with certain actions
  finalizeOffer: (layout: Layout) => Layout;
}>(undefined!);

type NewOfferFlowContextProviderProps = {
  integration_dataType: IntegrationDataType | undefined;
};

export const NewOfferFlowContextProvider: ReactFCC<
  NewOfferFlowContextProviderProps
> = ({ children, integration_dataType }) => {
  const [goal, setGoal] = useState<Goal>();
  const [layout, setLayout] = useState<Layout>();
  const [billingDetails, setBillingDetails] = useState<BillingDetails>({});
  const [connectBilling, setConnectBilling] = useState(false);

  const verifyBillingData = () => {
    // TODO: Change to YUP verification
    if (connectBilling) {
      if (goal?.category === 'discount' && has(billingDetails, 'couponId')) {
        // requires couponId
        return {
          coupon_id: billingDetails.couponId,
        };
      }
      if (goal?.category === 'plan_change' && has(billingDetails, 'planId')) {
        return {
          plan_id: billingDetails.planId,
        };
      }
      if (goal?.category === 'extension' || goal?.category === 'pause') {
        if (
          integration_dataType === IntegrationDataType.RECURLY &&
          goal?.category === 'pause' &&
          has(billingDetails, 'intervalCount')
        ) {
          return {
            interval_count: billingDetails.intervalCount,
          };
        }
        if (
          has(billingDetails, 'intervalCount') &&
          has(billingDetails, 'billingIntervalPeriod')
        ) {
          return {
            interval_count: billingDetails.intervalCount,
            billing_interval: billingDetails.billingIntervalPeriod,
          };
        }
      }
    }

    BlackToastTrigger({ content: 'Select required fields to link billing.' });
    return false;
  };

  const produceBillingDataInOffer = (layoutToEdit: Layout) => {
    const verifyBillingDataObj = verifyBillingData();
    if (verifyBillingDataObj) {
      return produce(layoutToEdit, (draft) => {
        draft.offer = {
          ...draft.offer,
          ...verifyBillingDataObj,
          billing: true,
        };
      });
    }
    return false;
  };

  return (
    <NewOfferFlowContext.Provider
      value={{
        // Goal
        goal,
        setGoal,

        // Layout
        layout,
        setLayout,

        // Billing details
        connectBilling,
        setConnectBilling,

        billingDetails,
        setBillingDetails,

        produceBillingDataInOffer,
        produceLayoutWithGoal: (layoutToEdit: Layout) =>
          globalProduceLayoutWithGoal(layoutToEdit, goal),
        produceUpdatedAction: (
          currentAction: RestModalForwards,
          newAction: Action
        ) => globalProduceUpdatedAction(currentAction, newAction, goal),

        // generates additional modals for offers with certain actions
        finalizeOffer: globalFinalizeOffer,
      }}
    >
      {children}
    </NewOfferFlowContext.Provider>
  );
};
