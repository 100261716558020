/* eslint-disable jsx-a11y/label-has-for,react/jsx-props-no-spreading */
import React from 'react';
import cs from 'classnames';
import { css } from '@emotion/react';
import TippyTooltip from '@bbkAdminComponents/tooltips/tippy-tooltip';
import icons from '@bbkAdminUtils/icons';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';

type Props = {
  id?: string;
  label?: string | React.ReactElement;
  helpText?: string | React.ReactElement;
  classes?: string;
  explain?: boolean;
} & React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

const Label: ReactFCC<Props> = (props) => {
  const { id, label, classes, helpText, explain, children, ...restProps } =
    props;
  const baseStyles = css`
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    font-style: ${explain ? 'italic' : 'normal'};
    &.error {
      color: #fb315d;
    }
  `;
  return (
    <label
      htmlFor={id}
      css={[baseStyles]}
      className={cs('bbk-label-component', classes)}
      {...restProps}
    >
      {label || children}
      {helpText && (
        <TippyTooltip content={helpText}>
          <span
            className="bbk-label-help-text"
            css={{
              display: 'flex',
              paddingLeft: '4px',
            }}
          >
            {icons.questionMark}
          </span>
        </TippyTooltip>
      )}
    </label>
  );
};

export default Label;
