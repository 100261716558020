import React from 'react';
import RoundButton from '@bbkAdminComponents/buttons/round-button';
import { TourStoryProps } from './tour-story-index';

const small = '300.jpg';
const medium = '768.jpg';
const large = '1280.jpg';
const ResponsiveImage = () => (
  <img
    src={'/assets/images/tour/trends-canceled.png'}
    srcSet={`${'/assets/images/tour/trends-canceled.png'} 300w, ${'/assets/images/tour/trends-canceled@2x.png'} 768w, ${'/assets/images/tour/trends-canceled@2x.png'} 1280w`}
  />
);

const ProductTourStoryCancelTrends = ({ onClick }: TourStoryProps) => (
  <div className="bbk-product-tour-story-template bbk-product-tour-story-cancel-trends">
    <article>
      <h1>Customer & Churn Insights</h1>
      <figure>
        <ResponsiveImage />
      </figure>
      <p>
        We go beyond just Deflection Performance reporting to provide insights
        into your Churn and Canceling Customers. These reports help answer:
      </p>
      <div className="columns padding center">
        <div>
          <p className="small">
            <em>How many customers canceled because of “missing features”?</em>
          </p>
          <p className="small">
            <em>What are my top Cancel Reasons?</em>
          </p>
          <p className="small">
            <em>Which types of customers are most likely to return?</em>
          </p>
        </div>
        <div>
          <p className="small">
            <em>How have cancel reasons changed over time?</em>
          </p>
          <p className="small">
            <em>
              How much revenue was lost last month due to product/shipping
              issues?
            </em>
          </p>
          <p className="small">
            <em>
              How did a pricing or product change impact cancelation reasons?
            </em>
          </p>
        </div>
      </div>
      <p>
        The Cancel Insights, Save & Cancel Trends reports and Customers page all
        help provide deeper insight into your canceling customers. These
        insights can be used to make upstream changes to your product or
        onboarding to improve overall retention or an be used to implement new
        Targeting in the cancel flow.
      </p>
      <footer>
        <p>
          <RoundButton onClick={onClick}>
            Next: Audiences & Targeting
          </RoundButton>
        </p>
      </footer>
    </article>
  </div>
);

export default {
  name: 'Customer & Churn Insights',
  key: 'bbk-pt-cancel-trends-report',
  story: <ProductTourStoryCancelTrends />,
};
