import { useMemo } from 'react';
import moment from 'moment';
import { reportsSlice, ReportType } from '@bbkAdminRedux/rtkq/reports.slice';
import { AppKey } from '@bbkAdminRedux/rtkq/current-user.slice';
import { selectFromResultTyped } from '@bbkAdminRedux/redux_utils';

export type BenchmarkMetricsReport = {
  metrics: {
    business_model: AppBusinessModel;

    min_deflect_rate: number;
    avg_deflect_rate: number;
    max_deflect_rate: number;

    min_save_rate: number;
    avg_save_rate: number;
    max_save_rate: number;

    min_active_deflect_rate: number;
    avg_active_deflect_rate: number;
    max_active_deflect_rate: number;

    min_passive_deflect_rate: number;
    avg_passive_deflect_rate: number;
    max_passive_deflect_rate: number;
  }[];
};

export enum AppBusinessModel {
  SaaS = 'SaaS',
  eCommerce = 'eCommerce',
  'd2c digital' = 'd2c digital',
}

export enum AppBusinessVertical {
  APPAREL_AND_BEAUTY = 'Apparel & Beauty',
  CONSUMER_SERVICES = 'Consumer Services',
  EDUCATION = 'Education',
  FINANCE = 'Finance',
  FINTECH_AND_INSURANCE = 'Fintech & Insurance',
  FOOD_DELIVERY = 'Food Delivery',
  HEALTH_AND_WELLNESS = 'Health & Wellness',
  HOSPITALITY = 'Hospitality',
  MANUFACTURING = 'Manufacturing',
  NEWS_AND_PUBLISHING = 'News & Publishing',
  PETS = 'Pets',
  RETAIL = 'Retail',
  SAAS = 'SaaS',
  STREAMING_MEDIA_AND_SERVICES = 'Streaming Media & Services',
  TELECOMMINICATIONS = 'Telecommunications',
  TRANSPORTATION = 'Transportation',
  OTHER = 'Other',
}

export const BUSINESS_VERTICAL_OPTS: {
  value: AppBusinessVertical;
  label: string;
}[] = [
  { value: AppBusinessVertical.APPAREL_AND_BEAUTY, label: 'Apparel & Beauty' },
  { value: AppBusinessVertical.CONSUMER_SERVICES, label: 'Consumer Services' },
  { value: AppBusinessVertical.EDUCATION, label: 'Education' },
  { value: AppBusinessVertical.FINANCE, label: 'Finance' },
  {
    value: AppBusinessVertical.FINTECH_AND_INSURANCE,
    label: 'Fintech & Insurance',
  },
  { value: AppBusinessVertical.FOOD_DELIVERY, label: 'Food Delivery' },
  {
    value: AppBusinessVertical.HEALTH_AND_WELLNESS,
    label: 'Health & Wellness',
  },
  { value: AppBusinessVertical.HOSPITALITY, label: 'Hospitality' },
  { value: AppBusinessVertical.MANUFACTURING, label: 'Manufacturing' },
  {
    value: AppBusinessVertical.NEWS_AND_PUBLISHING,
    label: 'News & Publishing',
  },
  { value: AppBusinessVertical.PETS, label: 'Pets' },
  { value: AppBusinessVertical.RETAIL, label: 'Retail' },
  { value: AppBusinessVertical.SAAS, label: 'SaaS' },
  {
    value: AppBusinessVertical.STREAMING_MEDIA_AND_SERVICES,
    label: 'Streaming Media & Services',
  },
  {
    value: AppBusinessVertical.TELECOMMINICATIONS,
    label: 'Telecommunications',
  },
  { value: AppBusinessVertical.TRANSPORTATION, label: 'Transportation' },
  { value: AppBusinessVertical.OTHER, label: 'Other' },
];

export const BUSINESS_MODEL_OPTS: {
  value: AppBusinessModel;
  label: string;
}[] = [
  {
    value: AppBusinessModel.eCommerce,
    label: 'eCommerce',
  },
  {
    value: AppBusinessModel.SaaS,
    label: 'B2B SaaS',
  },
  {
    value: AppBusinessModel['d2c digital'],
    label: 'Digital',
  },
];

export enum DataLineType {
  deflect_rate = 'deflect_rate',
  save_rate = 'save_rate',
  active_deflect_rate = 'active_deflect_rate',
  passive_deflect_rate = 'passive_deflect_rate',
}

export type BenchmarkMetricLine = {
  type: DataLineType;
  min: number;
  max: number;
  avg: number;
};

export const useBenchmarkMetricsReportQuery = (args: { appKey: AppKey }) =>
  reportsSlice.endpoints.getReportV2.useQuery(
    {
      appKey: args.appKey,
      start: moment('2018-01-01T00:00:00.000Z').toDate(),
      end: moment('2100-12-31T23:59:59.999Z').toDate(),
      report_type_requests: [{ report_type: ReportType.benchmark_metrics }],
    },
    {
      skip: !args.appKey,
      selectFromResult: selectFromResultTyped<BenchmarkMetricsReport>,
    }
  );

export const useSelectBenchmarkMetrics = (args: {
  appKey: AppKey;
}): {
  business_model: AppBusinessModel;
  metrics: BenchmarkMetricLine[];
}[] => {
  const { data: report } = useBenchmarkMetricsReportQuery(args);

  return useMemo(
    () =>
      (report?.metrics || []).map((value) => ({
        business_model: value.business_model,
        metrics: [
          {
            type: DataLineType.deflect_rate,
            min: value.min_deflect_rate,
            max: value.max_deflect_rate,
            avg: value.avg_deflect_rate,
          },
          {
            type: DataLineType.save_rate,
            min: value.min_save_rate,
            max: value.max_save_rate,
            avg: value.avg_save_rate,
          },
          {
            type: DataLineType.active_deflect_rate,
            min: value.min_active_deflect_rate,
            max: value.max_active_deflect_rate,
            avg: value.avg_active_deflect_rate,
          },
          {
            type: DataLineType.passive_deflect_rate,
            min: value.min_passive_deflect_rate,
            max: value.max_passive_deflect_rate,
            avg: value.avg_passive_deflect_rate,
          },
        ],
      })),
    [report]
  );
};
