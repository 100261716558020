export const popupCenter = (
  url: string,
  title: string,
  w: number,
  h: number
) => {
  const documentEl = document.documentElement;
  const screen = window.screen;

  // Fixes dual-screen position. Most browsers ?? Firefox
  const dualScreenLeft = window.screenLeft ?? window.screenX;
  const dualScreenTop = window.screenTop ?? window.screenY;

  const width = window.innerWidth ?? documentEl.clientWidth ?? screen.width;
  const height = window.innerHeight ?? documentEl.clientHeight ?? screen.height;

  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;

  const opts = `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`;
  const newWindow = window.open(url, title, opts);

  // Puts focus on the newWindow
  newWindow?.focus?.();
  return newWindow;
};
