/* eslint-disable max-len,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useShallowCompareEffect } from 'react-use';
import { css } from '@emotion/react';
import cs from 'classnames';
import type { LogArgument } from 'rollbar';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import Icons, { CBRIcons } from '@bbkAdminUtils/icons';
import cbrClock from '@bbkAdminImages/icons/cbrClock.png';
import cbrPagePreview from '@bbkAdminImages/cbrPagePreview.png';
import cbrCongratulations from '@bbkAdminImages/cbrCongratulations.png';
import tailwindColors from '@bbkRoot/tailwind.colors';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { useCreatePagesListUrl, useUrl } from '@bbkAdminUtils/nav';
import { AuthorizedPaths } from '@bbkAdminRouter/router-paths';
import { selectAppId } from '@bbkAdminRedux/app/selectors';
import {
  integrationSlice,
  useAvailableIntegrations,
  useSelectBillingIntegration,
} from '@bbkAdminRedux/rtkq/integrations.slice';
import {
  IntegrationDataType,
  IntegrationTypeEnum,
} from '@bbkAdminRedux/data-management/reducers';
import { IntegrationRow } from '@bbkAdminComponents/integrations/integrations-page';
import { CollapseFormContainerControlled } from '@bbkAdminComponents/form';
import { externalLinkIcon } from '@bbkAdminComponents/switches/toggle/toggle';
import { BillingSettings } from '@bbkAdminComponents/settings/billing-settings';
import { RevenueConfiguration } from '@bbkAdminComponents/settings/revenue-config';
import { UrlsConfig } from '@bbkAdminComponents/settings/urls-config';
import {
  ONBOARDING_HISTORY_STATUS,
  ONBOARDING_HISTORY_STEP,
  onboardingHistorySlice,
  selectOnboardingHistoryMap,
} from '@bbkAdminRedux/rtkq/onboarding-history.slice';
import { isValidOffer } from '@bbkAdminComponents/experiences/offers/edit-offer-validation';
import { useMapCategoriesToOffers } from '@bbkAdminComponents/get-started/onboarding-step1-context';
import { usePlans } from '@bbkAdminUtils/plans';
import { useSelectCurrentApp } from '@bbkAdminRedux/rtkq/current-user.slice';
import { useSelectActiveFunnels } from '@bbkAdminRedux/rtkq/funnels.slice';
import { recordError } from '@bbkAdminUtils/recordError';
import { useBaseUriDomain } from '@bbkAdminRedux/rtkq/preauth-company.slice';

export const GetStartedRowNum: ReactFCC<
  {
    size?: 6 | 7 | 9;
    className?: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ children, size = 9, className, ...restProps }) => (
  <div
    className={cs(
      'tw-bg-cbrneutral-100 tw-rounded-3xl tw-font-bold tw-flex tw-items-center tw-justify-center',
      {
        'tw-w-6 tw-h-6 tw-text-xs': size === 6,
        'tw-w-7 tw-h-7 tw-text-xs': size === 7,
        'tw-w-9 tw-h-9': size === 9,
      },
      className
    )}
    {...restProps}
  >
    {children}
  </div>
);

const GetStartedRow: ReactFCC<{ step: number; complete?: boolean }> = ({
  step,
  complete,
  children,
}) => {
  const lineBgCss = css`
    background-image: linear-gradient(
      90deg,
      ${tailwindColors.cbrneutral[100]} 1px,
      transparent 0,
      transparent
    );
    background-position: top;
    background-repeat: no-repeat;
    background-size: 1px 100%;
  `;
  return (
    <div className="tw-flex">
      <div css={step !== 4 && lineBgCss}>
        <GetStartedRowNum
          size={9}
          className={cs({
            'tw-bg-cbrsuccess-400 tw-text-white': complete,
          })}
        >
          {complete ? CBRIcons.cbrCheckmark(24, 24) : step}
        </GetStartedRowNum>
      </div>
      <div className="tw-ml-2 tw-w-full tw-pb-10">{children}</div>
    </div>
  );
};

const GetStartedRowTitle: ReactFCC<{
  title: string;
  time: string;
  open?: boolean;
  onClick?: React.MouseEventHandler;
}> = ({ title, time, open, onClick }) => (
  <div className="tw-flex tw-items-center tw-justify-between tw-cursor-default tw-select-none">
    <div
      className="tw-uppercase tw-font-bold tw-text-xs tw-h-9 tw-flex tw-items-center tw-cursor-pointer"
      onClick={onClick}
    >
      {title}
      <span
        className={cs('tw-ml-1.5 tw-transition-all', {
          '-tw-rotate-90': !open,
        })}
      >
        {Icons.cbrChevronDown}
      </span>
    </div>
    <div className="tw-flex tw-items-center tw-text-cbrneutral-500 tw-text-xs tw-font-semibold">
      <img srcSet={`${cbrClock} 2x`} alt="" className="tw-mr-1" /> {time}
    </div>
  </div>
);

const GetStartedRowDesc: ReactFCC<{
  open?: boolean;
  className?: string;
  wrapperClassName?: string;
}> = ({ children, open, className, wrapperClassName }) => (
  <div
    className={cs(
      'tw-bg-cbrneutral-50 tw-shadow tw-rounded',
      'tw-transition-all tw-overflow-y-scroll',
      {
        'tw-max-h-[150rem]': open,
        'tw-max-h-0': !open,
      },
      wrapperClassName
    )}
  >
    <div className={cs('tw-p-6', className)}>{children}</div>
  </div>
);

const SectionCollapsableSubtitle: ReactFCC<{
  text: React.ReactNode;
  complete?: boolean;
  onClick?: React.MouseEventHandler;
}> = ({ text, complete, onClick }) => (
  <div className="tw-flex tw-items-center">
    <span className="tw-text-[10px] tw-font-semibold tw-text-cbrneutral-500 tw-mr-4">
      {text}
    </span>
    <GetStartedRowNum
      size={6}
      className={cs('tw-inline tw-border tw-border-cbrneutral-200', {
        'tw-bg-cbrsuccess-400 tw-text-white tw-border-0': complete,
        'tw-bg-white': !complete,
      })}
      onClick={onClick}
    >
      {complete ? CBRIcons.cbrCheckmark() : undefined}
    </GetStartedRowNum>
  </div>
);

const GetStartedBilling: ReactFCC = () => {
  const appKey = useSelector(selectAppId);
  const billingIntegration = useSelectBillingIntegration({ appKey });
  const { isFetching: isIntegrationsFetching } =
    integrationSlice.endpoints.getIntegrations.useQuery({ appKey });
  const { data: integrations = [] } = useAvailableIntegrations();
  const billingIntegrations = useMemo(
    () =>
      integrations.filter(
        (i) => i.integration_type === IntegrationTypeEnum.Billing
      ),
    [integrations]
  );

  return (
    <>
      <div className="tw-text-sm">
        Retention integrates directly with your billing system to manage the
        processing of cancels via the respective billing systems API&apos;s for
        you.{' '}
        <a
          href="https://www.chargebee.com/docs/retention/chargebee-retention-billing-integrations-stripe-recurly-chargebee.html"
          target="_blank"
          rel="noreferrer"
          className="tw-underline"
        >
          Learn more {externalLinkIcon}
        </a>
      </div>

      {isIntegrationsFetching && <>{Icons.cbrLoader} Loading...</>}
      <div className="tw-flex tw-flex-wrap tw-gap-4 tw-mt-4 tw-max-w-2xl">
        {billingIntegration ? (
          <IntegrationRow
            short
            className="tw-w-3/4 tw-justify-between"
            integration={billingIntegration}
          />
        ) : (
          billingIntegrations?.map((integration) => (
            <IntegrationRow
              short
              className="tw-w-[calc(50%-1rem)] tw-justify-between !tw-py-2 !tw-pl-2 !tw-pr-1 !tw-mb-0"
              integration={integration}
              key={integration.id}
            />
          ))
        )}
      </div>
    </>
  );
};
const GetStartedAlerts: ReactFCC = () => {
  const createUrl = useUrl();
  const appKey = useSelector(selectAppId);
  const { isFetching: isIntegrationsFetching } =
    integrationSlice.endpoints.getIntegrations.useQuery({ appKey });
  const { data: integrations = [] } = useAvailableIntegrations();
  const alertsIntegrations = useMemo(
    () =>
      integrations.filter(
        (i) =>
          i.data_type === IntegrationDataType.SEGMENT ||
          i.data_type === IntegrationDataType.SLACK
      ),
    [integrations]
  );

  return (
    <>
      <div className="tw-text-sm">
        Alerts help keep you in the loop whenever a customer triggers events in
        the cancel experience, like successful deflections or actions initiated.
      </div>

      {isIntegrationsFetching && <>{Icons.cbrLoader} Loading...</>}
      <div className="tw-mt-4 tw-max-w-2xl">
        {alertsIntegrations?.map((integration) => (
          <IntegrationRow
            className="!tw-py-2"
            integration={integration}
            key={integration.id}
          />
        ))}
        <Link
          to={{
            pathname: createUrl(AuthorizedPaths.settingsSubscriptions),
          }}
          className="tw-block tw-text-sm tw-font-bold tw-text-cbrprimary-600 tw-mb-2"
        >
          Configure other alerts & events
          {CBRIcons.cbrArrowRight()}
        </Link>
      </div>
    </>
  );
};

export const useGetStartedStatus = (): {
  isBillingCompleted: boolean;
  isCreateExperienceCompleted: boolean;
  isConfigureStarterAccountCompleted: boolean;
  isConfigureAccountCompleted: boolean;
  isTestAndLaunchCompleted: boolean;
} => {
  const app = useSelectCurrentApp();
  const appKey = useSelector(selectAppId);
  const billingIntegration = useSelectBillingIntegration({ appKey });
  const { data: steps = {} } =
    onboardingHistorySlice.endpoints.getOnboardingHistory.useQuery(
      { appKey },
      {
        skip: appKey === '_' || app?.get_started_status !== 'ELIGIBLE',
        selectFromResult: selectOnboardingHistoryMap,
      }
    );

  const { isStarterFreePlan, isStarterPremiumPlan } = usePlans();
  const isStarterPlan = isStarterPremiumPlan || isStarterFreePlan;

  const isBillingCompleted = Boolean(billingIntegration);
  const isCreateExperienceCompleted =
    (isStarterPlan && Boolean(steps[ONBOARDING_HISTORY_STEP.BRANDING])) ||
    Boolean(
      steps[ONBOARDING_HISTORY_STEP.BRANDING] &&
        steps[ONBOARDING_HISTORY_STEP.CREATE_OFFERS] &&
        steps[ONBOARDING_HISTORY_STEP.CREATE_PAGES]
    );
  const isConfigureStarterAccountCompleted = Boolean(
    steps[ONBOARDING_HISTORY_STEP.TECHNICAL_INSTALLATION] &&
      steps[ONBOARDING_HISTORY_STEP.INTEGRATE_BILLING_SYSTEM] &&
      steps[ONBOARDING_HISTORY_STEP.ALERTS] &&
      steps[ONBOARDING_HISTORY_STEP.FIELD_MAPPINGS] &&
      steps[ONBOARDING_HISTORY_STEP.DOMAIN] &&
      steps[ONBOARDING_HISTORY_STEP.URL_AND_PAGES]
  );
  const isConfigureAccountCompleted =
    isConfigureStarterAccountCompleted &&
    Boolean(steps[ONBOARDING_HISTORY_STEP.REPORTING_REVENUE_FIELD]);
  const isTestAndLaunchCompleted = Boolean(
    steps[ONBOARDING_HISTORY_STEP.GO_LIVE]
  );

  return {
    isBillingCompleted,
    isCreateExperienceCompleted,
    isConfigureStarterAccountCompleted,
    isConfigureAccountCompleted,
    isTestAndLaunchCompleted,
  };
};

export const GetStartedPage: ReactFCC = () => {
  const history = useHistory();
  const createUrl = useUrl();
  const [expanded, setExpanded] = useState<
    Partial<
      Record<
        | 'billing'
        | 'experience'
        | 'account'
        | 'account.installation'
        | 'account.billing'
        | 'account.alerts'
        | 'account.mappings'
        | 'account.revenue'
        | 'account.domain'
        | 'account.urls'
        | 'launch',
        boolean
      >
    >
  >({});

  const offers = useMapCategoriesToOffers();

  const appKey = useSelector(selectAppId);
  const billingIntegration = useSelectBillingIntegration({ appKey });

  const activeFunnels = useSelectActiveFunnels({ appKey });
  const { data: steps = {} } =
    onboardingHistorySlice.endpoints.getOnboardingHistory.useQuery(
      { appKey },
      { skip: appKey === '_', selectFromResult: selectOnboardingHistoryMap }
    );
  const [updateOnboardingHistory] =
    onboardingHistorySlice.endpoints.updateOnboardingHistory.useMutation();
  const [goLive, { isLoading: isGoLiveLoading }] =
    onboardingHistorySlice.endpoints.goLive.useMutation();

  const baseUriDomain = useBaseUriDomain();

  const { isStarterFreePlan, isStarterPremiumPlan } = usePlans();
  const isStarterPlan = isStarterFreePlan || isStarterPremiumPlan;

  const newExpanded = useMemo(() => {
    const updatedExpanded: typeof expanded = {};
    if (activeFunnels.length > 2) {
      updatedExpanded.launch = true;
      return updatedExpanded;
    }
    if (!billingIntegration) {
      updatedExpanded.billing = true;
    }
    if (
      isStarterPlan
        ? !steps[ONBOARDING_HISTORY_STEP.BRANDING]
        : !(
            steps[ONBOARDING_HISTORY_STEP.BRANDING] &&
            steps[ONBOARDING_HISTORY_STEP.CREATE_OFFERS] &&
            steps[ONBOARDING_HISTORY_STEP.CREATE_PAGES]
          )
    ) {
      updatedExpanded.experience = true;
      return updatedExpanded;
    }
    if (
      !(
        steps[ONBOARDING_HISTORY_STEP.TECHNICAL_INSTALLATION] &&
        steps[ONBOARDING_HISTORY_STEP.INTEGRATE_BILLING_SYSTEM] &&
        steps[ONBOARDING_HISTORY_STEP.ALERTS] &&
        steps[ONBOARDING_HISTORY_STEP.FIELD_MAPPINGS] &&
        steps[ONBOARDING_HISTORY_STEP.REPORTING_REVENUE_FIELD] &&
        steps[ONBOARDING_HISTORY_STEP.DOMAIN] &&
        steps[ONBOARDING_HISTORY_STEP.URL_AND_PAGES]
      )
    ) {
      updatedExpanded.account = true;
      if (!steps[ONBOARDING_HISTORY_STEP.TECHNICAL_INSTALLATION]) {
        updatedExpanded['account.installation'] = true;
        return updatedExpanded;
      }
      if (!steps[ONBOARDING_HISTORY_STEP.INTEGRATE_BILLING_SYSTEM]) {
        updatedExpanded['account.billing'] = true;
        return updatedExpanded;
      }
      if (!steps[ONBOARDING_HISTORY_STEP.ALERTS]) {
        updatedExpanded['account.alerts'] = true;
        return updatedExpanded;
      }
      if (!steps[ONBOARDING_HISTORY_STEP.FIELD_MAPPINGS]) {
        updatedExpanded['account.mappings'] = true;
        return updatedExpanded;
      }
      if (
        !isStarterFreePlan &&
        !steps[ONBOARDING_HISTORY_STEP.REPORTING_REVENUE_FIELD]
      ) {
        updatedExpanded['account.revenue'] = true;
        return updatedExpanded;
      }
      if (!steps[ONBOARDING_HISTORY_STEP.DOMAIN]) {
        updatedExpanded['account.domain'] = true;
        return updatedExpanded;
      }
      if (!steps[ONBOARDING_HISTORY_STEP.URL_AND_PAGES]) {
        updatedExpanded['account.urls'] = true;
        return updatedExpanded;
      }
      if (!steps[ONBOARDING_HISTORY_STEP.GO_LIVE]) {
        updatedExpanded['launch'] = true;
        return updatedExpanded;
      }
      return updatedExpanded;
    }

    return {
      launch: true,
    };
  }, [isStarterFreePlan, isStarterPlan, billingIntegration, steps]);

  useShallowCompareEffect(() => {
    setExpanded(newExpanded);
  }, [newExpanded]);

  const handleGoLive = (): void => {
    goLive({ appKey })
      .unwrap()
      .then(() => {
        history.push(createUrl(AuthorizedPaths.dashboard), {
          showBanner: true,
        });
      })
      .catch((err) => recordError(err));
  };

  const {
    isBillingCompleted,
    isCreateExperienceCompleted,
    isConfigureStarterAccountCompleted,
    isConfigureAccountCompleted,
    isTestAndLaunchCompleted,
  } = useGetStartedStatus();

  const stepsCompleted = [
    isBillingCompleted,
    isCreateExperienceCompleted,
    isConfigureStarterAccountCompleted,
    isConfigureAccountCompleted,
    isTestAndLaunchCompleted,
  ].filter(Boolean).length;

  const completeStep =
    (step: ONBOARDING_HISTORY_STEP): React.MouseEventHandler =>
    (e): void => {
      if (!steps[step]) {
        e.stopPropagation();
        updateOnboardingHistory({
          appKey,
          step,
          status: ONBOARDING_HISTORY_STATUS.COMPLETED,
        })
          .unwrap()
          .catch((err: LogArgument) => recordError(err));
      }
    };

  const vanityDomainDocsUrl =
    'https://www.chargebee.com/docs/retention/setting-up-a-custom-domain.html';

  const chargebeeSiteName = billingIntegration?.required_input?.find(
    (x) => x.name === 'site'
  )?.value;

  const createExperienceUrl = useMemo(() => {
    const step1Url = createUrl(
      AuthorizedPaths.getStartedCreateExperienceStep1Branding
    );
    const step2Url = createUrl(
      AuthorizedPaths.getStartedCreateExperienceStep2Offers
    );
    const step3Url = createUrl(
      AuthorizedPaths.getStartedCreateExperienceStep3Targeting
    );
    if (
      steps.CREATE_OFFERS &&
      offers.length > 0 &&
      offers.every(isValidOffer)
    ) {
      return step3Url;
    }
    if (steps.BRANDING) {
      return step2Url;
    }
    return step1Url;
  }, [steps]);

  const pagesListUrl = useCreatePagesListUrl();
  return (
    <div className="tw-bg-white tw-p-8 -tw-mr-[30px] -tw-mb-4 tw-min-h-[100vh]">
      <div className="tw-text-cbrneutral-800 tw-font-bold">Get started</div>
      <div className="tw-text-cbrneutral-500 tw-text-sm">
        Create and launch your first cancel experience
      </div>

      <div className="tw-flex tw-w-72 tw-items-center tw-justify-between tw-px-3 tw-py-2.5 tw-mt-5 tw-rounded tw-border tw-border-cbrneutral-200">
        <div className="tw-w-1/2 tw-flex tw-gap-1">
          <div
            className={cs('tw-rounded-3xl tw-basis-1/4 tw-border-b-4', {
              'tw-border-cbrsuccess-400': isBillingCompleted,
            })}
          />
          <div
            className={cs('tw-rounded-3xl tw-basis-1/4 tw-border-b-4', {
              'tw-border-cbrsuccess-400': isCreateExperienceCompleted,
            })}
          />
          <div
            className={cs('tw-rounded-3xl tw-basis-1/4 tw-border-b-4', {
              'tw-border-cbrsuccess-400': isStarterPlan
                ? isConfigureStarterAccountCompleted
                : isConfigureAccountCompleted,
            })}
          />
          <div
            className={cs('tw-rounded-3xl tw-basis-1/4 tw-border-b-4', {
              'tw-border-cbrsuccess-400': isTestAndLaunchCompleted,
            })}
          />
        </div>
        <div className="tw-text-xs tw-font-bold">
          {stepsCompleted}/4 steps complete
        </div>
      </div>

      <div className="tw-mt-6">
        <GetStartedRow step={1} complete={isBillingCompleted}>
          <GetStartedRowTitle
            title="Connect your billing"
            time="10 minutes"
            open={expanded.billing}
            onClick={() =>
              setExpanded({ ...expanded, billing: !expanded.billing })
            }
          />
          <GetStartedRowDesc open={expanded.billing}>
            <GetStartedBilling />
            {!isBillingCompleted && (
              <div
                onClick={() => {
                  setExpanded({
                    ...expanded,
                    billing: !expanded.billing,
                  });
                }}
                className={cs(
                  'tw-max-w-2xl tw-w-full -tw-ml-4 tw-mt-4',
                  'tw-text-cbrprimary-600 tw-font-bold tw-text-sm',
                  'tw-cursor-pointer tw-text-right'
                )}
              >
                Skip for now
              </div>
            )}
          </GetStartedRowDesc>
        </GetStartedRow>
      </div>
      <div>
        <GetStartedRow step={2} complete={isCreateExperienceCompleted}>
          <GetStartedRowTitle
            title="Create your cancel experience"
            time="10 minutes"
            open={expanded.experience}
            onClick={() =>
              setExpanded({ ...expanded, experience: !expanded.experience })
            }
          />
          <GetStartedRowDesc open={expanded.experience} className="tw-flex">
            {isCreateExperienceCompleted ? (
              <div className="-tw-m-6 tw-flex">
                <div>
                  <img
                    srcSet={`${cbrCongratulations} 2x`}
                    alt=""
                    className="tw-mr-6"
                  />
                </div>

                <div>
                  <div className="tw-text-sm tw-text-black tw-mb-2 tw-mt-4">
                    <div className="tw-text-base tw-font-bold tw-mb-1">
                      Congrats, you’ve created your cancel experience!
                    </div>
                    Proceed to step 3 to configure your account.
                  </div>

                  <div className="tw-flex tw-justify-between tw-my-4">
                    <div>
                      <Link
                        to={{
                          pathname: pagesListUrl,
                        }}
                      >
                        <ButtonV2 primary>Edit experience</ButtonV2>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div>
                  <img
                    srcSet={`${cbrPagePreview} 2x`}
                    alt=""
                    className="tw-mr-6"
                  />
                </div>

                <div>
                  <div className="tw-text-sm tw-text-black">
                    {isStarterPlan ? (
                      <>
                        Build beautiful cancel pages with custom branding and
                        survey reasons.
                      </>
                    ) : (
                      <>
                        Build beautiful cancel pages with custom branding,
                        messages and offers. Deliver a totally tailored
                        experience for different customer groups likes
                        cancellations, downgrades and trials.
                      </>
                    )}
                  </div>

                  <div className="tw-flex tw-justify-between tw-my-4">
                    <div>
                      <Link
                        to={{
                          pathname: createExperienceUrl,
                        }}
                      >
                        <ButtonV2 primary>Create cancel page</ButtonV2>
                      </Link>
                    </div>
                    <div className="tw-w-1/2 tw-text-xs tw-text-cbrneutral-500 tw-flex">
                      <div className="tw-font-bold tw-mr-1">Tip:</div>
                      <div>
                        We recommend having a brand manager or your company’s
                        logo and brand colors on hand to brand your experience.
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </GetStartedRowDesc>
        </GetStartedRow>
      </div>
      <div>
        <GetStartedRow
          step={3}
          complete={
            isStarterPlan
              ? isConfigureStarterAccountCompleted
              : isConfigureAccountCompleted
          }
        >
          <GetStartedRowTitle
            title="Configure your account"
            time="60 minutes"
            open={expanded.account}
            onClick={() =>
              setExpanded({ ...expanded, account: !expanded.account })
            }
          />
          <GetStartedRowDesc
            open={expanded.account}
            wrapperClassName="tw-text-sm tw-bg-transparent tw-shadow-none"
            className="tw-px-0"
          >
            <div className="tw-flex tw-mb-6">
              <div className="tw-basis-1/4">
                <div className="tw-font-bold tw-text-cbrneutral-700 tw-mb-1">
                  Implementation
                </div>
                <div className="tw-text-cbrneutral-500">
                  Direct your users to Retention.
                </div>
              </div>
              <div className="tw-basis-3/4 tw-ml-4">
                <CollapseFormContainerControlled
                  classes="CFC-default-theme CFC-onboarding-theme"
                  open={Boolean(expanded['account.installation'])}
                  onToggle={() =>
                    setExpanded({
                      ...expanded,
                      'account.installation': !expanded['account.installation'],
                    })
                  }
                  title="Technical installation"
                  subtitle={
                    <SectionCollapsableSubtitle
                      text="30-40 min"
                      onClick={completeStep(
                        ONBOARDING_HISTORY_STEP.TECHNICAL_INSTALLATION
                      )}
                      complete={
                        steps[ONBOARDING_HISTORY_STEP.TECHNICAL_INSTALLATION]
                      }
                    />
                  }
                >
                  <div className="tw-ml-2.5 tw-px-8">
                    <div className="tw-mb-6">
                      Choose from one of the following options to direct your
                      users to Chargebee Retention. You may need a developer to
                      help with API and JS integration.{' '}
                      <Link
                        to={{
                          pathname: createUrl(AuthorizedPaths.settingsUsers),
                        }}
                        target="_blank"
                        className="tw-whitespace-nowrap"
                      >
                        <span className="tw-underline">Invite users</span>{' '}
                        {externalLinkIcon}
                      </Link>
                    </div>

                    {billingIntegration?.data_type ===
                      IntegrationDataType.CHARGEBEE && (
                      <a
                        href={`https://${chargebeeSiteName}.chargebee.com/checkout_and_portal_settings/configuration`}
                        target="_blank"
                        className="tw-block tw-text-sm tw-font-bold tw-text-cblogo tw-mb-2"
                        rel="noreferrer"
                      >
                        {CBRIcons.cbrLogo(24, 24)}
                        <span className="tw-ml-2">
                          Self Serve Portal Integration
                        </span>
                        {CBRIcons.cbrArrowRight()}
                      </a>
                    )}
                    {billingIntegration?.data_type ===
                    IntegrationDataType.CHARGEBEE ? (
                      <Link
                        to={{
                          pathname:
                            'https://www.chargebee.com/docs/retention/how-to-setup-chargebee-retention-for-the-chargebee-retention-api.html',
                        }}
                        target="_blank"
                        className="tw-block tw-text-sm tw-font-bold tw-text-cbrprimary-600 tw-mb-2"
                      >
                        Chargebee API Integration
                        {CBRIcons.cbrArrowRight()}
                      </Link>
                    ) : (
                      <Link
                        to={{
                          pathname: createUrl(AuthorizedPaths.settingsInstall),
                        }}
                        className="tw-block tw-text-sm tw-font-bold tw-text-cbrprimary-600 tw-mb-2"
                      >
                        API Integration
                        {CBRIcons.cbrArrowRight()}
                      </Link>
                    )}
                    <Link
                      to={{
                        pathname: createUrl(AuthorizedPaths.settingsInstall),
                      }}
                      className="tw-block tw-text-sm tw-font-bold tw-text-cbrprimary-600 tw-mb-2"
                    >
                      Embed Chargebee Retention.js
                      {CBRIcons.cbrArrowRight()}
                    </Link>
                  </div>
                </CollapseFormContainerControlled>
              </div>
            </div>
            <div className="tw-flex tw-mb-6">
              <div className="tw-basis-1/4">
                <div className="tw-font-bold tw-text-cbrneutral-700 tw-mb-1">
                  Integrations
                </div>
                <div className="tw-text-cbrneutral-500">
                  Connect your billing system and connect to Slack.
                </div>
              </div>
              <div className="tw-basis-3/4 tw-ml-4">
                <CollapseFormContainerControlled
                  classes="CFC-default-theme CFC-onboarding-theme"
                  open={Boolean(expanded['account.billing'])}
                  onToggle={() =>
                    setExpanded({
                      ...expanded,
                      'account.billing': !expanded['account.billing'],
                    })
                  }
                  subtitle={
                    <SectionCollapsableSubtitle
                      text="5 min"
                      onClick={completeStep(
                        ONBOARDING_HISTORY_STEP.INTEGRATE_BILLING_SYSTEM
                      )}
                      complete={
                        steps[ONBOARDING_HISTORY_STEP.INTEGRATE_BILLING_SYSTEM]
                      }
                    />
                  }
                  title="Billing integration"
                >
                  <div className="tw-ml-2.5 tw-px-8">
                    <GetStartedBilling />
                    {billingIntegration && <BillingSettings />}
                  </div>
                </CollapseFormContainerControlled>
                <CollapseFormContainerControlled
                  classes="CFC-default-theme CFC-onboarding-theme -tw-mt-[9px]"
                  open={Boolean(expanded['account.alerts'])}
                  onToggle={() =>
                    setExpanded({
                      ...expanded,
                      'account.alerts': !expanded['account.alerts'],
                    })
                  }
                  title="Alerts"
                  subtitle={
                    <SectionCollapsableSubtitle
                      text="2 min"
                      onClick={completeStep(ONBOARDING_HISTORY_STEP.ALERTS)}
                      complete={steps[ONBOARDING_HISTORY_STEP.ALERTS]}
                    />
                  }
                >
                  <div className="tw-ml-2.5 tw-px-8">
                    <GetStartedAlerts />
                  </div>
                </CollapseFormContainerControlled>
              </div>
            </div>
            <div className="tw-flex tw-mb-6">
              <div className="tw-basis-1/4">
                <div className="tw-font-bold tw-text-cbrneutral-700 tw-mb-1">
                  Field setup
                </div>
                <div className="tw-text-cbrneutral-500">
                  Map your fields and choose your reporting revenue field.
                </div>
              </div>
              <div className="tw-basis-3/4 tw-ml-4">
                <CollapseFormContainerControlled
                  classes="CFC-default-theme CFC-onboarding-theme"
                  open={Boolean(expanded['account.mappings'])}
                  onToggle={() =>
                    setExpanded({
                      ...expanded,
                      'account.mappings': !expanded['account.mappings'],
                    })
                  }
                  subtitle={
                    <SectionCollapsableSubtitle
                      text="5 min"
                      onClick={completeStep(
                        ONBOARDING_HISTORY_STEP.FIELD_MAPPINGS
                      )}
                      complete={steps[ONBOARDING_HISTORY_STEP.FIELD_MAPPINGS]}
                    />
                  }
                  title="Field mappings"
                >
                  <div className="tw-ml-2.5 tw-px-8">
                    Field mapping allows you to map fields from your enrichment
                    sources (billing, js snippet, api fields, sfdc) to be used
                    in Chargebee Retention experiences, audiences or data
                    analysis.
                    <Link
                      to={{
                        pathname: createUrl(
                          AuthorizedPaths.settingsFieldMappings
                        ),
                      }}
                      className="tw-block tw-text-sm tw-font-bold tw-text-cbrprimary-600 tw-my-2"
                    >
                      Map fields
                      {CBRIcons.cbrArrowRight()}
                    </Link>
                  </div>
                </CollapseFormContainerControlled>
                {!isStarterFreePlan && (
                  <CollapseFormContainerControlled
                    classes="CFC-default-theme CFC-onboarding-theme -tw-mt-[9px]"
                    open={Boolean(expanded['account.revenue'])}
                    onToggle={() =>
                      setExpanded({
                        ...expanded,
                        'account.revenue': !expanded['account.revenue'],
                      })
                    }
                    title="Revenue field for reporting"
                    subtitle={
                      <SectionCollapsableSubtitle
                        text="2 min"
                        onClick={completeStep(
                          ONBOARDING_HISTORY_STEP.REPORTING_REVENUE_FIELD
                        )}
                        complete={
                          steps[ONBOARDING_HISTORY_STEP.REPORTING_REVENUE_FIELD]
                        }
                      />
                    }
                  >
                    <div className="tw-ml-2.5 tw-px-8">
                      Select the field that will populate save and churn revenue
                      reporting throughout Retention (you can change this later
                      in settings).
                      <div className="tw-mt-4">
                        <RevenueConfiguration
                          closeRevenue={() => undefined}
                          setRevenueConnected={() => undefined}
                        />
                      </div>
                    </div>
                  </CollapseFormContainerControlled>
                )}
              </div>
            </div>
            <div className="tw-flex tw-mb-6">
              <div className="tw-basis-1/4">
                <div className="tw-font-bold tw-text-cbrneutral-700 tw-mb-1">
                  Post-cancel experience
                </div>
                <div className="tw-text-cbrneutral-500">
                  Create your custom domain and landing pages.
                </div>
              </div>
              <div className="tw-basis-3/4 tw-ml-4">
                <CollapseFormContainerControlled
                  classes="CFC-default-theme CFC-onboarding-theme"
                  open={Boolean(expanded['account.domain'])}
                  onToggle={() =>
                    setExpanded({
                      ...expanded,
                      'account.domain': !expanded['account.domain'],
                    })
                  }
                  subtitle={
                    <SectionCollapsableSubtitle
                      text="2 min"
                      onClick={completeStep(ONBOARDING_HISTORY_STEP.DOMAIN)}
                      complete={steps[ONBOARDING_HISTORY_STEP.DOMAIN]}
                    />
                  }
                  title="Domain"
                >
                  <div className="tw-ml-2.5 tw-px-8">
                    Configure a custom domain name you would like to use with
                    your Chargebee Retention site. Your domain URL will default
                    to
                    <span className="tw-font-bold tw-mx-1">
                      {baseUriDomain}
                    </span>
                    while your new custom domain is being verified.
                    <a
                      target="_blank"
                      className="tw-underline tw-ml-1 tw-whitespace-nowrap"
                      href={vanityDomainDocsUrl}
                      rel="noreferrer"
                    >
                      Learn more {externalLinkIcon}
                    </a>
                    <Link
                      to={{
                        pathname: createUrl(
                          AuthorizedPaths.settingsCustomDomain
                        ),
                      }}
                      className="tw-block tw-text-sm tw-font-bold tw-text-cbrprimary-600 tw-my-2"
                    >
                      Configure domain
                      {CBRIcons.cbrArrowRight()}
                    </Link>
                  </div>
                </CollapseFormContainerControlled>
                <CollapseFormContainerControlled
                  classes="CFC-default-theme CFC-onboarding-theme -tw-mt-[9px]"
                  open={Boolean(expanded['account.urls'])}
                  onToggle={() =>
                    setExpanded({
                      ...expanded,
                      'account.urls': !expanded['account.urls'],
                    })
                  }
                  title="Redirect URL & pages"
                  subtitle={
                    <SectionCollapsableSubtitle
                      text="5 min"
                      onClick={completeStep(
                        ONBOARDING_HISTORY_STEP.URL_AND_PAGES
                      )}
                      complete={steps[ONBOARDING_HISTORY_STEP.URL_AND_PAGES]}
                    />
                  }
                >
                  <div className="tw-ml-2.5 tw-px-8">
                    Select where your users will go after they click cancel or
                    nevermind.
                    <UrlsConfig />
                    <Link
                      to={{
                        pathname: createUrl(AuthorizedPaths.confirmationPages),
                      }}
                      className="tw-block tw-text-sm tw-font-bold tw-text-cbrprimary-600 tw-mb-2 tw-mt-4"
                    >
                      Configure redirect pages
                      {CBRIcons.cbrArrowRight()}
                    </Link>
                  </div>
                </CollapseFormContainerControlled>
              </div>
            </div>
          </GetStartedRowDesc>
        </GetStartedRow>
      </div>
      <div>
        <GetStartedRow step={4} complete={isTestAndLaunchCompleted}>
          <GetStartedRowTitle
            title="Launch"
            time="5 minutes"
            open={expanded.launch}
            onClick={() =>
              setExpanded({ ...expanded, launch: !expanded.launch })
            }
          />
          <GetStartedRowDesc
            open={expanded.launch}
            wrapperClassName="tw-w-1/2 tw-mt-6 tw-text-sm tw-bg-transparent"
            className="tw-px-0 tw-border-cbrneutral-100 tw-border tw-rounded"
          >
            <div className="tw-flex tw-px-6">
              <div className="tw-pr-2">{CBRIcons.cbrRocketLaunch()}</div>
              <div>
                <div className="tw-font-bold tw-mb-1">
                  Launch your cancel experience
                </div>
                <div className="tw-text-sm tw-pb-6 tw-text-cbrneutral-700">
                  You will be redirected to the dashboard to view your cancel
                  experience data.
                </div>
                <ButtonV2
                  primary
                  onClick={() => handleGoLive()}
                  disabled={isGoLiveLoading}
                >
                  {isGoLiveLoading && Icons.cbrLoader} Launch experience
                </ButtonV2>
              </div>
            </div>
          </GetStartedRowDesc>
        </GetStartedRow>
      </div>
    </div>
  );
};

export default GetStartedPage;
