/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import cs from 'classnames';
import _ from 'lodash';
import RemainingCounter from '@bbkAdminComponents/inputs/remaining-counter/remaining-counter';
import Fade from '@bbkUtils/fade';
import '../simple-text/simple-text-input.scss';
import './text-input.scss';

type Props = React.DetailedHTMLProps<
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue'>,
  HTMLInputElement
> & {
  value?: string | number;
  defaultValue?: string | number;
  innerRef?: React.Ref<HTMLInputElement>;
  classes?: string;
  enableLengthWarnings?: boolean;
  darkTheme?: boolean;
  bold?: boolean;
  short?: boolean;
  shorter?: boolean;
  tiny?: boolean;
  xsmall?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  tall?: boolean;
  center?: boolean;
};

const TextInput: React.FC<Props> = (props) => {
  const {
    innerRef,
    classes,
    value,
    defaultValue = '',
    placeholder = 'Start typing...',
    maxLength = 0,
    darkTheme = false,
    bold = false,
    enableLengthWarnings,
    short,
    shorter,
    tiny,
    xsmall,
    small,
    medium,
    large,
    tall,
    center,
    ...other
  } = props;

  const determineRemainingLength = (): { remaining: number | undefined } => {
    const internalValue = value || defaultValue;
    let useValue = value;
    const returnStatus = {
      remaining: maxLength,
    };
    if (_.isNil(maxLength)) {
      return returnStatus;
    }
    if (_.isNil(value) && _.isNil(defaultValue)) {
      return returnStatus;
    }
    if (_.isNil(value) && !_.isNil(defaultValue)) {
      useValue = internalValue;
    }
    if (useValue === '') {
      return returnStatus;
    }
    const remainingCharacters = maxLength - (`${useValue}`?.length ?? 0);
    returnStatus.remaining = remainingCharacters;
    return returnStatus;
  };

  const showLengthWarning = () => {
    return maxLength > 0 && enableLengthWarnings !== false;
  };

  let additionalProps: Record<string, any> = {
    value,
  };
  if (!_.isNil(defaultValue) && _.isNil(value)) {
    additionalProps = {
      defaultValue,
    };
  }
  const remaining = determineRemainingLength();
  return (
    <div className={cs('bbk-editor-text-input-container', classes)}>
      <input
        className={cs('bbk-editor-text-input bbk-simple-text-input', classes, {
          'enable-length-warning': showLengthWarning(),
          'bbk-eti-dark-theme': darkTheme,
          bold,
          short,
          shorter,
          small,
          xsmall,
          tiny,
          medium,
          large,
          tall,
          center,
        })}
        placeholder={placeholder}
        // maxLength={maxLength} // allow for overflow, use the warning to show a problem
        autoComplete="off"
        ref={innerRef}
        {...additionalProps}
        {...other}
      />
      {showLengthWarning() && (
        <Fade in={showLengthWarning()} duration={150}>
          <RemainingCounter remaining={remaining.remaining} />
        </Fade>
      )}
    </div>
  );
};

export default React.forwardRef<HTMLInputElement, Props>((props, ref) => (
  <TextInput {...props} innerRef={ref} />
));
