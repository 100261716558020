/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import { isNil } from 'lodash';
import cs from 'classnames';
import icons from '@bbkAdminUtils/icons';

type Props = {
  classes: string;
  icon?: keyof typeof icons;
  title?: string;
  text?: React.ReactNode;
  onClick: React.MouseEventHandler;
  iconNode?: React.ReactNode;
  content?: React.ReactNode;
  disabled?: boolean;
};
export const NavItem = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      classes,
      icon,
      text,
      title,
      onClick = () => undefined,
      iconNode,
      content,
      disabled,
    },
    ref
  ) => (
    <div
      ref={ref}
      className={cs(classes, {
        'disabled !tw-cursor-not-allowed': disabled,
      })}
      title={title}
      onClick={disabled ? undefined : onClick}
    >
      {!isNil(icon) && <span className="nav-icon">{icons[icon]}</span>}
      {!isNil(iconNode) && <span className="nav-icon">{iconNode}</span>}
      {text}
      {!isNil(content) && <div className="nav-content">{content}</div>}
    </div>
  )
);
