import React, { useMemo } from 'react';
import cs, { Argument } from 'classnames';
import { getRandomInt } from '@bbkAdminComponents/experiences/offers/utils';
import CustomerHtml from '@bbkFunnelComponents/customer_html/customer_html';

import './_checkbox.scss';

export const checkmark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="11"
    viewBox="0 0 14 11"
  >
    <path
      fill="#FFF"
      d="M9.06764833,13.1413828 L5.89389204,9.98890436 C5.78562148,9.8806338 5.60955304,9.88282178 5.50345786,9.98890436 L4.08120292,11.4199784 C3.97293236,11.528249 3.97293236,11.7043174 4.08120292,11.8125964 C5.68179967,13.4026942 7.2805766,14.992932 8.88117335,16.58079 C8.98944391,16.6890606 9.16551235,16.6890606 9.27379131,16.58079 L17.9207094,7.89397603 C18.026792,7.78570547 18.026792,7.60963703 17.9185221,7.50135807 L16.4874481,6.08120292 C16.3791775,5.97293236 16.2031091,5.97293236 16.0948301,6.08120292 L9.06824677,13.1422227 L9.06764833,13.1413828 Z"
      transform="translate(-4 -6)"
    />
  </svg>
);

export const checkmarkCurvy = (
  <svg
    width="13"
    height="11"
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2762 1.14424L10.276 1.14454L5.73499 8.00433L2.55422 5.04268L2.55334 5.04188C2.16223 4.67944 1.57984 4.76044 1.2792 5.17664L1.27919 5.17664L1.27748 5.17904C1.00764 5.55727 1.04429 6.10937 1.39696 6.43807L1.39716 6.43825L5.31189 10.0829L5.31319 10.0841C5.4667 10.226 5.67072 10.3138 5.89079 10.3138C5.92486 10.3138 5.9588 10.3118 5.99249 10.308L5.99971 10.3071L6.00691 10.3061C6.25987 10.2698 6.47326 10.1229 6.60747 9.9214L6.60827 9.92018L11.7102 2.21292L11.7108 2.21207C11.9654 1.8257 11.913 1.27528 11.546 0.959348C11.136 0.602829 10.555 0.723778 10.2762 1.14424Z"
      fill="white"
      stroke="white"
    />
  </svg>
);

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
export type Props = Pick<InputProps, 'name' | 'checked' | 'disabled'> & {
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  label?: React.ReactNode;
  classes?: Argument;
  labelClass?: Argument;
};
const CheckBox: React.FC<Props> = (props) => {
  const { label, classes, labelClass, handleChange, ...inputProps } = props;
  const id = useMemo(() => `${getRandomInt()}`, []);

  return (
    <div className="bbk-checkbox-wrapper">
      <label
        className={cs('bbk-checkbox-label', classes, {
          disabled: inputProps.disabled,
        })}
        htmlFor={id}
      >
        <input
          type="checkbox"
          className="bbk-checkbox-input"
          id={id}
          onChange={handleChange}
          {...inputProps}
        />
        <div className="bbk-checkbox-indicator">{checkmark}</div>
        <span className={cs('bbk-checkbox-label-label', labelClass)}>
          <CustomerHtml value={label} />
        </span>
      </label>
    </div>
  );
};

export default CheckBox;
