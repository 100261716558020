/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import '@bbkAdminStylesheets/modal.scss';
import cs from 'classnames';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';

type Props = {
  show: boolean;
  clicked: () => void;
  invisible?: boolean;
};
const Backdrop: ReactFCC<Props> = ({ show, clicked, invisible }) =>
  show ? (
    <div
      role="button"
      tabIndex={0}
      className={cs('backdrop', { invisible })}
      onClick={clicked}
      onKeyPress={clicked}
    />
  ) : null;

export default Backdrop;
