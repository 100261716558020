/* eslint-disable no-unused-expressions,react/jsx-props-no-spreading */
import React, { InputHTMLAttributes, useState } from 'react';
import classNames, { Argument } from 'classnames';
import icons from '@bbkAdminUtils/icons';
import '../simple-text/simple-text-input.scss';
import './search-text-input.scss';

type Props = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  | 'id'
  | 'name'
  | 'value'
  | 'placeholder'
  | 'maxLength'
  | 'type'
  | 'onFocus'
  | 'onKeyUp'
  | 'onBlur'
> & {
  classes?: Argument;
  darkTheme?: boolean;
  camoTheme?: boolean;

  onChange: (e: { target: { value: string } }) => void;
  innerRef?: React.ForwardedRef<HTMLInputElement> | null;
  activateClearButton?: boolean;
  showSearchButton?: boolean;
  searchIcon?: boolean;
  onSearch?: () => void;
};

const SearchTextInput: React.FC<Props> = (props) => {
  const {
    type = 'text',
    id,
    name,
    value,
    placeholder = 'search...',
    classes,
    innerRef,
    maxLength,
    onChange,
    onFocus,
    onKeyUp,
    onBlur,
    camoTheme,
    darkTheme = true,
    activateClearButton = true,
    searchIcon = true,
    showSearchButton = false,
    onSearch,
  } = props;
  const [isClear, setIsClear] = useState(false);

  const resetVisibility = (val: string) => {
    setIsClear(val.length > 0);
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    resetVisibility(e.target.value);
    onChange?.(e);
  };

  const handleClearInput = () => {
    resetVisibility('');
    onChange?.({ target: { value: '' } });
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.keyCode === 27) handleClearInput();
    onKeyUp?.(e);
  };

  return (
    <div className={classNames('bbk-search-text-input-container', classes)}>
      {searchIcon && (
        <span className="bbk-search-text-input-search">{icons.search}</span>
      )}
      <input
        type={type}
        name={name}
        id={id}
        value={value}
        ref={innerRef}
        className={classNames(
          'bbk-search-text-input bbk-simple-text-input',
          classes,
          {
            dark: darkTheme,
            camo: camoTheme,
          }
        )}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={handleInputChange}
        onKeyUp={handleKeyPress}
        maxLength={maxLength}
        autoComplete="off"
      />
      {activateClearButton && (
        <button
          type="button"
          className={classNames('bbk-search-text-input-reset', {
            'reset-text-input-active': isClear,
          })}
          disabled={!isClear}
          onClick={handleClearInput}
        >
          {icons.clear}
        </button>
      )}
      {showSearchButton && (
        <button
          type="button"
          className={classNames('bbk-search-text-input-reset', {
            'reset-text-input-active': isClear,
          })}
          disabled={!isClear}
          onClick={onSearch}
          css={{ color: 'rgba(0,0,0,0.4)' }}
        >
          <span css={{ color: 'rgba(0,0,0,0.4)' }}>{icons.search}</span>
        </button>
      )}
    </div>
  );
};

export default React.forwardRef<HTMLInputElement, Props>((props, ref) => (
  <SearchTextInput innerRef={ref} {...props} />
));
