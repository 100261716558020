import React from 'react';
import tailwindColors from '@bbkRoot/tailwind.colors';

export const HoverIcons = {
  info: (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6"
        y="3"
        width="6"
        height="11"
        fill={tailwindColors.cbrprimary['600']}
      />
      <path
        d="M9 0C4.02966 0 0 4.02966 0 9C0 13.9703 4.02966 18 9 18C13.9703 18 18 13.9703 18 9C18 4.02966 13.9703 0 9 0ZM7.96009 12.8848V8.76438C7.96009 8.19063 8.42555 7.72447 9 7.72447C9.57445 7.72447 10.0399 8.19063 10.0399 8.76438V12.8848C10.0399 13.4592 9.57445 13.9247 9 13.9247C8.42555 13.9247 7.96009 13.4592 7.96009 12.8848ZM9 6.58962C8.49445 6.58962 8.03884 6.28517 7.84546 5.81828C7.6514 5.3514 7.75827 4.81352 8.11616 4.45633C8.47336 4.09844 9.01125 3.99157 9.47812 4.18493C9.9457 4.37828 10.2502 4.83462 10.2502 5.34017C10.2502 5.67133 10.118 5.98916 9.88382 6.224C9.64967 6.45815 9.33116 6.58962 8.99998 6.58962H9Z"
        fill={tailwindColors.cbrprimary['50']}
      />
    </svg>
  ),
  question: (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5.92542"
        y="1"
        width="8"
        height="14"
        fill={tailwindColors.cbrprimary['600']}
      />
      <path
        d="M3.53698 2.65158C5.30471 0.883851 7.4343 0 9.92542 0C12.4165 0 14.5399 0.876479 16.2939 2.63154C18.0489 4.38556 18.9254 6.50862 18.9254 9C18.9254 11.4914 18.0489 13.6145 16.2939 15.3685C14.5399 17.1235 12.4168 18 9.92542 18C7.43403 18 5.31094 17.1235 3.55696 15.3685C1.80189 13.6144 0.925415 11.4914 0.925415 9C0.925415 6.50862 1.79558 4.39309 3.53692 2.65153L3.53698 2.65158ZM10.7292 13.1388C10.7556 12.7907 10.6554 12.4954 10.4276 12.2549C10.1998 12.0134 9.91181 11.8932 9.56376 11.8932C9.24207 11.8932 8.96151 11.9997 8.71998 12.2148C8.47951 12.429 8.35822 12.7095 8.35822 13.0586C8.35822 13.4067 8.46581 13.6946 8.67991 13.9224C8.89402 14.1503 9.16193 14.2631 9.48362 14.2631C9.83169 14.2631 10.126 14.1629 10.3675 13.9625C10.609 13.7611 10.7292 13.4868 10.7292 13.1388ZM12.6984 7.99589C13.0191 7.54024 13.1804 7.05822 13.1804 6.5488C13.1804 5.66494 12.845 4.9688 12.1753 4.45938C11.5329 3.97736 10.7029 3.73688 9.684 3.73688C8.9341 3.73688 8.30441 3.91091 7.79604 4.25898C7.04614 4.76735 6.67063 5.67863 6.67063 6.99067H8.63982C8.63982 6.42851 8.69361 6.06672 8.80014 5.90641C9.04167 5.50456 9.38973 5.3031 9.84537 5.3031C10.301 5.3031 10.6354 5.45077 10.8495 5.74503C11.0636 6.04037 11.1712 6.34834 11.1712 6.66898C11.1712 6.88414 11.0636 7.15205 10.8495 7.47269C10.7957 7.58027 10.6617 7.71422 10.4476 7.87454C9.75152 8.2764 9.28216 8.67826 9.04169 9.08008C8.82758 9.42815 8.69362 9.97765 8.63983 10.7276C8.63983 10.8352 8.69362 10.8879 8.80015 10.8879H10.3675C10.5014 10.8879 10.5689 10.8352 10.5689 10.7276C10.5689 10.4333 10.5953 10.1781 10.6491 9.96396C10.7292 9.72349 10.8896 9.49566 11.1311 9.28157L11.6933 8.91981C12.2027 8.54433 12.5371 8.23636 12.6984 7.99586L12.6984 7.99589Z"
        fill={tailwindColors.cbrprimary['50']}
      />
    </svg>
  ),
  tripleDots: (
    <svg
      width="4"
      height="12"
      viewBox="0 0 4 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.31195 1.65923C3.31195 0.745059 2.56905 0 1.65597 0C0.747109 0 4.0031e-08 0.74343 0 1.65923C-3.98436e-08 2.57074 0.746077 3.31846 1.65597 3.31846C2.57067 3.31846 3.31195 2.56921 3.31195 1.65923ZM3.31195 5.99999C3.31195 5.08582 2.56905 4.34076 1.65597 4.34076C0.747109 4.34076 4.0031e-08 5.08419 0 5.99999C-3.98436e-08 6.9115 0.746077 7.65922 1.65597 7.65922C2.57067 7.65922 3.31195 6.90997 3.31195 5.99999ZM1.65597 8.68155C2.57065 8.68155 3.31195 9.43086 3.31195 10.3408C3.31195 11.255 2.56909 12 1.65597 12C0.747136 12 -4.00291e-08 11.2565 0 10.3408C3.98227e-08 9.42974 0.745521 8.68155 1.65597 8.68155Z"
        fill={tailwindColors.cbrprimary['600']}
      />
    </svg>
  ),
};
