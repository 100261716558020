import type { DefaultRootState } from 'react-redux';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { defaults } from 'lodash';
import type {
  AppKey,
  CompanyKey,
} from '@bbkAdminRedux/rtkq/current-user.slice';
import {
  currentUserSlice,
  useSelectCurrentApp,
} from '@bbkAdminRedux/rtkq/current-user.slice';
import { AppBusinessModel } from '@bbkAdminRedux/rtkq/reports/benchmark.selectors';
import type { ReportsFilterState } from '@bbkAdminRedux/rtkq/app-persist/reports-filter';
import { FeatureFlagsE, useFeatureFlags } from '@bbkAdminUtils/feature-flags';
import { OfferCategory } from '@bbkAdminUtils/api-client/rest-offers-api';
import type { RuleWithTree } from '@bbkAdminComponents/experiences/audiences/edit-rule/edit-rule-context';
import type { Play, PlayEdit } from '@bbkAdminRedux/rtkq/play.slice';

const REDUCER_PREFIX = 'appPersist-rtk';

export type PersistSliceSuffix = `#demo` | '';

type GlobalPrefix = `Global#`;
type GlobalSpace = `${GlobalPrefix}${string}${PersistSliceSuffix}`; // 'Global#'

type AppPrefix = `App#`;
export type AppSpace = `${AppPrefix}${AppKey}${PersistSliceSuffix}`; // 'App#YxAoqEoOVj'

type CompanyPrefix = `Company#`;
type CompanySpace = `${CompanyPrefix}${CompanyKey}${PersistSliceSuffix}`; // 'Company#alex-demo'

export const buildGlobalPrefix = (
  suffix: PersistSliceSuffix = ''
): GlobalSpace => `Global#global${suffix}`;
export const buildAppPrefix = (
  appKey: AppKey,
  suffix: PersistSliceSuffix = ''
): AppSpace => `App#${appKey}${suffix}`;
export const buildCompanyPrefix = (
  companyInternalName: CompanyKey,
  suffix: PersistSliceSuffix = ''
): CompanySpace => `Company#${companyInternalName}${suffix}`;

type OfferPerfReportNavState = Partial<{
  groupBy: string[];
  visibleColumns: string[];
}>;
type ExpPerfReportNavState = Partial<{
  groupBy: string[];
  visibleColumns: string[];
}>;
type GetStartedState = Partial<{
  selectedOfferCategories: OfferCategory[];
  previewCategory: OfferCategory;
}>;

type SelectedBrandState = Partial<{
  id: string;
}>;

const defaultOfferPerfReportNavState: OfferPerfReportNavState = {};
const defaultExpPerfReportNavState: ExpPerfReportNavState = {};
const defaultGetStartedState: GetStartedState = {
  selectedOfferCategories: [OfferCategory.discount, OfferCategory.pause],
  previewCategory: OfferCategory.discount,
};

export type AppMiscState = {
  playState?: {
    play: PlayEdit | undefined;
    rule?: RuleWithTree;
    addNewOffer?: {
      path: string | Array<string | number>;
      value: { target_key: string | undefined; type: string } | undefined;
    };
    subAudiencePath?: string | Array<string | number>;
  };
};

export type CompanyMiscState = {
  showWelcomePopup?: boolean;
  offerAI?: {
    hideGenAiStep1?: boolean;
  };
};
const defaultCompanyMiscState: CompanyMiscState = {
  showWelcomePopup: true,
};

const defaultAppMiscState: AppMiscState = {};

export enum GroupByOpts {
  category = 'category',
  publish_state = 'publish_state',
  usage = 'usage',
  objective = 'objective',
  presentation_mode = 'presentation',
}

export enum ViewTypeOpts {
  grid = 'grid',
  list = 'list',
}

export enum OfferPlacementColumn {
  experience = 'experience',
  placement = 'placement',
}

export enum OfferDateColumn {
  updatedAt = 'updatedAt',
  createdAt = 'createdAt',
}

export type OffersLibNavState = {
  viewType: ViewTypeOpts;
  groupByType: GroupByOpts;
  placementColumn: OfferPlacementColumn;
  dateColumn: OfferDateColumn;
  orderType: {
    fieldName: string;
    dir: 'asc' | 'desc';
  };
  recommendedOffersType: AppBusinessModel;
};

const defaultOffersLibNavState: OffersLibNavState = {
  groupByType: GroupByOpts.category,
  viewType: ViewTypeOpts.grid,
  orderType: {
    fieldName: 'display_name',
    dir: 'asc',
  },
  placementColumn: OfferPlacementColumn.experience,
  dateColumn: OfferDateColumn.updatedAt,
  recommendedOffersType: AppBusinessModel['d2c digital'],
};

type LastActiveState = Partial<{
  companyKey: string;
  appKey: string;
  email: string;
}>;

type AppPersistState = Partial<{
  [space: CompanySpace]: {
    companyMisc?: CompanyMiscState;
  };
  [space: AppSpace]: {
    offerPerfReportNav?: OfferPerfReportNavState;
    expPerfReportNav?: ExpPerfReportNavState;
    reportsFilter?: ReportsFilterState;
    getStartedState?: GetStartedState;
    selectedBrand?: SelectedBrandState;
    appMisc?: AppMiscState;
  };
  [space: GlobalSpace]: {
    appMisc?: AppMiscState;
    offersLibNav?: Partial<OffersLibNavState>;
    lastActive?: LastActiveState;
  };
}>;

const initialState: AppPersistState = {};

export const appPersistSlice = createSlice({
  name: REDUCER_PREFIX,
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      currentUserSlice.endpoints.getCurrentUser.matchFulfilled,
      (draftState, action) => {
        const prefix = buildGlobalPrefix();

        draftState[prefix] = {
          ...draftState[prefix],
          lastActive: {
            ...draftState[prefix]?.lastActive,
            email: action.payload.email,
          },
        };
      }
    );
  },
  reducers: {
    saveOfferPerfReportNavState: (
      draftState,
      action: PayloadAction<
        { appKey: AppKey } & Partial<OfferPerfReportNavState>
      >
    ) => {
      const { appKey, ...partialNewState } = action.payload;
      const prefix = buildAppPrefix(appKey);
      draftState[prefix] = {
        ...draftState[prefix],
        offerPerfReportNav: {
          ...draftState[prefix]?.offerPerfReportNav,
          ...partialNewState,
        },
      };
    },
    saveExpPerfReportNavState: (
      draftState,
      action: PayloadAction<{ appKey: AppKey } & Partial<ExpPerfReportNavState>>
    ) => {
      const { appKey, ...partialNewState } = action.payload;
      const prefix = buildAppPrefix(appKey);
      draftState[prefix] = {
        ...draftState[prefix],
        expPerfReportNav: {
          ...draftState[prefix]?.expPerfReportNav,
          ...partialNewState,
        },
      };
    },
    saveSelectedBrand: (
      draftState,
      action: PayloadAction<{ appKey: AppKey } & Partial<SelectedBrandState>>
    ) => {
      const { appKey, ...selectedBrand } = action.payload;
      const prefix = buildAppPrefix(appKey);
      draftState[prefix] = {
        ...draftState[prefix],
        selectedBrand,
      };
    },
    saveCompanyMisc: (
      draftState,
      action: PayloadAction<
        { companyInternalName: CompanyKey } & Partial<CompanyMiscState>
      >
    ) => {
      const { companyInternalName, ...partialNewState } = action.payload;
      const prefix = buildCompanyPrefix(companyInternalName);
      draftState[prefix] = {
        ...draftState[prefix],
        companyMisc: {
          ...draftState[prefix]?.companyMisc,
          ...partialNewState,
        },
      };
    },
    saveAppMisc: (
      draftState,
      action: PayloadAction<{ appKey: AppKey } & Partial<AppMiscState>>
    ) => {
      const { appKey, ...partialNewState } = action.payload;
      const prefix = buildAppPrefix(appKey);
      draftState[prefix] = {
        ...draftState[prefix],
        appMisc: {
          ...draftState[prefix]?.appMisc,
          ...partialNewState,
        },
      };
    },
    saveOffersLibNav: (
      draftState,
      action: PayloadAction<Partial<OffersLibNavState>>
    ) => {
      const prefix = buildGlobalPrefix();
      draftState[prefix] = {
        ...draftState[prefix],
        offersLibNav: {
          ...draftState[prefix]?.offersLibNav,
          ...action.payload,
        },
      };
    },
    saveReportsFilter: (
      draftState,
      action: PayloadAction<
        { appKey: AppKey; suffix?: PersistSliceSuffix } & ReportsFilterState
      >
    ) => {
      const { appKey, suffix, ...partialNewState } = action.payload;
      const prefix = buildAppPrefix(appKey, suffix);
      draftState[prefix] = {
        ...draftState[prefix],
        reportsFilter: {
          ...draftState[prefix]?.reportsFilter,
          ...partialNewState,
        },
      };
    },
    saveGetStartedState: (
      draftState,
      action: PayloadAction<{ appKey: AppKey } & GetStartedState>
    ) => {
      const { appKey, ...partialNewState } = action.payload;
      const prefix = buildAppPrefix(appKey);
      draftState[prefix] = {
        ...draftState[prefix],
        getStartedState: {
          ...draftState[prefix]?.getStartedState,
          ...partialNewState,
        },
      };
    },
    saveLastActive: (draftState, action: PayloadAction<LastActiveState>) => {
      const prefix = buildGlobalPrefix();

      draftState[prefix] = {
        ...draftState[prefix],
        lastActive: {
          ...draftState[prefix]?.lastActive,
          ...action.payload,
        },
      };
    },
  },
});

export const selectOfferPerfReportNav = (appKey: AppKey) =>
  createSelector(
    (state: DefaultRootState) => {
      const prefix = buildAppPrefix(appKey);
      return state[appPersistSlice.name][prefix]?.offerPerfReportNav;
    },
    (subSliceState): OfferPerfReportNavState =>
      defaults({}, subSliceState, defaultOfferPerfReportNavState)
  );

export const selectExpPerfReportNav = (appKey: AppKey) =>
  createSelector(
    (state: DefaultRootState) => {
      const prefix = buildAppPrefix(appKey);
      return state[appPersistSlice.name][prefix]?.expPerfReportNav;
    },
    (subSliceState): ExpPerfReportNavState =>
      defaults({}, subSliceState, defaultExpPerfReportNavState)
  );

export const selectBrand = (appKey: AppKey) =>
  createSelector(
    (state: DefaultRootState) => {
      const prefix = buildAppPrefix(appKey);
      return state[appPersistSlice.name][prefix]?.selectedBrand;
    },
    (subSliceState): SelectedBrandState => defaults({}, subSliceState)
  );

export const selectAppMisc = (appKey: AppKey) =>
  createSelector(
    (state: DefaultRootState) => {
      const prefix = buildAppPrefix(appKey);
      return state[appPersistSlice.name][prefix]?.appMisc;
    },
    (subSliceState): AppMiscState => defaults({}, subSliceState)
  );

export const selectCompanyMisc = (companyInternalName: CompanyKey) =>
  createSelector(
    (state: DefaultRootState) => {
      const prefix = buildCompanyPrefix(companyInternalName);
      return state[appPersistSlice.name][prefix]?.companyMisc;
    },
    (subSliceState): CompanyMiscState =>
      defaults({}, subSliceState, defaultCompanyMiscState)
  );

export const selectOffersLibNav = createSelector(
  (state: DefaultRootState) => {
    const prefix = buildGlobalPrefix();
    return state[appPersistSlice.name][prefix]?.offersLibNav;
  },
  (subSliceState): OffersLibNavState =>
    defaults({}, subSliceState, defaultOffersLibNavState)
);

export const useGetDemoSuffix = (): PersistSliceSuffix => {
  const showDemoData = useFeatureFlags(FeatureFlagsE.ENABLE_DEMO_REPORTS);
  const app = useSelectCurrentApp();
  return app?.show_demo_data || showDemoData ? '#demo' : '';
};

export const selectGetStartedState = (appKey: AppKey) =>
  createSelector(
    (state: DefaultRootState) => {
      const prefix = buildAppPrefix(appKey);
      return state[appPersistSlice.name][prefix]?.getStartedState;
    },
    (subSliceState): GetStartedState =>
      defaults({}, subSliceState, defaultGetStartedState)
  );

export const selectLastActive = (state: DefaultRootState) => {
  const prefix = buildGlobalPrefix();
  return state[appPersistSlice.name][prefix]?.lastActive;
};
