import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { preauthBbkApiSlice } from '@bbkAdminRedux/rtkq/preauth.bbkApi.slice';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';

const versionSlice = preauthBbkApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getVersion: build.query<string, void>({
      query: () => ({
        url: '/version',
        params: { nonce: Date.now() },
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
});

export const usePollAndNotifyVersion = () => {
  const { data: currentVersion } = versionSlice.endpoints.getVersion.useQuery(
    undefined,
    { pollingInterval: 60000 }
  );

  const previousVersion = usePrevious(currentVersion);

  const [versionChangeAlertId, setVersionChangeAlertId] = useState<
    string | number
  >();

  const alertVersionChange = () => {
    if (versionChangeAlertId) return;
    setVersionChangeAlertId(
      BlackToastTrigger({
        content: 'Site updates available. Click to refresh page.',
        options: {
          autoClose: false,
          closeButton: true,
          closeOnClick: false,
          draggable: false,
          toastId: 'versionUpdate',
          onClick: () => window.location.reload(),
        },
      })
    );
  };

  useEffect(() => {
    if (!previousVersion) return;
    if (previousVersion === currentVersion) return;
    // trigger update only
    console.log('prev version:', previousVersion);
    console.log('updated version: ', currentVersion);
    alertVersionChange();
  }, [previousVersion, currentVersion]);
};
