import React from 'react';
import cs from 'classnames';

export const NavHeader = ({
  active,
  children,
}: {
  active?: boolean;
  children: React.ReactElement;
}) => {
  return (
    <li
      className={cs('bbk-nav-header tw-rounded', { 'tw-bg-white': active })}
      css={{
        borderRadius: '4px',
        margin: '5px 0 5px -10px',
        padding: '7px 10px',
        fontSize: '15px',
      }}
    >
      <span>{children}</span>
    </li>
  );
};
