import React, { useEffect } from 'react';
import BrightbackSession from '@bbkAdminUtils/brightback-session';
import SuspenseLoader from '@bbkAdminComponents/loaders/suspense-loader';

export const LogoutPage: React.FC = () => {
  useEffect(() => {
    BrightbackSession.clear();
    window.location.reload();
  }, []);

  return <SuspenseLoader />;
};
