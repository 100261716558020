import { IntegrationDataType } from '@bbkAdminRedux/data-management/reducers';
import { PublishState } from '@bbkAdminUtils/api-client/rest-offers-api';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';

export type StripeOfferConfig = {
  type: 'stripe';
  integration_data_type: IntegrationDataType.STRIPE;
  plan_change_proration: 'always_invoice' | 'create_prorations' | 'none';
};
export type RecurlyOfferConfig = {
  type: 'recurly';
  integration_data_type: IntegrationDataType.RECURLY;
  plan_change_timeframe: 'bill_date' | 'now' | 'renewal' | 'term_end';
};
export type ChargebeeOfferConfig = {
  type: 'chargebee';
  integration_data_type: IntegrationDataType.CHARGEBEE;
  plan_change_timeframe: 'immediate' | 'end_of_term';
  pause_option: 'IMMEDIATELY' | 'END_OF_TERM' | 'SPECIFIC_DATE';
  extend_term_end_on_pause: boolean;
  pause_orders_and_shipments: boolean;
};

export type RechargePlanChangeType =
  | 'PRODUCT_ONLY'
  | 'FREQUENCY_ONLY'
  | 'PRODUCT_AND_FREQUENCY';
export type RechargeOfferConfig = {
  type: 'recharge';
  integration_data_type: IntegrationDataType.RECHARGE;
  pause_offer_type: 'DELAY' | 'SKIP';
};

export type OfferConfig =
  | StripeOfferConfig
  | RechargeOfferConfig
  | RecurlyOfferConfig
  | ChargebeeOfferConfig;

export const offerConfigSlice = bbkApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOfferConfig: builder.query<
      OfferConfig,
      {
        app_key: string;
        offer_id?: string;
      }
    >({
      query: ({ app_key, offer_id }) => ({
        url: `/api/v1/apps/${app_key}/offer_config`,
        params: { offer_id },
      }),
      providesTags: ['OfferConfigs'],
    }),
    putOfferConfig: builder.mutation<
      OfferConfig,
      OfferConfig & {
        app_key: string;
        offer_id: string;
        publish_state: PublishState;
      }
    >({
      query: ({ app_key, offer_id, publish_state, ...args }) => ({
        url: `/api/v1/apps/${app_key}/offer_config?publish_state=${publish_state}&offer_id=${offer_id}`,
        method: 'PUT',
        body: { ...args },
      }),
      invalidatesTags: ['OfferConfigs'],
    }),
  }),
});
