import { recordError } from '@bbkAdminUtils/recordError';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const NotFoundPage = () => {
  const location = useLocation();

  useEffect(() => {
    recordError(`Could not find page' ${location.pathname}`);
  }, []);

  return <>Page is not found</>;
};
