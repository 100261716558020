import type React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { TypedUseQueryStateResult } from '@reduxjs/toolkit/query/react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import type { CSSObject } from '@emotion/styled';
import type {
  AppKey,
  CompanyKey,
  DateString,
} from '@bbkAdminRedux/rtkq/current-user.slice';
import {
  selectAppId,
  selectCompanyInternalName,
} from '@bbkAdminRedux/app/selectors';
import type { BBK_FETCH_BASE_QUERY } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import type {
  RestCard,
  RestExperience,
} from '@bbkAdminUtils/api-client/rest-experiences-api';
import type { RestModalForwards } from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import { DEFAULT_BRAND_ID } from '@bbkAdminRedux/rtkq/brands.slice';
import type { RestAbstractCard } from '@bbkAdminUtils/api-client/rest-cards-types';
import type { ExitSurveyIcons } from '@bbkAdminUtils/icons/funnel-icons';
import type { EligibleLALayouts } from './placements.utils';

type ExperienceValidationError = {
  detail: string;
  message: string;
  path: string;
  reference_error: boolean;
  type: string;
};

type ExperienceIntegrations = {
  email?: {
    default: string; // email
  };
};

type ExperienceCompany = RestExperience['company'];
type ExperienceStyleOverlay = RestBranding['style_overlay'];

export enum ExperienceCardTemplate {
  simple_header = 'simple_header',
  hosted_footer = 'hosted_footer',
  full_width_header = 'full_width_header',
  simple_plea = 'simple_plea',
  simple_plea_with_return = 'simple_plea_with_return',
  multi_row_survey = 'multi_row_survey',
  modal_confirm_cancel = 'modal_confirm_cancel',
}

export enum DestinationType {
  global = 'global',
  url = 'url',
  confirmation_page = 'confirmation_page',
}

export type ExperienceSimpleHeaderCard = RestAbstractCard & {
  template: ExperienceCardTemplate.simple_header;
  requires: {
    company: ExperienceCompany;
  };
  style_overlay: React.CSSProperties & {
    hrColor?: React.CSSProperties['color'];
    centerLogo?: boolean;
  };
};
export type ExperienceHostedFooterCard = RestAbstractCard & {
  template: ExperienceCardTemplate.hosted_footer;
  requires: {
    footer: {
      title: string;
    };
  };
};

export type ExperienceFullWidthHeaderCard = RestAbstractCard & {
  template: ExperienceCardTemplate.full_width_header;
  requires: {
    company: RestExperience['company'];
  };
  style_overlay: React.CSSProperties & {
    logo: CSSObject;
  };
};

export const isExperienceSimplePleaCard = (
  card: RestCard
): card is ExperienceSimplePleaCard =>
  card.template === ExperienceCardTemplate.simple_plea;

export type ExperienceSimplePleaCard = RestAbstractCard & {
  template: ExperienceCardTemplate.simple_plea;
  requires: {
    plea: {
      title: string;
      headline?: string;
    };
  };
  style_overlay: {
    pleaTitle?: React.CSSProperties;
  };
};

export type ExperienceSimplePleaWithReturnCard = RestAbstractCard & {
  template: ExperienceCardTemplate.simple_plea_with_return;
  requires: {
    plea: {
      title: string;
      headline: string;
      disabled?: boolean;
    };
    backwards: RestModalForwards;
  };
  style_overlay: {
    button: {
      icon: { name: keyof typeof ExitSurveyIcons };
      button_style: {
        backgroundColor?: React.CSSProperties['backgroundColor'];
        background_color?: React.CSSProperties['backgroundColor'];
      };
    };
    title: React.CSSProperties;
  };
  disabled?: boolean;
};

export enum ExperienceMultiSurveyCardSectionName {
  competition = 'competition',
  sentiment = 'sentiment',
  feedback = 'feedback',
  reason_for_leaving = 'reason_for_leaving',
  confirmation = 'confirmation',
}

type AbstractExperienceMultiSurveyCardSection = {
  order: number;
  disabled: boolean;
  hide_checkbox?: boolean;
};

export type ExperienceMultiSurveyCardCompetitionSection =
  AbstractExperienceMultiSurveyCardSection & {
    name: ExperienceMultiSurveyCardSectionName.competition;
    is_required: boolean;
    required_label?: string;
    freeform: boolean;
    randomize: boolean;
    label: string;
    otherTextPlaceholder: string;
    placeholder: string;
    options: string[];
    options_meta: { [key: string]: { display_name: string } };
  };
export type ExperienceMultiSurveyCardSentimentSection =
  AbstractExperienceMultiSurveyCardSection & {
    name: ExperienceMultiSurveyCardSectionName.sentiment;
    is_required: boolean;
    required_label?: string;
    label: string;
    high_text: string;
    low_text: string;
  };
export type ExperienceMultiSurveyCardFeedbackSection =
  AbstractExperienceMultiSurveyCardSection & {
    name: ExperienceMultiSurveyCardSectionName.feedback;
    is_required: boolean;
    required_label?: string;
    label: string;
    placeholder: string;
  };
export type ExperienceMultiSurveyCardReasonSection =
  AbstractExperienceMultiSurveyCardSection & {
    name: ExperienceMultiSurveyCardSectionName.reason_for_leaving;
    options: { value: string; label: string; action?: string; tier?: string }[];
    is_required: boolean;
    required_label?: string;
    label: string;
    randomize: boolean;
  };
export type ExperienceMultiSurveyCardConfirmationSection =
  AbstractExperienceMultiSurveyCardSection & {
    name: ExperienceMultiSurveyCardSectionName.confirmation;
    is_required: boolean;
    hide_checkbox?: boolean;
    required_label?: string;
    sticky_desktop?: boolean;
    sticky_mobile?: boolean;
    label: string;
  };
export type ExperienceMultiSurveyCardSection =
  | ExperienceMultiSurveyCardCompetitionSection
  | ExperienceMultiSurveyCardSentimentSection
  | ExperienceMultiSurveyCardFeedbackSection
  | ExperienceMultiSurveyCardReasonSection
  | ExperienceMultiSurveyCardConfirmationSection;

export const isMultiRowSurveyCard = (
  card: RestCard
): card is ExperienceMultiRowSurveyCard =>
  card.template === ExperienceCardTemplate.multi_row_survey;

export type ExperienceMultiRowSurveyCard = RestAbstractCard & {
  style_overlay: {
    buttonOrder?: 'reversed' | 'standard';
    button_order?: React.CSSProperties;
    buttonColors?: 'reversed';
  };

  template: ExperienceCardTemplate.multi_row_survey;
  provides: {
    reason_for_leaving: {
      name: string;
      value: string;
      tier: string;
      lives_on: string;
    }[];
    competition: string;
    competition_other?: string;
    sentiment: number;
    feedback: string;
    confirmation?: boolean;
    reason?: string;
    reason_other?: string;
    responses?: {
      [modalName: string]: string | undefined;
    };
  } & Record<string, unknown>;
  requires: {
    backwards: RestModalForwards;
    forwards: RestModalForwards;
    multi_row_survey: ExperienceMultiSurveyCardSection[];
    error_message?: {
      title?: 'There was an error.';
      content?: 'Please fill in all required fields.';
    };
    verificationMessage?: 'This question is required.';
  };
};

export type ExperienceConfirmCancelCard = RestAbstractCard & {
  template: ExperienceCardTemplate.modal_confirm_cancel;
  requires: {
    info: {
      title: string;
      terms: string;
      cta: string;
      phrase: string;
      placeholder: string;
    };
    forwards: RestModalForwards;
  };
};

export type ExperienceCard =
  | ExperienceSimpleHeaderCard
  | ExperienceFullWidthHeaderCard
  | ExperienceSimplePleaCard
  | ExperienceSimplePleaWithReturnCard
  | ExperienceMultiRowSurveyCard
  | ExperienceConfirmCancelCard
  | ExperienceHostedFooterCard;

type ExperienceLayout = {
  id: string; // uuid like
  style_overlay: ExperienceStyleOverlay;
  cards: ExperienceCard[];
};

export type AssembledExperience = {
  id: string; // uuid "135-7f3de81c-a738-4bd8-b74e-c90e61cd41fa"
  session: string; // string for evaluation in the runtime "${value('session_id')}"
  version: string; // sha-like "20c1b9d6"
  company: ExperienceCompany;
  integrations: ExperienceIntegrations;
  layout: ExperienceLayout;
  lossAversionLayout?: (typeof EligibleLALayouts)[number]['label'];
};

type ExperienceResponse = {
  draft_publish_date: DateString;
  draft_published: boolean;
  draft_version: `${number}`;
  validation_errors: ExperienceValidationError[];
  experience: AssembledExperience;
};

type GetExperienceArgs = {
  companyKey: CompanyKey;
  appKey: AppKey;
  funnelId: number | string;
  last_draft?: boolean;
};
type UpdatePropArgs = {
  funnelId: number | string;
  element: string;
  expectedLastDraftVersion: string;
  path: string;
};
type UpdateElementArgs = {
  funnelId: number | string;
  element: ExperienceCard;
  expectedLastDraftVersion: string;
};
type UpdateBrandingArgs = {
  appKey: string;
  brandId?: string;
  company: {
    logo?: string;
    name?: string;
    brandingLogoActivated?: boolean;
  };
  style_overlay: unknown;
};
type GetBrandingArgs = {
  brandId?: string;
  appKey: string;
};

export type RestBranding = {
  company: RestExperience['company'];
  style_overlay: RestExperience['layout']['style_overlay'];
};
type PublishExperienceArgs = {
  funnelId: string | number;
  expectedLastDraftVersion: string;
};
export const experienceSlice = bbkApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getExperience: build.query<ExperienceResponse, GetExperienceArgs>({
      query: ({ companyKey, appKey, funnelId, last_draft }) => ({
        url: `/api/company/${companyKey}/app/${appKey}/funnel/${funnelId}`,
        params: {
          last_draft,
        },
      }),
      providesTags: (result, error, arg, meta) => [
        {
          type: 'Experience',
          id: `${arg.funnelId}-${arg.last_draft ? 'draft' : 'live'}`,
        },
        {
          type: 'Experience',
          id: 'ALL',
        },
      ],
    }),
    publishExperience: build.mutation<void, PublishExperienceArgs>({
      query: ({ funnelId, ...body }) => ({
        url: `/api/v1/funnels/${funnelId}/experiences/publishings`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg, meta) => [
        { type: 'Funnels' },
        { type: 'Experience', id: `${arg.funnelId}-live` },
      ],
    }),
    getBranding: build.query<RestBranding, GetBrandingArgs>({
      query: ({ appKey, brandId = DEFAULT_BRAND_ID }) =>
        `/api/v1/apps/${appKey}/brands/${brandId}/branding`,
      providesTags: (result, error, arg, meta) => [
        {
          type: 'Branding',
          id: arg.brandId ?? DEFAULT_BRAND_ID,
        },
      ],
    }),
    updateBranding: build.mutation<void, UpdateBrandingArgs>({
      query: ({ appKey, brandId = DEFAULT_BRAND_ID, ...body }) => ({
        url: `/api/v1/apps/${appKey}/brands/${brandId}/branding`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg, meta) => [
        {
          type: 'Branding',
          id: arg.brandId ?? DEFAULT_BRAND_ID,
        },
        {
          type: 'AdditionalBrands',
          id: arg.brandId ?? DEFAULT_BRAND_ID,
        },
        {
          type: 'Experience',
        },
      ],
    }),
    updateExperienceElement: build.mutation<
      { draft_version: string },
      UpdateElementArgs
    >({
      query: ({ funnelId, element, expectedLastDraftVersion }) => ({
        url: `/api/v1/funnels/${funnelId}/experiences`,
        method: 'POST',
        body: {
          action: 'update',
          path: `/layout/cards/${element.id}`,
          element,
          expectedLastDraftVersion,
        },
      }),
      invalidatesTags: (result, error, arg, meta) => [
        { type: 'Funnels' },
        { type: 'Experience', id: `${arg.funnelId}-draft` },
      ],
    }),
    updateExperienceProp: build.mutation<
      { draft_version: string },
      UpdatePropArgs
    >({
      query: ({ funnelId, element, expectedLastDraftVersion, path }) => ({
        url: `/api/v1/funnels/${funnelId}/experiences`,
        method: 'POST',
        body: {
          action: 'update',
          path,
          element,
          expectedLastDraftVersion,
        },
      }),
      invalidatesTags: (result, error, arg, meta) => [
        { type: 'Funnels' },
        { type: 'Experience', id: `${arg.funnelId}-draft` },
      ],
    }),
  }),
});

export const useSelectFunnelExperienceFull = (
  args: Pick<GetExperienceArgs, 'funnelId'>
) => {
  const companyKey = useSelector(selectCompanyInternalName);
  const appKey = useSelector(selectAppId);
  const { funnelId } = args;

  return experienceSlice.endpoints.getExperience.useQuery(
    companyKey && appKey
      ? {
          companyKey,
          appKey,
          funnelId,
          last_draft: true,
        }
      : skipToken
  );
};
export const useSelectFunnelExperience = (
  args: Pick<GetExperienceArgs, 'funnelId'>
) => {
  const { data } = useSelectFunnelExperienceFull(args);

  return data;
};

export const useSelectFunnelExperiencePlea = (
  args: Pick<GetExperienceArgs, 'funnelId'>
) => {
  const experience = useSelectFunnelExperience(args);

  const isPleaCard = (c: ExperienceCard): c is ExperienceSimplePleaCard =>
    c.template === ExperienceCardTemplate.simple_plea;

  return useMemo(
    () => experience?.experience.layout.cards.find(isPleaCard),
    [experience]
  );
};

export const useSelectFunnelExperiencePleaWithReturn = (
  args: Pick<GetExperienceArgs, 'funnelId'>
) => {
  const experience = useSelectFunnelExperience(args);

  const isPleaWithReturnCard = (
    c: ExperienceCard
  ): c is ExperienceSimplePleaWithReturnCard =>
    c.template === ExperienceCardTemplate.simple_plea_with_return;

  return useMemo(
    () => experience?.experience.layout.cards.find(isPleaWithReturnCard),
    [experience]
  );
};

export const useSelectFunnelExperienceMultiRowSurveyCard = (
  args: Pick<GetExperienceArgs, 'funnelId'>
) => {
  const experience = useSelectFunnelExperience(args);

  return useMemo(
    () => experience?.experience.layout.cards.find(isMultiRowSurveyCard),
    [experience]
  );
};

export const useSelectFunnelExperienceConfirmCancelCard = (
  args: Pick<GetExperienceArgs, 'funnelId'>
) => {
  const experience = useSelectFunnelExperience(args);

  const isConfirmCancelCard = (
    c: ExperienceCard
  ): c is ExperienceConfirmCancelCard =>
    c.template === ExperienceCardTemplate.modal_confirm_cancel;

  return useMemo(
    () => experience?.experience.layout.cards.find(isConfirmCancelCard),
    [experience]
  );
};

export const useSelectFunnelExperienceHostedFooter = (
  args: Pick<GetExperienceArgs, 'funnelId'>
) => {
  const experience = useSelectFunnelExperience(args);

  const isHostedFooterCard = (
    c: ExperienceCard
  ): c is ExperienceHostedFooterCard =>
    c.template === ExperienceCardTemplate.hosted_footer;

  return useMemo(
    () => experience?.experience.layout.cards.find(isHostedFooterCard),
    [experience]
  );
};

type ExperienceSelectorArgs = TypedUseQueryStateResult<
  ExperienceResponse,
  GetExperienceArgs,
  typeof BBK_FETCH_BASE_QUERY
>;
// An example of selectFromResult function
export const selectExperience = (res: ExperienceSelectorArgs) => res;
