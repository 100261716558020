import React, { useEffect, useRef, useState } from 'react';
import Fuse from 'fuse.js';
import classNames, { Argument } from 'classnames';
import SearchTextInput from '@bbkAdminComponents/inputs/search-text/search-text-input';
import LinkGroupRowItem from './link-group-row-item';
import './styles/link_group.scss';
import { ActiveApp, ActiveCompany } from '@bbkAdminRedux/app/reducers';

const fuseOptions = {
  shouldSort: false,
  threshold: 0.4,
  keys: ['company.internal_name', 'company.name', 'app.display_name'],
};

type LinkGroupRowItemProps = React.ComponentProps<typeof LinkGroupRowItem>;
type Link = {
  isApplication?: boolean;
  company?: ActiveCompany;
  app?: ActiveApp;
} & LinkGroupRowItemProps['item'];
type Props = {
  links: Link[];
  classes?: Argument;
  handleClose: LinkGroupRowItemProps['handleClose'];
  enableSearch?: boolean;
};
const LinkGroup: React.FC<Props> = (props) => {
  const { links, classes, handleClose, enableSearch } = props;

  const fuseSearch = new Fuse(links, fuseOptions);
  const searchInput = useRef<HTMLInputElement | null>(null);

  const [search, setSearch] = useState('');
  const [searchIndex, setSearchIndex] = useState(0);
  const [searchLinks, setSearchLinks] = useState(links);

  useEffect(() => {
    setTimeout(() => searchInput.current?.focus(), 100);
  }, []);

  const handleReasonSearch = (e: { target: { value: string } }) => {
    const searchVal = e.target.value;
    setSearch(searchVal);
    setSearchIndex(0);
    setSearchLinks(
      searchVal === '' ? links : fuseSearch.search(searchVal).map((l) => l.item)
    );
  };

  const handleArrowDirections: React.KeyboardEventHandler<HTMLInputElement> = (
    e
  ) => {
    const keyValue = e.keyCode;

    const upValueChange = (idx: number): number =>
      idx >= searchLinks.length - 1 ? 0 : idx + 1;

    const downValueChange = (idx: number): number =>
      idx <= 0 ? searchLinks.length - 1 : idx - 1;

    if (keyValue === 38 || keyValue === 40) {
      e.preventDefault();

      let searchIndexValue = searchIndex;
      if (keyValue === 40) {
        // up
        searchIndexValue = upValueChange(searchIndexValue);
        if (!searchLinks[searchIndexValue]?.isApplication) {
          searchIndexValue = upValueChange(searchIndexValue);
        }
      } else if (keyValue === 38) {
        // down
        searchIndexValue = downValueChange(searchIndexValue);
        if (!searchLinks[searchIndexValue]?.isApplication) {
          searchIndexValue = downValueChange(searchIndexValue);
        }
      }

      setSearchIndex(searchIndexValue);

      return;
    }

    if (keyValue === 13) {
      e.preventDefault();
      searchLinks[searchIndex]?.linkAction?.();
    }
  };

  return (
    <div className={classNames('bbk-link-group', classes)}>
      {enableSearch && (
        <SearchTextInput
          name="reasonPicker"
          id="reasonPicker"
          value={search}
          classes="short"
          placeholder="Search applications..."
          darkTheme={false}
          camoTheme
          onChange={handleReasonSearch}
          onKeyUp={handleArrowDirections}
          maxLength={50}
          ref={searchInput}
        />
      )}
      {searchLinks.map((item, index) => (
        <LinkGroupRowItem
          item={item}
          key={index}
          handleClose={handleClose}
          keySelected={Boolean(searchIndex === index && item.isApplication)}
        />
      ))}
    </div>
  );
};

export default LinkGroup;
