/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import type { Argument } from 'classnames';
import classNames from 'classnames';
import LinkGroupItem from './link-group-item';
import './styles/link_group.scss';

type Props = {
  item: {
    disabled?: boolean;
    classes?: Argument;
    linkAction?: (e?: React.MouseEvent) => void;
    link?: string;
  } & React.ComponentProps<typeof LinkGroupItem>['item'];
  keySelected: boolean;
  handleClose: React.MouseEventHandler<HTMLAnchorElement>;
};
const LinkGroupRowItem: React.FC<Props> = (props) => {
  const { item, keySelected, handleClose } = props;

  // is disabled?
  if (item.disabled) {
    return (
      <div
        className={classNames('bbk-lg-ri', item.classes)}
        title="coming soon..."
      >
        <LinkGroupItem item={item} />
      </div>
    );
  }

  // has a link fn?
  if (item.linkAction) {
    return (
      <div
        className={classNames('bbk-lg-ri', item.classes, { keySelected })}
        onClick={item.linkAction}
      >
        <LinkGroupItem item={item} />
      </div>
    );
  }

  if (!item.link) {
    return (
      <div
        className={classNames('bbk-lg-ri', 'header', item.classes, {
          keySelected,
        })}
      >
        <LinkGroupItem item={item} />
      </div>
    );
  }

  return (
    <a
      className={classNames('bbk-lg-ri', item.classes)}
      href={item.link}
      onClick={handleClose}
    >
      <LinkGroupItem item={item} />
    </a>
  );
};

export default LinkGroupRowItem;
