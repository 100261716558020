import * as Runtypes from 'runtypes';
import type { RoutingNodeType } from '@bbkAdminUtils/api-client/routing.types';

export enum RoutingNodeEnum {
  percentRoutingNode = 'percentRoutingNode',
  rulesRoutingNode = 'rulesRoutingNode',
}

export enum RoutingRulesEnum {
  routingToNodeRule = 'routingToNodeRule',
  routingToFunnelRule = 'routingToFunnelRule',
  routingToTargetRule = 'routingToTargetRule',
}

export enum RoutingPercentEnum {
  routingToFunnelPercent = 'routingToFunnelPercent',
  routingToNodePercent = 'routingToNodePercent',
  routingToTargetPercent = 'routingToTargetPercent',
}

const RoutingToFunnelPercentSimple = Runtypes.Record({
  name: Runtypes.String,
  type: Runtypes.Literal(RoutingPercentEnum.routingToFunnelPercent),
  percent: Runtypes.Number,
  funnel_id: Runtypes.Number,
});

const RoutingToTargetPercentSimple = Runtypes.Record({
  name: Runtypes.String,
  type: Runtypes.Literal(RoutingPercentEnum.routingToTargetPercent),
  percent: Runtypes.Number,
  target: Runtypes.Record({
    type: Runtypes.String,
    target_key: Runtypes.String,
  }),
});

const RoutingToTargetRuleSimple = Runtypes.Record({
  name: Runtypes.String,
  type: Runtypes.Literal(RoutingRulesEnum.routingToTargetRule),
  targets: Runtypes.Array(
    Runtypes.Record({
      type: Runtypes.String,
      target_key: Runtypes.String,
    })
  ),
});

const RoutingToFunnelRuleSimple = Runtypes.Record({
  name: Runtypes.String,
  type: Runtypes.Literal(RoutingRulesEnum.routingToFunnelRule),
  funnel_ids: Runtypes.Array(Runtypes.Number),
});

const RoutingToFunnelRuleWithRuleIdSimple = Runtypes.Record({
  name: Runtypes.String,
  type: Runtypes.Literal(RoutingRulesEnum.routingToFunnelRule),
  funnel_ids: Runtypes.Tuple(Runtypes.Number),
  rule_id: Runtypes.String,
});

const PercentRoutingNodeComplexSimple = Runtypes.Record({
  name: Runtypes.String,
  type: Runtypes.Literal(RoutingNodeEnum.percentRoutingNode),
  routing_percents: Runtypes.Union(
    Runtypes.Array(RoutingToFunnelPercentSimple),
    Runtypes.Array(RoutingToTargetPercentSimple)
  ),
});

const RulesRoutingNodeLastComplexSimple = Runtypes.Record({
  name: Runtypes.String,
  type: Runtypes.Literal(RoutingNodeEnum.rulesRoutingNode),
  routing_rules: Runtypes.Array(RoutingToFunnelRuleWithRuleIdSimple),
  fallback: Runtypes.Record({
    name: Runtypes.String,
    type: Runtypes.Literal(RoutingRulesEnum.routingToFunnelRule),
    funnel_ids: Runtypes.Tuple(Runtypes.Number),
  }),
});

const RoutingToNodeRuleComplex = Runtypes.Record({
  name: Runtypes.String,
  type: Runtypes.Literal(RoutingRulesEnum.routingToNodeRule),
  routing_node: Runtypes.Union(
    PercentRoutingNodeComplexSimple,
    RulesRoutingNodeLastComplexSimple
  ),
  rule_id: Runtypes.String,
});

const RulesRoutingNodeTopComplex = Runtypes.Record({
  name: Runtypes.String,
  type: Runtypes.Literal(RoutingNodeEnum.rulesRoutingNode),
  routing_rules: Runtypes.Array(RoutingToNodeRuleComplex),
  fallback: Runtypes.Record({
    name: Runtypes.String,
    type: Runtypes.Literal(RoutingRulesEnum.routingToFunnelRule),
    funnel_ids: Runtypes.Array(Runtypes.Number),
  }),
});

const RoutingToNodePercent = Runtypes.Record({
  name: Runtypes.String,
  type: Runtypes.Literal(RoutingPercentEnum.routingToNodePercent),
  percent: Runtypes.Number,
  routing_node: Runtypes.Union(
    RulesRoutingNodeTopComplex,
    PercentRoutingNodeComplexSimple
  ),
});

const TopNode = Runtypes.Record({
  name: Runtypes.String,
  type: Runtypes.Literal(RoutingNodeEnum.percentRoutingNode),
  routing_percents: Runtypes.Tuple(
    RoutingToFunnelPercentSimple,
    RoutingToNodePercent
  ),
});

const TopPlayTargetingNode = Runtypes.Record({
  name: Runtypes.String,
  type: Runtypes.Literal(RoutingNodeEnum.percentRoutingNode),
  routing_percents: Runtypes.Array(
    Runtypes.Union(RoutingToTargetPercentSimple, RoutingToNodePercent)
  ),
});

export type TopNode = Runtypes.Static<typeof TopNode>;

export type TopPlayTargetingNode = Runtypes.Static<typeof TopPlayTargetingNode>;

export const RoutingResponse = Runtypes.Record({
  routing_node: TopNode,
  branches_with_ds_experiment_enabled: Runtypes.Array(Runtypes.String),
});

export type RoutingResponse = {
  routing_node: RoutingNodeType;
  branches_with_ds_experiment_enabled: string[];
};

export type RoutingPageAndOfferCountsResponse = {
  livePageCount: number;
  liveOfferCount: number;
};
