import isEmail from 'validator/lib/isEmail';
import { merge } from 'lodash';
import type {
  RestModalEmailSendModal,
  RestModalEmailStructuredModal,
  RestModalEmailSuccessModal,
  RestModalForwards,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import {
  CtaProcessingOption,
  RestModalAction,
  RestModalTemplates,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import type { RestOffer } from '@bbkAdminUtils/api-client/rest-offers-api';
import {
  getOfferSubcategories,
  OfferCategory,
  OfferCtaDiscountOption,
  OfferCtaSubcategory,
} from '@bbkAdminUtils/api-client/rest-offers-api';
import type { Paths } from '@bbkAdminUtils/utility-types';
import { ACTIONS_AVAILABLE_FOR_CHOICE } from '@bbkAdminComponents/experiences/offers/action-select';
import { DestinationType } from '@bbkAdminRedux/rtkq/experience.slice';
import { extractConfirmationFunnelKey } from '@bbkAdminRedux/rtkq/confirmation-funnel.slice';
import { BrightbackExpressionProcess } from '@bbkAdminUtils/brightback-expression';
import { collectChainOfCards } from '@bbkAdminComponents/experiences/offers/edit-offer-utils';
import type { RestCard } from '@bbkAdminUtils/api-client/rest-experiences-api';
import { isDefined } from '@bbkAdminRedux/rtkq/rtkq-utils';
import { collectForwards } from '@bbkAdminComponents/experiences/offers/collect-forwards';
import { findPrimaryCard } from '@bbkAdminUtils/api-client/rest-cards-types';
import type { SidebarState } from '@bbkAdminComponents/experiences/offers/edit-offer-hooks';
import {
  getDurationFromCode,
  getIntervalFromCode,
} from '@bbkAdminComponents/experiences/offers/billing-offer-tab';
import type { OfferConfig } from '@bbkAdminRedux/rtkq/offer-config.slice';
import { IntegrationDataType } from '@bbkAdminRedux/data-management/reducers';

export const isCtaDismissOrCancel = (cta: RestModalForwards): boolean =>
  cta.action === RestModalAction.dismiss_modal ||
  cta.action === RestModalAction.cancel_with_link ||
  cta.action === RestModalAction.nevermind;

export const isCtaNeedsDetailsValidation = (cta: RestModalForwards): boolean =>
  !isCtaDismissOrCancel(cta);

type ModalPrefix =
  | RestModalTemplates.modal_email_structured
  | RestModalTemplates.modal_email_send
  | RestModalTemplates.modal_email_success;
type ErrorKeys =
  | Paths<RestModalForwards>
  | `${ModalPrefix}.${Paths<RestModalForwards>}`;
export type CtaValidationErrors = Record<ErrorKeys, string>;

export const validateCtaDetails = (
  cta: RestModalForwards,
  ctaIdx: number,
  offerConfig?: OfferConfig
): CtaValidationErrors => {
  const errors = {} as CtaValidationErrors;
  if (!isCtaNeedsDetailsValidation(cta)) return errors;
  const details = cta.cta_details_list?.at(ctaIdx ?? 0);
  const processing = details?.processing_option;
  const isBilling = processing === CtaProcessingOption.billing;
  const category = details?.category;
  const subcategory = details?.sub_category;

  const isChargebee =
    offerConfig?.integration_data_type === IntegrationDataType.CHARGEBEE;
  const isChargebeePause =
    isChargebee && details?.category === OfferCategory.pause;
  const isRecurlyPause =
    offerConfig?.integration_data_type === IntegrationDataType.RECURLY &&
    details?.category === OfferCategory.pause;
  const isRechargePause =
    offerConfig?.integration_data_type === IntegrationDataType.RECHARGE &&
    details?.category === OfferCategory.pause;

  const hideInterval =
    isRecurlyPause ||
    ((isChargebeePause || isRechargePause) &&
      (details?.pause_offer_type === 'SKIP' ||
        details?.pause_offer_type === 'INDEFINITE'));
  const hideDuration = details?.pause_offer_type === 'INDEFINITE';

  if (!category) {
    errors['cta_details.category'] = 'Please choose a category';
  }
  if (category) {
    const subcategories = getOfferSubcategories(category) ?? [];
    if (subcategories.length > 0 && !subcategory) {
      errors['cta_details.sub_category'] = 'Please choose a subcategory';
    }
  }
  if (!processing) {
    errors['cta_details.processing_option'] =
      'Please choose how do you want to process this offer';
  }

  if (category === OfferCategory.discount) {
    if (!details?.discount_option) {
      errors['cta_details.discount_option'] = 'Please choose a discount option';
    }
    if (details?.discount_option === OfferCtaDiscountOption.percent) {
      if (!details.amount && details.amount !== 0) {
        errors['cta_details.amount'] = 'Please provide percent of the discount';
      }
    }
    if (details?.discount_option === OfferCtaDiscountOption.fixed) {
      if (!details.amount && details.amount !== 0) {
        errors['cta_details.amount'] = 'Please provide amount for the discount';
      }
      if (!details.currency) {
        errors['cta_details.currency'] =
          'Please choose currency for the discount';
      }
    }
    if (isBilling) {
      if (!details?.coupon_id) {
        errors['cta_details.coupon_id'] =
          'Please choose coupon for the discount';
      }
    }
  }
  if (
    category === OfferCategory.pause ||
    category === OfferCategory.extension
  ) {
    if (!hideDuration && !hideInterval) {
      if (!details?.duration || !getDurationFromCode(details?.duration)) {
        errors['cta_details.duration'] = `Please select the duration of the ${
          category === OfferCategory.pause ? 'pause' : 'extension'
        }`;
      } else if (!getIntervalFromCode(details?.duration)) {
        errors['cta_details.duration'] = `Please select the interval of the ${
          category === OfferCategory.pause ? 'pause' : 'extension'
        }`;
      }
    } else if (!hideDuration) {
      if (!details?.duration || !getDurationFromCode(details?.duration)) {
        errors['cta_details.duration'] =
          'Please select the number of orders to skip.';
      }
    }
  }
  if (category === OfferCategory.plan_change) {
    if (
      subcategory === OfferCtaSubcategory.upgrade ||
      subcategory === OfferCtaSubcategory.downgrade ||
      subcategory === OfferCtaSubcategory.swap_product
    ) {
      // For this phase quantity will be optional
      // if (!details?.quantity) {
      //   errors['cta_details.quantity'] = 'Please provide quantity';
      // }
    }
    if (
      isBilling &&
      subcategory !== OfferCtaSubcategory.trial_conversion_to_current
    ) {
      if (!details?.plan_id) {
        errors['cta_details.plan_id'] = 'Please choose plan';
      }
    }
  }
  if (category === OfferCategory.gifts_and_credits) {
    if (subcategory === OfferCtaSubcategory.credit) {
      if (!details?.amount) {
        errors['cta_details.amount'] = 'Please provide amount for the credit';
      }
      if (!details?.currency) {
        errors['cta_details.currency'] =
          'Please choose currency for the credit';
      }
    }
    if (subcategory === OfferCtaSubcategory.gift) {
      if (isBilling) {
        if (!details?.plan_id) {
          errors['cta_details.plan_id'] = 'Please enter gift product ID';
        }
      }
    }
  }
  return errors;
};

const buildErrorKey = (
  key: Paths<RestModalForwards>,
  prefix?: ModalPrefix
): ErrorKeys => {
  if (prefix) {
    return `${prefix}.${key}`;
  }
  return key;
};

const validateUrl = (
  cta: RestModalForwards,
  prefix?: ModalPrefix
): CtaValidationErrors => {
  const errors = {} as CtaValidationErrors;

  const key = buildErrorKey('url', prefix);
  if (!cta.destination_type || cta.destination_type === DestinationType.url) {
    let parsedUrl;
    try {
      parsedUrl = BrightbackExpressionProcess('text', cta.url ?? '', {});
    } catch {
      /* empty */
    }
    const urlRegex = /^https?:\/\/\w+/;
    if (isDefined(parsedUrl) && !parsedUrl.match(urlRegex)) {
      errors[key] =
        'Please enter URL or choose field with fallback (https://...)';
    }
  }
  if (cta.destination_type === DestinationType.confirmation_page) {
    if (!extractConfirmationFunnelKey(cta.url)) {
      errors[key] = 'Please choose redirect page';
    }
  }
  return errors;
};

const validateEmail = (
  cta: RestModalForwards,
  prefix?: ModalPrefix
): CtaValidationErrors => {
  const errors = {} as CtaValidationErrors;

  const key = buildErrorKey('url', prefix);
  let parsedEmail = '';
  try {
    parsedEmail = BrightbackExpressionProcess('text', cta.url ?? '', {});
  } catch {
    /* empty */
  }
  if (!isEmail(parsedEmail)) {
    errors[key] = 'Please enter valid email';
  }
  return errors;
};

export const validateCtaState = (
  cta: RestModalForwards,
  offer: RestOffer
): CtaValidationErrors => {
  const errors = {} as CtaValidationErrors;
  const ctaModal = cta.modal;
  let chainOfCards: RestCard[] = [];
  if (ctaModal) {
    chainOfCards = collectChainOfCards(offer, ctaModal)
      .map((cName) => offer.cards.find((c) => c.name === cName))
      .filter(Boolean);
  }
  const emailSendCard = chainOfCards.find(
    (c) => c.template === RestModalTemplates.modal_email_send
  ) as RestModalEmailSendModal | undefined;
  const emailStructuredModal = chainOfCards.find(
    (c) => c.template === RestModalTemplates.modal_email_structured
  ) as RestModalEmailStructuredModal | undefined;
  const emailSuccessCard = chainOfCards.find(
    (c) => c.template === RestModalTemplates.modal_email_success
  ) as RestModalEmailSuccessModal | undefined;

  if (!ACTIONS_AVAILABLE_FOR_CHOICE.includes(cta.action)) {
    errors.action = 'Please choose action';
  }

  if (
    cta.action === RestModalAction.link ||
    cta.action === RestModalAction.cancel_with_link
  ) {
    merge(errors, validateUrl(cta));
  }

  if (cta.action === RestModalAction.modal_email_structured) {
    if (emailStructuredModal) {
      merge(
        errors,
        validateEmail(
          emailStructuredModal.requires.forwards,
          RestModalTemplates.modal_email_structured
        )
      );
    }
    if (emailSuccessCard) {
      merge(
        errors,
        validateUrl(
          emailSuccessCard.requires.forwards,
          RestModalTemplates.modal_email_success
        )
      );
    }
  }
  if (cta.action === RestModalAction.modal_email) {
    if (emailSendCard) {
      merge(
        errors,
        validateEmail(
          emailSendCard.requires.forwards,
          RestModalTemplates.modal_email_send
        )
      );
    }
    if (emailSuccessCard) {
      merge(
        errors,
        validateUrl(
          emailSuccessCard.requires.forwards,
          RestModalTemplates.modal_email_success
        )
      );
    }
  }
  if (cta.action === RestModalAction.send_email) {
    merge(errors, validateEmail(cta));
    if (emailSuccessCard) {
      merge(
        errors,
        validateUrl(
          emailSuccessCard.requires.forwards,
          RestModalTemplates.modal_email_success
        )
      );
    }
  }
  if (cta.action === RestModalAction.send_email) {
    if (emailSuccessCard) {
      merge(
        errors,
        validateUrl(
          emailSuccessCard.requires.forwards,
          RestModalTemplates.modal_email_success
        )
      );
    }
  }
  if (cta.action === RestModalAction.accept_offer_with_confirmation) {
    if (emailSuccessCard) {
      merge(
        errors,
        validateUrl(
          emailSuccessCard.requires.forwards,
          RestModalTemplates.modal_email_success
        )
      );
    }
  }

  return errors;
};

const validateCtaSection = (
  cta: RestModalForwards,
  offer: RestOffer,
  offerConfig: OfferConfig
): { type: SidebarState['type']; valid: boolean }[] => {
  const isValidCta =
    cta.cta_details_list?.every((details, ctaIdx) => {
      const ctaDetailsErrors = validateCtaDetails(cta, ctaIdx, offerConfig);
      return Object.keys(ctaDetailsErrors).length === 0;
    }) ?? true;
  const ctaStateErrors = validateCtaState(cta, offer);

  return [
    { type: 'button', valid: isValidCta },
    { type: 'state', valid: Object.keys(ctaStateErrors).length === 0 },
  ];
};

export const validateSection = (
  offer: RestOffer,
  offerConfig: OfferConfig
): { ctaIdx: number; type: SidebarState['type'] }[] => {
  const primaryCard = findPrimaryCard(offer);
  if (!primaryCard) return [];
  const ctas = collectForwards(primaryCard);
  return ctas.flatMap((cta, idx) => {
    const invalidCtaSections = validateCtaSection(
      cta.value,
      offer,
      offerConfig
    );

    return invalidCtaSections
      .filter((x) => !x.valid)
      .map((section) => ({
        ctaIdx: idx,
        type: section.type,
      }));
  });
};

const isValidCta = (cta: RestModalForwards, offer: RestOffer): boolean => {
  const ctaDetailsList =
    cta.cta_details_list ?? (cta.cta_details ? [cta.cta_details] : undefined);
  const isCtaValid =
    ctaDetailsList?.every((details, ctaIdx) => {
      const ctaDetailsErrors = validateCtaDetails(cta, ctaIdx);
      return Object.keys(ctaDetailsErrors).length === 0;
    }) ?? true;

  if (!isCtaValid) {
    return false;
  }
  const ctaStateErrors = validateCtaState(cta, offer);
  if (Object.keys(ctaStateErrors).length > 0) {
    return false;
  }
  return true;
};
export const isValidOffer = (offer: RestOffer): boolean => {
  const primaryCard = findPrimaryCard(offer);
  if (!primaryCard) return true;
  const ctas = collectForwards(primaryCard);
  return ctas.every((cta) => isValidCta(cta.value, offer));
};
