// @ts-nocheck
import { DeserializeHtml } from '@udecode/slate-plugins-core';
import { getNodeDeserializer, setDefaults } from '@udecode/slate-plugins';
import { DEFAULTS_BBK_EXPRESSION_CLEAN_EXPORT } from './defaults';
import { BrightbackText } from '@bbkAdminUtils/brightback-expression';

export const deserializeBbkExpressionCleanExport = (
  options?
): DeserializeHtml => {
  const { bbkExpressionCleanExport } = setDefaults(
    options,
    DEFAULTS_BBK_EXPRESSION_CLEAN_EXPORT
  );

  return {
    element: getNodeDeserializer({
      type: bbkExpressionCleanExport.type,
      node: (el) => {
        const parts = new BrightbackText(
          el.textContent
        ).getStandardFieldReferenceParts();
        const field = {
          name: parts.identifier,
          fallBackValue: parts.fallback,
          key: parts.identifier,
        };

        // TODO: locate "field" within the fields list and make sure to
        // fully fill out the information  that goes with that field.

        return {
          type: bbkExpressionCleanExport.type,
          field,
        };
      },
      rules: [{ nodeNames: 'BBK-EXPRESSION-CLEAN-EXPORT' }],
    }),
  };
};
