import React from 'react';
import Icon from '@bbkAdminComponents/icons/icon-generator';
import { ButtonV2 } from './button-v2';
import './styles/buttons.scss';

type Props = {
  title: React.ReactNode;
  url?: string;
  icon?: React.ComponentProps<typeof Icon>['name'];
  clicked?: React.MouseEventHandler;
};
const CheckButton: React.FC<Props> = (props) => {
  const { url, clicked, icon, title } = props;

  const buttonItem = (
    <ButtonV2
      onClick={clicked}
      centerAll
      transparent
      deepColor
      className="bbk-check-button"
    >
      <Icon class="currentColor" name={icon || 'check'} />
      <span className="ck-btn-text">{title}</span>
    </ButtonV2>
  );

  if (url) {
    return (
      <a style={{ paddingLeft: 0 }} href={url} onClick={clicked}>
        {buttonItem}
      </a>
    );
  }
  return buttonItem;
};

export default CheckButton;
