import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import { internalBbkApiSlice } from '@bbkAdminRedux/rtkq/internal.bbkApi.slice';
import { preauthBbkApiSlice } from '@bbkAdminRedux/rtkq/preauth.bbkApi.slice';
import { brightbackAuthSlice } from '@bbkAdminRedux/rtkq/brightback-auth.slice';

export const reducersMiddlewares = [
  preauthBbkApiSlice.middleware,
  bbkApiSlice.middleware,
  internalBbkApiSlice.middleware,
  brightbackAuthSlice.middleware,
];
