import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAppId,
  selectCompanyInternalName,
} from '@bbkAdminRedux/app/selectors';
import type { FeatureFlagsE } from '@bbkAdminUtils/feature-flags';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';

type GetUserFFsArgs = {
  companyKey: string;
  appEncodedId: string;
};
export const featureFlagsSlice = bbkApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getGlobalFFs: build.query<FeatureFlagsE[], void>({
      query: () => ({
        url: `/app/enabledFEFeatureFlags`,
        params: { nonce: Date.now() },
      }),
    }),
    getUserFFs: build.query<FeatureFlagsE[], GetUserFFsArgs>({
      query: ({ companyKey, appEncodedId }) => ({
        url: `/api/enabledFEFeatureFlags`,
        params: {
          companyKey,
          application_id: appEncodedId,
        },
      }),
    }),
  }),
});

const FF_POLL_DELAY = 5 * 60 * 1000; // 5 mins

export const useQueryFeatureFlags = () => {
  const companyKey = useSelector(selectCompanyInternalName);
  const appEncodedId = useSelector(selectAppId);

  const globalRes = featureFlagsSlice.endpoints.getGlobalFFs.useQuery(
    undefined,
    {
      pollingInterval: FF_POLL_DELAY,
    }
  );

  const userRes = featureFlagsSlice.endpoints.getUserFFs.useQuery(
    {
      companyKey,
      appEncodedId,
    },
    {
      skip:
        !companyKey ||
        !appEncodedId ||
        companyKey === '_' ||
        appEncodedId === '_',
      pollingInterval: FF_POLL_DELAY,
    }
  );

  const data = useMemo(() => {
    const globalFFs = globalRes.data ?? [];
    const userFFs = userRes.data ?? [];
    return Array.from(new Set([...globalFFs, ...userFFs]));
  }, [JSON.stringify(globalRes.data), JSON.stringify(userRes.data)]);

  return useMemo(() => {
    const {
      isLoading: globalIsLoading,
      isUninitialized: globalIsUninitialized,
      isSuccess: globalIsSuccess,
    } = globalRes;
    const {
      isLoading: userIsLoading,
      isUninitialized: userIsUnitialized,
      isSuccess: userIsSuccess,
    } = userRes;
    return {
      isUninitialized: globalIsUninitialized || userIsUnitialized,
      isLoading: globalIsLoading || userIsLoading,
      isSuccess: globalIsSuccess && userIsSuccess,
      data,
    };
  }, [globalRes, userRes]);
};
