import React, { useState } from 'react';
import ArrowButton from '@bbkAdminComponents/buttons/ArrowButton';
import SendButton from '@bbkAdminComponents/buttons/send-button';
import CheckButton from '@bbkAdminComponents/buttons/CheckButton';
import DisconnectButton from '@bbkAdminComponents/buttons/DisconnectButton';
import LinkButton from '@bbkAdminComponents/buttons/link-button';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';

type Button = {
  type?: string;
  title: React.ReactNode;
  href?: string;
  toOptions?: object;
  exportName?: string;
  downloadURL?: string;
  timeAgoKey?: string;
  disconnectLink?: string;
  manageLink?: string;
  clicked?: React.MouseEventHandler;
  modal?: React.ReactElement;
};

type Props = {
  buttons?: Button[];
};

const BottomActionBox: ReactFCC<Props> = (props) => {
  const { buttons = [], children, ...restProps } = props;

  const [open, setOpen] = useState(false);
  const hideModal = () => setOpen(false);
  const showModal = () => setOpen(true);

  const renderButton = (button: Button) => {
    const clicked = button.modal ? showModal : button.clicked;
    const modal = button.modal
      ? React.cloneElement(button.modal, {
          show: open,
          modalClosed: hideModal,
        })
      : undefined;

    switch (button.type) {
      case 'link':
        return (
          <LinkButton
            title={button.title}
            href={button.href}
            toOptions={button.toOptions}
            key={`${button.type}_${button.title}`}
          />
        );

      case 'arrow':
        return (
          <div key={`${button.type}_${button.title}`}>
            <ArrowButton
              title={button.title}
              openModal={clicked}
              key={`${button.type}_${button.title}`}
            />
            {modal}
          </div>
        );

      case 'send':
        return (
          <div key={`${button.type}_${button.title}`}>
            <SendButton
              title={button.title}
              clicked={clicked}
              key={`${button.type}_${button.title}`}
            />
            {modal}
          </div>
        );

      case 'check':
        return (
          <CheckButton
            url={button.href}
            title={button.title}
            clicked={clicked}
            key={`${button.type}_${button.title}`}
          />
        );

      case 'add':
        return (
          <CheckButton
            url={button.href}
            title={button.title}
            clicked={clicked}
            icon="plus"
            key={`${button.type}_${button.title}`}
          />
        );

      case 'manage':
        return (
          <div
            className="bbk-button manage"
            key={`${button.type}_${button.title}`}
          >
            {button.manageLink ? (
              <a href={button.manageLink} style={{ padding: 0 }}>
                <ArrowButton
                  title={`Manage Connection on ${button.title}`}
                  openModal={clicked}
                />
              </a>
            ) : (
              <span />
            )}
            {button.disconnectLink ? (
              <DisconnectButton
                url={button.disconnectLink}
                title={`Disconnect ${button.title}`}
              />
            ) : (
              <span />
            )}
            {modal}
          </div>
        );

      default:
        return undefined;
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#fafafa',
        minHeight: '52px',
        borderTop: '1px solid #E2E2E2',
        display: 'flex',
        alignItems: 'center',

        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      }}
      {...restProps}
    >
      <div
        style={{
          marginLeft: '30px',
          marginRight: '30px',
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {buttons.map(renderButton)}
        {children}
      </div>
    </div>
  );
};

export default BottomActionBox;
