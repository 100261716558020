import type { DefaultRootState } from 'react-redux';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const REDUCER_PREFIX = 'appNotPersisted-rtk';

type SliceState = {
  showUpgradeDialog?: boolean;
  showProductTour?: boolean;
  activeProductTourStory?: number;
  viewedProductTourStories?: number[];
};
const initialState: SliceState = {};

export const appNotPersistedSlice = createSlice({
  name: REDUCER_PREFIX,
  initialState,
  reducers: {
    saveMisc: (draftState, action: PayloadAction<SliceState>) => ({
      ...draftState,
      ...action.payload,
    }),
  },
});

export const selectNotPersistedMisc = (state: DefaultRootState) =>
  state[appNotPersistedSlice.name];
