import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';

export type Badge = {
  name: string;
  state: 'none' | 'flag';
};

export const badgesSlice = bbkApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBadges: builder.query<Badge[], string>({
      query: (appKey) => ({
        url: `/api/v1/apps/${appKey}/badges`,
      }),
      transformResponse: (response: { badges: Badge[] }) => response.badges,
    }),
  }),
});
