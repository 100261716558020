import React from 'react';
import classNames from 'classnames';
import icons from '@bbkAdminUtils/icons';
import '@bbkAdminStylesheets/icon.scss';

export type IconName = keyof typeof icons;
type Props = {
  name: IconName;
  class?: string;
};

const Icon: React.FC<Props> = (props) => {
  const { name, class: classes } = props;
  return <div className={classNames('bbk-icon', classes)}>{icons[name]}</div>;
};

export default Icon;
