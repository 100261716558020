import React, { useEffect, useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import cs from 'classnames';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';

import './collapse-form-container.scss';

type Props = {
  title?: React.ReactNode;
  extraHeader?: React.ReactNode;
  subtitle?: string;
  classes?: string;
  isOpened?: boolean;
  renderChildrenIfClosed?: boolean;
  btnClasses?: string;
  onClick?: (newState: boolean) => unknown;
};

const CollapseFormContainer: ReactFCC<Props> = (props) => {
  const {
    title = 'Show / Hide',
    subtitle,
    extraHeader,
    classes = 'CFC-default-theme',
    btnClasses = 'CFC-button',
    isOpened = true,
    renderChildrenIfClosed = true,
    onClick,
    children,
  } = props;

  const [isOpenedState, setIsOpened] = useState(isOpened);

  const content = useMemo(() => {
    // renderChildrenIfClosed === true
    if (renderChildrenIfClosed) return children;

    // renderChildrenIfClosed === false && isOpenedState === true
    if (isOpenedState) return children;

    // renderChildrenIfClosed === false && isOpenedState === false
    return null;
  }, [renderChildrenIfClosed, children, isOpenedState]);

  useEffect(() => {
    if (isOpenedState === undefined || isOpened) setIsOpened(isOpened);
  }, [isOpened]);

  const toggle = () => {
    onClick?.(!isOpenedState);
    setIsOpened(!isOpenedState);
  };

  const component = extraHeader ? (
    <div className="CFC-button-wrapper">
      <button
        type="button"
        onClick={toggle}
        className="CFC-button CFC-button-extra"
      >
        {title}
        {subtitle && <span className="CFC-button-subtitle">{subtitle}</span>}
      </button>
      {extraHeader}
    </div>
  ) : (
    <button type="button" onClick={toggle} className={btnClasses}>
      {title}
      {subtitle && <span className="CFC-button-subtitle">{subtitle}</span>}
    </button>
  );

  return (
    <div
      className={cs('collapse-form-container', classes, {
        'CFC-collapsed': !isOpenedState,
        'CFC-expanded': isOpenedState,
      })}
    >
      {component}
      <Collapse isOpened={isOpenedState}>{content}</Collapse>
    </div>
  );
};

export default CollapseFormContainer;
