import { matchPath } from 'react-router-dom';
import type {
  ActiveApp,
  ActiveCompany,
  ActiveUser,
} from '@bbkAdminRedux/app/reducers';
import BrightbackSession from '@bbkAdminUtils/brightback-session';

const findCompany = (user: ActiveUser, companyKey: string) => {
  if (!companyKey) return undefined;
  return user.companies.find((c) => c.internal_name === companyKey);
};
const findApp = (company: ActiveCompany, appKey: string) => {
  const companyApps = company.applications;
  if (appKey === '_') {
    return companyApps[0];
  }
  return companyApps.find((a) => a.encoded_id === appKey);
};
export const getActiveCompany = (user: ActiveUser) => {
  const pathname = window.location.pathname;

  const hasSingleCompany = user.companies.length === 1;
  const firstCompany = user.companies[0];
  const beSelectedCompany = user.selected_company;
  const urlMatch = matchPath<{ companyKey: string }>(pathname, {
    path: '/company/:companyKey',
  });
  const lsCompanyKey = BrightbackSession.getActiveCompany();

  // Priority 0 : User only has one company, use it.
  // Priority 1 : Server is telling us what company to use
  // Priority 2 : URL is telling us what company to use
  // Priority 3 : Use last active company (stored in LocalStorage)
  return (
    (hasSingleCompany ? firstCompany : undefined) ??
    (beSelectedCompany ? findCompany(user, beSelectedCompany) : undefined) ??
    (urlMatch ? findCompany(user, urlMatch.params.companyKey) : undefined) ??
    (lsCompanyKey ? findCompany(user, lsCompanyKey) : undefined)
  );
};
export const getActiveApp = (company: ActiveCompany) => {
  const placeholderApp = {
    id: 0,
    internal_name: '_',
    encoded_id: '_',
  } as ActiveApp;

  const hasNoApps = company.applications.length === 0;
  const hasSingleApp = company.applications.length === 1;
  const firstApp = company.applications[0];
  const urlMatched = matchPath<{ appKey: string }>(window.location.pathname, {
    path: '/company/:companyKey/app/:appKey',
  });
  const lsAppKey = BrightbackSession.getActiveApp();

  // Priority 0 : Use the app from the url
  // Priority 1 : If there is only one app, use it
  // temporary fallback for companies that have no apps
  // FIXME: Do we still need it ^
  // Priority 2: Use last active app, if there was one
  // Priority 3: Company has apps, but url isn't populated yet
  return (
    (hasSingleApp ? firstApp : undefined) ??
    (urlMatched ? findApp(company, urlMatched.params.appKey) : undefined) ??
    (hasNoApps ? placeholderApp : undefined) ??
    (lsAppKey ? findApp(company, lsAppKey) : undefined) ??
    firstApp
  );
};
