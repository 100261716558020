import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Slide } from '@material-ui/core';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { usePlans } from '@bbkAdminUtils/plans';
import { useUpgradeDialog } from '@bbkAdminComponents/get-started/starter-upgrade-dialog';
import { useSelectCurrentApp } from '@bbkAdminRedux/rtkq/current-user.slice';

export const StarterUpgradeBanner: React.FC = () => {
  const currentApp = useSelectCurrentApp();
  const isGetStartedEligible = currentApp?.get_started_status === 'ELIGIBLE';
  const inAdminApp = useRouteMatch('/company/:companyKey/app/:appKey');
  const { isStarterFreePlan } = usePlans();
  const { component: upgradeDialog, handleOpen } = useUpgradeDialog();
  const showBanner =
    Boolean(inAdminApp) && isStarterFreePlan && !isGetStartedEligible;

  return (
    <>
      <Slide direction="down" in={showBanner} mountOnEnter unmountOnExit>
        <div className="tw-flex tw-justify-between tw-px-4 tw-bg-cbrprimary-800 tw-text-white">
          <div className="tw-flex tw-flex-1 tw-justify-center tw-items-center tw-py-3">
            <div className="">
              <div className="">
                You’re viewing only the last{' '}
                <span className="tw-font-bold">30 days</span> of your retention
                insights. Upgrade your plan to view all.
              </div>
            </div>
            <ButtonV2 secondary className="tw-ml-4" onClick={handleOpen}>
              Upgrade plan
            </ButtonV2>
          </div>
        </div>
      </Slide>
      {upgradeDialog}
    </>
  );
};
