// @ts-nocheck
import type {
  RestFiftyFiftyColumnModal,
  RestFortySixtyMiniModal1Modal,
  RestFortySixtyMiniModal2Modal,
  RestFortySixtyMiniModal3Modal,
  RestFortySixtyMiniModal4Modal,
  RestLCOBanner,
  RestLCOModal,
  RestLCOPopUp,
  RestModalEmailSendModal,
  RestModalEmailStructuredModal,
  RestModalEmailSuccessModal,
  RestModalFinalOffer,
  RestModalMultiCTATypeI,
  RestModalMultiCTATypeII,
  RestSeventyThirtyRowModal,
  RestSixtyFortyColumnActionModal,
  RestSixtyFortyColumnCodeModal,
  RestSixtyFortyColumnG2Modal,
  RestSixtyFortyColumnModal,
  RestSixtyFortyColumnTwitterModal,
  RestSpeechBubble1Modal,
  RestSpeechBubble2Modal,
  RestVideoRowModal,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import type { GroupLayout } from '@bbkAdminComponents/experiences/offers/editor-layouts/group-layouts';
import {
  booleanConfig,
  defaultTrueBooleanConfig,
  detailGroupLayout,
  heroImageGroupForLCOBannerLayout,
  heroImageGroupForLCOModalLayout,
  heroImageGroupForOfferCardPackLayout,
  offerCardPackPriceEditorLayout1,
  offerCardPackPriceEditorLayout2,
  offerCardPackBadgeEditorLayout,
  offerCardPackDescEditorLayout,
  offerCardPackActivityEditorLayout,
  heroImageGroupForLCOPopupLayout,
  heroImageGroupLayout,
  iconEditorConfig,
  imageEditorConfig,
  lossActivityGroupLayout,
  lossActivityTweetProfile,
  lossConfigurationGroup,
  lossQuoteGroupLayout,
  personGroupLayout,
  quoteGroupLayout,
  selectConfig,
  structuredContentOptionsLayout,
  textAreaConfig,
  textEditorConfig,
  offerCardPackDisclaimer1Editor,
  offerCardPackDisclaimer2Editor,
} from '@bbkAdminComponents/experiences/offers/editor-layouts/group-layouts';
import type {
  RestLossAversionCard,
  RestOneUpLaCard,
} from '@bbkAdminUtils/api-client/rest-la-cards-types';
import { oneOf } from '@bbkAdminComponents/experiences/offers/editor-layouts/helpers';
import type { RestLossAversionCardOrModalCard } from '@bbkAdminUtils/api-client/rest-cards-types';
import { Paths } from '@bbkAdminUtils/utility-types';

export type CardLayoutItem<T, K = Paths<T>> = {
  label: string;
  groupLabel?: string;
  key: K;
  layout: GroupLayout<T[K]>;
  isArray?: boolean;
};

export type CardLayout<
  T extends RestLossAversionCardOrModalCard,
  P = T['requires'],
> = CardLayoutItem<P>[];

export const FortySixtyModal1Layout: CardLayout<RestFortySixtyMiniModal1Modal> =
  [
    oneOf([
      { label: 'Sidebar', key: 'person', layout: personGroupLayout },
      { label: 'Sidebar', key: 'hero', layout: heroImageGroupLayout },
    ]),
    { label: 'Text', key: 'info', layout: detailGroupLayout },
  ];

export const FortySixtyModal2Layout: CardLayout<RestFortySixtyMiniModal2Modal> =
  [
    oneOf([
      { label: 'Sidebar', key: 'person', layout: personGroupLayout },
      { label: 'Sidebar', key: 'hero', layout: heroImageGroupLayout },
    ]),
    { label: 'Text', key: 'info', layout: detailGroupLayout },
  ];

export const FortySixtyModal3Layout: CardLayout<RestFortySixtyMiniModal3Modal> =
  [
    { label: 'Sidebar', key: 'person', layout: personGroupLayout },
    { label: 'Text', key: 'info', layout: detailGroupLayout },
    {
      label: 'Offer',
      key: 'info',
      layout: [
        { key: 'offer_details', isArray: true },
        { key: 'interest' },
        { key: 'inline_disclaimer' },
        { key: 'disclaimer' },
        { key: 'offer_plea' },
      ],
    },
  ];

export const FortySixtyModal4Layout: CardLayout<RestFortySixtyMiniModal4Modal> =
  [
    { label: 'Sidebar', key: 'person', layout: personGroupLayout },
    { label: 'Text', key: 'info', layout: detailGroupLayout },
    {
      label: 'Cancelation',
      key: 'info',
      layout: [
        { key: 'importantTitle' },
        { key: 'important', isArray: true },
        { key: 'cta' },
        { key: 'phrase' },
        { key: 'placeholder' },
      ],
    },
  ];

export const SpeechBubble1Layout: CardLayout<RestSpeechBubble1Modal> = [
  { label: 'Sidebar', key: 'person', layout: personGroupLayout },
  {
    label: 'Text',
    key: 'info',
    layout: [...detailGroupLayout, { key: 'question' }],
  },
];

export const SpeechBubble2Layout: CardLayout<RestSpeechBubble2Modal> = [
  { label: 'Sidebar', key: 'person', layout: personGroupLayout },
  { label: 'Text', key: 'info', layout: detailGroupLayout },
];

export const VideoRowLayout: CardLayout<RestVideoRowModal> = [
  {
    label: 'Video',
    key: 'hero',
    layout: [{ key: 'url' }],
  },
  {
    label: 'Text',
    key: 'info',
    layout: [
      ...detailGroupLayout,
      { key: 'detail_heading', editor: { ...textAreaConfig } },
    ],
  },
];

export const FiftyFiftyColumnLayout: CardLayout<RestFiftyFiftyColumnModal> = [
  {
    label: 'Sidebar',
    key: ['hero', 'quote'],
    layout: quoteGroupLayout,
  },
  {
    label: 'Text',
    key: 'info',
    layout: detailGroupLayout,
  },
];

export const SeventyThirtyRowLayout: CardLayout<RestSeventyThirtyRowModal> = [
  {
    label: 'Main part',
    key: ['hero', 'quotes'],
    isArray: true,
    layout: quoteGroupLayout,
  },
  {
    label: 'Text',
    key: 'info',
    layout: [{ key: 'headline' }, ...detailGroupLayout],
  },
  {
    label: 'G2 Rating',
    key: ['hero', 'g2_rating'],
    layout: [{ key: 'rating' }, { key: 'badge', editor: imageEditorConfig }],
  },
];

export const SixtyFortyColumnModalLayout: CardLayout<RestSixtyFortyColumnModal> =
  [
    oneOf([
      { label: 'Sidebar', key: 'person', layout: personGroupLayout },
      { label: 'Sidebar', key: 'hero', layout: heroImageGroupLayout },
    ]),
    {
      label: 'Text',
      key: 'info',
      layout: [...detailGroupLayout],
    },
  ];

export const OfferCardPackLayout = [
  {
    label: 'Sidebar',
    key: 'hero',
    layout: heroImageGroupForOfferCardPackLayout,
  },
  {
    label: 'Text',
    key: 'info',
    layout: [...detailGroupLayout],
  },
  {
    lable: 'Text',
    key: 'price',
    layout: [...offerCardPackPriceEditorLayout1]
  },
  {
    label: 'Text',
    key: 'badge',
    layout: [...offerCardPackBadgeEditorLayout],
  },
  {
    label: 'Text',
    key: 'info',
    layout: [...offerCardPackDescEditorLayout]
  },{
    label: 'Text',
    key: 'activity',
    layout: [...offerCardPackActivityEditorLayout]
  },
  {
    label: 'Text',
    key: 'info',
    layout: [...offerCardPackDisclaimer1Editor],
  },
  {
    label: 'Text',
    key: 'info',
    layout: [...offerCardPackDisclaimer2Editor],
  },
  {
    lable: 'Text',
    key: 'price',
    layout: [...offerCardPackPriceEditorLayout2]
  },
]

export const LCOModalLayout: CardLayout<RestLCOModal> = [
  { label: 'Sidebar', key: 'hero', layout: heroImageGroupForLCOModalLayout },
  {
    label: 'Text',
    key: 'info',
    layout: [...detailGroupLayout],
  },
];

export const LCOPopupLayout: CardLayout<RestLCOPopUp> = [
  { label: 'Sidebar', key: 'hero', layout: heroImageGroupForLCOPopupLayout },
  {
    label: 'Text',
    key: 'info',
    layout: [...detailGroupLayout],
  },
];

export const LCOBannerLayout: CardLayout<RestLCOBanner> = [
  { label: 'Sidebar', key: 'hero', layout: heroImageGroupForLCOBannerLayout },
  {
    label: 'Text',
    key: 'info',
    layout: [...detailGroupLayout],
  },
];

export const SixtyFortyColumnActionModalLayout: CardLayout<RestSixtyFortyColumnActionModal> =
  [
    { label: 'Sidebar', key: 'hero', layout: heroImageGroupLayout },
    {
      label: 'Text',
      key: 'info',
      layout: [{ key: 'headline' }, ...detailGroupLayout],
    },
  ];

export const SixtyFortyColumnCodeModalLayout: CardLayout<RestSixtyFortyColumnCodeModal> =
  [
    {
      label: 'Text',
      key: 'info',
      layout: [{ key: 'offer' }, { key: 'headline' }, ...detailGroupLayout],
    },
    {
      label: 'Offer',
      key: 'offerCode',
      layout: [{ key: 'code' }, { key: 'how_to', isArray: true }],
    },
  ];

export const SixtyFortyColumnTwitterModalLayout: CardLayout<RestSixtyFortyColumnTwitterModal> =
  [
    {
      label: 'Text',
      key: 'info',
      layout: [{ key: 'headline' }, ...detailGroupLayout],
    },
    {
      label: 'Sidebar',
      key: 'hero',
      layout: [
        { key: 'title' },
        {
          key: 'content',
          editor: { label: 'Twitter Feed' },
          isArray: true,
        },
      ],
    },
  ];

export const SixtyFortyColumnG2ModalLayout: CardLayout<RestSixtyFortyColumnG2Modal> =
  [
    {
      label: 'Text',
      key: 'info',
      layout: [{ key: 'headline' }, ...detailGroupLayout],
    },
    {
      label: 'Quotes',
      key: ['hero', 'quotes'],
      isArray: true,
      layout: quoteGroupLayout,
    },
  ];

export const EmailSendModalLayout: CardLayout<RestModalEmailSendModal> = [
  {
    label: 'Text',
    key: 'info',
    layout: [{ key: 'title' }, { key: 'placeholder' }],
  },
  {
    label: 'Message',
    layout: [{ key: 'message' }],
  },
];

export const StructuredContentModalLayout: CardLayout<RestModalEmailStructuredModal> =
  [
    {
      label: 'Text',
      key: 'info',
      layout: [
        { key: 'title' },
        {
          key: 'optionsExplanation',
          editor: textEditorConfig,
        },
      ],
    },
    {
      label: 'Options',
      key: 'info',
      layout: structuredContentOptionsLayout,
    },
    {
      label: 'Header',
      key: ['..'],
      layout: [
        {
          key: 'dismissable',
          editor: {
            ...defaultTrueBooleanConfig,
            label: 'Show dismiss action',
          },
        },
      ],
    },
  ];

export const EmailSuccessModalLayout: CardLayout<RestModalEmailSuccessModal> = [
  {
    label: 'Text',
    key: 'info',
    layout: [{ key: 'title', editor: textEditorConfig }],
  },
  {
    label: 'Message',
    key: 'success',
    layout: [
      { key: 'title', editor: textEditorConfig },
      { key: 'details', editor: textEditorConfig },
    ],
  },
];

export const FinalOfferEditCardLayout: CardLayout<RestModalFinalOffer> = [
  {
    label: 'Text',
    key: 'info',
    layout: [
      {
        key: 'title',
        editor: {
          ...textEditorConfig,
          enabledButtons: {
            bold: false,
            italic: false,
            underline: false,
            link: false,
          },
        },
      },
      {
        key: 'image',
        editor: {
          ...imageEditorConfig,
          helpLabel: 'Recommended size: 1000x600px; < 300kb',
        },
      },
      {
        key: 'details',
        editor: textEditorConfig,
      },
    ],
  },
];

export const RestModalMultiCTATypeIEditCardLayout: CardLayout<RestModalMultiCTATypeI> =
  [
    {
      label: 'Text',
      key: 'info',
      layout: [
        {
          key: 'title',
          editor: {
            ...textEditorConfig,
            enabledButtons: {
              bold: false,
              italic: false,
              underline: false,
              link: false,
            },
          },
        },
        {
          key: 'details',
          editor: textEditorConfig,
        },
      ],
    },
  ];
export const RestModalMultiCTATypeIIEditCardLayout: CardLayout<RestModalMultiCTATypeII> =
  [
    {
      label: 'Content',
      key: 'info',
      layout: [
        {
          key: 'title',
          editor: {
            ...textEditorConfig,
            enabledButtons: {
              bold: false,
              italic: false,
              underline: false,
              link: false,
            },
          },
        },
        {
          key: 'image',
          editor: {
            ...imageEditorConfig,
            helpLabel: 'Recommended size: 550x700px; < 250kB',
          },
        },
        {
          key: 'details',
          editor: textEditorConfig,
        },
      ],
    },
  ];

// LossAversion Cards Layouts

export const OneUpImageLossAversionLayout: CardLayout<RestOneUpLaCard> = [
  {
    label: 'Card',
    layout: [
      {
        key: 'title',
        editor: {
          ...textEditorConfig,
        },
      },
      {
        key: 'image',
        editor: {
          ...imageEditorConfig,
          label: 'Hero',
          helpLabel: 'Recommended width: 460px',
        },
      },
      {
        key: 'imageStretch',
        editor: {
          ...booleanConfig,
          groupLabel: 'Stretch image to edge',
          label: 'Stretch image',
        },
      },
    ],
  },
  lossConfigurationGroup,
];
export const LossSimpleLinkLossAversionLayout: CardLayout<RestLossAversionCard> =
  [
    {
      label: 'Card',
      layout: [
        {
          key: 'title',
          editor: {
            ...textEditorConfig,
          },
        },
        {
          key: 'image',
          editor: {
            ...imageEditorConfig,
            helpLabel: 'Recommended max width: 150px',
          },
        },
        {
          key: 'copy',
          editor: {
            label: 'Text',
            ...textEditorConfig,
          },
        },
      ],
    },
    {
      ...lossConfigurationGroup,
      layout: [
        ...lossConfigurationGroup.layout,
        {
          key: 'vertical',
          editor: {
            ...booleanConfig,
            label: 'Vertical layout',
            groupLabel: ' ',
          },
        },
      ],
    },
  ];

export const LossSimpleListLossAversionLayout: CardLayout<RestLossAversionCard> =
  [
    {
      label: 'Card',
      layout: [
        {
          key: 'image',
          editor: {
            ...imageEditorConfig,
            helpLabel: 'Recommended max height: 400px',
          },
        },
        {
          key: 'title',
          editor: {
            ...textEditorConfig,
          },
        },
        {
          key: 'bullet_icon',
          editor: {
            ...imageEditorConfig,
            label: 'Bullet icon (upload image)',
            helpLabel: 'Recommended width and height: 16px',
          },
        },
        {
          key: 'bullet_icon_picker',
          editor: {
            ...iconEditorConfig,
            label: 'Bullet icon (pick from the list)',
          },
        },
        {
          key: 'cols',
          editor: {
            ...selectConfig,
            label: '# of columns',
            defaultValue: 2,
            opts: [
              {
                value: 1,
                label: '1 column',
              },
              {
                value: 2,
                label: '2 column',
              },
            ],
          },
        },
        {
          key: 'items',
          isArray: true,
          editor: {
            ...textEditorConfig,
          },
        },
      ],
    },
    lossConfigurationGroup,
  ];

export const LossSimpleBlurbLossAversionLayout: CardLayout<RestLossAversionCard> =
  [
    {
      label: 'Card',
      layout: lossQuoteGroupLayout,
    },
    {
      ...lossConfigurationGroup,
      layout: [
        ...lossConfigurationGroup.layout,
        {
          key: 'vertical',
          editor: {
            ...booleanConfig,
            label: 'Vertical layout',
            groupLabel: ' ',
          },
        },
      ],
    },
  ];

export const LossActivityLossAversionLayout: CardLayout<RestLossAversionCard> =
  [
    {
      label: 'Card',
      layout: [
        {
          key: 'title',
          editor: {
            ...textEditorConfig,
          },
        },
      ],
    },
    {
      label: 'Activities',
      isArray: true,
      key: 'activities',
      layout: lossActivityGroupLayout,
    },
    lossConfigurationGroup,
  ];

export const LossTweetLossAversionLayout: CardLayout<RestLossAversionCard> = [
  {
    label: 'Card',
    layout: [
      {
        key: 'title',
        editor: {
          ...textEditorConfig,
        },
      },
    ],
  },
  {
    key: 'profile',
    isCollapse: true,
    layout: lossActivityTweetProfile,
  },
  {
    key: 'tweet',
    isCollapse: true,
    layout: [
      {
        key: 'content',
        editor: {
          ...textAreaConfig,
        },
      },
      {
        key: 'link',
      },
      {
        key: 'linkText',
      },
      {
        key: 'date',
      },
    ],
  },
  lossConfigurationGroup,
];
