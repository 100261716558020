import RadioButton from '@bbkAdminComponents/switches/radio-button/radio-button';
import { RecurlyBillingConfig } from '@bbkAdminRedux/rtkq/billing-config.slice';
import { startCase } from 'lodash';
import React from 'react';
import { DescriptionTitle, RowStyles } from '../lib';

const cancelType = ['cancel_subscription', 'terminate_subscription'];
const timeFrame = ['term_end', 'bill_date'];
const refundType = ['full', 'partial', 'none'];

export const RecurlyForm = (props: {
  values: RecurlyBillingConfig;
  setCancelConfig: (values: RecurlyBillingConfig) => void;
  loading: boolean;
}) => {
  const {
    values,
    values: { cancel_type, charge, refund_type, time_frame },
    setCancelConfig,
    loading,
  } = props;

  const handleChange = (update: { [key: string]: any }) => {
    setCancelConfig({
      ...values,
      ...update,
    });
  };

  return (
    <>
      {cancelType.map((cf) => (
        <RadioButton
          classes="bbk-radio-button-label-new-purple"
          key={cf}
          checked={cancel_type === cf}
          id={cf}
          label={startCase(cf)}
          handleChange={() => handleChange({ cancel_type: cf })}
          disabled={loading}
          questionContent={
            cf === 'cancel_subscription'
              ? 'Remains active for the paid period. Choose to end when the subscription next bills or at the end of the subscription term.'
              : 'Instantly stops the subscription. Choose to provide a partial, full, or no refund. If "Charge" is true, current billing cycle unbilled usage is billed on the final invoice.'
          }
        />
      ))}
      {cancel_type === 'cancel_subscription' && (
        <div>
          <DescriptionTitle css={[RowStyles.paragraphSpacer]}>
            Time frame
          </DescriptionTitle>
          {timeFrame.map((tf) => (
            <RadioButton
              classes="bbk-radio-button-label-new-purple"
              key={tf}
              checked={time_frame === tf}
              id={tf}
              label={startCase(tf)}
              handleChange={() => handleChange({ time_frame: tf })}
              disabled={loading}
            />
          ))}
        </div>
      )}
      {cancel_type === 'terminate_subscription' && (
        <div>
          <DescriptionTitle css={[RowStyles.paragraphSpacer]}>
            Refund type
          </DescriptionTitle>
          {refundType.map((rf) => (
            <RadioButton
              classes="bbk-radio-button-label-new-purple"
              key={rf}
              checked={refund_type === rf}
              id={rf}
              label={startCase(rf)}
              handleChange={() => handleChange({ refund_type: rf })}
              disabled={loading}
            />
          ))}
          <DescriptionTitle css={[RowStyles.paragraphSpacer]}>
            Charge unbilled usage
          </DescriptionTitle>
          <RadioButton
            classes="bbk-radio-button-label-new-purple"
            checked={charge === true}
            id={'chargeT'}
            label={'Unbilled usage will appear on a final invoice'}
            handleChange={() => handleChange({ charge: true })}
            disabled={loading}
          />
          <RadioButton
            classes="bbk-radio-button-label-new-purple"
            checked={charge === false}
            id={'chargeF'}
            label={
              'Create a negative usage record that will zero out on a final invoice'
            }
            handleChange={() => handleChange({ charge: false })}
            disabled={loading}
          />
        </div>
      )}
    </>
  );
};
