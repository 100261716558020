/* eslint-disable default-param-last */
import type { DefaultRootState } from 'react-redux';
import { useDispatch } from 'react-redux';
import type { Action, AnyAction, Reducer } from 'redux';
import type { ThunkDispatch } from '@reduxjs/toolkit';
import type { TypedUseQueryStateResult } from '@reduxjs/toolkit/query/react';
import type { Dictionary } from '@bbkAdminUtils/utility-types';
import type { BBK_FETCH_BASE_QUERY } from '@bbkAdminRedux/rtkq/bbkApi.slice';

type Actions<T extends keyof any = string> = Record<T, Action>;

export type CaseReducer<S = any, A extends Action = AnyAction> = (
  state: S,
  action: A
) => S;

export type CaseReducers<S, AS extends Actions> = {
  [T in keyof AS]: AS[T] extends Action ? CaseReducer<S, AS[T]> : void;
};

export function reducer<
  S,
  CR extends CaseReducers<S, any> = CaseReducers<S, any>,
>(initialState: S, mapping: CR): Reducer<S> {
  return function (state = initialState, action) {
    const caseReducer = mapping[action.type];
    if (caseReducer) {
      return caseReducer(state, action);
    }
    return state;
  };
}

export type AppDispatch = ThunkDispatch<DefaultRootState, any, Action>;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();

export function toDictionary<T extends { id: string | number }>(
  collection: T[]
): Dictionary<T> {
  return Object.fromEntries(collection.map((el) => [el.id, el]));
}

export type GenericSelectorArgs = TypedUseQueryStateResult<
  unknown,
  unknown,
  typeof BBK_FETCH_BASE_QUERY
>;
export const selectFromResultTyped = <
  T extends unknown,
  ARGS extends GenericSelectorArgs = GenericSelectorArgs,
>(
  res: ARGS
) => {
  type ResType = ARGS & {
    // TODO: fix as Maybe<T>
    data: T;
  };
  return res as ResType;
};
