import { useCallback } from 'react';
import { useAppDispatch } from '@bbkAdminRedux/redux_utils';
import type { ActiveUser } from '@bbkAdminRedux/app/reducers';
import BrightbackSession from '@bbkAdminUtils/brightback-session';
import { getActiveApp, getActiveCompany } from '@bbkAdminRedux/app/utils';
import * as Action from '@bbkAdminRedux/app/actions';
import type { Maybe } from '@bbkAdminUtils/utility-types';
import { LogInPaths } from '@bbkAdminComponents/log-in-flow/log-in-paths';
import {
  brightbackAuthSlice,
  isSignedIn,
} from '@bbkAdminRedux/rtkq/brightback-auth.slice';

export const useAppInit = () => {
  const dispatch = useAppDispatch();
  const [signOut$] = brightbackAuthSlice.endpoints.signOut.useMutation();

  return useCallback(
    (user: Maybe<ActiveUser>) => {
      if (isSignedIn()) {
        if (user) {
          if (window.location.pathname !== LogInPaths.selector) {
            const company = getActiveCompany(user);
            if (company) {
              BrightbackSession.setActiveCompany(company, false);
              dispatch({
                type: Action.SET_ACTIVE_COMPANY,
                activeCompany: company,
              });
              const app = getActiveApp(company);
              dispatch({ type: Action.SET_ACTIVE_APP, activeApp: app });
              BrightbackSession.setActiveApp(app);
            } else {
              BrightbackSession.setActiveCompany(undefined);
            }
          }
        } else {
          BrightbackSession.setActiveCompany(undefined);
          BrightbackSession.setActiveApp(undefined);
          BrightbackSession.setAuthAlert('Please log in again for security');
          signOut$().unwrap();
        }
        dispatch({ type: Action.DATA_LOADED });
      }
    },
    [dispatch]
  );
};
