/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useState } from 'react';
import { Popover } from '@material-ui/core';
import { ButtonV2, CircleIcon } from '@bbkAdminComponents/buttons';
import { GenericActionMenuButton } from '@bbkAdminComponents/action-menu/generic-action-menu-button';
import { HoverIcons } from '@bbkAdminComponents/experiences/audiences/components';

type Props = {
  actions: {
    icon?: React.ReactNode;
    copy?: React.ReactNode;
    onClick?: (...args: unknown[]) => unknown;
    disabled?: boolean;
    disabledText?: string;
  }[];
  disabled?: boolean;
  actionButton?: typeof DefaultActionButton;
  popoverProps?: Omit<React.ComponentProps<typeof Popover>, 'open'>;
};

const DefaultActionButton: React.FC<{
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled: boolean | undefined;
}> = ({ onClick, disabled }) => (
  <GenericActionMenuButton onClick={onClick} disabled={disabled} circle small>
    {HoverIcons.tripleDots}
  </GenericActionMenuButton>
);

export const GenericActionMenu = forwardRef<HTMLDivElement, Props>(
  (
    {
      actions,
      disabled,
      actionButton: ActionButton = DefaultActionButton,
      popoverProps,
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleOpen: React.MouseEventHandler<HTMLButtonElement> = (event) =>
      setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const Button: React.FC<
      {
        icon?: React.ReactNode;
        copy?: React.ReactNode;
      } & React.ComponentProps<typeof ButtonV2>
    > = ({ icon, copy, ...restButtonProps }) => (
      <ButtonV2
        transparent
        centerAll
        css={{
          paddingBottom: 0,
          justifyContent: 'left',
        }}
        {...restButtonProps}
      >
        {icon && <CircleIcon css={{ marginRight: 5 }}>{icon}</CircleIcon>}
        {copy}
      </ButtonV2>
    );

    const ButtonGroup = () => (
      <div className="tw-flex tw-flex-col">
        {actions.map(
          (
            {
              onClick,
              disabled: disabledAction,
              disabledText,
              ...restActionProps
            },
            idx
          ) => (
            <Button
              key={idx}
              onClick={() => {
                handleClose();
                onClick?.();
              }}
              disabled={disabledAction}
              title={disabledAction ? disabledText : ''}
              {...restActionProps}
            />
          )
        )}
      </div>
    );

    return (
      <div ref={ref}>
        <ActionButton onClick={handleOpen} disabled={disabled} />
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          {...popoverProps}
        >
          <div className="tw-pt-2 tw-pb-4">
            <ButtonGroup />
          </div>
        </Popover>
      </div>
    );
  }
);
