import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { useHasSubscription, useSubscriptionPlans } from '@bbkAdminUtils/plans';
import {
  Expiration,
  useDaysToExpiration,
  usePlanPurchaseIntent,
  useSelectCompanySubscription,
} from '@bbkAdminRedux/rtkq/checkout.slice';
import { selectCompanyInternalName } from '@bbkAdminRedux/app/selectors';
import { useUrl } from '@bbkAdminUtils/nav';
import { useRoles } from '@bbkAdminUtils/roles';
import moment from 'moment';
import { Link } from 'react-router-dom';
import tailwindColors from '@bbkRoot/tailwind.colors';
import { Constants } from '@bbkUtils/constants/ui-constants';

export const BottomAlertTab: React.FC = () => {
  const createUrl = useUrl();
  // Plan filters
  const { isEssentialsPlan } = useSubscriptionPlans();
  const { hasBillingRole } = useRoles();
  const expiration: Expiration | undefined = useDaysToExpiration();
  const [alertText, setAlertText] = useState<string>('');

  const handlePlanPurchaseIntent = usePlanPurchaseIntent();

  const hasSubscription = useHasSubscription();
  const companyInternalName = useSelector(selectCompanyInternalName);
  const subscription = useSelectCompanySubscription(
    hasSubscription
      ? {
          company: companyInternalName,
        }
      : skipToken
  );

  useEffect(() => {
    if (!expiration) {
      return;
    }
    const { isTrial, endDate } = expiration;
    const daysToExpiry = endDate.diff(moment.utc(), 'days');
    let alert = '';
    if (isTrial) {
      alert =
        daysToExpiry < 0
          ? 'Trial expired'
          : `Expires on ${endDate.format('MMM D, YYYY')} UTC`;
    } else if (isEssentialsPlan) {
      const usage = subscription
        ? ` (${subscription?.usage}\\${subscription?.price.included_session_limit})`
        : '';
      alert = `Essentials plan ${usage}`;
    }
    setAlertText(alert);
  }, [expiration, isEssentialsPlan, subscription]);

  return (
    <div
      css={{
        position: 'absolute',
        width: 225,
        height: 41,
        left: 10,
        bottom: 0,
        background: '#dde7fa',
        color: tailwindColors.darkerPurple,
        padding: '10px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        fontSize: 12,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div css={{ flex: 1 }}>{alertText}</div>
      {hasBillingRole && (
        <Link
          to={{
            pathname: Constants.PurchaseLink,
          }}
          target="_blank"
        >
          <ButtonV2
            onClick={() => {
              handlePlanPurchaseIntent();
            }}
            tiny
            transparent
            deepColor
            css={{ marginLeft: 2 }}
          >
            Purchase
          </ButtonV2>
        </Link>
      )}
    </div>
  );
};
