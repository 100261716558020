import React from 'react';
import Gravatar from 'react-gravatar';
import '@bbkAdminStylesheets/profileImage.scss';

const ProfileImage: React.FC<{
  user: { email: string; details?: { image_url: string } };
}> = (props) => {
  if (props.user && props.user.details && props.user.details.image_url) {
    return <img className="profileImage" src={props.user.details.image_url} />;
  }
  return (
    <Gravatar
      className="profileImage"
      email={props.user.email}
      size={18}
      rating="pg"
      default="mp"
    />
  );
};

export default ProfileImage;
