import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import {
  AppBusinessModel,
  AppBusinessVertical,
} from './reports/benchmark.selectors';
import {
  OfferCategory,
  OfferCtaDiscountOption,
  OfferCtaSubcategory,
  OfferObjective,
  OfferPresentation,
} from '@bbkAdminUtils/api-client/rest-offers-api';
import { PauseOfferType } from '@bbkAdminUtils/api-client/rest-modals-cards-types';

export type GenerateContentId = {
  id: number;
  companyKey: string;
  appKey: string;
};

export type GenerateContentArgs = {
  companyKey: string;
  appKey: string;

  domain: string;
  vertical: string;
  model: string;
  audience: string;
  tone: string[];
  lang: string;
  offer_presentation_mode: OfferPresentation;
  additional_details?: string;

  objective: OfferObjective;
  offer_category: OfferCategory | undefined; //undefined for new offers only
  discount_type?: OfferCtaDiscountOption;
  discount_strength?: number;
  offer_sub_category?: OfferCtaSubcategory;
  pause_type?: PauseOfferType;
  duration?: string;
  plan_name?: string;

  content_id?: string;
};
export type EnrichArgs = {
  companyKey: string;
  appKey: string;
  content_id: string;
  offer_content_id: number;
  outcome: 'ACCEPTED' | 'ACCEPTED_BUT_MODIFIED';
};
type TranslateContentArgs = GenerateContentArgs & {
  lang: string;
};

export type OfferContent = {
  id: number;
  outcome: EnrichArgs['outcome'];
  model_output: string;
};

export type GenAIGeneratedContent = {
  title: string;
  description: string;
  cta_text: string;
  offer_content_id?: number;
};
export type GenAIGeneratedImage = {
  created: number;
  data: {
    revised_prompt: string;
    url: string;
  }[];
};

type UpdateCompanyInfo = {
  company: string;
  app: string;
  name: string;
  domain: string;
  vertical: AppBusinessVertical;
  model: AppBusinessModel;
};

export const genaiSlice = bbkApiSlice.injectEndpoints({
  endpoints: (build) => ({
    generateContent: build.mutation<
      GenAIGeneratedContent[],
      GenerateContentArgs
    >({
      query: ({ companyKey, appKey, ...body }) => ({
        url: `/api/v2/company/${companyKey}/app/${appKey}/genai/content`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: { result: GenAIGeneratedContent[] }) =>
        response.result,
    }),
    getContent: build.query<OfferContent, GenerateContentId>({
      query: ({ id, companyKey, appKey }) =>
        `/api/v2/company/${companyKey}/app/${appKey}/genai/content?id=${id}`,
    }),
    enrich: build.mutation<void, EnrichArgs>({
      query: ({ companyKey, appKey, ...body }) => ({
        url: `/api/v2/company/${companyKey}/app/${appKey}/genai/enrich`,
        method: 'PUT',
        body,
      }),
    }),
    updateCompanyInfo: build.mutation<void, UpdateCompanyInfo>({
      query: ({ company, app, ...body }) => ({
        url: `/api/v1/company/${company}/app/${app}/updateCompanyInfo`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CurrentApp', 'CurrentCompany'],
    }),
  }),
});

export const useGenerateContent = () => {
  return genaiSlice.endpoints.generateContent.useMutation();
};
