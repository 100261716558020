import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { SkipToken } from '@reduxjs/toolkit/query/react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import moment from 'moment';
import {
  selectCompany,
  selectCompanyInternalName,
} from '@bbkAdminRedux/app/selectors';
import type { IntegrationDataType } from '@bbkAdminRedux/data-management/reducers';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import { PLANS } from '@bbkAdminRedux/rtkq/checkout.plans';
import {
  parseRTKAPIError,
  RTKAPIErrorResponse,
} from '@bbkAdminRedux/rtkq/rtkq-error-logger';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';
import { recordError } from '@bbkAdminUtils/recordError';

type GetSubscriptionsArgs = {
  company: string;
  admin?: boolean;
};

type GetSubscriptionsHistoryArgs = {
  companyKey: string;
};

type DateString = string;

type Subscription = {
  id: string;
  plan_id: SubscriptionPlanId;
  status: 'active' | 'incomplete';
  usage: number;
  period: PlanPeriod;
  price: PlanPrice;
  card_last4: string;
  billing_name: string;
  billing_email: string;
  subscription_plan: PLANS;
  coupon_id?: string;
  coupon_percent_off?: number;
  current_period_end: DateString;
  current_period_start: DateString;
  base_subscription_start: DateString;
  current_usage_period_start: DateString;
  current_usage_period_end: DateString;
  scheduled_price_id_change?: string;
  scheduled_change_effective_after?: DateString;
  cancellation_effective_on?: DateString;
  committed_until?: DateString;
  billing_system?: IntegrationDataType;
};

type CompanyKeyArgs = {
  companyKey: string;
};

export enum SubscriptionPlanId {
  Essentials_Month = 'Essentials_Month',
  Essentials_Year = 'Essentials_Year',
  Performance_Quarter = 'Performance_Quarter',
  Performance_Year = 'Performance_Year',
}

export type PlanPrice = {
  base_price: number;
  id: string;
  usage_price_id: string;
  included_session_limit: number;
  overage_price: number;
  subscriptionPlanId: SubscriptionPlanId;
  period: PlanPeriod;
  planName: PLANS;
  currency_code: string;
  usage_coupon_id?: string;
  usage_coupon_percent_off?: number;
};

export enum PlanPeriod {
  month = 'month',
  quarter = 'quarter',
  semiannual = 'semiannual',
  year = 'year',
}

type EmptySubscription = Record<string, never>; // Type for an empty object {}
type SubscriptionsResponse = EmptySubscription | Subscription;

type PlanUpgradeArgs = {
  companyKey: string;
  subscriptionPlan: PLANS;
};

type SubscriptionsHistoryResponse = {
  subscription_plan: PLANS;
  start_date: DateString;
  end_date: DateString;
}[];

export const checkoutSlice = bbkApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    planUpgradeIntent: builder.mutation<void, PlanUpgradeArgs>({
      query: ({ companyKey, subscriptionPlan }) => ({
        url: `/api/v1/companies/${companyKey}/transition/${subscriptionPlan}/notify`,
        method: 'POST',
      }),
    }),
    planPurchaseIntent: builder.mutation<void, CompanyKeyArgs>({
      query: ({ companyKey }) => ({
        url: `/api/v1/companies/${companyKey}/purchase_intent`,
        method: 'POST',
      }),
    }),
    planUpgrade: builder.mutation<void, PlanUpgradeArgs>({
      query: ({ companyKey, subscriptionPlan }) => ({
        url: `/api/v1/companies/${companyKey}/transition/${subscriptionPlan}`,
        method: 'POST',
      }),
      invalidatesTags: [
        'CurrentApp',
        'CompanySubscriptionsHistory',
        'OnboardingStepsHistory',
      ],
    }),
    getSubscriptions: builder.query<
      SubscriptionsResponse,
      GetSubscriptionsArgs
    >({
      query: ({ company, admin }) =>
        `${admin ? '/dev' : '/api/v1'}/company/${company}/checkout/status`,
      providesTags: ['CompanySubscriptions'],
    }),
    getSubscriptionsHistory: builder.query<
      SubscriptionsHistoryResponse,
      GetSubscriptionsHistoryArgs
    >({
      query: ({ companyKey }) =>
        `/api/v1/companies/${companyKey}/plan_histories`,
      providesTags: ['CompanySubscriptionsHistory'],
    }),
    clearCheckoutStatusCache: builder.mutation<void, string>({
      query: (companyId: string) => ({
        url: `/dev/companies/${companyId}/checkout/clear_cache`,
        method: 'POST',
      }),
      invalidatesTags: ['CompanySubscriptions'],
    }),
  }),
});

export const prepareGetSubscriptionsArgs = (
  args: GetSubscriptionsArgs | SkipToken
) => {
  if (args === skipToken) return skipToken;
  const { company } = args;
  if (!company || company === '_') return skipToken;
  return args;
};

export const useSelectCompanySubscription = (
  args: GetSubscriptionsArgs | SkipToken
): Subscription | undefined => {
  const { data } = checkoutSlice.endpoints.getSubscriptions.useQuery(
    prepareGetSubscriptionsArgs(args)
  );
  return data?.subscription_plan ? (data as Subscription) : undefined;
};

export const getPeriodShort = (period: PlanPeriod): string | undefined => {
  switch (period) {
    case PlanPeriod.year:
      return 'yr';
    case PlanPeriod.semiannual:
      return '6mo';
    case PlanPeriod.quarter:
      return 'qtr';
    case PlanPeriod.month:
      return 'mo';
    default:
      recordError(`Unknown period: ${JSON.stringify(period)}`);
      return undefined;
  }
};

export const getPeriodLong = (
  period: PlanPeriod | undefined
): string | undefined => {
  switch (period) {
    case PlanPeriod.year:
      return 'annually';
    case PlanPeriod.semiannual:
      return 'semi-annually';
    case PlanPeriod.quarter:
      return 'quarterly';
    case PlanPeriod.month:
      return 'monthly';
    default:
      recordError(`Unknown period: ${JSON.stringify(period)}`);
      return undefined;
  }
};

export type Expiration = {
  isTrial: boolean;
  endDate: moment.Moment;
};

export const PLAN_REMINDER_DAYS = 7;

export const useDaysToExpiration = (): Expiration | undefined => {
  const company = useSelector(selectCompany);
  const subscription = useSelectCompanySubscription(
    company ? { company: company.internal_name } : skipToken
  );
  return useMemo((): Expiration | undefined => {
    if (!company) return undefined;
    const isTrial = !!company.trial;
    const isCancelled =
      company.plan === PLANS.ESSENTIALS &&
      subscription?.cancellation_effective_on;
    if (!isTrial && !isCancelled) return undefined;

    const endDate = isTrial
      ? moment(company.trial_end_date).utc()
      : moment(subscription?.cancellation_effective_on).utc();
    return { isTrial, endDate };
  }, [company, subscription]);
};

export const getDiscountFactor = (
  subscription: Subscription | undefined,
  type: 'usage' | 'base'
) => {
  if (type === 'usage') {
    return subscription?.price?.usage_coupon_id
      ? 1 - (subscription?.price?.usage_coupon_percent_off ?? 0) / 100
      : 1;
  }
  return subscription?.coupon_id
    ? 1 - (subscription?.coupon_percent_off ?? 0) / 100
    : 1;
};

export const usePlanPurchaseIntent = () => {
  const companyKey = useSelector(selectCompanyInternalName);
  const [planPurchaseIntent] =
    checkoutSlice.endpoints.planPurchaseIntent.useMutation();

  return useCallback(() => {
    planPurchaseIntent({ companyKey })
      .unwrap()
      .catch((e: RTKAPIErrorResponse) => {
        const err = parseRTKAPIError(e);
        BlackToastTrigger({
          content: 'Failed to push purchase intent notification.',
        });
        recordError(`planPurchaseIntent error ${err}`);
      });
  }, [planPurchaseIntent, companyKey]);
};
