import React from 'react';
import cs from 'classnames';
import { LoggedInRouter } from '@bbkAdminRouter/logged-in.router';
import LeftColumn from '@bbkAdminComponents/app-layout/left-column';
import { MainContent } from '@bbkAdminComponents/app-layout/main-content';
import { usePageTitle } from '@bbkUtils/page-title';
import './app-root.scss';
import './styles/layout.scss';
import { EndOfPlanBanner } from '@bbkAdminComponents/settings/plans/end-of-plan-banner';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import { StarterUpgradeBanner } from '@bbkAdminComponents/settings/plans/starter-upgrade-banner';
import { GetStartedBanner } from '@bbkAdminComponents/settings/plans/get-started-banner';

export const AppRoot: ReactFCC = ({ children }) => {
  usePageTitle();

  return (
    <div className="tw-box-border" id="bbk-app-root">
      <div className={cs('tw-flex tw-flex-col', 'tw-h-screen tw-max-h-screen')}>
        <GetStartedBanner />
        <StarterUpgradeBanner />
        <EndOfPlanBanner />
        <div className="bbk-layout column-layout">
          <LeftColumn />
          <LoggedInRouter>
            <MainContent>{children}</MainContent>
          </LoggedInRouter>
        </div>
      </div>
      {/* For when needed in a component */}
      <div id="chartjs-portal" />
    </div>
  );
};
