// @ts-nocheck
import React, { useMemo } from 'react';
import type { SerializedStyles } from '@emotion/react';
import { GenericPopover } from '@bbkAdminComponents/popovers/generic-popover';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';

export const GenericInlineEditor: ReactFCC<{
  title?: string;
  content: React.ReactNode;
  onOpen: () => void;
  onClose: () => void;
  refresh?: boolean;
  customStyles: SerializedStyles;
}> = ({
  title = 'Editor',
  content,
  onOpen,
  onClose,
  refresh,
  children,
  customStyles,
}) => {
  const actionButton = useMemo(
    () => (props) => (
      <div className="design-element" {...props} css={customStyles}>
        {children}
      </div>
    ),
    [refresh]
  );

  return (
    <GenericPopover
      popoverProps={{
        PaperProps: {
          style: { width: '430px', overflow: 'initial' },
        },
        classes: { paper: 'bbk-generic-dialog-popover' },
        disableEnforceFocus: true,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      actionButton={actionButton}
      onClose={onClose}
      onOpen={onOpen}
    >
      {({ handleClose }) => {
        const close = () => {
          onClose();
          handleClose();
        };
        return (
          <div>
            <div className="bbk-gdialog-header">
              <div className="bbk-gdialog-title">{title}</div>
              <div className="bbk-gdialog-header-actions">
                <ButtonV2
                  primary
                  className="bbk-gdialog-button"
                  onClick={close}
                >
                  Done
                </ButtonV2>
              </div>
            </div>
            <div className="bbk-gdialog-body tw-max-h-[75vh] !tw-pt-0">
              {content}
            </div>
          </div>
        );
      }}
    </GenericPopover>
  );
};
