/* eslint-disable camelcase */
import type {
  RestLossAversion,
  RestOffer,
} from '@bbkAdminUtils/api-client/rest-offers-api';
import type { VanityReason } from '@bbkAdminRedux/rtkq/survey-reasons.slice';
import type { RestFunnel } from '@bbkAdminRedux/rtkq/funnels.slice';
import type { RestPosition } from '@bbkAdminRedux/rtkq/placements.utils';

export type RestContent = RestLossAversion | RestOffer;

export type PlacementsWarningsResponse = {
  content_publish_warnings: Array<{
    content: RestContent;
    funnels: RestFunnel[];
  }>;
  position_warnings: Array<{
    funnel: RestFunnel;
    position_warnings: {
      position: RestPosition;
      content?: RestContent;
    }[];
  }>;
  reason_warnings: Array<{
    funnel: RestFunnel;
    reason_warnings: {
      vanity_reason: VanityReason;
      content?: RestContent;
    }[];
  }>;
};

export enum WarningType {
  vanityReason = 'vanityReason',
  position = 'position',
  content = 'content',
}

type VanityReasonWarning = {
  type: WarningType.vanityReason;
  vanityReason: VanityReason;
  content: RestContent | null;
};

type PositionWarning = {
  type: WarningType.position;
  position: RestPosition;
  content: RestContent | null;
};

type ContentWarning = {
  type: WarningType.content;
  content: RestContent;
};

export type Warning = VanityReasonWarning | PositionWarning | ContentWarning;

export type FunnelWarning = {
  funnel: RestFunnel;
} & Warning;

export type FunnelWarnings = {
  funnel: RestFunnel;
  warnings: Warning[];
}[];
