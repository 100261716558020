import type {
  RestModalCard,
  RestModalFinalOffer,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import { RestModalTemplates } from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import type { RestLossAversionCard } from '@bbkAdminUtils/api-client/rest-la-cards-types';
import { RestLossAversionCardTemplates } from '@bbkAdminUtils/api-client/rest-la-cards-types';
import type {
  NewOffer,
  RestLossAversion,
  RestOffer,
} from '@bbkAdminUtils/api-client/rest-offers-api';
import type { RestCard } from '@bbkAdminUtils/api-client/rest-experiences-api';
import type { ExperienceMultiRowSurveyCard } from '@bbkAdminRedux/rtkq/experience.slice';
import { recordError } from '@bbkAdminUtils/recordError';
import _ from 'lodash';

type ModelGenerated = {
  offer_content_id?: number;
};

export type RestAbstractCard = {
  id: string;
  name: string;
  version: string;
  type: 'Card';
  deploy: string;
  order?: number;
} & ModelGenerated;

export type RestLossAversionCardOrModalCard =
  | RestModalCard
  | RestLossAversionCard;

export function isLossAversionCard(
  card: RestCard
): card is RestLossAversionCard {
  return (
    card.template === RestLossAversionCardTemplates.simple_chip ||
    card.template === RestLossAversionCardTemplates.simple_chip_images ||
    card.template === RestLossAversionCardTemplates.simple_team ||
    card.template === RestLossAversionCardTemplates.simple_list ||
    card.template === RestLossAversionCardTemplates.loss_simple_link ||
    card.template === RestLossAversionCardTemplates.simple_blurb ||
    card.template === RestLossAversionCardTemplates.la_one_up_image ||
    card.template === RestLossAversionCardTemplates.la_twitter_card
  );
}

export function isModalCard(card: RestCard): card is RestModalCard {
  return Object.keys(RestModalTemplates).includes(card.template);
}

export function isModalTemplate(
  template: RestModalTemplates | RestLossAversionCardTemplates
): template is RestModalTemplates {
  return Object.keys(RestModalTemplates).includes(template);
}

export function isFinalOfferCard(card: RestCard): card is RestModalFinalOffer {
  return RestModalTemplates.final_offer === card.template;
}

export const findPrimaryCard = (
  offerOrLossAversion: RestOffer | RestLossAversion
): RestLossAversionCardOrModalCard => {
  const pcName = offerOrLossAversion.primary_card_name;
  return (offerOrLossAversion.cards as RestLossAversionCardOrModalCard[]).find(
    (c) => c.name === pcName
  )!;
};

export function isLossAversion(
  offerOrLossAversion: RestOffer | RestLossAversion
): offerOrLossAversion is RestLossAversion {
  const primaryCard = findPrimaryCard(offerOrLossAversion);
  if (!primaryCard) {
    recordError(`Could not find primary card ${primaryCard}`);
    return false;
  }
  return isLossAversionCard(primaryCard);
}

export function isOffer(
  offerOrLossAversion: RestOffer | RestLossAversion
): offerOrLossAversion is RestOffer {
  const primaryCard = findPrimaryCard(offerOrLossAversion);
  if (!primaryCard) {
    recordError(`Could not find primary card ${primaryCard}`);
    return false;
  }
  return isModalCard(primaryCard);
}

export function isOfferCardPack(offer: RestOffer | NewOffer) {
  if (!offer) return false;
  const primaryCardIndex = offer.cards.findIndex(
    (c) => c.name === offer.primary_card_name
  );
  if (primaryCardIndex < 0) return false;
  const cardPackPath = ['cards', primaryCardIndex, 'requires', 'content_set'];
  return !!_.get(offer, cardPackPath);
}

export function isReasonOffer(
  offerOrLossAversion: RestOffer | RestLossAversion
): offerOrLossAversion is RestOffer {
  const card = offerOrLossAversion.cards[0];
  return (
    isModalCard(card as RestModalCard) &&
    !isFinalOfferCard(card as RestModalFinalOffer)
  );
}

export function isFinalOffer(offer: RestOffer): offer is RestOffer {
  return isFinalOfferCard(offer.cards[0] as RestModalCard);
}

export enum SurveyType {
  multi_row_survey = 'multi_row_survey',
  multi_tier_survey = 'multi_tier_survey',
}

export type RestMultiRowSurveyCard = ExperienceMultiRowSurveyCard;
