import { useCallback } from 'react';
import type { TypedLazyQueryTrigger } from '@reduxjs/toolkit/query/react';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';

type PollingResponse =
  | { status: 'pending' }
  | { status: 'running' }
  | {
      status: 'ready';
      download: string;
    }
  | { status: 'failed' }
  | { status: 'expired' };

export const pollingSlice = bbkApiSlice.injectEndpoints({
  endpoints: (build) => ({
    callPollingApi: build.query<
      PollingResponse,
      { url: string; method?: string; contentType?: string; body?: unknown }
    >({
      query: ({
        url,
        method = 'GET',
        body,
        contentType = 'application/json',
      }) => ({
        url,
        method,
        headers: {
          'Content-Type': contentType,
        },
        body: method === 'GET' ? undefined : body,
      }),
    }),
  }),
});

export const useCreatePollingRequest = <
  T extends TypedLazyQueryTrigger<{ statusURL: string }, any, any>,
>(
  fn: T
) => {
  const maxRequests = 100;
  const retryTimeout = 6000;
  const [callApi$] = pollingSlice.endpoints.callPollingApi.useLazyQuery();

  return useCallback(
    async (arg: Parameters<T>[0]) => {
      let requests = 0;

      const poll = async (url: string): Promise<string> => {
        const res = await callApi$({ url }).unwrap();
        switch (res.status) {
          case 'pending':
          case 'running': {
            if (requests === maxRequests) throw new Error('timeout');
            requests += 1;
            return new Promise((resolve) => {
              setTimeout(() => resolve(poll(url)), retryTimeout);
            });
          }
          case 'failed':
          case 'expired': {
            throw new Error(res.status);
          }
          case 'ready': {
            return res.download;
          }
          default: {
            throw new Error('unknown status');
          }
        }
      };
      return fn(arg)
        .unwrap()
        .then((res) => poll(res.statusURL));
    },
    [callApi$, fn, maxRequests, retryTimeout]
  );
};
