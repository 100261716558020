import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const InputLabel = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
`;

export const InputMessage = styled.div`
  line-height: 2rem;
  margin-left: 0.5rem;
  font-size: 14px;
`;

export const InputError: React.FC<React.ComponentProps<typeof InputMessage>> = (
  props
) => (
  <InputMessage
    css={css`
      color: rgba(251, 49, 93, 0.8);
    `}
    {...props}
  />
);

export const ImportantMessage: React.FC<
  React.ComponentProps<typeof InputMessage>
> = (props) => (
  <InputMessage
    css={css`
      display: inline;
      margin: 0;
      color: rgba(251, 49, 93, 0.8);
    `}
    {...props}
  />
);
