/* eslint-disable max-len */

export type CardLevelTheme = {
  useCustom: boolean;
  button: {
    textColor: React.CSSProperties['color'];
    backgroundColor: React.CSSProperties['backgroundColor'];
  };
  style: {
    textColor: React.CSSProperties['color'];
    backgroundColor: React.CSSProperties['backgroundColor'];
  };
};

export type EmotionTheme = {
  global: string;
  background: React.CSSProperties['backgroundColor'];
  cardBackground: string | undefined;
  modalBackground: string | undefined;
  fontFamily: React.CSSProperties['fontFamily'];
  fontSize: React.CSSProperties['fontSize'];
  fontWeight: React.CSSProperties['fontWeight'];
  primaryColor: React.CSSProperties['color'];
  textColor: React.CSSProperties['color'];
  modalTextColor: React.CSSProperties['color'];
  logoCenter: boolean;
  logoMaxWidth: number | undefined;
  headerBorder: React.CSSProperties['color'];
  headerFullWidth: boolean;
  buttonTextColor: React.CSSProperties['color'];
  buttonFontFamily: React.CSSProperties['fontFamily'];
  buttonTextTransform: React.CSSProperties['textTransform'];
  buttonBackgroundColor: React.CSSProperties['color'];
  buttonBorderRadius: number | undefined;
  buttonFontWeight: React.CSSProperties['fontWeight'];
  buttonFontSize: React.CSSProperties['fontSize'];
  borderRadius: number | undefined;
  boxShadow: React.CSSProperties['boxShadow'];
  border: React.CSSProperties['border'];
  textAreaBorderRadius: number | undefined;
  errorVal: React.CSSProperties['color'];
  titleFontFamily: React.CSSProperties['fontFamily'];
  titleFontSize: React.CSSProperties['fontSize'];
  titleFontWeight: React.CSSProperties['fontWeight'];
  titleFontColor: React.CSSProperties['color'];
  modalTitleFontColor: React.CSSProperties['color'];
  titleTextTransform: React.CSSProperties['textTransform'];
  submitButtonOrder: 'standard' | 'reversed' | undefined;
  submitButtonMode: 'light' | 'dark' | undefined;
  formRequiredLabel: string;
  formRequiredFieldError: string;
  surveyErrorBoxTitle: string;
  surveyErrorBoxContent: string;
  dictionaryNone: string;
  dictionaryOther: string;
  fontEmbedUrl?: string;
  cardLevelTheme?: CardLevelTheme;
  offerCardBadgeColors?: Array<React.CSSProperties['color']>;
  offerCardBadgeTextColors?: Array<React.CSSProperties['color']>;
};

export enum ThemeEnums {
  BACKGROUND = 'BACKGROUND',
  CARD_BACKGROUND = 'CARD_BACKGROUND',
  MODAL_BACKGROUND = 'MODAL_BACKGROUND',
  FONT_FAMILY = 'FONT_FAMILY',
  FONT_EMBED_URL = 'FONT_EMBED_URL',
  FONT_SIZE = 'FONT_SIZE',
  FONT_WEIGHT = 'FONT_WEIGHT',
  PRIMARY_COLOR = 'PRIMARY_COLOR',
  TEXT_COLOR = 'TEXT_COLOR',
  MODAL_TEXT_COLOR = 'MODAL_TEXT_COLOR',
  BUTTON_TEXT_COLOR = 'BUTTON_TEXT_COLOR',
  BUTTON_FONT_FAMILY = 'BUTTON_FONT_FAMILY',
  BUTTON_TEXT_TRANSFORM = 'BUTTON_TEXT_TRANSFORM',
  BUTTON_BACKGROUND_COLOR = 'BUTTON_BACKGROUND_COLOR',
  BUTTON_BORDER_RADIUS = 'BUTTON_BORDER_RADIUS',
  BUTTON_FONT_WEIGHT = 'BUTTON_FONT_WEIGHT',
  BUTTON_FONT_SIZE = 'BUTTON_FONT_SIZE',
  BORDER_RADIUS = 'BORDER_RADIUS',
  BOX_SHADOW = 'BOX_SHADOW',
  BORDER = 'BORDER',
  TEXT_AREA_BORDER_RADIUS = 'TEXT_AREA_BORDER_RADIUS',
  ERROR_VAL = 'ERROR_VAL',
  GLOBAL_CSS = 'GLOBAL_CSS',
  LOGO_CENTER = 'LOGO_CENTER',
  LOGO_MAX_WIDTH = 'LOGO_MAX_WIDTH',
  HEADER_BORDER = 'HEADER_BORDER',
  HEADER_FULL_WIDTH = 'HEADER_FULL_WIDTH',
  TITLE_FONT_FAMILY = 'TITLE_FONT_FAMILY',
  TITLE_FONT_SIZE = 'TITLE_FONT_SIZE',
  TITLE_FONT_WEIGHT = 'TITLE_FONT_WEIGHT',
  TITLE_FONT_COLOR = 'TITLE_FONT_COLOR',
  MODAL_TITLE_FONT_COLOR = 'MODAL_TITLE_FONT_COLOR',
  TITLE_TEXT_TRANSFORM = 'TITLE_TEXT_TRANSFORM',
  SUBMIT_BUTTON_ORDER = 'SUBMIT_BUTTON_ORDER',
  SUBMIT_BUTTON_MODE = 'SUBMIT_BUTTON_MODE',
  FORM_REQUIRED_LABEL = 'FORM_REQUIRED_LABEL',
  FORM_REQUIRED_FIELD_ERROR = 'FORM_REQUIRED_FIELD_ERROR',
  SURVEY_ERROR_BOX_TITLE = 'SURVEY_ERROR_BOX_TITLE',
  SURVEY_ERROR_BOX_CONTENT = 'SURVEY_ERROR_BOX_CONTENT',
  DICTIONARY_NONE = 'DICTIONARY_NONE',
  DICTIONARY_OTHER = 'DICTIONARY_OTHER',
  OFFER_CARD_BADGE_COLORS = 'OFFER_CARD_BADGE_COLORS',
  OFFER_CARD_BADGE_TEXT_COLORS = 'OFFER_CARD_BADGE_TEXT_COLORS',
}

export enum ThemeKeys {
  BACKGROUND = 'background',
  CARD_BACKGROUND = 'cardBackground',
  MODAL_BACKGROUND = 'modalBackground',
  FONT_FAMILY = 'fontFamily',
  FONT_EMBED_URL = 'fontEmbedUrl',
  FONT_SIZE = 'fontSize',
  FONT_WEIGHT = 'fontWeight',
  PRIMARY_COLOR = 'primaryColor',
  TEXT_COLOR = 'textColor',
  MODAL_TEXT_COLOR = 'modalTextColor',
  BUTTON_TEXT_COLOR = 'buttonTextColor',
  BUTTON_FONT_FAMILY = 'buttonFontFamily',
  BUTTON_TEXT_TRANSFORM = 'buttonTextTransform',
  BUTTON_BORDER_RADIUS = 'buttonBorderRadius',
  BUTTON_FONT_WEIGHT = 'buttonFontWeight',
  BUTTON_FONT_SIZE = 'buttonFontSize',
  BUTTON_BACKGROUND_COLOR = 'buttonBackgroundColor',
  BORDER_RADIUS = 'borderRadius',
  BOX_SHADOW = 'boxShadow',
  BORDER = 'border',
  TEXT_AREA_BORDER_RADIUS = 'textAreaBorderRadius',
  ERROR_VAL = 'errorVal',
  GLOBAL_CSS = 'global',
  LOGO_CENTER = 'logoCenter',
  LOGO_MAX_WIDTH = 'logoMaxWidth',
  HEADER_BORDER = 'headerBorder',
  HEADER_FULL_WIDTH = 'headerFullWidth',
  TITLE_FONT_FAMILY = 'titleFontFamily',
  TITLE_FONT_SIZE = 'titleFontSize',
  TITLE_FONT_WEIGHT = 'titleFontWeight',
  TITLE_FONT_COLOR = 'titleFontColor',
  MODAL_TITLE_FONT_COLOR = 'modalTitleFontColor',
  TITLE_TEXT_TRANSFORM = 'titleTextTransform',
  SUBMIT_BUTTON_ORDER = 'submitButtonOrder',
  SUBMIT_BUTTON_MODE = 'submitButtonMode',
  FORM_REQUIRED_LABEL = 'formRequiredLabel',
  FORM_REQUIRED_FIELD_ERROR = 'formRequiredFieldError',
  SURVEY_ERROR_BOX_TITLE = 'surveyErrorBoxTitle',
  SURVEY_ERROR_BOX_CONTENT = 'surveyErrorBoxContent',
  DICTIONARY_NONE = 'dictionaryNone',
  DICTIONARY_OTHER = 'dictionaryOther',
  CARD_LEVEL_THEME = 'cardLevelTheme',
  OFFER_CARD_BADGE_COLORS = 'offerCardBadgeColors',
  OFFER_CARD_BADGE_TEXT_COLORS = 'offerCardBadgeTextColors',
}

// Map into the 'style_overlay' object of an experience
export enum ThemePaths {
  BACKGROUND = 'bodyBackground',
  CARD_BACKGROUND = 'cardBackground',
  MODAL_BACKGROUND = 'modalBackground',
  FONT_FAMILY = 'font.fontFamily',
  FONT_EMBED_URL = 'font.href',
  FONT_SIZE = 'font.baseFontSize',
  FONT_WEIGHT = 'font.baseFontWeight',
  PRIMARY_COLOR = 'primaryColor',
  TEXT_COLOR = 'textColor',
  MODAL_TEXT_COLOR = 'modalTextColor',
  BUTTON_TEXT_COLOR = 'buttonTextColor',
  BUTTON_FONT_FAMILY = 'buttonFontFamily',
  BUTTON_TEXT_TRANSFORM = 'buttonTextTransform',
  BUTTON_BACKGROUND_COLOR = 'buttonBackgroundColor',
  BUTTON_BORDER_RADIUS = 'buttonBorderRadius',
  BUTTON_FONT_WEIGHT = 'buttonFontWeight',
  BUTTON_FONT_SIZE = 'buttonFontSize',
  BORDER_RADIUS = 'borderRadius',
  BOX_SHADOW = 'dropShadow',
  BORDER = 'border',
  TEXT_AREA_BORDER_RADIUS = 'textAreaBorderRadius',
  ERROR_VAL = 'errorVal',
  GLOBAL_CSS = 'global',
  LOGO_CENTER = 'logoCenter',
  LOGO_MAX_WIDTH = 'logoMaxWidth',
  HEADER_BORDER = 'headerBorder',
  HEADER_FULL_WIDTH = 'headerFullWidth',
  TITLE_FONT_FAMILY = 'titleFontFamily',
  TITLE_FONT_SIZE = 'titleFontSize',
  TITLE_FONT_WEIGHT = 'titleFontWeight',
  TITLE_FONT_COLOR = 'titleFontColor',
  TITLE_TEXT_TRANSFORM = 'titleTextTransform',
  MODAL_TITLE_FONT_COLOR = 'modalTitleFontColor',
  SUBMIT_BUTTON_ORDER = 'submitButtonOrder',
  SUBMIT_BUTTON_MODE = 'submitButtonMode',
  FORM_REQUIRED_LABEL = 'formRequiredLabel',
  FORM_REQUIRED_FIELD_ERROR = 'formRequiredFieldError',
  SURVEY_ERROR_BOX_TITLE = 'surveyErrorBoxTitle',
  SURVEY_ERROR_BOX_CONTENT = 'surveyErrorBoxContent',
  DICTIONARY_NONE = 'dictionaryNone',
  DICTIONARY_OTHER = 'dictionaryOther',
  CARD_LEVEL_THEME = 'cardLevelTheme',
  OFFER_CARD_BADGE_COLORS = 'offerCardBadgeColors',
  OFFER_CARD_BADGE_TEXT_COLORS = 'offerCardBadgeTextColors',
}

export const ThemeDefaults = {
  BACKGROUND: '#F4F5F5',
  CARD_BACKGROUND: '#FFFFFF',
  MODAL_BACKGROUND: '#FFFFFF',
  FONT_FAMILY:
    "-apple-system, system-ui, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
  FONT_EMBED_URL: '',
  FONT_SIZE: '18px',
  FONT_WEIGHT: 'normal',
  PRIMARY_COLOR: '#582ea9',
  TEXT_COLOR: '#5E5E5E',
  MODAL_TEXT_COLOR: undefined,
  BUTTON_FONT_FAMILY: undefined,
  BUTTON_TEXT_COLOR: '#fff',
  BUTTON_TEXT_TRANSFORM: 'none' as React.CSSProperties['textTransform'],
  BUTTON_BACKGROUND_COLOR: undefined,
  BUTTON_BORDER_RADIUS: undefined,
  BUTTON_FONT_WEIGHT: 'bold',
  BUTTON_FONT_SIZE: undefined,
  BORDER_RADIUS: 3,
  BOX_SHADOW: '0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)',
  BORDER: undefined,
  TEXT_AREA_BORDER_RADIUS: 3,
  ERROR_VAL: '#D0021B',
  GLOBAL_CSS:
    '.funnel-button.funnel-button-global { /* custom button styles */ }',
  LOGO_CENTER: false,
  LOGO_MAX_WIDTH: undefined,
  HEADER_BORDER: undefined,
  HEADER_FULL_WIDTH: false,
  TITLE_FONT_FAMILY: undefined,
  TITLE_FONT_SIZE: '24px',
  TITLE_FONT_WEIGHT: 'bold',
  TITLE_FONT_COLOR: undefined,
  MODAL_TITLE_FONT_COLOR: undefined,
  TITLE_TEXT_TRANSFORM: 'none' as React.CSSProperties['textTransform'],
  SUBMIT_BUTTON_ORDER: 'standard' as 'standard' | 'reversed' | undefined,
  SUBMIT_BUTTON_MODE: 'light' as 'light' | 'dark' | undefined,
  FORM_REQUIRED_LABEL: 'REQUIRED',
  FORM_REQUIRED_FIELD_ERROR: 'This question is required.',
  SURVEY_ERROR_BOX_TITLE: 'There was an error.',
  SURVEY_ERROR_BOX_CONTENT: 'Please fill in all required fields.',
  DICTIONARY_NONE: 'None',
  DICTIONARY_OTHER: 'Other',
  OFFER_CARD_BADGE_COLORS: ['#062531', '#FF3300', '#111827'],
  OFFER_CARD_BADGE_TEXT_COLORS: ['#FFFFFF', '#FFFFFF', '#FFFFFF'],
};
