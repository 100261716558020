import { BbkExpressionElement } from './components/BbkExpressionElement';

export const ELEMENT_BBK_EXPRESSION = 'bbk-expression';

export const DEFAULTS_BBK_EXPRESSION = {
  bbkExpression: {
    component: BbkExpressionElement,
    type: ELEMENT_BBK_EXPRESSION,
    rootProps: {
      className: 'slate-bbk-expression',
      prefix: '@',
    },
  },
};
