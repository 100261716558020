// @ts-nocheck
/* eslint-disable max-len,no-template-curly-in-string */
import { DestinationType } from '@bbkAdminRedux/rtkq/experience.slice';
import {
  RestModalAction,
  RestModalTemplates,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import {
  OfferCategory,
  type RestOffer,
} from '@bbkAdminUtils/api-client/rest-offers-api';

export const categoryTemplates: RestOffer[] = [
  {
    id: 'Dx8r24b8XZ',
    active: false,
    display_name: 'Chat',
    internal_name: 'chat.1747395092',
    type: 'chat',
    category: 'support',
    published_at: '2020-09-16T23:31:07.180Z',
    primary_card_name:
      'chat.forty_sixty_mini_modal_2.55b7e70e-2e36-41cd-bc0c-544b36e919a6',
    cards: [
      {
        type: 'Card',
        id: '55b7e70e-2e36-41cd-bc0c-544b36e919a6',
        name: 'chat.forty_sixty_mini_modal_2.55b7e70e-2e36-41cd-bc0c-544b36e919a6',
        version: '22021',
        deploy: 'modal',
        template: 'forty_sixty_mini_modal_2',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'composeEmail',
            text: 'View Roadmap',
            action: 'modal_email',
            sync: {
              form_submit: true,
            },
            icon: 'bbk-esi-road',
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/ff71fd65-f515-4a6c-a44c-3bab658126aa',
            name: 'Carl',
            title: 'Product Manager',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            title: '<div class="slate-p">Chat with an Agent</div>',
            details:
              '<div class="slate-p">Our team is here to help solve any of your needs. Start a chat with an agent to get quick resolutions to your questions. </div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-09-16T23:25:49.496Z',
    updated_at: '2022-06-01T22:22:55.566Z',
  },
  {
    id: '5xKgLwzlQe',
    active: false,
    display_name: 'Customer Service Feedback',
    internal_name: 'video_paused_skipped_cf57f3.513532056',
    type: 'customer_service',
    category: 'customer_feedback',
    published_at: '2020-09-23T10:57:59.405Z',
    primary_card_name: 'modal_video_paused_skipped_c',
    cards: [
      {
        type: 'Card',
        id: 'd3cc69b3-0024-4715-8515-44c8290e923',
        name: 'modal_video_paused_skipped_c',
        version: '22212',
        deploy: 'modal',
        template: 'speech_bubble_2',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Help Jen Improve',
            action: 'modal_email',
            url: 'support@brightback.com',
            modal:
              'customer_service_feedback.modal_email_send.e92c943f-e263-4534-a3b1-fc03acb2cca8',
            validation:
              'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
            sync: {},
            icon: 'none',
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/60d329b5-89b1-4011-8e7f-94b903be153f.png',
            name: 'Jen',
            title: 'Product Manager',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            title: '<div>Help me Improve my Service!</div>',
            details:
              '<div class="slate-p"> We pride ourselves on exceptional customer service and would appreciate any feedback on how we can improve!</div>',
          },
        },
        provides: {},
      },
      {
        type: 'Card',
        id: 'e92c943f-e263-4534-a3b1-fc03acb2cca8',
        name: 'customer_service_feedback.modal_email_send.e92c943f-e263-4534-a3b1-fc03acb2cca8',
        version: '22212',
        deploy: 'modal',
        template: 'modal_email_send',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Send message',
            action: 'send_email',
            url: 'support@brightback.com',
            modal:
              'customer_service_feedback.modal_email_success.e5d4a847-3134-4990-8e4f-247927dd079b',
            validation:
              'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
            sync: {},
          },
          info: {
            title: 'Send message',
            placeholder: 'Start typing...',
          },
        },
        provides: {},
      },
      {
        type: 'Card',
        id: 'e5d4a847-3134-4990-8e4f-247927dd079b',
        name: 'customer_service_feedback.modal_email_success.e5d4a847-3134-4990-8e4f-247927dd079b',
        version: '22212',
        deploy: 'modal',
        template: 'modal_email_success',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Got it! Return to app.',
            action: 'link',
            url: "${default(value('standard.Save return Url'), 'https://www.brightback.com/')}",
            validation:
              '9d54e171cfa83874a47bc694f6990181552cb65e2fae00891d7af121423ef9c5',
            sync: {},
            cancelText: 'Continue to cancel',
            subText: "(I'll wait for a response before canceling.)",
          },
          success: {
            title: 'Before you go...',
            details:
              'We would love to have the opportunity to address your feedback. Please give us a few hours to respond to your message. Thanks for your patience!',
          },
          hideCancel: false,
          info: {
            title: 'Message sent',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:20.974Z',
    updated_at: '2022-06-01T22:23:07.414Z',
  },
  {
    id: 'Ka6ywqxg57',
    active: false,
    display_name: 'Free Credit',
    internal_name: 'video_paused_skipped64fa22.57660808',
    type: 'discount_free_credits',
    category: 'discount',
    published_at: '2020-09-23T11:06:01.958Z',
    primary_card_name: 'modal_video_paused_skipped6',
    cards: [
      {
        type: 'Card',
        id: '38601f32-ced1-443d-b564-96db140f00a7',
        name: 'modal_video_paused_skipped6',
        version: '22217',
        deploy: 'modal',
        template: 'speech_bubble_2',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'button',
            text: 'Claim $x in Credits',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {
              form_submit: true,
            },
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/21789d6c-38ff-4593-8a68-20316002aab2.png',
            name: 'Jen',
            title: 'Product Manager',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            details:
              '<div class="slate-p">As a loyal customer, we have a special offer just for you! We will credit your account with $x Brightback Credits to be used at your disposal! </div>',
            title: '<div class="slate-p">$x in Brightback Credits!</div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:20.949Z',
    updated_at: '2022-06-01T22:24:58.563Z',
  },
  {
    id: 'A9ngxjQyLk',
    active: false,
    display_name: '$ Discount',
    internal_name: 'sixty_forty_column_comp451b6.1788919501',
    type: 'dollar',
    category: 'discount',
    published_at: '2020-09-18T16:18:28.110Z',
    primary_card_name: 'sixty_forty_column_comp',
    cards: [
      {
        type: 'Card',
        id: '7b97cf2a-42dd-4be4-b3d5-23a4713f8b81',
        name: 'sixty_forty_column_comp',
        version: '22135',
        deploy: 'modal',
        template: 'sixty_forty_column',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'button',
            text: 'Claim $x Discount',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {
              form_submit: true,
            },
            icon: 'none',
          },
          hero: {
            show: 'image',
            url: 'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
          },
          info: {
            title: '<div class="slate-p">How about $x off?</div>',
            details:
              '<div class="slate-p">As a long time customer, we want to give you a $x discount on your next order!</div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:21.170Z',
    updated_at: '2022-06-01T22:24:27.919Z',
  },
  {
    id: 'A9ngxjQyLk',
    active: true,
    display_name: '$ Offer Card Pack',
    internal_name: 'offer_card_pack.1788919501',
    type: 'offer_pack',
    category: 'offer_pack',
    published_at: '2020-09-18T16:18:28.110Z',
    primary_card_name: 'offer_card_pack_1',
    cards: [
      {
        type: 'Card',
        id: '7b97cf2a-42dd-4be4-b3d5-23a4713f8b81',
        name: 'offer_card_pack_1',
        version: '22135',
        deploy: 'modal',
        template: 'offer_card_pack',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'button',
            text: 'Claim $x Discount',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {
              form_submit: true,
            },
            icon: 'none',
          }
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:21.170Z',
    updated_at: '2022-06-01T22:24:27.919Z',
  },
  {
    id: 'M7lab7agPW',
    active: false,
    display_name: 'Downgrade',
    internal_name: 'downgrade.1622078831',
    type: 'downgrade',
    category: 'plan_change',
    published_at: '2020-09-16T20:54:52.577Z',
    primary_card_name:
      'downgrade.sixty_forty_column.7813d8c7-3e46-4cb3-adb3-11afef968c6e',
    cards: [
      {
        type: 'Card',
        id: '7813d8c7-3e46-4cb3-adb3-11afef968c6e',
        name: 'downgrade.sixty_forty_column.7813d8c7-3e46-4cb3-adb3-11afef968c6e',
        version: '22007',
        deploy: 'modal',
        template: 'sixty_forty_column',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Downgrade Now',
            action: 'modal_email',
            url: 'support@brightback.com',
            modal:
              'downgrade.modal_email_send.8c4f7872-c9ff-4a58-aed3-90076ed50cd1',
            validation:
              'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
            sync: {},
            icon: 'none',
          },
          hero: {
            show: 'image',
            url: 'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
          },
          info: {
            title: '<div class="slate-p">Try our Essentials Plan! </div>',
            details:
              '<div class="slate-p">Not everyone needs the Performance Plan. Try downgrading to our Essentials Tier so that you can keep saving customers! </div>',
          },
        },
        provides: {},
      },
      {
        type: 'Card',
        id: '8c4f7872-c9ff-4a58-aed3-90076ed50cd1',
        name: 'downgrade.modal_email_send.8c4f7872-c9ff-4a58-aed3-90076ed50cd1',
        version: '22007',
        deploy: 'modal',
        template: 'modal_email_send',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Send message',
            action: 'send_email',
            url: 'support@brightback.com',
            modal:
              'downgrade.modal_email_success.68727ad9-d1a2-4c7b-8b84-d4f798224b4f',
            validation:
              'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
            sync: {},
          },
          info: {
            title: 'Send message',
            placeholder: 'Start typing...',
          },
        },
        provides: {},
      },
      {
        type: 'Card',
        id: '68727ad9-d1a2-4c7b-8b84-d4f798224b4f',
        name: 'downgrade.modal_email_success.68727ad9-d1a2-4c7b-8b84-d4f798224b4f',
        version: '22007',
        deploy: 'modal',
        template: 'modal_email_success',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Got it! Return to app.',
            action: 'link',
            url: "${default(value('standard.Save return Url'), 'https://www.brightback.com/')}",
            validation:
              '9d54e171cfa83874a47bc694f6990181552cb65e2fae00891d7af121423ef9c5',
            sync: {},
            cancelText: 'Continue to cancel',
            subText: "(I'll wait for a response before canceling.)",
          },
          success: {
            title: 'Before you go...',
            details:
              'We would love to have the opportunity to address your feedback. Please give us a few hours to respond to your message. Thanks for your patience!',
          },
          hideCancel: false,
          info: {
            title: 'Message sent',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-09-16T20:52:20.747Z',
    updated_at: '2022-06-01T22:24:42.986Z',
  },
  {
    id: 'GZk805KyBN',
    active: false,
    display_name: 'Renewal Extension',
    internal_name: 'forty_sixty_mini_130d496.1886651656',
    type: 'extend_renewal_dates',
    category: 'extension',
    published_at: '2021-07-02T19:29:49.114Z',
    primary_card_name: 'forty_sixty_mini_modal_13',
    cards: [
      {
        type: 'Card',
        id: '312338b7-ceb9-48e3-bf23-fe2d97e38585',
        name: 'forty_sixty_mini_modal_13',
        version: '39354',
        deploy: 'modal',
        template: 'forty_sixty_mini_modal_1',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Extend Renewal Date',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {},
            icon: 'none',
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/680e5b98-e293-4dac-9604-e4ce23977b80.jpg',
            name: 'Frank',
            title: 'CEO',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            details:
              '<div class="slate-p">As a valued customer, we will extend your renewal date for a month to give you time to decide!</div>',
            title: 'Extend Renewal Date by One Month',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:20.333Z',
    updated_at: '2022-06-01T22:25:34.519Z',
  },
  {
    id: 'avdyjRwyLD',
    active: true,
    display_name: 'Free Month',
    internal_name: 'sixty_forty_column211b4f.1562060650',
    type: 'free_months',
    category: 'extension',
    published_at: '2020-09-16T20:44:02.322Z',
    primary_card_name: 'sixty_forty_column2',
    cards: [
      {
        type: 'Card',
        id: '5aa09db6-70ba-404b-a16a-d5a1b4a2caba',
        name: 'sixty_forty_column2',
        version: '22001',
        deploy: 'modal',
        template: 'sixty_forty_column',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'button',
            text: 'Free Month',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {
              form_submit: true,
            },
            icon: 'none',
          },
          hero: {
            show: 'image',
            url: 'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
          },
          info: {
            title: '<div class="slate-p">Free Month on Us?</div>',
            details:
              '<div class="slate-p">We know you didn\'t get a chance to try Brightback fully. How about a free month on us? </div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:21.072Z',
    updated_at: '2021-06-02T17:21:19.152Z',
  },
  {
    id: '47gZ213gPm',
    active: false,
    display_name: 'Suspend Payment',
    internal_name: 'suspend_payment.1239400049',
    type: 'free_months',
    category: 'extension',
    published_at: '2021-09-23T15:32:45.180Z',
    primary_card_name:
      'suspend_payment.forty_sixty_mini_modal_1.cdf1a5e1-176b-44b3-a9bb-7a170986e21d',
    cards: [
      {
        type: 'Card',
        id: 'cdf1a5e1-176b-44b3-a9bb-7a170986e21d',
        name: 'suspend_payment.forty_sixty_mini_modal_1.cdf1a5e1-176b-44b3-a9bb-7a170986e21d',
        version: '47132',
        deploy: 'modal',
        template: 'forty_sixty_mini_modal_1',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Suspend Payment',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {},
            icon: 'none',
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/e84abec5-98e8-4d27-9944-75cca12b1e21.jpg',
            name: 'Jordy',
            title: 'CSM',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            details:
              '<div class="slate-p">Need some more time? That\'s ok, we will suspend your payment for x weeks/months and your service will continue to work.</div>',
            title: 'Suspend Payment for x Weeks/Months',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-09-18T16:26:09.003Z',
    updated_at: '2022-06-01T22:38:14.590Z',
  },
  {
    id: 'WMlBDZb8Nm',
    active: false,
    display_name: 'Trial Extension',
    internal_name: 'trial_extension.495889569',
    type: 'free_months',
    category: 'extension',
    published_at: '2021-09-23T15:34:17.806Z',
    primary_card_name:
      'trial_extension.forty_sixty_mini_modal_1.f23c15e7-dbfb-4be2-8a19-c1a0a40a8238',
    cards: [
      {
        type: 'Card',
        id: 'f23c15e7-dbfb-4be2-8a19-c1a0a40a8238',
        name: 'trial_extension.forty_sixty_mini_modal_1.f23c15e7-dbfb-4be2-8a19-c1a0a40a8238',
        version: '47134',
        deploy: 'modal',
        template: 'forty_sixty_mini_modal_1',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Extend Trial',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {},
            icon: 'none',
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/8985cec9-5e11-44fe-ae4b-80b73cd2648f.jpg',
            name: 'Nick',
            title: 'CSM',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            details:
              '<div class="slate-p">Need some more time to figure out Brightback? That\'s ok, we will extend your trial for x days! </div>',
            title: 'Extend Trial for x Days',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-09-16T20:44:33.122Z',
    updated_at: '2022-06-01T22:25:48.811Z',
  },
  {
    id: '2xl36BOyJZ',
    active: false,
    display_name: 'Free Training',
    internal_name: 'new_content.1447689987',
    type: 'free_training',
    category: 'support',
    published_at: '2021-07-02T19:28:29.861Z',
    primary_card_name:
      'new_content.forty_sixty_mini_modal_2.e6ec86b6-af82-48f8-b286-ae45ac02fe5d',
    cards: [
      {
        type: 'Card',
        id: 'e6ec86b6-af82-48f8-b286-ae45ac02fe5d',
        name: 'new_content.forty_sixty_mini_modal_2.e6ec86b6-af82-48f8-b286-ae45ac02fe5d',
        version: '39353',
        deploy: 'modal',
        template: 'forty_sixty_mini_modal_2',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'composeEmail',
            text: 'Book Training',
            action: 'modal_email',
            sync: {
              form_submit: true,
            },
            icon: 'none',
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/06409779-5d4e-4f87-bf9d-52bf213d5cf8.jpg',
            name: 'Frank',
            title: 'CFO',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            title: '<div class="slate-p">Free Training Session</div>',
            details:
              '<div class="slate-p">We have a free training session you can attend to learn how to use our product! </div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-09-16T23:18:59.830Z',
    updated_at: '2022-06-01T22:26:07.265Z',
  },
  {
    id: 'jxLgqNr8DK',
    active: true,
    display_name: 'General Feedback',
    internal_name: 'sixty_forty_column14ffaa.1278111302',
    type: 'general_feedback',
    category: 'customer_feedback',
    published_at: '2020-09-23T10:59:17.137Z',
    primary_card_name: 'sixty_forty_column1',
    cards: [
      {
        type: 'Card',
        id: '388159ff-fa2b-4c3f-82e3-5ec05ac7f864',
        name: 'sixty_forty_column1',
        version: '22213',
        deploy: 'modal',
        template: 'sixty_forty_column',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Send message',
            action: 'modal_email',
            url: 'support@brightback.com',
            modal:
              'general_feedback.modal_email_send.d3499b65-a570-413c-84b9-9d48cd78d6b0',
            validation:
              'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
            sync: {},
            icon: 'none',
          },
          hero: {
            show: 'image',
            url: 'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
          },
          info: {
            title: '<div>Help us Improve!&nbsp;</div>',
            details:
              '<div class="slate-p">We are always looking for ways to improve our product or service for future customers. Can you take a minute to share some feedback? </div>',
          },
        },
        provides: {},
      },
      {
        type: 'Card',
        id: 'd3499b65-a570-413c-84b9-9d48cd78d6b0',
        name: 'general_feedback.modal_email_send.d3499b65-a570-413c-84b9-9d48cd78d6b0',
        version: '22213',
        deploy: 'modal',
        template: 'modal_email_send',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Send message',
            action: 'send_email',
            url: 'support@brightback.com',
            modal:
              'general_feedback.modal_email_success.f5e7adf1-3c32-4ee7-a32e-06f671cb8d0c',
            validation:
              'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
            sync: {},
          },
          info: {
            title: 'Send message',
            placeholder: 'Start typing...',
          },
        },
        provides: {},
      },
      {
        type: 'Card',
        id: 'f5e7adf1-3c32-4ee7-a32e-06f671cb8d0c',
        name: 'general_feedback.modal_email_success.f5e7adf1-3c32-4ee7-a32e-06f671cb8d0c',
        version: '22213',
        deploy: 'modal',
        template: 'modal_email_success',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Got it! Return to app.',
            action: 'link',
            url: "${default(value('standard.Save return Url'), 'https://www.brightback.com/')}",
            validation:
              '9d54e171cfa83874a47bc694f6990181552cb65e2fae00891d7af121423ef9c5',
            sync: {},
            cancelText: 'Continue to cancel',
            subText: "(I'll wait for a response before canceling.)",
          },
          success: {
            title: 'Before you go...',
            details:
              'We would love to have the opportunity to address your feedback. Please give us a few hours to respond to your message. Thanks for your patience!',
          },
          hideCancel: false,
          info: {
            title: 'Message sent',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:21.048Z',
    updated_at: '2022-06-01T22:26:24.530Z',
  },
  {
    id: 'MvbydVnge1',
    active: false,
    display_name: 'KB Article',
    internal_name: 'sixty_forty_column4cf589.349049536',
    type: 'kb_article',
    category: 'support',
    published_at: '2020-09-18T16:21:10.286Z',
    primary_card_name: 'sixty_forty_column4',
    cards: [
      {
        type: 'Card',
        id: '58be0862-873e-445c-9986-e8523e721659',
        name: 'sixty_forty_column4',
        version: '22139',
        deploy: 'modal',
        template: 'sixty_forty_column',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'button',
            text: 'View Docs',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {
              form_submit: true,
            },
            icon: 'none',
          },
          hero: {
            show: 'image',
            url: 'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
          },
          info: {
            title: '<div class="slate-p">View Support Guide</div>',
            details:
              '<div class="slate-p">Setting up the product can be difficult, view our docs to help get you on your way.</div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:21.120Z',
    updated_at: '2021-05-25T18:52:22.022Z',
  },
  {
    id: 'AJlmakJlGo',
    active: false,
    display_name: 'Onboarding',
    internal_name: 'send_email.1178169664',
    type: 'onboarding',
    category: 'support',
    published_at: '2020-09-16T23:35:28.444Z',
    primary_card_name:
      'send_email.forty_sixty_mini_modal_2.e2858653-4024-42d9-9396-3368e97adc5f',
    cards: [
      {
        type: 'Card',
        id: 'e2858653-4024-42d9-9396-3368e97adc5f',
        name: 'send_email.forty_sixty_mini_modal_2.e2858653-4024-42d9-9396-3368e97adc5f',
        version: '22024',
        deploy: 'modal',
        template: 'forty_sixty_mini_modal_2',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'composeEmail',
            text: 'Schedule Time',
            action: 'modal_email',
            sync: {
              form_submit: true,
            },
            icon: 'none',
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/ff71fd65-f515-4a6c-a44c-3bab658126aa',
            name: 'Carl',
            title: 'Product Manager',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            title: '<div class="slate-p">Free Onboarding Session</div>',
            details:
              '<div class="slate-p">We know it can be difficult to get started on Brightback. Our Implementation Specialists are here to help you get a jump start! </div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-09-16T23:21:03.701Z',
    updated_at: '2022-06-01T22:34:58.485Z',
  },
  {
    id: '5WKgY2284A',
    active: false,
    display_name: 'One Month Pause',
    internal_name: 'forty_sixty_mini_15fa891.581521447',
    type: 'pause_1_month',
    category: 'pause',
    published_at: '2021-06-28T18:39:15.377Z',
    primary_card_name: 'forty_sixty_mini_modal_15',
    cards: [
      {
        type: 'Card',
        id: '159708f0-9aba-4ab7-beed-3db942577ab5',
        name: 'forty_sixty_mini_modal_15',
        version: '39100',
        deploy: 'modal',
        template: 'forty_sixty_mini_modal_1',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Pause Plan',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {},
            icon: 'bbk-esi-phone',
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/cbd0a9ed-488f-482a-8bf4-0b3eb23ebeca.jpg',
            name: 'Keith',
            title: 'CSM',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            title: '<div class="slate-p">Let\'s Pause Your Plan!</div>',
            details:
              '<div>We can sort this out and get you back on track. In the mean time, lets pause your plan for a month while we get you setup!&nbsp;</div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:20.392Z',
    updated_at: '2022-06-01T22:35:38.949Z',
  },
  {
    id: 'MrLlX4alB6',
    active: false,
    display_name: 'Two Month Pause',
    internal_name: 'video_paused_skippedbeae7.1250206382',
    type: 'pause_2_months',
    category: 'pause',
    published_at: '2021-09-23T15:09:34.514Z',
    primary_card_name: 'modal_video_paused_skipped',
    cards: [
      {
        type: 'Card',
        id: 'd3cc69b3-0024-4715-8515-44c8290e943d',
        name: 'modal_video_paused_skipped',
        version: '47128',
        deploy: 'modal',
        template: 'speech_bubble_2',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'button',
            text: 'Pause for Two Months',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {
              form_submit: true,
            },
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/6f7cc4fe-41fa-40b4-a4af-b938463b3e0a.jpg',
            name: 'Nick',
            title: 'CSM',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            title:
              '<div class="slate-p">Need a Break? Pause for Two Months</div>',
            details:
              '<div class="slate-p">Take a few months to sort  things out and we will restart your plan when you are ready to return! </div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:20.844Z',
    updated_at: '2022-06-01T22:35:28.602Z',
  },
  {
    id: '47gZkwv8Pm',
    active: false,
    display_name: 'Multi-CTA Pause',
    internal_name: 'multiselect_pause.789687505',
    type: 'pause_3_months',
    category: 'pause',
    published_at: '2021-02-09T20:19:27.318Z',
    primary_card_name:
      'multi_select_pause.multi_cta_type_II.f3a40c43-5649-405c-a5a1-ea9ab82a3cea',
    cards: [
      {
        type: 'Card',
        id: 'f3a40c43-5649-405c-a5a1-ea9ab82a3cea',
        name: 'multi_select_pause.multi_cta_type_II.f3a40c43-5649-405c-a5a1-ea9ab82a3cea',
        version: '29801',
        deploy: 'modal',
        template: 'multi_cta_type_II',
        requires: {
          generic_actions: [
            {
              destination_type: 'url',
              text: 'Pause for One Month',
              action: 'link',
              url: 'https://www.brightback.com',
              validation: '123',
              sync: {},
            },
            {
              destination_type: 'url',
              text: 'Pause for Two Months',
              action: 'link',
              url: 'https://www.brightback.com',
              validation: '123',
              sync: {},
            },
            {
              destination_type: 'url',
              text: 'Pause for Three Months',
              action: 'link',
              url: 'https://www.brightback.com',
              validation: '123',
              sync: {},
            },
          ],
          info: {
            title: '<div class="slate-p">Pause For Up To Three Months</div>',
            details:
              '<div class="slate-p">We understand your needs can change. Did you know you can <strong class="slate-bold">pause your plan</strong> instead of canceling? Pause for as long as you like. We\'ll be here for you when you are ready to come back. </div>',
            image:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2021-02-09T20:03:28.345Z',
    updated_at: '2022-06-01T22:37:48.791Z',
  },
  {
    id: '94e860MlVA',
    active: false,
    display_name: 'Three Month Pause',
    internal_name: 'sixty_forty_column5b444c.1809755190',
    type: 'pause_3_months',
    category: 'pause',
    published_at: '2020-09-28T15:45:12.585Z',
    primary_card_name: 'sixty_forty_column5',
    cards: [
      {
        type: 'Card',
        id: '0c11549a-dd1a-4ea0-8579-42a6b6e16854',
        name: 'sixty_forty_column5',
        version: '22349',
        deploy: 'modal',
        template: 'sixty_forty_column',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'button',
            text: 'Pause for Three Months',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {
              form_submit: true,
            },
            icon: 'none',
          },
          hero: {
            show: 'image',
            url: 'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
          },
          info: {
            title: '<div class="slate-p">Pause for Three Months</div>',
            details:
              '<div class="slate-p">We understand your needs can change. Why don\'t you <strong class="slate-bold">pause your plan</strong> instead of canceling? We\'ll be here for you when you are ready to come back. </div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:21.146Z',
    updated_at: '2021-05-19T14:10:57.461Z',
  },
  {
    id: '4e86DBBlVA',
    active: true,
    display_name: 'Pause Plan',
    internal_name: 'pause_plan.83375876',
    type: 'pause_plan',
    category: 'pause',
    published_at: '2021-09-23T15:30:49.514Z',
    primary_card_name:
      'pause_plan.speech_bubble_2.66e9c09f-2932-40b8-9c4f-13f9089591bb',
    cards: [
      {
        type: 'Card',
        id: '66e9c09f-2932-40b8-9c4f-13f9089591bb',
        name: 'pause_plan.speech_bubble_2.66e9c09f-2932-40b8-9c4f-13f9089591bb',
        version: '47130',
        deploy: 'modal',
        template: 'speech_bubble_2',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'button',
            text: 'Pause my Plan',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {
              form_submit: true,
            },
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/ec302a21-0981-423a-a400-12aec370962e.jpg',
            name: 'Nick',
            title: 'CSM',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            title: '<div class="slate-p">Pause Your Plan</div>',
            details:
              '<div class="slate-p">As a loyal customer we want to give you the chance to take a break for a little while. Select to Pause your plan for either 2/4/8 weeks.</div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-09-16T20:01:53.700Z',
    updated_at: '2022-06-01T22:35:49.909Z',
  },
  {
    id: '4e86DBBlVA1',
    active: true,
    display_name: 'Free Gift',
    internal_name: 'pause_plan.83375876',
    type: 'gift',
    category: 'gifts_and_credits',
    published_at: '2021-09-23T15:30:49.514Z',
    primary_card_name:
      'pause_plan.speech_bubble_2.66e9c09f-2932-40b8-9c4f-13f9089591bb',
    cards: [
      {
        type: 'Card',
        id: '66e9c09f-2932-40b8-9c4f-13f9089591bb',
        name: 'pause_plan.speech_bubble_2.66e9c09f-2932-40b8-9c4f-13f9089591bb',
        version: '47130',
        deploy: 'modal',
        template: 'speech_bubble_2',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'button',
            text: 'Claim Gift',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {
              form_submit: true,
            },
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/ec302a21-0981-423a-a400-12aec370962e.jpg',
            name: 'Nick',
            title: 'CSM',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            title: '<div class="slate-p">Add a Free Product</div>',
            details:
              '<div class="slate-p">As a loyal customer, we wanted to offer you a free product as part of your next subscription order!</div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-09-16T20:01:53.700Z',
    updated_at: '2022-06-01T22:35:49.909Z',
  },
  {
    id: 'wWlWoD1lRM',
    active: true,
    display_name: '% Discount',
    internal_name: '25_discount_1499036141',
    type: 'precent',
    category: 'discount',
    published_at: '2022-07-29T18:58:24.653Z',
    primary_card_name:
      '25_discount.sixty_forty_column.7435a92f-0e2d-4025-9ecb-76e7766b2eeb',
    cards: [
      {
        type: 'Card',
        id: '7435a92f-0e2d-4025-9ecb-76e7766b2eeb',
        name: '25_discount.sixty_forty_column.7435a92f-0e2d-4025-9ecb-76e7766b2eeb',
        version: '90404',
        deploy: 'modal',
        template: 'sixty_forty_column',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Apply x% Discount',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
            sync: {},
            icon: 'none',
          },
          hero: {
            show: 'image',
            url: 'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
          },
          info: {
            title:
              '<div class="slate-p">How about x% off your next month? </div>',
            details:
              '<div class="slate-p">You have been with Chargebee Retention for a while now and we value your loyalty to our product!</div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2022-06-01T22:21:04.708Z',
    updated_at: '2022-06-01T22:42:46.357Z',
  },
  {
    id: 'wWlWoG4lRM',
    active: false,
    display_name: 'Vertical - LA Offer',
    internal_name: 'vertical_la_offer_1270466161',
    type: 'precent',
    category: 'discount',
    published_at: '2022-06-07T22:11:26.908Z',
    primary_card_name:
      'vertical_la_offer.loss_simple_link.b16f7848-2fbe-427d-a276-a60cefbb19f3',
    cards: [
      {
        type: 'Card',
        id: 'b16f7848-2fbe-427d-a276-a60cefbb19f3',
        name: 'vertical_la_offer.loss_simple_link.b16f7848-2fbe-427d-a276-a60cefbb19f3',
        version: '81251',
        deploy: 'exit_survey',
        template: 'loss_simple_link',
        requires: {
          title: '<div class="slate-p">Chat with a Retention Expert</div>',
          forwards: {
            destination_type: 'url',
            type: 'button',
            text: 'Apply 25% Discount',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {},
          },
          image:
            'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/73eaa9ce-2196-4e3c-a287-a307e8b4d0f7.jpg',
          vertical: true,
          copy: '<div class="slate-p">Schedule time with a Retention Expert to go through your setup and we will give you a 25% discount off your next month.</div>',
          info: {
            title:
              '<div class="slate-p">How about x% off your next month? </div>',
            details:
              '<div class="slate-p">You have been with Brightback for a while now and we value your loyalty to our product!</div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2022-06-07T22:11:15.605Z',
    updated_at: '2022-06-07T22:11:15.605Z',
  },
  {
    id: 'XVe8MrnyKW',
    active: false,
    display_name: 'Product Feedback',
    internal_name: 'forty_sixty_mini_11c8472.1799439190',
    type: 'product_feedback',
    category: 'customer_feedback',
    published_at: '2020-12-22T14:45:40.797Z',
    primary_card_name: 'forty_sixty_mini_modal_11',
    cards: [
      {
        type: 'Card',
        id: '9f6f0e29-9f7c-4a48-80b6-3c876fbaa2dd',
        name: 'forty_sixty_mini_modal_11',
        version: '26393',
        deploy: 'modal',
        template: 'forty_sixty_mini_modal_1',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Send message',
            action: 'modal_email',
            url: 'support@brightback.com',
            modal:
              'product_feedback.modal_email_send.d7a72b3a-aa2f-41c3-a0e9-1a8a95c4511f',
            sync: {},
            icon: 'none',
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/ba3268b4-cd41-44a0-ae8f-c4b072dae839.png',
            name: 'Jen',
            title: 'Product Manager',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            title: '<div>Help us Improve our Product!&nbsp;</div>',
            details:
              '<div class="slate-p">Will you take a few minutes to share what we could do to improve the product experience for you? </div>',
          },
        },
        provides: {},
      },
      {
        type: 'Card',
        id: 'd7a72b3a-aa2f-41c3-a0e9-1a8a95c4511f',
        name: 'product_feedback.modal_email_send.d7a72b3a-aa2f-41c3-a0e9-1a8a95c4511f',
        version: '26393',
        deploy: 'modal',
        template: 'modal_email_send',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Send message',
            action: 'send_email',
            url: 'support@brightback.com',
            modal:
              'product_feedback.modal_email_success.17dec841-4d56-4e8d-a1a6-96787789c23b',
            sync: {},
          },
          info: {
            title: 'Send message',
            placeholder: 'Start typing...',
          },
        },
        provides: {},
      },
      {
        type: 'Card',
        id: '17dec841-4d56-4e8d-a1a6-96787789c23b',
        name: 'product_feedback.modal_email_success.17dec841-4d56-4e8d-a1a6-96787789c23b',
        version: '26393',
        deploy: 'modal',
        template: 'modal_email_success',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Got it! Return to app.',
            action: 'link',
            url: "${default(value('standard.Save return URL'), 'https://www.brightback.com/')}",
            sync: {},
            cancelText: 'Continue to cancel',
            subText: "(I'll wait for a response before canceling.)",
          },
          success: {
            title: 'Before you go...',
            details:
              'We would love to have the opportunity to address your feedback. Please give us a few hours to respond to your message. Thanks for your patience!',
          },
          hideCancel: false,
          info: {
            title: 'Message sent',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:20.303Z',
    updated_at: '2022-06-01T22:37:31.670Z',
  },
  {
    id: 'mEg4QBd8ke',
    active: false,
    display_name: 'Schedule Call',
    internal_name: 'schedule_time.1018110087',
    type: 'schedule_time',
    category: 'support',
    published_at: '2020-09-18T16:23:02.054Z',
    primary_card_name:
      'schedule_time.forty_sixty_mini_modal_2.322a665f-2a30-4041-85aa-c6a1fd2931ba',
    cards: [
      {
        type: 'Card',
        id: '322a665f-2a30-4041-85aa-c6a1fd2931ba',
        name: 'schedule_time.forty_sixty_mini_modal_2.322a665f-2a30-4041-85aa-c6a1fd2931ba',
        version: '22140',
        deploy: 'modal',
        template: 'forty_sixty_mini_modal_2',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'composeEmail',
            text: 'Schedule Call ',
            action: 'modal_email',
            sync: {
              form_submit: true,
            },
            icon: 'none',
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/ff71fd65-f515-4a6c-a44c-3bab658126aa',
            name: 'Carl',
            title: 'Product Manager',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            title: '<div class="slate-p">Schedule Call w/ Expert</div>',
            details:
              '<div class="slate-p">Schedule a quick call with one of our Retention experts today!</div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-09-16T23:49:19.874Z',
    updated_at: '2022-06-01T22:36:11.759Z',
  },
  {
    id: '32lbmpByVD',
    active: true,
    display_name: 'Send Email',
    internal_name: 'schedule_time.254072690',
    type: 'send_email',
    category: 'support',
    published_at: '2020-09-16T23:48:10.814Z',
    primary_card_name:
      'schedule_time.forty_sixty_mini_modal_2.7a04bd02-7f48-4670-95ee-87f1f5949d05',
    cards: [
      {
        type: 'Card',
        id: '7a04bd02-7f48-4670-95ee-87f1f5949d05',
        name: 'schedule_time.forty_sixty_mini_modal_2.7a04bd02-7f48-4670-95ee-87f1f5949d05',
        version: '22027',
        deploy: 'modal',
        template: 'forty_sixty_mini_modal_2',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'composeEmail',
            text: 'Schedule Time ',
            action: 'modal_email',
            sync: {
              form_submit: true,
            },
            icon: 'none',
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/ff71fd65-f515-4a6c-a44c-3bab658126aa',
            name: 'Carl',
            title: 'Product Manager',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            title: '<div class="slate-p">Send Email to our Team</div>',
            details:
              '<div class="slate-p">Tell us how you can help us and a member of our team will reach out shortly! </div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-09-16T23:20:33.137Z',
    updated_at: '2022-06-01T22:36:26.312Z',
  },
  {
    id: '9ngxBZoyLk',
    active: false,
    display_name: 'Skip Order',
    internal_name: 'one_month_pause_big.847666817',
    type: 'skip',
    category: 'pause',
    published_at: '2021-09-23T15:33:17.485Z',
    primary_card_name:
      'one_month_pause_big.speech_bubble_2.bb80de81-4e3a-4ed1-9ec8-a40ff0965311',
    cards: [
      {
        type: 'Card',
        id: 'bb80de81-4e3a-4ed1-9ec8-a40ff0965311',
        name: 'one_month_pause_big.speech_bubble_2.bb80de81-4e3a-4ed1-9ec8-a40ff0965311',
        version: '47133',
        deploy: 'modal',
        template: 'speech_bubble_2',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'button',
            text: 'Skip my Next Order',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {
              form_submit: true,
            },
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/b48e6e13-e3ce-4ba0-8572-1bfee8c04582.jpg',
            name: 'Nick',
            title: 'CSM',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            title: '<div class="slate-p">Too Much? Skip an Order! </div>',
            details:
              '<div class="slate-p">Got too much going on right now to use our product? Skip a month on us! </div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-09-16T20:02:41.612Z',
    updated_at: '2022-06-01T22:37:14.281Z',
  },
  {
    id: 'NQZ8Kn4gBW',
    active: false,
    display_name: 'Swap Products',
    internal_name: 'sixty_forty_columnaad8f.1830255671',
    type: 'swap_products',
    category: 'plan_change',
    published_at: '2020-09-16T20:56:20.727Z',
    primary_card_name: 'sixty_forty_column',
    cards: [
      {
        type: 'Card',
        id: 'ca49c789-83d8-42a0-9b50-dabc716afbce',
        name: 'sixty_forty_column',
        version: '22009',
        deploy: 'modal',
        template: 'sixty_forty_column',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'button',
            text: 'Swap Plans',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {
              form_submit: true,
            },
            icon: 'none',
          },
          hero: {
            show: 'image',
            url: 'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
          },
          info: {
            title: '<div class="slate-p">Try our new Product Line!</div>',
            details:
              '<div class="slate-p">We launched Brightback Attribution this month! Switch to our new offering for free! </div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:21.025Z',
    updated_at: '2021-05-25T18:54:22.882Z',
  },
  {
    id: 'q04gnDYyan',
    active: false,
    display_name: 'Switch to Monthly',
    internal_name: 'video_paused_skipped5f78af.1691546005',
    type: 'switch_to_monthly',
    category: 'plan_change',
    published_at: '2021-05-25T18:41:19.056Z',
    primary_card_name: 'modal_video_paused_skipped5',
    cards: [
      {
        type: 'Card',
        id: '86e5ed49-4425-4214-9d37-fda9b997e704',
        name: 'modal_video_paused_skipped5',
        version: '36632',
        deploy: 'modal',
        template: 'speech_bubble_2',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'button',
            text: 'Switch to Monthly',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              'dfe5dc894b9059c4a8ad910e67b5605fb43987282d94b0a3c5a7668f20cc723b',
            sync: {
              form_submit: true,
            },
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/be9f906a-0add-4c87-9742-e4b4bb812d9a',
            name: 'Carl',
            title: 'Product Manager',
            organization: 'Brightback',
            preposition: '@',
          },
          info: {
            title: '<div>We can help with this!</div>',
            details:
              '<div>We know that an annual commitment can be a lot right now. Switch to a Monthly Plan for the time being.&nbsp;</div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:20.920Z',
    updated_at: '2022-06-01T22:36:41.624Z',
  },
  {
    id: 'AeyO36Yyo9',
    active: true,
    display_name: 'Upgrade',
    internal_name: 'upgrade.356241759',
    type: 'upgrade',
    category: 'plan_change',
    published_at: '2021-09-23T15:41:47.624Z',
    primary_card_name:
      'upgrade.speech_bubble_1.3c3dedb6-66f1-470a-b629-dd0d2ef17f9b',
    cards: [
      {
        type: 'Card',
        id: '3c3dedb6-66f1-470a-b629-dd0d2ef17f9b',
        name: 'upgrade.speech_bubble_1.3c3dedb6-66f1-470a-b629-dd0d2ef17f9b',
        version: '47135',
        deploy: 'modal',
        template: 'speech_bubble_1',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Claim Upgrade',
            action: 'modal_email',
            url: 'support@brightback.com',
            modal:
              'upgrade.modal_email_send.dfb56f75-18bb-452b-8b2d-b5a7583a7916',
            validation:
              'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
            sync: {},
            icon: 'none',
          },
          person: {
            avatar:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/0fa2a27f-4482-4881-974f-724931570b2e.jpg',
            name: 'Nick',
            title: 'CSM ',
            preposition: '@',
            organization: 'Brightback',
          },
          info: {
            title: '<div class="slate-p">Upgrade for Free! </div>',
            details:
              '<div class="slate-p">We want to upgrade you to our Enterprise Plan free of charge so you can get all the value you need from Brightback! </div>',
          },
        },
        provides: {},
      },
      {
        type: 'Card',
        id: 'dfb56f75-18bb-452b-8b2d-b5a7583a7916',
        name: 'upgrade.modal_email_send.dfb56f75-18bb-452b-8b2d-b5a7583a7916',
        version: '47135',
        deploy: 'modal',
        template: 'modal_email_send',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Send message',
            action: 'send_email',
            url: 'support@brightback.com',
            modal:
              'upgrade.modal_email_success.45cdcf82-131d-4f7e-ac67-ca70490508ed',
            validation:
              'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
            sync: {},
          },
          message:
            'Input the price you are willing to pay per month for an annual plan...',
          info: {
            title: 'Send message',
            placeholder: 'Start typing...',
          },
        },
        provides: {},
      },
      {
        type: 'Card',
        id: '45cdcf82-131d-4f7e-ac67-ca70490508ed',
        name: 'upgrade.modal_email_success.45cdcf82-131d-4f7e-ac67-ca70490508ed',
        version: '47135',
        deploy: 'modal',
        template: 'modal_email_success',
        requires: {
          forwards: {
            destination_type: 'url',
            text: 'Got it! Return to app.',
            action: 'link',
            url: "${default(value('standard.Save return Url'), 'https://www.brightback.com/')}",
            validation:
              '9d54e171cfa83874a47bc694f6990181552cb65e2fae00891d7af121423ef9c5',
            sync: {},
            cancelText: 'Continue to cancel',
            subText: "(I'll wait for a response before canceling.)",
          },
          success: {
            title: 'Before you go...',
            details:
              'We would love to have the opportunity to address your feedback. Please give us a few hours to respond to your message. Thanks for your patience!',
          },
          hideCancel: false,
          info: {
            title: 'Message sent',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-09-16T20:52:40.400Z',
    updated_at: '2022-06-01T22:36:52.402Z',
  },
  {
    id: 'A9ngxjQyLk',
    active: true,
    display_name: 'Multi-action',
    internal_name: 'sixty_forty_column_comp451b6.1788919501',
    type: 'dollar',
    category: OfferCategory.multi_action,
    published_at: '2020-09-18T16:18:28.110Z',
    primary_card_name: 'sixty_forty_column_comp',
    cards: [
      {
        type: 'Card',
        id: '7b97cf2a-42dd-4be4-b3d5-23a4713f8b81',
        name: 'sixty_forty_column_comp',
        version: '22135',
        deploy: 'modal',
        template: 'sixty_forty_column',
        requires: {
          forwards: {
            destination_type: 'url',
            type: 'button',
            text: 'Upgrade + take x% off',
            action: 'link',
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {
              form_submit: true,
            },
            icon: 'none',
          },
          hero: {
            show: 'image',
            url: 'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
          },
          info: {
            title: '<div class="slate-p">Upgrade and save big!</div>',
            details:
              '<div class="slate-p">Switch to Chargebee Retention\'s annual plan today and get a x% discount.</div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2020-06-29T23:27:21.170Z',
    updated_at: '2022-06-01T22:24:27.919Z',
  },
  {
    id: 'A9ngxjQyLk',
    active: true,
    display_name: 'Nevermind - Take me back',
    internal_name: 'sixty_forty_column_comp451b6.1788919501',
    category: OfferCategory.other,
    type: 'other',
    published_at: '2023-12-04T00:00:00.000Z',
    primary_card_name: 'sixty_forty_column_comp',
    cards: [
      {
        type: 'Card',
        id: '7b97cf2a-42dd-4be4-b3d5-23a4713f8b81',
        name: 'sixty_forty_column_comp',
        version: '22135',
        deploy: 'modal',
        template: RestModalTemplates.speech_bubble_2,
        requires: {
          forwards: {
            destination_type: DestinationType.url,
            type: 'button',
            text: 'Go back to my account',
            action: RestModalAction.nevermind,
            url: 'https://www.brightback.com',
            validation:
              '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
            sync: {
              form_submit: true,
            },
            icon: 'none',
          },
          hero: {
            show: 'image',
            url: 'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
          },
          info: {
            title: '<div class="slate-p">Stay with us!</div>',
            details:
              '<div class="slate-p">Stay subscribed and continue to enjoy benefits available with your plan.</div>',
          },
        },
        provides: {},
      },
    ],
    placements: [],
    created_at: '2023-12-04T00:00:00.000Z',
    updated_at: '2023-12-04T00:00:00.000Z',
  },
];
