// @ts-nocheck
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import _, { get, set, defaults } from 'lodash';
import type { Draft } from 'immer';
import { produce } from 'immer';
import type { PatchPath } from '@bbkAdminUtils/utility-types';
import type {
  RestLossAversion,
  RestOffer,
} from '@bbkAdminUtils/api-client/rest-offers-api';
import { OfferCategory } from '@bbkAdminUtils/api-client/rest-offers-api';
import { collectForwards } from '@bbkAdminComponents/experiences/offers/collect-forwards';
import {
  CtaProcessingOption,
  RestModalAction,
  RestModalForwards,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import { RestModalTemplates } from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import type { RestLossAversionCardTemplates } from '@bbkAdminUtils/api-client/rest-la-cards-types';
import Icons from '@bbkAdminUtils/icons';
import type { RestCard } from '@bbkAdminUtils/api-client/rest-experiences-api';
import {
  getDefaultActionContent,
  getDefaultActionSteps,
} from '@bbkAdminComponents/experiences/offers/content/action-content';

export const collectChainOfCards = (
  offer: RestOffer,
  cardName: string
): string[] => {
  const card = offer.cards.find((c) => c.name === cardName);
  if (!card) return [];
  const cardCtas = collectForwards(card).map((f) => f.value);

  const ctasModals: string[] = cardCtas
    .map((cardCtasModals) => cardCtasModals.modal as string | undefined)
    .filter(Boolean);

  let result = [cardName];
  if (ctasModals.length !== 0) {
    result = [
      ...result,
      ...ctasModals.flatMap((x) => collectChainOfCards(offer, x)),
    ];
  }
  return result;
};

export function getCardTemplateHumanName(
  template: RestModalTemplates | RestLossAversionCardTemplates
) {
  switch (template) {
    case RestModalTemplates.modal_email_send:
    case RestModalTemplates.modal_email_structured:
      return 'Send message';
    case RestModalTemplates.modal_email_success:
      return 'Confirmation modal';
    default:
      return undefined;
  }
}

// ~ is a little hack to trick sort() function and put archived at the end of the list
export const RECOMMENDED_GROUP = '~~recommended' as const;
export const ARCHIVED_GROUP = '~archived' as const;
export const OfferCategoryDisplayName: Record<
  OfferCategory | typeof RECOMMENDED_GROUP | typeof ARCHIVED_GROUP,
  string
> = {
  [OfferCategory.discount]: 'Discount',
  [OfferCategory.pause]: 'Pause | Skip',
  [OfferCategory.plan_change]: 'Plan change',
  [OfferCategory.extension]: 'Extension',
  [OfferCategory.customer_feedback]: 'Customer feedback',
  [OfferCategory.support]: 'Support & training',
  [OfferCategory.gifts_and_credits]: 'Gifts & credits',
  [OfferCategory.multi_action]: 'Multi-action',
  [OfferCategory.multi_cta]: 'Multi-offer',
  [OfferCategory.other]: 'Cancel | Nevermind',
  [RECOMMENDED_GROUP]: 'Recommended offers',
  [ARCHIVED_GROUP]: 'Archived offers',
  [OfferCategory.offer_pack]: 'Offer Pack',
};

export const renderCategoryName = (
  categoryName: keyof OfferCategoryDisplayName
): string => OfferCategoryDisplayName[categoryName];

export const renderCategoryIcon = (
  categoryName: OfferCategory
): React.ReactNode => {
  switch (categoryName) {
    case OfferCategory.discount:
      return Icons.categoryDiscount;
    case OfferCategory.pause:
      return Icons.categoryPause;
    case OfferCategory.extension:
      return Icons.categoryExtension;
    case OfferCategory.plan_change:
      return Icons.categoryPlanChange;
    case OfferCategory.support:
      return Icons.categorySupport;
    case OfferCategory.customer_feedback:
      return Icons.categoryCustomerFeedback;
    case OfferCategory.gifts_and_credits:
      return Icons.categoryGifts;
    case OfferCategory.other:
      return Icons.categoryOther;
    case OfferCategory.multi_cta:
      return Icons.categoryMultiOffer;
    case OfferCategory.multi_action:
      return Icons.categoryMultiAction;
    case OfferCategory.offer_pack:
      return Icons.categoryOther;
    default:
      return null;
  }
};
export const renderCategoryDescription = (
  categoryName: OfferCategory
): React.ReactNode => {
  switch (categoryName) {
    case OfferCategory.discount:
      return (
        <>
          Discount offers allow you to present canceling customers with an
          incentive (% or $ off) to stay with your product or service. Stronger
          discounts tend to perform better, but we recommend testing a range to
          find your sweet spot.{' '}
        </>
      );
    case OfferCategory.pause:
      return (
        <>
          Pauses and Skips are a very effective offer type for certain
          subscription businesses, allowing customers to delay a subscription if
          they have too much product or are going on vacation.
        </>
      );
    case OfferCategory.extension:
      return (
        <>
          Extensions allow you to defer a renewal or extend a trial while the
          customer gets value from your product or service.{' '}
        </>
      );
    case OfferCategory.plan_change:
      return (
        <>
          Plan changes can be a very effective way to retain a customer who may
          need some elements of your product, but not the version they are on
          today.
        </>
      );
    case OfferCategory.support:
      return (
        <>
          Support & training offers allow you to educate the customer on why
          your product or service can help meet their needs before they cancel.
        </>
      );
    case OfferCategory.customer_feedback:
      return (
        <>
          Customer Feedback offers give you a format to gather critical feedback
          on your product or service from your customer. Typically these offers
          have lower deflection performance, but the tradeoff is the insight
          gathered.
        </>
      );
    case OfferCategory.gifts_and_credits:
      return (
        <>
          Gifts and credits are a way to either reward loyal customers or make
          up for issues that may occur in your product or service.
        </>
      );
    case OfferCategory.multi_cta:
      return (
        <>
          With multi-offer, you can set up multiple offers per card. Use button
          settings to define the offer details.
        </>
      );
    case OfferCategory.multi_action:
      return (
        <>
          With multi-action offers, you can configure multiple offer actions per
          button click. Use button settings to define the behavior for each
          action.
        </>
      );
    case OfferCategory.other:
      return (
        <>
          This layout supports 'offers' that primarily have cancel or nevermind
          actions.
        </>
      );
    default:
      return null;
  }
};

export function generateNewIdentityForOfferOrLossAversionCard(
  offer: RestOffer | RestLossAversion,
  card: RestCard
): { id: string; name: string } {
  const id = uuidv4();
  const name = [_.snakeCase(offer.display_name), card.template, id].join('.');
  return { id, name };
}

export const createNewName = (
  displayName: string,
  usedDisplayNames: string[] = []
) => {
  const escapedDisplayName = displayName.replace(
    /[-/\\^$*+?.()|[\]{}]/g,
    '\\$&'
  );
  const regex = new RegExp(`${escapedDisplayName}( \\((\\d+)\\))?$`, 'i');

  const matchedNumbers = usedDisplayNames
    .map((el) => el.trim())
    .filter((el) => regex.test(el))
    .map((el) => parseInt(el.match(regex)?.[2] ?? '0', 10))
    .sort();

  const largestIndex = Math.max(...matchedNumbers);

  if (largestIndex !== -Infinity) {
    return `${displayName} (${largestIndex + 1})`;
  }
  return displayName;
};

const BRIGHTBACK_STR_TO_REPLACE = 'https://www.brightback.com';

const replaceBrightbackWithDomain = (url: string, domain: string) => {
  return url.replace(BRIGHTBACK_STR_TO_REPLACE, `https://${domain}`);
};

export const replaceBrightbackWithDomainInForward = (
  cta: RestModalForwards,
  domain: string
): RestModalForwards =>
  produce(cta, (draft) => {
    const url: string | undefined = draft.url;
    if (url && url.indexOf(BRIGHTBACK_STR_TO_REPLACE) !== -1) {
      draft.url = replaceBrightbackWithDomain(url, domain);
    }
  });

export const replaceBrightbackWithDomainInCards = <T extends RestCard>(
  cards: T[],
  domain: string
): T[] =>
  produce(cards, (draft) => {
    draft.forEach((draftCard, idx) => {
      const draftCtas = collectForwards(draftCard);

      draftCtas.forEach((draftCta) => {
        const url = get(draft, [idx, ...draftCta.path, 'url']);
        set(
          draft,
          [idx, ...draftCta.path, 'url'],
          replaceBrightbackWithDomain(url, domain)
        );
      });
    });
  });

export const replaceBrightbackWithDomainInOffer = (
  offer: RestOffer | RestLossAversion,
  domain: string
): RestOffer | RestLossAversion =>
  produce(offer, (draft) => {
    draft.cards.forEach((draftCard, idx) => {
      const draftCtas = collectForwards(draftCard);
      draftCtas.forEach((draftCta) => {
        const urlPath = ['cards', idx, ...draftCta.path, 'url'];
        const url = get(draft, urlPath);
        set(draft, urlPath, replaceBrightbackWithDomain(url, domain));
      });
    });
  });

export const updateCtaAction = (
  draft: Draft<RestOffer | RestLossAversion>,
  ctaPath: PatchPath,
  newAction: RestModalAction,
  domain?: string
): void => {
  const cta = (get(draft, ctaPath) ?? {}) as RestModalForwards;
  const ctaModal = cta.modal as string | undefined;
  const removeCardNames = ctaModal ? collectChainOfCards(draft, ctaModal) : [];
  // Generate values
  let newActionCards = getDefaultActionSteps(draft, newAction);
  let newActionObj = getDefaultActionContent(newAction);
  if (domain) {
    if (newActionCards) {
      newActionCards = replaceBrightbackWithDomainInCards(
        newActionCards,
        domain
      );
    }
    if (newActionObj) {
      newActionObj = replaceBrightbackWithDomainInForward(newActionObj, domain);
    }
  }

  if (newAction === RestModalAction.nevermind) {
    //update processing option if billing to url
    cta.cta_details_list?.forEach((draftCtaDetails) => {
      if (draftCtaDetails.processing_option === CtaProcessingOption.billing) {
        draftCtaDetails.processing_option = CtaProcessingOption.url;
      }
    });
  }

  // Update the offer (card)
  // path grabs the area to set the new item
  set(draft, ctaPath, {
    ...defaults({}, { text: cta.text }, newActionObj),
    modal: newActionCards?.[0]?.name,
    cta_details_list: cta.cta_details_list ? [...cta.cta_details_list] : [],
  } as RestModalForwards);
  // remove any card chains
  const remainingCards = draft.cards.filter(
    (card) => !removeCardNames.includes(card.name)
  );
  // add in any new cards
  draft.cards = [...remainingCards, ...newActionCards];
};
