import React from 'react';
import { Link } from 'react-router-dom';
import { CBRetentionLogo } from '@bbkAdminComponents/cbretention-logo';
import { SignupPaths } from '@bbkAdminRouter/signup-paths';

export const TrialSignupLogo: React.FC = () => (
  <Link
    to={SignupPaths.trialSignup}
    className="tw-max-w-[300px] tw-inline-block"
  >
    <CBRetentionLogo />
  </Link>
);
