import type { DefaultRootState } from 'react-redux';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import type { AppKey } from '@bbkAdminRedux/rtkq/current-user.slice';
import { useSelectCurrentApp } from '@bbkAdminRedux/rtkq/current-user.slice';
import type {
  AppSpace,
  PersistSliceSuffix,
} from '@bbkAdminRedux/rtkq/app-persist/app-persist.slice';
import {
  buildAppPrefix,
  useGetDemoSuffix,
} from '@bbkAdminRedux/rtkq/app-persist/app-persist.slice';
import type {
  RangeKeys,
  TimePickerStructure,
  TimeRangesEnum,
} from '@bbkAdminUtils/time-periods';
import {
  DefaultTimePeriod,
  DEMO_RANGE,
  StarterFreeDisabledPeriods,
  TimeRanges,
} from '@bbkAdminUtils/time-periods';
import { selectAppId } from '@bbkAdminRedux/app/selectors';
import { usePlans } from '@bbkAdminUtils/plans';
import { FeatureFlagsE, useFeatureFlags } from '@bbkAdminUtils/feature-flags';

type DatepickerState =
  | {
      timeAgoKey: 'CUSTOM';
      startDate: Date;
      endDate: Date;
    }
  | {
      timeAgoKey: TimeRangesEnum;
    };

type SliceState = Partial<{
  [space: AppSpace]: DatepickerState;
}>;

const defaultTimeRange = TimeRanges[DefaultTimePeriod.value];
export const defaultDatepicker: TimePickerStructure = {
  timeAgoKey: DefaultTimePeriod.value,
  startDate: defaultTimeRange[0],
  endDate: defaultTimeRange[1],
};

export const demoDatepicker = {
  timeAgoKey: 'CUSTOM' as RangeKeys,
  startDate: DEMO_RANGE[0],
  endDate: DEMO_RANGE[1],
};

const initialState: SliceState = {};

export const datepickerPersistSlice = createSlice({
  name: 'datepickerPersist-rtk',
  initialState,
  reducers: {
    saveDatepicker: (
      draftState,
      action: PayloadAction<
        { appKey: AppKey; suffix?: PersistSliceSuffix } & DatepickerState
      >
    ) => {
      const { appKey, suffix, ...partialNewState } = action.payload;
      const prefix = buildAppPrefix(appKey, suffix);
      const timeAgoKey = partialNewState.timeAgoKey;

      if (timeAgoKey === 'CUSTOM') {
        const { startDate, endDate } = partialNewState;
        let updatedPartialState = partialNewState;
        if (moment(endDate).isSameOrBefore(startDate, 'day')) {
          updatedPartialState = {
            timeAgoKey: 'CUSTOM',
            startDate: moment.utc(startDate).startOf('day').toDate(),
            endDate: moment.utc(startDate).endOf('day').add(1, 'day').toDate(),
          };
        }
        draftState[prefix] = {
          ...draftState[prefix],
          ...updatedPartialState,
        };
      } else {
        draftState[prefix] = {
          ...draftState[prefix],
          timeAgoKey: partialNewState.timeAgoKey,
        };
      }
    },
  },
});

const selectDatepicker =
  (suffix: PersistSliceSuffix = '') =>
  (state: DefaultRootState) => {
    const appKey = selectAppId(state);
    const prefix = buildAppPrefix(appKey, suffix);
    return state[datepickerPersistSlice.name][prefix] ?? defaultDatepicker;
  };
export const useSelectDatePicker = (): TimePickerStructure => {
  const { isStarterFreePlan } = usePlans();

  const app = useSelectCurrentApp();
  const showDemoData = useFeatureFlags(FeatureFlagsE.ENABLE_DEMO_REPORTS);
  const isDemoData = app?.show_demo_data || showDemoData;

  const suffix = useGetDemoSuffix();
  const datePicker = useSelector(selectDatepicker(suffix));

  return useMemo(() => {
    const timeAgoKey = datePicker.timeAgoKey;

    if (isDemoData) {
      return demoDatepicker;
    }

    if (isStarterFreePlan && StarterFreeDisabledPeriods.includes(timeAgoKey)) {
      return defaultDatepicker;
    }

    if (timeAgoKey !== 'CUSTOM') {
      const range = TimeRanges[timeAgoKey] ?? defaultDatepicker;
      return {
        timeAgoKey,
        startDate: range[0],
        endDate: range[1],
      };
    }

    if (timeAgoKey === 'CUSTOM') {
      const startDate = new Date(datePicker.startDate);
      const endDate = new Date(datePicker.endDate);
      if (moment(endDate).isSameOrBefore(startDate, 'day')) {
        return {
          timeAgoKey: 'CUSTOM',
          startDate: moment.utc(startDate).startOf('day').toDate(),
          endDate: moment.utc(startDate).endOf('day').add(1, 'day').toDate(),
        };
      }
      return {
        timeAgoKey,
        startDate,
        endDate,
      };
    }

    return datePicker;
  }, [isDemoData, isStarterFreePlan, datePicker]);
};
