import React from 'react';
import type { Argument } from 'classnames';
import classNames from 'classnames';
import icons from '@bbkAdminUtils/icons';
import './styles/link_group.scss';

// Interior to a row item
type Props = {
  item: {
    icon?: keyof typeof icons;
    iconClasses?: Argument;
    text: React.ReactNode;
    itemClasses?: Argument;
  };
};
const LinkGroupItem: React.FC<Props> = (props) => {
  const { item } = props;
  return (
    <div className="bbk-lg-i">
      {item.icon && (
        <span className={classNames('bbk-lg-i-i', item.iconClasses)}>
          {icons[item.icon]}
        </span>
      )}
      <div className={classNames('bbk-lg-i-t', item.itemClasses)}>
        {item.text}
      </div>
    </div>
  );
};

export default LinkGroupItem;
