// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import type { FormatOptionLabelMeta, Props as RSProps } from 'react-select';
import Select, { components } from 'react-select';
import { css } from '@emotion/react';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import _, { debounce } from 'lodash';
import type { FieldProps } from 'formik';
import { useField } from 'formik';
import icons, { svgToBase64URI } from '@bbkAdminUtils/icons';
import { plural } from '@bbkUtils/pluralize';
import tailwindColors from '@bbkRoot/tailwind.colors';

export const customStyles: RSProps['styles'] = {
  control: (provided, { isDisabled, selectProps }) => {
    let fontFamily = {};
    if (_.has(selectProps, 'value.stack')) {
      fontFamily = { fontFamily: _.get(selectProps, 'value.stack') };
    }
    return {
      ...provided,
      background: selectProps.dark ? 'rgba(0,0,0,.06)' : '#fff',
      border: '1px solid rgba(0,0,0,0.1)',
      borderColor: 'rgba(0,0,0,0.1)',
      boxShadow: 'none',
      minHeight: selectProps.short ? 30 : 42,
      opacity: isDisabled ? '0.6' : '1',
      ...fontFamily,
      fontSize: '14px',
      ':hover': {
        ...provided[':hover'],
        boxShadow: 'none',
        border: '1px solid rgba(0,0,0,.40)',
      },
      ':active': {
        ...provided[':active'],
        border: '1px solid rgba(0,0,0,.25)',
      },
    };
  },
  option: (
    provided,
    { isSelected, isFocused, selectProps, isDisabled, data, isMulti }
  ) => {
    let fontFamily = {};
    if (_.has(data, 'stack')) {
      fontFamily = { fontFamily: _.get(data, 'stack') };
    }
    const styles = {
      ...provided,
      fontWeight: !isMulti && isSelected ? 'bold' : 'normal',
      opacity: isDisabled ? '0.3' : null,
      fontStyle: isDisabled ? 'italic' : null,
      backgroundColor: isDisabled
        ? null
        : !isMulti && isSelected
          ? tailwindColors.cbrprimary['50']
          : isFocused
            ? tailwindColors.cbrprimary['50']
            : null,
      color: isFocused ? tailwindColors.cbrprimary['600'] : null,
      padding: selectProps.short ? '2px 6px' : '4px 20px',
      ...fontFamily,
      fontSize: '14px',
      ':active': {
        ...provided[':active'],
        backgroundColor:
          !isDisabled &&
          (isSelected
            ? tailwindColors.cbrprimary['100']
            : tailwindColors.cbrprimary['50']),
      },
    };

    if (isMulti) {
      styles.backgroundRepeat = 'no-repeat';
      styles.backgroundPosition = 'left 10px center';
      styles.paddingLeft = '42px';
      styles.backgroundImage = `url("${svgToBase64URI(icons.checkmarkEmpty)}")`;

      if (isSelected) {
        styles.backgroundImage = `url("${svgToBase64URI(
          icons.checkmarkSelected
        )}")`;
      }
    }

    return styles;
  },
  indicatorSeparator: (provided, state) => ({
    ...provided,
    width: 0,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    color: tailwindColors.cbrprimary['800'],
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    visibility: state.isDisabled ? 'hidden' : 'visible',
    color: tailwindColors.cbrprimary['800'],
    opacity: 0.7,
    padding: state.selectProps.short ? '2px 4px' : '8px',
    ':hover': {
      color: tailwindColors.cbrprimary['600'],
      opacity: 1,
    },
  }),
};

const customCreatableSelectStyle: RSProps['styles'] = {
  ...customStyles,
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: '100px',
    overflow: 'auto',
  }),
};

type NestedSelectOption<T extends SelectOption> = {
  label: React.ReactNode;
  options: T[];
};
type GroupedMultiSelectOption<T extends SelectOption> =
  | T
  | Readonly<T>
  | NestedSelectOption<T>;

export type GroupedMultiSelectOptions<T> = Array<GroupedMultiSelectOption<T>>;

export type SelectOption<T = unknown> = {
  label?: React.ReactNode;
  value: T;
  isDisabled?: boolean;
  selected?: boolean;
};

type GenericSelectProps<T extends GroupedMultiSelectOption> = Omit<
  RSProps,
  'onChange' | 'options' | 'isMulti' | 'formatOptionLabel'
> & {
  dark?: boolean;
  short?: boolean;
  isMulti?: false;
  options: T[];
  onChange?: (e: T extends { options?: Array<infer Opt> } ? Opt : T) => unknown;
  formatOptionLabel?: (
    data: T,
    formatOptionLabelMeta: FormatOptionLabelMeta<T>
  ) => React.ReactNode;
};
const GenericSelect = <T,>({
  innerRef,
  isMulti,
  ...restProps
}: GenericSelectProps<T>) => (
  <Select styles={customStyles} ref={innerRef} {...restProps} />
);

type MultiGenericSelectProps<T> = Omit<GenericSelectProps<T>, 'onChange'> & {
  onChange?: (e: { value: T }[]) => unknown;
};
export const MultiGenericSelect = <T,>({
  innerRef,
  isMulti,
  ...restProps
}: MultiGenericSelectProps<T>) => (
  <Select styles={customStyles} ref={innerRef} isMulti {...restProps} />
);

export const generateGenericSelectOptions = <
  T extends { id: unknown; display_name: string },
>(
  items: T[]
): SelectOption<T['id']>[] =>
  items.map((item) => ({
    value: item.id,
    label: item.display_name,
  }));

type FormikGenericSelectProps = GenericSelectProps & FieldProps;
export const FormikGenericSelect: React.FC<FormikGenericSelectProps> = (
  props
) => {
  const { iid, options, ...restProps } = props;
  const [field, meta, helpers] = useField(props);

  const { value } = meta;
  const { setValue } = helpers;

  const opts =
    options?.map((opt) => ({
      ...opt,
      selected: value === opt.value,
    })) ?? [];

  return (
    <GenericSelect
      {...restProps}
      isSearchable={false}
      options={opts}
      name={field.name}
      value={opts.find((o) => o.value === value)}
      onChange={(opt) => setValue(opt?.value)}
      instanceId={iid}
    />
  );
};

const handleCommaSeparatedValues = (value: string) => {
  return value
    .split(',')
    .map((val) => val.trim())
    .filter((val) => val.length > 0);
};

type ClearableType = React.ComponentProps<typeof CreatableSelect>;

export const InputSelectWithOptions: React.FC<
  Omit<ClearableType, 'value' | 'onChange'> & {
    value?: string[];
    onChange: (value?: string[]) => unknown;
  }
> = ({ value = [], onChange, options, ...restProps }) => {
  const [inputValue, setInputValue] = useState('');

  const selectValue = value?.map(
    (x) => options?.find((o) => o.value === x) ?? { label: x, value: x }
  );

  const handleChange: ClearableType['onChange'] = (val) => {
    onChange(val?.map((x) => x.value));
  };

  return (
    <CreatableSelect
      isMulti
      placeholder="Start typing..."
      isClearable={false}
      inputValue={inputValue}
      value={selectValue}
      onChange={handleChange}
      options={options}
      {...restProps}
    />
  );
};

export const InputSelect: React.FC<
  Omit<ClearableType, 'value' | 'onChange'> & {
    value?: string[];
    onChange: (value?: string[]) => unknown;
    allowCsvValues?: boolean;
  }
> = ({ value = [], onChange, allowCsvValues, ...restProps }) => {
  const [inputValue, setInputValue] = useState('');

  const selectValue = value?.map((x) => ({ label: x, value: x }));

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    if (!inputValue) return;
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        e.preventDefault();
        setInputValue('');
        const updatedValues = allowCsvValues
          ? _.uniq([...value, ...handleCommaSeparatedValues(inputValue)])
          : [...value, inputValue];
        onChange(updatedValues);
        break;
      default:
        break;
    }
  };

  const handleChange: ClearableType['onChange'] = (val) => {
    onChange(val?.map((x) => x.value));
  };

  const handleBlur: ClearableType['handleBlur'] = () => {
    if (!inputValue) return;
    setInputValue('');
    const updatedValues = allowCsvValues
      ? _.uniq([...value, ...handleCommaSeparatedValues(inputValue)])
      : [...value, inputValue];
    onChange(updatedValues);
  };

  return (
    <CreatableSelect
      styles={customCreatableSelectStyle}
      components={{ DropdownIndicator: null }}
      isMulti
      placeholder="Start typing..."
      menuIsOpen={false}
      isClearable={false}
      inputValue={inputValue}
      onInputChange={setInputValue}
      value={selectValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      {...restProps}
    />
  );
};

type AsyncCreatableSelectType = React.ComponentProps<
  typeof AsyncCreatableSelect
>;

export const AsyncInputSelect: React.FC<
  Omit<
    ClearableType,
    'value' | 'onChange | loadOptions | isLoading | defaultOptions'
  > & {
    value?: string;
    showDefaultOptions?: boolean;
    formatCreateLabel?: AsyncCreatableSelectType['formatCreateLabel'];
    loadOptions: AsyncCreatableSelectType['loadOptions'];
    onSelect: (value?: string) => unknown;
  }
> = ({
  value,
  onSelect,
  formatCreateLabel,
  loadOptions,
  showDefaultOptions,
  ...restProps
}) => {
  const selectValue = value ? { label: value, value: value } : undefined;
  const handleChange: ClearableType['onChange'] = (val) => {
    onSelect(val?.value);
  };

  return (
    <AsyncCreatableSelect
      styles={customStyles}
      components={{ DropdownIndicator: null }}
      loadOptions={debounce(loadOptions, 1000)}
      placeholder="Start typing..."
      isClearable={false}
      formatCreateLabel={formatCreateLabel}
      defaultOptions={showDefaultOptions}
      value={selectValue}
      onChange={handleChange}
      {...restProps}
    />
  );
};

export const AsyncMultiInputSelect: React.FC<
  Omit<ClearableType, 'value' | 'onChange | loadOptions'> & {
    value?: string[];
    showDefaultOptions?: boolean;
    formatCreateLabel?: AsyncCreatableSelectType['formatCreateLabel'];
    loadOptions: AsyncCreatableSelectType['loadOptions'];
    onSelect: (value?: string[]) => unknown;
  }
> = ({
  value = [],
  onChangeValue,
  formatCreateLabel,
  loadOptions,
  onSelect,
  showDefaultOptions,
  ...restProps
}) => {
  const selectValue = value?.map((x) => ({ label: x, value: x }));

  const handleChange: ClearableType['onChange'] = (val) => {
    onSelect(val?.map((x) => x.value));
  };

  return (
    <AsyncCreatableSelect
      styles={customStyles}
      isMulti
      formatCreateLabel={formatCreateLabel}
      components={{ DropdownIndicator: null }}
      defaultOptions={showDefaultOptions}
      loadOptions={debounce(loadOptions, 1000)}
      placeholder="Start typing..."
      isClearable={false}
      closeMenuOnSelect={false}
      value={selectValue}
      onChange={handleChange}
      {...restProps}
    />
  );
};
export const RSValueContainer = (label: string) => (props) => {
  const { getValue, children, ...restProps } = props;
  const [valuesChildren, ...restChildren] = children;

  const values: unknown[] = getValue();
  const newChildren = (
    <span className="tw-text-sm tw-font-medium">
      {values.length === 0
        ? 'None'
        : `${values.length} ${plural(label, values.length)}`}
      {restChildren}
    </span>
  );
  return (
    <components.ValueContainer {...restProps}>
      {newChildren}
    </components.ValueContainer>
  );
};

const MultiSelectValueContainer = RSValueContainer('item');

type MultiSelectProps = React.ComponentProps<typeof Select>;
export const MultiSelect: React.FC<MultiSelectProps<unknown, true>> = ({
  ...restProps
}) => (
  <Select
    styles={customStyles}
    components={{
      ValueContainer: MultiSelectValueContainer,
    }}
    isMulti
    isSearchable={false}
    hideSelectedOptions={false}
    closeMenuOnSelect={false}
    placeholder={restProps.placeholder ?? 'None'}
    isClearable={false}
    {...restProps}
  />
);

const ValueContainerExpPerf = (props) => {
  const { getValue, children, ...restProps } = props;
  const [valuesChildren, ...restChildren] = children;

  const values = getValue();
  const newChildren = (
    <span
      css={css`
        font-size: 15px;
        font-weight: 500;
      `}
    >
      {values.length === 0
        ? 'filter...'
        : values.length === 1
          ? values[0].label
          : `${values.length} experiences`}
      {restChildren}
    </span>
  );
  return (
    <components.ValueContainer {...restProps}>
      {newChildren}
    </components.ValueContainer>
  );
};

export const MultiSelectExpPerf: React.FC<MultiSelectProps> = ({
  ...restProps
}) => (
  <Select
    styles={customStyles}
    components={{
      ValueContainer: ValueContainerExpPerf,
    }}
    isMulti
    isSearchable={false}
    hideSelectedOptions={false}
    placeholder="filter..."
    isClearable={false}
    {...restProps}
  />
);

export default GenericSelect;
