import React from 'react';
import type { PopoverProps } from '@material-ui/core';
import { Popover } from '@material-ui/core';
import LinkGroupMenu from '@bbkAdminComponents/linkGroup/link-group';
import './styles/popover.scss';

type Props = PopoverProps & {
  handleClose: PopoverProps['onClose'] &
    React.ComponentProps<typeof LinkGroupMenu>['handleClose'];
  enableSearch?: boolean;
  linkGroupItems: React.ComponentProps<typeof LinkGroupMenu>['links'];
};
const LinkListPopover: React.FC<Props> = (props) => {
  const {
    open,
    handleClose,
    anchorEl,
    anchorOrigin,
    transformOrigin,
    enableSearch,
    linkGroupItems,
  } = props;
  return (
    <div className="bbk-popover-container">
      <div className="bbk-p-po">
        <Popover
          classes={{ paper: 'bbk-rr-po' }}
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          <div className="bbk-po-menu no-pad">
            <LinkGroupMenu
              links={linkGroupItems}
              handleClose={handleClose}
              enableSearch={enableSearch}
            />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default LinkListPopover;
