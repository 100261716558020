import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  type CognitoUserSession,
  type IAuthenticationCallback,
  type ICognitoUserData,
  type ISignUpResult,
} from 'amazon-cognito-identity-js';
import type { BbkClientConfig } from '@bbkRoot/typings';
import type { SignInTokens } from '@bbkAdminRedux/rtkq/brightback-auth.slice';
import { SignInType } from '@bbkAdminRedux/rtkq/brightback-auth.slice';
import {
  createNodeCallbackHandler,
  createNodeCallbackObjectHandler,
} from '@bbkAdminUtils/utils';

export type UsernameSignUpArgs = {
  email: string;
  pw: string;
  firstName: string;
  lastName: string;
};

export type UsernameSignInArgs = {
  email: string;
  pw: string;
  onMFAReset: (
    usr: CognitoUser,
    usrDetails: Record<string, unknown>
  ) => unknown;
};

export type UsernameTokens = {
  id_token: string;
  refresh_token: string;
  exp: number;
  iat: number;
};

export class AuthUsername {
  private userPool: CognitoUserPool;

  constructor(config: BbkClientConfig) {
    this.userPool = new CognitoUserPool({
      UserPoolId: config.cognito_user_pool_id,
      ClientId: config.cognito_web_client_id,
    });
  }

  private cognitoUserFromEmailAddress = (email: string): CognitoUser => {
    const userData: ICognitoUserData = {
      Username: email,
      Pool: this.userPool,
    };

    return new CognitoUser(userData);
  };

  private handleSessionTokens(session: CognitoUserSession): UsernameTokens {
    const idToken = session.getIdToken();
    const idJWTToken = idToken.getJwtToken();
    const exp = idToken.getExpiration();
    const iat = idToken.getIssuedAt();
    const refreshToken = session.getRefreshToken().getToken();
    return {
      id_token: idJWTToken,
      refresh_token: refreshToken,
      exp,
      iat,
    };
  }

  private transformCognitoUsernameTokens(tokens: UsernameTokens): SignInTokens {
    return {
      type: SignInType.USERNAME,
      access_token: tokens.id_token,
      refresh_token: tokens.refresh_token,
      exp: tokens.exp,
    };
  }

  protected signInUsername({
    email,
    pw,
    onMFAReset,
  }: UsernameSignInArgs): Promise<SignInTokens> {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: pw,
    });

    const cognitoUser = this.cognitoUserFromEmailAddress(email);
    return new Promise<CognitoUserSession>((resolve, reject) => {
      const cbHandler = createNodeCallbackObjectHandler(resolve, reject);
      const callbacks: IAuthenticationCallback = {
        ...cbHandler,
        newPasswordRequired: (userAttributes: Record<string, unknown>) => {
          onMFAReset(cognitoUser, userAttributes);
        },
      };
      cognitoUser.authenticateUser(authenticationDetails, callbacks);
    })
      .then((session) => {
        const tokens = this.handleSessionTokens(session);
        return this.transformCognitoUsernameTokens(tokens);
      })
      .catch((err: Error) => {
        const errMsg = err.message;
        err.message =
          errMsg === 'User does not exist.'
            ? 'Incorrect username or password.'
            : errMsg;
        throw err;
      });
  }

  protected signOutUsername() {
    return this.getUser()?.signOut();
  }

  public signUp({
    email,
    pw,
    firstName,
    lastName,
  }: UsernameSignUpArgs): Promise<ISignUpResult> {
    const attributes = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
      new CognitoUserAttribute({
        Name: 'given_name',
        Value: firstName,
      }),
      new CognitoUserAttribute({
        Name: 'family_name',
        Value: lastName,
      }),
    ];

    return new Promise((resolve, reject) => {
      const cbHandler = createNodeCallbackHandler(resolve, reject);
      this.userPool.signUp(email, pw, attributes, [], cbHandler);
    });
  }

  public resendSignUpConfirmation(email: string): Promise<unknown> {
    const cognitoUser = this.cognitoUserFromEmailAddress(email);
    return new Promise((resolve, reject) => {
      const cbHandler = createNodeCallbackHandler(resolve, reject);
      cognitoUser.resendConfirmationCode(cbHandler);
    });
  }

  protected doRefresh(refresh_token: string): Promise<SignInTokens> {
    const refreshToken = new CognitoRefreshToken({
      RefreshToken: refresh_token,
    });
    const user = this.getUser();
    return new Promise<CognitoUserSession>((resolve, reject) => {
      const cbHandler = createNodeCallbackHandler(resolve, reject);
      user.refreshSession(refreshToken, cbHandler);
    }).then((session) => {
      const tokens = this.handleSessionTokens(session);
      return this.transformCognitoUsernameTokens(tokens);
    });
  }

  public sendUpdatePasswordCode(email: string): Promise<unknown> {
    const cognitoUser = this.cognitoUserFromEmailAddress(email);
    return new Promise((resolve, reject) => {
      const cbHandler = createNodeCallbackObjectHandler(resolve, reject);
      cognitoUser.forgotPassword(cbHandler);
    });
  }

  public updatePassword(
    email: string,
    password: string,
    updatePasswordCode: string
  ): Promise<unknown> {
    const cognitoUser = this.cognitoUserFromEmailAddress(email);
    return new Promise((resolve, reject) => {
      const cbHandler = createNodeCallbackObjectHandler(resolve, reject);
      cognitoUser.confirmPassword(updatePasswordCode, password, cbHandler);
    });
  }

  public mfaUpdatePassword(
    password: string,
    cognitoUser: CognitoUser,
    userDeets: unknown
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const cbHandler = createNodeCallbackObjectHandler(resolve, reject);
      cognitoUser.completeNewPasswordChallenge(password, userDeets, cbHandler);
    });
  }

  public confirmSignUp(
    email: string,
    signUpConfirmationCode: string
  ): Promise<unknown> {
    const cognitoUser = this.cognitoUserFromEmailAddress(email);
    return new Promise((resolve, reject) => {
      const cbHandler = createNodeCallbackHandler(resolve, reject);
      cognitoUser.confirmRegistration(signUpConfirmationCode, true, cbHandler);
    });
  }

  private getUser(): CognitoUser {
    const user = this.userPool.getCurrentUser();
    if (!user) throw new Error('User is not signed in');
    return user;
  }
}
