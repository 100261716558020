import { useMemo } from 'react';
import type { SkipToken } from '@reduxjs/toolkit/query/react';
import type { RestOffer } from '@bbkAdminUtils/api-client/rest-offers-api';
import type { AppBusinessModel } from '@bbkAdminRedux/rtkq/reports/benchmark.selectors';
import { isOffer } from '@bbkAdminUtils/api-client/rest-cards-types';
import type { Goal } from '@bbkAdminComponents/experiences/offers/new-offer-flow';
import { categoryTemplates } from '@bbkAdminComponents/experiences/offers/new-offer-flow/category-templates';
import { isDefined } from '@bbkAdminRedux/rtkq/rtkq-utils';
import type { RestAbstractModal } from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';

type GetOffersTemplatesArgs = {
  appKey: string;
  type?: AppBusinessModel;
};

export const offersTemplatesSlice = bbkApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOffersTemplates: builder.query<RestOffer[], GetOffersTemplatesArgs>({
      query: ({ appKey, type }) => {
        const query = type ? `type=${type}` : undefined;
        return `/api/v1/apps/${appKey}/offer_templates${
          query ? `?${query}` : ''
        }`;
      },
      transformResponse: (response: { collection: RestOffer[] }) =>
        response.collection,
    }),
  }),
});

export const useSelectAllOffersTemplates = (
  args: GetOffersTemplatesArgs | SkipToken
) => {
  const { data } =
    offersTemplatesSlice.endpoints.getOffersTemplates.useQuery(args);

  return useMemo(() => (data ?? []).filter((o) => o.active), [data]);
};

export const NEW_OFFER_GOALS = categoryTemplates
  .filter(isOffer)
  .filter((o) => o.active)
  .map((template) => {
    const primaryCard = template.cards.find(
      (c) => c.name === template.primary_card_name
    );
    if (!primaryCard) return undefined;
    const requires = primaryCard.requires as RestAbstractModal['requires'] & {
      info?: {
        title: string;
        details: string;
      };
    };
    return {
      category: template.category,
      type: template.type,
      name: template.display_name,
      info: {
        title: requires.info?.title,
        details: requires.info?.details,
      },
      forwards: {
        text: requires.forwards?.text,
      },
    } as Goal;
  })
  .filter(isDefined);
export const useSelectNewOfferGoals = () => {
  return NEW_OFFER_GOALS;
};
