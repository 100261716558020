import React from 'react';
import type { Success } from '@bbkAdminComponents/log-in-flow/log-in-types';

type Props = {
  success: Success;
};

export const LogInSuccess: React.FC<Props> = ({
  success: { title, message },
}) => (
  <div className="success">
    <h2>{title}</h2>
    {message}
  </div>
);
