import React from 'react';
import { css } from '@emotion/react';
import type {
  FunnelWarnings,
  Warning,
} from '@bbkAdminUtils/api-client/rest-placement-warnings-api';
import { WarningType } from '@bbkAdminUtils/api-client/rest-placement-warnings-api';
import { DialogContent } from '@bbkAdminComponents/editor-page-overlay/dialog';
import CollapseFormContainer from '@bbkAdminComponents/form/collapse-form-container/collapse-form-container';
import { getPositionDisplayName } from '@bbkAdminRedux/rtkq/placements.utils';

export const buildWarningMessage = (
  funnelWarnings: FunnelWarnings
): React.ReactNode => {
  const renderWarning = (warning: Warning) => {
    switch (warning.type) {
      case WarningType.content: {
        let title = 'Offer';
        if (warning.content.category === 'generic_loss_aversion') {
          title = 'Loss aversion';
        }
        return (
          <div>
            {title}:{' '}
            <span className="offer">{warning.content.display_name}</span>
          </div>
        );
      }
      case WarningType.position:
        return (
          <>
            Position:{' '}
            <span className="position">
              {getPositionDisplayName(warning.position)}
            </span>
            {warning.content && (
              <div css={{ marginLeft: '1rem' }}>
                Content:{' '}
                <span className="offer">{warning.content.display_name}</span>
              </div>
            )}
          </>
        );
      case WarningType.vanityReason:
        return (
          <>
            Reason:{' '}
            <span className="reason">{warning.vanityReason.display_name}</span>
            {warning.content && (
              <div css={{ marginLeft: '1rem' }}>
                Content:{' '}
                <span className="offer">{warning.content.display_name}</span>
              </div>
            )}
          </>
        );
      default:
        return null;
    }
  };
  return (
    <DialogContent>
      <CollapseFormContainer
        classes="CFC-flat-theme mt-0 mb-0"
        title={
          <div css={{ color: '#f79f45' }}>
            Warning! A few extra items will be auto-published
          </div>
        }
        isOpened={false}
      >
        {funnelWarnings.map((fw) => (
          <div
            css={css`
              margin-top: 0.5rem;
              margin-bottom: 1rem;
              font-size: 0.875rem;
              margin-left: 2rem;
              color: rgba(0, 0, 0, 0.5);

              .funnel,
              .reason,
              .position,
              .offer {
                font-weight: 700;
              }
            `}
          >
            Page: <span className="funnel">{fw.funnel.display_name}</span>
            <br />
            {fw.warnings.map((w) => (
              <div
                key={fw.funnel.id + Math.random()}
                css={{ marginLeft: '1rem' }}
              >
                {renderWarning(w)}
                <div css={{ marginBottom: '0.25rem' }} />
              </div>
            ))}
          </div>
        ))}
      </CollapseFormContainer>
    </DialogContent>
  );
};
