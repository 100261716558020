import { BbkExpressionElementExport } from './components/expression-render-element';

export const ELEMENT_BBK_EXPRESSION_CLEAN_EXPORT =
  'bbk-expression-clean-render';

export const DEFAULTS_BBK_EXPRESSION_CLEAN_EXPORT = {
  bbkExpressionCleanExport: {
    component: BbkExpressionElementExport,
    type: ELEMENT_BBK_EXPRESSION_CLEAN_EXPORT,
    rootProps: {
      className: 'slate-bbk-expression-clean-render',
      prefix: '@',
    },
  },
};
