import type { DefaultRootState } from 'react-redux';
import { selectCurrentUser } from '@bbkAdminRedux/rtkq/current-user.slice';

export const selectAppState = (state: DefaultRootState) => state.app;

export const selectCompany = (state: DefaultRootState) =>
  selectAppState(state).activeCompany;

export const selectApp = (state: DefaultRootState) =>
  selectAppState(state).activeApp;

export const selectCompanyName = (state: DefaultRootState) =>
  selectCompany(state)?.name;

export const selectAppDisplayName = (state: DefaultRootState) =>
  selectApp(state)?.display_name;

export const selectUser = (state: DefaultRootState) => selectCurrentUser(state);

export const selectCompanyInternalName = (state: DefaultRootState) =>
  selectCompany(state)?.internal_name ?? '_';

export const selectCompanyId = (state: DefaultRootState): number | string =>
  selectCompany(state)?.id ?? '_';

export const selectAppId = (state: DefaultRootState) =>
  selectApp(state)?.encoded_id ?? '_';
