// @ts-nocheck
import { SlatePlugin } from '@udecode/slate-plugins-core';
import { setDefaults } from '@udecode/slate-plugins';
import { DEFAULTS_BBK_EXPRESSION_CLEAN_EXPORT } from './defaults';
import { deserializeBbkExpressionCleanExport } from './deserialize-clean-export';
import { renderElementBbkExpressionCleanExport } from './render-element-bbk-expression-clean-export';

export const BbkExpressionPluginCleanExport = (options): SlatePlugin => {
  const { bbkExpressionCleanExport } = setDefaults(
    options,
    DEFAULTS_BBK_EXPRESSION_CLEAN_EXPORT
  );

  return {
    renderElement: renderElementBbkExpressionCleanExport(options),
    deserialize: deserializeBbkExpressionCleanExport(options),
    inlineTypes: [bbkExpressionCleanExport.type],
    voidTypes: [bbkExpressionCleanExport.type],
  };
};
