import { useTitle } from 'react-use';
import { useSelector } from 'react-redux';
import { isString, toUpper } from 'lodash';
import {
  selectAppDisplayName,
  selectCompanyName,
} from '@bbkAdminRedux/app/selectors';

export const usePageTitle = () => {
  const companyName = useSelector(selectCompanyName);
  const appName = useSelector(selectAppDisplayName);

  const envMeta = document.querySelector('meta[name="environment"]');
  const env = envMeta?.getAttribute('value');
  let envPref = '';

  if (env !== 'prod' && env) {
    envPref = `[${toUpper(env)}] `;
  }

  let title = 'Chargebee Retention';
  let companyTitle = [companyName, appName].filter(isString);
  if (companyTitle.length === 1) {
    title = companyTitle.join('');
  } else if (companyTitle.length > 1) {
    title = companyTitle.join(' | ');
  }

  title = `${envPref}${title}`;

  useTitle(title);
};
