import type { ReactElement } from 'react';
import React from 'react';
import classNames, { Argument } from 'classnames';
import './styles/buttons.scss';
import type { TippyProps } from '@tippyjs/react';
import TippyToolTip from '@bbkAdminComponents/tooltips/tippy-tooltip';

type Props = {
  title: React.ReactNode;
  classes?: Argument;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: React.ReactNode;
  iconClass?: Argument;
  disabled?: boolean;
  tabIndex?: number;
  tooltip?: {
    tippyOptions?: TippyProps;
    content: React.ReactNode;
    children?: ReactElement;
  };
};

const ActionButton: React.FC<Props> = React.forwardRef<
  HTMLButtonElement,
  Props
>((props, ref) => {
  const {
    classes,
    onClick,
    disabled,
    icon,
    title,
    iconClass,
    tabIndex,
    tooltip,
  } = props;

  const btnComponent = (
    <button
      className={classNames('bbk-button', 'action-button', classes)}
      onClick={onClick}
      disabled={disabled}
      tabIndex={tabIndex}
      type="button"
      ref={ref}
    >
      {icon && <span className={classNames('ab-icon', iconClass)}>{icon}</span>}
      {title}
    </button>
  );

  return tooltip ? (
    <TippyToolTip {...tooltip}>
      <span>{btnComponent}</span>
    </TippyToolTip>
  ) : (
    btnComponent
  );
});

export default ActionButton;
