export type UseQuerySubscriptionOptions = {
  skip?: boolean;
  refetchOnMountOrArgChange?: boolean | number;
  pollingInterval?: number;
  refetchOnReconnect?: boolean;
  refetchOnFocus?: boolean;
};

export const isPromiseFulfilled = <T>(
  x: PromiseSettledResult<T>
): x is PromiseFulfilledResult<T> => x.status === 'fulfilled';

export const isPromiseFailed = <T>(
  x: PromiseSettledResult<T>
): x is PromiseRejectedResult => x.status === 'rejected';

export const isDefined = <T>(x: T | undefined | null): x is T =>
  x !== undefined && x !== null;

export const encodeObject = <
  T extends Record<string, string | number | boolean | null | undefined>,
>(
  obj: T
): string => {
  const nonNilEntries = Object.entries(obj).filter(([, v]) => isDefined(v)) as [
    string,
    NonNullable<T[keyof T]>,
  ][];

  return nonNilEntries
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');
};

export const encodeObjectForQuery: typeof encodeObject = <
  T extends Record<string, string | number | boolean | null | undefined>,
>(
  obj: T
): string => {
  const result = encodeObject(obj);

  return result ? `?${result}` : '';
};
