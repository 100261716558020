export const createNodeCallbackHandler =
  (resolve: CallableFunction, reject: CallableFunction) =>
  (err: unknown, result: unknown) => {
    if (result) {
      resolve(result);
    } else {
      reject(err);
    }
  };
export const createNodeCallbackObjectHandler = (
  resolve: CallableFunction,
  reject: CallableFunction
) => ({
  onSuccess: (result: unknown) => {
    resolve(result);
  },
  onFailure: (err: unknown) => {
    reject(err);
  },
});

export const getNowInSeconds = () => Math.floor(Date.now() / 1000);
