import React from 'react';
import RoundButton from '@bbkAdminComponents/buttons/round-button';
import { TourStoryProps } from './tour-story-index';

const ResponsiveImage = () => (
  <img src="/assets/images/tour/audiences-and-targeting.png" />
);

const ProductTourStoryAudiencesAndTargeting = ({ onClick }: TourStoryProps) => (
  <div className="bbk-product-tour-story-template bbk-product-tour-story-audiences-and-targeting">
    <article>
      <h1>Audiences & Targeting</h1>
      <figure>
        <ResponsiveImage />
      </figure>
      <p>
        Chargebee Retention provides several advanced testing and targeting
        features beyond the simple A/B test we setup previously. We provide you
        the flexibility to choose to manage things yourself or let Chargebee
        Retention’s ML model take over and optimize performance.
      </p>
      <p>
        <strong>Manual Audience Testing</strong> - setup eligibility rules (ex:
        trial vs. paid, monthly v. annual, high value v. low value) and test
        different Offer content within each based on what offers you are willing
        to show them.
      </p>
      <p>
        <strong>ML Model testing</strong> - Chargebee Retention’s ML model will
        use the fields you pass us to dynamically present the Experience with
        the highest probability of saving the customer based on hundreds of
        signals.
      </p>
      <p>
        These features are available on our Performance Plan and allow you to
        maximize your Deflection Funnel performance in Chargebee Retention.
        Contact us to learn more.
      </p>

      <footer>
        <p>
          <RoundButton onClick={onClick}>
            Next: Mastering Retention with Chargebee Retention
          </RoundButton>
        </p>
      </footer>
    </article>
  </div>
);

export default {
  name: 'Audiences and Targeting',
  key: 'bbk-pt-audiences-and-targeting',
  story: <ProductTourStoryAudiencesAndTargeting />,
};
