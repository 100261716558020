import React from 'react';
import classNames, { Argument } from 'classnames';
import icons from '@bbkAdminUtils/icons';
import TippyTooltip from '../../tooltips/tippy-tooltip';

import './radio-button.scss';
import { css } from '@emotion/react';

export type RadioButtonBase = {
  id?: string;
  name?: string;
  icon?: keyof typeof icons;
  classes?: Argument;
  handleChange?: React.ChangeEventHandler<HTMLInputElement>;
  handleClick?: React.MouseEventHandler<HTMLInputElement>;
  checked: boolean;
  label?: React.ReactNode;
  labelClass?: Argument;
  questionContent?: React.ReactNode;
  disabled?: boolean;
};
const RadioButton: React.FC<RadioButtonBase> = (props) => {
  const {
    id,
    name,
    checked,
    classes,
    disabled,
    handleChange,
    handleClick,
    icon,
    label = '',
    labelClass,
    questionContent,
  } = props;

  return (
    <div className="bbk-radio-button-wrapper">
      <label
        className={classNames('bbk-radio-button-label', classes, { disabled })}
        htmlFor={id}
      >
        <input
          type="checkbox"
          className="bbk-radio-button-input"
          id={id}
          name={name}
          onChange={handleChange}
          onClick={handleClick}
          checked={checked}
          disabled={disabled}
        />
        <div className="bbk-radio-button-indicator">{icons.checkmark}</div>
        <div className="bbk-radio-button-inner-group">
          {icon && <div className="bbk-radio-button-icon">{icons[icon]}</div>}
          <div
            className={classNames(
              'flex bbk-radio-button-label-label',
              labelClass
            )}
            css={css`
              padding-right: 15px;
            `}
          >
            {label}
          </div>
          {questionContent && (
            <span className="bbk-radio-button-question-icon">
              <TippyTooltip
                content={questionContent}
                tippyOptions={{
                  delay: [500, 0],
                }}
              >
                {icons.questionMark}
              </TippyTooltip>
            </span>
          )}
        </div>
      </label>
    </div>
  );
};

export default RadioButton;
