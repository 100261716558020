import BrightbackSession from '@bbkAdminUtils/brightback-session';
import type { BbkClientConfig } from '@bbkRoot/typings';
import { AuthGoogle } from '@bbkAdminUtils/auth-google';
import { AuthChargebee } from '@bbkAdminUtils/auth-chargebee';
import { AuthUsername } from '@bbkAdminUtils/auth-username';
import type {
  GetSignInUrlArgs,
  SignInArgs,
  SignInTokens,
} from '@bbkAdminRedux/rtkq/brightback-auth.slice';
import { SignInType } from '@bbkAdminRedux/rtkq/brightback-auth.slice';
import { recordError } from '@bbkAdminUtils/recordError';

export type SignOutArgs = {
  type: SignInType;
  refresh_token?: string;
};

export type RefreshTokenArgs = {
  type: SignInType;
  refresh_token: string;
};

export class BrightbackAuth extends AuthUsername {
  private googleAuth: AuthGoogle;

  private chargebeeAuth: AuthChargebee;

  constructor(config: BbkClientConfig) {
    super(config);
    this.googleAuth = new AuthGoogle(config);
    this.chargebeeAuth = new AuthChargebee(config);
  }

  public async signOut({ refresh_token, type }: SignOutArgs): Promise<void> {
    const isGoogle = type === SignInType.GOOGLE;
    const isChargebee = type === SignInType.CHARGEBEE;
    const isCognitoUsername = type === SignInType.USERNAME;

    try {
      if (isGoogle || isCognitoUsername) {
        await this.googleAuth.signOut(refresh_token); // should be used for all users, not just google
      }

      if (isCognitoUsername) {
        this.signOutUsername();
      }
    } catch (err) {
      recordError(err);
    }

    BrightbackSession.clear();

    if (isChargebee) {
      window.location.assign(AuthChargebee.signOutUrl);
    } else {
      window.location.reload();
    }
  }

  public async refreshTokens({
    refresh_token,
    type,
  }: RefreshTokenArgs): Promise<SignInTokens> {
    switch (type) {
      case SignInType.GOOGLE:
        return this.googleAuth.doRefresh(refresh_token);
      case SignInType.CHARGEBEE:
        return this.chargebeeAuth.doRefresh(refresh_token);
      case SignInType.USERNAME:
        return this.doRefresh(refresh_token);
      default:
        throw new Error('User is signed in with unsupported type');
    }
  }

  public signIn(opts: SignInArgs): Promise<SignInTokens> {
    switch (opts.type) {
      case SignInType.USERNAME:
        return this.signInUsername(opts);
      case SignInType.GOOGLE:
        return this.googleAuth.signIn();
      case SignInType.CHARGEBEE:
        return this.chargebeeAuth.signIn(opts);
      default:
        throw new Error('Invalid sign-in type');
    }
  }

  public getSignInUrl(opts: GetSignInUrlArgs): Promise<string> {
    switch (opts.type) {
      case SignInType.CHARGEBEE: {
        return Promise.resolve(this.chargebeeAuth.getSignInUrl(opts));
      }
      default: {
        throw new Error('Invalid sign-in type');
      }
    }
  }
}
