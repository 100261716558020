import { useMemo } from 'react';
import type { TypedUseQueryStateResult } from '@reduxjs/toolkit/query/react';
import { toDictionary } from '@bbkAdminRedux/redux_utils';
import type {
  BBK_FETCH_BASE_QUERY,
  BBKRequestContextArgs,
} from '@bbkAdminRedux/rtkq/bbkApi.slice';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import type { AppKey } from '@bbkAdminRedux/rtkq/current-user.slice';
import { DEFAULT_BRAND_ID } from '@bbkAdminRedux/rtkq/brands.slice';

export const ESSENTIALS_PLAN_MAX_PAGES = 5;
export const ESSENTIALS_PLAN_MAX_PAGES_TEXT = 'five';

export enum FunnelType {
  DEFLECT = 'DEFLECT',
  LIFECYCLE = 'LIFECYCLE',
}

type GetFunnelsArgs = {
  appKey: string;
  brandId?: string;
  funnelType?: FunnelType;
} & BBKRequestContextArgs;

type GetFunnelRoutingMetadataArgs = {
  appKey: string;
  brandId?: string;
} & BBKRequestContextArgs;

type GetLiveFunnelsArgs = {
  appKey: string;
} & BBKRequestContextArgs;

export type RestFunnel = {
  id: number;
  encoded_id: string;
  application_id: number;
  experiment_id: string;
  active: boolean;
  display_name?: string;
  definition: string;
  type?: FunnelType;
  traffic_percent: number;
  created_at: string;
  updated_at: string;
  is_control: boolean;
  is_template: boolean;
  brand_id?: string;
  published_at?: string;
  publish_state?: 'live' | 'draft';
  offer_name_offer_placement_info?: OfferNameOfferPlacementInfo;
  cancelation_request?: 'url' | 'direct' | 'webhook' | 'email';
};

export type OfferNameOfferPlacementInfo = {
  [key: string]: OfferPlacement;
};

export type OfferPlacement = {
  vanity_reasons_list: number[];
  final_offer: boolean;
  first_load_offer: boolean;
  loss_aversion_left: boolean;
  loss_aversion_right: boolean;
};

export type RestExperiment = {
  id: string;
  display_name: string;
  active: boolean;
  master: boolean;
  traffic_percent: number;
  created_at: string;
  funnels: RestFunnel[];
};
export type RestExperiments = {
  experiments: RestExperiment[];
};

type SaveFunnelsArgs = {
  appKey: string;
  experimentId: string;
  funnels: RestFunnel[];
  funnelType?: FunnelType;
};

type CopyFunnelArgs = {
  appKey: string;
  companyKey: string;
  funnelId: number;
};

export type FunnelRoutingMetadata = {
  funnel_key: string;
  is_control: boolean;
  is_fallback: boolean;
  audience_segments: number;
};

export const funnelsSlice = bbkApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFunnels: build.query<RestFunnel[], GetFunnelsArgs>({
      query: ({ appKey, context, funnelType }) => {
        return {
          url: `/api/v1/apps/${appKey}/experiments`,
          method: 'GET',
          params: {
            context: context,
            funnel_type: funnelType,
          },
        };
      },
      transformResponse: (response: RestExperiments) =>
        response.experiments.filter((e) => e.active).flatMap((e) => e.funnels),
      providesTags: ['Funnels'],
    }),
    getFunnelRoutingMetadata: build.query<
      FunnelRoutingMetadata[],
      GetFunnelRoutingMetadataArgs
    >({
      query: (args) => ({
        url: `/api/v1/apps/${args.appKey}/funnel_routing_metadata`,
        method: 'GET',
        params: {
          context: args.context,
          brand_id: args.brandId,
        },
      }),
      providesTags: ['FunnelRoutingMetadata'],
    }),
    getLiveFunnels: build.query<RestFunnel[], GetLiveFunnelsArgs>({
      query: (args) => ({
        url: `/api/v1/apps/${args.appKey}/live_funnels`,
        method: 'GET',
        params: {
          context: args.context,
        },
      }),
      providesTags: ['LiveFunnels'],
    }),
    saveFunnels: build.mutation<void, SaveFunnelsArgs>({
      query: ({ appKey, experimentId, funnelType, ...body }) => ({
        method: 'POST',
        url: `/api/v1/apps/${appKey}/experiments/${experimentId}/funnels`,
        params: {
          funnel_type: funnelType,
        },
        body,
      }),
      invalidatesTags: ['Funnels', 'Reasons'],
    }),
    copyFunnel: build.mutation<void, CopyFunnelArgs>({
      query: ({ companyKey, appKey, funnelId }) => ({
        method: 'POST',
        url: `/api/company/${companyKey}/app/${appKey}/funnel/${funnelId}/copy`,
      }),
      invalidatesTags: ['Funnels'],
    }),
    updateCancelationRequest: build.mutation<
      void,
      { app_key: string; funnel_id: number; cancelation_request: string }
    >({
      query: (args) => ({
        url: `/api/v1/apps/${args.app_key}/funnel/${args.funnel_id}/cancelation_request?request_type=${args.cancelation_request}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Funnels'],
    }),
    createPagesAndTargeting: build.mutation<
      void,
      {
        appKey: AppKey;
        offersIdsPercentages: { offer_id: string; percent: number }[];
      }
    >({
      query: ({ appKey, offersIdsPercentages }) => ({
        url: `/api/v1/apps/${appKey}/funnels_by_offers`,
        method: 'POST',
        body: { offers: offersIdsPercentages },
      }),
      invalidatesTags: [
        'Funnels',
        'Routing',
        'PageAndOfferCounts',
        'Experience',
        'Placements',
        'Offers',
      ],
    }),
  }),
});

export const useSelectAllFunnels = (args: GetFunnelsArgs) => {
  const { data } = funnelsSlice.endpoints.getFunnels.useQuery(args);
  return useMemo(() => data ?? [], [data]);
};

function matchBrand(f: RestFunnel, brandId?: string) {
  return brandId && brandId !== DEFAULT_BRAND_ID
    ? f.brand_id === brandId
    : !f.brand_id;
}

export const useSelectAllFunnelsEntities = (args: GetFunnelsArgs) => {
  const funnels = useSelectAllFunnels(args);
  return useMemo(() => toDictionary(funnels), [funnels]);
};

export const useSelectActiveFunnels = (args: GetFunnelsArgs) => {
  const funnels = useSelectAllFunnels(args);
  return useMemo(
    () => funnels.filter((f) => f.active),
    [funnels, args.brandId]
  );
};

export const useSelectBrandFunnels = (args: GetFunnelsArgs) => {
  const funnels = useSelectAllFunnels(args);
  return useMemo(
    () =>
      args.brandId
        ? funnels.filter((f) => matchBrand(f, args.brandId))
        : funnels,
    [funnels, args.brandId]
  );
};

export const useSelectBrandActiveFunnels = (args: GetFunnelsArgs) => {
  const funnels = useSelectAllFunnels(args);
  return useMemo(
    () => funnels.filter((f) => f.active && matchBrand(f, args.brandId)),
    [funnels, args.brandId]
  );
};

export const useSelectArchivedFunnels = (args: GetFunnelsArgs) => {
  const funnels = useSelectAllFunnels(args);
  return useMemo(
    () => funnels.filter((f) => !f.active && matchBrand(f, args.brandId)),
    [funnels, args.brandId]
  );
};

export const useSelectTemplateFunnel = (args: GetFunnelsArgs) => {
  const funnels = useSelectAllFunnels(args);
  return useMemo(
    () => funnels.find((f) => f.is_template && matchBrand(f, args.brandId)),
    [funnels, args.brandId]
  );
};

export const useSelectControlFunnel = (args: GetFunnelsArgs) => {
  const funnels = useSelectAllFunnels(args);
  return useMemo(
    () => funnels.find((f) => f.is_control && matchBrand(f, args.brandId)),
    [funnels, args.brandId]
  );
};

export const useSelectFunnelById = (args: GetFunnelsArgs, funnelId: number) => {
  const funnels = useSelectAllFunnels(args);
  return useMemo(
    () => funnels.find((f) => f.id === funnelId),
    [funnels, funnelId]
  );
};

type ExperienceSelectorArgs = TypedUseQueryStateResult<
  RestFunnel[],
  GetFunnelsArgs,
  typeof BBK_FETCH_BASE_QUERY
>;
export const selectTemplateFunnel = (res: ExperienceSelectorArgs) => ({
  ...res,
  data: res.data?.find(
    (f) => f.is_template && matchBrand(f, res.originalArgs?.brandId)
  ),
});
export const selectControlFunnel = (res: ExperienceSelectorArgs) => ({
  ...res,
  data: res.data?.find(
    (f) => f.is_control && matchBrand(f, res.originalArgs?.brandId)
  ),
});
