import React from 'react';
import cs from 'classnames';
import type { Error } from '@bbkAdminComponents/log-in-flow/log-in-types';

type Props = {
  error: Error;
};
export const LogInError: React.FC<Props> = ({ error: { title, message } }) => (
  <div
    className={cs(
      'tw-p-4 tw-my-4 tw-mx-3',
      'tw-text-sm',
      'tw-bg-cbrdanger-50 tw-text-cbrprimary-700 tw-rounded'
    )}
  >
    {title && (
      <h2 className="tw-block tw-text-base tw-text-cbrdanger-400">{title}</h2>
    )}
    {message}
  </div>
);
