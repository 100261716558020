import { RestBranding } from '@bbkAdminRedux/rtkq/experience.slice';
import { GlobalUrls } from '@bbkAdminRedux/rtkq/company-app-settings.slice';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import { Rule } from '@bbkAdminRedux/rtkq/rules.slice';
import { useMemo } from 'react';
import { SelectOption } from '@bbkAdminComponents/select/select';
import { FeatureFlagsE, useFeatureFlags } from '@bbkAdminUtils/feature-flags';
import { RestFunnel } from '@bbkAdminRedux/rtkq/funnels.slice';
import { useSelector } from 'react-redux';
import { selectBrand } from '@bbkAdminRedux/rtkq/app-persist/app-persist.slice';
import { keyBy } from 'lodash';

export const DEFAULT_BRAND_ID = 'default';

export type Brand = {
  id: string;
  display_name: string;
  application_id: string;
  domain: string;
  rule_id?: string;
  modified_at: string;
  created_at: string;
  rule?: Rule;
  targeting_enabled: boolean;
};

export type BrandOptionType = {
  data: { targeting_enabled: boolean };
} & SelectOption<string>;

export type BrandWithBranding = {
  brand: Brand;
  branding: RestBranding;
  global_urls: GlobalUrls;
};

export enum BrandStatusUpdateType {
  active = 'active',
  targeting_enabled = 'targeting_enabled',
}

export type CreateBrandDto = {
  brand: {
    display_name: string;
    application_id: string;
    domain: string;
  };
  branding: RestBranding;
  global_urls: GlobalUrls;
};

export type BrandRuleDto = {
  application_id: string;
  active?: boolean;
  client_formatted_rule: string;
  display_name?: string;
  id?: string;
};

export type UpdateBrandDto = {
  brand: {
    display_name: string;
    application_id: string;
    domain: string;
    rule_id?: string;
    rule?: BrandRuleDto;
  };
  branding: RestBranding;
  global_urls: GlobalUrls;
};

export const brandSlice = bbkApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBrands: builder.query<Brand[], { appKey: string }>({
      query: ({ appKey }) => ({
        url: `/api/v1/apps/${appKey}/brands`,
      }),
      transformResponse: (response: { collection: Brand[] }) =>
        response.collection,
      providesTags: ['AdditionalBrands'],
    }),
    getBrand: builder.query<
      BrandWithBranding,
      { appKey: string; brandId: string }
    >({
      query: ({ appKey, brandId }) => ({
        url: `/api/v1/apps/${appKey}/brands/${brandId}`,
      }),
      providesTags: (result, error, arg, meta) => [
        {
          type: 'AdditionalBrands',
          id: arg.brandId,
        },
      ],
    }),
    toggleBrandTargeting: builder.mutation<
      BrandWithBranding,
      { brandId: string; appKey: string; updatedValue: boolean }
    >({
      query: ({ brandId, appKey, updatedValue }) => ({
        url: `/api/v1/apps/${appKey}/brands/${brandId}/update/${BrandStatusUpdateType.targeting_enabled}`,
        method: 'PUT',
        body: { value: updatedValue },
      }),
      invalidatesTags: (result, error, arg, meta) => [
        'AdditionalBrands',
        { type: 'AdditionalBrands', id: arg.brandId },
      ],
    }),
    createOrUpdateBrand: builder.mutation<
      BrandWithBranding,
      { payload: UpdateBrandDto | CreateBrandDto; appKey: string }
    >({
      query: ({ appKey, payload }) => ({
        url: `/api/v1/apps/${appKey}/brand`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['AdditionalBrands'],
    }),
  }),
});

export const useGetBrandOptions = (args: {
  appKey: string;
}): BrandOptionType[] => {
  const { data } = brandSlice.endpoints.getBrands.useQuery(args);
  const hideDefaultBrand = useFeatureFlags(FeatureFlagsE.HIDE_DEFAULT_BRAND);
  const additionalBrands = useFeatureFlags(
    FeatureFlagsE.ADDITIONAL_BRANDS_ENABLED
  );
  return useMemo(() => {
    if (data && additionalBrands) {
      const brandOptions = data
        .map((b) => ({
          label: b.display_name,
          value: b.id,
          data: { targeting_enabled: b.targeting_enabled },
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      if (!hideDefaultBrand) {
        brandOptions.splice(0, 0, {
          label: 'Default',
          value: DEFAULT_BRAND_ID,
          data: { targeting_enabled: false },
        });
      }
      return brandOptions;
    }
    return [];
  }, [data]);
};
export const useGetSelectedBrand = (appKey: string) => {
  const selectedBrand = useSelector(selectBrand(appKey));
  const brandOptions = useGetBrandOptions({ appKey });
  return useMemo(
    () =>
      brandOptions.find((b) => b.value === selectedBrand.id)?.value ??
      brandOptions[0]?.value,
    [selectedBrand, brandOptions]
  );
};
export const getBrandFunnelFilter = (brandId?: string) => (item: RestFunnel) =>
  brandId && brandId !== DEFAULT_BRAND_ID
    ? item.brand_id === brandId
    : !item.brand_id;

export const useGetBrandsMap = (args: { appKey: string }) => {
  const { data } = brandSlice.endpoints.getBrands.useQuery(args);
  return useMemo(() => {
    return keyBy(data, (x) => x.id);
  }, [data]);
};
