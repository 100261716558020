import React from 'react';
import RoundButton from '@bbkAdminComponents/buttons/round-button';
import { TourStoryProps } from './tour-story-index';

const ResponsiveImage = () => <img src="/assets/images/tour/test-design.png" />;

const ProductTourStoryTestDesign = ({ onClick }: TourStoryProps) => (
  <div className="bbk-product-tour-story-template bbk-product-tour-story-test-design">
    <article>
      <h1>Set up a test using Targeting</h1>
      <figure>
        <ResponsiveImage />
      </figure>
      <p>
        The Targeting Tab in Chargebee Retention allows you to configure and
        deploy your initial cancel test. On the Essentials Plan you can either
        setup an A/B test or build simple Audience Rules to target specific
        pages at certain customers.
      </p>
      <p>We recommend you start with a simple a/b test with a Holdout.</p>
      <p>
        Add your two test pages to the Targeting and split traffic evenly
        between these Pages. Set 5% of your traffic to your Control page and
        publish.
      </p>
      <p>
        Chargebee Retention's Performance Plan allows you to setup multi-page
        tests across Audiences.
      </p>

      <footer>
        <p>
          <RoundButton onClick={onClick}>
            Next: How to Analyze Performance
          </RoundButton>
        </p>
      </footer>
    </article>
  </div>
);

export default {
  name: 'Test Design',
  key: 'bbk-pt-test-design',
  story: <ProductTourStoryTestDesign />,
};
