import React, { useMemo } from 'react';
import type { Argument } from 'classnames';
import classNames from 'classnames';
import type { SerializedStyles } from '@emotion/react';
import { getRandomInt } from '@bbkAdminComponents/experiences/offers/utils';
import DotIndicator from '@bbkAdminComponents/indicators/dot';
import './_toggle.scss';
import TippyToolTip from '@bbkAdminComponents/tooltips/tippy-tooltip';
import icons, { CBRIcons } from '@bbkAdminUtils/icons';

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
type ToggleTheme =
  | 'theme-dark-green'
  | 'theme-always-colored'
  | 'theme-always-green'
  | 'theme-new-purple'
  | 'theme-always-purple'
  | 'theme-dark-purple'
  | 'theme-large-label'
  | 'theme-small-label';
export type Props = Pick<
  InputProps,
  'name' | 'id' | 'checked' | 'disabled' | 'readOnly'
> & {
  handleChange?: React.ChangeEventHandler<HTMLInputElement>;
  label?: React.ReactNode;
  leftLabel?: React.ReactNode;
  wrapperStyles?: SerializedStyles;
  labelStyles?: SerializedStyles;
  leftLabelStyles?: SerializedStyles;
  rightLabelStyles?: SerializedStyles;
  classes?: Argument;
  dotColor?: React.ComponentProps<typeof DotIndicator>['dotColor'];
  theme?: ToggleTheme | ToggleTheme[];
  small?: boolean;
  xs?: boolean;
  helpText?: React.ReactNode;
  helpDocLink?: string;
  helpTextLeft?: boolean;
  restrictionTooltip?: React.ReactNode;
};

export const externalLinkIcon = (
  <span css={{ svg: { height: '10px' } }}>{icons.externalLink}</span>
);

const Toggle: React.FC<Props> = (props) => {
  const {
    classes,
    wrapperStyles,
    label,
    leftLabel,
    labelStyles,
    leftLabelStyles,
    rightLabelStyles,
    dotColor: propsDotColor,
    theme,
    small,
    xs,
    handleChange,
    id: propsId,
    name,
    checked,
    disabled,
    readOnly,
    helpText,
    helpDocLink,
    helpTextLeft = false,
    restrictionTooltip,
  } = props;

  const id = useMemo(() => propsId ?? `${getRandomInt()}`, [propsId]);

  const dotColor =
    propsDotColor !== undefined ? propsDotColor : checked ? 'white' : 'black30';

  const helpTextComponent = (
    <span className="tw-pl-1">
      <TippyToolTip
        tippyOptions={{
          delay: [300, 0],
        }}
        content={
          <>
            {helpText}{' '}
            {helpDocLink && (
              <a href={helpDocLink} target="_blank" rel="noreferrer">
                Help Doc. {externalLinkIcon}
              </a>
            )}
          </>
        }
      >
        <span>{icons.questionMark}</span>
      </TippyToolTip>
    </span>
  );

  return (
    <div
      className={classNames(
        'bbk-toggle-wrapper',
        classes,
        {
          'bbk-toggle-disabled': disabled,
          'theme-sm': small,
          'theme-xs': xs,
        },
        theme
      )}
      css={[wrapperStyles]}
    >
      {leftLabel && (
        <label
          className="bbk-toggle-label"
          css={[labelStyles, leftLabelStyles]}
          htmlFor={id}
        >
          {leftLabel}
          {helpTextLeft && helpText && helpTextComponent}
        </label>
      )}
      <label className="bbk-toggle-switch" htmlFor={id}>
        <input
          type="checkbox"
          className="bbk-toggle-input"
          id={id}
          name={name}
          onChange={(e) => (disabled ? null : handleChange?.(e))}
          checked={checked}
          disabled={disabled}
          readOnly={readOnly}
        />
        <div className="bbk-toggle-slider">
          <div className="bbk-toggle-slider-dot">
            <DotIndicator dotColor={dotColor} />
          </div>
        </div>
      </label>
      {label && (
        <label
          className="bbk-toggle-label"
          css={[labelStyles, rightLabelStyles]}
          htmlFor={id}
        >
          {label}
          {!helpTextLeft && helpText && helpTextComponent}
        </label>
      )}
      {restrictionTooltip && (
        <div>
          <TippyToolTip
            tippyOptions={{
              delay: [300, 0],
              zIndex: 10,
            }}
            content={restrictionTooltip}
          >
            <span className="tw-text-cbrneutral-500">
              {CBRIcons.cbrLock(20, 20)}
            </span>
          </TippyToolTip>
        </div>
      )}
    </div>
  );
};

export default Toggle;
