/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { RestModalAction } from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import type { SelectOption } from '@bbkAdminComponents/select/select';
import GenericSelect from '@bbkAdminComponents/select/select';
import { EditOfferPageContext } from '@bbkAdminComponents/experiences/offers/edit-offer-page-context';
import {
  OfferObjective,
  OfferPresentation,
} from '@bbkAdminUtils/api-client/rest-offers-api';
import { FeatureFlagsE, useFeatureFlags } from '@bbkAdminUtils/feature-flags';

type RestModalActionsAvailableForChoice =
  | typeof RestModalAction.link
  | typeof RestModalAction.accept_offer_with_confirmation
  | typeof RestModalAction.modal_email
  | typeof RestModalAction.modal_email_structured
  | typeof RestModalAction.send_email
  | typeof RestModalAction.cancel_with_link
  | typeof RestModalAction.nevermind
  | typeof RestModalAction.dismiss_modal;

export const ACTIONS_AVAILABLE_FOR_CHOICE: RestModalActionsAvailableForChoice[] =
  [
    RestModalAction.link,
    RestModalAction.accept_offer_with_confirmation,
    RestModalAction.modal_email,
    RestModalAction.modal_email_structured,
    RestModalAction.send_email,
    RestModalAction.cancel_with_link,
    RestModalAction.nevermind,
    RestModalAction.dismiss_modal,
  ];

const actionOpts = (
  isCancelNevermind: boolean
): SelectOption<RestModalActionsAvailableForChoice>[] => [
  {
    label: 'Link',
    value: RestModalAction.link,
    isDisabled: isCancelNevermind,
  },
  {
    label: 'Confirmation modal',
    value: RestModalAction.accept_offer_with_confirmation,
    isDisabled: isCancelNevermind,
  },
  {
    label: 'Collect feedback (freeform)',
    value: RestModalAction.modal_email,
    isDisabled: isCancelNevermind,
  },
  {
    label: 'Collect feedback (structured)',
    value: RestModalAction.modal_email_structured,
    isDisabled: isCancelNevermind,
  },
  {
    label: 'Send email with confirmation',
    value: RestModalAction.send_email,
    isDisabled: isCancelNevermind,
  },
  {
    label: 'Cancel',
    value: RestModalAction.cancel_with_link,
    isDisabled: !isCancelNevermind,
  },
  {
    label: 'Nevermind',
    value: RestModalAction.nevermind,
    isDisabled: !isCancelNevermind,
  },
  {
    label: 'Dismiss',
    value: RestModalAction.dismiss_modal,
    isDisabled: isCancelNevermind,
  },
];
export type ActionEditorProps = Omit<
  React.ComponentProps<typeof GenericSelect>,
  'options' | 'onChange'
> & {
  onChange: (e: { value: RestModalAction }) => unknown;
  isDisabled?: boolean;
  isNevermindCancel?: boolean;
};
export const ActionSelect: React.FC<ActionEditorProps> = ({
  value,
  isNevermindCancel,
  ...restProps
}) => {
  const offerContext = useContext(EditOfferPageContext);

  const isLCOEnabled = useFeatureFlags(FeatureFlagsE.ENABLE_LIFECYCLE_OFFERS);

  const filterAcceptStepOptions = (opt: RestModalAction) => {
    if (!isLCOEnabled || !offerContext) return true;
    const offer = offerContext.offer;
    const isChurnDeflect = offer.objective === OfferObjective.churn_deflection;

    if (offer.presentation === OfferPresentation.banner) {
      return opt === RestModalAction.link ||
        opt === RestModalAction.dismiss_modal
        ? true
        : false;
    } else if (!isChurnDeflect) {
      return opt === RestModalAction.link ||
        opt === RestModalAction.dismiss_modal ||
        opt === RestModalAction.accept_offer_with_confirmation ||
        opt === RestModalAction.send_email
        ? true
        : false;
    }
    return true;
  };

  return (
    <GenericSelect
      isSearchable={false}
      placeholder="Pick an action"
      value={actionOpts(isNevermindCancel ?? false).find(
        (x) => x.value === value
      )}
      options={actionOpts(isNevermindCancel ?? false).filter((o) =>
        filterAcceptStepOptions(o.value)
      )}
      short
      {...restProps}
    />
  );
};
