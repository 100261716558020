import { matchPath } from 'react-router-dom';
import { isNil } from 'lodash';
import LocalStorage from '@bbkJavascript/localstorage';
import type { Maybe } from '@bbkAdminUtils/utility-types';
import type { ActiveApp, ActiveCompany } from '@bbkAdminRedux/app/reducers';
import { Cookie } from '@bbkAdminUtils/cookie';
import { getNowInSeconds } from '@bbkAdminUtils/utils';
import { authPersistedSlice } from '@bbkAdminRedux/rtkq/brightback-auth.slice';

const BrightBackLS = new LocalStorage('BrightBackAdminLS');

const getLS = <T = unknown>(key: string) =>
  BrightBackLS.get(key)[1] as Maybe<T>;

const setLS = (key: string, value: unknown) => {
  if (isNil(value)) {
    return BrightBackLS.del(key);
  }
  return BrightBackLS.put(key, value);
};

const BrightbackSession = {
  isTest: () => getLS<`${boolean}`>('isTest') === 'true',
  getSuppressSelectorPage: () => getLS<boolean>('suppressSelectorPage'),
  setSuppressSelectorPage: (suppress: boolean) =>
    setLS('suppressSelectorPage', suppress),
  setActiveCompany: (
    company?: ActiveCompany | Pick<ActiveCompany, 'internal_name'>,
    redirect = true
  ) => {
    // Main logic
    const activeCompanyKey = company?.internal_name;
    if (isNil(activeCompanyKey)) {
      sessionStorage.removeItem('activeCompanyKey');
    } else {
      sessionStorage.setItem('activeCompanyKey', activeCompanyKey);
    }
    setLS('activeCompanyKey', activeCompanyKey);

    // reset active app if it isn't part of company
    if (company && 'applications' in company) {
      const activeAppKey = BrightbackSession.getActiveApp();
      const validApp = activeAppKey
        ? company.applications.find((a) => a.encoded_id === activeAppKey)
        : undefined;
      if (!validApp) {
        BrightbackSession.setActiveApp(company.applications[0]);
      }
    }

    // redirect if not internal page and and redirect is true
    const internalUrlMatched = matchPath(window.location.pathname, {
      path: '/internal',
    });
    const companyUrlMatched = matchPath<{ companyKey: string }>(
      window.location.pathname,
      { path: '/company/:companyKey' }
    );
    if (redirect && !internalUrlMatched) {
      const activeAppKey = BrightbackSession.getActiveApp() || '_';
      const appUrl = `/company/${activeCompanyKey}/app/${activeAppKey}`;
      if (activeCompanyKey) {
        if (
          !companyUrlMatched ||
          companyUrlMatched.params.companyKey !== activeCompanyKey
        ) {
          window.location.assign(appUrl);
        }
      } else if (companyUrlMatched) {
        window.location.assign('/company/');
      }
    }
  },
  setActiveApp: (app?: Pick<ActiveApp, 'encoded_id'>) => {
    const encodedId = app?.encoded_id;
    if (isNil(encodedId)) {
      sessionStorage.removeItem('activeAppKey');
    } else {
      sessionStorage.setItem('activeAppKey', encodedId);
    }
    setLS('activeAppKey', encodedId);
  },
  // if LS is empty or error happened then undefined, otherwise a string
  getActiveCompany: () => {
    const activeCompanyLS = getLS<string>('activeCompanyKey');
    const activeCompanySS = sessionStorage.getItem('activeCompanyKey');
    return activeCompanySS ?? activeCompanyLS;
  },
  getActiveApp: () => {
    // if LS is empty or error happened then undefined, otherwise a string
    const activeAppLS = getLS<string>('activeAppKey');
    const activeAppSS = sessionStorage.getItem('activeAppKey');
    return activeAppSS ?? activeAppLS;
  },
  getAccessToken: () => Cookie.getCookie(),
  setAccessToken: (accessToken: string | null, expiresIn: number) => {
    setLS('accessTokenUpdated', accessToken ? getNowInSeconds() : null);
    setLS('accessTokenExpiresIn', expiresIn);
    if (accessToken === null) {
      Cookie.deleteCookie();
    } else {
      Cookie.setCookie(accessToken, expiresIn);
    }
  },
  setRefreshToken: (refreshToken: string | null) => {
    setLS('refreshToken', refreshToken);
  },
  getRefreshToken: () => getLS<string>('refreshToken'),
  getAccessTokenExpiresIn: () => getLS<number>('accessTokenExpiresIn'),
  setAuthAlert: (alert: string | null) => setLS('authAlert', alert),
  getAuthAlert: () => getLS<string>('authAlert'),
  getAccessTokenType: () => getLS<'google' | 'cbidp'>('accessTokenType'),
  setAccessTokenType: (accessTokenType: 'google' | 'cbidp' | null) =>
    setLS('accessTokenType', accessTokenType),
  getAccessTokenAgeInSeconds: () => {
    const updated = getLS<number>('accessTokenUpdated');
    if (!updated) {
      return 0;
    }
    return getNowInSeconds() - updated;
  },
  clear: () => {
    window.bbkStore.dispatch(authPersistedSlice.actions.clearTokens());
  },
};

export default BrightbackSession;
