import type { BbkClientConfig } from '@bbkRoot/typings';

export const getCurrentBaseURI = () => {
  const ret = `${window.location.protocol}//${window.location.hostname}`;
  const port = window.location.port;
  if (port !== '') {
    return `${ret}:${port}`;
  }
  return ret;
};
export const correctBaseURI = (config: BbkClientConfig) => {
  const current = getCurrentBaseURI();
  if (
    !config.is_test &&
    current !== config.base_uri &&
    current !== config.base_retention_uri &&
    !current.endsWith(':8443') &&
    !current.endsWith(':8080') &&
    !current.includes('host.docker.internal')
  ) {
    if (
      current.includes('chargebee.com') ||
      current.includes('brightback') ||
      current.includes('brtbck.net') ||
      current.includes('localhost')
    ) {
      if (current.includes('chargebee.com')) {
        window.location.replace(
          window.location.toString().replace(current, config.base_retention_uri)
        );
      } else {
        window.location.replace(
          window.location.toString().replace(current, config.base_uri)
        );
      }
    } else {
      // go to TLD
      window.location.replace(
        `http://${window.location.hostname.split('.').slice(-2).join('.')}`
      );
    }
  }
};
