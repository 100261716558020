import React from 'react';
import RoundButton from '@bbkAdminComponents/buttons/round-button';
import { TourStoryProps } from './tour-story-index';

const ResponsiveImage = () => (
  <img src="/assets/images/tour/setup-a-test.png" />
);

const ProductTourStorySetupATest = ({ onClick }: TourStoryProps) => (
  <div className="bbk-product-tour-story-template bbk-product-tour-story-setup-a-test">
    <article>
      <h1>How to setup a test in Chargebee Retention</h1>
      <figure>
        <ResponsiveImage />
      </figure>
      <p>There are two aspects to setting up a test.</p>
      <p>
        <strong>Create a page</strong> - brand your page to match your website
        or app’s brand guidelines using the Experience Manager, then introduce
        offers and loss aversion content to deflect customers from canceling
      </p>
      <p>
        <strong>Design your first test</strong> - use Chargebee Retention’s
        flexible testing and targeting engine to gauge how offers and loss
        aversion content perform relative to one another and/or relative to a no
        offer control experience.
      </p>
      <p>To start, you must first build out your page(s).</p>

      <footer>
        <p>
          <RoundButton onClick={onClick}>
            Next: Build a page using the Experience Manager
          </RoundButton>
        </p>
      </footer>
    </article>
  </div>
);

export default {
  name: 'How to setup a test in Chargebee Retention',
  key: 'bbk-pt-setup-a-test',
  story: <ProductTourStorySetupATest />,
};
