import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  css,
  Global,
  ThemeProvider as EmotionThemeProvider,
} from '@emotion/react';
import _ from 'lodash';
import { processTheme } from '@bbkFunnelUtils/funnel-utils';
import { selectAppId } from '@bbkAdminRedux/app/selectors';
import { experienceSlice } from '@bbkAdminRedux/rtkq/experience.slice';
import SuspenseLoader from '@bbkAdminComponents/loaders/suspense-loader';
import type { CardLevelTheme, EmotionTheme } from '@bbkFunnel/pages/theme';
import { ThemeKeys } from '@bbkFunnel/pages/theme';
import { useGetSelectedBrand } from '@bbkAdminRedux/rtkq/brands.slice';
import type { RestCard } from '@bbkAdminUtils/api-client/rest-experiences-api';

export const useAsyncThemeData = (props: { card?: RestCard } | void) => {
  const card = props?.card;
  const appKey = useSelector(selectAppId);
  const brandId = useGetSelectedBrand(appKey);

  const cardStyleOverlay = useMemo(
    () => _.get(card, 'style_overlay', undefined) as CardLevelTheme | undefined,
    [card]
  );

  return experienceSlice.endpoints.getBranding.useQuery(
    { appKey, brandId },
    {
      selectFromResult: (res) => {
        const branding = res.data;

        if (!branding) return { ...res, data: {} as EmotionTheme };

        const pTheme = processTheme({ layout: branding });

        return {
          ...res,
          data: {
            ...pTheme,
            [ThemeKeys.CARD_LEVEL_THEME]: cardStyleOverlay,
          } as EmotionTheme,
        };
      },
    }
  );
};

type Props = React.PropsWithChildren<{
  modal?: boolean;
  card?: RestCard;
}>;
export const AsyncThemeWrapper: React.FC<Props> = ({
  children,
  modal,
  card,
}) => {
  const { data: processedTheme, isLoading } = useAsyncThemeData({ card });

  if (isLoading) return <SuspenseLoader message="" />;

  const isOfferPack = !!_.get(card, ['requires', 'content_set']);

  return (
    <EmotionThemeProvider theme={processedTheme}>
      <Global
        styles={css`
          .bbk-preview-wrapper {
            ${isOfferPack ? 'width: 100%;' : ''}
            ${processedTheme.global}
          }
        `}
      />
      <span
        css={{
          fontFamily: processedTheme.fontFamily,
          fontSize: processedTheme.fontSize,
          fontWeight: processedTheme.fontWeight,
          color: modal
            ? processedTheme.modalTextColor || processedTheme.textColor
            : processedTheme.textColor,
          background: processedTheme.background,
        }}
      >
        {children}
      </span>
    </EmotionThemeProvider>
  );
};
