import styled from '@emotion/styled';

export const DeleteBtn = styled.button`
  color: rgba(0, 0, 0, 0.35);
  padding: 5;
  font-size: 14px;
  display: flex;
  align-items: center;
  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }
`;
