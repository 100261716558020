import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '@bbkAdminRedux/redux';
import { AdminApp } from '@bbkAdminRouter/admin-app';
import { useDetectIE } from '@bbkAdminUtils/detect-ie';
import { ErrorPage } from '@bbkAdminComponents/app-layout/error';
import { BlackToastContainer } from '@bbkAdminComponents/alerts/black-toast';
import { InfoModalContainer } from '@bbkAdminComponents/info-dialog';

export const Main: React.FC = () => {
  const isIE = useDetectIE();

  if (isIE) {
    return (
      <ErrorPage message="Sorry, IE is not supported.  Please try Chrome, Firefox, or Safari" />
    );
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <InfoModalContainer>
            <AdminApp />
          </InfoModalContainer>
          <BlackToastContainer />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};
