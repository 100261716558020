import React from 'react';
import { Link } from 'react-router-dom';

type TroubleTypes = {
  link?: {
    path: string;
    copy: string;
  };
};

export const LogInTrouble: React.FC<TroubleTypes> = ({
  link: { path, copy } = {},
}) => {
  const className =
    'tw-mb-3 tw-text-sm tw-font-semibold tw-text-cbrprimary-600';
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-mt-4">
      {path && copy && (
        <div className={className}>
          <Link to={path}>{copy}</Link>
        </div>
      )}
      <div className={className}>
        <a
          href="mailto:retention-support@chargebee.com"
          target="_blank"
          rel="noreferrer"
        >
          Trouble signing in?
        </a>
      </div>
    </div>
  );
};
