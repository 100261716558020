import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import type { TypedUseQueryStateResult } from '@reduxjs/toolkit/query/react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { chain } from 'lodash';
import type { Dictionary } from '@bbkAdminUtils/utility-types';
import type {
  Integration,
  IntegrationField,
  IntegrationFieldObject,
  IntegrationFieldsResponse,
  IntegrationFieldWithId,
} from '@bbkAdminRedux/data-management/reducers';
import {
  AppFieldType,
  FieldTypes,
  IntegrationDataType,
  IntegrationFieldTypeEnum,
  IntegrationTypeEnum,
} from '@bbkAdminRedux/data-management/reducers';
import { toDictionary, useAppDispatch } from '@bbkAdminRedux/redux_utils';
import type {
  AppKey,
  DateString,
} from '@bbkAdminRedux/rtkq/current-user.slice';
import { isDefined, isPromiseFulfilled } from '@bbkAdminRedux/rtkq/rtkq-utils';
import type { BBK_FETCH_BASE_QUERY } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import type { Suggestion } from '@bbkAdminComponents/rich-text-editor';
import { FeatureFlagsE, useFeatureFlags } from '@bbkAdminUtils/feature-flags';
import { selectAppId, selectCompany } from '@bbkAdminRedux/app/selectors';
import { canAccessSalesforceIntegration } from '@bbkAdminUtils/plans';
import * as Action from '@bbkAdminRedux/data-management/actions';
import { selectDM } from '@bbkAdminRedux/data-management/selectors';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';

export type IntegrationAPIInput = {
  appId: string;
  integrationId: string;
  inputs?: Record<string, string>;
};

enum StandardFieldType {
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  INTEGER = 'INTEGER',
  DECIMAL = 'DECIMAL',
  DATE = 'DATE',
  EMAIL = 'EMAIL',
  URL = 'URL',
}

export type StandardField = {
  id: number;
  description: string;
  active: boolean;
  created_at: DateString;
  updated_at: DateString;
  internal_name: string;
  name: string;
  order: number;
  type: StandardFieldType;
};

export type StandardFieldsGroup = {
  group: string;
  name: string;
  order: number;
  fields: StandardField[];
};

export type FieldMapping = {
  name: string;
  display_name: string;
  standard: boolean;
  integration_id: number;
  type: IntegrationFieldTypeEnum;
  include_in_customers_report: boolean;
  field: {
    name: string;
    object?: string;
    is_lov?: boolean;
    type?: IntegrationFieldTypeEnum;
    maps_to_type: IntegrationFieldTypeEnum[];
  };
};

export type FieldMappingsResponse = FieldMapping[];

export type IntegrationFieldResponse = {
  key: string;
  display_name: string;
}[];

type GetIntegrationsArgs = { appKey: AppKey };
type GetFieldMappingsArgs = { appKey: AppKey };
type GetStandardFieldsArgs = { companyKey: string; appKey: string };

type GetIntegrationFieldsArgs = {
  appKey: AppKey;
  integrationDataType: IntegrationDataType;
};

type SetExternalIdArgs = {
  appKey: AppKey;
  integrationDataType: IntegrationDataType;
  externalIdObjectName: string;
} & {
  external_id: string;
};

export type CustomFieldBody = {
  objects: [
    {
      fields: IntegrationField[];
    },
  ];
};
type SaveCustomFieldsArgs = {
  appKey: AppKey;
  integrationDataType: IntegrationDataType;
  body: CustomFieldBody;
};

type GetCustomFieldsArgs = {
  appKey: AppKey;
};

type CustomField = `custom.${string}`;
type CustomFieldsResponse = {
  custom_field_names: CustomField[];
};
type GetThirdPartyIntegrationManageUrlArgs = {
  appKey: string;
  integrationId: number;
  redirectUrl: string;
};
export const integrationSlice = bbkApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getThirdPartyIntegrationManageUrl: build.query<
      { url: string },
      GetThirdPartyIntegrationManageUrlArgs
    >({
      query: ({ appKey, integrationId, redirectUrl }) =>
        `/admin/app/${appKey}/integration/${integrationId}/manage?redirectURL=${redirectUrl}`,
    }),
    getStandardFields: build.query<
      StandardFieldsGroup[],
      GetStandardFieldsArgs
    >({
      query: ({ companyKey, appKey }) =>
        `/api/standard_fields/companies/${companyKey}/apps/${appKey}`,
    }),
    getIntegrationFields: build.query<
      IntegrationFieldsResponse,
      GetIntegrationFieldsArgs
    >({
      query: ({ appKey, integrationDataType }) =>
        `/api/v1/apps/${appKey}/integrations/${integrationDataType.toLowerCase()}/fields?${integrationDataType.toLowerCase()}`,
      providesTags: (result, error, arg) => [
        { type: 'IntegrationFields', id: arg.integrationDataType },
      ],
    }),
    getCustomFields: build.query<CustomField[], GetCustomFieldsArgs>({
      query: ({ appKey }) =>
        `/api/v1/apps/${appKey}/integrations/brightbackjs/custom_field_names`,
      transformResponse: (res: CustomFieldsResponse) => res.custom_field_names,
    }),
    saveCustomFields: build.mutation<void, SaveCustomFieldsArgs>({
      query: ({ appKey, integrationDataType, body }) => ({
        url: `/api/v1/apps/${appKey}/integrations/${integrationDataType}/fields`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        if (error) return [];
        return [{ type: 'IntegrationFields', id: arg.integrationDataType }];
      },
    }),
    setExternalId: build.mutation<Integration[], SetExternalIdArgs>({
      query: ({
        appKey,
        integrationDataType,
        externalIdObjectName,
        ...body
      }) => ({
        url: `/api/v1/apps/${appKey}/integrations/${integrationDataType.toLowerCase()}/fields/${externalIdObjectName}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'IntegrationFields', id: arg.integrationDataType },
      ],
    }),
    getFieldMappings: build.query<FieldMappingsResponse, GetFieldMappingsArgs>({
      query: ({ appKey }) => `/api/v1/apps/${appKey}/mappings`,
      providesTags: ['FieldMappings'],
    }),
    saveFieldMappings: build.mutation<
      unknown,
      { appKey: string; mappings: unknown }
    >({
      query: ({ appKey, mappings }) => ({
        url: `/api/v1/apps/${appKey}/mappings`,
        method: 'PUT',
        body: mappings,
      }),
      invalidatesTags: ['FieldMappings'],
    }),
    loadFieldValues: build.query<
      IntegrationFieldResponse,
      {
        appKey: string;
        fieldName: string;
        integrationId: number;
        text?: string;
      }
    >({
      query: ({ appKey, fieldName, integrationId, text = '' }) => ({
        url: `/api/v1/apps/${appKey}/integrations/${integrationId}/fields/${fieldName}/values`,
        params: { text },
      }),
      providesTags: ['IntegrationFieldValues'],
    }),
    getIntegrations: build.query<Integration[], GetIntegrationsArgs>({
      query: ({ appKey }) => `/api/v1/apps/${appKey}/integrations`,
      transformResponse: (response: { integrations: Integration[] }) => {
        const result = response.integrations;

        // we want chargebee be always at the top
        const chargebeeIdx = result.findIndex(
          (x) => x.data_type === IntegrationDataType.CHARGEBEE
        );
        if (chargebeeIdx !== -1) {
          const chargebee = result.splice(chargebeeIdx, 1)[0]!;
          result.unshift(chargebee);
        }
        return result;
      },
      providesTags: ['Integrations'],
    }),
    putIntegration: build.mutation<void, IntegrationAPIInput>({
      query: ({ appId, integrationId, inputs }) => ({
        url: `/api/v1/apps/${appId}/integrations/${integrationId}/inputs`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: { ...inputs },
      }),
      invalidatesTags: [
        'Integrations',
        'CurrentApp',
        'FieldMappings',
        { type: 'BillingConfigs', id: 'GLOBAL' },
      ],
    }),
    deleteIntegration: build.mutation<void, IntegrationAPIInput>({
      query: ({ appId, integrationId }) => ({
        url: `/admin/app/${appId}/integration/${integrationId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'Integrations',
        'CurrentApp',
        'FieldMappings',
        { type: 'BillingConfigs', id: 'GLOBAL' },
      ],
    }),
  }),
});

type IntegrationSelectorArgs = TypedUseQueryStateResult<
  Integration[],
  GetIntegrationsArgs,
  typeof BBK_FETCH_BASE_QUERY
>;
export const selectBillingIntegration = (res: IntegrationSelectorArgs) => ({
  ...res,
  data: res.data?.find(
    (x) => x.integration_type === IntegrationTypeEnum.Billing && x.connected
  ),
});

export const selectBillingIntegrations = (res: IntegrationSelectorArgs) => ({
  ...res,
  data: res.data?.filter(
    (x) => x.integration_type === IntegrationTypeEnum.Billing && x.connected
  ),
});

export const useSelectBillingIntegration = (args?: GetIntegrationsArgs) => {
  const { data: integrations = [] } =
    integrationSlice.endpoints.getIntegrations.useQuery(
      !args || args.appKey === '_' ? skipToken : args
    );

  return useMemo(
    () =>
      integrations.find(
        (x) => x.integration_type === IntegrationTypeEnum.Billing && x.connected
      ),
    [integrations]
  );
};

export const useSelectFieldTypes = () => useMemo(() => FieldTypes, []);

export type LegacySagaFieldMapping = {
  id: string;
  name: string;
  displayName: string;
  integration_id?: number;
  includeInCustomersReport: boolean;
  standard: boolean;
  type?: IntegrationFieldTypeEnum;
  field: {
    object?: string;
    name?: string;
  };
  warning?: {
    name?: string;
    field?: string;
    type?: string;
  };
};

export const useSelectIntegrationsFields = (
  args: GetIntegrationsArgs
): IntegrationFieldsResponse[] => {
  const { data: integrations = [] } =
    integrationSlice.endpoints.getIntegrations.useQuery(args);
  const [fetchIntegrationFields] =
    integrationSlice.endpoints.getIntegrationFields.useLazyQuery();

  const [fields, setFields] = useState<IntegrationFieldsResponse[]>([]);

  useEffect(() => {
    const connected = integrations.filter((x) => x.connected);

    const fetchFields = (x: Integration) =>
      fetchIntegrationFields(
        {
          appKey: args.appKey,
          integrationDataType: x.data_type,
        },
        true
      ).unwrap();

    const results = Promise.allSettled(connected.map(fetchFields));
    results
      .then((res) =>
        setFields(res.filter(isPromiseFulfilled).map((x) => x.value))
      )
      .catch(console.warn);
  }, [args.appKey, integrations]);

  return fields;
};

export const useSelectFlatIntegrationsFields = (
  args: GetIntegrationsArgs
): IntegrationFieldWithId[] => {
  const intObjFields = useSelectIntegrationsFields(args);

  return useMemo(
    () =>
      intObjFields.flatMap((int) =>
        int.objects.flatMap((obj) =>
          obj.fields.map((field) => ({
            ...field,
            integration_id: int.id,
          }))
        )
      ),
    [intObjFields]
  );
};

const useSelectExternalIdMappingsLegacySagaFormat = (
  args: GetIntegrationsArgs
): LegacySagaFieldMapping[] => {
  const integrationsFields = useSelectIntegrationsFields(args);
  const hasExternalIdMapped = (o: IntegrationFieldObject) =>
    o.requires_external_id && o.external_id;

  return useMemo(
    () =>
      integrationsFields
        .map((ifields) => {
          const object = ifields.objects.find(hasExternalIdMapped);
          if (!object) return undefined;
          const integrationId = ifields.id;
          const name = `Instance ID_${integrationId}_${object.name}`;
          return {
            id: name,
            name,
            displayName: name,
            integration_id: integrationId,
            includeInCustomersReport: false,
            standard: true,
            type: IntegrationFieldTypeEnum.external_id,
            field: {
              object: object.name,
              name: object.external_id,
            },
          };
        })
        .filter(isDefined),
    [integrationsFields]
  );
};

const useSelectFieldMappingsListLegacySagaFormat = (
  args: GetFieldMappingsArgs
): LegacySagaFieldMapping[] => {
  const { data } = integrationSlice.endpoints.getFieldMappings.useQuery(args);
  const externalIdMappings = useSelectExternalIdMappingsLegacySagaFormat(args);

  return useMemo(() => {
    const fields = (data ?? []).map((mapping) => ({
      id: mapping.name,
      name: mapping.name,
      displayName: mapping.display_name,
      integration_id: mapping.integration_id,
      includeInCustomersReport: mapping.include_in_customers_report,
      standard: mapping.standard,
      type: mapping.type,
      field: mapping.field,
    }));

    return externalIdMappings.length
      ? [...fields, ...externalIdMappings]
      : fields;
  }, [data, externalIdMappings]);
};

export const useSelectFieldMappingsLegacySagaFormat = (
  args: GetFieldMappingsArgs
): Dictionary<LegacySagaFieldMapping> => {
  const list = useSelectFieldMappingsListLegacySagaFormat(args);

  return useMemo(() => toDictionary(list), [list]);
};

type MappingSelectorArgs = TypedUseQueryStateResult<
  FieldMappingsResponse,
  GetFieldMappingsArgs,
  typeof BBK_FETCH_BASE_QUERY
>;

export const selectFields = (res: MappingSelectorArgs) => ({
  ...res,
  data: chain(res.data ?? [])
    .map(({ name, standard, integration_id, type, field }) => ({
      name,
      val: name,
      key: name,
      standard,
      type,
      is_lov: field?.is_lov,
      field_name: field?.name,
      integration_id,
      fallBackValue: '',
    }))
    .sortBy((x) => x.name)
    .value(),
});

export const selectUrlFields = (res: MappingSelectorArgs) => ({
  ...res,
  data: selectFields(res).data.filter(
    (f) => f.type === IntegrationFieldTypeEnum.url
  ),
});

export const selectNonUrlFields = (res: MappingSelectorArgs) => ({
  ...res,
  data: selectFields(res).data.filter(
    (f) => f.type !== IntegrationFieldTypeEnum.url
  ),
});

export const RETENTION_OFFER_RESERVED_FIELD_NAME = 'Retention Offer';
export const OFFER_RESERVED_FIELDS: Suggestion[] = [
  {
    val: RETENTION_OFFER_RESERVED_FIELD_NAME,
    key: RETENTION_OFFER_RESERVED_FIELD_NAME,
    name: RETENTION_OFFER_RESERVED_FIELD_NAME,
    fallBackValue: '',
    type: AppFieldType.text,
    reserved: true,
  },
  {
    val: 'Days Since Offer Viewed',
    key: 'Days Since Offer Viewed',
    name: 'Days Since Offer Viewed',
    fallBackValue: '',
    type: AppFieldType.integer,
    reserved: true,
  },
  {
    val: 'Days Since Offer Accepted',
    key: 'Days Since Offer Accepted',
    name: 'Days Since Offer Accepted',
    fallBackValue: '',
    type: AppFieldType.integer,
    reserved: true,
  },
  {
    val: 'Days Since Offer Viewed But Not Accepted',
    key: 'Days Since Offer Viewed But Not Accepted',
    name: 'Days Since Offer Viewed But Not Accepted',
    fallBackValue: '',
    type: AppFieldType.integer,
    reserved: true,
  },
];

const RESERVED_FIELDS: Suggestion[] = [
  {
    val: 'Mobile Session',
    key: 'Mobile Session',
    name: 'Mobile Session',
    fallBackValue: '',
    type: AppFieldType.boolean,
    reserved: true,
  },
];

export const useSelectReservedFields = (): Suggestion[] => {
  const showOfferReservedFields = useFeatureFlags(
    FeatureFlagsE.SHOW_VIEWED_ACCEPTED_OFFER_RESERVED_FIELDS
  );
  if (showOfferReservedFields) {
    return [...RESERVED_FIELDS, ...OFFER_RESERVED_FIELDS];
  }
  return RESERVED_FIELDS;
};

export const ALERT_FIELDS: Suggestion[] = [
  {
    fallBackValue: '',
    key: 'Company',
    name: 'Company',
    alert: true,
    type: 'text',
    val: 'Company',
  },
  {
    fallBackValue: '',
    key: 'Reason',
    name: 'Reason',
    alert: true,
    type: 'text',
    val: 'Reason',
  },
  {
    fallBackValue: '',
    key: 'Reason Other',
    name: 'Reason Other',
    alert: true,
    type: 'text',
    val: 'Reason Other',
  },
  {
    fallBackValue: '',
    key: 'Question',
    name: 'Question',
    alert: true,
    type: 'text',
    val: 'Question',
  },
  {
    fallBackValue: '',
    key: 'Competitor',
    name: 'Competitor',
    alert: true,
    type: 'text',
    val: 'Competitor',
  },
  {
    fallBackValue: '',
    key: 'Competitor Other',
    name: 'Competitor Other',
    alert: true,
    type: 'text',
    val: 'Competitor Other',
  },
  {
    fallBackValue: '',
    key: 'Comment',
    name: 'Comment',
    alert: true,
    type: 'text',
    val: 'Comment',
  },
  {
    fallBackValue: '',
    key: 'Sentiment',
    name: 'Sentiment',
    alert: true,
    type: 'text',
    val: 'Sentiment',
  },
  {
    fallBackValue: '',
    key: 'offer_name',
    name: 'offer_name',
    alert: true,
    type: 'text',
    val: 'offer_name',
  },
  {
    fallBackValue: '',
    key: 'offer_display_name',
    name: 'offer_display_name',
    alert: true,
    type: 'text',
    val: 'offer_display_name',
  },
  {
    fallBackValue: '',
    key: 'offer_type',
    name: 'offer_type',
    alert: true,
    type: 'text',
    val: 'offer_type',
  },
  {
    fallBackValue: '',
    key: 'offer_category',
    name: 'offer_category',
    alert: true,
    type: 'text',
    val: 'offer_category',
  },
  {
    fallBackValue: '',
    key: 'offer_content',
    name: 'offer_content',
    alert: true,
    type: 'text',
    val: 'offer_content',
  },
  {
    fallBackValue: '',
    key: 'Structured Feedback',
    name: 'Structured Feedback',
    alert: true,
    type: 'text',
    val: 'Structured Feedback',
  },
];

export const getAlertFields = (): Suggestion[] => {
  const isLifeCycleEnabled = useFeatureFlags(
    FeatureFlagsE.ENABLE_LIFECYCLE_OFFERS
  );
  if (isLifeCycleEnabled) {
    return useMemo(() => {
      const alertCopy = [...ALERT_FIELDS];

      const offerObjectiveField = {
        fallBackValue: '',
        key: 'offer_objective',
        name: 'offer_objective',
        alert: true,
        type: 'text',
        val: 'offer_objective',
      };
      const insertIndex = alertCopy.findIndex((af) => af.key === 'offer_type');
      alertCopy.splice(insertIndex, 0, offerObjectiveField);
      return alertCopy;
    }, [ALERT_FIELDS]);
  }
  return ALERT_FIELDS;
};

export const createFieldsWithPrefixes = (allFields: Suggestion[]) => {
  const getPrefix = (field: Suggestion) => {
    if (field.reserved) return 'reserved';
    if (field.standard) return 'standard';
    if (field.alert) return 'alert';
    return 'custom';
  };

  return allFields.map((f) => {
    const prefix = getPrefix(f);
    return {
      ...f,
      name: `${prefix}.${f.name}`,
    };
  });
};
export const useAvailableIntegrations = () => {
  const company = useSelector(selectCompany);
  const appKey = useSelector(selectAppId);
  return integrationSlice.endpoints.getIntegrations.useQuery(
    { appKey },
    {
      selectFromResult: (res) => ({
        ...res,
        data: res.data?.filter((integration) => {
          switch (integration.data_type) {
            // intercom and totango is deprecated
            case IntegrationDataType.INTERCOM:
            case IntegrationDataType.TOTANGO:
              return false;

            case IntegrationDataType.SALESFORCE:
              return !company.plan || canAccessSalesforceIntegration(company);

            default:
              return true;
          }
        }),
      }),
    }
  );
};

export const useRetrieveIntegrationFields = () => {
  const dispatch = useAppDispatch();
  const appKey = useSelector(selectAppId);
  const [fetchIntegrations$] =
    integrationSlice.endpoints.getIntegrations.useLazyQuery();
  const [getIntegrationFields$] =
    integrationSlice.endpoints.getIntegrationFields.useLazyQuery();
  const [getFieldMappings$] =
    integrationSlice.endpoints.getFieldMappings.useLazyQuery();

  const processIntegration = useCallback(
    async (integration: Integration, incomplete: boolean) => {
      if (integration.active && integration.connected) {
        const integrationName = integration.data_type;
        try {
          const fields = await getIntegrationFields$(
            {
              appKey,
              integrationDataType: integrationName,
            },
            true
          ).unwrap();
          if (fields) {
            dispatch({
              type: Action.SET_INTEGRATION_FIELDS,
              fields,
              integration,
              incomplete,
            });

            const fieldMappings = await getFieldMappings$(
              { appKey },
              true
            ).unwrap();

            for (let i = 0; i < fieldMappings.length; i++) {
              const fieldMapping = fieldMappings[i]!;
              dispatch(
                Action.setFieldMapping({
                  name: fieldMapping.name,
                  displayName: fieldMapping.display_name,
                  includeInCustomersReport:
                    fieldMapping.include_in_customers_report,
                  type: fieldMapping.type,
                  standard: fieldMapping.standard,
                  integrationId: fieldMapping.integration_id,
                  extObjectName: fieldMapping.field.object!,
                  extFieldName: fieldMapping.field.name,
                })
              );
            }
          } else if (integration.active) {
            dispatch({
              type: Action.SET_INTEGRATION_FIELDS,
              integration,
              incomplete,
            });
          }
        } catch (e) {
          // ignore
          console.warn(
            'Error gettings fields for integration:',
            integrationName,
            e
          );
        }
      } else if (integration.active) {
        dispatch({
          type: Action.SET_INTEGRATION_FIELDS,
          integration,
          incomplete,
        });
      }
    },
    []
  );

  const retrieveIntegrationFields = useCallback(
    async (cb?: CallableFunction) => {
      const integrations = await fetchIntegrations$({ appKey }, true).unwrap();
      dispatch({
        type: Action.RETRIEVE_INTEGRATION_FIELDS_REQUEST,
      });
      for (let i = 0; i < integrations.length; i++) {
        await processIntegration(integrations[i]!, i < integrations.length - 1);
      }
      cb?.();
      dispatch({
        type: Action.LOADED_FIELD_MAPPINGS,
      });
    },
    []
  );

  return retrieveIntegrationFields;
};

export const useSaveFieldMappings = () => {
  const dispatch = useAppDispatch();
  const appKey = useSelector(selectAppId);
  const fieldMappings = useSelector(selectDM).fieldMappings;
  const [saveFieldMappings$] =
    integrationSlice.endpoints.saveFieldMappings.useMutation();
  const retrieveIntegrationFields = useRetrieveIntegrationFields();

  return useCallback(async () => {
    dispatch({ type: Action.SAVE_FIELD_MAPPINGS });
    try {
      let hasWarnings = false;
      const fields = Object.values(fieldMappings)
        .filter(isDefined)
        .filter((fieldMapping) => {
          const warning = fieldMapping.warning;
          if (warning && Object.values(warning).length > 0) {
            hasWarnings = true;
            return false;
          }
          return (
            fieldMapping.name &&
            fieldMapping.type &&
            fieldMapping.integration_id &&
            !fieldMapping.id.startsWith('Instance ID')
          );
        });
      if (hasWarnings) {
        return;
      }
      // TODO Alex/Jeff temporarily save name as displayName. Update when displayName is added to UI
      await saveFieldMappings$({
        appKey,
        mappings: fields.map((fieldMapping) => ({
          field: fieldMapping.field,
          integration_id: fieldMapping.integration_id,
          name: fieldMapping.name,
          display_name: fieldMapping.name,
          include_in_customers_report: fieldMapping.includeInCustomersReport,
          standard: fieldMapping.standard,
          type: !fieldMapping.standard ? fieldMapping.type : undefined,
        })),
      });
      dispatch({ type: Action.CLEAR_FIELD_MAPPINGS });
      dispatch({ type: Action.SAVED_FIELD_MAPPINGS });
      retrieveIntegrationFields();
    } catch (e) {
      dispatch({ type: Action.FAILED_SAVING_FIELD_MAPPINGS, error: e });
      Promise.resolve(e).then((data: any) => {
        BlackToastTrigger({ content: `Error saving fields: ${data.message}` });
      });
    }
  }, [fieldMappings]);
};
