// @ts-nocheck
import { DeserializeHtml } from '@udecode/slate-plugins-core';
import { getNodeDeserializer, setDefaults } from '@udecode/slate-plugins';
import { DEFAULTS_BBK_EXPRESSION } from './defaults';
import { BrightbackText } from '@bbkAdminUtils/brightback-expression';

const getTopMethod = (
  textContent: string | null
): 'value' | 'default' | undefined => {
  if (textContent?.startsWith('${default(')) return 'default';
  if (textContent?.startsWith('${value(')) return 'value';
  return undefined;
};

export const deserializeBbkExpression = (options?): DeserializeHtml => {
  const { bbkExpression } = setDefaults(options, DEFAULTS_BBK_EXPRESSION);

  return {
    element: getNodeDeserializer({
      type: bbkExpression.type,
      node: (el) => {
        const bbkText = new BrightbackText(el.textContent);
        const parts = bbkText.getStandardFieldReferenceParts();
        const identifierSplit = new RegExp(/([a-zA-Z]+)\.([\s\S]+)/, 'g');
        const splitIdentifier = identifierSplit.exec(parts.identifier);

        const fieldName = splitIdentifier
          ? splitIdentifier[2]
          : parts.identifier;
        const isStandard = splitIdentifier ? splitIdentifier[1] : null;

        let expressionTopMethod = getTopMethod(el.textContent);
        const field = {
          key: fieldName,
          name: fieldName,
          val: fieldName,
          standard: isStandard === 'standard',
          fallBackValue: parts.fallback,
          topMethod: expressionTopMethod,
        };

        // TODO: locate "field" within the fields list and make sure to
        // fully fill out the information  that goes with that field.

        return {
          type: bbkExpression.type,
          field,
        };
      },
      rules: [{ nodeNames: 'BBK-EXPRESSION' }],
    }),
  };
};
