/* eslint-disable react/jsx-props-no-spreading */
import type { ReactElement } from 'react';
import React from 'react';
import type { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react';
import './styles/tooltips.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

type Props = {
  tippyOptions?: TippyProps;
  content: React.ReactNode;
  children: ReactElement;
};

const TippyToolTip: React.FC<Props> = (props) => {
  const { tippyOptions, content, children } = props;
  const tippyOptionsUpdated: TippyProps = {
    placement: tippyOptions?.placement ?? 'auto',
    interactive: true,
    allowHTML: true,
    content,
    className: 'tippy-tooltip light-theme',
    theme: 'light',
    ...tippyOptions,
  };

  return <Tippy {...tippyOptionsUpdated}>{children}</Tippy>;
};

export default TippyToolTip;
