export const BASE_PATH = '/company/:companyId/app/:appId';

export const TrendsPaths = {
  saves: 'saves',
  cancels: 'cancels',
  deflection: 'deflection',
  savePerformance: 'save-performance',
};
export const AuthorizedPaths = {
  getStarted: '/get-started',
  getStartedCreateExperience: '/get-started/create-experience',
  getStartedCreateExperienceStep1Branding:
    '/get-started/create-experience/branding',
  getStartedCreateExperienceStep2Offers:
    '/get-started/create-experience/offers',
  getStartedCreateExperienceStep3Targeting:
    '/get-started/create-experience/targeting',
  audiencesEdit: '/audiences/:ruleId/edit',
  audiencesList: '/audiences/list',
  audiencesNew: '/audiences/new',
  createCompany: '/create',
  customers: `/customers/:customerView(all|cancels|saves|watchlist|lostDeflects|repurchases|winbacks)`,
  customersDetail: `/customers/customer/:userInternalId/:sessionId?`,
  dashboard: '/dashboard',
  experiencesBrandManager: '/experiences/manager/brand',
  experiencesFunnelPreview: '/experiences/funnels/:funnelId/preview',
  experiencesFunnelPreviewFull: '/experiences/funnels/:funnelId/preview-full',
  experiencesFunnelsPage: '/experiences/funnels/:funnelId/page',
  experiencesHostedPage: '/experiences/hosted/:funnelId/page',
  experiencesFunnelsPageConfirmation:
    '/experiences/funnels/:funnelId/page/confirmation',
  experiencesFunnelsPageReasons: '/experiences/funnels/:funnelId/page/reasons',
  experiencesManager: '/experiences/manager',
  experiencesBrandingPage: '/experiences/branding',
  additionalBrandsPage: '/experiences/brands',
  createAdditionalBrand: '/experiences/create-brand',
  viewAdditionalBrand: '/experiences/brands/:brandId/edit/:tab?',
  editAdditionalBranding: '/experiences/brands/:brandId/edit-branding',
  experiencesPages: '/experiences/pages',
  experiencesPageReasons: '/experiences/page/reasons',
  experiencesTargeting: '/experiences/targeting',
  insightsCancel: '/insights/cancel',
  insightsCancelAI: '/insights/ai/cancel',
  insightsExperiencePerformanceReport:
    '/insights/experience-performance-report',
  insightsExperiencePerformanceReportV2:
    '/insights/experience-performance-report-v2',
  insightsOfferPerformance: '/insights/offer-performance',
  insightsRetainedRevenueCohort: '/insights/retained-revenue-cohort',
  insightsLiftDashboard: '/insights/lift-report',
  lossAversionList: '/experiences/loss-aversion',
  lossAversionNew: '/experiences/loss-aversion/new',
  lossAversionEdit: '/experiences/loss-aversion/:lossAversionId?/edit',
  lossAversionPlacement:
    '/experiences/loss-aversion/:lossAversionId?/placement',
  offers: '/experiences/offers',
  offersEdit: '/experiences/offers/:offerId?/edit',
  offersEditAITopLevel: '/experiences/offers/:offerId?/edit/ai-toplevel',
  offersEditDesign: '/experiences/offers/:offerId?/edit/layout',
  offersEditItems: '/experiences/offers/:offerId?/edit/items',
  offersEditActivities: '/experiences/offers/:offerId?/edit/activities',
  offersEditSettings: '/experiences/offers/:offerId?/edit/settings',
  offersEditBranding: '/experiences/offers/:offerId?/edit/branding',
  offersEditCtaSettings: '/experiences/offers/:offerId?/edit/:ctaIdx/settings',
  offersEditCtaState: '/experiences/offers/:offerId?/edit/:ctaIdx/state',
  offersPlacement: '/experiences/offers/:offerId?/placement/funnel/:funnelId',
  offerPosition: '/experiences/offers/:offerId?/edit/position',
  offersBilling: '/experiences/offers/:offerId?/billing',
  offersNew: '/experiences/offers/new',
  confirmationPages: '/experiences/confirmation-pages',
  confirmationPagesCreate: '/experiences/confirmation-pages/create',
  confirmationPagesEdit: '/experiences/confirmation-pages/:ccpKey?/edit',
  settings: '/settings',
  settingsAlerts: '/settings/alerts/:alertId',
  settingsDevelopers: '/settings/developers',
  settingsFieldMappings: '/settings/field-mappings',
  settingsInstall: '/settings/install',
  settingsIntegrations: '/settings/integrations',
  settingsPermissions: '/settings/permissions',
  settingsSetup: '/settings/setup',
  settingsActivityLog: '/settings/activity-log',
  settingsSubscriptions: '/settings/subscriptions',
  settingsRestrictedDomainsManage: '/settings/users/restricted-domains/manage',
  settingsUsers: '/settings/users',
  settingsCustomDomain: '/settings/custom-domain',
  settingsPlans: '/settings/plan',
  settingsUsersInvite: '/settings/users/invite',
  settingsWebhooks: '/settings/webhooks/:webhookId',
  trendsCancels: `/trends/${TrendsPaths.cancels}`,
  trendsDeflection: `/trends/${TrendsPaths.deflection}`,
  trendsGraphs: `/trends/:trendsPage(${TrendsPaths.cancels}|${TrendsPaths.saves}|${TrendsPaths.deflection}|${TrendsPaths.savePerformance})`,
  trendsSavePerformance: `/trends/${TrendsPaths.savePerformance}`,
  trendsSaves: `/trends/${TrendsPaths.saves}`,
  plays: `/plays`,
  playEdit: `/plays/:playId?/edit`,
  playNew: '/plays/new',
} as const;
