import React from 'react';
import type { FieldProps, FormikHelpers } from 'formik';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import cs from 'classnames';
import * as yup from 'yup';
import setup from 'yup-password';
import { get } from 'lodash';
import Icon from '@bbkAdminComponents/icons/icon-generator';
import Label from '@bbkAdminComponents/inputs/label/label';
import { SigninButton } from '@bbkAdminComponents/log-in-flow/signin-button';
import { LogInError } from '@bbkAdminComponents/log-in-flow/error';
import { LogInTrouble } from '@bbkAdminComponents/log-in-flow/trouble';
import type * as LogInTypes from '@bbkAdminComponents/log-in-flow/log-in-types';
import { LogInPaths } from '@bbkAdminComponents/log-in-flow/log-in-paths';
import { useLocationQuery } from '@bbkAdminUtils/useLocationQuery';
import { PasswordInput } from '@bbkAdminComponents/inputs/password/password-input';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { CBRetentionLogo } from '@bbkAdminComponents/cbretention-logo';
import Icons from '@bbkAdminUtils/icons';

setup(yup);

type Props = {
  error?: LogInTypes.Error;
  googleSignIn: LogInTypes.GoogleSignIn;
  signUp: LogInTypes.SignUpSubmit;
  sending: LogInTypes.Sending;
};

type Values = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};

const SignUpSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(1, 'Name is too short.')
    .max(50, 'Name is too long.')
    .required('First name is required.'),
  lastName: yup
    .string()
    .min(1, 'Name is too short.')
    .max(50, 'Name is too long.')
    .required('Last name is required.'),
  email: yup
    .string()
    .email('Invalid email provided.')
    .required('Email is required.'),
  password: yup
    .string()
    .password()
    .min(12, '12 character minimum.')
    .minLowercase(0)
    .minUppercase(0)
    .minSymbols(0)
    .minNumbers(0)
    .required('Password is required.'),
});

export const SignUp: React.FC<Props> = (props) => {
  const { error, googleSignIn, signUp, sending } = props;
  const query = useLocationQuery();

  return (
    <div>
      <div className="content !tw-mt-8">
        <CBRetentionLogo className="tw-h-10 tw-mb-6" />
        <div className="tw-font-semibold tw-mb-4">Sign up</div>
        <div className="tw-w-[300px] tw-mx-auto">
          <SigninButton
            copy="Join with Google"
            onClick={googleSignIn}
            icon={Icons.google}
          />
        </div>
        <div className="signin-divider">
          <div className="or">or</div>
        </div>
        {error && <LogInError error={error} />}
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
          }}
          onSubmit={async (
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            signUp(values);
            setSubmitting(false);
          }}
          validationSchema={SignUpSchema}
        >
          {({ errors, touched }) => (
            <Form className="form-container">
              <Label
                id="firstName"
                label="First name"
                classes={cs('tw-text-sm tw-font-semibold', {
                  error: get(errors, 'firstName') && get(touched, 'firstName'),
                })}
              />
              <Field
                name="firstName"
                placeholder="Jane"
                type="text"
                className={cs('bbk-simple-text-input medium', {
                  error: get(errors, 'firstName') && get(touched, 'firstName'),
                })}
              />
              <ErrorMessage
                name="firstName"
                component="div"
                className="field-error"
              />
              <Label
                id="lastName"
                label="Last name"
                classes={cs('tw-text-sm tw-font-semibold', {
                  error: get(errors, 'lastName') && get(touched, 'lastName'),
                })}
              />
              <Field
                name="lastName"
                placeholder="Doe"
                type="text"
                className={cs('bbk-simple-text-input medium', {
                  error: get(errors, 'lastName') && get(touched, 'lastName'),
                })}
              />
              <ErrorMessage
                name="lastName"
                component="div"
                className="field-error"
              />
              <Label
                id="email"
                label="Email"
                classes={cs('tw-text-sm tw-font-semibold', {
                  error: get(errors, 'email') && get(touched, 'email'),
                })}
              />
              <Field
                name="email"
                placeholder="you@domain.com"
                type="text"
                className={cs('bbk-simple-text-input medium', {
                  error: get(errors, 'email') && get(touched, 'email'),
                })}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="field-error"
              />
              <Label
                id="password"
                label="Password"
                helpText="12 character minimum"
                classes={cs('tw-text-sm tw-font-semibold', {
                  error: get(errors, 'password') && get(touched, 'password'),
                })}
              />
              <Field name="password">
                {({ field, meta }: FieldProps) => (
                  <PasswordInput
                    {...field}
                    placeholder="Password"
                    className={cs('bbk-simple-text-input medium', {
                      error: get(meta, 'touched') && get(meta, 'error'),
                    })}
                  />
                )}
              </Field>
              <ErrorMessage
                name="password"
                component="div"
                className="field-error"
              />
              <div className="tw-text-sm tw-text-left">
                By clicking "Create account" you agree to{' '}
                <a
                  href="https://brightback.com/legal/terms"
                  target="_blank"
                  className="tw-font-bold tw-text-cbrprimary-600 tw-whitespace-nowrap"
                  rel="noreferrer"
                >
                  Chargebee Retention Terms
                </a>{' '}
                and{' '}
                <a
                  href="https://brightback.com/legal/privacy"
                  target="_blank"
                  className="tw-font-bold tw-text-cbrprimary-600 tw-whitespace-nowrap"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </div>
              <ButtonV2
                className="submitButtonFooter tw-mt-3"
                primary
                fullWidth
                type="submit"
                disabled={sending}
              >
                Create account <Icon name="arrow" class="inline" />
              </ButtonV2>
            </Form>
          )}
        </Formik>
      </div>
      <LogInTrouble
        link={{
          path:
            LogInPaths.signIn +
            (query.get('invite') ? `?invite=${query.get('invite')}` : ''),
          copy: 'Already a customer? Sign in',
        }}
      />
    </div>
  );
};
