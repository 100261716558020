import React from 'react';
import { Dialog, DialogProps, withStyles } from '@material-ui/core';
import styled from '@emotion/styled';
import tailwindColors from '@bbkRoot/tailwind.colors';

export const DialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.8);
  padding: 0.7rem 1rem;
`;

export const DialogContent = styled.div`
  padding: 1rem;
`;

export const DialogContentBold = styled.div`
  padding: 1rem;
  font-weight: bold;
`;

export const DialogError = styled.div`
  padding: 1rem;
  color: rgb(192, 40, 40);
  word-break: break-all;
`;

export const DialogSaveStep = styled.div`
  padding: 1rem;
  color: ${tailwindColors.cbrprimary['600']};
`;

export const DialogActions = styled.div`
  padding: 1rem;

  button {
    margin-right: 0.5rem;
  }
`;

export const WrappedDialog = withStyles({
  paper: {
    borderRadius: 20,
    background: '#F6F8FB',
  },
})(Dialog);

export const ConfirmationDialog: React.FC<DialogProps> = (props) => (
  <WrappedDialog
    maxWidth="xs"
    aria-labelledby="brightback-confirmation-dialog"
    fullWidth
    className="bbk-confirmation-dialog"
    {...props}
  />
);
