import type { IntegrationDataType } from '@bbkAdminRedux/data-management/reducers';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';

export type StripeBillingConfig = {
  integration_data_type: IntegrationDataType.STRIPE;
  invoice_now: boolean;
  prorate: boolean;
  type: 'stripe';
};

export type RechargeBillingConfig = {
  integration_data_type: IntegrationDataType.RECHARGE;
  end_of_term: boolean;
  type: 'recharge';
};

export type RecurlyBillingConfig = {
  integration_data_type: IntegrationDataType.RECURLY;
  cancel_type: 'cancel_subscription' | 'terminate_subscription';
  charge: boolean;
  refund_type: 'none' | 'partial' | 'full';
  time_frame: 'term_end' | 'bill_date';
  type: 'recurly';
};

export type ChargebeeBillingConfig = {
  integration_data_type: IntegrationDataType.CHARGEBEE;
  account_receivables_handling:
    | 'schedule_payment_collection'
    | 'write_off'
    | 'no_action';
  credit_option: 'none' | 'prorate' | 'full';
  charges_option: 'invoice' | 'delete';
  end_of_term: boolean;
  type: 'chargebee';
};

export type CancelConfig =
  | StripeBillingConfig
  | RecurlyBillingConfig
  | RechargeBillingConfig
  | ChargebeeBillingConfig;

export type BillingConfig = {
  enrich: boolean;
  manage_cancels: boolean;
  manage_offers: boolean;
  attribution: boolean;
  repurchase_based_attribution: boolean;
  cancel_all_subscriptions: boolean;
  apply_to_all_pages: boolean;
  cancel_config?: CancelConfig;
  enable_bypass?: boolean;
  bypass_percent?: number;
};

type GetBillingConfigArgs = {
  app_key: string;
  funnel_id?: number;
};
export const billingConfigSlice = bbkApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBillingConfig: builder.query<BillingConfig, GetBillingConfigArgs>({
      query: ({ app_key, funnel_id }) => ({
        url: `/api/v1/apps/${app_key}/billing_config`,
        params: {
          funnel: funnel_id,
        },
      }),
      providesTags: (result, err, args) => {
        if (!args.funnel_id) {
          return [{ type: 'BillingConfigs', id: 'GLOBAL' }];
        }
        return [{ type: 'BillingConfigs', id: args.funnel_id }];
      },
    }),

    putBillingConfig: builder.mutation<
      BillingConfig,
      BillingConfig & GetBillingConfigArgs
    >({
      query: ({ app_key, funnel_id, ...body }) => ({
        url: `/api/v1/apps/${app_key}/billing_config`,
        params: {
          funnel: funnel_id,
        },
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, err, args) => {
        if (!args.funnel_id) {
          return [{ type: 'BillingConfigs', id: 'GLOBAL' }];
        }
        return [{ type: 'BillingConfigs', id: args.funnel_id }];
      },
    }),

    propagateConfigs: builder.mutation<
      BillingConfig,
      BillingConfig & {
        app_key: string;
      }
    >({
      query: ({ app_key, ...args }) => ({
        url: `/api/v1/apps/${app_key}/propagate_billing_config`,
        method: 'POST',
        body: {
          enrich: args.enrich,
          manage_cancels: args.manage_cancels,
          manage_offers: args.manage_offers,
          attribution: args.attribution,
          cancel_all_subscriptions: args.cancel_all_subscriptions,
        },
      }),
      invalidatesTags: ['Funnels'],
    }),
  }),
});

export const useSelectBillingConfig = (args: GetBillingConfigArgs) => {
  const { data } = billingConfigSlice.endpoints.getBillingConfig.useQuery(args);
  return data;
};
