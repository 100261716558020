import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import {
  selectAppId,
  selectCompanyInternalName,
} from '@bbkAdminRedux/app/selectors';
import { AuthorizedPaths } from '@bbkAdminRouter/router-paths';
import {
  funnelsSlice,
  selectControlFunnel,
} from '@bbkAdminRedux/rtkq/funnels.slice';
import { useSelectCurrentApp } from '@bbkAdminRedux/rtkq/current-user.slice';
import { usePlans } from '@bbkAdminUtils/plans';

export function useUrl() {
  const appId = useSelector(selectAppId);
  const companyId = useSelector(selectCompanyInternalName);
  const generateUrl = <
    T extends { [x: string]: string | number | boolean | undefined },
  >(
    href = '',
    params?: T
  ) => {
    // set to empty string to avoid creating an error or empty path
    // rely on rollbar to surface the error
    if (href === '' && window.Rollbar) {
      window.Rollbar.error('Undefined URL attempting to be built.');
    }
    if (
      href.includes('://') ||
      href.startsWith('/internal') ||
      href.startsWith('/admin') ||
      href.startsWith('/company/') ||
      href.startsWith('/dev')
    ) {
      return href;
    }

    let parsedHref;
    if (params) {
      parsedHref = generatePath(href, params);
    }

    const builtUrl = `/company/${companyId}/app/${appId}${parsedHref ?? href}`;
    return builtUrl.replace('//', '/');
  };

  return generateUrl;
}

export const useCreateControlFunnelUrl = () => {
  const createUrl = useUrl();
  const app = useSelectCurrentApp();
  const { data: controlFunnel } = funnelsSlice.endpoints.getFunnels.useQuery(
    { appKey: app?.encoded_id ?? '' },
    {
      selectFromResult: selectControlFunnel,
      skip: !app,
    }
  );

  return createUrl(AuthorizedPaths.experiencesFunnelsPage, {
    funnelId: controlFunnel?.id ?? 0,
  });
};

export const useCreatePagesListUrl = (showFunnel = false) => {
  const createUrl = useUrl();
  const { isStarterPremiumPlan, isStarterFreePlan } = usePlans();
  const isStarterPlan = isStarterPremiumPlan || isStarterFreePlan;
  const controlFunnelUrl = useCreateControlFunnelUrl();

  return isStarterPlan || showFunnel
    ? controlFunnelUrl
    : createUrl(AuthorizedPaths.experiencesPages);
};
