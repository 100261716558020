/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useMemo } from 'react';
import { CBRIcons } from '@bbkAdminUtils/icons';
import { useUpgradeDialog } from '@bbkAdminComponents/get-started/starter-upgrade-dialog';

enum StringE {
  CONTROL_DISPLAY_NAME = 'CONTROL_DISPLAY_NAME',
  BRANDING_DISPLAY_NAME = 'BRANDING_DISPLAY_NAME',
  TEMPLATE_DISPLAY_NAME = 'TEMPLATE_DISPLAY_NAME',
  BRANDS_DISPLAY_NAME = 'BRANDS_DISPLAY_NAME',
  UPGRADE_PLAN = 'UPGRADE_PLAN',
  UPGRADE_TO_DEFLECTION_DESC = 'UPGRADE_TO_DEFLECTION_DESC',
  UPGRADE_TO_STARTER_PREMIUM_DESC = 'UPGRADE_TO_STARTER_PREMIUM_DESC',
  UPGRADE_TO_STARTER_PREMIUM_SAVES_DESC = 'UPGRADE_TO_STARTER_PREMIUM_SAVES_DESC',
  UPGRADE_TO_STARTER_PREMIUM_EXPORT = 'UPGRADE_TO_STARTER_PREMIUM_EXPORT',
}

export const useStrings = () => {
  const { handleOpen: openUpgradeDialog } = useUpgradeDialog();

  return useMemo(
    () =>
      ({
        [StringE.CONTROL_DISPLAY_NAME]: 'Control',
        [StringE.BRANDING_DISPLAY_NAME]: 'Branding',
        [StringE.TEMPLATE_DISPLAY_NAME]: 'Default',
        [StringE.BRANDS_DISPLAY_NAME]: 'Brands',
        [StringE.UPGRADE_PLAN]: (opts: { description: React.ReactNode }) => {
          const { description } = opts;
          return (
            <div className="tw-text-cbrneutral-500 tw-p-1">
              {description}
              <div
                className="tw-text-cbrprimary-600 tw-font-semibold tw-mt-2 tw-cursor-pointer"
                onClick={openUpgradeDialog}
              >
                Upgrade plan {CBRIcons.cbrArrowRight(12, 12)}
              </div>
            </div>
          );
        },
        [StringE.UPGRADE_TO_DEFLECTION_DESC]: (
          <>
            Upgrade to{' '}
            <span className="tw-text-cbrprimary-800 tw-font-semibold">
              Deflection trial
            </span>{' '}
            to unlock this feature.
          </>
        ),
        [StringE.UPGRADE_TO_STARTER_PREMIUM_DESC]: (
          <>
            Upgrade to{' '}
            <span className="tw-text-cbrprimary-800 tw-font-semibold">
              Starter Premium
            </span>{' '}
            to view more than the last 30 days of retention insights.
          </>
        ),
        [StringE.UPGRADE_TO_STARTER_PREMIUM_SAVES_DESC]: (
          <>
            Upgrade to{' '}
            <span className="tw-text-cbrprimary-800 tw-font-semibold">
              Starter Premium
            </span>{' '}
            to view Saves
          </>
        ),
        [StringE.UPGRADE_TO_STARTER_PREMIUM_EXPORT]: (
          <>
            Upgrade to{' '}
            <span className="tw-text-cbrprimary-800 tw-font-semibold">
              Starter Premium
            </span>{' '}
            to export reports as CSV/JSON file
          </>
        ),
      }) as const,
    [openUpgradeDialog]
  );
};
