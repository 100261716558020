import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { produce } from 'immer';
import { set } from 'lodash';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import type {
  RestLossAversion,
  RestOffer,
} from '@bbkAdminUtils/api-client/rest-offers-api';
import { isBlobUrl, traverseAndApply } from '@bbkUtils/blob-images';
import {
  selectAppId,
  selectCompanyInternalName,
} from '@bbkAdminRedux/app/selectors';

type AssetConfig = {
  upload_url: string;
  download_url: string;
  content_type: string;
};
export const cdnAssetsSlice = bbkApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAssetConfig: build.query<
      AssetConfig,
      {
        companyKey: string;
        appKey: string;
        fileName: string;
        contentType: string;
      }
    >({
      query: ({ companyKey, appKey, fileName, contentType }) => ({
        url: `/api/v1/companies/${companyKey}/apps/${appKey}/assetConfig/${encodeURIComponent(
          contentType
        )}/${encodeURIComponent(fileName)}`,
      }),
    }),
    uploadBlobToS3: build.mutation<
      string,
      {
        assetConfig: AssetConfig;
        blob: Blob;
      }
    >({
      query: ({ assetConfig, blob }) => ({
        url: assetConfig.upload_url,
        method: 'PUT',
        headers: {
          'Content-Type': assetConfig.content_type,
        },
        body: blob,
      }),
      transformResponse: (res, meta, args) => args.assetConfig.download_url,
    }),
  }),
});

export const useUploadBlobToS3 = () => {
  const companyKey = useSelector(selectCompanyInternalName);
  const appKey = useSelector(selectAppId);
  const [getAssetConfig$] =
    cdnAssetsSlice.endpoints.getAssetConfig.useLazyQuery();
  const [uploadBlobToS3$] =
    cdnAssetsSlice.endpoints.uploadBlobToS3.useMutation();

  return useCallback(
    async (blobUrl: string): Promise<string> => {
      const blob = await fetch(blobUrl).then((r) => r.blob());
      const assetConfig = await getAssetConfig$({
        companyKey,
        appKey,
        fileName: '',
        contentType: blob.type,
      }).unwrap();

      return await uploadBlobToS3$({ assetConfig, blob }).unwrap();
    },
    [appKey, companyKey]
  );
};

export const useUploadBlobsToS3 = () => {
  const uploadBlobToS3 = useUploadBlobToS3();

  return useCallback(
    async <T extends RestOffer | RestLossAversion>(
      offerOrLA: T
    ): Promise<T> => {
      const updatedNodes = await Promise.all(
        traverseAndApply(offerOrLA, isBlobUrl, (blobUrl) =>
          uploadBlobToS3(blobUrl)
        )
      );

      const changed = Object.fromEntries(
        updatedNodes.map(([path, updatedImgUrl]) => [
          path.join('.'),
          updatedImgUrl,
        ])
      );

      return produce(offerOrLA, (draft) => {
        Object.entries(changed).forEach(([key, value]) => {
          set(draft, key, value);
        });
      });
    },
    [uploadBlobToS3]
  );
};
