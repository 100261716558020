import ProductTourStoryWelcomeToBrightback from './welcome-to-brightback';
import ProductTourStoryCancelTrends from './cancel-trends';
import ProductTourStoryDeflectionFunnel from './deflection-funnel';
import ProductTourStorySetupATest from './setup-a-test';
import ProductTourStoryBuildAPage from './build-a-page';
import ProductTourStoryTestDesign from './test-design';
import ProductTourStoryDeflectionPerformance from './deflection-performance';
import ProductTourStoryAudiencesAndTargeting from './audiences-and-targeting';
import ProductTourStoryMasteringRetention from './mastering-retention';

export type TourStoryProps = {
  onClick?: () => {};
};

export const ProductTours = [
  ProductTourStoryWelcomeToBrightback,
  ProductTourStoryDeflectionFunnel,
  ProductTourStorySetupATest,
  ProductTourStoryBuildAPage,
  ProductTourStoryTestDesign,
  ProductTourStoryDeflectionPerformance,
  ProductTourStoryCancelTrends,
  ProductTourStoryAudiencesAndTargeting,
  ProductTourStoryMasteringRetention,
];

export const ProductTourList = ProductTours.map((pt) => ({
  name: pt.name,
  key: pt.key,
}));
