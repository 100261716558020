import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { produce } from 'immer';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import {
  confirmationFunnelSlice,
  destinationProtocols,
  extractConfirmationFunnelKey,
} from '@bbkAdminRedux/rtkq/confirmation-funnel.slice';
import GenericSelect from '@bbkAdminComponents/select/select';
import type { RestModalForwards } from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import { DestinationType } from '@bbkAdminRedux/rtkq/experience.slice';
import { AuthorizedPaths } from '@bbkAdminRouter/router-paths';
import { selectAppId } from '@bbkAdminRedux/app/selectors';
import { useUrl } from '@bbkAdminUtils/nav';
import { RichTextEditor } from '@bbkAdminComponents/rich-text-editor';
import {
  integrationSlice,
  selectNonUrlFields,
  selectUrlFields,
} from '@bbkAdminRedux/rtkq/integrations.slice';

type Props = {
  value: RestModalForwards;
  onChange?: (cta: RestModalForwards) => unknown;
};
export const UrlSelect: ReactFCC<Props> = ({ value: cta, onChange }) => {
  const createUrl = useUrl();

  const appKey = useSelector(selectAppId);
  const { data: confFunnels, isFetching: isFetchingCCPs } =
    confirmationFunnelSlice.endpoints.getConfirmationFunnels.useQuery({
      appKey,
    });
  const confFunnelsOpts = useMemo(
    () =>
      (confFunnels ?? [])
        .filter((cf) => cf.active)
        .map((cf) => ({ value: cf.id, label: cf.display_name })),
    [confFunnels]
  );

  const { data: suggestions } =
    integrationSlice.endpoints.getFieldMappings.useQuery(
      { appKey },
      { selectFromResult: selectNonUrlFields }
    );
  const { data: urlSuggestions } =
    integrationSlice.endpoints.getFieldMappings.useQuery(
      { appKey },
      { selectFromResult: selectUrlFields }
    );

  const destinationType = cta.destination_type;
  const confFunnelKey = extractConfirmationFunnelKey(cta.url);

  const updateCta = (updatedCta: RestModalForwards): void => {
    onChange?.(updatedCta);
  };

  const renderEditor = (): React.ReactNode => {
    switch (cta.destination_type) {
      case DestinationType.confirmation_page:
        return (
          <GenericSelect
            isSearchable={false}
            menuPosition="fixed"
            isDisabled={isFetchingCCPs}
            options={confFunnelsOpts}
            onChange={({ value }) => {
              const updatedCta = produce(cta, (draft) => {
                draft.url = `?confirmation_funnel=${value}`;
              });
              updateCta(updatedCta);
            }}
            value={confFunnelsOpts.find((cf) => cf.value === confFunnelKey)}
          />
        );
      case DestinationType.global:
        return (
          <div>
            Global URL Configurations are managed in the application
            <Link
              className="tw-font-bold tw-text-cbrprimary-600 tw-mx-1"
              to={createUrl(AuthorizedPaths.settingsSetup)}
            >
              Settings
            </Link>
            page
          </div>
        );
      default:
        return (
          <RichTextEditor
            value={cta.url}
            onChange={(value) => {
              const updatedCta = produce(cta, (draft) => {
                draft.url = value;
              });
              updateCta(updatedCta);
            }}
            suggestions={suggestions}
            urlSuggestions={urlSuggestions}
            enabledButtons={{
              bold: false,
              italic: false,
              underline: false,
              link: false,
              urlFields: true,
            }}
            outputType="plaintext"
          />
        );
    }
  };

  return (
    <>
      <GenericSelect
        isSearchable={false}
        menuPosition="fixed"
        options={destinationProtocols}
        onChange={({ value }) => {
          const updatedCta = produce(cta, (draft) => {
            draft.destination_type = value;
            if (value === DestinationType.confirmation_page) {
              draft.url = `?confirmation_funnel=${confFunnelsOpts[0]?.value}`;
            } else if (value === DestinationType.global) {
              delete draft.url;
            } else {
              delete draft.destination_type;
            }
          });
          updateCta(updatedCta);
        }}
        value={
          destinationProtocols.find((x) => x.value === destinationType) ??
          destinationProtocols[0]
        }
      />
      <div
        className="tw-pl-6 tw-mt-5"
        style={{
          border: 'none',
          borderLeft: '3px solid rgba(0,0,0,.5)',
        }}
      >
        {renderEditor()}
      </div>
    </>
  );
};
