import React from 'react';
import cs from 'classnames';

export const SigninButton = ({
  copy,
  onClick,
  icon,
}: {
  copy: string;
  onClick: () => void;
  icon: React.ReactNode;
}) => (
  <div className="google">
    <button
      onClick={onClick}
      type="button"
      className={cs(
        'tw-w-full tw-py-2 tw-px-8',
        'tw-text-sm tw-font-semibold',
        'tw-flex tw-items-center tw-justify-center',
        'tw-rounded tw-bg-cbrneutral-50',
        'tw-border-solid tw-border-2 tw-border-cbrneutral-200',
        'hover:tw-border-cbrneutral-400'
      )}
    >
      {icon}
      <span className="tw-ml-2">{copy}</span>
    </button>
  </div>
);
