import React, { useEffect, useState } from 'react';
import {
  Expiration,
  PLAN_REMINDER_DAYS,
  useDaysToExpiration,
  usePlanPurchaseIntent,
} from '@bbkAdminRedux/rtkq/checkout.slice';
import moment from 'moment';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import tailwindColors from '@bbkRoot/tailwind.colors';
import { useRoles } from '@bbkAdminUtils/roles';
import { Constants } from '@bbkUtils/constants/ui-constants';

const Banner = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 80px;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  color: white;
  background: ${tailwindColors.cbrprimary['400']};
  padding: 20px;
`;

export const EndOfPlanBanner: React.FC = () => {
  const { hasBillingRole } = useRoles();
  const expiration: Expiration | undefined = useDaysToExpiration();
  const [message, setMessage] = useState<string>('');
  const [subMessage, setSubMessage] = useState<string>('');
  const handlePlanPurchaseIntent = usePlanPurchaseIntent();
  useEffect(() => {
    if (!expiration) {
      setMessage('');
      setSubMessage('');
      return;
    }
    const { isTrial, endDate } = expiration;
    const daysToExpiry = endDate.diff(moment.utc(), 'days');
    if (daysToExpiry < 0) {
      setMessage(
        `Your ${
          isTrial ? '30 day free trial' : 'plan'
        } ended on ${endDate.format('MMM D, YYYY')} UTC`
      );
      setSubMessage(
        hasBillingRole
          ? 'To publish your pages and deploy traffic you will need to upgrade.'
          : ''
      );
    } else if (daysToExpiry <= PLAN_REMINDER_DAYS) {
      setMessage(
        `Your ${isTrial ? 'trial' : 'plan'} is due to end on ${endDate.format(
          'MMM D, YYYY'
        )} UTC`
      );
      setSubMessage(
        hasBillingRole
          ? 'Click upgrade to ensure your traffic keeps routing to your cancel page(s).'
          : ''
      );
    }
  }, [expiration, hasBillingRole]);

  return (
    <>
      {message && (
        <Banner>
          <div>
            <div>
              <strong>{message}</strong>
            </div>
            <div>
              <p css={{ 'font-size': '0.75em' }}>{subMessage}</p>
            </div>
          </div>
          <div>
            {hasBillingRole && (
              <Link
                to={{
                  pathname: Constants.PurchaseLink,
                }}
                target="_blank"
              >
                <ButtonV2
                  onClick={() => {
                    handlePlanPurchaseIntent();
                  }}
                  primary
                  css={{ 'border-radius': '10em' }}
                >
                  Purchase
                </ButtonV2>
              </Link>
            )}
          </div>
        </Banner>
      )}
    </>
  );
};
