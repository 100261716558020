import React from 'react';
import type { Argument } from 'classnames';
import cs from 'classnames';
import './badge.scss';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';

type Props = {
  badgeClasses?: Argument;
  badgeInnerWrapperClasses?: Argument;
};

const Badge: ReactFCC<Props> = (props) => {
  const { badgeClasses, badgeInnerWrapperClasses, children } = props;
  return (
    <span className="-tw-mt-1">
      <span className={cs('bbk-badge', badgeClasses)}>
        <div
          className={cs('bbk-badge-inner-wrapper', badgeInnerWrapperClasses)}
        >
          {children}
        </div>
      </span>
    </span>
  );
};

export default Badge;
