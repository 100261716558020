import type { BbkClientConfig } from '@bbkRoot/typings';
import { pollFor } from '@bbkAdminUtils/poll-for';
import {
  encodeObject,
  encodeObjectForQuery,
} from '@bbkAdminRedux/rtkq/rtkq-utils';
import { LogInPaths } from '@bbkAdminComponents/log-in-flow/log-in-paths';
import { popupCenter } from '@bbkAdminUtils/popup';
import { JWTParser } from '@bbkAdminUtils/jwt-parser';
import type { SignInTokens } from '@bbkAdminRedux/rtkq/brightback-auth.slice';
import { SignInType } from '@bbkAdminRedux/rtkq/brightback-auth.slice';

export type ChargebeeTokens = { access_token: string };

export type ChargebeeSignInArgs = { email?: string; jwt?: string };

export type ChargebeeGetSignInUrlArgs = { email?: string; redirect?: string };

export class AuthChargebee {
  private config: BbkClientConfig;

  private static BASE_URI = window.location.origin;

  private static LOGIN_REDIRECT_URL = `${AuthChargebee.BASE_URI}${LogInPaths.cbidpExchange}`;

  private static exchangeTokenURL = `/api/v2/cbidp/exchange_token`;

  public static signOutUrl = `/api/v2/cbidp/logout?${encodeObject({ redirect: `${AuthChargebee.BASE_URI}${LogInPaths.signIn}` })}`;

  constructor(config: BbkClientConfig) {
    this.config = config;
  }

  public getSignInUrl({ email, redirect }: ChargebeeGetSignInUrlArgs) {
    const query = encodeObject({
      client_id: this.config.cbidp_client_id,
      forward: `${AuthChargebee.LOGIN_REDIRECT_URL}${encodeObjectForQuery({ redirect })}`,
      login_hint: email,
    });
    return `${this.config.cbidp_base_uri}/login?${query}`;
  }

  private transformChargebeeTokens(tokens: ChargebeeTokens): SignInTokens {
    const payload = JWTParser.parse<{ exp: number }>(tokens.access_token)!;
    return {
      type: SignInType.CHARGEBEE,
      access_token: tokens.access_token,
      refresh_token: tokens.access_token,
      exp: payload.exp,
    };
  }

  private exchangeJwtCodeForAccessToken = (
    jwt: string
  ): Promise<SignInTokens> =>
    fetch(AuthChargebee.exchangeTokenURL, {
      method: 'POST',
      body: JSON.stringify({ jwt }),
    }).then(async (response) => {
      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      return this.transformChargebeeTokens(result as ChargebeeTokens);
    });

  public doRefresh = this.exchangeJwtCodeForAccessToken;

  private pollForJwtToken = pollFor((win) => {
    const url = new URL(win.location.toString());
    return url.searchParams.get('jwt');
  });

  public signIn = async ({
    email,
    jwt,
  }: ChargebeeSignInArgs): Promise<SignInTokens> => {
    if (jwt) return this.exchangeJwtCodeForAccessToken(jwt);

    const loginURL = this.getSignInUrl({ email });

    const pop = popupCenter(loginURL, 'Chargebee Log in', 640, 640);
    if (!pop) throw new Error('Could not log in via Chargebee');

    const code = await this.pollForJwtToken(pop);
    if (!code) throw new Error('Could not log in via Chargebee');

    const tokens = await this.exchangeJwtCodeForAccessToken(code);
    pop.close();
    return tokens;
  };
}
