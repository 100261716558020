import * as React from 'react';
import { useSlate } from 'slate-react';
import { DEFAULTS_BBK_EXPRESSION } from '../defaults';
import {
  setDefaults,
  someNode,
  ToolbarButton,
  ToolbarButtonProps,
} from '@udecode/slate-plugins';
import { css } from '@emotion/react';
import tailwindColors from '@bbkRoot/tailwind.colors';

export const ToolbarBbkExpression = ({
  bbkExpression,
  activateMentionViaToolbar,
  onToolbarMentionClick,
  ...props
}: ToolbarButtonProps) => {
  const options = setDefaults({ bbkExpression }, DEFAULTS_BBK_EXPRESSION);
  const editor = useSlate();
  // const active = isNodeTypeIn(editor, );
  const active = someNode(editor, { match: options.bbkExpression.type });

  return (
    <ToolbarButton
      css={css`
        cursor: pointer;
        height: 28px;
        width: 32px;
        color: ${active ? 'white' : 'black'} !important;
        background: ${active ? tailwindColors.cbrprimary['600'] : 'none'};
        padding: 3px 5px;
        margin: 5px;
        border-radius: 3px;
        display: flex;

        &:hover,
        &:active {
          background: ${active
            ? tailwindColors.cbrprimary['600']
            : 'rgba(144, 19, 254, 0.1)'};
          color: ${active ? 'white' : 'black'} !important;
        }
      `}
      active={active}
      onMouseDown={(event) => {
        event.preventDefault();

        // activate the mention select
        if (!activateMentionViaToolbar) {
          onToolbarMentionClick(event.currentTarget, editor);
        } else {
          onToolbarMentionClick(false, editor);
        }
      }}
      {...props}
    />
  );
};
