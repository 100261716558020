/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Dialog } from '@material-ui/core';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import { CBRIcons } from '@bbkAdminUtils/icons';
import { ButtonV2 } from '@bbkAdminComponents/buttons';

const InfoModalContext = createContext<{
  open: boolean;
  title: React.ReactNode;
  description: React.ReactNode;
  triggerModal: (args: {
    title: React.ReactNode;
    description: React.ReactNode;
  }) => void;
}>(undefined!);

const InfoModal: ReactFCC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const { open, title, description } = useContext(InfoModalContext);
  return (
    <Dialog
      fullWidth
      open={open}
      PaperProps={{
        style: { overflow: 'hidden', maxWidth: '500px' },
      }}
      onClose={onClose}
    >
      <div className="tw-px-8 tw-py-5">
        <div className="tw-flex tw-justify-between">
          <div>
            <div className="tw-text-2xl tw-font-bold tw-mb-4">{title}</div>
            <div className="tw-text-sm tw-mb-8">{description}</div>
          </div>
          <div className="tw-cursor-pointer" onClick={onClose}>
            {CBRIcons.cbrClose(20, 20)}
          </div>
        </div>
        <div>
          <ButtonV2 primary onClick={onClose} className="!tw-px-8">
            Got it
          </ButtonV2>
        </div>
      </div>
    </Dialog>
  );
};

export const InfoModalContainer: ReactFCC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<React.ReactNode>();
  const [description, setDescription] = useState<React.ReactNode>();

  const triggerModal = useCallback(
    (args: { title: React.ReactNode; description: React.ReactNode }) => {
      setTitle(args.title);
      setDescription(args.description);
      setOpen(true);
    },
    []
  );

  const value = useMemo(
    () => ({
      open,
      title,
      description,
      triggerModal,
    }),
    [open, title, description, triggerModal]
  );

  return (
    <InfoModalContext.Provider value={value}>
      {children}
      <InfoModal onClose={() => setOpen(false)} />
    </InfoModalContext.Provider>
  );
};

export const useInfoModal = () => useContext(InfoModalContext);
