export const pollFor =
  <T>(fn: (win: Window, timeout?: number) => T) =>
  (win: Window, timeout = 30) =>
    new Promise<T>((accept, reject) => {
      console.log(`Polling every ${timeout} ms`);
      const pollTimer = window.setInterval(() => {
        try {
          if (win.closed) {
            clearInterval(pollTimer);
            reject(new Error('window is closed. Stop polling.'));
            return;
          }

          if (win.location.href === undefined) {
            clearInterval(pollTimer);
            reject(new Error('window location is undefined. Stop polling.'));
            return;
          }

          const result = fn(win);
          if (result) {
            const resultStr = JSON.stringify(result);
            console.log(
              `Result of polling is truthy: ${resultStr}. Stop polling.`
            );
            clearInterval(pollTimer);
            accept(result);
            return;
          }
        } catch (e) {
          /* ignore */
        }
      }, timeout);
    });
