import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { chain } from 'lodash';
import moment from 'moment';
import cs from 'classnames';
import '@bbkAdminComponents/settings/settings.scss';
import {
  HeaderItem,
  RowItem,
  RowLinkButton,
} from '@bbkAdminComponents/settings/lib';
import icons from '@bbkAdminUtils/icons';
import TippyToolTip from '@bbkAdminComponents/tooltips/tippy-tooltip';
import type { Integration } from '@bbkAdminRedux/data-management/reducers';
import {
  IntegrationDataType,
  IntegrationTypeEnum,
} from '@bbkAdminRedux/data-management/reducers';
import { segmentCustomEvent } from '@bbkAdminUtils/analytics';
import { selectAppId } from '@bbkAdminRedux/app/selectors';
import {
  integrationSlice,
  useAvailableIntegrations,
  useRetrieveIntegrationFields,
  useSelectBillingIntegration,
} from '@bbkAdminRedux/rtkq/integrations.slice';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import IntegrationSelectKeyModal from './integration-select-key-modal';
import IntegrationModal from './integration-modal';

const Status: React.FC<{ integration: Integration; short?: boolean }> = ({
  integration,
  short,
}) => {
  let hasError = false;
  let errorMessage: string | undefined;
  let lastUpdated: string;

  if (integration.integration_status) {
    const { last_bad_use, last_good_use, updated_at } =
      integration.integration_status;
    hasError =
      last_bad_use !== undefined &&
      (last_good_use === undefined ||
        moment(last_bad_use).isAfter(last_good_use));
    errorMessage = hasError
      ? integration.integration_status.fix_message
      : undefined;

    const lastUpdatedDate = last_good_use || updated_at;
    lastUpdated = moment(lastUpdatedDate).utc().format('L HH:mm');
  }

  const renderTimestamp = (): React.ReactNode =>
    !short && lastUpdated ? (
      <span className="tw-ml-2" title="Last updated">
        ({lastUpdated})
      </span>
    ) : undefined;

  if (!integration.connected) {
    return short ? '' : <div>Not configured.</div>;
  }

  if (!errorMessage) {
    return (
      <>
        {icons.check}
        <span className="tw-ml-2 tw-text-[#10a069]">Enabled</span>
        {renderTimestamp()}
      </>
    );
  }

  return (
    <>
      <TippyToolTip content={errorMessage}>
        <span
          css={{
            color: '#f32b43',
            path: { fill: 'currentColor' },
          }}
        >
          {icons.warning}
          <span className="tw-ml-2">Error</span>
        </span>
      </TippyToolTip>
      {renderTimestamp()}
    </>
  );
};

const cleanModalProps = { id: -1 };
const ManageButton: React.FC<{ integration: Integration; short?: boolean }> = ({
  integration,
  short,
}) => {
  const appKey = useSelector(selectAppId);
  const connectedBillingIntegration = useSelectBillingIntegration({ appKey });

  // allow only one billing integration to be enabled
  // disable rest of billing integrations
  const disabled = useMemo(() => {
    if (!connectedBillingIntegration) return false;
    if (integration.integration_type !== IntegrationTypeEnum.Billing) {
      return false;
    }
    return integration.id !== connectedBillingIntegration.id;
  }, [integration, connectedBillingIntegration]);

  const [modalProps, setModalProps] = useState<{ id: number }>();
  const [selectKeyModalProps, setSelectKeyModalProps] = useState<{
    id: number;
    objectName: string;
  }>();

  const openModal = (args: { id: number }): void => {
    setModalProps({ ...args });
  };

  const hideModal = (): void => {
    setModalProps(cleanModalProps);
  };

  const selectKey = (integrationId: number, objectName: string): void => {
    setSelectKeyModalProps({
      id: integrationId,
      objectName,
    });
  };

  return (
    <>
      <RowLinkButton
        onClick={() => {
          segmentCustomEvent('Settings - Integrations: clicked "manage"', {
            display_name: integration.display_name,
            status: integration.connected,
          });
          openModal({ id: integration.id });
        }}
        disabled={disabled}
        title={
          disabled
            ? 'Only one billing connection allowed at a time. Disconnect existing one to connect to another.'
            : ''
        }
        small={short}
      >
        {!short ? 'Manage' : ''}
      </RowLinkButton>
      {modalProps && (
        <IntegrationModal
          id={modalProps.id}
          closeFunc={hideModal}
          selectKey={selectKey}
        />
      )}
      {selectKeyModalProps && (
        <IntegrationSelectKeyModal
          id={selectKeyModalProps.id}
          objectName={selectKeyModalProps.objectName}
          closeFunc={() => setSelectKeyModalProps(undefined)}
          invisibleBackdrop
        />
      )}
    </>
  );
};

export const IntegrationRow: ReactFCC<{
  integration: Integration;
  className?: string;
  short?: boolean;
}> = ({ integration, className, short }) => (
  <RowItem className={className}>
    <div
      className={cs('tw-flex tw-items-center', {
        'tw-basis-2/5': !short,
        'tw-flex-grow': short,
      })}
    >
      <div css={{ svg: { width: 22, height: 'auto' } }}>
        {
          icons[
            integration.data_type.toLowerCase() as Lowercase<IntegrationDataType>
          ]
        }
      </div>
      <span className="tw-ml-2">{integration.display_name}</span>
    </div>
    <div
      className={cs({
        'tw-flex-1': !short,
      })}
    >
      <Status integration={integration} short={short} />
    </div>
    <ManageButton integration={integration} short={short} />
  </RowItem>
);

export const IntegrationsPage: React.FC = () => {
  const appKey = useSelector(selectAppId);
  const { isFetching: isIntegrationsFetching } =
    integrationSlice.endpoints.getIntegrations.useQuery({ appKey });
  const { data: integrations = [] } = useAvailableIntegrations();
  const retrieveIntegrationFields$ = useRetrieveIntegrationFields();
  useEffect(() => {
    retrieveIntegrationFields$();
  }, []);

  const renderIntegrations = (): React.ReactNode => {
    // Get the types
    const integrationTypes = integrations
      .map((t) => t.integration_type)
      .filter((t, index, self) => self.indexOf(t) === index)
      .sort();

    // return as grouped items
    return integrationTypes.map((it) => (
      <div key={it}>
        <HeaderItem>
          {['CRM'].includes(it)
            ? it
            : chain(it).lowerCase().startCase().value()}
        </HeaderItem>
        {integrations
          .filter((i) => i.integration_type === it)
          .map((integration) => (
            <IntegrationRow key={integration.id} integration={integration} />
          ))}
      </div>
    ));
  };

  return (
    <div className="-tw-mt-4">
      <div>{renderIntegrations()}</div>
      {isIntegrationsFetching && (
        <div>{icons.loader} Loading integrations...</div>
      )}
    </div>
  );
};
