import React from 'react';
import { startCase } from 'lodash';
import type { SelectOption } from '@bbkAdminComponents/select/select';

export const intervalCountOptions: SelectOption<number>[] = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 },
  { label: 12, value: 12 },
  { label: 13, value: 13 },
  { label: 14, value: 14 },
  { label: 15, value: 15 },
  { label: 16, value: 16 },
  { label: 17, value: 17 },
  { label: 18, value: 18 },
  { label: 19, value: 19 },
  { label: 20, value: 20 },
  { label: 21, value: 21 },
  { label: 22, value: 22 },
  { label: 23, value: 23 },
  { label: 24, value: 24 },
  { label: 25, value: 25 },
  { label: 26, value: 26 },
  { label: 27, value: 27 },
  { label: 28, value: 28 },
  { label: 29, value: 29 },
  { label: 30, value: 30 },
  { label: 31, value: 31 },
];
export const billingIntervalPeriod = ['day', 'week', 'month', 'year'];
export const billingIntervalOptionsWithCode = [
  { label: 'Day(s)', value: 'D' },
  { label: 'Week(s)', value: 'W' },
  { label: 'Month(s)', value: 'M' },
  { label: 'Year(s)', value: 'Y' },
];
export const billingIntervalOptions = billingIntervalPeriod.map((p) => ({
  label: `${startCase(p)}(s)`,
  value: p,
}));
export const getDurationFromCode = (code?: string): number => {
  if (!code) return 0;
  const interval = getIntervalFromCode(code);
  if (interval.length === 0) {
    return +code.slice(1);
  }
  return +code.slice(1, code.indexOf(interval));
};
export const getIntervalFromCode = (code?: string) => {
  if (!code) return '';
  if (code.indexOf('D') !== -1) return 'D';
  if (code.indexOf('W') !== -1) return 'W';
  if (code.indexOf('M') !== -1) return 'M';
  if (code.indexOf('Y') !== -1) return 'Y';
  if (code.indexOf('C') !== -1) return 'C';
  return '';
};
