/* eslint-disable max-len */
import React from 'react';

export const ErrorPage: React.FC<{ message: string }> = ({ message }) => (
  <div className="container">
    <link
      rel="stylesheet"
      type="text/css"
      href="/assets/bootstrap/bootstrap-reboot.min.css"
    />
    <link rel="stylesheet" type="text/css" href="/assets/stylesheets/404.css" />
    <div className="error-template">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="91"
        height="80"
        viewBox="0 0 91 80"
        className="warning"
      >
        <g fill="none" fillRule="evenodd" transform="translate(-372 -494)">
          <rect width="835" height="606" fill="#FAFAFA" />
          <path
            fill="#000"
            fillRule="nonzero"
            d="M462.220601,565.435889 L422.446579,496.838176 C420.248869,493.053941 414.752597,493.053941 412.554888,496.838176 L372.780866,565.435889 C370.583156,569.220124 373.280527,574 377.675945,574 L457.22399,574 C461.72094,573.996111 464.41831,569.216241 462.220601,565.435889 Z M416.25178,517.843226 C418.648555,517.244288 421.049326,518.442164 422.146182,520.631819 C422.544352,521.429109 422.645844,522.323613 422.544352,523.120923 C422.345267,525.610027 422.243776,528.099131 422.146182,530.689392 C421.947097,534.570801 421.646521,538.553368 421.447436,542.438958 C421.345945,543.632932 421.345945,544.826905 421.248351,546.122036 C421.14686,548.214476 419.550303,549.805113 417.450128,549.805113 C415.451483,549.805113 413.753435,548.210494 413.651904,546.21921 C413.351328,540.144204 412.953158,534.073379 412.652582,527.997974 C412.551091,526.403354 412.453497,524.71156 412.352006,523.120923 C412.355909,520.729093 413.95252,518.438321 416.251661,517.843226 L416.25178,517.843226 Z M417.551699,563.444606 C414.752797,563.444606 412.555088,561.153834 412.555088,558.365241 C412.555088,555.576648 414.854328,553.285876 417.65323,553.285876 C420.452131,553.285876 422.649841,555.576648 422.649841,558.462416 C422.54835,561.153735 420.249169,563.444507 417.551699,563.444507 L417.551699,563.444606 Z"
            opacity=".1"
          />
        </g>
      </svg>
      <div className="h2">Uh oh!</div>
      <div className="error-details">{message}</div>
    </div>
  </div>
);
