import { isDefined } from '@bbkAdminRedux/rtkq/rtkq-utils';

export enum ErrorType {
  validation_error = 'validation_error',
  missing_or_invalid_email = 'missing_or_invalid_email',
}

export type APIError = {
  type: ErrorType;
  message: string;
};
export type APIV1ErrorResponse = APIError;
export type APIV2ErrorResponse = {
  metadata: { error: APIError };
};
export type APIErrorResponse = APIV1ErrorResponse | APIV2ErrorResponse;
export type RTKAPIErrorResponse = { data: APIErrorResponse };

export const isAPIV2Error = (
  err: APIErrorResponse
): err is APIV2ErrorResponse => isDefined((err as APIV2ErrorResponse).metadata);

export const parseAPIError = (err: APIErrorResponse) =>
  isAPIV2Error(err) ? err.metadata.error : err;

export const parseRTKAPIError = (res: { data: APIErrorResponse }) =>
  parseAPIError(res.data);
