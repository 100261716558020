export const LogInPaths = {
  company: '/company/',
  selector: '/company/selector',
  signIn: '/company/sign-in',
  signUp: '/company/sign-up',
  reset: '/company/reset-password',
  mfaReset: '/company/mfa-reset-password',
  cbidpExchange: '/cbidp-exchange',
  logout: '/logout',
  forgotPassword: '/company/forgot-password',
  verify: '/company/verify',
  invite: '/company/invite',
};
