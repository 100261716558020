/* eslint-disable max-len */
import '@bbkAdminStylesheets/signInWithRoutes.scss';
import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import cs from 'classnames';
import { skipToken } from '@reduxjs/toolkit/query/react';
import type { LogArgument } from 'rollbar';
import _ from 'lodash';
import type { CognitoUser } from 'amazon-cognito-identity-js';
import BrightbackSession from '@bbkAdminUtils/brightback-session';
import { useSearchParams } from '@bbkAdminUtils/useSearchParams';
import { PLANS } from '@bbkAdminRedux/rtkq/checkout.plans';
import { SignupPaths } from '@bbkAdminRouter/signup-paths';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';
import { ForgotPassword } from '@bbkAdminComponents/log-in-flow/forgot-password';
import type * as LogInTypes from '@bbkAdminComponents/log-in-flow/log-in-types';
import { ResetPassword } from '@bbkAdminComponents/log-in-flow/reset-password';
import { SignIn } from '@bbkAdminComponents/log-in-flow/sign-in';
import { SignUp } from '@bbkAdminComponents/log-in-flow/sign-up';
import { Verify } from '@bbkAdminComponents/log-in-flow/verify';
import { PreparingOverlay } from '@bbkAdminComponents/preparing-overlay/preparing-overlay';
import {
  IdentityProviderEnum,
  preauthCompanySlice,
} from '@bbkAdminRedux/rtkq/preauth-company.slice';
import type { CompanyData } from '@bbkAdminComponents/trial-signup/TrialSignupContext';
import { useSetBBAuthenticityToken } from '@bbkAdminRedux/rtkq/current-user.slice';
import type { ActiveUser } from '@bbkAdminRedux/app/reducers';
import { LogInPaths } from '@bbkAdminComponents/log-in-flow/log-in-paths';
import { encodeObjectForQuery } from '@bbkAdminRedux/rtkq/rtkq-utils';
import { selectLastActive } from '@bbkAdminRedux/rtkq/app-persist/app-persist.slice';
import { recordError } from '@bbkAdminUtils/recordError';
import {
  brightbackAuthSlice,
  SignInType,
} from '@bbkAdminRedux/rtkq/brightback-auth.slice';

type LoginError = { title: string; message: string; action?: string };
export const LoginErrorMap: Record<string, LoginError> = {
  UserNotFoundException: {
    title: "Something's not right...",
    message: 'Email address not found',
  },
  PasswordResetRequiredException: {
    title: 'Password reset required',
    message:
      'Chargebee Retention values your security and as part of our normal security practices, we require a password reset. Please use the "Reset Password" button below to reset your password.',
    action: '/company/forgot-password',
  },
};

export const LogInRouter: React.FC = () => {
  const history = useHistory();
  const lastActive = useSelector(selectLastActive);
  const searchParams = useSearchParams<
    {
      invite: string;
      email: string;
      tmp: string;
      redirectUri: string;
      // next three fields are specific to initiating signIn flow from chargebee Product Switcher
      idp_hint: IdentityProviderEnum.CHARGEBEE_IDP;
      login_hint: string;
      forward: string;
    } & CompanyData
  >();
  const inviteQuery = searchParams.invite;
  const emailQuery = searchParams.email ?? '';
  const tmpPWQuery = searchParams.tmp;
  const cbQuery = searchParams.cb_email;
  const redirectUri = searchParams.redirectUri;
  const search = history.location.search;
  const setBBAuthenticityToken = useSetBBAuthenticityToken();

  // Sign in
  const [openSignIn$] = brightbackAuthSlice.endpoints.openSignIn.useMutation();
  const [signIn$] = brightbackAuthSlice.endpoints.signIn.useMutation();

  // Sign up
  const [signUp$] = brightbackAuthSlice.endpoints.signUp.useMutation();
  const [confirmSignUp$] =
    brightbackAuthSlice.endpoints.confirmSignUp.useMutation();
  const [resendSignUpConfirmation$] =
    brightbackAuthSlice.endpoints.resendSignUpConfirmation.useMutation();
  const [updatePassword$] =
    brightbackAuthSlice.endpoints.updatePassword.useMutation();
  const [mfaUpdatePassword$] =
    brightbackAuthSlice.endpoints.mfaUpdatePassword.useMutation();
  const [sendUpdatePasswordCode$] =
    brightbackAuthSlice.endpoints.sendUpdatePasswordCode.useMutation();

  // Sign out
  const [signOut$] = brightbackAuthSlice.endpoints.signOut.useMutation();

  useEffect(() => {
    if (searchParams.idp_hint === IdentityProviderEnum.CHARGEBEE_IDP) {
      openSignIn$({
        type: SignInType.CHARGEBEE,
        email: searchParams.login_hint,
        redirect: searchParams.forward,
      });
    }
  }, []);

  const [email, setEmail] = useState<string>(
    emailQuery || lastActive?.email || ''
  );
  const [password, setPassword] = useState<string>(tmpPWQuery || '');
  const [passwordVerifyCode, setPasswordVerifyCode] = useState('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [error, setError] = useState<LogInTypes.Error>();
  const [success, setSuccess] = useState<LoginError>();
  const [sending, setSending] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [mfaUser, setMfaUser] = useState<CognitoUser>();
  const [userDetails, setUserDetails] = useState<unknown>();
  const [showOverLay, setShowOverLay] = useState<boolean>(false);
  const [cacheCompanyCreation] =
    preauthCompanySlice.endpoints.cacheCompanyCreation.useMutation();
  const [createCompanyFromCache] =
    preauthCompanySlice.endpoints.createCompanyFromCache.useMutation();

  const { data: chargebeeCachedData } =
    preauthCompanySlice.endpoints.fetchCachedDataFromChargebee.useQuery(
      cbQuery ? { token: cbQuery } : skipToken
    );

  const [assignInvitation$] =
    preauthCompanySlice.endpoints.assignInvitation.useMutation();

  const signupPlan = chargebeeCachedData?.subscription_plan;
  const isStarter =
    signupPlan === PLANS.STARTER_FREE || signupPlan === PLANS.STARTER_PREMIUM;

  useEffect(() => {
    if (cbQuery) setEmail(cbQuery);
  }, [cbQuery]);

  useEffect(() => {
    async function init() {
      if (history.location.pathname === LogInPaths.signIn && inviteQuery) {
        history.push({ pathname: LogInPaths.signUp, search });
        return;
      } else if (
        history.location.pathname === LogInPaths.mfaReset &&
        emailQuery &&
        tmpPWQuery
      ) {
        setDisabled(false);
        setEmail(emailQuery);
        setPassword(tmpPWQuery);
        await signOut$().unwrap();
        await performSignIn();
      } else if (history.location.pathname === LogInPaths.logout) {
        setDisabled(false);
        await signOut$().unwrap();
        history.push({ pathname: LogInPaths.signIn });
        return;
      }
    }

    init();
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    body?.classList.add('allow-overflow');
    return () => {
      body?.classList.remove('allow-overflow');
    };
  });

  useEffect(() => {
    const alert = BrightbackSession.getAuthAlert();
    if (alert) {
      BrightbackSession.setAuthAlert(null);
      BlackToastTrigger({
        content: `Session ended. ${alert}`,
        options: { toastId: 'sessionEndedAlert' },
      });
    }
  }, []);

  const formValues = {
    email,
    setEmail,
    password,
    setPassword,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    passwordVerifyCode,
    setPasswordVerifyCode,
  };

  const checkCompanyCreation = async (em: string) => {
    if (searchParams?.companyName) {
      const { companyName, domain, additionalParams } = searchParams;

      if (!companyName || !domain) {
        throw Error('Company fields cannot be blank');
      }
      await cacheCompanyCreation({
        email: em,
        company_name: companyName,
        domain,
        additional_params: additionalParams || undefined,
      }).unwrap();
    }

    await createCompanyFromCache()
      .unwrap()
      .then(({ companyKey, appKey }) => {
        if (!companyKey || !_.isString(companyKey)) {
          window.location.pathname = `/company`;
        }

        if (!isStarter && searchParams?.companyName) {
          // company creation path
          if (searchParams?.cb_email) {
            BrightbackSession.setActiveCompany(
              { internal_name: companyKey },
              false
            );
          }
          history.push({
            pathname: SignupPaths.trialSignup3,
            search: encodeObjectForQuery({ companyKey, ...searchParams }),
          });
          return;
        } else {
          // no company creation - normal login
          if (redirectUri) {
            window.location.pathname = redirectUri;
          } else {
            window.location.pathname =
              companyKey && appKey
                ? `/company/${companyKey}/app/${appKey}`
                : '/company';
          }
        }
      })
      .catch((err) => {
        recordError(err);
        window.location.reload();
      });
  };

  const googleSignIn: LogInTypes.GoogleSignIn = async () => {
    try {
      setShowOverLay(true);
      BlackToastTrigger({ content: 'Signing you in.' });
      const tokens = await signIn$({ type: SignInType.GOOGLE }).unwrap();
      if (!tokens) {
        throw new Error('Failed to sign in via Google');
      }
      const user = (await setBBAuthenticityToken()) as ActiveUser;
      await checkCompanyCreation(user.email);
    } catch (e) {
      setShowOverLay(false);
      (window.Rollbar ?? console).warn(e as LogArgument);
    }
  };

  const chargebeeSignIn = async (em?: string) => {
    try {
      setShowOverLay(true);
      BlackToastTrigger({ content: 'Signing you in.' });
      const tokens = await signIn$({
        type: SignInType.CHARGEBEE,
        email: em,
      }).unwrap();
      if (!tokens) {
        throw new Error('Failed to sign in via Chargebee');
      }
      const user = (await setBBAuthenticityToken()) as ActiveUser;
      await checkCompanyCreation(user.email);
    } catch (e) {
      setShowOverLay(false);
      (window.Rollbar ?? console).warn(e as LogArgument);
    }
  };

  const displayError = (err: LogInTypes.Error) => {
    const title = "Something's not right...";
    const errorMessage =
      (err.code && LoginErrorMap[err.code]) ||
      (err.message
        ? {
            title,
            message: err.message.replace('Username', 'Email'),
          }
        : {
            title,
            message: 'Please double-check the fields below and try again.',
          });
    setError(errorMessage);
    if (errorMessage.action) {
      history.push(errorMessage.action);
      return;
    }
  };

  const displaySuccess = (
    msg: {
      title: string;
      message: string;
    },
    cb?: () => void
  ) => {
    setSuccess(msg);
    setSending(false);
    setTimeout(() => {
      setSuccess(undefined);
      if (cb) cb();
    }, 3000);
  };

  const performSignIn = async () => {
    try {
      await signIn$({
        type: SignInType.USERNAME,
        email,
        pw: password,
        onMFAReset: (usr, usrDetails) => {
          setSending(false);
          setDisabled(false);
          setMfaUser(usr);
          const deets = { ...usrDetails };
          // email_verified will be rejected by the api
          delete deets.email_verified;
          setUserDetails(deets);
          history.push({ pathname: LogInPaths.mfaReset });
          return false;
        },
      }).unwrap();
    } catch (err) {
      const e = err as LogInTypes.Error;
      setSending(false);
      if (e.code === 'UserNotConfirmedException') {
        setError(undefined);
        history.push({
          pathname: LogInPaths.verify,
          search: encodeObjectForQuery({ email }),
        });
        return false;
      } else {
        setError(e);
        throw e;
      }
    }
    return true;
  };

  const verify = async (code: string) => {
    try {
      setSending(true);
      setError(undefined);
      await confirmSignUp$({
        email: emailQuery,
        signUpConfirmationCode: code,
      }).unwrap();

      if (email && password) {
        await signIn();
      } else {
        history.push({
          pathname: LogInPaths.signIn,
          search: encodeObjectForQuery(searchParams),
        });
        setSending(false);
        return false;
      }
    } catch (e) {
      const err = e as LogInTypes.Error;
      if (!err.message.includes('Current status is CONFIRMED')) {
        setSending(false);
        displayError(err);
        setShowOverLay(false);
        (window.Rollbar ?? console).warn(
          `Sign-in Error: failed to verify user ${email} in Cognito`
        );
        return false;
      }
    }
    return true;
  };

  const signIn = async (
    e?:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setShowOverLay(true);
    setSending(true);
    e?.preventDefault();
    try {
      if (!(await performSignIn())) {
        return;
      }
    } catch (err) {
      setShowOverLay(false);
      (window.Rollbar ?? console).warn(
        `Sign-in Error: user ${email} failed to sign-in`
      );
      return;
    }

    try {
      await setBBAuthenticityToken();
    } catch (err) {
      setShowOverLay(false);
      (window.Rollbar ?? console).warn(
        `Sign-in Error: user ${email} failed to get bbk authenticity token`
      );
      return;
    }

    try {
      await checkCompanyCreation(email);
    } catch (err) {
      setShowOverLay(false);
      (window.Rollbar ?? console).warn(
        `Sign-in Error: an error occurred while user ${email} created company ${searchParams?.companyName} from cache`,
        err as LogArgument
      );
      window.location.pathname = redirectUri ?? '/company';
    }
  };

  const sendVerificationEmail = async (e?: React.MouseEvent<HTMLElement>) => {
    e?.preventDefault();
    // TODO add response check to this
    resendSignUpConfirmation$({ email }).unwrap();
    setSending(true);
    setError(undefined);
    setTimeout(() => {
      setSending(false);
      setError(undefined);
    }, 5000);
    return false;
  };

  const signUpSubmit = async ({
    email,
    firstName,
    lastName,
    password,
  }: LogInTypes.SignupFormValues) => {
    setSending(true);
    setError(undefined);
    try {
      const user = await signUp$({
        email,
        pw: password,
        firstName,
        lastName,
      }).unwrap();
      if (inviteQuery) {
        const username = user!.userSub;
        await assignInvitation$({ inviteCode: inviteQuery, username }).unwrap();
      }
      setSending(false);
      displaySuccess({
        title: 'Registration successful',
        message: 'Please check your email for a code to activate your account.',
      });
      setEmail(email);
      setPassword(password);
      history.push({
        pathname: LogInPaths.verify,
        search: encodeObjectForQuery({ email }),
      });
      return;
    } catch (e) {
      setSending(false);
      (window.Rollbar ?? console).log(
        'Failed in signUpSubmit for email',
        email,
        e as LogArgument
      );
      displayError(e as LogInTypes.Error);
    }
  };

  const updatePassword = async (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    setSending(true);

    try {
      await updatePassword$({
        email: emailQuery,
        password,
        updatePasswordCode: passwordVerifyCode,
      }).unwrap();
      setError(undefined);
      setEmail(emailQuery);
      setSending(false);
      setDisabled(true);
      displaySuccess(
        { title: 'Password has updated successfully...', message: '' },
        () => {
          signIn();
          return;
        }
      );
    } catch (e) {
      const err = e as LogInTypes.Error;
      displayError(err);
      setSending(false);
      setDisabled(false);
    }

    return false;
  };

  const mfaUpdatePassword = async (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    setSending(true);

    try {
      await mfaUpdatePassword$({
        password,
        cognitoUser: mfaUser!,
        userDetails,
      }).unwrap();
      setError(undefined);
      setEmail('');
      setPassword('');
      setSending(false);
      setDisabled(true);
      displaySuccess(
        { title: 'Password has updated successfully...', message: '' },
        () => {
          setSending(false);
          setDisabled(false);
          history.push({ pathname: LogInPaths.signIn });
          return;
        }
      );
    } catch (err) {
      const localError = err as LogInTypes.Error;
      displayError(localError);
      setSending(false);
      setDisabled(false);
    }

    return false;
  };

  const sendResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSending(true);
    setError(undefined);
    try {
      await sendUpdatePasswordCode$({ email }).unwrap();
      setSending(false);
      setError(undefined);
      history.push({
        pathname: LogInPaths.reset,
        search: encodeObjectForQuery({ email }),
      });
      return;
    } catch (e) {
      const err = e as LogInTypes.Error;
      displayError(err);
      setSending(false);
    }
    return false;
  };

  return (
    <div className="bbk-authRoot signIn">
      <div
        className={cs({
          forgot: useRouteMatch(LogInPaths.forgotPassword),
          reset: useRouteMatch(LogInPaths.reset),
          mfaReset: useRouteMatch(LogInPaths.reset),
          verify: useRouteMatch(LogInPaths.verify),
        })}
      />
      <Switch>
        <Route exact path="/company">
          <Redirect to={{ pathname: LogInPaths.signIn, search }} />
        </Route>
        <Route path={LogInPaths.signIn}>
          <>
            {!showOverLay && (
              <SignIn
                formValues={formValues}
                googleSignIn={googleSignIn}
                chargebeeSignIn={chargebeeSignIn}
                signIn={signIn}
                error={error}
                success={success}
                sending={sending}
              />
            )}
            {showOverLay && <PreparingOverlay message="Signing you in" />}
          </>
        </Route>
        <Route path={LogInPaths.reset}>
          <ResetPassword
            formValues={formValues}
            error={error}
            success={success}
            updatePassword={updatePassword}
            sending={sending}
            disabled={disabled}
          />
        </Route>
        <Route path={LogInPaths.mfaReset}>
          <ResetPassword
            formValues={{ setPassword }}
            error={error}
            success={success}
            updatePassword={mfaUpdatePassword}
            sending={sending}
            disabled={disabled}
          />
        </Route>
        <Route path={LogInPaths.forgotPassword}>
          <ForgotPassword
            formValues={formValues}
            error={error}
            success={success}
            sendResetPassword={sendResetPassword}
            sending={sending}
          />
        </Route>
        <Route path={LogInPaths.verify}>
          <Verify
            sending={sending}
            sendVerificationEmail={sendVerificationEmail}
            error={error}
            verify={verify}
          />
        </Route>
        <Route path={LogInPaths.signUp}>
          <SignUp
            error={error}
            googleSignIn={googleSignIn}
            signUp={signUpSubmit}
            sending={sending}
          />
        </Route>
        <Route path="/company/:companyName/app/:appId">
          <Redirect
            to={{
              pathname: LogInPaths.signIn,
              search: encodeObjectForQuery({
                redirectUri: history.location.pathname,
              }),
            }}
          />
        </Route>
        <Route path="/">
          <Redirect to={{ pathname: LogInPaths.signIn }} />
        </Route>
      </Switch>
    </div>
  );
};
