import type { ActiveCompany, ActiveUser } from '@bbkAdminRedux/app/reducers';

export const segmentCustomEvent = (event: string, properties?: object) =>
  window.analytics?.track(event, properties);

export const identifyWithFullStory = (userId: number, traits: object) =>
  window.FS?.identify(userId, traits);

export const identifyWithSegment = (userId: number, traits?: object) =>
  window.analytics?.identify(userId, traits);

export const identifyWithAutopilot = (email: string) => {
  if (!email) return;
  window.Autopilot?.run('associate', email);
};

export const identifyWithSegmentGroup = (
  user: ActiveUser,
  company: ActiveCompany
) => {
  if (!company) return;
  const {
    id: companyId,
    internal_name,
    name,
    company_type,
    domain,
    active,
    plan,
  } = company;
  window.analytics?.group(internal_name, {
    internal_name,
    name,
    company_type,
    domain,
    companyId,
    active,
    plan,
  });
};

export const identifyWithBeacon = (
  user: ActiveUser,
  company: ActiveCompany
) => {
  if (!company) return;
  window.Beacon?.('identify', {
    name: `${user.details.first_name} ${user.details.last_name}`,
    email: user.email,
    company: company.internal_name,
    plan: company.plan,
  });
};

export const segmentPageView = (referrerPath: string, currentPath: string) => {
  const url = window.location.href.split(/[?#]/)[0];
  const referrer = referrerPath ? url?.replace(currentPath, referrerPath) : '';
  const properties = {
    path: currentPath,
    referrer,
    url,
    search: '',
  };
  window.analytics?.page(properties);
};
