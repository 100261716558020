import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useUrl } from '@bbkAdminUtils/nav';
import TextInput from '@bbkAdminComponents/inputs/text/text-input';
import Icon from '@bbkAdminComponents/icons/icon-generator';
import { selectAppId } from '@bbkAdminRedux/app/selectors';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';
import DashModal from '@bbkAdminComponents/DashboardModal/dash-modal';
import ActionButton from '@bbkAdminComponents/buttons/action-button';
import Label from '@bbkAdminComponents/inputs/label/label';
import BottomActionBox from '@bbkAdminComponents/cards/bottom-action-box';
import CollapseFormContainer from '@bbkAdminComponents/form/collapse-form-container/collapse-form-container';
import CheckButton from '@bbkAdminComponents/buttons/CheckButton';
import type { IntegrationInput } from '@bbkAdminRedux/data-management/reducers';
import {
  IntegrationAuthType,
  IntegrationDataType,
} from '@bbkAdminRedux/data-management/reducers';
import FormSelect from '@bbkAdminComponents/form-select/form-select';
import { segmentCustomEvent } from '@bbkAdminUtils/analytics';
import FormZone from '@bbkAdminComponents/form/form-zone';
import FormSection from '@bbkAdminComponents/form/form-section';
import FormLayout from '@bbkAdminComponents/form/form-layout';
import FormCompoundField from '@bbkAdminComponents/form/form-compound-field';
import './integration-modal.scss';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { AuthorizedPaths } from '@bbkAdminRouter/router-paths';
import type { IntegrationAPIInput } from '@bbkAdminRedux/rtkq/integrations.slice';
import {
  integrationSlice,
  useRetrieveIntegrationFields,
} from '@bbkAdminRedux/rtkq/integrations.slice';
import { isDefined } from '@bbkAdminRedux/rtkq/rtkq-utils';
import Icons from '@bbkAdminUtils/icons';
import type { RTKAPIErrorResponse } from '@bbkAdminRedux/rtkq/rtkq-error-logger';
import { parseRTKAPIError } from '@bbkAdminRedux/rtkq/rtkq-error-logger';

const buildAuthorizeIntegrationURL = (
  integrationId: number,
  appId: string,
  redirectURL: string,
  options: { [name: string]: string } = {}
) => {
  const opts = Object.entries(options).map(
    ([name, value]) => `${name}=${value}`
  );
  const optsStr = opts.length ? `&${opts.join('&')}` : '';
  return `/admin/app/${appId}/integration/${integrationId}/authorize?redirectURL=${redirectURL}${optsStr}`;
};

type Props = {
  id: number;
  closeFunc: () => unknown;
  selectKey: (id: number, object: string) => unknown;
};
const IntegrationModal: React.FC<Props> = (props) => {
  const history = useHistory();
  const { closeFunc, id, selectKey } = props;
  const appKey = useSelector(selectAppId);
  const createUrl = useUrl();
  const manageFieldMappingsUrl = createUrl(
    AuthorizedPaths.settingsFieldMappings
  );
  const { data: integration, isLoading: isIntegrationsLoading } =
    integrationSlice.endpoints.getIntegrations.useQuery(
      { appKey },
      {
        selectFromResult: (res) => ({
          ...res,
          data: res.data?.find((i) => i.id === id),
        }),
      }
    );
  const { data: integrationFields, isLoading: isFieldsLoading } =
    integrationSlice.endpoints.getIntegrationFields.useQuery(
      integration
        ? { appKey, integrationDataType: integration.data_type }
        : skipToken
    );
  const retrieveIntegrationFields = useRetrieveIntegrationFields();

  const { data: fieldMappings = [], isLoading: isMappingLoading } =
    integrationSlice.endpoints.getFieldMappings.useQuery({ appKey });

  const [getThirdPartyIntegrationManageUrl] =
    integrationSlice.endpoints.getThirdPartyIntegrationManageUrl.useLazyQuery();

  const isLoading =
    isIntegrationsLoading || isFieldsLoading || isMappingLoading;

  const [putIntegration] =
    integrationSlice.endpoints.putIntegration.useMutation();
  const [deleteIntegration] =
    integrationSlice.endpoints.deleteIntegration.useMutation();

  const [values, setValues] = useState<Record<string, string | undefined>>({});

  if (!integration) return null;

  const integrationObjects = integrationFields?.objects;
  const integrationInputs = integration.required_input;
  const integrationRequiredInputs = integrationInputs?.filter(
    (i) => i.required
  );
  const isConnected = integration.connected;
  const hasInputs = integrationInputs?.length;
  const hasObjects = Object.keys(integrationObjects ?? {}).length !== 0;
  const isOAuthBased = integration.auth_type === IntegrationAuthType.oauth;

  const prepareCleanValues = () =>
    Object.fromEntries(
      Object.entries(values)
        .map(([key, value]) =>
          !!value?.trim() ? ([key, value] as const) : undefined
        )
        .filter(isDefined)
    );

  const authorizeIntegrationUrl = () => {
    const cleanValues = prepareCleanValues();
    return buildAuthorizeIntegrationURL(
      id,
      appKey,
      encodeURI(window.location.toString()),
      cleanValues
    );
  };

  const areInputsValid = (): boolean => {
    // if there are no required inputs, just return true
    if (!integrationRequiredInputs?.length) return true;

    const hasEmpty = integrationRequiredInputs
      ?.map((i) => values[i.name])
      .some((v) => !v?.trim());

    if (hasEmpty) return false;
    return true;
  };

  const validateInputsAndShowError = (e?: React.MouseEvent): boolean => {
    const isValid = areInputsValid();

    if (!isValid) {
      e?.preventDefault();
      BlackToastTrigger({
        content: 'Fill out required fields.',
      });
    }

    return isValid;
  };

  const manageIntegrationAPI = () => {
    getThirdPartyIntegrationManageUrl({
      integrationId: id,
      appKey,
      redirectUrl: encodeURI(window.location.toString()),
    })
      .unwrap()
      .then(({ url }) => {
        window.location.assign(url);
      })
      .catch((e: RTKAPIErrorResponse) => {
        const err = parseRTKAPIError(e);
        BlackToastTrigger({ content: `Error: ${err.message}` });
      });
  };

  const disconnectIntegrationAPI = () => {
    const args: IntegrationAPIInput = {
      appId: appKey,
      integrationId: `${id}`,
    };
    deleteIntegration(args)
      .unwrap()
      .then(() => {
        BlackToastTrigger({
          content: 'Integration disconnected successfully.',
        });
        retrieveIntegrationFields();
        closeFunc();
      })
      .catch((e: RTKAPIErrorResponse) => {
        const err = parseRTKAPIError(e);
        BlackToastTrigger({ content: `Error: ${err.message}` });
      });
  };

  const save = () => {
    segmentCustomEvent(
      'Settings - Integrations, Manage: clicked "Save & continue"',
      {
        inputs: values,
        integration: integration.display_name,
      }
    );
    if (!validateInputsAndShowError()) return;
    const cleanValues = prepareCleanValues();

    const args: IntegrationAPIInput = {
      appId: appKey,
      integrationId: `${id}`,
      inputs: cleanValues,
    };
    putIntegration(args)
      .unwrap()
      .then(() => {
        BlackToastTrigger({
          content: 'Integration connected successfully.',
        });
        // window.location.reload();
        closeFunc();
      })
      .catch((e: RTKAPIErrorResponse) => {
        const err = parseRTKAPIError(e);
        BlackToastTrigger({ content: `Error: ${err.message}` });
      });
  };

  const setInput = (e: {
    target: { name: string; value: string | undefined };
  }) => {
    const key = e.target.name;
    const value = e.target.value;
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const gotoManageUrl = () => history.push(manageFieldMappingsUrl);

  const renderInput = (input: IntegrationInput) => {
    const label = `${input.display} ${input.required ? '(required)' : ''}`;
    if (input.options.length > 0) {
      const list = input.options.map((opt) => ({
        name: opt,
        selected: values[input.name] === opt,
      }));
      return (
        <FormSelect
          key={input.name}
          label={label}
          placeholder="..."
          toggleItem={(item) =>
            setInput({ target: { name: input.name, value: item?.name } })
          }
          items={list}
        />
      );
    }
    return (
      <>
        <Label id={input.name} label={label} />
        <TextInput
          name={input.name}
          id={input.name}
          value={values[input.name] || ''}
          onChange={setInput}
          darkTheme
          placeholder="Start typing..."
        />
      </>
    );
  };

  const renderInputs = () => (
    <div>
      {integrationInputs?.map((input) => (
        <div key={input.name} className="tw-pb-4">
          {renderInput(input)}
          {isConnected && input.value && (
            <div className="tw-text-sm tw-text-black/50 tw-italic tw-pb-1">
              Current value (protected): {input.value}
            </div>
          )}
        </div>
      ))}
    </div>
  );

  const renderObjects = () => {
    const objsWithNames = (integrationFields?.objects ?? []).filter((o) =>
      isDefined(o.name)
    );
    return (
      <div>
        Data Management
        <FormLayout>
          <FormZone>
            {objsWithNames.length > 0 && (
              <FormSection
                title={
                  objsWithNames.length > 1 ? 'Mapped Objects' : 'Mapped Object'
                }
              >
                {objsWithNames.map((objectInfo) => (
                  <FormCompoundField key={objectInfo.name}>
                    <div className="integration-modal-field-row">
                      <div className="valueObject">{objectInfo.name}</div>
                      <div className="valueField">{objectInfo.external_id}</div>
                      {objectInfo.requires_external_id && (
                        <ActionButton
                          title={
                            objectInfo.external_id ? 'change key' : 'select key'
                          }
                          onClick={() => selectKey(id, objectInfo.name)}
                        />
                      )}
                    </div>
                  </FormCompoundField>
                ))}
              </FormSection>
            )}

            <FormSection title="Mapped fields">
              <FormCompoundField>
                <div className="integration-modal-field-row">
                  {fieldMappings.filter((x) => x.integration_id === id).length}
                  <ActionButton
                    title="manage"
                    onClick={() => {
                      segmentCustomEvent(
                        'Settings: Integrations, Manage - clicked "mapped fields ... manage"',
                        {
                          integration: integration.display_name,
                        }
                      );
                      gotoManageUrl();
                    }}
                  />
                </div>
              </FormCompoundField>
            </FormSection>
          </FormZone>
        </FormLayout>
      </div>
    );
  };

  const renderInputsForm = () => {
    if (!isConnected) return renderInputs();
    return (
      <CollapseFormContainer
        title="Change credentials"
        isOpened={false}
        onClick={(openState: boolean) => {
          segmentCustomEvent(
            'Settings: Integrations, Manage - clicked collapsable "Change credentials"',
            {
              openState,
              integration: integration.display_name,
            }
          );
        }}
      >
        {renderInputs()}
        <div className="tw-mt-2">
          {isOAuthBased ? (
            <CheckButton
              title="Reconnect"
              url={authorizeIntegrationUrl()}
              clicked={validateInputsAndShowError}
            />
          ) : (
            <CheckButton
              title="Update"
              clicked={() => {
                segmentCustomEvent(
                  'Settings - Integrations, Manage: clicked "update"',
                  {
                    inputs: values,
                    integration: integration.display_name,
                  }
                );
                save();
              }}
            />
          )}
        </div>
      </CollapseFormContainer>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="description tw-py-8">{Icons.loader} Loading...</div>
      );
    }
    return (
      <div className="description">
        <p>{(!isConnected || !hasObjects) && integration.description}</p>
        {isConnected && hasObjects && renderObjects()}
        {hasInputs && renderInputsForm()}
      </div>
    );
  };

  const hasMgmtUrl = [
    IntegrationDataType.STRIPE,
    IntegrationDataType.SALESFORCE,
    IntegrationDataType.SLACK,
    IntegrationDataType.INTERCOM,
  ].includes(integration.data_type);

  const renderFooter = () => {
    if (isConnected) {
      return (
        <BottomActionBox key={integration.id} buttons={[]}>
          {!integration.system && (
            <ButtonV2
              onClick={() => {
                segmentCustomEvent(
                  'Settings: Integrations, Manage - clicked Disconnect',
                  {
                    integration: integration.display_name,
                  }
                );
                disconnectIntegrationAPI();
              }}
              danger
            >
              Disconnect
            </ButtonV2>
          )}
          {hasMgmtUrl && (
            <ButtonV2
              onClick={() => {
                segmentCustomEvent(
                  'Settings: Integrations, Manage - clicked "Manage on..."',
                  {
                    integration: integration.display_name,
                  }
                );
                manageIntegrationAPI();
              }}
              secondary
            >
              Manage on {integration.display_name}
            </ButtonV2>
          )}
        </BottomActionBox>
      );
    }

    if (isOAuthBased) {
      return (
        <BottomActionBox
          key={integration.id}
          buttons={[
            {
              type: 'check',
              title: `Connect to ${integration.display_name}`,
              clicked: (e) => {
                segmentCustomEvent(
                  'Settings: Integrations, Manage - clicked "Connect to ..."',
                  {
                    integration: integration.display_name,
                  }
                );
                validateInputsAndShowError(e);
              },
              href: authorizeIntegrationUrl(),
            },
          ]}
        />
      );
    }

    if (hasInputs) {
      return (
        <BottomActionBox
          key={integration.id}
          buttons={[
            {
              type: 'check',
              title: 'Save & continue',
              clicked: save,
            },
          ]}
        />
      );
    }

    return undefined;
  };

  return (
    <DashModal
      show
      modalTitle={integration.display_name}
      modalIcon={
        <Icon
          name={
            integration.data_type.toLowerCase() as Lowercase<IntegrationDataType>
          }
        />
      }
      modalContent={renderContent()}
      modalClosed={closeFunc}
      classList={['dash-modal', 'integration-modal']}
      modalFooter={renderFooter()}
    />
  );
};

export default IntegrationModal;
