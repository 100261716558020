import React from 'react';
import './form-zone.scss';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';

const FormZone: ReactFCC = ({ children }) => (
  <div className="bbk-form-zone">
    {children}
    <div className="bbk-form-zone-separator" />
  </div>
);

export default FormZone;
