import React from 'react';
import RoundButton from '@bbkAdminComponents/buttons/round-button';
import { TourStoryProps } from './tour-story-index';

const ResponsiveImage = () => (
  <img src="/assets/images/tour/setup-a-test.png" />
);

const ProductTourStoryMasteringRetention = ({ onClick }: TourStoryProps) => (
  <div className="bbk-product-tour-story-template bbk-product-tour-story-setup-a-test">
    <article>
      <h1>Mastering Retention with Chargebee Retention</h1>
      {/*<figure>*/}
      {/*  <ResponsiveImage />*/}
      {/*</figure>*/}
      <p>
        That should be enough to get you started in the Chargebee Retention app!
        If you need more assistance getting started, feel free to check our our
        Getting Started Guide for more tips to get you up and running.
      </p>
      <p>
        <strong>Advanced Topic Links:</strong>
      </p>
      <div>
        <ul className="small">
          <li>
            <a
              href="https://www.chargebee.com/docs/retention/getting-started-guide-chargebee-retention.html"
              target="_blank"
              rel="noreferrer"
            >
              Getting Started Guide
            </a>
          </li>
          <li>
            <a
              href="https://www.chargebee.com/docs/retention/the-chargebee-retention-testing-methodology.html"
              target="_blank"
              rel="noreferrer"
            >
              Chargebee Retention Testing Methodology
            </a>
          </li>
          <li>
            <a
              href="https://www.chargebee.com/docs/retention/testing-in-chargebee-retention.html"
              target="_blank"
              rel="noreferrer"
            >
              Testing in Chargebee Retention
            </a>
          </li>
        </ul>
      </div>

      <footer>
        <p>
          <RoundButton onClick={onClick}>Close tour</RoundButton>
        </p>
      </footer>
    </article>
  </div>
);

export default {
  name: 'Mastering Retention with Chargebee Retention',
  key: 'bbk-pt-mastering-retention',
  story: <ProductTourStoryMasteringRetention />,
};
