import React, { Suspense, useEffect, useState } from 'react';
import '@bbkAdminStylesheets/tailwind.scss';
import { Route, Switch } from 'react-router-dom';
import { correctBaseURI } from '@bbkAdminUtils/getCurrentBaseUri';
import { useBbkFavicon } from '@bbkUtils/favicon';
import { preauthCompanySlice } from '@bbkAdminRedux/rtkq/preauth-company.slice';
import { usePollAndNotifyVersion } from '@bbkAdminRedux/rtkq/version.slice';
import { recordError } from '@bbkAdminUtils/recordError';
import { PreparingOverlay } from '@bbkAdminComponents/preparing-overlay/preparing-overlay';
import { LogInPaths } from '@bbkAdminComponents/log-in-flow/log-in-paths';
import { LogoutPage } from '@bbkAdminRouter/logout.page';
import { AuthRoot } from '@bbkAdminComponents/app-layout/auth-root';

// ATTN: Keep routes simple, no logic
// Only Simple <Route path={path} component={Component} />
const AdminAppRouter = () => (
  <Suspense fallback={<PreparingOverlay message="" />}>
    <Switch>
      <Route path={LogInPaths.logout} component={LogoutPage} />
      <Route component={AuthRoot} />
    </Switch>
  </Suspense>
);

export const AdminApp: React.FC = () => {
  usePollAndNotifyVersion();
  useBbkFavicon();

  const [isLoadingFinished, setIsLoadingFinished] = useState<boolean>();
  const [getClientConfig$] =
    preauthCompanySlice.endpoints.getClientConfig.useLazyQuery();

  useEffect(() => {
    getClientConfig$()
      .unwrap()
      .then((config) => correctBaseURI(config))
      .catch(recordError)
      .finally(() => setIsLoadingFinished(true));
  }, [getClientConfig$]);

  if (!isLoadingFinished) return null;

  return <AdminAppRouter />;
};
