import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { groupBy, isNull } from 'lodash';
import type {
  RevenueSource,
  RevenueSourceType,
} from '@bbkAdminRedux/app/reducers';
import {
  selectAppId,
  selectCompanyInternalName,
} from '@bbkAdminRedux/app/selectors';
import { toDictionary, useAppDispatch } from '@bbkAdminRedux/redux_utils';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import RadioButton from '@bbkAdminComponents/switches/radio-button/radio-button';
import GenericSelect from '@bbkAdminComponents/select/select';
import { useUrl } from '@bbkAdminUtils/nav';
import { IntegrationFieldTypeEnum } from '@bbkAdminRedux/data-management/reducers';
import { segmentCustomEvent } from '@bbkAdminUtils/analytics';
import { AuthorizedPaths } from '@bbkAdminRouter/router-paths';
import { integrationSlice } from '@bbkAdminRedux/rtkq/integrations.slice';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import { DescriptionTitle, RowStyles, SplitGroup } from '../lib';
import {
  parseRTKAPIError,
  RTKAPIErrorResponse,
} from '@bbkAdminRedux/rtkq/rtkq-error-logger';
import { useSelectCurrentApp } from '@bbkAdminRedux/rtkq/current-user.slice';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import { billingConfigSlice } from '@bbkAdminRedux/rtkq/billing-config.slice';
import { companyAppSettingsSlice } from '@bbkAdminRedux/rtkq/company-app-settings.slice';

const numericTypes = [
  IntegrationFieldTypeEnum.decimal,
  IntegrationFieldTypeEnum.integer,
];

export const RevenueConfiguration: ReactFCC<{
  closeRevenue: () => void;
  setRevenueConnected: (connected: boolean) => void;
}> = ({ closeRevenue, setRevenueConnected }) => {
  const dispatch = useAppDispatch();
  const appKey = useSelector(selectAppId);

  const [updateAppSettings] =
    companyAppSettingsSlice.endpoints.updateAppSettings.useMutation();

  const { data: integrations = [] } =
    integrationSlice.endpoints.getIntegrations.useQuery({ appKey });
  const integrationsMap = useMemo(
    () => toDictionary(integrations),
    [integrations]
  );
  const application = useSelectCurrentApp()!;
  const companyKey = useSelector(selectCompanyInternalName);
  const { revenue_source, revenue_source_type } = application;
  const [loading, setLoading] = useState(true);
  const [revenueSource, setRevenueSource] = useState<RevenueSource | null>(
    null
  );
  const [revenueSourceType, setRevenueSourceType] =
    useState<RevenueSourceType | null>(null);
  // const [customerFieldOptions, setCustomerFieldOptions] = useState([]);
  const createUrl = useUrl();
  const { data: billingConfig } =
    billingConfigSlice.endpoints.getBillingConfig.useQuery({
      app_key: appKey,
    });
  const billingAttributionInactive = !billingConfig?.attribution;

  const { data: fieldMappings = [] } =
    integrationSlice.endpoints.getFieldMappings.useQuery({ appKey });
  const fieldMappingsNumeric = useMemo(
    () =>
      Object.values(fieldMappings).filter((fm) =>
        numericTypes.includes(fm.type)
      ),
    [fieldMappings]
  );

  const keyedFieldMappings = groupBy(
    fieldMappingsNumeric,
    (x) => x.integration_id
  );
  const activeIntegrationKeys = Object.keys(keyedFieldMappings);
  const customerFieldOptions = activeIntegrationKeys.map((aik) => ({
    label: integrationsMap[aik]?.display_name,
    options: keyedFieldMappings[aik]?.map((fm) => ({
      // field: fm,
      label: fm.display_name,
      value: fm.name as RevenueSource,
    })),
  }));

  useEffect(() => {
    setRevenueSource(revenue_source);
    setRevenueSourceType(revenue_source_type);
    if (!isNull(revenue_source_type)) {
      setRevenueConnected(true);
    }
    setLoading(false);
  }, [application]);

  const isRevenueSourceTypeCustomerField =
    revenueSourceType === 'customer_field';

  const isRevenueSourceTypeNull = revenueSourceType === null;

  function resetRevenueData() {
    setRevenueSource(revenue_source);
    setRevenueSourceType(revenue_source_type);
  }

  async function saveRevenueData() {
    // verify values
    if (revenueSourceType && revenueSource === null) {
      BlackToastTrigger({ content: 'A revenue source value is required.' });
      return;
    }

    setLoading(true);
    try {
      // update values
      await updateAppSettings({
        companyInternalName: companyKey,
        appKey,
        revenueSource,
        revenueSourceType,
      }).unwrap();
    } catch (e: unknown) {
      const err = parseRTKAPIError(e as RTKAPIErrorResponse);
      BlackToastTrigger({
        content: `Error updating revenue settings: ${err.message}`,
      });
      return;
    } finally {
      setLoading(false);
    }
    // REFRESH app data
    dispatch(bbkApiSlice.util.invalidateTags(['CurrentApp']));
    // Explain to users
    BlackToastTrigger({ content: 'Revenue settings updated.' });
    // Close collapse
    closeRevenue();
  }

  return (
    <SplitGroup>
      <div>
        <DescriptionTitle css={[RowStyles.paragraphSpacer]}>
          Revenue source type
        </DescriptionTitle>
        <RadioButton
          checked={revenueSourceType === 'customer_field'}
          id="rstCustomerField"
          label="Customer field"
          handleChange={() => {
            const newRevenueSourceType =
              revenueSourceType === 'customer_field' ? null : 'customer_field';
            setRevenueSourceType(newRevenueSourceType);
            setRevenueSource(null);
          }}
          disabled={loading}
        />
        <RadioButton
          checked={revenueSourceType === 'billing'}
          id="rstBilling"
          label="Billing"
          handleChange={() => {
            const newRevenueSourceType =
              revenueSourceType === 'billing' ? null : 'billing';
            setRevenueSourceType(newRevenueSourceType);
            setRevenueSource(null);
          }}
          disabled={loading || billingAttributionInactive}
        />
        <DescriptionTitle css={[RowStyles.paragraphSpacer]}>
          Revenue source options
        </DescriptionTitle>
        <div css={[RowStyles.paragraphSpacer, { maxWidth: '350px' }]}>
          {customerFieldOptions.length > 0 ? (
            <GenericSelect
              options={customerFieldOptions}
              value={
                isRevenueSourceTypeCustomerField
                  ? { value: revenueSource, label: revenueSource }
                  : { value: null, label: null }
              }
              onChange={(e) => setRevenueSource(e.value)}
              placeholder="Select a mapped field"
              isDisabled={
                isRevenueSourceTypeNull || !isRevenueSourceTypeCustomerField
              }
            />
          ) : (
            <div>No fields available.</div>
          )}
        </div>
        {billingAttributionInactive && (
          <DescriptionTitle css={[RowStyles.paragraphSpacer]}>
            MRR and Subscription Price options are only available when
            Attribution has been properly configured.
          </DescriptionTitle>
        )}
        <RadioButton
          checked={revenueSource === 'mrr'}
          id="rsMrr"
          label="MRR"
          handleChange={() =>
            setRevenueSource(revenueSource === 'mrr' ? null : 'mrr')
          }
          disabled={
            loading ||
            isRevenueSourceTypeNull ||
            isRevenueSourceTypeCustomerField ||
            billingAttributionInactive
          }
        />
        <RadioButton
          checked={revenueSource === 'price'}
          id="rsPrice"
          label="Subscription price"
          handleChange={() =>
            setRevenueSource(revenueSource === 'price' ? null : 'price')
          }
          disabled={
            loading ||
            isRevenueSourceTypeNull ||
            isRevenueSourceTypeCustomerField ||
            billingAttributionInactive
          }
        />

        <div css={{ marginTop: '1rem' }}>
          <ButtonV2
            primary
            onClick={() => {
              segmentCustomEvent(
                'Settings - Setup, Configuration: clicked on Revenue in report: "Save and close"',
                {
                  revenueSource,
                  revenueSourceType,
                }
              );
              saveRevenueData();
            }}
            disabled={loading}
          >
            Save
          </ButtonV2>
          <ButtonV2
            transparent
            css={{ marginLeft: '1rem' }}
            onClick={resetRevenueData}
            disabled={loading}
          >
            reset
          </ButtonV2>
        </div>
      </div>
      <div>
        <DescriptionTitle> Revenue source type</DescriptionTitle>
        <div css={[RowStyles.paragraphSpacer]}>
          Whether to use a field passed in or to use one of the Chargebee
          Retention computed values.
        </div>
        <DescriptionTitle>Revenue source options</DescriptionTitle>
        <div css={[RowStyles.paragraphSpacer]}>
          Pick a mapped field or the computer MRR or Price value.{' '}
          <Link
            to={{
              pathname: createUrl(AuthorizedPaths.settingsFieldMappings),
            }}
          >
            Map additional fields
          </Link>{' '}
          if missing a necessary field.
        </div>
      </div>
    </SplitGroup>
  );
};
