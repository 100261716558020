import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Slide } from '@material-ui/core';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { useUrl } from '@bbkAdminUtils/nav';
import { useSelectCurrentApp } from '@bbkAdminRedux/rtkq/current-user.slice';
import { AuthorizedPaths } from '@bbkAdminRouter/router-paths';
import { usePlans } from '@bbkAdminUtils/plans';
import { selectCompanyInternalName } from '@bbkAdminRedux/app/selectors';
import { useSelector } from 'react-redux';
import { checkoutSlice } from '@bbkAdminRedux/rtkq/checkout.slice';
import { PLANS } from '@bbkAdminRedux/rtkq/checkout.plans';

export const GetStartedBanner: React.FC = () => {
  const createUrl = useUrl();
  const currentApp = useSelectCurrentApp();
  const companyKey = useSelector(selectCompanyInternalName);
  const isGetStartedEligible = currentApp?.get_started_status === 'ELIGIBLE';
  const inAdminApp = useRouteMatch('/company/:companyKey/app/:appKey');
  const showBanner = Boolean(inAdminApp) && isGetStartedEligible;
  const { isStarterFreePlan, isStarterPremiumPlan } = usePlans();
  const isStarterPlan = isStarterFreePlan || isStarterPremiumPlan;
  const BANNER_MESSAGE_STARTER =
    'Create and launch your first cancel page to start seeing data in your dashboard';
  const BANNER_MESSAGE_TRANSITION =
    'Add loss aversion cards and offers to your existing cancel page to retain more customers.';
  const BANNER_MESSAGE_DEFLECTION =
    'Create and launch your cancel page with offers to retain more customers.';

  const { data: subsHistory } =
    checkoutSlice.endpoints.getSubscriptionsHistory.useQuery(
      { companyKey },
      { skip: companyKey === '_' }
    );
  const wasOnStarterPreviously = subsHistory?.find((x) =>
    [PLANS.STARTER_PREMIUM, PLANS.STARTER_FREE].includes(x.subscription_plan)
  );

  const getBannerMessage = () => {
    if (isStarterPlan) {
      return BANNER_MESSAGE_STARTER;
    } else if (wasOnStarterPreviously) {
      return BANNER_MESSAGE_TRANSITION;
    } else {
      return BANNER_MESSAGE_DEFLECTION;
    }
  };

  return (
    <Slide direction="down" in={showBanner} mountOnEnter unmountOnExit>
      <div className="tw-flex tw-justify-between tw-px-4 tw-bg-cbrprimary-800 tw-text-white">
        <div className="tw-flex tw-flex-1 tw-justify-center tw-items-center tw-py-3">
          <div>
            <div>{getBannerMessage()}</div>
          </div>
          <Link to={createUrl(AuthorizedPaths.getStarted)}>
            <ButtonV2 secondary className="tw-ml-4">
              Get started
            </ButtonV2>
          </Link>
        </div>
      </div>
    </Slide>
  );
};
