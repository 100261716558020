import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import SuspenseLoader from '@bbkAdminComponents/loaders/suspense-loader';
import { RichTextEditor } from '@bbkAdminComponents/rich-text-editor';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';
import type { RTKAPIErrorResponse } from '@bbkAdminRedux/rtkq/rtkq-error-logger';
import { parseRTKAPIError } from '@bbkAdminRedux/rtkq/rtkq-error-logger';
import { IntegrationFieldTypeEnum } from '@bbkAdminRedux/data-management/reducers';
import {
  companyAppSettingsSlice,
  GlobalUrls,
} from '@bbkAdminRedux/rtkq/company-app-settings.slice';
import {
  FieldMapping,
  integrationSlice,
} from '@bbkAdminRedux/rtkq/integrations.slice';
import { selectAppId } from '@bbkAdminRedux/app/selectors';
import { recordError } from '@bbkAdminUtils/recordError';

type ComponentProps = {
  globalUrls: GlobalUrls;
  onChange: (fieldName: string, value: string) => void;
  suggestions: FieldMapping[];
  urlSuggestions: FieldMapping[];
  onSave: () => void;
  saveUrlLabel?: string;
  cancelUrlLabel?: string;
  isLoading: boolean;
};

export const GlobalUrlComponent: ReactFCC<ComponentProps> = ({
  globalUrls,
  onChange,
  suggestions,
  urlSuggestions,
  onSave,
  saveUrlLabel = 'Global Nevermind URL',
  cancelUrlLabel = 'Global Cancel URL',
  isLoading,
}) => {
  return (
    <>
      <div className="tw-text-sm tw-font-semibold tw-text-cbrneutral-700 tw-mt-5 tw-mb-2">
        {saveUrlLabel}
      </div>
      <RichTextEditor
        value={globalUrls.global_save_url ?? ''}
        onChange={(value) => onChange('global_save_url', value)}
        suggestions={suggestions}
        urlSuggestions={urlSuggestions}
        outputType="plaintext"
        enabledButtons={{
          link: false,
          bold: false,
          underline: false,
          italic: false,
          urlFields: true,
        }}
      />

      <div className="tw-text-sm tw-font-semibold tw-text-cbrneutral-700 tw-mt-5 tw-mb-2">
        {cancelUrlLabel}
      </div>
      <RichTextEditor
        value={globalUrls.global_cancel_url ?? ''}
        onChange={(value) => onChange('global_cancel_url', value)}
        suggestions={suggestions}
        urlSuggestions={urlSuggestions}
        outputType="plaintext"
        enabledButtons={{
          link: false,
          bold: false,
          underline: false,
          italic: false,
          urlFields: true,
        }}
      />

      <div className="tw-mt-4">
        <ButtonV2 primary onClick={onSave} disabled={isLoading}>
          Save &amp; close
        </ButtonV2>
      </div>
    </>
  );
};

export const UrlsConfig: ReactFCC<{ onSave?: CallableFunction }> = ({
  onSave,
}) => {
  const appKey = useSelector(selectAppId);
  const { data: fieldMappings, isFetching: isFetchingFieldMappings } =
    integrationSlice.endpoints.getFieldMappings.useQuery({ appKey });

  const [putGlobalUrls, { isLoading: isPuttingGlobalUrls }] =
    companyAppSettingsSlice.endpoints.putGlobalUrls.useMutation();

  const urlFields = useMemo(
    () =>
      fieldMappings?.filter((fm) => fm.type === IntegrationFieldTypeEnum.url) ??
      [],
    [fieldMappings]
  );
  const nonUrlFields = useMemo(
    () =>
      fieldMappings?.filter((fm) => fm.type !== IntegrationFieldTypeEnum.url) ??
      [],
    [fieldMappings]
  );

  const { data: globalUrls, isFetching: isFetchingGlobalUrls } =
    companyAppSettingsSlice.endpoints.getGlobalUrls.useQuery({ appKey });

  const [updatedGlobalUrls, setUpdatedGlobalUrls] = useState<GlobalUrls>();
  useEffect(() => {
    if (!globalUrls) return;
    setUpdatedGlobalUrls({
      global_save_url: globalUrls.global_save_url,
      global_cancel_url: globalUrls.global_cancel_url,
      global_fallback_url: globalUrls.global_fallback_url,
    });
  }, [globalUrls]);

  if (isFetchingFieldMappings || isFetchingGlobalUrls || isPuttingGlobalUrls) {
    return <SuspenseLoader message="Loading field mappings" />;
  }
  if (!updatedGlobalUrls) {
    return <>Could not load url config. Try again later.</>;
  }

  return (
    <GlobalUrlComponent
      globalUrls={updatedGlobalUrls}
      onChange={(fieldName, value) => {
        if (!updatedGlobalUrls) return;
        setUpdatedGlobalUrls({
          ...updatedGlobalUrls,
          [fieldName]: value,
        });
      }}
      suggestions={nonUrlFields}
      urlSuggestions={urlFields}
      onSave={() => {
        if (!updatedGlobalUrls) return;
        putGlobalUrls({
          appKey,
          globalUrls: updatedGlobalUrls,
        })
          .unwrap()
          .then(() => {
            onSave?.();
            BlackToastTrigger({
              content: `Global URLs updated for app ${appKey}`,
            });
          })
          .catch((e: RTKAPIErrorResponse) => {
            const err = parseRTKAPIError(e);
            const errorMessage = `Global URLs failed to update for app ${appKey}`;
            BlackToastTrigger({ content: errorMessage });
            recordError(`${errorMessage} ${err}`);
          });
      }}
      isLoading={
        isFetchingFieldMappings || isFetchingGlobalUrls || isPuttingGlobalUrls
      }
    />
  );
};
