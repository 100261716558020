import React from 'react';
import { LogInError } from '@bbkAdminComponents/log-in-flow/error';
import { LogInSuccess } from '@bbkAdminComponents/log-in-flow/success';
import { LogInTrouble } from '@bbkAdminComponents/log-in-flow/trouble';
import type * as LogInTypes from '@bbkAdminComponents/log-in-flow/log-in-types';
import { LogInPaths } from '@bbkAdminComponents/log-in-flow/log-in-paths';
import Label from '@bbkAdminComponents/inputs/label/label';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { CBRetentionLogo } from '@bbkAdminComponents/cbretention-logo';

type ForgotPasswordTypes = {
  error?: LogInTypes.Error;
  success?: LogInTypes.Success;
  formValues: LogInTypes.FormValues;
  sendResetPassword: LogInTypes.SendResetPassword;
  sending: LogInTypes.Sending;
};

export const ForgotPassword: React.FC<ForgotPasswordTypes> = ({
  error,
  success,
  sending,
  formValues,
  sendResetPassword,
}) => {
  const { email, setEmail } = formValues;
  return (
    <div>
      <form target="#" onSubmit={sendResetPassword}>
        <div className="content">
          <CBRetentionLogo className="tw-h-10 tw-mb-6" />
          <div className="tw-font-semibold tw-mb-4">Forgot your password?</div>

          {success && <LogInSuccess success={success} />}
          {error && <LogInError error={error} />}
          <div className="verify form-container">
            <div>
              <Label className="tw-text-sm tw-font-semibold">Email</Label>
              <input
                type="email"
                name="email"
                className="bbk-simple-text-input medium"
                autoComplete="on"
                placeholder="you@domain.com"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={sending}
              />
            </div>
            <ButtonV2 disabled={sending} type="submit" primary fullWidth>
              {sending ? 'Sending...' : 'Send reset email'}
            </ButtonV2>
          </div>
        </div>
      </form>
      <LogInTrouble link={{ path: LogInPaths.signIn, copy: 'Sign in' }} />
    </div>
  );
};
