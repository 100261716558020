import type { DefaultRootState } from 'react-redux';
import type {
  BaseQueryApi,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { CognitoUser, ISignUpResult } from 'amazon-cognito-identity-js';
import {
  createSelector,
  createSlice,
  isAnyOf,
  type PayloadAction,
} from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { preauthCompanySlice } from '@bbkAdminRedux/rtkq/preauth-company.slice';
import { BrightbackAuth } from '@bbkAdminUtils/brightback-auth';
import BrightbackSession from '@bbkAdminUtils/brightback-session';
import type {
  UsernameSignInArgs,
  UsernameSignUpArgs,
} from '@bbkAdminUtils/auth-username';
import type { GoogleSignInArgs } from '@bbkAdminUtils/auth-google';
import type {
  ChargebeeGetSignInUrlArgs,
  ChargebeeSignInArgs,
} from '@bbkAdminUtils/auth-chargebee';
import { getNowInSeconds } from '@bbkAdminUtils/utils';

export const isSignedIn = (): boolean =>
  Boolean(BrightbackSession.getAccessToken());

export enum SignInType {
  'USERNAME' = 'USERNAME',
  'GOOGLE' = 'GOOGLE',
  'CHARGEBEE' = 'CHARGEBEE',
}

export type SignInTokens = {
  type: SignInType;
  access_token: string;
  refresh_token: string;
  exp: number; // expires at
};

export type SignInArgs =
  | ({
      type: SignInType.USERNAME;
    } & UsernameSignInArgs)
  | ({
      type: SignInType.GOOGLE;
    } & GoogleSignInArgs)
  | ({
      type: SignInType.CHARGEBEE;
    } & ChargebeeSignInArgs);

export type GetSignInUrlArgs = {
  type: SignInType.CHARGEBEE;
} & ChargebeeGetSignInUrlArgs;

const createAuth = (api: BaseQueryApi): BrightbackAuth => {
  const state = api.getState() as DefaultRootState;
  const { data: config } =
    preauthCompanySlice.endpoints.getClientConfig.select()(state);

  if (!config) {
    throw new Error('Config is not loaded');
  }

  return new BrightbackAuth(config);
};

const resolveSigninTypeFromTokenType = (
  tokenType: 'cbidp' | 'google' | undefined
) => {
  return tokenType === 'google'
    ? SignInType.GOOGLE
    : tokenType === 'cbidp'
      ? SignInType.CHARGEBEE
      : SignInType.USERNAME;
};

export const brightbackAuthSlice = createApi({
  reducerPath: 'brightbackAuth-rktq',
  baseQuery: fetchBaseQuery(),
  tagTypes: [],
  endpoints: (build) => ({
    signUp: build.mutation<ISignUpResult, UsernameSignUpArgs>({
      queryFn: async (args, api) => {
        try {
          const auth = createAuth(api);
          const user = await auth.signUp(args);
          return { data: user };
        } catch (error) {
          return {
            error: error as FetchBaseQueryError,
          };
        }
      },
    }),
    openSignIn: build.mutation<void, GetSignInUrlArgs>({
      queryFn: async (args, api) => {
        try {
          const auth = createAuth(api);
          const url = await auth.getSignInUrl(args);
          // FIXME: side effect
          window.location.assign(url);
          // next line doesn't do anything, because window.location.assign redirects to different url
          // but to comply with rtkq contract we need to return it
          return { data: undefined };
        } catch (error) {
          return {
            error: error as FetchBaseQueryError,
          };
        }
      },
    }),

    signIn: build.mutation<SignInTokens, SignInArgs>({
      queryFn: async (args, api) => {
        try {
          const auth = createAuth(api);
          const tokens = await auth.signIn(args);
          return { data: tokens };
        } catch (error) {
          return {
            error: error as FetchBaseQueryError,
          };
        }
      },
    }),

    signOut: build.mutation<void, void>({
      queryFn: async (args, api) => {
        try {
          const refreshToken = BrightbackSession.getRefreshToken();
          const tokenType = BrightbackSession.getAccessTokenType();
          const type = resolveSigninTypeFromTokenType(tokenType);
          const auth = createAuth(api);

          await auth.signOut({
            refresh_token: refreshToken,
            type,
          });
          return { data: undefined };
        } catch (error) {
          return {
            error: error as FetchBaseQueryError,
          };
        }
      },
    }),
    resendSignUpConfirmation: build.mutation<void, { email: string }>({
      queryFn: async ({ email }, api) => {
        try {
          const auth = createAuth(api);
          await auth.resendSignUpConfirmation(email);
          return { data: undefined };
        } catch (error) {
          return {
            error: error as FetchBaseQueryError,
          };
        }
      },
    }),
    sendUpdatePasswordCode: build.mutation<void, { email: string }>({
      queryFn: async ({ email }, api) => {
        try {
          const auth = createAuth(api);
          await auth.sendUpdatePasswordCode(email);
          return { data: undefined };
        } catch (error) {
          return {
            error: error as FetchBaseQueryError,
          };
        }
      },
    }),
    updatePassword: build.mutation<
      void,
      { email: string; password: string; updatePasswordCode: string }
    >({
      queryFn: async ({ email, password, updatePasswordCode }, api) => {
        try {
          const auth = createAuth(api);
          await auth.updatePassword(email, password, updatePasswordCode);
          return { data: undefined };
        } catch (error) {
          return {
            error: error as FetchBaseQueryError,
          };
        }
      },
    }),
    mfaUpdatePassword: build.mutation<
      void,
      { password: string; cognitoUser: CognitoUser; userDetails: unknown }
    >({
      queryFn: async ({ password, cognitoUser, userDetails }, api) => {
        try {
          const auth = createAuth(api);
          await auth.mfaUpdatePassword(password, cognitoUser, userDetails);
          return { data: undefined };
        } catch (error) {
          return {
            error: error as FetchBaseQueryError,
          };
        }
      },
    }),
    confirmSignUp: build.mutation<
      void,
      { email: string; signUpConfirmationCode: string }
    >({
      queryFn: async ({ email, signUpConfirmationCode }, api) => {
        try {
          const auth = createAuth(api);
          await auth.confirmSignUp(email, signUpConfirmationCode);
          return { data: undefined };
        } catch (error) {
          return {
            error: error as FetchBaseQueryError,
          };
        }
      },
    }),
    doRefreshTokens: build.mutation<SignInTokens, void>({
      queryFn: async (args, api) => {
        try {
          const refreshToken = BrightbackSession.getRefreshToken();
          const accessTokenType = BrightbackSession.getAccessTokenType();
          const type = resolveSigninTypeFromTokenType(accessTokenType);
          if (!refreshToken) throw new Error('User is not signed in');
          const auth = createAuth(api);

          const tokens = await auth.refreshTokens({
            refresh_token: refreshToken,
            type,
          });
          return { data: tokens };
        } catch (error) {
          return {
            error: error as FetchBaseQueryError,
          };
        }
      },
    }),
  }),
});

type AuthState = {
  auth?: {
    type: SignInType;
    // access_token: string;
    // refresh_token: string;
    exp: number; // expires at (timestamp in seconds)
  };
};

export const authPersistedSlice = createSlice({
  name: 'authPersisted-rtk',
  initialState: {} as AuthState,
  reducers: {
    saveTokens: (
      draftState,
      { payload: tokens }: PayloadAction<SignInTokens>
    ) => {
      draftState.auth = omit(tokens, ['refresh_token', 'access_token']);
      const expiresIn = tokens.exp - getNowInSeconds();
      const accessTokenType =
        tokens.type === SignInType.GOOGLE
          ? 'google'
          : tokens.type === SignInType.CHARGEBEE
            ? 'cbidp'
            : null;
      BrightbackSession.setAccessToken(tokens.access_token, expiresIn);
      BrightbackSession.setRefreshToken(tokens.refresh_token);
      BrightbackSession.setAccessTokenType(accessTokenType);
    },
    clearTokens: (draftState) => {
      draftState.auth = undefined;
      BrightbackSession.setAccessToken(null, 0);
      BrightbackSession.setAccessTokenType(null);
      BrightbackSession.setRefreshToken(null);
      BrightbackSession.setSuppressSelectorPage(false);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        brightbackAuthSlice.endpoints.signIn.matchFulfilled,
        brightbackAuthSlice.endpoints.doRefreshTokens.matchFulfilled
      ),
      authPersistedSlice.caseReducers.saveTokens
    );
    builder.addMatcher(
      brightbackAuthSlice.endpoints.signOut.matchFulfilled,
      authPersistedSlice.caseReducers.clearTokens
    );
  },
});

export const selectAuth = (state: DefaultRootState) =>
  state[authPersistedSlice.name].auth;

export const selectAuthExpiresIn = createSelector(selectAuth, (subState) => {
  const exp = subState?.exp;
  let expiresIn = 0;
  if (exp) {
    expiresIn = exp - getNowInSeconds();
  }
  return expiresIn > 5 ? expiresIn - 5 : 0;
});
