/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import cs from 'classnames';
import '../simple-text/simple-text-input.scss';
import './password-input.scss';
import { Visibility, VisibilityOff } from '@material-ui/icons';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  innerRef?: React.Ref<HTMLInputElement>;
  classes?: string;
};

const PasswordInputComponent: React.FC<Props> = (props) => {
  const {
    innerRef,
    classes,
    placeholder = 'Start typing...',
    ...other
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={cs('bbk-password-input-container', classes)}>
      <div className="input-wrapper">
        <input
          className={cs('bbk-password-input bbk-simple-text-input', classes)}
          placeholder={placeholder}
          autoComplete="off"
          ref={innerRef}
          type={showPassword ? 'text' : 'password'}
          {...other}
        />
        <span
          className="eye-icon"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </span>
      </div>
    </div>
  );
};

export const PasswordInput = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => <PasswordInputComponent {...props} innerRef={ref} />
);
