import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { css } from '@emotion/react';
import Compressor from 'compressorjs';
import Slider from 'rc-slider';
import '@bbkAdminComponents/sliders/styles/rc-slider.scss';
import numeral from 'numeral';
import Toggle from '@bbkAdminComponents/switches/toggle/toggle';
import TippyToolTip from '@bbkAdminComponents/tooltips/tippy-tooltip';
import icons from '@bbkAdminUtils/icons';
import { isUndefined } from 'lodash';

type Props = {
  url?: string;
  onChange: (opts: { fileUrl?: string }) => void;
  uploadText?: string;
  enableClear?: boolean;
  enableCompression?: boolean;
};

const scaleImageTypes = ['image/png', 'image/jpeg'];
const imageTypes = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/svg+xml': ['.svg'],
};

const cssPreview = `
    padding: 10px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  `;

const FileInputPreview: React.FC<Props> = ({
  url,
  onChange,
  uploadText = 'Upload new image...',
  enableClear = true,
  enableCompression = true,
}) => {
  const [imageFile, setImageFile] = useState<File | undefined>();
  const [originalImage, setOriginalImageFile] = useState<File | undefined>();
  const [blobUrl, setBlobUrl] = useState<string | undefined>(url);
  const uploadButtonText = blobUrl ? 'Choose new file...' : uploadText;
  const [imageQuality, setImageQuality] = useState(0.6);
  const [utilizeCompression, setUtilizeCompression] = useState(true);

  useEffect(() => {
    if (!originalImage) return;
    compressFile(originalImage);
  }, [imageQuality]);

  const updateBlobUrl = (newBlobUrl: string | undefined) => {
    setBlobUrl(newBlobUrl);
    onChange({ fileUrl: newBlobUrl });
  };

  const compressFile = (fileItem: File) => {
    if (!fileItem) return;
    new Compressor(fileItem, {
      quality: imageQuality,
      convertSize: 1000, // very low to make sure it will resize
      success(result: File) {
        setImageFile(result);
        updateBlobUrl(URL.createObjectURL(result));
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const chooseFileToUse = (compressionActive: boolean) => {
    if (!originalImage) return;
    setImageFile(originalImage);
    if (compressionActive) {
      compressFile(originalImage);
    } else {
      updateBlobUrl(URL.createObjectURL(originalImage));
    }
  };

  const handleInputChange = async (files: File[]) => {
    const file = files[0];
    if (!file) return;
    if (isCorrectImageType(file) && enableCompression) {
      setUtilizeCompression(true);
      setOriginalImageFile(file);
      compressFile(file);
    } else {
      updateBlobUrl(URL.createObjectURL(file));
      setImageFile(undefined);
    }
  };

  const isCorrectImageType = (file: File) =>
    scaleImageTypes.includes(file?.type);

  return (
    <div className="bbk-file-input-preview">
      {blobUrl && (
        <div
          className="bbk-file-input-preview-image-container"
          css={css(cssPreview)}
        >
          <img src={blobUrl} css={{ maxWidth: '100%' }} alt="" />
        </div>
      )}
      <Dropzone onDrop={handleInputChange} accept={imageTypes} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <ButtonV2
            {...getRootProps()}
            transparent
            css={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <input {...getInputProps()} />
            {uploadButtonText}
          </ButtonV2>
        )}
      </Dropzone>
      {enableClear && blobUrl && (
        <ButtonV2
          onClick={() => updateBlobUrl(undefined)}
          css={{ marginLeft: '.5rem' }}
          transparent
        >
          clear
        </ButtonV2>
      )}
      {imageFile && isCorrectImageType(imageFile) && (
        <div>
          <div
            css={{ fontSize: 12, color: 'rgba(0,0,0,0.5)', marginBottom: 5 }}
          >
            <Toggle
              xs
              theme="theme-dark-green"
              checked={utilizeCompression}
              handleChange={() => {
                setUtilizeCompression(!utilizeCompression);
                chooseFileToUse(!utilizeCompression);
              }}
              label={
                <span>
                  Compress ({numeral(originalImage?.size).format('0b')} |{' '}
                  {numeral(imageFile?.size).format('0b')})
                  <TippyToolTip
                    content={
                      <p className="small">
                        PNGs will lose transprency if compressed.
                      </p>
                    }
                  >
                    <span className="ms-1">{icons.questionMark}</span>
                  </TippyToolTip>
                </span>
              }
            />
          </div>
          {utilizeCompression && (
            <div css={{ width: '75%' }}>
              <Slider
                min={0.1}
                max={1}
                step={0.1}
                value={imageQuality}
                onChange={(values) => {
                  const value = Array.isArray(values) ? values[0] : values;
                  if (!isUndefined(value)) {
                    setImageQuality(value);
                  }
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileInputPreview;
