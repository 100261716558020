import type { RestAbstractCard } from '@bbkAdminUtils/api-client/rest-cards-types';
import type { RestModalForwards } from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import type { PlacementLossAversionPosition } from '@bbkAdminRedux/rtkq/placements.utils';

export enum RestLossAversionCardTemplates {
  simple_chip = 'simple_chip',
  la_twitter_card = 'la_twitter_card',
  la_one_up_image = 'la_one_up_image',
  simple_list = 'simple_list',
  simple_blurb = 'simple_blurb',
  loss_simple_link = 'loss_simple_link',
  simple_team = 'simple_team',
  simple_chip_images = 'simple_chip_images',
}

export type RestRuntimeLossAversionOffer = {
  max_redemptions_reached?: boolean;
};

export type RestAbstractLossAversion = RestAbstractCard & {
  position: PlacementLossAversionPosition;
  requires: {
    disableOnMobile?: boolean;
  };
};

export type RestOneUpLaCard = RestAbstractLossAversion &
  RestRuntimeLossAversionOffer & {
    template: RestLossAversionCardTemplates.la_one_up_image;
    requires: {
      title: string;
      image: string;
      imageStretch?: boolean;
      forwards: RestModalForwards;
    };
  };

export type RestSimpleLinkLaCard = RestAbstractLossAversion &
  RestRuntimeLossAversionOffer & {
    template: RestLossAversionCardTemplates.loss_simple_link;
    requires: {
      title: string;
      image: string;
      copy: string;
      vertical?: boolean;
      help_text: string;
      forwards: RestModalForwards;
      other_actions?: RestModalForwards[];
    };
  };

export type RestSimpleChipLaCard = RestAbstractLossAversion &
  RestRuntimeLossAversionOffer & {
    template: RestLossAversionCardTemplates.simple_chip;
    requires: {
      title: string;
      headline: string;
      activities: {
        icon: string;
        image?: string;
        volume: string;
        unit: string;
        text: string;
        value: string;
      }[];
      forwards?: RestModalForwards;
      other_actions?: RestModalForwards[];
    };
  };

export type RestTwitterLaCard = RestAbstractLossAversion & {
  template: RestLossAversionCardTemplates.la_twitter_card;
  requires: {
    title: string;
    profile: {
      pic: string;
      name: string;
      handle: string;
    };
    tweet: {
      content: string;
      link: string;
      linkText: string;
      date: string;
    };
  };
};

export type RestSimpleListLaCard = RestAbstractLossAversion &
  RestRuntimeLossAversionOffer & {
    template: RestLossAversionCardTemplates.simple_list;
    requires: {
      title: string;
      items: string[];
      cols?: number;
      image?: string;
      bullet_icon?: string;
      bullet_icon_picker?: string;
      forwards?: RestModalForwards;
      other_actions?: RestModalForwards[];
    };
  };

export type RestSimpleBlurbLaCard = RestAbstractLossAversion & {
  template: RestLossAversionCardTemplates.simple_blurb;
  requires: {
    title: string;
    quote: string;
    author: string;
    position: string;
    avatar: string;
    vertical?: boolean;
  };
};

export type RestSimpleTeamLaCard = RestAbstractLossAversion & {
  template: RestLossAversionCardTemplates.simple_team;
  requires: {
    title: string;
    headline: string;
    teamMembers: {
      avatar: string;
      name: string;
      avatarText?: string;
    }[];
  };
};

export type RestSimpleChipImagesLaCard = RestAbstractLossAversion & {
  template: RestLossAversionCardTemplates.simple_chip_images;
  requires: {
    title: string;
    headline: string;
    pics: {
      href: string;
      label: string;
    }[];
  };
};

export type RestLossAversionWithOffer =
  | RestOneUpLaCard
  | RestSimpleLinkLaCard
  | RestSimpleListLaCard;

export type RestLossAversionWithContent =
  | RestSimpleChipLaCard
  | RestTwitterLaCard
  | RestSimpleListLaCard
  | RestSimpleBlurbLaCard
  | RestSimpleTeamLaCard
  | RestSimpleChipImagesLaCard;

export type RestLossAversionCard =
  | RestLossAversionWithOffer
  | RestLossAversionWithContent;
