import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { trim } from 'lodash';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import type { CSSObject } from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query/react';
import isEmail from 'validator/lib/isEmail';
import {
  ImportantMessage,
  InputError,
  InputLabel,
} from '@bbkAdminComponents/form-components';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { TrialSignupSteps } from '@bbkAdminComponents/trial-signup/TrialSignupSteps';
import { TrialSignupLogo } from '@bbkAdminComponents/trial-signup/TrialSignupLogo';
import {
  domainValidator,
  extractDomain,
  MARKETO_UTM_FIELDS_QUERY_PARAMS_TO_COOKIE,
  MarketoUtmFieldsType,
  preauthCompanySlice,
} from '@bbkAdminRedux/rtkq/preauth-company.slice';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';
import { PreparingOverlay } from '@bbkAdminComponents/preparing-overlay/preparing-overlay';
import BrightbackSession from '@bbkAdminUtils/brightback-session';
import { SignupPaths } from '@bbkAdminRouter/signup-paths';
import img from '@bbkAdminComponents/trial-signup/assets/signup-page1-cb-retention.png';
import stephano from '@bbkAdminComponents/trial-signup/assets/stephano.png';
import { useSearchParams } from '@bbkAdminUtils/useSearchParams';
import { encodeObjectForQuery } from '@bbkAdminRedux/rtkq/rtkq-utils';

const TrialSignupPage1Testimonial: React.FC = () => (
  <div>
    <div>
      <div className="tw-text-3xl">
        <strong>
          &quot;With Chargebee we were able to reduce churn by 20% nearly
          instantly after deployment.
        </strong>{' '}
        When compared to building an internal solution... Chargebee Retention
        was the obvious choice.&quot;
      </div>
      <div className="tw-flex tw-mt-6 tw-mb-10 tw-text-sm">
        <img
          className="tw-mr-5"
          srcSet={`${stephano} 2x`}
          alt="Stefano Scalia, Head of Growth pliability"
        />
        <div>
          - Stefano Scalia, Head of Growth
          <br />
          <strong>pliability</strong>
        </div>
      </div>
    </div>
    <img srcSet={`${img} 2x`} className="tw-w-11/12" alt="Testimonials" />
  </div>
);

export const signupStyles: Record<string, SerializedStyles | CSSObject> = {
  inputLabelStyles: css`
    margin: 0.5rem 0 0 0;
    color: #000;
  `,
  selectLabelStyles: css`
    margin: 1rem 0 0.5rem 0;
    color: #000;
  `,
};

type FormData = {
  companyName: string;
  domain: string;
};

type FormTouched = Partial<{
  companyName: boolean;
  domain: boolean;
}>;

type FormError = Partial<{
  companyName: string;
  domain: string;
}>;

export const TrialSignupPage: React.FC = () => {
  const history = useHistory();
  const searchParams = useSearchParams<
    {
      cb_email: string;
    } & MarketoUtmFieldsType
  >();
  const opCbToken = searchParams.cb_email || '';
  const cbToken = isEmail(opCbToken) ? opCbToken : undefined;
  const {
    data: chargebeeCachedData,
    isFetching: isFetchingCBData,
    isError,
  } = preauthCompanySlice.endpoints.fetchCachedDataFromChargebee.useQuery(
    cbToken ? { token: cbToken } : skipToken
  );

  const [showOverLay, setShowOverLay] = useState<boolean>(!!cbToken);

  const gotoNonCbSignup = () => {
    history.push({
      pathname: SignupPaths.trialSignup,
    });
  };

  const gotoSignupPage2 = (s: string) =>
    history.push({
      pathname: SignupPaths.trialSignup2,
      search: s,
    });

  useEffect(() => {
    if (isError) {
      setShowOverLay(false);
      gotoNonCbSignup();
    }
    if (chargebeeCachedData) {
      // on cb path
      if (chargebeeCachedData.company_exists_for_merchant_id) {
        // company already exists with merchantID
        gotoSignupPage2(
          encodeObjectForQuery({
            ...searchParams,
            companyName: chargebeeCachedData.merchant_name,
            domain: extractDomain(chargebeeCachedData.domain),
          })
        );
      } else {
        setShowOverLay(false);
      }
    }
  }, [isFetchingCBData, isError]);

  const [formTouched, setFormTouched] = useState<FormTouched>({});
  const [formData, setFormData] = useState<FormData>({
    companyName: '',
    domain: '',
  });
  const [formError, setFormError] = useState<FormError>({});

  const validateCompanyName = (): string => {
    const { companyName } = formData;

    let companyNameError = '';
    if (formTouched.companyName) {
      if (!companyName || trim(companyName).length === 0) {
        companyNameError = 'Please enter your company name.';
      } else if (companyName.length < 3) {
        companyNameError = 'Company name should be at least 3 characters long.';
      }
    }
    return companyNameError;
  };

  const autoValidateFields = (): boolean => {
    const companyNameError = validateCompanyName();

    setFormError({
      companyName: companyNameError,
      domain: '',
    });

    return !companyNameError;
  };

  const validateFields = (): boolean => {
    /** ABK: Replaced Formik+Yup validations with manual setup only for step 1 to
     * accommodate Chargebee integration since enableReinitialize property of Formik
     * was not working for me. */
    const companyNameError = validateCompanyName();
    const { domain } = formData;
    const domainError = domainValidator(domain);

    setFormError({
      companyName: companyNameError,
      domain: domainError,
    });

    return !companyNameError && !domainError;
  };

  useEffect(() => {
    autoValidateFields();
  }, [formData]);

  useEffect(() => {
    if (!chargebeeCachedData) return;
    setFormTouched({
      companyName: true,
      domain: true,
    });
    setFormData({
      companyName: chargebeeCachedData.merchant_name,
      domain: extractDomain(chargebeeCachedData.domain) ?? '',
    });
  }, [chargebeeCachedData]);

  const onSubmit = () => {
    if (!validateFields()) {
      BlackToastTrigger({
        content: 'There are unresolved validation errors on this page.',
      });
      return;
    }
    BrightbackSession.setActiveCompany(undefined);
    BrightbackSession.setActiveApp(undefined);
    gotoSignupPage2(
      encodeObjectForQuery({
        ...searchParams,
        ...formData,
      })
    );
    const cookieExpiration = new Date(Date.now() + 30 * 60 * 1000); // 30minutes

    Object.keys(MARKETO_UTM_FIELDS_QUERY_PARAMS_TO_COOKIE).map((key) => {
      const cookieName =
        MARKETO_UTM_FIELDS_QUERY_PARAMS_TO_COOKIE[
          key as keyof typeof MARKETO_UTM_FIELDS_QUERY_PARAMS_TO_COOKIE
        ].cookieName;
      const utmField = searchParams[key as keyof typeof searchParams];
      if (utmField) {
        document.cookie = `${cookieName}=${encodeURIComponent(utmField)}; expires=${cookieExpiration.toUTCString()}; path=/;`;
      }
    });
  };

  return (
    <div className="trial-signup-page">
      <div className="left-col">
        <div>
          <div className="tw-text-center">
            <TrialSignupLogo />
            <div className="page-title">Create your company</div>
          </div>
          <InputLabel css={signupStyles.inputLabelStyles}>
            Company <ImportantMessage>*</ImportantMessage>
          </InputLabel>
          <input
            name="companyName"
            type="text"
            value={formData.companyName}
            onChange={(e) => {
              setFormTouched((prev) => ({ ...prev, companyName: true }));
              setFormData((prev) => ({ ...prev, companyName: e.target.value }));
            }}
            className="bbk-simple-text-input w-100"
          />
          <InputError>{formError.companyName}</InputError>

          <InputLabel css={signupStyles.inputLabelStyles}>
            Domain <ImportantMessage>*</ImportantMessage>
          </InputLabel>
          <input
            name="domain"
            placeholder="www.yourcompany.com"
            type="text"
            value={formData.domain}
            className="bbk-simple-text-input w-100"
            onChange={(e) => {
              if (!formTouched.domain) {
                setFormTouched((prev) => ({ ...prev, domain: true }));
              }
              setFormData((prev) => ({ ...prev, domain: e.target.value }));
            }}
          />
          <InputError>{formError.domain}</InputError>

          <ButtonV2
            primary
            fullWidth
            css={css`
              margin: 0.5rem 0 1rem 0;
            `}
            onClick={onSubmit}
          >
            Next
          </ButtonV2>
        </div>

        <TrialSignupSteps step={1} maxSteps={cbToken ? 2 : 3} />
      </div>
      <div className="right-col tw-text-black">
        <TrialSignupPage1Testimonial />
      </div>
      {showOverLay && <PreparingOverlay />}
    </div>
  );
};
