/* eslint-disable camelcase,default-param-last */
import { matchPath } from 'react-router-dom';
import { produce } from 'immer';
import type { DateString } from '@bbkAdminRedux/rtkq/current-user.slice';
import type { AppAuthType } from '@bbkAdminRedux/rtkq/apiKeys.slice';
import type {
  AppBusinessModel,
  AppBusinessVertical,
} from '@bbkAdminRedux/rtkq/reports/benchmark.selectors';
import type { PLANS } from '@bbkAdminRedux/rtkq/checkout.plans';
import type { RestFunnel } from '@bbkAdminRedux/rtkq/funnels.slice';
import type {
  ApplicationType,
  CompanyType,
} from '@bbkInternalComponents/companies/company/company';
import { reducer } from '../redux_utils';
import * as Action from './actions';

export type RevenueSourceType = 'billing' | 'customer_field' | null;
export type RevenueSource = 'mrr' | 'price' | null;

export type ActiveApp = {
  show_demo_data?: boolean;
  get_started_status: 'COMPLETED' | 'ELIGIBLE' | 'INCOMPLETE';
  id: number;
  company_id: number;
  application_type: ApplicationType;
  funnel_selection_strategy: 'TREE' | 'RANDOM' | 'PERCENT_SPLIT';
  active: boolean;
  cb_product_catalog_version?: string;
  display_name: string;
  full_display_name: string;
  save_interval: number;
  cancel_unique_period: number;
  save_unique_period: number;
  user_session_interval: number;
  lco_accept_cooldown: string;
  lco_dismiss_cooldown: string;
  created_at: string;
  updated_at: string;
  launched_at: string;
  csp_domains: string[];
  cb_site_domain?: string;
  internal_name: string;
  encoded_id: string;
  revenue_source: RevenueSource;
  revenue_source_type: RevenueSourceType;
  funnels: RestFunnel[];
  first_non_test_session_date: string | null; // Date string | null
  api_auth_type: AppAuthType;
  business_type?: AppBusinessModel;
  industry_type?: AppBusinessVertical;
  company?: ActiveCompany;
};

export type FunnelRoutingNode = {
  routing_tree: string;
  comment: string;
  brand_id?: string;
};

export enum PricingBookVersions {
  v1 = 'v1',
  v2 = 'v2',
}

export enum UserRoles {
  billing = 'billing',
}

export type ActiveCompany = {
  id: number;
  internal_name: string;
  name: string;
  company_type: CompanyType;
  domain: string;
  details?: {
    image_url?: string;
  };
  vanity_domain?: string;
  vanity_domain_active: boolean;
  plan?: PLANS;
  trial?: boolean;
  trial_end_date?: DateString;
  active: boolean;
  created_at: DateString;
  updated_at: DateString;
  first_payment_date?: DateString;
  churn_date?: DateString;
  owner_user_id: string;
  external_billing_id?: string;
  applications: ActiveApp[];
  price_book_version: PricingBookVersions;
  cb_merchant_id: string;
  multi_product_customer_id: string;
  user_roles: UserRoles[];
};
export type CompanyFilterValues = {
  types?: string[];
  plans?: string[];
  text?: string;
  status?: string;
  page_no?: number;
  page_size?: number;
};
export type ActiveUser = {
  id: number;
  email: string;
  details: {
    image_url: string;
    language: string;
    display_name: string;
    first_name: string;
    last_name: string;
  };
  companies: ActiveCompany[];
  created_at: DateString;
  updated_at: DateString;
  authenticity_token: string;
  selected_company?: string;
  active: boolean;
  groups: string[];
};

export type LifecycleOption = boolean | 'dismissed' | 'not_done' | 'done';
export type LifecycleStatus = {
  id: number;
  application_id: number;
  wizard_visible: LifecycleOption;
  progress_visible: LifecycleOption;
  control_page_status: LifecycleOption;
  billing_integration_status: LifecycleOption;
  offer_creation_status: LifecycleOption;
  cancel_mgmt_status: LifecycleOption;
  setup_alert_status: LifecycleOption;
  non_test_session_status: LifecycleOption;
  welcome_status: LifecycleOption;
  branding_status: LifecycleOption;
  reasons_status: LifecycleOption;
  test_page_status: LifecycleOption;
  targeting_test_status: LifecycleOption;
  benchmark_status: LifecycleOption;
};

type State = {
  activeCompany: ActiveCompany;
  activeApp: ActiveApp;
  activeUser: ActiveUser;

  loadedNecessaryData?: boolean;
};

const initialState: State = {
  activeCompany: undefined!,
  activeUser: undefined!,
  activeApp: undefined!,
};

export const determineSelectedNavItem = (fullPath: string) => {
  const appMatched = matchPath<{ '0'?: string }>(fullPath, {
    path: '/company/:companyKey/app/:appKey/*',
  });
  if (appMatched) {
    const subPath = appMatched.params['0'] ?? 'dashboard';
    return `/${subPath}`;
  }
  return fullPath;
};

export const determineLeftNavAppearance = (fullPath: string) => {
  const appMatched = matchPath(fullPath, {
    path: '/company/:companyKey/app/:appKey',
  });
  const internalMatched = matchPath(fullPath, { path: '/internal' });
  if (appMatched) {
    if (fullPath.endsWith('/preview') || fullPath.endsWith('/preview-full')) {
      return false;
    }
  }

  return Boolean(appMatched || internalMatched);
};

export default reducer(initialState, {
  [Action.SET_ACTIVE_COMPANY]: (
    state,
    payload: { activeCompany: ActiveCompany }
  ) =>
    produce(state, (draft) => {
      draft.activeCompany = payload.activeCompany;
    }),
  [Action.SET_ACTIVE_APP]: (state, payload: { activeApp: ActiveApp }) =>
    produce(state, (draft) => {
      draft.activeApp = payload.activeApp;
    }),
  [Action.DATA_LOADED]: (state) =>
    produce(state, (draft) => {
      draft.loadedNecessaryData = true;
    }),
});
