import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import cs from 'classnames';
import { skipToken } from '@reduxjs/toolkit/query';
import { AuthorizedPaths } from '@bbkAdminRouter/router-paths';
import {
  DescriptionTitle,
  InputGroup,
  InputGroupItemDesc,
  RowStyles,
} from '@bbkAdminComponents/settings/lib';
import Toggle from '@bbkAdminComponents/switches/toggle/toggle';
import { segmentCustomEvent } from '@bbkAdminUtils/analytics';
import { IntegrationDataType } from '@bbkAdminRedux/data-management/reducers';
import Label from '@bbkAdminComponents/inputs/label/label';
import {
  ChargebeeForm,
  RecurlyForm,
  StripeForm,
} from '@bbkAdminComponents/settings/cancel-config';
import type {
  BillingConfig,
  CancelConfig,
  ChargebeeBillingConfig,
  RechargeBillingConfig,
  RecurlyBillingConfig,
  StripeBillingConfig,
} from '@bbkAdminRedux/rtkq/billing-config.slice';
import { billingConfigSlice } from '@bbkAdminRedux/rtkq/billing-config.slice';
import { RechargeForm } from '@bbkAdminComponents/settings/cancel-config/recharge';
import {
  ConfirmationDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@bbkAdminComponents/editor-page-overlay/dialog';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';
import type { RTKAPIErrorResponse } from '@bbkAdminRedux/rtkq/rtkq-error-logger';
import { parseRTKAPIError } from '@bbkAdminRedux/rtkq/rtkq-error-logger';
import { FeatureFlagsE, useFeatureFlags } from '@bbkAdminUtils/feature-flags';
import {
  integrationSlice,
  useSelectBillingIntegration,
} from '@bbkAdminRedux/rtkq/integrations.slice';
import { selectAppId } from '@bbkAdminRedux/app/selectors';
import { useUrl } from '@bbkAdminUtils/nav';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import tailwindColors from '@bbkRoot/tailwind.colors';
import { usePlans } from '@bbkAdminUtils/plans';
import { useStrings } from '@bbkAdminUtils/strings';

const cancelConfigInitialState = (
  integrationDataType: IntegrationDataType
): CancelConfig | undefined => {
  switch (integrationDataType) {
    case IntegrationDataType.STRIPE:
    case IntegrationDataType.STRIPE_API_KEY:
      return {
        type: 'stripe',
        integration_data_type: IntegrationDataType.STRIPE,
        invoice_now: false,
        prorate: false,
      };
    case IntegrationDataType.RECHARGE:
      return {
        type: 'recharge',
        integration_data_type: IntegrationDataType.RECHARGE,
        end_of_term: true,
      };
    case IntegrationDataType.RECURLY:
      return {
        type: 'recurly',
        integration_data_type: IntegrationDataType.RECURLY,
        cancel_type: 'cancel_subscription',
        charge: true,
        refund_type: 'none',
        time_frame: 'term_end',
      };
    case IntegrationDataType.CHARGEBEE:
      return {
        type: 'chargebee',
        integration_data_type: IntegrationDataType.CHARGEBEE,
        account_receivables_handling: 'schedule_payment_collection',
        credit_option: 'prorate',
        charges_option: 'invoice',
        end_of_term: true,
      };
    default:
      return undefined;
  }
};

export const BillingSettings: ReactFCC<{ onSave?: CallableFunction }> = ({
  onSave,
}) => {
  const createUrl = useUrl();
  const appKey = useSelector(selectAppId);

  const strings = useStrings();

  const { isFetching: isFetchingIntegrations } =
    integrationSlice.endpoints.getIntegrations.useQuery({ appKey });
  const billingIntegration = useSelectBillingIntegration({ appKey });
  const integrationsLoaded = !isFetchingIntegrations;

  const showBypassSettings = useFeatureFlags(FeatureFlagsE.BYPASS);

  const isChargebeeIntegration =
    billingIntegration?.data_type === IntegrationDataType.CHARGEBEE;
  const isRechargeIntegration =
    billingIntegration?.data_type === IntegrationDataType.RECHARGE;

  const { data: billingConfig, isFetching: isFetchingBillingConfig } =
    billingConfigSlice.endpoints.getBillingConfig.useQuery(
      billingIntegration ? { app_key: appKey } : skipToken
    );
  const [putBillingConfig, { isLoading: isPuttingBillingConfig }] =
    billingConfigSlice.endpoints.putBillingConfig.useMutation();
  const [propagateConfigs, { isLoading: isPropagatingConfigs }] =
    billingConfigSlice.endpoints.propagateConfigs.useMutation();

  const [updatedConfig, setUpdatedConfig] = useState<BillingConfig>();
  const [updatedCancelConfig, setUpdatedCancelConfig] =
    useState<CancelConfig>();
  useEffect(() => {
    if (billingConfig && billingIntegration) {
      const cancelConfig =
        billingConfig.cancel_config ??
        cancelConfigInitialState(billingIntegration.data_type);
      setUpdatedCancelConfig(cancelConfig);
      setUpdatedConfig({ ...billingConfig, cancel_config: cancelConfig });
    }
  }, [billingConfig]);
  const [applyAllOn, setApplyAllOn] = useState<boolean>(false);
  const [applyAllOff, setApplyAllOff] = useState<boolean>(false);
  const { isStarterFreePlan, isStarterPremiumPlan } = usePlans();

  const isStarterPlan = isStarterFreePlan || isStarterPremiumPlan;

  const isConfigLoading = () =>
    isFetchingIntegrations ||
    isFetchingBillingConfig ||
    isPuttingBillingConfig ||
    isPropagatingConfigs;

  const handleConfigReset = () => {
    if (!billingIntegration) return;
    setUpdatedConfig(billingConfig);
    setUpdatedCancelConfig(
      billingConfig?.cancel_config ??
        cancelConfigInitialState(billingIntegration.data_type)
    );
  };

  const render = () => {
    if (!billingIntegration && !integrationsLoaded) {
      return <div>Determining billing integration...</div>;
    }

    if (isConfigLoading()) {
      return <div>Loading billing integration...</div>;
    }

    // no billing integration, but integrations are all loaded
    // billing integration exists, but not connected
    if (
      (!billingIntegration && integrationsLoaded) ||
      !billingIntegration?.connected
    ) {
      return (
        <div>
          Visit{' '}
          <Link
            to={{
              pathname: createUrl(AuthorizedPaths.settingsIntegrations),
            }}
            css={{
              color: tailwindColors.cbrprimary['600'],
              '&:hover': { color: tailwindColors.cbrprimary['600'] },
            }}
          >
            integrations
          </Link>{' '}
          to connect a billing system.
        </div>
      );
    }

    if (!updatedConfig) {
      return (
        <div>
          Billing integration failed to load, or it is not supported currently.
        </div>
      );
    }

    return (
      <>
        {updatedConfig && (
          <div
            css={css`
              margin: 0 auto;
            `}
          >
            <DescriptionTitle css={[RowStyles.paragraphSpacer]}>
              What you would like us to manage...
            </DescriptionTitle>
            <Toggle
              xs
              theme={['theme-new-purple', 'theme-large-label']}
              checked={updatedConfig.attribution}
              label="Save Validation + Attribution"
              handleChange={() => {
                segmentCustomEvent(
                  'Settings - Setup, Configuration: clicked on Billing system "Attribution"'
                );

                setUpdatedConfig({
                  ...updatedConfig,
                  attribution: !updatedConfig.attribution,
                  repurchase_based_attribution: updatedConfig.attribution
                    ? false
                    : updatedConfig.repurchase_based_attribution,
                  enable_bypass: updatedConfig.attribution
                    ? false
                    : updatedConfig.enable_bypass,
                });
              }}
              disabled={isStarterFreePlan || isConfigLoading()}
              helpText="When this is turned on, we will validate saves against your billing system and pull in revenue numbers applying the relevant currency calculation based on the time of the session."
              helpDocLink="https://www.chargebee.com/docs/retention/validating-saves-against-your-billing-system.html"
              restrictionTooltip={
                isStarterFreePlan
                  ? strings.UPGRADE_PLAN({
                      description: strings.UPGRADE_TO_DEFLECTION_DESC,
                    })
                  : undefined
              }
            />
            {(isChargebeeIntegration || isRechargeIntegration) && (
              <Toggle
                xs
                theme={['theme-new-purple', 'theme-large-label']}
                checked={updatedConfig.repurchase_based_attribution}
                label="Retained Revenue Reporting"
                handleChange={() => {
                  segmentCustomEvent(
                    'Settings - Setup, Configuration: clicked on Billing system "Repurchase-based Attribution"'
                  );

                  setUpdatedConfig({
                    ...updatedConfig,
                    repurchase_based_attribution:
                      !updatedConfig.repurchase_based_attribution,
                  });
                }}
                disabled={
                  isStarterPlan ||
                  isConfigLoading() ||
                  !updatedConfig.attribution
                }
                helpText="When this is turned on, we will track repurchases based on invoices in your billing system, and will add metrics such as first-time repurchase rate, average # of repurchase orders, and cumulative retained revenue to your in-app reports."
                helpDocLink="https://www.chargebee.com/docs/retention/repurchase-revenue-impact-reporting.html"
                restrictionTooltip={
                  isStarterPlan
                    ? strings.UPGRADE_PLAN({
                        description: strings.UPGRADE_TO_DEFLECTION_DESC,
                      })
                    : undefined
                }
              />
            )}
            {showBypassSettings && isChargebeeIntegration && (
              <Toggle
                xs
                theme={['theme-new-purple', 'theme-large-label']}
                checked={updatedConfig.enable_bypass}
                label="Enable Bypass Targeting"
                handleChange={() => {
                  segmentCustomEvent(
                    'Settings - Setup, Configuration: clicked on Billing system "Enable Bypass Targeting"'
                  );

                  setUpdatedConfig({
                    ...updatedConfig,
                    enable_bypass: !updatedConfig.enable_bypass,
                  });
                }}
                disabled={
                  isStarterPlan ||
                  isConfigLoading() ||
                  billingIntegration.data_type !==
                    IntegrationDataType.CHARGEBEE ||
                  !updatedConfig.attribution
                }
                helpText="When bypass is enabled, a percentage (as configured below) of your cancelation traffic will be routed to your native flow outside of Chargebee Retention to allow for A/B performance testing."
                helpDocLink="https://www.chargebee.com/docs/retention/repurchase-revenue-impact-reporting.html"
                restrictionTooltip={
                  isStarterPlan
                    ? strings.UPGRADE_PLAN({
                        description: strings.UPGRADE_TO_DEFLECTION_DESC,
                      })
                    : undefined
                }
              />
            )}
            {showBypassSettings && updatedConfig.enable_bypass && (
              <InputGroup>
                <div>
                  <Label id="bypassPercent" label="Bypass percent" />
                  <div css={[RowStyles.centerAll]}>
                    <input
                      id="bypassPercent"
                      name="bypassPercent"
                      type="text"
                      className={cs('bbk-simple-text-input xsmall')}
                      onChange={(e) => {
                        segmentCustomEvent(
                          'Settings - Setup, Configuration: clicked on Billing system "Set Bypass Percent"'
                        );

                        setUpdatedConfig({
                          ...updatedConfig,
                          bypass_percent: +e.target.value,
                        });
                      }}
                      value={updatedConfig.bypass_percent ?? 0}
                    />
                    <span css={{ marginLeft: RowStyles.iconSpacer }}>%</span>
                  </div>
                </div>
                <InputGroupItemDesc>
                  <div>
                    % of traffic to route via your native cancelation flow
                    outside of Chargebee Retention
                  </div>
                </InputGroupItemDesc>
              </InputGroup>
            )}
            <Toggle
              xs
              theme={['theme-new-purple', 'theme-large-label']}
              checked={updatedConfig.enrich}
              label="Enrichment"
              handleChange={() => {
                segmentCustomEvent(
                  'Settings - Setup, Configuration: clicked on Billing system "Enrichment"'
                );

                setUpdatedConfig({
                  ...updatedConfig,
                  enrich: !updatedConfig.enrich,
                });
              }}
              disabled={isConfigLoading()}
              helpText="When enrichment is enabled we will pull in fields from your billing system to map to Chargebee Retention based on the subscription ID. This requires that a valid subscription ID that matches to your billing system is passed in the pre-cancel request."
              helpDocLink="https://www.chargebee.com/docs/retention/enrichment-with-stripe-recurly.html"
            />
            <Toggle
              xs
              theme={['theme-new-purple', 'theme-large-label']}
              checked={updatedConfig.manage_offers}
              label="Manage offers"
              handleChange={() => {
                segmentCustomEvent(
                  'Settings - Setup, Configuration: clicked on Billing system "Manage offers"'
                );
                setUpdatedConfig({
                  ...updatedConfig,
                  manage_offers: !updatedConfig.manage_offers,
                });
              }}
              disabled={isStarterPlan || isConfigLoading()}
              helpText="When this is turned on, you will be able to link Pause, Discount, Plan Change and Extension offers directly to your billing system, to be applied via API when a user accepts those offers. To link offers to your billing system, go to the Offer Library."
              helpDocLink="https://www.chargebee.com/docs/retention/configuring-offer-management-in-chargebee-retention.html"
              restrictionTooltip={
                isStarterPlan
                  ? strings.UPGRADE_PLAN({
                      description: strings.UPGRADE_TO_DEFLECTION_DESC,
                    })
                  : undefined
              }
            />
            {billingConfig &&
              updatedConfig &&
              !billingConfig.manage_offers &&
              !!updatedConfig.manage_offers && (
                <div>
                  You have turned on manage offers. When you click save, this
                  will enable you to directly process offers via the integrated
                  billing system. But to be safe, this feature is also
                  controlled at the offer-level and is turned off for each offer
                  by default. But you can turn this on in the offer editor one
                  offer at a time.
                </div>
              )}
            <Toggle
              xs
              theme={['theme-new-purple', 'theme-large-label']}
              checked={updatedConfig.manage_cancels}
              label="Manage cancels"
              handleChange={() => {
                segmentCustomEvent(
                  'Settings - Setup, Configuration: clicked on Billing system "Manage cancels"'
                );

                setUpdatedConfig({
                  ...updatedConfig,
                  manage_cancels: !updatedConfig.manage_cancels,
                });
              }}
              disabled={isConfigLoading()}
              helpText="When this is turned on, Chargebee Retention will cancel subscriptions for you directly via your billing systems API."
              helpDocLink="https://www.chargebee.com/docs/retention/configuring-cancel-management-with-billing.html"
            />
            {updatedConfig.manage_cancels && (
              <div>
                <div
                  css={css`
                    margin-top: 1rem;
                    margin-bottom: 0.5rem;
                  `}
                >
                  <DescriptionTitle>
                    How do you want this setting to apply?
                  </DescriptionTitle>
                </div>
                <div>
                  <Toggle
                    xs
                    theme={['theme-always-purple', 'theme-large-label']}
                    dotColor="white"
                    checked={updatedConfig.apply_to_all_pages}
                    leftLabel="Page-level"
                    label="App-level"
                    handleChange={() => {
                      segmentCustomEvent(
                        'Settings - Setup, Configuration: clicked on Billing system "Cancel all subscriptions"'
                      );
                      setUpdatedConfig({
                        ...updatedConfig,
                        apply_to_all_pages: !updatedConfig.apply_to_all_pages,
                      });
                    }}
                    disabled={isConfigLoading()}
                    helpText="Select if you would like to have your Manage Cancels settings applied at the App-level or Page-level. App-level means these settings will apply to all of your pages by default, where as Page-level allows you to configure these settings on a page-by-page basis including turning them off if you would like."
                  />
                  {updatedConfig.apply_to_all_pages && (
                    <div
                      css={css`
                        margin-left: 99px;
                      `}
                    >
                      <Toggle
                        xs
                        theme={['theme-new-purple', 'theme-large-label']}
                        checked={updatedConfig.cancel_all_subscriptions}
                        label="Cancel all subscriptions"
                        handleChange={() => {
                          segmentCustomEvent(
                            'Settings - Setup, Configuration: clicked on Billing system "Cancel all subscriptions"'
                          );
                          setUpdatedConfig({
                            ...updatedConfig,
                            cancel_all_subscriptions:
                              !updatedConfig.cancel_all_subscriptions,
                          });
                        }}
                        disabled={isConfigLoading()}
                        helpText="When this is enabled Chargebee Retention will cancel all the subscriptions on a customers record including any future subscriptions that have been scheduled."
                      />
                      <div
                        css={css`
                          margin-top: 10px;
                        `}
                      >
                        {(billingIntegration.data_type ===
                          IntegrationDataType.STRIPE ||
                          billingIntegration.data_type ===
                            IntegrationDataType.STRIPE_API_KEY) && (
                          <div>
                            <DescriptionTitle css={[RowStyles.paragraphSpacer]}>
                              When a subscription is canceled...
                            </DescriptionTitle>
                            <StripeForm
                              values={
                                updatedCancelConfig as StripeBillingConfig
                              }
                              setCancelConfig={setUpdatedCancelConfig}
                              loading={isConfigLoading()}
                            />
                          </div>
                        )}
                        {billingIntegration.data_type ===
                          IntegrationDataType.RECHARGE && (
                          <div>
                            <DescriptionTitle css={[RowStyles.paragraphSpacer]}>
                              When a subscription is canceled...
                            </DescriptionTitle>
                            <RechargeForm
                              values={
                                updatedCancelConfig as RechargeBillingConfig
                              }
                              setCancelConfig={setUpdatedCancelConfig}
                              loading={isConfigLoading()}
                            />
                          </div>
                        )}
                        {billingIntegration.data_type ===
                          IntegrationDataType.RECURLY && (
                          <div>
                            <DescriptionTitle css={[RowStyles.paragraphSpacer]}>
                              When a subscription is canceled...
                            </DescriptionTitle>
                            <RecurlyForm
                              values={
                                updatedCancelConfig as RecurlyBillingConfig
                              }
                              setCancelConfig={setUpdatedCancelConfig}
                              loading={isConfigLoading()}
                            />
                          </div>
                        )}
                        {billingIntegration.data_type ===
                          IntegrationDataType.CHARGEBEE && (
                          <div>
                            <DescriptionTitle css={[RowStyles.paragraphSpacer]}>
                              When a subscription is canceled...
                            </DescriptionTitle>
                            <ChargebeeForm
                              values={
                                updatedCancelConfig as ChargebeeBillingConfig
                              }
                              setCancelConfig={setUpdatedCancelConfig}
                              loading={isConfigLoading()}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {applyAllOn && (
          <ConfirmationDialog open={applyAllOn}>
            <DialogTitle css={{ 'text-align': 'center' }}>
              You are turning OFF page-based cancel control.
            </DialogTitle>
            <DialogContent>
              This will remove any previously configured page-level cancel
              settings, and will result in all cancelations being processed via
              the app-level settings.
            </DialogContent>
            <DialogActions
              css={{ display: 'flex', 'flex-direction': 'column' }}
            >
              <ButtonV2
                success
                onClick={() => {
                  if (billingConfig) {
                    setUpdatedConfig(billingConfig);
                  }
                  setApplyAllOn(false);
                }}
              >
                No - keep my page-based settings
              </ButtonV2>
              <ButtonV2
                secondary
                onClick={() => {
                  if (updatedConfig && updatedCancelConfig) {
                    const args: BillingConfig = {
                      ...updatedConfig,
                      cancel_config: updatedCancelConfig,
                    };
                    putBillingConfig({ ...args, app_key: appKey })
                      .unwrap()
                      .then(async () => {
                        await propagateConfigs({ ...args, app_key: appKey })
                          .unwrap()
                          .then(() => {
                            onSave?.();
                            BlackToastTrigger({
                              content: 'Billing Config Updated',
                            });
                          });
                      })
                      .catch((e: RTKAPIErrorResponse) => {
                        const err = parseRTKAPIError(e);
                        BlackToastTrigger({
                          content: `Error Saving Config: ${err.message}`,
                        });
                      })
                      .finally(() => {
                        setApplyAllOn(false);
                      });
                  }
                }}
              >
                Yes - turn off page-based settings
              </ButtonV2>
            </DialogActions>
          </ConfirmationDialog>
        )}

        {applyAllOff && (
          <ConfirmationDialog open={applyAllOff}>
            <DialogTitle>
              You are turning ON page-based cancel control.
            </DialogTitle>
            <DialogContent>
              Do you want to propagate the app-level settings to all your pages
              now? This will turn on direct cancel settings for each page. You
              can also manually set up direct cancels for each page later.
            </DialogContent>
            <DialogActions
              css={{ display: 'flex', 'flex-direction': 'column' }}
            >
              <ButtonV2
                success
                onClick={() => {
                  if (billingConfig && updatedConfig) {
                    const args = {
                      ...billingConfig,
                      manage_cancels: updatedConfig.manage_cancels,
                      apply_to_all_pages: updatedConfig.apply_to_all_pages,
                      app_key: appKey,
                    };
                    putBillingConfig(args)
                      .unwrap()
                      .then(async () => {
                        await propagateConfigs(args)
                          .unwrap()
                          .then(() => {
                            onSave?.();
                            BlackToastTrigger({
                              content: 'Billing Config Updated',
                            });
                          });
                      })
                      .catch((e: RTKAPIErrorResponse) => {
                        const err = parseRTKAPIError(e);
                        BlackToastTrigger({
                          content: `Error Saving Config: ${err.message}`,
                        });
                      })
                      .finally(() => {
                        setApplyAllOff(false);
                      });
                  }
                }}
              >
                Yes - propagate to all pages
              </ButtonV2>

              <ButtonV2
                secondary
                onClick={() => {
                  if (billingConfig && updatedConfig) {
                    const args = {
                      ...billingConfig,
                      manage_cancels: updatedConfig.manage_cancels,
                      apply_to_all_pages: updatedConfig.apply_to_all_pages,
                      app_key: appKey,
                    };
                    putBillingConfig(args)
                      .unwrap()
                      .then(async () => {
                        await propagateConfigs({
                          ...args,
                          manage_cancels: false,
                        })
                          .unwrap()
                          .then(() => {
                            onSave?.();
                            BlackToastTrigger({
                              content: 'Billing Config Updated',
                            });
                          });
                      })
                      .catch((e: RTKAPIErrorResponse) => {
                        const err = parseRTKAPIError(e);
                        BlackToastTrigger({
                          content: `Error: Saving Config ${err.message}`,
                        });
                      })
                      .finally(() => {
                        setApplyAllOff(false);
                      });
                  }
                }}
              >
                No - I will set up manually
              </ButtonV2>

              <ButtonV2
                secondary
                onClick={() => {
                  if (billingConfig) {
                    setUpdatedConfig(billingConfig);
                  }
                  setApplyAllOff(false);
                }}
              >
                Cancel
              </ButtonV2>
            </DialogActions>
          </ConfirmationDialog>
        )}

        <div css={{ marginTop: '1rem' }}>
          <ButtonV2
            primary
            onClick={() => {
              segmentCustomEvent(
                'Settings - Setup, Configuration: clicked on Billing system "Save and close"',
                { updatedCancelConfig }
              );

              if (
                !billingConfig?.apply_to_all_pages &&
                ((billingConfig?.manage_cancels &&
                  !updatedConfig?.manage_cancels) ||
                  updatedConfig?.apply_to_all_pages)
              ) {
                // warn user if page-based is being turned off
                return setApplyAllOn(true);
              }
              if (
                !updatedConfig?.apply_to_all_pages &&
                ((!billingConfig?.manage_cancels &&
                  updatedConfig?.manage_cancels) ||
                  billingConfig?.apply_to_all_pages)
              ) {
                // warn user if page based is being turned on
                return setApplyAllOff(true);
              }
              if (updatedConfig) {
                const args = {
                  ...updatedConfig,
                  ...(updatedCancelConfig && {
                    cancel_config: updatedCancelConfig,
                  }),
                  app_key: appKey,
                };
                putBillingConfig(args)
                  .unwrap()
                  .then(async () => {
                    if (
                      billingConfig?.manage_cancels !==
                      updatedConfig?.manage_cancels
                    ) {
                      // manage cancel changed
                      await propagateConfigs(args).unwrap();
                    }
                    onSave?.();
                    BlackToastTrigger({
                      content: 'Billing Config Updated',
                    });
                  })
                  .catch((e: RTKAPIErrorResponse) => {
                    const err = parseRTKAPIError(e);
                    BlackToastTrigger({
                      content: `Error Saving Config: ${err.message}`,
                    });
                  })
                  .finally(() => {
                    setApplyAllOn(false);
                  });
              }
            }}
            disabled={isConfigLoading()}
          >
            Save
          </ButtonV2>
          <ButtonV2
            transparent
            css={{ marginLeft: '1rem' }}
            onClick={() => {
              handleConfigReset();
            }}
            disabled={isConfigLoading()}
          >
            Reset
          </ButtonV2>
        </div>
      </>
    );
  };

  return render();
};
