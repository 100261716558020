type GetFieldPrefixForBbkExpressionArgs = {
  standard?: boolean;
  alert?: boolean;
};
export const getFieldPrefixForBbkExpression = (
  args: GetFieldPrefixForBbkExpressionArgs
): 'standard' | 'alert' | 'custom' => {
  const { standard, alert } = args;
  if (standard) return 'standard';
  if (alert) return 'alert';
  return 'custom';
};
