// @ts-nocheck
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _, { get } from 'lodash';
import { produce } from 'immer';
import cs from 'classnames';
import { useUrl } from '@bbkAdminUtils/nav';
import { selectAppId } from '@bbkAdminRedux/app/selectors';
import { EditOfferPageContext } from '@bbkAdminComponents/experiences/offers/edit-offer-page-context';
import {
  confirmationFunnelSlice,
  destinationProtocols,
  extractConfirmationFunnelKey,
} from '@bbkAdminRedux/rtkq/confirmation-funnel.slice';
import type { SelectOption } from '@bbkAdminComponents/select/select';
import GenericSelect, {
  generateGenericSelectOptions,
} from '@bbkAdminComponents/select/select';
import type { RestModalForwards } from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import { RestModalAction } from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import type {
  Editor,
  GroupLayout,
} from '@bbkAdminComponents/experiences/offers/editor-layouts/group-layouts';
import {
  getDefaultActionContent,
  getDefaultActionSteps,
} from '@bbkAdminComponents/experiences/offers/content/action-content';
import { DestinationType } from '@bbkAdminRedux/rtkq/experience.slice';
import { AuthorizedPaths } from '@bbkAdminRouter/router-paths';
import TextInput from '@bbkAdminComponents/inputs/text/text-input';
import type { ElementType, PatchPath } from '@bbkAdminUtils/utility-types';
import { DeleteBtn } from '@bbkAdminComponents/experiences/offers/edit-components';
import Icons from '@bbkAdminUtils/icons';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import Label from '@bbkAdminComponents/inputs/label/label';
import CollapseFormContainer from '@bbkAdminComponents/form/collapse-form-container/collapse-form-container';
import type { CardLayout } from '@bbkAdminComponents/experiences/offers/editor-layouts/card-layouts';
import { collectChainOfCards } from '@bbkAdminComponents/experiences/offers/edit-offer-utils';
import { UrlSelect } from '@bbkAdminComponents/experiences/offers/url-select';
import {
  integrationSlice,
  selectNonUrlFields,
  selectUrlFields,
} from '@bbkAdminRedux/rtkq/integrations.slice';
import { useQueryFeatureFlags } from '@bbkAdminRedux/rtkq/feature-flags.slice';

type CardGenericLayoutEditorProps = {
  layout: CardLayout<any>;
  initParentPath: PatchPath;
  wrapInCollapsableContainer?: boolean;
  isCollapsableOpened?: boolean;
};
export const CardGenericLayoutEditor: React.FC<
  CardGenericLayoutEditorProps
> = ({
  layout,
  initParentPath,
  wrapInCollapsableContainer = true,
  isCollapsableOpened,
}) => {
  const createUrl = useUrl();

  const appKey = useSelector(selectAppId);
  const { data: featureFlags } = useQueryFeatureFlags();

  const { data: nonUrlFields } =
    integrationSlice.endpoints.getFieldMappings.useQuery(
      { appKey },
      { selectFromResult: selectNonUrlFields }
    );
  const { data: urlFields } =
    integrationSlice.endpoints.getFieldMappings.useQuery(
      { appKey },
      { selectFromResult: selectUrlFields }
    );

  const { offer, setOffer } = useContext(EditOfferPageContext);

  const { data: confirmationFunnels, isFetching: isFetchingCCPs } =
    confirmationFunnelSlice.endpoints.getConfirmationFunnels.useQuery({
      appKey,
    });
  const confirmationFunnelOptions = useMemo(
    () =>
      generateGenericSelectOptions(
        confirmationFunnels?.filter((cf) => cf.active) ?? []
      ),
    [confirmationFunnels]
  );

  const generateCardsToClear = (patchPath: PatchPath): string[] => {
    // const idx = patchPath[1];
    // return offer.cards.slice(parseInt(idx, 10) + 1).map((c) => c.name);
    const ctaPath = patchPath.slice(0, -1);
    const cta = get(offer, ctaPath) as RestModalForwards;
    const ctaModal: string | undefined = cta.modal;
    if (ctaModal) return collectChainOfCards(offer, ctaModal);
    return [];
  };

  function update(...opts: { patchPath: PatchPath; value: any }[]) {
    const newOffer = produce(offer, (draft) => {
      opts.forEach(({ patchPath, value }) => {
        const nullableValue = value === '' ? undefined : value;
        _.set(draft, patchPath.join('.'), nullableValue);
        // if we updated action type - we need to generate new default content
        if (patchPath.join('.').endsWith('.action')) {
          const filterCardNames = generateCardsToClear(patchPath);
          const newAction = nullableValue as RestModalAction;
          // Generate values
          const newActionCards = getDefaultActionSteps(draft, newAction);
          const newActionObj = getDefaultActionContent(newAction);
          if (newActionCards.length && newActionObj) {
            newActionObj.modal = newActionCards[0]?.name;
          }

          // Update the offer (card)
          // path grabs the area to set the new item
          const forwardsPath = patchPath.slice(0, -1).join('.');
          _.set(draft, forwardsPath, newActionObj);
          // remove any card chains
          const remainingCards = draft.cards.filter(
            (card) => !filterCardNames.includes(card.name)
          );
          // add in any new cards
          draft.cards = [...remainingCards, ...newActionCards];
        }
      });
    });

    setOffer(newOffer);
  }

  function addNewOtherAction(groupPath: PatchPath) {
    const existingOtherActions = _.get(offer, groupPath);
    const newActionObj = getDefaultActionContent(RestModalAction.link);

    if (existingOtherActions) {
      // handle case of appending
      update({
        patchPath: groupPath,
        value: [...existingOtherActions, newActionObj],
      });
    } else {
      // handle case of non-existence
      update({ patchPath: groupPath, value: [newActionObj] });
    }
  }

  function removeOtherActionEntry(patchPath: PatchPath) {
    // removes the item and any associated other cards
    const idx = patchPath.slice(-1);
    const containerPath = patchPath.slice(0, -1);
    const cardsToClear = generateCardsToClear([...patchPath, null]);

    const newOffer = produce(offer, (draft) => {
      const containerItems = [..._.get(offer, containerPath, [])];
      containerItems.splice(idx, 1);
      _.set(draft, containerPath, containerItems);
      if (containerItems.length === 0) {
        _.unset(draft, containerPath);
      }
      // remove any card chains
      const remainingCards = draft.cards.filter(
        (card) => !cardsToClear.includes(card.name)
      );
      // add in any new cards
      draft.cards = [...remainingCards];
    });

    setOffer(newOffer);
  }

  function removeEntry(patchPath: PatchPath) {
    const idx = patchPath.slice(-1);
    const arrPath = patchPath.slice(0, -1);
    const newOffer = produce(offer, (draft) => {
      const oldArray = _.get(draft, arrPath.join('.'));
      oldArray.splice(idx, 1);
      _.set(draft, arrPath.join('.'), oldArray);
    });

    setOffer(newOffer);
  }

  function addNewEntry(patchPath: PatchPath) {
    const newOffer = produce(offer, (draft) => {
      _.set(draft, patchPath.join('.'), '');
    });

    setOffer(newOffer);
  }

  function getEditor(
    fieldEditor: Editor | undefined,
    patchPath: PatchPath,
    inputValue: string,
    index = 0
  ) {
    const onChange = (path: PatchPath, value: unknown) =>
      update({ patchPath: path, value });

    if (fieldEditor?.node) {
      const { node, ...restEditorProps } = fieldEditor;
      let editor = fieldEditor.node({
        patchPath,
        value: inputValue,
        onChange,
        suggestions: nonUrlFields,
        ...(fieldEditor.fieldType === 'url' && {
          urlSuggestions: urlFields,
        }),
        featureFlags,
        ...restEditorProps,
      });

      if (fieldEditor.nodeType === 'colorSelector') {
        editor = fieldEditor.node({
          patchPath,
          value: inputValue,
          onChange,
        });
      }
      const navigationDestinationType = () =>
        _.get(offer, `${patchPath.slice(0, -1).join('.')}.destination_type`) ??
        DestinationType.url;

      const oldUrlSelect = (
        <>
          <GenericSelect
            isSearchable={false}
            menuPosition="fixed"
            isDisabled={false}
            options={destinationProtocols}
            onChange={(value) => {
              const destinationProtocol = value as SelectOption;
              const path = [...patchPath.slice(0, -1), 'destination_type'];
              update(
                { patchPath: path, value: destinationProtocol.value },
                {
                  patchPath,
                  value: `?confirmation_funnel=${confirmationFunnelOptions[0]?.value}`,
                }
              );
            }}
            value={
              destinationProtocols.find(
                (d) => d.value === navigationDestinationType()
              ) ?? destinationProtocols[0]
            }
          />
          <div
            style={{
              paddingLeft: '25px',
              marginTop: '20px',
              border: 'none',
              borderLeft: '3px solid rgba(0,0,0,.5)',
            }}
          >
            {navigationDestinationType() === DestinationType.url && editor}
            {navigationDestinationType() ===
              DestinationType.confirmation_page && (
              <GenericSelect
                menuPosition="fixed"
                isDisabled={isFetchingCCPs}
                options={confirmationFunnelOptions}
                onChange={(value) => {
                  const funnel = value as SelectOption;
                  update({
                    patchPath,
                    value: `?confirmation_funnel=${funnel.value}`,
                  });
                }}
                value={
                  confirmationFunnelOptions.find(
                    (cf) =>
                      cf.value ===
                      extractConfirmationFunnelKey(
                        _.get(offer, patchPath.join('.'))
                      )
                  ) ?? confirmationFunnelOptions[0]
                }
              />
            )}
            {navigationDestinationType() === DestinationType.global && (
              <div>
                Global URL Configurations are managed in the application
                <Link
                  to={createUrl(AuthorizedPaths.settingsSetup)}
                  className="tw-font-bold tw-text-cbrprimary-600 tw-mx-1"
                >
                  Settings
                </Link>
                page
              </div>
            )}
          </div>
        </>
      );
      const newUrlSelect = (
        <UrlSelect
          value={get(offer, patchPath.slice(0, -1)) as RestModalForwards}
          onChange={(updatedCta) =>
            onChange(patchPath.slice(0, -1), updatedCta)
          }
        />
      );
      const urlSelect = oldUrlSelect;
      return fieldEditor.fieldType === 'url' && fieldEditor.subtype !== 'email'
        ? urlSelect
        : editor;
    }
    return (
      <TextInput
        {...fieldEditor}
        name={`${patchPath}__${index}`}
        id={`${patchPath}__${index}`}
        value={inputValue}
        onChange={(e) => onChange(patchPath, e.target.value)}
        classes="flex-1"
      />
    );
  }

  function renderInput(parentPath: PatchPath, field: ElementType<GroupLayout>) {
    const patchPath: PatchPath = _.flattenDeep([...parentPath, field.key]);
    const inputValue = _.get(offer, patchPath.join('.'));

    const hidden = field.hidden;

    let hide: boolean;
    if (typeof hidden === 'function') {
      hide = hidden({ value: inputValue });
    } else {
      hide = !!hidden;
    }

    if (hide) {
      return null;
    }

    let editor;
    if (field.isArray && Array.isArray(inputValue)) {
      editor = (
        <>
          {inputValue.map((el, idx) => (
            <div
              key={[...patchPath, idx].join('.')}
              className="tw-flex tw-mt-2"
            >
              {getEditor(field.editor, [...patchPath, idx], el, idx)}
              <DeleteBtn onClick={() => removeEntry([...patchPath, idx])}>
                {Icons.trash}
              </DeleteBtn>
            </div>
          ))}
          <ButtonV2
            onClick={() => addNewEntry([...patchPath, inputValue.length])}
            css={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '0.5rem',
              svg: {
                width: 16,
                height: 16,
                marginRight: 3,
              },
            }}
          >
            {Icons.plus} Add
          </ButtonV2>
        </>
      );
    } else {
      editor = getEditor(field.editor, patchPath, inputValue);
    }

    const hideGroupLabel = field.editor?.hideGroupLabel;
    return (
      <div className="tw-mb-3">
        <Label id={field.key}>
          {hideGroupLabel
            ? ''
            : ((field.editor?.groupLabel || field.editor?.label) ??
              _.startCase(field.key))}
        </Label>
        <div className="tw-mt-1">{editor}</div>
        {field.editor?.helpLabel && (
          <div className="tw-text-[13px] tw-text-black/50">
            {field.editor.helpLabel}
          </div>
        )}
      </div>
    );
  }

  // group is the "editGroup" that has been generated
  // groupPath is the path for the section within the card
  // groupValue is the actual value of the items within the
  function renderModalLayout(parentPath: PatchPath, group: GroupLayout) {
    let groupKey = group.key ?? [];
    let groupPath = _.flattenDeep([parentPath, groupKey]);

    for (let i = 0, l = groupPath.length; i < l; i++) {
      if (i !== 0 && groupPath[i] === '..') {
        groupPath.splice(i - 1, 2);
      }
    }

    const groupValue = _.get(offer, groupPath.join('.'));

    if (_.isFunction(group.isActive)) {
      if (!group.isActive(featureFlags)) {
        return undefined;
      }
    }

    // Used when the group isn't a variable set
    const renderGroupLayout = (path: PatchPath) =>
      group.layout.map((field, index) => (
        <React.Fragment key={index}>{renderInput(path, field)}</React.Fragment>
      ));

    // variable group types, requires their own item to be passed
    const renderArrayGroupInputs = (
      path: PatchPath,
      groupItemIndex: number
    ) => {
      const groupItem = group.layouts[groupItemIndex];
      return groupItem.map((field, index: number) => (
        <React.Fragment key={index}>{renderInput(path, field)}</React.Fragment>
      ));
    };
    const groupTitle = group.label ?? _.startCase(group.key);

    function renderArrayGroupLayout() {
      return (
        <div className="bbk-new-editor-array-item-wrapper">
          {groupValue.map((item, idx) => (
            <React.Fragment key={idx}>
              {/* <div className="bbk-new-editor-top-title">#{idx + 1}</div> */}
              <div
                className="bbk-new-editor-array-item"
                key={[...groupPath, idx].join('.')}
              >
                {renderGroupLayout([...groupPath, idx])}
              </div>
              <ButtonV2
                onClick={() => removeEntry([...groupPath, idx])}
                transparent
              >
                {Icons.trash}
              </ButtonV2>
            </React.Fragment>
          ))}
        </div>
      );
    }

    function renderGenerics() {
      return group.generics.map((field, index) => (
        <React.Fragment key={index}>
          {renderInput(_.flattenDeep([parentPath]), field)}
        </React.Fragment>
      ));
    }

    function renderGenericArrayGroupLayout() {
      let generics;
      if (group.generics) {
        generics = renderGenerics();
      }

      return (
        <>
          <ButtonV2
            onClick={() => addNewOtherAction(groupPath)}
            css={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '0.5rem',
              marginBottom: '1rem',
              svg: {
                width: 16,
                height: 16,
                marginRight: 3,
              },
            }}
            disabled={groupValue?.length >= group.arrayLimit}
          >
            {Icons.plus} Add new action
          </ButtonV2>
          {generics}

          <div className="bbk-new-editor-array-item-wrapper">
            {groupValue?.map((item, idx) => (
              <React.Fragment key={idx}>
                <div
                  className="bbk-new-editor-array-item"
                  key={[...groupPath, idx].join('.')}
                >
                  {renderArrayGroupInputs([...groupPath, idx], idx)}
                </div>
                <ButtonV2
                  onClick={() => removeOtherActionEntry([...groupPath, idx])}
                  transparent
                >
                  {Icons.trash}
                </ButtonV2>
              </React.Fragment>
            ))}
          </div>
        </>
      );
    }

    function renderCreateItem() {
      return group.createLayout.map((lo, idx) => (
        <React.Fragment key={idx}>
          {lo?.editor?.node &&
            lo.editor.node({
              onClick: () => addNewOtherAction(groupPath),
            })}
        </React.Fragment>
      ));
    }

    let renderItem: React.ReactNode;

    if (group.isArray && Array.isArray(groupValue)) {
      renderItem = renderArrayGroupLayout();
    } else if (group.isVariableArray) {
      renderItem = renderGenericArrayGroupLayout();
    } else if (group.createItem) {
      renderItem = renderCreateItem();
    } else {
      renderItem = renderGroupLayout(groupPath);
    }

    const el = <div className="tw-pt-4">{renderItem}</div>;

    if (!wrapInCollapsableContainer) {
      return el;
    }

    return (
      <CollapseFormContainer
        title={groupTitle}
        isOpened={isCollapsableOpened}
        classes={cs('CFC-default-theme', 'CFC-new-editor-theme')}
      >
        {el}
      </CollapseFormContainer>
    );
  }

  return (
    <>
      {layout.map((group) => (
        <React.Fragment key={`${group.key}-${group.label}`}>
          {renderModalLayout(initParentPath, group)}
        </React.Fragment>
      ))}
    </>
  );
};
