import { combineReducers } from '@reduxjs/toolkit';
import app from '@bbkAdminRedux/app';
import dataManagement from '@bbkAdminRedux/data-management';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import { appPersistSlice } from '@bbkAdminRedux/rtkq/app-persist/app-persist.slice';
import { appNotPersistedSlice } from '@bbkAdminRedux/rtkq/app-not-persisted.slice';
import { internalBbkApiSlice } from '@bbkAdminRedux/rtkq/internal.bbkApi.slice';
import { preauthBbkApiSlice } from '@bbkAdminRedux/rtkq/preauth.bbkApi.slice';
import { datepickerPersistSlice } from '@bbkAdminRedux/rtkq/datepicker-persist.slice';
import {
  authPersistedSlice,
  brightbackAuthSlice,
} from '@bbkAdminRedux/rtkq/brightback-auth.slice';

export const rootReducer = combineReducers({
  // Legacy reducers
  app: app.reducers,
  dataManagement: dataManagement.reducers,

  // LocalStorage reducer
  [appPersistSlice.name]: appPersistSlice.reducer,
  [datepickerPersistSlice.name]: datepickerPersistSlice.reducer,
  [authPersistedSlice.reducerPath]: authPersistedSlice.reducer,

  // Memory reducer
  [appNotPersistedSlice.name]: appNotPersistedSlice.reducer,

  // Server responses reducers
  [preauthBbkApiSlice.reducerPath]: preauthBbkApiSlice.reducer,
  [bbkApiSlice.reducerPath]: bbkApiSlice.reducer,
  [internalBbkApiSlice.reducerPath]: internalBbkApiSlice.reducer,

  // External services
  [brightbackAuthSlice.reducerPath]: brightbackAuthSlice.reducer,
});
