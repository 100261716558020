import { useFavicon } from 'react-use';
import { useBbkEnv } from '@bbkUtils/useBbkEnv';

const envConfigs = {
  dev: {
    favicon: '/assets/images/bbk-lightgreen-favicon.svg',
  },
  staging: {
    favicon: '/assets/images/bbk-pink-favicon.svg',
  },
  prod: {
    favicon: '/assets/images/favicon.svg',
  },
};

export const useBbkFavicon = () => {
  const env = useBbkEnv();
  const favicon = envConfigs[env].favicon;

  useFavicon(favicon);
};
