import { getNowInSeconds } from '@bbkAdminUtils/utils';

export class Cookie {
  private static ACCESS_TOKEN_COOKIE_NAME = 'BRTBCK_JWT';

  private static isSecure() {
    return window.location.protocol === 'https:';
  }

  private static get cookieName() {
    const cname = this.ACCESS_TOKEN_COOKIE_NAME;
    return this.isSecure() ? `__Host-${cname}` : cname;
  }

  private static decorateCookieString(cookie: string) {
    return this.isSecure() ? `${cookie} Secure;` : cookie;
  }

  public static getCookie() {
    const nameWithEqualChar = `${this.cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');
    const jwtCookie = cookies
      .find((c) => c.trim().startsWith(nameWithEqualChar))
      ?.trim();
    return jwtCookie?.slice(nameWithEqualChar.length) ?? null;
  }

  public static setCookie(cval: string, expiresIn: number) {
    const name = this.cookieName;
    const d = new Date((getNowInSeconds() + expiresIn) * 1000);
    const cookie = `${name}=${encodeURIComponent(cval)}; expires=${d.toUTCString()}; path=/;`;
    document.cookie = this.decorateCookieString(cookie);
  }

  public static deleteCookie() {
    const name = this.cookieName;
    const cookie = `${name}=; expires=${new Date(0).toUTCString()}; path=/;`;
    document.cookie = this.decorateCookieString(cookie);
  }
}
