import React from 'react';
import './form-layout.scss';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';

const FormLayout: ReactFCC = ({ children }) => (
  <div className="bbk-form-layout">
    <div>{children}</div>
  </div>
);

export default FormLayout;
