import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { chain, first, keyBy, orderBy } from 'lodash';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import GenericSelect from '@bbkAdminComponents/select/select';
import { selectAppId } from '@bbkAdminRedux/app/selectors';
import {
  integrationSlice,
  LegacySagaFieldMapping,
  useSelectFlatIntegrationsFields,
} from '@bbkAdminRedux/rtkq/integrations.slice';
import { isDefined } from '@bbkAdminRedux/rtkq/rtkq-utils';
import type { IntegrationFieldWithId } from '@bbkAdminRedux/data-management/reducers';

type Props = {
  value?: Pick<LegacySagaFieldMapping, 'integration_id' | 'field'>;
  onChange: (e: { value: IntegrationFieldWithId }) => unknown;
  filterOptions?: (x: IntegrationFieldWithId) => boolean;
};
export const IntegrationFieldSelect: ReactFCC<Props> = ({
  value: fm,
  onChange,
  filterOptions = () => true,
}) => {
  const appKey = useSelector(selectAppId);
  const { data: integrationsMap } =
    integrationSlice.endpoints.getIntegrations.useQuery(
      { appKey },
      {
        selectFromResult: (res) => ({
          ...res,
          data: keyBy(res.data, (x) => x.id),
        }),
      }
    );
  const flatIntegrationFields = useSelectFlatIntegrationsFields({ appKey });
  const fieldsOpts = useMemo(
    () =>
      chain(flatIntegrationFields)
        .filter(filterOptions)
        .groupBy((x) => `${x.integration_id}_${x.object ?? ''}`)
        .map((group) => {
          const firstEl = first(group);
          if (!firstEl) return undefined;
          const objId = firstEl.object;
          const intId = firstEl.integration_id;
          const int = integrationsMap[intId];
          if (!int) return undefined;
          const intObjLabel = int.display_name + (!objId ? '' : ` (${objId})`);
          return {
            label: intObjLabel,
            options: orderBy(
              group.map((field) => ({
                label: field.label,
                value: field,
              })),
              (x) => x.value.name,
              'asc'
            ),
          };
        })
        .filter(isDefined)
        .value(),
    [filterOptions, flatIntegrationFields, integrationsMap]
  );

  const flatFieldsOpts = useMemo(
    () => fieldsOpts.flatMap((group) => group.options),
    [fieldsOpts]
  );

  return (
    <GenericSelect
      isSearchable={false}
      options={fieldsOpts}
      value={flatFieldsOpts.find((ffo) => {
        if (!fm) return undefined;
        const value = ffo.value;
        return (
          value.integration_id === fm.integration_id &&
          value.object === fm.field.object &&
          value.name === fm.field.name
        );
      })}
      onChange={onChange}
    />
  );
};
