// @ts-nocheck
import { Editor, Transforms } from 'slate';
import { setDefaults } from '@udecode/slate-plugins';
import { DEFAULTS_BBK_EXPRESSION } from '../defaults';
import { BrightbackExpression } from '@bbkAdminUtils/brightback-expression';
import type { Suggestion } from '@bbkAdminComponents/rich-text-editor';
import { getFieldPrefixForBbkExpression } from '@bbkAdminComponents/rich-text-editor/bbk-expression/transforms/getFieldPrefixForBbkExpression';

export const insertBbkExpression = (
  editor: Editor,
  field: Suggestion,
  options?
) => {
  const { bbkExpression } = setDefaults(options, DEFAULTS_BBK_EXPRESSION);

  const updatedField = { ...field };
  const value = `${getFieldPrefixForBbkExpression(field)}.${field.name}`;

  let text;
  if (field.type === 'url' && !field.fallBackValue?.trim()) {
    text = BrightbackExpression.createValueFieldReferencePart(value);
    delete updatedField.fallBackValue;
    updatedField.topMethod = 'value';
  } else {
    updatedField.topMethod = 'default';
    text = BrightbackExpression.createStandardFieldReferencePart(
      value,
      field.fallBackValue
    );
  }

  const bbkExpressionNode = {
    type: bbkExpression.type,
    children: [
      {
        text,
      },
    ],
    field: updatedField,
  };
  Transforms.insertNodes(editor, bbkExpressionNode);
  Transforms.move(editor);
};
