import React from 'react';
import cs from 'classnames';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import Icons, { svgToBase64URI } from '@bbkAdminUtils/icons';
import { useGetStartedStatus } from '@bbkAdminComponents/get-started/get-started-page';
import { usePlans } from '@bbkAdminUtils/plans';

export const GetStartedNav: ReactFCC = () => {
  const {
    isBillingCompleted,
    isCreateExperienceCompleted,
    isConfigureStarterAccountCompleted,
    isConfigureAccountCompleted,
    isTestAndLaunchCompleted,
  } = useGetStartedStatus();

  const { isStarterFreePlan, isStarterPremiumPlan } = usePlans();
  const isStarterPlan = isStarterPremiumPlan || isStarterFreePlan;

  return (
    <div className="tw-w-full">
      <div className="tw-flex">
        <div className="tw-mr-3">{Icons.cbrFlag}</div>
        <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
          Get started
          <img
            src={svgToBase64URI(Icons.rightArrow)}
            className="tw-w-4"
            alt=""
          />
        </div>
      </div>
      <div className="tw-flex tw-gap-1 tw-mt-2 tw-mb-1 tw-px-0.5">
        <div
          className={cs('tw-rounded-3xl tw-basis-1/4 tw-border-b-4', {
            'tw-border-b-cbrsuccess-400': isBillingCompleted,
          })}
        />
        <div
          className={cs('tw-rounded-3xl tw-basis-1/4 tw-border-b-4', {
            'tw-border-b-cbrsuccess-400': isCreateExperienceCompleted,
          })}
        />
        <div
          className={cs('tw-rounded-3xl tw-basis-1/4 tw-border-b-4', {
            'tw-border-b-cbrsuccess-400': isStarterPlan
              ? isConfigureStarterAccountCompleted
              : isConfigureAccountCompleted,
          })}
        />
        <div
          className={cs('tw-rounded-3xl tw-basis-1/4 tw-border-b-4', {
            'tw-border-b-cbrsuccess-400': isTestAndLaunchCompleted,
          })}
        />
      </div>
    </div>
  );
};
