import type React from 'react';
import styled from '@emotion/styled';
import * as polished from 'polished';
import tailwindColors from '@bbkRoot/tailwind.colors';

const baseBackground = '#fff';
const baseBackgroundHover = tailwindColors.cbrprimary[50];
const baseColor = 'rgba(0,0,0,0.5)';
const primaryBackground = tailwindColors.cbrprimary[800];
const baseColorHover = primaryBackground;
const primaryBackgroundHover = polished.lighten(0.1, primaryBackground);
const primaryColor = '#ffffff';
const primaryBackgroundActive = polished.lighten(0.2, primaryBackground);
const secondaryBackgroundHover = baseBackgroundHover;
const secondaryBackgroundActive = polished.darken(
  0.1,
  secondaryBackgroundHover
);
const radioBorder = 'rgba(0,0,0,0.25)';
const successColor = '#fff';
const successBackground = '#27AE60';
const successBackgroundHover = '#24a259';
const successBackgroundActive = '#239855';
const dangerColor = '#fff';
const dangerBackground = '#EB5757';
const dangerBackgroundHover = '#ef4343';
const dangerBackgroundActive = '#ef3b3b';
const warningColor = '#fff';
const warningBackground = '#F2C94C';
const warningBackgroundHover = '#D9B033';
const warningBackgroundActive = '#FFE366';
const deepPurpleColor = '#fff';
const deepPurpleBackground = tailwindColors.cbrprimary['700'];
const deepPurpleBackgroundHover = tailwindColors.cbrprimary['800'];
const deepPurpleBackgroundActive = tailwindColors.cbrprimary['600'];
const white = '#fff';
const black = `#000`;

export type ButtonBase = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  primary?: boolean;
  secondary?: boolean;
  deepPurple?: boolean;
  white?: boolean;
  black?: boolean;
  success?: boolean;
  danger?: boolean;
  warning?: boolean;
  tab?: boolean;
  tabVertical?: boolean;
  active?: boolean;
  radio?: boolean;
  transparent?: boolean;
  deepColor?: boolean;
  circle?: boolean;
  small?: boolean;
  tiny?: boolean;
  centerAll?: boolean;
  fullWidth?: boolean;
};

export const CircleIcon = styled.div`
  background: ${baseBackgroundHover};
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(51, 51, 79, 0.1);
  color: ${baseColorHover};
  height: 32px;
  padding: 0;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    background: ${secondaryBackgroundHover};
  }

  &:active {
    background: ${secondaryBackgroundActive};
  }
`;

export const ButtonV2 = styled.button<ButtonBase>`
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  flex-shrink: 0;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props: ButtonBase) => {
    if (props.tiny) return '12px';
    return '14px';
  }};
  line-height: 17px;
  mix-blend-mode: normal;
  box-sizing: border-box;
  ${(props: ButtonBase) => {
    if (props.centerAll) {
      return `
      display: flex;
      align-items: center;
      justify-content: center;
      > * {
        display: flex;
      }
      `;
    }
  }}
  height: ${(props: ButtonBase) => {
    if (props.circle && props.small) return '24px';
    if (props.circle) return '36px';
  }};
  width: ${(props: ButtonBase) => {
    if (props.circle && props.small) return '24px';
    if (props.circle) return '36px';
    if (props.fullWidth) return '100%';
  }};

  svg {
    width: 18px;
  }

  padding: ${(props: ButtonBase) => {
    if (props.radio) return '8px 15px 9px';
    if (props.circle) return 0;
    if (props.small) return '5px';
    if (props.tiny) return '2px';
    return '9px 15px 10px';
  }};
  border-radius: ${(props: ButtonBase) => {
    if (props.circle) return '50%';
    if ((props.tab || props.tabVertical) && props.active) return 0;
    return '4px';
  }};
  color: ${(props: ButtonBase) => {
    if ((props.tab || props.tabVertical) && props.active) return baseColorHover;
    if (props.radio && props.active) return primaryColor;
    if (props.success) return successColor;
    if (props.danger) return dangerColor;
    if (props.warning) return warningColor;
    if (props.secondary || props.circle) return tailwindColors.cbrprimary[600];
    if (props.deepColor) return baseColorHover;
    if (props.deepPurple) return deepPurpleColor;
    if (props.white) return white;
    if (props.black) return black;
    return props.primary ? primaryColor : baseColor;
  }};
  background: ${(props: ButtonBase) => {
    if ((props.tab || props.tabVertical) && props.active) return baseBackground;
    if (props.radio && props.active) return primaryBackground;
    if (props.primary) return primaryBackground;
    if (props.secondary || props.circle) return baseBackgroundHover;
    if (props.transparent) return 'transparent';
    if (props.success) return successBackground;
    if (props.danger) return dangerBackground;
    if (props.warning) return warningBackground;
    if (props.deepPurple) return deepPurpleBackground;
    if (props.white) return black;
    if (props.black) return white;
    return baseBackground;
  }};
  border-bottom: ${(props: ButtonBase) => {
    if (props.tab)
      return props.active
        ? `3px solid ${baseColorHover}`
        : '3px solid transparent';
    return 'none';
  }};
  border-left: ${(props: ButtonBase) => {
    if (props.tabVertical)
      return props.active ? `3px solid ${baseColorHover}` : '3px solid #fff';
    return 'none';
  }};
  border: ${(props: ButtonBase) => {
    if (props.radio) {
      if (props.active) {
        return `1px solid ${primaryBackground}`;
      }
      return `1px solid ${radioBorder}`;
    }
  }};

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    background: ${(props: ButtonBase) => {
      if (props.primary) return primaryBackgroundHover;
      if (props.secondary || props.circle) return secondaryBackgroundHover;
      if (props.success) return successBackgroundHover;
      if (props.danger) return dangerBackgroundHover;
      if (props.warning) return warningBackgroundHover;
      if ((props.tab || props.tabVertical) && props.active)
        return 'transparent';
      if (props.radio && props.active) return primaryBackground;
      if (props.transparent) return 'transparent';
      if (props.deepPurple) return deepPurpleBackgroundHover;
      return baseBackgroundHover;
    }};
    color: ${(props: ButtonBase) => {
      if (props.primary) return primaryColor;
      if (props.success) return successColor;
      if (props.danger) return dangerColor;
      if (props.warning) return warningColor;
      if (props.radio && props.active) return primaryColor;
      if (props.white) return white;
      if (props.deepPurple) return deepPurpleColor;
      if (props.transparent) return primaryBackgroundActive;
      return baseColorHover;
    }};
    box-shadow: ${(props: ButtonBase) => {
      if (props.transparent) {
        return;
      }
      if (
        props.primary ||
        props.success ||
        props.danger ||
        props.warning ||
        props.secondary ||
        props.circle ||
        props.small ||
        props.radio
      ) {
        return '0 2px 5px 0 rgba(51, 51, 79, 0.1)';
      }
    }};
  }

  &:active {
    background: ${(props: ButtonBase) => {
      if (props.tab) return baseBackground;
      if (props.primary) return primaryBackgroundActive;
      if (props.secondary || props.circle) return secondaryBackgroundActive;
      if (props.success) return successBackgroundActive;
      if (props.danger) return dangerBackgroundActive;
      if (props.warning) return warningBackgroundActive;
      if (props.radio)
        return props.active ? primaryBackground : primaryBackgroundActive;
      if (props.transparent) return 'transparent';
      if (props.deepPurple) return deepPurpleBackgroundActive;
      return baseBackgroundHover;
    }};
    transform: ${(props: ButtonBase) => {
      if (props.tab || props.active) return;
      return 'scale(0.934)';
    }};
    border-bottom: ${(props: ButtonBase) =>
      props.tab && `3px solid ${baseColorHover}`};
    border-radius: ${(props: ButtonBase) => props.tab && 0};
    color: ${(props: ButtonBase) => {
      if (props.radio) return primaryColor;
    }};
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const ButtonAI = styled(ButtonV2)`
  background: #012a38;
  border-radius: 4px;
  svg {
    width: auto;
  }
  &:hover,
  &:focus {
    background: #012a38;
    opacity: 0.75;
  }
`;
