// @ts-nocheck
import { useCallback, useState, useEffect, useRef } from 'react';
import { Editor, Range, Transforms } from 'slate';
import {
  isCollapsed,
  isPointAtWordEnd,
  isWordAfterTrigger,
  getSelectionText,
  getPointBefore,
} from '@udecode/slate-plugins';
import Fuse from 'fuse.js';
import type { Suggestion } from '@bbkAdminComponents/rich-text-editor';
import { insertBbkExpression } from './transforms';
import { DEFAULTS_BBK_EXPRESSION } from './defaults';

export const getNextIndex = (i: number, max: number) => (i >= max ? 0 : i + 1);
export const getPreviousIndex = (i: number, max: number) =>
  i <= 0 ? max : i - 1;

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  keys: ['name'],
};
const fuseSearch = new Fuse([], fuseOptions);

export const useBbkExpression = (
  suggestions: Suggestion[] = [],
  { maxSuggestions = 10, trigger = '@', ...options } = {}
) => {
  const [targetRange, setTargetRange] = useState<Range | null>(null);
  const [valueIndex, setValueIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [fallback, setFallback] = useState('');
  const fallbackRef = useRef<string>();
  const [activateMentionViaToolbar, setActivateMentionViaToolbar] =
    useState(false);

  useEffect(() => {
    fuseSearch.setCollection(suggestions);
  }, [suggestions]);

  const values = fuseSearch.search(search).map((i) => i.item);

  const resetValues = () => {
    setValueIndex(0);
    setFallback('');
    setActivateMentionViaToolbar(false);
    setTargetRange(null);
  };

  const onAddBbkExpression = useCallback(
    (editor: Editor, data) => {
      // Inline insert
      if (targetRange !== null) {
        Transforms.select(editor, targetRange);

        insertBbkExpression(
          editor,
          { ...data, fallBackValue: fallback },
          options
        );
      } else {
        Transforms.collapse(editor, { edge: 'end' });
        insertBbkExpression(
          editor,
          { ...data, fallBackValue: fallback },
          options
        );
      }
      setValueIndex(0);
      setFallback('');
      setActivateMentionViaToolbar(false);
      return setTargetRange(null);
    },
    [options, targetRange, fallback, setFallback]
  );

  const onToolbarMentionClick = useCallback(
    (toolbarButton: HTMLElement | false, editor: Editor) => {
      if (toolbarButton) {
        const { selection } = editor;
        const selectedText = getSelectionText(editor);
        setTargetRange(selection);
        setFallback(selectedText);
      }

      setActivateMentionViaToolbar(toolbarButton);
    },
    [activateMentionViaToolbar]
  );

  const onMouseClickToolbarMention = useCallback(
    (index) => {
      return setValueIndex(index);
    },
    [values, valueIndex]
  );

  const closeMentionSelect = useCallback(resetValues, []);

  const onKeyDownBbkExpression = useCallback(
    (e: any, editor: Editor) => {
      if (targetRange) {
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          return setValueIndex(getNextIndex(valueIndex, values.length - 1));
        }
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          return setValueIndex(getPreviousIndex(valueIndex, values.length - 1));
        }
        if (e.key === 'Escape') {
          e.preventDefault();
          return setTargetRange(null);
        }

        if (['Tab', 'Enter'].includes(e.key)) {
          e.preventDefault();
          // if (fallback) {
          //   return onAddBbkExpression(editor, values[valueIndex]);
          // }
          setTimeout(() => fallbackRef?.current.focus(), 100);
        }
      } else {
        // possibly deleting the partial bbk expression element
        // to keep it formattable, need to make sure you have to delete the whole thing
        if (e.key === 'Backspace') {
          const { selection } = editor;
          // isNodeTypeIn(editor, [DEFAULTS_BBK_EXPRESSION.bbkExpression.type], {
          //   at: getPointBefore(editor, selection).path,
          // })
          const pointBefore = getPointBefore(editor, selection);
          if (pointBefore) {
            Transforms.removeNodes(editor, {
              at: pointBefore.path,
              match: (node) =>
                node.type === DEFAULTS_BBK_EXPRESSION.bbkExpression.type,
            });
          }
        }
      }
    },
    [
      values,
      valueIndex,
      setValueIndex,
      targetRange,
      setTargetRange,
      onAddBbkExpression,
      fallbackRef,
    ]
  );

  const onChangeBbkExpression = useCallback(
    (editor: Editor) => {
      const { selection } = editor;

      if (selection && isCollapsed(selection)) {
        const cursor = Range.start(selection);

        const { range, match: beforeMatch } = isWordAfterTrigger(editor, {
          at: cursor,
          trigger,
        });

        if (beforeMatch && isPointAtWordEnd(editor, { at: cursor })) {
          setTargetRange(range as Range);
          const [, word] = beforeMatch;
          setSearch(word);
          setValueIndex(0);
          return;
        }
      }
    },
    [setTargetRange, setSearch, setValueIndex, trigger]
  );

  const onChangeFallback = useCallback(
    (fallback: string) => {
      setFallback(fallback);
    },
    [fallback]
  );

  return {
    search,
    index: valueIndex,
    target: targetRange,
    values,
    onChangeBbkExpression,
    onKeyDownBbkExpression,
    onAddBbkExpression,
    onChangeFallback,
    fallbackRef,
    fallback,
    onToolbarMentionClick,
    activateMentionViaToolbar,
    onMouseClickToolbarMention,
    closeMentionSelect,
  };
};
