import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SuspenseLoader from '@bbkAdminComponents/loaders/suspense-loader';
import { LogInPaths } from '@bbkAdminComponents/log-in-flow/log-in-paths';
import BrightbackSession from '@bbkAdminUtils/brightback-session';
import {
  selectApp,
  selectAppState,
  selectCompany,
  selectUser,
} from '@bbkAdminRedux/app/selectors';
import { RenderInsideMainContent } from '@bbkAdminRouter/render-inside-main';
import { NotFoundPage } from '@bbkAdminComponents/not-found.page';
import { AuthorizedRouter } from '@bbkAdminRouter/authorized.router';
import { TrialSignupPage3 } from '@bbkAdminComponents/trial-signup/TrialSignupPage3';
import {
  invitationSlice,
  useSelectInvitesByUserQuery,
} from '@bbkAdminRedux/rtkq/invitation.slice';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import { SignupPaths } from '@bbkAdminRouter/signup-paths';
import { LogoutPage } from '@bbkAdminRouter/logout.page';

const Selector = lazy(() => import('@bbkAdminComponents/selector'));
const InternalRouter = lazy(
  () => import('@bbkAdminComponents/internal/internal-router')
);

export const LoggedInRouter: ReactFCC = ({ children }) => {
  const location = useLocation();
  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);
  const app = useSelector(selectApp);
  const invites = useSelectInvitesByUserQuery();
  const { isFetching: isFetchingInvites } =
    invitationSlice.endpoints.getPendingInvitationsByEmail.useQuery();
  const suppressSelectorPage = BrightbackSession.getSuppressSelectorPage();

  const loadedNecessaryData = useSelector(selectAppState).loadedNecessaryData;
  const pathname = location.pathname;
  if (
    pathname.startsWith('/trial_signup') ||
    pathname.startsWith('/company') ||
    pathname.startsWith('/internal') ||
    pathname.startsWith('/logout')
  ) {
    return (
      <Suspense
        fallback={RenderInsideMainContent(<SuspenseLoader message=" " />)}
      >
        <Switch>
          {/* Still trying to set all items, wait until finished to choose what to show */}
          {(!loadedNecessaryData || isFetchingInvites) && (
            <Route render={() => <SuspenseLoader message=" " />} />
          )}

          <Route
            path={SignupPaths.trialSignup3}
            render={() => <TrialSignupPage3 />}
          />

          <Route path={LogInPaths.mfaReset} component={LogoutPage} />

          <Route path={LogInPaths.logout} component={LogoutPage} />

          <Route
            path={LogInPaths.selector}
            exact
            render={() => RenderInsideMainContent(<Selector />)}
          />

          {/* If a user has pending invites and has not previously suppressed this page */}
          {invites.length > 0 && !suppressSelectorPage && (
            <Redirect to={LogInPaths.selector} />
          )}

          {/** No company or app set yet, but there is a user with companies. Need to go to the selector. */}
          {!(company && app) && user && user.companies.length > 1 && (
            <Switch>
              <Redirect
                path={LogInPaths.signIn}
                exact
                to={LogInPaths.selector}
              />
              <Redirect path="/company" exact to={LogInPaths.selector} />
            </Switch>
          )}

          {/* If a logged-in user has no companies associated with them, default to the No Companies page */}
          {user && user.companies.length === 0 && (
            <Redirect to={LogInPaths.selector} />
          )}

          <Route
            path="/internal/"
            render={() => RenderInsideMainContent(<InternalRouter />)}
          />

          <Route render={() => <AuthorizedRouter />} />
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    );
  } else {
    window.location.pathname = '/company';
  }

  return children;
};
