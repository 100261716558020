// @ts-nocheck
import { SlatePlugin } from '@udecode/slate-plugins-core';
import { setDefaults } from '@udecode/slate-plugins';
import { DEFAULTS_BBK_EXPRESSION } from './defaults';
import { deserializeBbkExpression } from './deserializeBbkExpression';
import { renderElementBbkExpression } from './renderElementBbkExpression';

/**
 * Enables support for autocompleting @mentions and #tags.
 * When typing a configurable marker, such as @ or #, a select
 * component appears with autocompleted suggestions.
 */
export const BbkExpressionPlugin = (options): SlatePlugin => {
  const { bbkExpression } = setDefaults(options, DEFAULTS_BBK_EXPRESSION);

  return {
    renderElement: renderElementBbkExpression(options),
    deserialize: deserializeBbkExpression(options),
    inlineTypes: [bbkExpression.type],
    // voidTypes: [bbkExpression.type], // can't be void otherwise formatting won't apply
  };
};
