/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { css } from '@emotion/react';
import type { Argument } from 'classnames';
import cs from 'classnames';
import tailwindColors from '@bbkRoot/tailwind.colors';

type SpanProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;
export const Button = React.forwardRef<
  HTMLSpanElement,
  SpanProps & {
    className?: Argument;
    active: boolean;
  }
>(({ className, active, ...props }, ref) => (
  <span
    {...props}
    ref={ref}
    className={cs(className)}
    css={css`
      cursor: pointer;
      color: ${active ? 'white' : 'black'};
      background: ${active ? tailwindColors.cbrprimary['600'] : 'none'};
      padding: 3px 5px;
      margin: 5px;
      border-radius: 3px;
      display: flex;

      &:hover {
        background: ${active
          ? tailwindColors.cbrprimary['600']
          : tailwindColors.cbrprimary['50']};
      }
    `}
  />
));

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
export const Toolbar = React.forwardRef<
  HTMLDivElement,
  DivProps & {
    className?: Argument;
  }
>(({ className, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={cs(className)}
    css={{
      display: 'flex',
      background: 'rgba(0,0,0,.1)',
      borderBottom: '1px solid rgba(0,0,0,.1)',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      padding: '2px 5px',
    }}
  />
));
