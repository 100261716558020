import { useMemo } from 'react';
import type { Dictionary } from '@bbkAdminUtils/utility-types';
import { toDictionary } from '@bbkAdminRedux/redux_utils';
import type {
  CreateLossAversionDto,
  PublishState,
  RestLossAversion,
  RestOffer,
} from '@bbkAdminUtils/api-client/rest-offers-api';
import {
  OfferGroup,
  OfferObjective,
} from '@bbkAdminUtils/api-client/rest-offers-api';
import {
  findPrimaryCard,
  isLossAversion,
  isOffer,
} from '@bbkAdminUtils/api-client/rest-cards-types';
import type { RestModalCard } from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import { RestModalAction } from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import type { BBKRequestContextArgs } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import { FeatureFlagsE, useFeatureFlags } from '@bbkAdminUtils/feature-flags';

type GetOffersArgs = {
  appKey: string;
} & BBKRequestContextArgs;

type GetLossAversionsArgs = {
  appKey: string;
} & BBKRequestContextArgs;

type UpdateLossAversionArgs = {
  appKey: string;
  content: RestLossAversion;
  publish_state: PublishState;
  comment: string;
};

type CreateLossAversionArgs = {
  appKey: string;
  lossAversion: RestLossAversion | CreateLossAversionDto;
};

type UpdateOfferArgs = {
  appKey: string;
  content: RestOffer;
  publish_state: PublishState;
  comment: string;
};

type CreateOfferArgs = {
  appKey: string;
  offer: Omit<RestOffer, 'id'>;
};
type GetCtaTextArgs = {
  appKey: string;
  body: {
    offer_id: string;
    cta_id: string;
  }[];
};
type GetCtaTextResponse = {
  result: {
    offer_id: string;
    cta_id: string;
    text: string;
  }[];
};
export const offersAndLossAversionsSlice = bbkApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOffers: builder.query<RestOffer[], GetOffersArgs>({
      query: ({ appKey, context }) => ({
        url: `/api/v1/apps/${appKey}/offers`,
        params: {
          active_only: false,
          context,
        },
      }),
      transformResponse: (res: { collection: RestOffer[] }) => res.collection,
      providesTags: ['Offers'],
    }),
    getLossAversions: builder.query<RestLossAversion[], GetLossAversionsArgs>({
      query: ({ appKey, context }) => ({
        url: `/api/v1/apps/${appKey}/loss_aversions`,
        params: {
          active_only: false,
          context,
        },
      }),
      transformResponse: (res: { collection: RestLossAversion[] }) =>
        res.collection,
      providesTags: ['LossAversions'],
    }),
    createLossAversion: builder.mutation<
      RestLossAversion,
      CreateLossAversionArgs
    >({
      query: ({ appKey, lossAversion: body }) => ({
        url: `/api/v1/apps/${appKey}/loss_aversions`,
        method: 'POST',
        body: { ...body, groups: [OfferGroup.loss_version] },
      }),
      invalidatesTags: ['LossAversions'],
    }),
    updateLossAversion: builder.mutation<unknown, UpdateLossAversionArgs>({
      query: ({ appKey, ...body }) => ({
        url: `/api/v1/apps/${appKey}/loss_aversion_change_requests`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['LossAversions'],
    }),
    createOffer: builder.mutation<RestOffer, CreateOfferArgs>({
      query: ({ appKey, offer: body }) => ({
        url: `/api/v1/apps/${appKey}/offers`,
        method: 'POST',
        body: {
          ...body,
          groups:
            body.objective === OfferObjective.churn_deflection
              ? [OfferGroup.offer]
              : [OfferGroup.lifecycle_offer],
        },
      }),
      invalidatesTags: ['Offers'],
    }),
    updateOffer: builder.mutation<unknown, UpdateOfferArgs>({
      query: ({ appKey, ...body }) => ({
        url: `/api/v1/apps/${appKey}/offer_change_requests`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Offers'],
    }),
    getCtaTextByOfferIdCtaId: builder.query<GetCtaTextResponse, GetCtaTextArgs>(
      {
        query: (args) => ({
          url: `/api/v2/apps/${args.appKey}/reports/offers/cta`,
          method: 'POST',
          body: args.body,
        }),
      }
    ),
  }),
});

export const useSelectAllOffers = (args: GetOffersArgs): RestOffer[] => {
  const { data } =
    offersAndLossAversionsSlice.endpoints.getOffers.useQuery(args);
  return useMemo(() => data ?? [], [data]);
};

export const useSelectAllChurnDeflectOffers = (args: GetOffersArgs) => {
  const offers = useSelectAllOffers(args);
  const isLCOEnabled = useFeatureFlags(FeatureFlagsE.ENABLE_LIFECYCLE_OFFERS);

  return useMemo(
    () =>
      isLCOEnabled
        ? offers.filter(
            (o) =>
              o.objective === OfferObjective.churn_deflection ||
              o.objective == null
          )
        : offers,
    [offers]
  );
};

export const useSelectActiveOffers = (args: GetOffersArgs) => {
  const offers = useSelectAllChurnDeflectOffers(args);

  return useMemo(() => offers.filter((o) => o.active), [offers]);
};

export const useSelectArchivedOffers = (args: GetOffersArgs) => {
  const offers = useSelectAllChurnDeflectOffers(args);

  return useMemo(() => offers.filter((o) => o.active === false), [offers]);
};

export const useSelectCompatibleFinalOffers = (args: GetOffersArgs) => {
  const offers = useSelectActiveOffers(args);

  return useMemo(
    () =>
      offers.filter((x) => {
        const primaryCard = findPrimaryCard(x);
        const requires = primaryCard?.requires as RestModalCard['requires'];
        if (!primaryCard || !requires) return false;

        const actions = [
          ...(requires.other_actions ?? []).map((a) => a.action),
          ...(requires.generic_actions ?? []).map((a) => a.action),
          requires.forwards?.action,
        ];

        return actions.includes(RestModalAction.cancel_with_link);
      }),
    [offers]
  );
};

export const useSelectAllOffersEntities = (args: GetOffersArgs) => {
  const offers = useSelectAllOffers(args);

  return useMemo(() => toDictionary(offers), [offers]);
};

export const useSelectAllLossAversions = (args: GetLossAversionsArgs) => {
  const { data } =
    offersAndLossAversionsSlice.endpoints.getLossAversions.useQuery(args);

  return useMemo(() => data ?? [], [data]);
};

export const useSelectAllLossAversionsEntities = (
  args: GetLossAversionsArgs
) => {
  const lossAversions = useSelectAllLossAversions(args);

  return useMemo(() => toDictionary(lossAversions), [lossAversions]);
};

export const useSelectActiveLossAversions = (args: GetLossAversionsArgs) => {
  const lossAversions = useSelectAllLossAversions(args);

  return useMemo(
    () => lossAversions.filter((la) => la.active !== false),
    [lossAversions]
  );
};

export const useSelectArchivedLossAversions = (args: GetLossAversionsArgs) => {
  const lossAversions = useSelectAllLossAversions(args);

  return useMemo(
    () => lossAversions.filter((la) => la.active === false),
    [lossAversions]
  );
};

export const useSelectAllOffersAndLossAversions = (
  args: GetLossAversionsArgs
) => {
  const offers = useSelectAllChurnDeflectOffers(args);
  const lossAversions = useSelectAllLossAversions(args);

  return useMemo(() => [...lossAversions, ...offers], [lossAversions, offers]);
};

export const useSelectAllOffersAndLossAversionsEntities = (
  args: GetLossAversionsArgs
) => {
  const items = useSelectAllOffersAndLossAversions(args);

  return useMemo(() => toDictionary(items), [items]);
};

export const useSelectLossAversionCompatibleItems = (
  args: GetLossAversionsArgs
) => {
  const items = useSelectAllOffersAndLossAversions(args);
  return useMemo(() => items.filter(isLossAversion), [items]);
};

export const useSelectLossAversionCompatibleEntities = (
  args: GetLossAversionsArgs
): Dictionary<RestLossAversion> => {
  const items = useSelectLossAversionCompatibleItems(args);
  return useMemo(() => toDictionary(items), [items]);
};

export const useSelectOffersCompatibleItems = (args: GetLossAversionsArgs) => {
  const items = useSelectAllOffersAndLossAversions(args);
  return useMemo(() => items.filter(isOffer), [items]);
};

export const useSelectOffersCompatibleEntities = (
  args: GetLossAversionsArgs
): Dictionary<RestOffer> => {
  const items = useSelectOffersCompatibleItems(args);
  return useMemo(() => toDictionary(items), [items]);
};
