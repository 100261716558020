import type { DefaultRootState } from 'react-redux';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { ActiveUser } from '@bbkAdminRedux/app/reducers';
import BrightbackSession from '@bbkAdminUtils/brightback-session';

export enum BBKRequestContext {
  reports_filter = 'reports_filter',
  deflection_funnel = 'deflection_funnel',
  benchmark_card_prior = 'benchmark_card_prior',
  benchmark_card_current = 'benchmark_card_current',
  exp_perf = 'exp_perf',
  offer_perf = 'offer_perf',
}

export type BBKRequestContextArgs = {
  context?: BBKRequestContext;
};

const bbkFetchBaseQuery: typeof fetchBaseQuery = (args) => {
  let fbqArgs = {};
  if (process.env.NODE_ENV === 'test') {
    fbqArgs = {
      baseUrl: 'http://localhost:3000',
    };
  }
  return fetchBaseQuery({
    ...fbqArgs,
    ...args,
    prepareHeaders: (headers, api) => {
      if (api.endpoint === bbkApiSlice.endpoints.getCurrentUser.name) {
        return headers;
      }
      const { data: currentUser } =
        bbkApiSlice.endpoints.getCurrentUser.select()(
          api.getState() as DefaultRootState
        );
      const token = currentUser?.authenticity_token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('BBK-Authenticity-Token', token);
      }

      const activeCompany = BrightbackSession.getActiveCompany();
      if (activeCompany) {
        headers.set('BBK-Company', activeCompany);
      }

      const activeApp = BrightbackSession.getActiveApp();
      if (activeApp) {
        headers.set('BBK-App', activeApp);
      }

      return headers;
    },
  });
};
const bbkPreauthFetchBaseQuery: typeof fetchBaseQuery = (args) => {
  let fbqArgs = {};
  if (process.env.NODE_ENV === 'test') {
    fbqArgs = {
      baseUrl: 'http://localhost:3000',
    };
  }
  return fetchBaseQuery({
    ...fbqArgs,
    ...args,
    prepareHeaders: (headers, api) => {
      const { data: currentUser } =
        bbkApiSlice.endpoints.getCurrentUser.select()(
          api.getState() as DefaultRootState
        );
      const token = currentUser?.authenticity_token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('BBK-Authenticity-Token', token);
      }

      return headers;
    },
  });
};
export const BBK_FETCH_BASE_QUERY = bbkFetchBaseQuery();
export const BBK_PREAUTH_FETCH_BASE_QUERY = bbkPreauthFetchBaseQuery();
export const bbkApiSlice = createApi({
  reducerPath: 'bbkApi-rktq',
  baseQuery: BBK_FETCH_BASE_QUERY,
  tagTypes: [
    'Session',
    'Notification',
    'Funnels',
    'FunnelRoutingMetadata',
    'LiveFunnels',
    'PageAndOfferCounts',
    'Experience',
    'Placements',
    'Offers',
    'Routing',
    'CurrentUser',
    'CurrentCompany',
    'CurrentApp',
    'UserCompanyRoles',
    'LifecycleState',
    'OnboardingHistory',
    'OnboardingStepsHistory',
    'Branding',
    'Integrations',
    'FieldMappings',
    'IntegrationFields',
    'BillingConfigs',
    'CompanySettings',
    'AppSettings',
    'GlobalUrls',
    'AlertsAndWebhooks',
    'AlertNotification',
    'NotificationConfigs',
    'ActivityLog',
    'ApiKeys',
    'Teams',
    'VanityDomain',
    'CompanySubscriptions',
    'CompanySubscriptionsHistory',
    'Plans',
    'Invoices',
    'Coupons',
    'Companies',
    'CompanyUsers',
    'ConfirmationFunnels',
    'PendingInvitesByCompany',
    'PendingInvitesByEmail',
    'OfferConfigs',
    'LossAversions',
    'Rules',
    'Reasons',
    'AdditionalBrands',
    'IntegrationFieldValues',
    'Plays',
  ],
  endpoints: (build) => ({
    getCurrentUser: build.query<ActiveUser, void>({
      query: () => ({
        url: '/api/current_user',
      }),
      providesTags: ['CurrentUser', 'CurrentCompany', 'CurrentApp'],
    }),
  }),
});
