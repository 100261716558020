import { useEffect, useState } from 'react';

export const useResizeObserver = (cb: ResizeObserverCallback) => {
  const [resizeObserver, setResizeObserver] = useState<ResizeObserver>();

  useEffect(() => {
    const observer = new ResizeObserver(cb);
    setResizeObserver(observer);
  }, []);

  return resizeObserver;
};
