import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Modal } from '@material-ui/core';
import { ProductTour } from '@bbkAdminComponents/product-tour/product-tour';
import Icons from '@bbkAdminUtils/icons';
import { useAppDispatch } from '@bbkAdminRedux/redux_utils';
import './product-tour-modal.scss';
import {
  appNotPersistedSlice,
  selectNotPersistedMisc,
} from '@bbkAdminRedux/rtkq/app-not-persisted.slice';

export const ProductTourModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showProductTour } = useSelector(selectNotPersistedMisc);
  const handleHideProductTour = () =>
    dispatch(appNotPersistedSlice.actions.saveMisc({ showProductTour: false }));

  return (
    <div className="bbk-product-tour-modal">
      <Modal
        aria-labelledby="Product Tour Modal"
        open={Boolean(showProductTour)}
        onClose={() => handleHideProductTour()}
        style={{
          display: 'flex',
        }}
      >
        <div className={classnames('bbk-product-tour-modal-inner-container')}>
          <button onClick={() => handleHideProductTour()}>{Icons.close}</button>
          <ProductTour />
        </div>
      </Modal>
    </div>
  );
};
