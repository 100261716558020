/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import type { Id, ToastContainerProps, ToastOptions } from 'react-toastify';
import { Slide, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './black-toast.scss';

type TriggerOptions = {
  content: string | React.ReactElement;
  options?: ToastOptions;
};

export const BlackToastTrigger = ({ content, options }: TriggerOptions): Id =>
  toast(content, { ...options, containerId: 'blackToast' });

export const BlackToastContainer: React.FC<ToastContainerProps> = (props) => (
  <ToastContainer
    position="top-center"
    className="bbk-black-toast-container"
    transition={Slide}
    autoClose={3000}
    icon={false}
    hideProgressBar={false}
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnFocusLoss={false}
    draggable
    pauseOnHover
    containerId="blackToast"
    closeButton={false}
    {...props}
  />
);
