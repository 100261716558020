import _ from 'lodash';
import { isDefined } from '@bbkAdminRedux/rtkq/rtkq-utils';
import { PatchPath } from '@bbkAdminUtils/utility-types';

type ResultPromise = Promise<[PatchPath, string]>;

type ObjType = {
  [prop: string]: unknown;
};

interface TraverseAndApply<T = ObjType> {
  (
    obj: T,
    predicate: (val: string) => boolean,
    applyFn: (oldVal: string) => Promise<string>,
    parentPath?: PatchPath
  ): ResultPromise[];
}

export const traverseAndApply: TraverseAndApply = (
  obj,
  predicate,
  applyFn,
  parentPath: PatchPath = []
) =>
  Object.entries(obj)
    .flatMap(([key, val]) => {
      const path = [...parentPath, key];
      if (_.isString(val) && predicate(val)) {
        return applyFn(val).then((newVal) => [path, newVal]);
      }
      if (_.isPlainObject(val)) {
        return traverseAndApply(val as ObjType, predicate, applyFn, path);
      }
      if (_.isArray(val)) {
        return val
          .filter((val) => isDefined(val))
          .flatMap((el, idx) =>
            traverseAndApply(el, predicate, applyFn, [...path, idx])
          );
      }
      return undefined;
    })
    .filter((p): p is ResultPromise => p !== undefined);

export const isBlobUrl = (url: string) => url.indexOf('blob:http') === 0;
