import { setupListeners } from '@reduxjs/toolkit/query';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';
import { appPersistSlice } from '@bbkAdminRedux/rtkq/app-persist/app-persist.slice';
import { reducersMiddlewares } from '@bbkAdminRedux/reducers-middlewares';
import { rootReducer } from '@bbkAdminRedux/root-reducer';
import { datepickerPersistSlice } from '@bbkAdminRedux/rtkq/datepicker-persist.slice';

const DEBUGGER = new URLSearchParams(window.location.search).has('debugger');

const persistConfig = {
  key: 'bbk',
  whitelist: [appPersistSlice.name, datepickerPersistSlice.name],
  storage,
  throttle: 500,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const makeStore = () =>
  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        ...reducersMiddlewares,
        ...(DEBUGGER ? [createLogger()] : [])
      ),
    devTools: process.env.NODE_ENV !== 'production',
  });

const store = makeStore();

const persistor = persistStore(store);

setupListeners(store.dispatch);

window.bbkStore = store;

export { store, persistor };
