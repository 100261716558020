import React, { useEffect } from 'react';
import type { Argument } from 'classnames';
import classNames from 'classnames';
import Backdrop from '@bbkAdminUtils/backdrop';
import CloseBtn from '@bbkAdminComponents/buttons/closeBtn';

import '@bbkAdminStylesheets/modal.scss';

type Props = {
  show: boolean;
  modalClosed: () => unknown;
  modalTitle: React.ReactNode;
  classList?: Argument;
  modalContent: React.ReactNode;
  modalFooter: React.ReactNode;
  modalIcon?: React.ReactNode;
  invisibleBackdrop?: boolean;
};
const DashModal: React.FC<Props> = (props) => {
  const {
    show,
    modalClosed,
    invisibleBackdrop,
    classList,
    modalIcon,
    modalTitle,
    modalContent,
    modalFooter,
  } = props;

  const escFunction = (e: KeyboardEvent) => {
    if (e.keyCode === 27) modalClosed();
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (show) {
      document.querySelector('body')?.classList.add('no-scroll');
    } else {
      document.querySelector('body')?.classList.remove('no-scroll');
    }
  }, [show]);

  return (
    <div className="modal-wrapper-abs">
      <div
        className="modal"
        style={{
          transform: show ? 'scale(1)' : 'scale(0)',
          opacity: show ? 1 : 0,
        }}
      >
        <Backdrop
          show={show}
          clicked={modalClosed}
          invisible={invisibleBackdrop}
        />
        <div
          className={classNames(classList)}
          style={{
            transform: show ? 'scale(1)' : 'scale(0)',
            opacity: show ? 1 : 0,
          }}
        >
          <div className="top-content">
            <div className="title tw-flex tw-items-center">
              {modalIcon}
              <h2>{modalTitle}</h2>
            </div>
            <CloseBtn clicked={modalClosed} />
            <div className="content">{modalContent}</div>
          </div>
          {modalFooter}
        </div>
      </div>
    </div>
  );
};

export default DashModal;
