import React from 'react';
import { RechargeBillingConfig } from '@bbkAdminRedux/rtkq/billing-config.slice';
import Toggle from '@bbkAdminComponents/switches/toggle/toggle';
import { RowStyles } from '../lib';

export const RechargeForm = (props: {
  values: RechargeBillingConfig;
  setCancelConfig: (values: RechargeBillingConfig) => void;
  loading: boolean;
}) => (
  <>
    <div css={[RowStyles.paragraphSpacer]}>
      <Toggle
        xs
        theme={['theme-new-purple', 'theme-large-label']}
        checked={props.values.end_of_term === true}
        id={'endOfTerm'}
        label={'Cancel at end of term'}
        handleChange={() =>
          props.setCancelConfig({
            ...props.values,
            end_of_term: !props.values.end_of_term,
          })
        }
        disabled={props.loading}
        helpText="When disabled, the subscription will be canceled immediately in Recharge. If this setting is enabled, the subscription will be canceled at the end of the billing term."
      />
    </div>
  </>
);
