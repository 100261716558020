import React from 'react';
import Icon from '@bbkAdminComponents/icons/icon-generator';
import './styles/buttons.scss';

type Props = {
  clicked?: React.MouseEventHandler<HTMLButtonElement>;
};
const CloseBtn: React.FC<Props> = ({ clicked }) => (
  <button className="bbk-button primary topRight" onClick={clicked}>
    <Icon name="close" />
  </button>
);

export default CloseBtn;
