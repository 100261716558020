import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Icons from '@bbkAdminUtils/icons';

const Loading = styled.div`
  width: 100%;
  text-align: center;

  &:after {
    display: inline-block;
    animation: ellipsis ease-in-out 3s infinite;
    content: '';
  }

  &:before {
    display: inline-block;
    visibility: hidden;
    animation: ellipsis ease-in-out 3s infinite;
    content: '';
  }

  @keyframes ellipsis {
    0% {
      content: '';
    }
    12.5% {
      content: '.';
    }
    33% {
      content: '..';
    }
    50% {
      content: '...';
    }
  }
`;

export const PreparingOverlay: React.FC<{ message?: string }> = ({
  message,
}) => {
  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => document.body.classList.remove('no-scroll');
  }, []);

  return (
    <div
      css={css`
        height: 100vh;
        overflow: hidden;
        position: absolute;
      `}
    >
      <div
        css={css`
          z-index: 10;
          background: #fff;
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #1d0861;
          font-weight: 500;
          font-size: 0.875rem;

          .pulse {
            animation: pulse ease-in-out 3s infinite;
            @keyframes pulse {
              0% {
                transform: scale(0.9);
                box-shadow: 0 0 0 0 rgba(29, 8, 97, 0.7);
              }
              50% {
                transform: scale(1.1);
                box-shadow: 0 0 0 0 rgba(29, 8, 97, 0);
              }
              100% {
                transform: scale(0.9);
              }
            }
          }
        `}
      >
        <div className="tw-text-center">
          <div className="pulse tw-inline-block tw-w-2/3 tw-text-cblogo">
            {Icons.cbRetentionPulse}
          </div>
        </div>
        <Loading>{message || 'Preparing your workspace'}</Loading>
      </div>
    </div>
  );
};
