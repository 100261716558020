/* eslint-disable no-param-reassign,camelcase */
import type { ReactNode } from 'react';
import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { Dictionary } from '@bbkAdminUtils/utility-types';
import _, { get, has, set } from 'lodash';
import { produce } from 'immer';
import type {
  NewOffer,
  RestOffer,
} from '@bbkAdminUtils/api-client/rest-offers-api';
import {
  isNewOffer,
  PublishState,
} from '@bbkAdminUtils/api-client/rest-offers-api';
import { generateNewIdentityForOfferOrLossAversionCard } from '@bbkAdminComponents/experiences/offers/edit-offer-utils';
import { useUploadBlobsToS3 } from '@bbkAdminRedux/rtkq/cdnAssets.slice';
import {
  selectApp,
  selectAppId,
  selectCompany,
  selectCompanyInternalName,
  selectUser,
} from '@bbkAdminRedux/app/selectors';
import type { Integration } from '@bbkAdminRedux/data-management/reducers';
import type { OfferConfig } from '@bbkAdminRedux/rtkq/offer-config.slice';
import { offerConfigSlice } from '@bbkAdminRedux/rtkq/offer-config.slice';
import { BlackToastTrigger } from '@bbkAdminComponents/alerts/black-toast';
import { useSelectAllFunnels } from '@bbkAdminRedux/rtkq/funnels.slice';
import { offersAndLossAversionsSlice } from '@bbkAdminRedux/rtkq/offers-and-lossAversions.slice';
import type { BillingConfig } from '@bbkAdminRedux/rtkq/billing-config.slice';
import {
  placementsSlice,
  useFetchWarningsForContent,
} from '@bbkAdminRedux/rtkq/placements.slice';
import type { RTKAPIErrorResponse } from '@bbkAdminRedux/rtkq/rtkq-error-logger';
import { parseRTKAPIError } from '@bbkAdminRedux/rtkq/rtkq-error-logger';
import type { FunnelPlacements } from '@bbkAdminRedux/rtkq/placements.utils';
import { collectForwards } from '@bbkAdminComponents/experiences/offers/collect-forwards';
import {
  EnrichArgs,
  GenAIGeneratedContent,
  OfferContent,
  genaiSlice,
} from '@bbkAdminRedux/rtkq/genai.slice';
import { isLossAversionCard } from '@bbkAdminUtils/api-client/rest-cards-types';
import { recordError } from '@bbkAdminUtils/recordError';
import { TrackPayload, usePendoTrack } from '@bbkAdminUtils/usePendo';

export const EditOfferPageContext = createContext<{
  // Offer
  offer: RestOffer | NewOffer;
  setOffer: React.Dispatch<React.SetStateAction<RestOffer | NewOffer>>;

  // Billing Integration
  billingIntegration: Integration | undefined;

  // Billing Config
  billingConfig: BillingConfig;

  // Offer Config
  offerConfig: OfferConfig;
  setOfferConfig: React.Dispatch<React.SetStateAction<OfferConfig>>;

  // Placements
  placementsMap: Dictionary<FunnelPlacements>;
  setPlacementsMap: React.Dispatch<
    React.SetStateAction<Dictionary<FunnelPlacements>>
  >;

  //AI-specific settings
  editedTitle: boolean;
  setEditedTitle: React.Dispatch<React.SetStateAction<boolean>>;
  visitedOfferEditorHome: boolean;
  setVisitedOfferEditorHome: React.Dispatch<React.SetStateAction<boolean>>;
  aiContentGenerated: boolean;
  setAiContentGenerated: React.Dispatch<React.SetStateAction<boolean>>;

  // Misc
  isPristine: boolean;
  reset: () => void;

  // Async functions
  save: (
    publishState: PublishState,
    comment: string,
    checkWarnings: boolean
  ) => Promise<unknown>;
}>(undefined!);

type EditOfferPageProviderProps = {
  offer: RestOffer | NewOffer;
  billingConfig: BillingConfig;
  offerConfig: OfferConfig;
  placementsMap: Dictionary<FunnelPlacements>;
  billingIntegration: Integration | undefined;
  children: ReactNode;
};

export const EditOfferPageProvider: React.FC<EditOfferPageProviderProps> = ({
  offer: initOffer,
  billingConfig,
  offerConfig: initOfferConfig,
  placementsMap: initPlacementsMap,
  billingIntegration,
  children,
}) => {
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const companyId = company.internal_name;
  const app = useSelector(selectApp);
  const appKey = app.encoded_id;
  const userId = useSelector(selectUser).id;
  const activeFunnels = useSelectAllFunnels({ appKey });
  const [createOffer] =
    offersAndLossAversionsSlice.endpoints.createOffer.useMutation();
  const [updateOffer] =
    offersAndLossAversionsSlice.endpoints.updateOffer.useMutation();
  const [updatePlacement] =
    placementsSlice.endpoints.updatePlacement.useMutation();
  const [putOfferConfig] =
    offerConfigSlice.endpoints.putOfferConfig.useMutation();
  const [enrichModelGeneratedContent] =
    genaiSlice.endpoints.enrich.useMutation();
  const [getModelGeneratedContent] =
    genaiSlice.endpoints.getContent.useLazyQuery();

  const uploadBlobsOnS3 = useUploadBlobsToS3();
  const fetchWarningsForContent = useFetchWarningsForContent();

  const [isPristine, setIsPristine] = useState<boolean>(true);

  const pendoTrack = usePendoTrack();

  // Offer
  const [offer, setOfferState] = useState(initOffer);
  const setOffer: typeof setOfferState = (...args) => {
    setIsPristine(false);
    setOfferState(...args);
  };

  // Offer Config
  const [offerConfig, setOfferConfigState] =
    useState<OfferConfig>(initOfferConfig);
  useEffect(() => setOfferConfigState(initOfferConfig), [initOfferConfig]);
  const setOfferConfig: typeof setOfferConfigState = (...args) => {
    setIsPristine(false);
    setOfferConfigState(...args);
  };

  // Placements
  const [placementsMap, setPlacementsMapState] = useState(initPlacementsMap);
  const setPlacementsMap: typeof setPlacementsMapState = (...args) => {
    setIsPristine(false);
    setPlacementsMapState(...args);
  };

  //AI-specific settings
  const [editedTitle, setEditedTitle] = useState<boolean>(false);
  const [visitedOfferEditorHome, setVisitedOfferEditorHome] =
    useState<boolean>(false);
  const [aiContentGenerated, setAiContentGenerated] = useState<boolean>(false);

  // Misc
  const reset = () => {
    setIsPristine(true);
    setOfferState(initOffer);
    setPlacementsMapState(initPlacementsMap);
  };

  const buildPendoTrackPayloadForAIOfferSave = (
    offer: RestOffer | NewOffer,
    outcome: EnrichArgs['outcome']
  ) =>
    ({
      event: `${isNewOffer(offer) ? 'Saving' : 'Updating'} AI offer${outcome === 'ACCEPTED_BUT_MODIFIED' ? ' with changes' : ''}`,
      properties: {
        offerId: offer.id,
        category: offer.category,
      },
    }) as TrackPayload;

  // Async functions
  async function save(
    publishState: PublishState,
    comment: string,
    checkWarnings: boolean
  ) {
    const updatedOffer = await uploadBlobsOnS3(offer);
    const activeFunnelsPlacementsMap: Dictionary<FunnelPlacements> = {};
    activeFunnels.forEach((funnel) => {
      if (funnel.active) {
        activeFunnelsPlacementsMap[funnel.id] = placementsMap[funnel.id];
      }
    });

    let offerToSave = updatedOffer;
    let updatedOfferId = null;
    let placementToSave = activeFunnelsPlacementsMap;
    const isNew = isNewOffer(updatedOffer);
    if (isNew) {
      const prevId = updatedOffer.id;
      const { cards } = updatedOffer;
      const updateCards = cards.map((card) => {
        const newCardIdentity = generateNewIdentityForOfferOrLossAversionCard(
          offer,
          card
        );
        return {
          ...card,
          newCardData: newCardIdentity,
        };
      });

      const updatedOfferWithRemappedNames = produce(updatedOffer, (draft) => {
        const { cards: updatedOfferCards } = draft;

        updatedOfferCards.forEach((card, index) => {
          if (has(card, 'requires.forwards.modal')) {
            const prevModal = get(card, 'requires.forwards.modal');
            const cardUpdate = updateCards.find((uc) => uc.name === prevModal);
            if (!cardUpdate || !cardUpdate?.newCardData.name) {
              recordError(
                `Broken reference to the modal: can't find card with name ${prevModal} ${JSON.stringify(card)} ${JSON.stringify(updateCards)} ${JSON.stringify(cardUpdate)}`
              );
            }
            set(card, 'requires.forwards.modal', cardUpdate?.newCardData.name);
          }
          if ('generic_actions' in card.requires) {
            card.requires.generic_actions?.forEach((ga) => {
              if (has(ga, 'modal')) {
                const cardUpdate = updateCards.find(
                  (uc) => uc.name === get(ga, 'modal')
                );
                set(ga, 'modal', cardUpdate?.newCardData.name);
              }
            });
          }
          if ('other_actions' in card.requires) {
            card.requires.other_actions?.forEach((oa) => {
              if (has(oa, 'modal')) {
                const cardUpdate = updateCards.find(
                  (uc) => uc.name === get(oa, 'modal')
                );
                set(oa, 'modal', cardUpdate?.newCardData.name);
              }
            });
          }
          const ctas = collectForwards(card);
          ctas?.forEach((draftCta) => {
            const ctaIdPath = [
              'cards',
              index,
              ...draftCta.path,
              'cta_details',
              'cta_id',
            ];
            set(card, ctaIdPath, undefined);
          });

          set(card, 'id', updateCards[index]?.newCardData.id);
          set(card, 'name', updateCards[index]?.newCardData.name);
        });

        set(draft, 'primary_card_name', draft.cards[0]?.name);
        set(draft, 'id', undefined);
      });

      const createdOffer = await createOffer({
        appKey,
        offer: updatedOfferWithRemappedNames,
      })
        .unwrap()
        .catch((e: RTKAPIErrorResponse) => {
          throw e.data;
        });

      const newId = createdOffer.id;
      updatedOfferId = newId;
      // update placements
      const newPlacements = produce(activeFunnelsPlacementsMap, (draft) => {
        Object.entries(draft).forEach(([funnelId, el]) => {
          if (!el) return;
          el.placements.forEach((p) => {
            if (get(p, 'content_id') === prevId) {
              set(p, 'content_id', newId);
            }
          });
        });
      });

      setOffer(createdOffer);
      setPlacementsMap(newPlacements);
      offerToSave = createdOffer;
      placementToSave = newPlacements;
    }

    //TODO AbK if offer content was model generated, then update contentID and outcome in OfferContent table for chosen record - also reject any remaining unchosen records for offer
    const primaryCardIdx = offer.cards.findIndex(
      (c) => c.name === offer.primary_card_name
    );
    const offerContentIdPath = ['cards', primaryCardIdx, 'offer_content_id'];
    const offerContentId = _.get(offerToSave, offerContentIdPath);
    if (!!offerContentId) {
      await getModelGeneratedContent({
        companyKey: companyId,
        appKey,
        id: offerContentId,
      })
        .unwrap()
        //has model generateed content
        .then(async (offerContentRecord) => {
          if (!offerContentRecord) return;
          const { outcome: initialOutcome, model_output } = offerContentRecord;
          if (initialOutcome === 'ACCEPTED_BUT_MODIFIED') {
            pendoTrack?.(
              buildPendoTrackPayloadForAIOfferSave(updatedOffer, initialOutcome)
            );
            return;
          }
          const {
            title,
            description,
            cta_text: buttonText,
          } = JSON.parse(model_output) as GenAIGeneratedContent;
          const primaryCard = offer.cards.at(primaryCardIdx)!;
          let outcome: EnrichArgs['outcome'] = initialOutcome;

          if (isLossAversionCard(primaryCard)) {
            const cardTitle = _.get(primaryCard, ['requires', 'title']);
            const cardDescription = _.get(primaryCard, ['requires', 'copy']);
            const cardButtonText = _.get(primaryCard, [
              'requires',
              'forwards',
              'text',
            ]);
            if (
              title !== cardTitle ||
              description !== cardDescription ||
              buttonText !== cardButtonText
            ) {
              outcome = 'ACCEPTED_BUT_MODIFIED';
            }
          } else {
            const cardTitle = _.get(primaryCard, ['requires', 'info', 'title']);
            const cardDescription = _.get(primaryCard, [
              'requires',
              'info',
              'details',
            ]);
            const cardButtonText = _.get(primaryCard, [
              'requires',
              'forwards',
              'text',
            ]);
            if (
              title !== cardTitle ||
              description !== cardDescription ||
              buttonText !== cardButtonText
            ) {
              outcome = 'ACCEPTED_BUT_MODIFIED';
            }
          }

          pendoTrack?.(
            buildPendoTrackPayloadForAIOfferSave(updatedOffer, outcome)
          );
          if (!outcome || outcome !== initialOutcome) {
            await enrichModelGeneratedContent({
              companyKey: companyId,
              appKey,
              content_id: offerToSave.id,
              offer_content_id: offerContentId,
              outcome: outcome ?? 'ACCEPTED',
            }).unwrap();
          }
        });
    }

    if (publishState === PublishState.live && checkWarnings) {
      const result = await fetchWarningsForContent(
        offerToSave,
        placementToSave
      );
      if (result) {
        return { type: 'error', message: result };
      }
    }

    // check that billing is present for the case where two keys are necessary
    if (!!billingIntegration && billingConfig?.manage_offers) {
      await putOfferConfig({
        app_key: appKey,
        offer_id: offerToSave.id,
        publish_state: publishState,
        ...offerConfig,
      })
        .unwrap()
        .catch((e: RTKAPIErrorResponse) => {
          const message = parseRTKAPIError(e).message;
          BlackToastTrigger({
            content: `Could not update offer configs ${message}`,
          });
        });
    }

    await Promise.all(
      Object.entries(placementToSave).map(([funnelId, el]) =>
        updatePlacement({
          appKey,
          funnelId: +funnelId,
          publish_state: publishState,
          placements: el?.placements ?? [],
          comment,
        })
      )
    );

    await updateOffer({
      appKey,
      publish_state: publishState,
      content: offerToSave,
      comment,
    })
      .unwrap()
      .catch((err: RTKAPIErrorResponse) => {
        const message = parseRTKAPIError(err).message;
        BlackToastTrigger({
          content: `We could not save the offer. Please try again. Error: ${message}`,
        });
      });

    return { offerId: updatedOfferId };
  }

  return (
    <EditOfferPageContext.Provider
      value={{
        // Offer
        offer,
        setOffer,

        // Billing Integration
        billingIntegration,

        // Billing Config
        billingConfig,

        // Offer Config
        offerConfig,
        setOfferConfig,

        // Placements
        placementsMap,
        setPlacementsMap,

        //AI-specific settings
        editedTitle,
        setEditedTitle,
        visitedOfferEditorHome,
        setVisitedOfferEditorHome,
        aiContentGenerated,
        setAiContentGenerated,

        // Misc
        isPristine,
        reset,

        // Async functions
        save,
      }}
    >
      {children}
    </EditOfferPageContext.Provider>
  );
};
