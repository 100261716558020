import Toggle from '@bbkAdminComponents/switches/toggle/toggle';
import { startCase } from 'lodash';
import React from 'react';
import { DescriptionTitle, RowStyles } from '../lib';
import RadioButton from '@bbkAdminComponents/switches/radio-button/radio-button';
import { ChargebeeBillingConfig } from '@bbkAdminRedux/rtkq/billing-config.slice';
import TippyToolTip from '@bbkAdminComponents/tooltips/tippy-tooltip';
import icons from '@bbkAdminUtils/icons';
import { css } from '@emotion/react';

const accountReceivablesHandling = [
  'schedule_payment_collection',
  'write_off',
  'no_action',
];
const creditOption = ['none', 'prorate', 'full'];
const chargesOption = ['invoice', 'delete'];

export const ChargebeeForm = (props: {
  values: ChargebeeBillingConfig;
  setCancelConfig: (values: ChargebeeBillingConfig) => void;
  loading: boolean;
}) => {
  const {
    values,
    values: {
      account_receivables_handling,
      credit_option,
      charges_option,
      end_of_term,
    },
    setCancelConfig,
    loading,
  } = props;

  const handleChange = (update: { [key: string]: any }) => {
    setCancelConfig({
      ...values,
      ...update,
    });
  };

  return (
    <>
      <div css={[RowStyles.paragraphSpacer]}>
        <Toggle
          xs
          theme={['theme-new-purple', 'theme-large-label']}
          checked={end_of_term === true}
          id={'endOfTerm'}
          label={'Cancel at end of term'}
          handleChange={() => handleChange({ end_of_term: !end_of_term })}
          disabled={loading}
          helpText="When disabled, the subscription will be canceled immediately in Chargebee. If this setting is enabled, the subscription will be canceled at the end of the billing term."
        />
      </div>
      {!end_of_term && (
        <>
          <DescriptionTitle css={[RowStyles.paragraphSpacer]}>
            Credit option
            <span
              className="bbk-radio-button-question-icon"
              css={css`
                margin-left: 15px;
              `}
            >
              <TippyToolTip
                content={
                  'Choose to either provide no credits, prorate credits for the unused period, or issue full credits for the current term charges.'
                }
                tippyOptions={{
                  delay: [500, 0],
                }}
              >
                {icons.questionMark}
              </TippyToolTip>
            </span>
          </DescriptionTitle>

          {creditOption.map((opt) => (
            <RadioButton
              classes="bbk-radio-button-label-new-purple"
              key={opt}
              checked={credit_option === opt}
              id={opt}
              label={startCase(opt)}
              handleChange={() => handleChange({ credit_option: opt })}
              disabled={loading}
            />
          ))}

          <DescriptionTitle css={[RowStyles.paragraphSpacer]}>
            Account receivable option
            <span
              className="bbk-radio-button-question-icon"
              css={css`
                margin-left: 15px;
              `}
            >
              <TippyToolTip
                content={
                  'Close invoices of the subscription which have amounts due. The invoices are chosen for payment collection or for writing off the due amount after applying the available credits and excess payments.'
                }
                tippyOptions={{
                  delay: [500, 0],
                }}
              >
                {icons.questionMark}
              </TippyToolTip>
            </span>
          </DescriptionTitle>
          {accountReceivablesHandling.map((opt) => (
            <RadioButton
              classes="bbk-radio-button-label-new-purple"
              key={opt}
              checked={account_receivables_handling === opt}
              id={opt}
              label={startCase(opt)}
              handleChange={() =>
                handleChange({ account_receivables_handling: opt })
              }
              disabled={loading}
            />
          ))}
          <DescriptionTitle css={[RowStyles.paragraphSpacer]}>
            Charge option
            <span
              className="bbk-radio-button-question-icon"
              css={css`
                margin-left: 15px;
              `}
            >
              <TippyToolTip
                content={
                  'Any unbilled charges present in the subscription can either be invoiced or deleted. Note that an automatic charge will be attempted on the payment method available if the customer has enabled auto-collection. If not, the invoice will be closed as unpaid.'
                }
                tippyOptions={{
                  delay: [500, 0],
                }}
              >
                {icons.questionMark}
              </TippyToolTip>
            </span>
          </DescriptionTitle>
          {chargesOption.map((opt) => (
            <RadioButton
              classes="bbk-radio-button-label-new-purple"
              key={opt}
              checked={charges_option === opt}
              id={opt}
              label={startCase(opt)}
              handleChange={() => handleChange({ charges_option: opt })}
              disabled={loading}
            />
          ))}
        </>
      )}
    </>
  );
};
