import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import SuspenseLoader from '@bbkAdminComponents/loaders/suspense-loader';
import { LogInRouter } from '@bbkAdminComponents/log-in-flow/log-in.router';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import { LogInPaths } from '@bbkAdminComponents/log-in-flow/log-in-paths';
import { SignupPaths } from '@bbkAdminRouter/signup-paths';

const CBIDPExchange = React.lazy(
  () => import('@bbkAdminComponents/log-in-flow/cbidp-exchange')
);
const SignupRouter = React.lazy(() => import('@bbkAdminRouter/signup.router'));

export const LoggedOutRouter: ReactFCC = () => (
  <Suspense fallback={<SuspenseLoader />}>
    <Switch>
      <Route path={LogInPaths.cbidpExchange} component={CBIDPExchange} />
      <Route path={SignupPaths.trialSignup} component={SignupRouter} />
      <Route component={LogInRouter} />
    </Switch>
  </Suspense>
);
