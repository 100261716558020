import { useSelector } from 'react-redux';
import { selectCompanyInternalName } from '@bbkAdminRedux/app/selectors';
import { UserRoles } from '@bbkAdminRedux/app/reducers';
import { currentUserSlice } from '@bbkAdminRedux/rtkq/current-user.slice';

export const useRoles = () => {
  const companyKey = useSelector(selectCompanyInternalName);
  return currentUserSlice.endpoints.getUserCompanyRoles.useQuery(
    { companyKey },
    {
      skip: companyKey === '_',
      selectFromResult: (res) => {
        const hasBillingRole = res.data?.find(
          (r) => r.name === UserRoles.billing
        );

        return {
          hasBillingRole: Boolean(hasBillingRole),
        };
      },
    }
  );
};
