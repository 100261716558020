import React from 'react';
import { useLocation } from 'react-router-dom';
import cs from 'classnames';
import './styles/layout.scss';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import { determineLeftNavAppearance } from '@bbkAdminRedux/app/reducers';

export const MainContent: ReactFCC = ({ children }) => {
  const location = useLocation();
  const showNav = determineLeftNavAppearance(location.pathname);
  return (
    <div
      className={cs('main-content', { 'full-screen': !showNav })}
      id="main-content"
    >
      {children}
    </div>
  );
};
