// @ts-nocheck
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import type { RestModalCard } from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import {
  RestModalAction,
  RestModalTemplates,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';

const ModalContentTemplates: {
  template: RestModalTemplates;
  content: Omit<RestModalCard, 'id' | 'name'>;
}[] = [
  {
    template: RestModalTemplates.forty_sixty_mini_modal_1,
    content: {
      type: 'Card',
      version: '1',
      deploy: 'modal',
      template: RestModalTemplates.forty_sixty_mini_modal_1,
      requires: {
        person: {
          avatar: 'https://cdn.brightback.com/custom/brightback/guymarion.png',
          name: 'Guy Marion',
          title: 'Co-Founder & CEO',
          organization: 'Chargebee Retention',
        },
        info: {
          title: "What could we've done better?",
          details:
            'As the CEO at Chargebee Retention, I take this seriously. Would you mind sharing details?',
        },
        forwards: {
          text: 'Book a time',
          action: RestModalAction.link,
          url: "${default(value('standard.Save return URL')}",
          sync: {},
        },
      },
      provides: {},
    },
  },
  {
    template: RestModalTemplates.forty_sixty_mini_modal_2,
    content: {
      type: 'Card',
      version: '1',
      deploy: 'modal',
      template: RestModalTemplates.forty_sixty_mini_modal_2,
      requires: {
        person: {
          avatar: 'https://cdn.brightback.com/custom/brightback/guymarion.png',
          name: 'Guy Marion',
          title: 'Co-Founder & CEO',
          organization: 'Chargebee Retention',
        },
        info: {
          title: "What could we've done better?",
          details:
            'As the CEO at Chargebee Retention, I take this seriously. Would you mind sharing details?',
        },
        forwards: {
          text: 'Book a time',
          action: RestModalAction.link,
          url: "${default(value('standard.Save return URL')}",
          sync: {},
        },
      },
      provides: {},
    },
  },
  {
    template: RestModalTemplates.video_row,
    content: {
      type: 'Card',
      version: '1',
      deploy: 'modal',
      template: RestModalTemplates.video_row,
      requires: {
        hero: {
          show: 'video',
          url: 'https://www.youtube-nocookie.com/embed/vxjdN-DNy20',
        },
        info: {
          title: "Don't worry ever again",
          details:
            "Given that you haven't been so active, learn in this video how to set catch bigger fish. You'll never worry about missing a lead or meeting again.",
        },
        forwards: {
          text: 'Book a time',
          action: RestModalAction.link,
          url: "${default(value('standard.Save return URL')}",
          sync: {},
        },
      },
      provides: {},
    },
  },
  {
    template: RestModalTemplates.speech_bubble_2,
    content: {
      type: 'Card',
      version: '1',
      deploy: 'modal',
      template: RestModalTemplates.speech_bubble_2,
      requires: {
        info: {
          title: 'We can help with this!',
          details:
            "Customer churn can be easily fixed! We have a few simple steps you can use to ensure you keep the customers you've worked so hard to get",
        },
        person: {
          avatar: 'https://cdn.brightback.com/custom/brightback/guymarion.png',
          name: 'Guy Marion',
          title: 'Co-Founder & CEO',
          organization: 'Chargebee Retention',
        },
        forwards: {
          text: 'Book a time',
          action: RestModalAction.link,
          url: "${default(value('standard.Save return URL')}",
          sync: {},
        },
      },
      provides: {},
    },
  },
  {
    template: RestModalTemplates.sixty_forty_column,
    content: {
      type: 'Card',
      version: '1',
      deploy: 'modal',
      template: RestModalTemplates.sixty_forty_column,
      requires: {
        info: {
          title: 'We can help with this!',
          details:
            "Are you game to speak with an customer churn specialist and we'll find quick wins for your team?",
        },
        hero: {
          show: 'image',
          url: 'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
        },
        forwards: {
          text: 'Book a time',
          action: RestModalAction.link,
          url: "${default(value('standard.Save return URL')}",
          sync: {},
        },
      },
      provides: {},
    },
  },
  {
    template: RestModalTemplates.sixty_forty_column_g2,
    content: {
      type: 'Card',
      version: '1',
      deploy: 'modal',
      template: RestModalTemplates.sixty_forty_column_g2,
      requires: {
        info: {
          title: 'Want to boost productivty?',
          details:
            'The best-run design teams use Chargebee Retention. Want results like these for your firm?',
        },
        hero: {
          show: 'quotes',
          title: 'What people are saying about Chargebee Retention.',
          quotes: [
            {
              text: "I've reviewed a LOT of marketing analytics software and Chargebee Retention, BY FAR, is <b>the most intuitive integrative, and easiest product to use.</b>",
              avatar: 'https://cdn.brightback.com/custom/clio/kim_s.jpeg',
              name: 'Kim S.',
              title: 'Designer, Bend Studio',
            },
            {
              text: 'Does exactly what I want it to do without feeling heavy and complicated',
              avatar:
                'https://cdn.brightback.com/custom/clio/michael_atton.jpeg',
              name: 'Michael M.',
              title: 'Creative Director, Acme co',
            },
            {
              text: 'Chargebee Retention came along and <b>blew all the others out of the way with their integrations.</b>',
              avatar: 'https://cdn.brightback.com/custom/clio/jeffrey_t.jpeg',
              name: 'Jeffrey T.',
              title: 'Lead Designer, Abacus Design',
            },
          ],
        },
        g2_rating: {
          rating: 4.5,
          badge:
            'https://images.g2crowd.com/uploads/report_medal/image/8/medal.svg',
        },
        forwards: {
          text: 'Book a time',
          action: RestModalAction.link,
          url: "${default(value('standard.Save return URL')}",
          sync: {},
        },
      },
      provides: {
        feedback: '',
      },
    },
  },
  {
    template: RestModalTemplates.fifty_fifty_column,
    content: {
      type: 'Card',
      version: '1',
      deploy: 'modal',
      template: RestModalTemplates.fifty_fifty_column,
      requires: {
        info: {
          title: 'Never got started?',
          details:
            'No problem - we created this Getting Started guide to run you through the basics.',
          headline: '',
        },
        hero: {
          show: 'quote',
          title: 'What people are saying about Chargebee Retention',
          quote: {
            text: 'In just 30 days we gained deeper insight into where our users are going, helping us plan our next app versions, including how to allocate time and resources on the imporant areas of focus.',
            avatar: 'https://cdn.brightback.com/custom/houndsights/boyd.JPG',
            name: 'Boyd',
            title: 'Head of Product at MeowMix',
          },
        },
        forwards: {
          text: 'Book a time',
          action: RestModalAction.link,
          url: "${default(value('standard.Save return URL')}",
          sync: {},
        },
      },
      provides: {},
    },
  },
  {
    template: RestModalTemplates.modal_email_success,
    content: {
      type: 'Card',
      version: '1',
      deploy: 'modal',
      template: RestModalTemplates.modal_email_success,
      requires: {
        success: {
          title: 'Before you go...',
          details:
            'We would love to have the opportunity to address your feedback. Please give us a few hours to respond to your message. Thanks for your patience!',
        },
        info: {
          title: 'Message sent',
        },
        forwards: {
          text: 'Got it! Return to app.',
          action: RestModalAction.link,
          url: "${default(value('standard.Save return URL'), 'https://www.brightback.com/')}",
          sync: {},
          subText: "(I'll wait for a response before canceling.)",
          cancelText: 'Continue to cancel',
        },
        hideCancel: false,
      },
      provides: {},
    },
  },
  {
    template: 'acceptOfferSuccess',
    content: {
      type: 'Card',
      version: '1',
      deploy: 'modal',
      template: RestModalTemplates.modal_email_success,
      requires: {
        success: {
          title: 'In the works...',
          details:
            'Thank you for accepting our offer. You will see it take effect soon.',
        },
        info: {
          title: 'Offer accepted!',
        },
        forwards: {
          text: 'Got it! Return to app.',
          action: RestModalAction.link,
          url: "${default(value('standard.Save return URL'), 'https://www.brightback.com/')}",
          sync: {},
          subText: "(I'll wait for a response before canceling.)",
          cancelText: 'Continue to cancel',
        },
        hideCancel: false,
      },
      provides: {},
    },
  },
  {
    template: RestModalTemplates.modal_email_send,
    content: {
      type: 'Card',
      version: '1',
      deploy: 'modal',
      template: RestModalTemplates.modal_email_send,
      requires: {
        info: {
          title: 'Send message',
          placeholder: 'Start typing...',
        },
        forwards: {
          text: 'Send message',
          action: RestModalAction.send_email, // DO NOT CHANGE. USED TO FIRE EMAIL
          url: '',
          modal: 'modal_email_success_url', // SET THIS TO THE NEXT MODAL
          sync: {},
        },
      },
      provides: {},
    },
  },
  {
    template: RestModalTemplates.modal_email_structured,
    content: {
      type: 'Card',
      version: '1',
      deploy: 'modal',
      template: RestModalTemplates.modal_email_structured,
      requires: {
        info: {
          title: 'Send message',
          placeholder: 'Start typing...',
          optionsExplanation: 'Please choose one of the following options',
          options: ['First option', 'Second option', 'Third option'],
          allowOther: true,
        },
        forwards: {
          text: 'Send message',
          action: RestModalAction.send_email, // DO NOT CHANGE. USED TO FIRE EMAIL
          url: '',
          modal: 'modal_email_success_url', // SET THIS TO THE NEXT MODAL
          sync: {},
        },
      },
      provides: {},
    },
  },
  {
    template: RestModalTemplates.modal_confirm_cancel,
    content: {
      type: 'Card',
      version: '1',
      deploy: 'modal',
      template: RestModalTemplates.modal_confirm_cancel,
      requires: {
        info: {
          title: 'Are you 100% sure?',
          terms:
            'We will follow-up with an email confirmation within 72 hours. Even after your subscription is cancelled, your account will be active until your next renewal date. Per our Terms of Service, we do not offer refunds for partial months.',
        },
        forwards: {
          text: 'Delete my account and data',
          action: RestModalAction.link,
          url: "${default(value('standard.Cancel confirmation URL'), '')}",
          sync: {},
        },
      },
      provides: {},
    },
  },
];

export const getModalContentForTemplate = (
  prepend: string,
  template: RestModalTemplates
): RestModalCard | undefined => {
  const obj = ModalContentTemplates.find((t) => t.template === template);
  if (!obj) return;
  const id = uuidv4();
  const name = [prepend, template, id].join('.');
  return {
    ...cloneDeep(obj.content),
    id,
    name,
  };
};

export default ModalContentTemplates;
