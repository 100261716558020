import React from 'react';
import CodeInput from 'react-verification-code-input';
import { css } from '@emotion/react';
import { LogInError } from '@bbkAdminComponents/log-in-flow/error';
import type * as LogInTypes from '@bbkAdminComponents/log-in-flow/log-in-types';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import { LogInTrouble } from '@bbkAdminComponents/log-in-flow/trouble';

type Props = {
  error?: LogInTypes.Error;
  sendVerificationEmail: LogInTypes.SendVerificationEmail;
  sending: LogInTypes.Sending;
  verify: (code: string) => Promise<boolean>;
};

export const Verify: React.FC<Props> = ({
  error,
  sending,
  sendVerificationEmail,
  verify,
}) => (
  <div>
    <div className="content">
      <div className="tw-font-semibold tw-mb-4">Almost there...</div>
      {error && <LogInError error={error} />}
      <div className="verify">
        <label>
          We have emailed a verification code to you.
          <br /> Please paste or type that 6-digit code below:
        </label>
      </div>
      <div className="tw-inline-flex">
        <CodeInput
          type="number"
          fields={6}
          autoFocus
          onComplete={verify}
          css={css`
            input {
              background-color: #f3e7f9;
              border: solid 2px #9014fe;
              border-right: none;
              font-size: 24px;
              font-family: inherit;
              text-align: center;
            }

            input::selection {
              background: #f3e7f9;
            }

            input:last-child {
              border-right: solid 2px #9014fe;
            }

            input:focus {
              border: solid 2px #a45fe1;
              background-color: #f5f0f8;
              caret-color: #9014fe;
            }
          `}
        />
      </div>
      {!sending && (
        <ButtonV2
          className="submitButtonFooter tw-mt-4"
          onClick={sendVerificationEmail}
          type="button"
          primary
        >
          Send me another email
        </ButtonV2>
      )}
    </div>
    <LogInTrouble />
  </div>
);
