import React from 'react';
import { RenderElementProps } from 'slate-react';

/**
 * BbkExpressionElementExport with no default styles.
 * [Use the `styles` API to add your own styles.](https://github.com/OfficeDev/office-ui-fabric-react/wiki/Component-Styling)
 */
export const BbkExpressionElementExport: React.FC<RenderElementProps> = ({
  attributes,
  children,
}) => {
  return (
    <span {...attributes} className="bbk-expression">
      {children}
    </span>
  );
};
